@charset "UTF-8";
@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fd05322c-bf43-4f8e-a129-24869b0942db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/74649485-cd74-443e-9d54-331ccd448900.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7732d6de-bd05-4c17-ae43-99e9a02edf31.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b4cd926c-1a31-4785-b261-1db1a68c521e.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9f93e286-eeaa-4077-b0b2-0e6901920f74.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b4a262e-3342-44e2-8ad7-719998a68134.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a5cf81d-ffbe-4c76-8982-270592fb36b0.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b39b5378-b594-45fe-8067-fc4385d08520.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/96b33a5f-d3a7-4a34-b360-76867695bb83.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/775a65da-14aa-4634-be95-6724c05fd522.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/a9e72f14-d263-440b-b2a4-fa729879fb51.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bb85cc70-1239-4a3c-b9c7-4da74afaf032.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/835e7b4f-b524-4374-b57b-9a8fc555fd4e.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/cf45dd62-4ace-47a2-abee-6fe9e95a1daa.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8ac9e38d-29c6-41ea-8e47-4ae4d2b1a4e1.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: bold;
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da3af6d8-5634-4072-8989-1ddf770e3142.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b29e833-1b7a-40ab-82a5-cfd69c8650f4.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/51f47b5d-143f-4d30-a6c3-23217fc0136b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d85949a1-c37a-43f7-9d09-fb056acf0c27.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-25UltL";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/277370bf-544b-4d3b-9f99-b9566a4b722b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/e2c2affa-fc35-4c36-aabd-33c8665d0531.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b1d7b778-bdf9-4ff3-b4e8-8cdabf234863.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-35Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9ec06c5b-556a-4dc4-9db2-425acf9fd515.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c881c21b-4148-4a11-a65d-f35e42999bc8.woff?1") format("woff"); }

:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

html {
  font-family: sans-serif; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }
  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

body {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

button {
  border-radius: 0; }

[data-z-counter] {
  z-index: 0; }

[data-z-counter='0'] {
  z-index: auto; }

.santa-preview-container html,
.santa-preview-container body,
.santa-preview-container div,
.santa-preview-container span,
.santa-preview-container applet,
.santa-preview-container object,
.santa-preview-container iframe,
.santa-preview-container h1,
.santa-preview-container h2,
.santa-preview-container h3,
.santa-preview-container h4,
.santa-preview-container h5,
.santa-preview-container h6,
.santa-preview-container p,
.santa-preview-container blockquote,
.santa-preview-container pre,
.santa-preview-container a,
.santa-preview-container abbr,
.santa-preview-container acronym,
.santa-preview-container address,
.santa-preview-container big,
.santa-preview-container cite,
.santa-preview-container code,
.santa-preview-container del,
.santa-preview-container dfn,
.santa-preview-container em,
.santa-preview-container font,
.santa-preview-container img,
.santa-preview-container ins,
.santa-preview-container kbd,
.santa-preview-container q,
.santa-preview-container s,
.santa-preview-container samp,
.santa-preview-container small,
.santa-preview-container strike,
.santa-preview-container strong,
.santa-preview-container sub,
.santa-preview-container sup,
.santa-preview-container tt,
.santa-preview-container var,
.santa-preview-container b,
.santa-preview-container u,
.santa-preview-container i,
.santa-preview-container center,
.santa-preview-container dl,
.santa-preview-container dt,
.santa-preview-container dd,
.santa-preview-container ol,
.santa-preview-container ul,
.santa-preview-container li,
.santa-preview-container fieldset,
.santa-preview-container form,
.santa-preview-container label,
.santa-preview-container legend,
.santa-preview-container table,
.santa-preview-container caption,
.santa-preview-container tbody,
.santa-preview-container tfoot,
.santa-preview-container thead,
.santa-preview-container tr,
.santa-preview-container th,
.santa-preview-container td,
.santa-preview-container nav,
.santa-preview-container button,
.santa-preview-container section,
.santa-preview-container header,
.santa-preview-container footer,
.santa-preview-container title {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

.sortable-list-item__dropzone {
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--dropZoneSize) * 1px); }

.sortable-list-item__dropzone::before {
  display: none;
  content: '';
  position: absolute;
  left: calc(var(--nestingLevel) * 24px);
  right: 0;
  height: 2px;
  background: #116dff; }

.sortable-list-item__dropzone_after {
  bottom: 0; }

.sortable-list-item__dropzone_before {
  top: -2px; }

.sortable-list-item__dropzone:first-child .sortable-list-item__dropzone_before {
  top: 0; }

.sortable-list-item__dropzone_after::before {
  bottom: 0; }

.sortable-list-item__dropzone_before::before {
  top: 0; }

.sortable-list-item__dropzone_drop::before {
  display: block; }

.sortable-list-item.sortable-list-item_edit-state {
  display: flex;
  align-items: center;
  background-color: #d6e6fe;
  padding: 6px 18px 6px 18px;
  height: 36px; }

.sortable-list-item_edit-state__input.input-container {
  width: calc(100% - 36px); }

.sortable-list-item_edit-state__input.input-container .input {
  background: #fff !important;
  height: 24px !important;
  font-size: 14px !important;
  color: #3b4057;
  border-radius: 4px; }

.sortable-list-item_edit-state__button {
  width: 54px;
  flex: 0;
  margin: 0 0 0 6px !important;
  padding: 6px 24px !important; }

.sortable-list-item__suffix {
  flex: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 6px; }
  .sortable-list-item__suffix:hover {
    cursor: pointer; }

.sortable-list-item__context-menu-action {
  display: flex; }

.sortable-list-item__context-menu-action-icon {
  width: 20px;
  flex: content 0 0; }

.sortable-list-item__context-menu-action-text {
  flex: 1; }

.sortable-list-item__context-menu-action-icon > * {
  fill: #3b4057 !important;
  color: #3b4057 !important; }

.sortable-list-item__context-menu-options .dropdown-options {
  width: unset !important; }

.sortable-list-item-ghost__layer-wrapper {
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  position: fixed; }

.sortable-list-item__ghost-layer {
  transform: translate3d(calc(var(--xOffset) * 1px), calc(var(--yOffset) * 1px), 0);
  width: calc(var(--itemWidth) * 1px); }

.sortable-list-item-ghost {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20px [icon] minmax(0, 1fr) [label];
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 6px;
  background: rgba(231, 240, 255, 0.6);
  border-radius: 4px;
  box-shadow: 2px 2px 14px 0 rgba(0, 6, 36, 0.18); }

.sortable-list-item-ghost__icon {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center; }
  .sortable-list-item-ghost__icon * {
    fill: #3b4057 !important;
    color: #3b4057 !important; }

.sortable-list-item-ghost__text {
  padding-left: 6px;
  max-width: 100%; }

.sortable-list-item {
  position: relative;
  height: 36px;
  background: #ffffff;
  padding-right: 6px;
  align-items: center;
  box-sizing: border-box; }
  .sortable-list-item:hover {
    background-color: #e7f0ff; }
  .sortable-list-item_selected {
    background: #d6e6fe; }
    .sortable-list-item_selected .sortable-list-item__prefix * {
      fill: #116dff !important;
      color: #116dff !important; }
    .sortable-list-item_selected .sortable-list-item__text .control-label {
      color: #116dff !important; }
  .sortable-list-item__context-menu, .sortable-list-item__custom-button {
    margin: 6px; }
  .sortable-list-item:not(:hover) .sortable-list-item__context-menu {
    visibility: hidden; }
  .sortable-list-item:not(:hover) .sortable-list-item__custom-button {
    visibility: hidden; }
  .sortable-list-item:not(:hover) .sortable-list-item__drag-indicator {
    visibility: hidden; }

.sortable-list-item__view {
  cursor: move;
  display: grid;
  grid-template-columns: 6px [drag-indicator] 18px [arrow] calc( 20px + var(--nestingLevel) * 17px) [icon] minmax(0, 1fr) [label] -webkit-min-content [icons] -webkit-min-content [context-menu];
  grid-template-columns: 6px [drag-indicator] 18px [arrow] calc( 20px + var(--nestingLevel) * 17px) [icon] minmax(0, 1fr) [label] min-content [icons] min-content [context-menu];
  grid-template-rows: 36px;
  align-items: center;
  justify-items: end;
  padding-left: 6px;
  width: 100%; }
  .sortable-list-item__view .control-label {
    cursor: move; }

.sortable-list-item__prefix {
  position: relative;
  height: 100%;
  justify-self: end;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px; }
  .sortable-list-item__prefix * {
    fill: #3b4057 !important;
    color: #3b4057 !important; }

.sortable-list-item__drag-indicator {
  display: flex; }

.sortable-list-item__text {
  justify-self: start;
  padding-left: 6px;
  max-width: 100%; }

.sortable-list-item__nesting-line {
  position: relative;
  align-self: stretch;
  justify-self: stretch;
  left: calc(var(--nestingLevel) * 18px - 18px); }
  .sortable-list-item__nesting-line::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    left: 10px;
    border-left: 1px solid #dfe5eb; }
    .sortable-list-item_last .sortable-list-item__nesting-line::after {
      bottom: 50%;
      border-left: 1px solid #dfe5eb;
      border-bottom: 1px solid #dfe5eb; }
  .sortable-list-item__nesting-line.sortable-list-item__nesting-line__sub::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    left: -16px;
    border-right: 1px solid #dfe5eb; }
    .sortable-list-item_last-upperlevel .sortable-list-item__nesting-line.sortable-list-item__nesting-line__sub::before {
      bottom: 50%;
      border-left: 1px solid #dfe5eb;
      border-bottom: 1px solid #dfe5eb;
      border-right: none;
      left: -8px; }

.sortable-list-item_drop-on {
  box-shadow: inset 0 0 0 2px #116dff; }

.sortable-list-item_dragging {
  background-color: #e7f0ff;
  box-shadow: none !important;
  cursor: grabbing !important;
  color: #f00 !important; }
  .sortable-list-item_dragging > *:not(.sortable-list-item__nesting-line) {
    opacity: 0; }

.sortable-list-item__arrow:not(.sortable-list-item__nesting-line) {
  width: 16px;
  height: 16px;
  display: none; }

.sortable-list-item_with-arrow .sortable-list-item__arrow:not(.sortable-list-item__nesting-line) {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out; }

.sortable-list-item_with-arrow .sortable-list-item__arrow::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #868aa5;
  position: absolute;
  top: calc(50% - 4px);
  left: 20px;
  cursor: pointer; }

.sortable-list-item_with-arrow .sortable-list-item__arrow.sortable-list-item__nesting-line::before {
  left: 26px; }

.sortable-list-item_with-arrow .sortable-list-item__arrow.sortable-list-item__arrow_closed::before {
  transform: rotate(90deg); }

.sortable-list-item_block-dnd .sortable-list-item__view .control-label {
  cursor: unset; }

.sortable-list-item_block-dnd .sortable-list-item__view {
  cursor: unset; }

.sortable-list-item_block-dnd .sortable-list-item__drag-indicator {
  visibility: hidden; }

.sortable-list__items-list {
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  width: 100%; }

.sortable-list__dropzone_before,
.sortable-list__dropzone_after {
  min-height: 12px; }

.sortable-list__dropzone_after {
  flex-grow: 1; }

.control-button .symbol-with-label {
  margin-right: 8px; }

.button-wrapper-center {
  text-align: center;
  margin-bottom: 15px; }

.control-button2 {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center; }
  .btn-back.control-button2 {
    min-width: 67px;
    height: 21px;
    background-color: #e7f0ff; }
    .btn-back.control-button2:hover {
      background-color: #d6e6fe; }
  .default.control-button2, .btn-confirm-primary.control-button2 {
    background-color: #116dff;
    color: #fff; }
    .default.control-button2:hover, .btn-confirm-primary.control-button2:hover {
      background-color: #5999ff; }
    .default.control-button2:active, .btn-confirm-primary.control-button2:active {
      background-color: #116dff; }
  .btn-confirm-primary.control-button2 {
    color: #fff; }
  .default.control-button2 {
    height: 36px;
    border-radius: 18px; }
    .default.control-button2 .symbol:nth-child(2):nth-last-child(2) {
      margin-left: 8px; }
  .btn-confirm-secondary.control-button2 {
    background-color: #e7f0ff;
    color: #116dff; }
    .btn-confirm-secondary.control-button2:hover {
      background-color: #d6e6fe; }
    .btn-confirm-secondary.control-button2:active {
      background-color: #e7f0ff; }
  .btn-md.control-button2, .btn-sm.control-button2 {
    padding: 0 18px;
    border-radius: 15px; }
  .btn-md.control-button2 {
    height: 30px; }
  .btn-sm.control-button2 {
    height: 24px; }
  .btn-text.control-button2 {
    background: 0;
    font-size: 18px;
    color: #116dff; }
  .btn-danger-secondary.control-button2 {
    background-color: #fde3e1;
    color: #e62214; }
    .btn-danger-secondary.control-button2:hover {
      background-color: #fbd0cd; }
    .btn-danger-secondary.control-button2:active {
      background-color: #fde3e1; }
  .btn-danger-primary.control-button2 {
    background-color: #e62214;
    color: #fff; }
    .btn-danger-primary.control-button2:hover {
      background-color: #ff6d63; }
    .btn-danger-primary.control-button2:active {
      background-color: #e62214; }
  .control-button2[disabled] {
    cursor: default; }
    .default.control-button2[disabled] {
      background-color: #bcbcbc; }
  .control-button2 > input {
    display: none; }
  .control-button2 > .label,
  .control-button2 > .symbol {
    display: inline-block; }
  .control-button2 > .label {
    font-size: initial; }
    .btn-back.control-button2 > .label {
      color: #116dff;
      font-size: 13px; }
    .default.control-button2 > .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      padding: 0 24px;
      color: #fff; }
    .btn-text.control-button2 > .label {
      text-decoration: underline; }

.classic-facelift-skin .control-button2.default > .label {
  font-family: var(--wbu-font-stack); }

.file-input-control {
  margin: 0;
  padding: 0;
  cursor: pointer; }
  .file-input-control.button {
    box-sizing: border-box;
    height: 30px;
    border-radius: 18px;
    background-color: #fe620f;
    color: #fff;
    margin-left: auto;
    padding-left: 22px; }
    .file-input-control.button:hover {
      background-color: #ff7d38; }
    .file-input-control.button:active {
      background-color: #fe620f; }
    .file-input-control.button[disabled] {
      cursor: default;
      background-color: #bcbcbc; }
    .file-input-control.button > .label,
    .file-input-control.button > .symbol {
      display: inline-block;
      vertical-align: middle;
      margin-top: 4px; }
    .file-input-control.button > .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      padding: 0 24px 0 11px;
      color: #fff; }
  .file-input-control.text > .label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }
  .file-input-control > input {
    display: none; }

.input-date-picker {
  position: relative;
  background-color: #fff;
  border-radius: 4px; }
  .input-date-picker .dp-header {
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    box-shadow: 0 1px 3.96px 0.04px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #f7f8f8; }
    .input-date-picker .dp-header .dp-previous {
      transform: rotate(-180deg); }
    .input-date-picker .dp-header .dp-navigate {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .input-date-picker .dp-header .dp-navigate:hover {
        background-color: #e7f0ff; }
      .input-date-picker .dp-header .dp-navigate:active {
        background-color: #116dff; }
        .input-date-picker .dp-header .dp-navigate:active * {
          fill: #fff; }
    .input-date-picker .dp-header .dp-title {
      flex: 1;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #116dff; }
  .input-date-picker .dp-body table {
    text-align: center;
    width: calc(100% - 24px);
    margin: 8px 12px;
    font-size: 13px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #3b4057; }
    .input-date-picker .dp-body table td div {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px; }
    .input-date-picker .dp-body table .dp-day-name {
      font-weight: bold; }
  .input-date-picker .dp-day {
    cursor: pointer; }
    .input-date-picker .dp-day:not(.dp-day-selected):hover {
      background-color: #e7f0ff; }
  .input-date-picker .dp-day-not-in-current-month {
    color: #dfe5eb; }
  .input-date-picker .dp-day-selected,
  .input-date-picker .dp-day-selected:hover {
    background-color: #116dff;
    color: #fff; }
  .input-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border: 1px solid #116dff; }
  .input-date-picker .dp-footer {
    border-top: 1px solid #f7f8f8;
    height: 32px;
    padding: 12px; }
    .input-date-picker .dp-footer .delete-btn {
      background-color: #fff;
      padding: 0 12px;
      margin-right: 12px;
      height: 32px;
      vertical-align: middle; }
      .input-date-picker .dp-footer .delete-btn path {
        fill: #3b4057; }
      .input-date-picker .dp-footer .delete-btn:hover path {
        fill: #e62214; }
    .input-date-picker .dp-footer .goto-today,
    .input-date-picker .dp-footer .goto-selection {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      background-color: transparent;
      color: #116dff;
      display: inline-block;
      border-radius: 32px;
      line-height: 32px;
      height: 32px;
      padding: 0 20px;
      cursor: pointer; }
      .input-date-picker .dp-footer .goto-today:hover,
      .input-date-picker .dp-footer .goto-selection:hover {
        background-color: #e7f0ff; }
    .input-date-picker .dp-footer .goto-selection {
      float: right; }
      .input-date-picker .dp-footer .goto-selection:disabled {
        cursor: inherit;
        color: #bcbcbc;
        background-color: transparent; }

.control-angle {
  position: relative;
  height: 108px; }
  .control-angle > .disabled {
    opacity: 0.4; }
  .control-angle > .circle {
    width: 72px;
    height: 72px;
    top: 32px;
    left: 48px;
    border-radius: 50%;
    border: solid 4px #d6e1e8;
    position: absolute;
    box-sizing: border-box; }
    .control-angle > .circle:hover > .knob {
      background-color: #86ccf7; }
    .control-angle > .circle:active > .circle-center,
    .control-angle > .circle:active > .knob {
      background-color: #0199ef; }
    .control-angle > .circle:active:after {
      border: solid 1px #0199ef; }
    .control-angle > .circle:hover > .knob, .control-angle > .circle:active > .knob {
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3), 0 0 8px 1px #86ccf7; }
    .control-angle > .circle > .circle-center {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      background-color: #d6e1e8;
      top: calc(50% - 3px);
      left: calc(50% - 3px); }
    .control-angle > .circle:after {
      width: 0;
      height: 32px;
      content: '';
      position: absolute;
      border: solid 1px #d6e1e8;
      z-index: -1;
      left: 31px; }
    .control-angle > .circle .knob {
      width: 8px;
      height: 8px;
      position: absolute;
      cursor: pointer;
      background-color: #116dff;
      border-radius: 50%;
      border: solid 4px #fff;
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3);
      box-sizing: border-box;
      top: -5px;
      left: calc(50% - 4px); }
  .control-angle > .control-label {
    width: 54px;
    color: #3b4057;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .animation-panel .control-angle {
    margin: 17px 10px 7px 24px;
    height: 96px; }
    .animation-panel .control-angle .input-stepper {
      left: auto;
      top: 28px;
      right: 0;
      width: 56px;
      padding: 0 6px; }
    .animation-panel .control-angle .circle {
      top: 18px;
      left: 80px; }

.control-thumbnails {
  position: relative;
  padding: 13px 24px 16px;
  white-space: initial; }
  .control-thumbnails,
  .control-thumbnails * {
    box-sizing: border-box;
    font-size: 0; }
  .control-thumbnails .radio-control {
    position: relative;
    display: inline-block;
    margin: 0 12px 12px 0;
    vertical-align: top; }
    .control-thumbnails .radio-control .svg-radio {
      position: relative;
      width: 100%;
      background-color: #e7f0ff;
      display: inline-block;
      border-radius: 4px;
      transition: background-color 0.2s; }
      .control-thumbnails .radio-control .svg-radio:hover {
        background-color: #d6e6fe; }
      .control-thumbnails .radio-control .svg-radio.disabled {
        background-color: #f0f0f0; }
    .control-thumbnails .radio-control .image-radio {
      position: relative;
      overflow: hidden;
      width: 100%;
      border: 1px solid #dfe5eb;
      display: inline-block;
      border-radius: 4px; }
      .control-thumbnails .radio-control .image-radio .image-radio-src {
        position: relative;
        display: block;
        top: -1px;
        left: -1px; }
    .control-thumbnails .radio-control:hover .image-radio {
      border: 2px solid #80b1ff; }
      .control-thumbnails .radio-control:hover .image-radio .image-radio-src {
        top: -2px;
        left: -2px; }
    .control-thumbnails .radio-control input:checked + .svg-radio {
      background-color: #116dff; }
      .control-thumbnails .radio-control input:checked + .svg-radio.symbol-thumb-none, .control-thumbnails .radio-control input:checked + .svg-radio.symbol-transition-none {
        background-color: #e7f0ff; }
      .control-thumbnails .radio-control input:checked + .svg-radio .cls-5.cls-5 {
        fill: #e7f0ff; }
    .control-thumbnails .radio-control input:checked + .image-radio {
      border: 2px solid #116dff; }
      .control-thumbnails .radio-control input:checked + .image-radio .image-radio-src {
        top: -2px;
        left: -2px; }
    .control-thumbnails .radio-control .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      display: block;
      text-align: center;
      color: #116dff;
      padding-top: 9px; }
      .control-thumbnails .radio-control .label.disabled {
        color: #bcbcbc; }
    .control-thumbnails .radio-control .notify-symbol {
      position: absolute; }
      .control-thumbnails .radio-control .notify-symbol.notify-symbol-small-right {
        right: -8px;
        top: -8px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='0'] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='0'] .radio-control:nth-of-type(2n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='0'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='0'] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='0'].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='1'] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='1'] .radio-control:nth-of-type(1n) {
      margin-right: 126px; }
  .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='1'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='1'] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='1'].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='2'] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='2'] .radio-control:nth-of-type(0n) {
      margin-right: 252px; }
  .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='2'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='2'] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='2'][data-items-to-skip='2'].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='0'] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='0'] .radio-control:nth-of-type(3n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='0'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='0'] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='0'].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='1'] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='1'] .radio-control:nth-of-type(2n) {
      margin-right: 84px; }
  .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='1'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='1'] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='1'].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='2'] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='2'] .radio-control:nth-of-type(1n) {
      margin-right: 168px; }
  .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='2'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='2'] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='3'][data-items-to-skip='2'].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='0'] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='0'] .radio-control:nth-of-type(4n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='0'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='0'] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='0'].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='1'] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='1'] .radio-control:nth-of-type(3n) {
      margin-right: 63px; }
  .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='1'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='1'] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='1'].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='2'] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='2'] .radio-control:nth-of-type(2n) {
      margin-right: 126px; }
  .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='2'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='2'] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='4'][data-items-to-skip='2'].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] .radio-control:nth-of-type(5n) {
      margin-right: 0px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='0'].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] .radio-control:nth-of-type(4n) {
      margin-right: 50.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='1'].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] .radio-control:nth-of-type(3n) {
      margin-right: 100.8px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row='5'][data-items-to-skip='2'].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails.with-title > div {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #3b4057;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px; }
  .control-thumbnails .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  .control-thumbnails:hover .info-icon {
    opacity: 1; }

.thumbnails-with-icons .control-header {
  display: flex; }
  .thumbnails-with-icons .control-header .thumbnails-with-icons-label {
    flex: 1; }
  .thumbnails-with-icons .control-header .info-icon {
    opacity: 0;
    float: right;
    position: relative;
    transition-property: opacity;
    transition-duration: 0.15s; }

.thumbnails-with-icons:hover .control-header .info-icon {
  opacity: 1; }

.thumbnails-with-icons .thumbnails-with-icons-label,
.thumbnails-with-icons .option-title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  text-overflow: ellipsis;
  overflow: hidden; }

.thumbnails-with-icons .thumbnails-with-icons-label {
  margin-bottom: 12px; }

.thumbnails-with-icons label {
  display: inline-block;
  cursor: pointer;
  text-align: center; }
  .thumbnails-with-icons label:first-of-type {
    margin-right: 12px; }
  .thumbnails-with-icons label svg {
    max-width: 62px;
    max-height: 62px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.thumbnails-with-icons input {
  display: none; }
  .thumbnails-with-icons input + span {
    padding-bottom: 18px;
    display: inline-block;
    border-radius: 4px;
    width: 114px;
    box-shadow: inset 0 0 0 1px #dfe5eb;
    font-size: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; }
  .thumbnails-with-icons input:checked + span {
    box-shadow: inset 0 0 0 2px #116dff; }
    .thumbnails-with-icons input:checked + span .option-title {
      color: #116dff; }
  .thumbnails-with-icons input:not(:checked) + span:hover {
    box-shadow: inset 0 0 0 2px #d6e6fe; }

.thumbnails-with-icons .image-container {
  display: block;
  width: 78px;
  height: 83px;
  border-bottom: 1px solid #dfe5eb;
  margin: 0 auto 12px auto;
  position: relative;
  box-sizing: border-box; }

.thumbnails-vertical {
  width: 100%;
  text-align: center;
  margin: 24px 0; }
  .thumbnails-vertical label {
    display: block;
    margin: 12px 0;
    line-height: 0; }
  .thumbnails-vertical .thumbnail-container {
    cursor: pointer;
    box-shadow: 0 0 0 1px #dfe5eb inset;
    border-radius: 6px;
    display: inline-block;
    width: calc(100% - 48px);
    line-height: 0;
    overflow: hidden; }
    .thumbnails-vertical .thumbnail-container img {
      margin: 20px 24px 13px 25px; }
      .thumbnails-vertical .thumbnail-container img.withoutLabel {
        margin-bottom: 20px; }
    .thumbnails-vertical .thumbnail-container span {
      margin: 0 0 12px 0;
      line-height: 14px; }
    .thumbnails-vertical .thumbnail-container.right {
      text-align: right; }
    .thumbnails-vertical .thumbnail-container.left {
      text-align: left; }
  .thumbnails-vertical .thumbnail-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    display: inline-block;
    color: #3b4057; }
  .thumbnails-vertical input {
    display: none; }
    .thumbnails-vertical input:checked + .thumbnail-container {
      box-shadow: 0 0 0 2px #116dff inset; }
      .thumbnails-vertical input:checked + .thumbnail-container .thumbnail-title {
        color: #116dff; }
    .thumbnails-vertical input:not(:checked) + .thumbnail-container:hover {
      box-shadow: 0 0 0 2px #d6e6fe inset; }

.control-mandatory {
  position: relative;
  cursor: pointer; }
  .control-mandatory:after {
    content: attr(data-text);
    display: inline-block;
    margin-left: 13px;
    position: absolute;
    white-space: nowrap;
    line-height: 18px; }
  .control-mandatory input {
    display: none; }
    .control-mandatory input + span {
      width: 16px;
      height: 16px;
      border: solid 1px #116dff;
      display: inline-block;
      border-radius: 50%;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      position: relative;
      background-color: #f7f8f8; }
      .control-mandatory input + span:before {
        content: '*';
        color: #b6c1cd;
        font-size: 30px;
        position: absolute;
        top: 4.5px;
        left: 2.7px;
        line-height: 18px; }
    .control-mandatory input:checked + span {
      background-color: #f7f8f8; }
      .control-mandatory input:checked + span:before {
        color: #116dff; }
    .control-mandatory input:disabled + span {
      border-color: #bcbcbc; }
      .control-mandatory input:disabled + span:before {
        color: #bcbcbc; }
  .control-mandatory:hover input + span {
    background-color: #fff; }
    .control-mandatory:hover input + span:before {
      color: #b6c1cd; }
  .control-mandatory:hover input:checked + span {
    background-color: #e7f0ff; }
    .control-mandatory:hover input:checked + span:before {
      color: #116dff; }
  .control-mandatory:hover input:disabled + span {
    border-color: #bcbcbc;
    background-color: #f7f8f8; }
    .control-mandatory:hover input:disabled + span:before {
      color: #bcbcbc; }

.control-buttons-group {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  color: #3b4057;
  position: relative; }
  .control-buttons-group p {
    font-size: 14px;
    color: #3b4057;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 0; }
  .control-buttons-group .group-buttons-container-left {
    text-align: left; }
  .control-buttons-group .group-buttons-container-right {
    text-align: right; }
  .control-buttons-group .group-buttons-container-center {
    text-align: center; }
  .control-buttons-group .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .control-buttons-group:hover .info-icon {
    display: inline-block; }
  .control-buttons-group input {
    display: none; }
    .control-buttons-group input + span {
      color: #116dff;
      cursor: pointer;
      height: 36px;
      background-color: #edf7ff;
      display: inline-block;
      position: relative;
      white-space: nowrap;
      line-height: 36px;
      vertical-align: middle;
      font-size: 14px;
      padding: 0 14px;
      max-width: 93px;
      min-width: 74px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      box-shadow: 0 2px 0 0 #d4e7fb; }
    .control-buttons-group input:checked + span {
      color: #fff;
      position: relative;
      top: 2px;
      background-color: #116dff;
      box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.11); }
    .control-buttons-group input:disabled + span {
      cursor: default;
      background-color: #f6f6f6; }
    .control-buttons-group input:disabled:checked + span {
      background-color: #bcbcbc; }
    .control-buttons-group input:disabled:not(:checked) + span {
      color: #bcbcbc;
      box-shadow: 0 2px 0 0 #e5e5e5; }
  .control-buttons-group label {
    display: inline-block; }
    .control-buttons-group label input + span:hover {
      background-color: #d6e6fe; }
    .control-buttons-group label:first-of-type span {
      border-radius: 20px 0 0 20px; }
    .control-buttons-group label:last-of-type span {
      border-radius: 0 20px 20px 0; }
  .animation-panel .control-buttons-group {
    text-align: center; }

.control-tabs-horizontal {
  box-sizing: border-box;
  font-size: 0;
  width: 100%; }
  .tabbed.control-tabs-horizontal {
    height: 59px;
    background-color: #fff; }
  .arrowed.control-tabs-horizontal {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05); }
  .arrowed-box.control-tabs-horizontal {
    margin: 18px 0;
    width: 240px;
    counter-reset: boxes 0; }
  .control-tabs-horizontal > .container {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(1),
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(1),
    .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label {
      width: 100%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(2),
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(2),
    .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label {
      width: 50%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(3),
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(3),
    .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label {
      width: 33.33333%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(4),
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(4),
    .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label {
      width: 25%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(5),
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(5) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(5),
    .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(5) ~ label {
      width: 20%; }
    .vertical.control-tabs-horizontal > .container {
      display: block; }
    .tabbed.control-tabs-horizontal > .container {
      vertical-align: middle; }
    .arrowed.control-tabs-horizontal > .container {
      height: 52px; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(1),
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label {
        width: 100%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(2),
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label {
        width: 50%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(3),
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label {
        width: 33.33333%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(4),
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label {
        width: 25%; }
    .control-tabs-horizontal > .container > .input {
      display: none; }
      .control-tabs-horizontal > .container > .input + .tab-decorator {
        font-size: 14px;
        box-sizing: border-box;
        transition-duration: 0.2s;
        position: relative; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator {
          height: 47px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 10px;
          background-color: #e7f0ff;
          border-right: solid 1px #d6e6fe;
          transition-property: background-color, border-color; }
          .tabbed.control-tabs-horizontal > .container > .input:checked + .tab-decorator {
            background-color: #fff;
            border-color: transparent; }
          .tabbed.control-tabs-horizontal > .container:last-child > .input + .tab-decorator {
            border-right: none; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          color: #3b4057; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          display: block; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          transition-property: border-color; }
          .horizontal.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
            border-bottom: solid 3px #116dff;
            padding-right: 3px;
            padding-left: 3px;
            height: 84px; }
          .vertical.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
            border-right: solid 3px #116dff;
            padding-right: 12px;
            height: calc( 42px + 3px * 2);
            width: calc( 132px + 12px + 3px); }
            .vertical.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator {
              height: 42px; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator {
          height: 52px;
          padding: 0 6px;
          border-bottom: solid 3px transparent;
          transition-property: border-color; }
          .arrowed.control-tabs-horizontal > .container:hover > .input + .tab-decorator {
            border-bottom-color: #80b1ff; }
          .arrowed.control-tabs-horizontal > .container > .input:checked + .tab-decorator {
            border-bottom-color: #116dff;
            color: #3b4057; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator:hover {
          background-color: #d6e6fe; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator:after, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator:after {
          content: '';
          width: 0;
          height: 0;
          border: solid;
          border-color: transparent transparent #116dff;
          border-width: 0 9px 7px;
          position: absolute;
          bottom: -3px;
          left: calc(50% - 9px);
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.2s; }
          .arrowed.control-tabs-horizontal > .container > .input:checked + .tab-decorator:after, .arrowed-box.control-tabs-horizontal > .container > .input:checked + .tab-decorator:after {
            opacity: 1; }
        .vertical.control-tabs-horizontal > .container > .input + .tab-decorator:after {
          border-color: transparent transparent transparent #116dff;
          border-width: 9px 0 9px 7px;
          left: calc( 132px + 12px);
          bottom: calc(50% - 3px * 2); }
          .vertical.control-tabs-horizontal:last-child > .container > .input + .tab-decorator:after {
            bottom: calc(50% - 9px); }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
          display: inline-flex;
          align-items: center;
          height: 49px;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #116dff;
          transition-property: color;
          transition-duration: 0.2s; }
          .arrowed.control-tabs-horizontal > .container:hover > .input:not(:checked) + .tab-decorator > .text-label {
            color: #3b4057; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
          background-color: #e7f0ff;
          display: inline-block;
          width: 100%;
          vertical-align: middle;
          text-align: center;
          transition-property: background-color;
          transition-duration: 0.2s; }
          .horizontal.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
            height: 48px; }
          .vertical.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
            height: 42px; }
          .arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
            border-top-left-radius: 6px; }
            .horizontal.arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
              border-bottom-left-radius: 6px; }
            .vertical.arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
              border-top-right-radius: 6px; }
          .arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
            border-bottom-right-radius: 6px; }
            .horizontal.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
              border-top-right-radius: 6px; }
            .vertical.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
              border-bottom-left-radius: 6px; }
          .arrowed-box.control-tabs-horizontal > .container > .input:checked + .tab-decorator > .text-label {
            background-color: #116dff; }
          .arrowed-box.control-tabs-horizontal > .container:hover > .input:not(:checked) + .tab-decorator > .text-label {
            background-color: #d6e6fe; }
        .tabbed.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
          border-right: none; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label:after {
          content: counter(boxes, decimal);
          counter-increment: boxes 1;
          position: absolute;
          bottom: 15px;
          font-size: 12px;
          color: #116dff;
          text-align: center;
          left: calc(50% - 3px); }
        .vertical.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label:after {
          left: calc( 132px + 12px + 3px + 7px + 7px);
          bottom: calc(50% - 3px * 2); }

.control-tabs-vertical {
  display: block;
  font-size: 0;
  width: 48px;
  height: 100%;
  background-color: #e7f0ff;
  box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d6e6fe; }
  .control-tabs-vertical .tab-text, .control-tabs-vertical .advanced-style-panel .tab-text-composite, .advanced-style-panel .control-tabs-vertical .tab-text-composite {
    display: none; }
  .control-tabs-vertical.closed {
    width: 288px;
    background-color: transparent;
    box-shadow: none; }
    .control-tabs-vertical.closed svg {
      left: 17px; }
    .control-tabs-vertical.closed .tab-text, .control-tabs-vertical.closed .advanced-style-panel .tab-text-composite, .advanced-style-panel .control-tabs-vertical.closed .tab-text-composite {
      display: inline-block; }
  .control-tabs-vertical label {
    cursor: pointer;
    display: block; }
  .control-tabs-vertical input {
    display: none; }
    .control-tabs-vertical input + span {
      display: inline-block;
      width: 100%;
      height: 48px;
      position: relative;
      line-height: 48px; }
      .control-tabs-vertical input + span svg {
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        fill: #116dff; }
    .control-tabs-vertical input:checked + span {
      background-color: #ffffff;
      border-top: 1px solid #d6e6fe;
      border-bottom: 1px solid #d6e6fe; }
      .control-tabs-vertical input:checked + span svg {
        fill: #000624; }
    .control-tabs-vertical input:not(:checked) + span {
      box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d6e6fe; }
      .control-tabs-vertical input:not(:checked) + span:hover {
        background-color: #d6e6fe; }
  .control-tabs-vertical label:first-child input:checked + span {
    border-top: none;
    border-bottom: 1px solid #d6e6fe; }
  .control-tabs-vertical label:last-child input:checked + span {
    border-bottom: none; }

.control-link div {
  width: 29px;
  height: 26px;
  border-radius: 6px;
  background-color: transparent;
  border: solid 1px #d6e1e8;
  display: block;
  box-sizing: border-box;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: background-color, border-color; }

.control-link:not(.disabled) div {
  cursor: pointer; }
  .control-link:not(.disabled) div:hover {
    background-color: #d6e6fe;
    border-color: #d6e6fe; }

.control-link path {
  fill: #868aa5;
  transition-duration: 0.2s;
  transition-property: fill; }

.control-link input {
  display: none; }
  .control-link input:checked + div {
    background-color: #116dff;
    border-color: transparent; }
    .control-link input:checked + div path {
      fill: #fff; }
    .control-link input:checked + div:hover {
      background-color: #5999ff; }

.info-icon {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  fill: #868aa5; }
  .label-info-icon-container.info-icon {
    background-color: #3b4057;
    cursor: pointer;
    border-radius: 0;
    width: 30px;
    height: 16px;
    padding-top: 2px;
    box-sizing: border-box; }
  .accordion-header .info-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none; }
    .accordion-header:hover .info-icon {
      display: inline-block; }
  .dropdown .info-icon {
    vertical-align: middle;
    margin-bottom: 3px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
    .dropdown .info-icon,
    .dropdown .info-icon > .symbol {
      margin: 0;
      padding: 0; }
  .dropdown:hover .info-icon {
    opacity: 1; }
  .control-checkbox .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    .control-checkbox:hover .info-icon {
      display: inline-block; }
  .control-text-input .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    .control-text-input:hover .info-icon {
      display: inline-block; }
  .control-text-input.always-show-info-icon .info-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0; }
  .preset-item .info-icon {
    position: absolute;
    bottom: 21px;
    right: 16px; }
    .preset-item:hover .info-icon {
      opacity: 1; }
      .preset-item:hover .info-icon:hover {
        color: #fff; }
    .preset-item .info-icon svg circle {
      fill: #fff; }
  .preset-item .info-wrapper.has-label .info-icon {
    bottom: 26px; }
  .section-header > .title-line .info-icon {
    position: absolute;
    bottom: 6px;
    right: 1px;
    display: none; }
  .site-members-settings .info-icon {
    display: none; }
    .site-members-settings:hover .info-icon {
      display: inline-block; }
  .control-section-divider.labeled .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  .control-section-divider.labeled:hover .info-icon {
    opacity: 1; }

.control-alignment {
  text-align: center; }
  .control-alignment .cls-4 {
    fill: #116dff; }
  .control-alignment .cls-5 {
    fill: #fff; }
  .control-alignment .label-info-wrapper {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .control-alignment div.label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    text-align: left; }
  .control-alignment div.items {
    display: inline-block;
    width: 90px;
    height: 90px; }
    .control-alignment div.items input[type='radio'] {
      display: none; }
    .control-alignment div.items label.item {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 3px;
      float: left; }
      .control-alignment div.items label.item span {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: #d6e6fe no-repeat center; }
        .control-alignment div.items label.item span:hover {
          background-color: #80b1ff; }
          .control-alignment div.items label.item span:hover svg {
            visibility: visible; }
        .control-alignment div.items label.item span svg {
          visibility: hidden;
          shape-rendering: crispEdges; }
      .control-alignment div.items label.item input[type='radio']:checked + span {
        background-color: #116dff; }
        .control-alignment div.items label.item input[type='radio']:checked + span svg {
          visibility: visible; }
      .control-alignment div.items label.item input[type='radio']:disabled + span {
        background-color: #f0f0f0; }
      .control-alignment div.items label.item input[type='radio']:disabled:not(:checked) + span:hover svg {
        visibility: hidden; }

.playIcon {
  width: 0;
  height: 0;
  margin-top: 50%;
  margin-left: 50%;
  top: -4px;
  left: -2px;
  position: relative;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #116dff; }

.playButton {
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #116dff; }
  .playButton:hover {
    background-color: #edf7ff; }
  .playButton.isPlaying {
    background-color: #116dff; }
    .playButton.isPlaying .playIcon {
      border-left-color: #ffffff; }

.thumbnail-preview-play {
  position: relative; }
  .thumbnail-preview-play svg {
    position: absolute;
    left: 18px;
    width: 49px;
    height: 24px;
    top: 50%;
    margin-top: -11px;
    text-align: center; }
  .thumbnail-preview-play .label {
    margin-left: 78px;
    margin-right: 48px;
    padding: 0;
    position: relative;
    color: #395671;
    font-size: 14px;
    font-weight: 300; }
  .thumbnail-preview-play .playButton {
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -11px; }

.pick-text-alignment .svg-radio {
  border-radius: 6px;
  background-color: #eaf7fe;
  width: 38px;
  height: 38px;
  fill: #7fccf7;
  padding: 8px; }

.pick-text-alignment input:checked + .svg-radio {
  background-color: #4e99ec;
  fill: #d3edfe; }

.pick-text-alignment .control-thumbnails:not(.with-title) {
  padding-top: 25px; }

hr.divider-long, hr.divider-short {
  background-color: #dfe5eb;
  border: 0;
  height: 1px;
  margin: 0; }

hr.divider-short {
  margin: 0 24px; }

.rich-text-paragraph {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .rich-text-paragraph .rich-text-paragraph-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    text-align: left;
    margin: 10px 0 0; }
  .rich-text-paragraph .rich-text-paragraph-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    margin: 0; }
    .rich-text-paragraph .rich-text-paragraph-content a {
      color: #116dff;
      text-decoration: none; }
      .rich-text-paragraph .rich-text-paragraph-content a:hover {
        cursor: pointer;
        text-decoration: underline; }
    .rich-text-paragraph .rich-text-paragraph-content b,
    .rich-text-paragraph .rich-text-paragraph-content strong {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      font-weight: normal; }

.listItemWithImage {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  .listItemWithImage:hover {
    background-color: #e7f0ff; }
    .disabled.listItemWithImage:hover {
      background-color: inherit;
      cursor: default; }
  .listItemWithImage .image {
    display: inline-block;
    width: 90px;
    height: 90px; }
  .listItemWithImage .content {
    vertical-align: top;
    display: flex;
    min-width: 0;
    margin: 0 10px 0 16px;
    flex: 1;
    flex-direction: column;
    justify-content: center; }
    .listItemWithImage .content .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis; }
    .listItemWithImage .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin-bottom: 6px; }
      .disabled.listItemWithImage .content .title {
        color: #7a7a7a; }
    .listItemWithImage .content .desc {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      line-height: 17px;
      max-height: 51px; }
      .disabled.listItemWithImage .content .desc {
        color: #939393; }
  .listItemWithImage .list-item-indicator {
    display: flex;
    align-items: center; }
  .listItemWithImage .control-button.inline-link-button {
    display: inline;
    height: auto;
    font-size: inherit; }

.list-item-with-image-tooltip-content {
  margin: -18px; }
  .list-item-with-image-tooltip-content p span {
    display: inline-block;
    padding-top: 6px; }

.listItem {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  .listItem:hover {
    background-color: #e7f0ff; }
  .listItem .image {
    display: inline-block;
    width: 90px;
    height: 90px; }
  .listItem .content {
    width: 148px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 9px 10px 0 14px; }
    .listItem .content.disabled {
      cursor: default; }
    .listItem .content .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis; }
    .listItem .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin: 0;
      height: 34px;
      line-height: 34px;
      flex-shrink: 0; }
    .listItem .content .desc {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      line-height: initial; }
  .listItem .expandSymbol {
    width: 28px;
    height: 28px; }

i.arrow-button {
  border: 2px solid #116dff;
  border-radius: 50%;
  display: inline-block;
  height: 21px;
  width: 21px;
  opacity: 0.67;
  cursor: pointer; }
  i.arrow-button svg {
    fill: #116dff;
    padding: 5px;
    vertical-align: middle; }
  i.arrow-button:hover {
    background-color: #116dff; }
    i.arrow-button:hover svg {
      fill: #fff; }
  i.arrow-button.without-border {
    border: none;
    width: 9px;
    height: 9px;
    opacity: 1; }
    i.arrow-button.without-border svg {
      width: 9px;
      padding: 0; }
    i.arrow-button.without-border:hover {
      background-color: transparent; }
      i.arrow-button.without-border:hover svg {
        fill: #116dff; }

:not(.composite-toggle-switch) > .control-boolean {
  overflow: hidden; }
  :not(.composite-toggle-switch) > .control-boolean input {
    display: none; }
    :not(.composite-toggle-switch) > .control-boolean input ~ svg,
    :not(.composite-toggle-switch) > .control-boolean input ~ .label {
      cursor: pointer; }
    :not(.composite-toggle-switch) > .control-boolean input:disabled ~ svg,
    :not(.composite-toggle-switch) > .control-boolean input:disabled ~ .label {
      cursor: default; }
  :not(.composite-toggle-switch) > .control-boolean span.label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: inherit;
    display: inline-block;
    vertical-align: middle; }
  :not(.composite-toggle-switch) > .control-boolean.medium-scale svg {
    transform: scale(0.8);
    margin-bottom: -3px;
    vertical-align: middle;
    padding: 1px; }
  :not(.composite-toggle-switch) > .control-boolean.no-scale svg {
    transform: scale(1);
    vertical-align: middle;
    padding: 1px; }

.control-boolean.toggle-button {
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .control-boolean.toggle-button:hover {
    background-color: #e7f0ff; }
  .control-boolean.toggle-button:active:not(.secondary), .control-boolean.toggle-button.checked:not(.secondary) {
    background-color: #116dff; }
    .control-boolean.toggle-button:active:not(.secondary):hover, .control-boolean.toggle-button.checked:not(.secondary):hover {
      background-color: #5999ff; }
  .control-boolean.toggle-button[disabled] {
    background-color: #fff; }
    .control-boolean.toggle-button[disabled]:hover {
      background-color: #fff; }
  .control-boolean.toggle-button:active.secondary, .control-boolean.toggle-button.checked.secondary {
    background-color: transparent; }
    .control-boolean.toggle-button:active.secondary svg, .control-boolean.toggle-button.checked.secondary svg {
      fill: #116dff; }
    .control-boolean.toggle-button:active.secondary:hover, .control-boolean.toggle-button.checked.secondary:hover {
      background-color: #e7f0ff; }
  .control-boolean.toggle-button.small {
    width: 24px;
    height: 24px; }

.radio-control {
  cursor: pointer; }
  .radio-control input {
    display: none; }
  .radio-control span.label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.circle-preloader {
  width: 15px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  overflow: hidden;
  transform-origin: 100% 50%;
  animation: semi-rotate 1s linear infinite; }
  .wix-code-file-tree .circle-preloader {
    width: 10px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px; }
  .circle-preloader::before {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate;
    color: #80b1ff; }
  .circle-preloader::after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate;
    color: #116dff;
    opacity: 0; }
  .circle-preloader.white::before {
    color: #f0f0f0; }
  .circle-preloader.white::after {
    color: #f0f0f0; }
  .install-preloader .circle-preloader {
    position: relative; }

@keyframes inner-rotate {
  to {
    transform: rotate(115deg);
    opacity: 1; } }

@keyframes semi-rotate {
  from {
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  to {
    transform: rotate(540deg); } }

.video-container {
  border-radius: 6px;
  height: 174px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 310px;
  z-index: 0; }
  .video-container * {
    border: none;
    margin: 0;
    outline: none;
    padding: 0; }
  .video-container > video {
    object-fit: cover;
    width: 100%; }
  .video-container > .controls {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .video-container > .controls[data-state='stopped'] > footer > .actions > .play-pause, .video-container > .controls[data-state='paused'] > footer > .actions > .play-pause {
      border-left: 8px solid #ffffff;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      height: 0;
      width: 0; }
    .video-container > .controls[data-state='playing'] > .main-action,
    .video-container > .controls[data-state='playing'] > footer {
      box-shadow: 0 0 10px 5px rgba(22, 45, 61, 0.2);
      opacity: 0; }
    .video-container > .controls[data-state='playing']:hover > footer {
      opacity: 1; }
    .video-container > .controls[data-state='playing'] > footer > .actions > .play-pause {
      height: 10px;
      border-left: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      width: 9px; }
    .video-container > .controls > .main-action,
    .video-container > .controls > footer {
      transition-duration: 0.3s;
      transition-property: opacity, box-shadow;
      will-change: opacity, box-shadow; }
    .video-container > .controls > .main-action {
      background: rgba(22, 45, 61, 0.2);
      flex-grow: 1;
      position: relative; }
      .video-container > .controls > .main-action > .play-big {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        border: 2px solid #ffffff;
        border-radius: 50%;
        background: rgba(22, 45, 61, 0.2);
        height: 80px;
        width: 80px; }
        .video-container > .controls > .main-action > .play-big:hover {
          opacity: 1; }
        .video-container > .controls > .main-action > .play-big:after {
          border-left: 30px solid #ffffff;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          height: 0;
          width: 0;
          content: '';
          display: inline-block;
          left: 4px;
          position: relative; }
    .video-container > .controls > footer {
      background: rgba(22, 45, 61, 0.2);
      box-sizing: border-box;
      height: 30px;
      padding: 0 18px;
      width: 100%; }
      .video-container > .controls > footer > .progress-bar {
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        cursor: pointer;
        height: 10px;
        overflow: hidden;
        position: relative; }
        .video-container > .controls > footer > .progress-bar:hover {
          opacity: 1; }
        .video-container > .controls > footer > .progress-bar:before {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          background: #ffffff;
          content: '';
          display: block;
          height: 2px;
          width: 100%; }
        .video-container > .controls > footer > .progress-bar:hover > .progress,
        .video-container > .controls > footer > .progress-bar:hover > .progress:after {
          box-shadow: 0 0 2px 0 #116dff; }
        .video-container > .controls > footer > .progress-bar > .progress {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          transition-duration: 0.3s;
          transition-property: box-shadow;
          will-change: box-shadow;
          background: #116dff;
          height: 2px;
          width: 0;
          display: block; }
          .video-container > .controls > footer > .progress-bar > .progress:after {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            transition-duration: 0.3s;
            transition-property: box-shadow;
            will-change: box-shadow;
            background: inherit;
            border-radius: 50%;
            content: '';
            display: block;
            height: 8px;
            right: 0;
            width: 8px; }
      .video-container > .controls > footer > .actions {
        align-items: center;
        display: flex;
        height: calc( 100% - 14px); }
        .video-container > .controls > footer > .actions button {
          transition-duration: 0.3s;
          transition-property: opacity;
          will-change: opacity;
          opacity: 0.7;
          background: none; }
          .video-container > .controls > footer > .actions button:hover {
            opacity: 1; }
        .video-container > .controls > footer > .actions > .play-pause {
          margin-right: 10px; }
        .video-container > .controls > footer > .actions > .stop {
          background: #ffffff;
          height: 10px;
          width: 10px; }
        .video-container > .controls > footer > .actions > .fullscreen {
          border: 2px solid #ffffff;
          height: 10px;
          margin-left: auto;
          width: 16px; }
  .wix-store-switch-panel .video-container {
    margin-bottom: 60px; }

.background-video-patterns-control .class-v-overlay-pat-0-icon,
.background-video-patterns-control .control-v-overlay-pat-0-icon .class-v-overlay-pat-0-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-0-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-0-icon:hover .class-v-overlay-pat-0-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-0-icon,
.background-video-patterns-control .control-v-overlay-pat-0-icon .input-v-overlay-pat-0-icon:checked ~ .class-v-overlay-pat-0-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-1-icon,
.background-video-patterns-control .control-v-overlay-pat-1-icon .class-v-overlay-pat-1-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-1-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-1-icon:hover .class-v-overlay-pat-1-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-1-icon,
.background-video-patterns-control .control-v-overlay-pat-1-icon .input-v-overlay-pat-1-icon:checked ~ .class-v-overlay-pat-1-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-2-icon,
.background-video-patterns-control .control-v-overlay-pat-2-icon .class-v-overlay-pat-2-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-2-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-2-icon:hover .class-v-overlay-pat-2-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-2-icon,
.background-video-patterns-control .control-v-overlay-pat-2-icon .input-v-overlay-pat-2-icon:checked ~ .class-v-overlay-pat-2-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-3-icon,
.background-video-patterns-control .control-v-overlay-pat-3-icon .class-v-overlay-pat-3-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-3-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-3-icon:hover .class-v-overlay-pat-3-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-3-icon,
.background-video-patterns-control .control-v-overlay-pat-3-icon .input-v-overlay-pat-3-icon:checked ~ .class-v-overlay-pat-3-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-4-icon,
.background-video-patterns-control .control-v-overlay-pat-4-icon .class-v-overlay-pat-4-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-4-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-4-icon:hover .class-v-overlay-pat-4-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-4-icon,
.background-video-patterns-control .control-v-overlay-pat-4-icon .input-v-overlay-pat-4-icon:checked ~ .class-v-overlay-pat-4-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-5-icon,
.background-video-patterns-control .control-v-overlay-pat-5-icon .class-v-overlay-pat-5-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-5-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-5-icon:hover .class-v-overlay-pat-5-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-5-icon,
.background-video-patterns-control .control-v-overlay-pat-5-icon .input-v-overlay-pat-5-icon:checked ~ .class-v-overlay-pat-5-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-6-icon,
.background-video-patterns-control .control-v-overlay-pat-6-icon .class-v-overlay-pat-6-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-6-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-6-icon:hover .class-v-overlay-pat-6-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-6-icon,
.background-video-patterns-control .control-v-overlay-pat-6-icon .input-v-overlay-pat-6-icon:checked ~ .class-v-overlay-pat-6-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .class-v-overlay-pat-7-icon,
.background-video-patterns-control .control-v-overlay-pat-7-icon .class-v-overlay-pat-7-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-7-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-7-icon:hover .class-v-overlay-pat-7-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

.background-video-patterns-control .selected .class-v-overlay-pat-7-icon,
.background-video-patterns-control .control-v-overlay-pat-7-icon .input-v-overlay-pat-7-icon:checked ~ .class-v-overlay-pat-7-icon {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

.background-video-patterns-control .control-buttons-group {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 24px;
  text-align: center; }
  .background-video-patterns-control .control-buttons-group input + span {
    max-width: 70px;
    max-height: 34px;
    line-height: 34px; }

.control-dock {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0;
  position: relative; }
  .control-dock:hover .info-icon {
    opacity: 1; }
  .control-dock .info-icon {
    float: right;
    opacity: 0; }
  .control-dock .dock-label {
    font-size: 14px;
    left: 25px;
    margin-bottom: 20px;
    display: block;
    color: #3b4057;
    margin-top: 20px; }
  .control-dock .dock-corners-wrapper {
    position: relative;
    margin: 0 auto;
    width: 185px; }
  .control-dock label {
    font-size: 0;
    margin: 0;
    display: inline-block; }
    .control-dock label input {
      display: none; }
      .control-dock label input:checked + .top,
      .control-dock label input:checked + .right,
      .control-dock label input:checked + .bottom,
      .control-dock label input:checked + .left {
        border-color: #116dff;
        background: #d6e6fe; }
      .control-dock label input:checked + .middle {
        background: #116dff; }
    .control-dock label .square {
      cursor: pointer;
      box-sizing: border-box;
      background: #e7f0ff;
      margin: 0;
      padding: 0;
      width: 61px;
      height: 43px;
      display: block; }
      .control-dock label .square.top {
        margin-bottom: 1px;
        margin-right: 1px;
        border-top: 12px solid #a8caff; }
        .control-dock label .square.top:hover {
          border-top-color: #5999ff; }
        .control-dock label .square.top.left {
          border-top-left-radius: 5px; }
        .control-dock label .square.top.right {
          border-top-right-radius: 5px; }
      .control-dock label .square.left {
        border-left: 12px solid #a8caff;
        margin-left: 0; }
        .control-dock label .square.left:hover {
          border-left-color: #5999ff; }
      .control-dock label .square.middle {
        margin-left: 1px;
        margin-right: 1px; }
        .control-dock label .square.middle:hover {
          background: #5999ff; }
      .control-dock label .square.right {
        border-right: 12px solid #a8caff;
        margin-right: 0; }
        .control-dock label .square.right:hover {
          border-right-color: #5999ff; }
      .control-dock label .square.bottom {
        margin-top: 1px;
        margin-right: 1px;
        border-bottom: 12px solid #a8caff; }
        .control-dock label .square.bottom:hover {
          border-bottom-color: #5999ff; }
        .control-dock label .square.bottom.left {
          border-bottom-left-radius: 5px; }
        .control-dock label .square.bottom.right {
          border-bottom-right-radius: 5px;
          margin-right: 0; }
    .control-dock label.disabled .square {
      background: #f0f0f0;
      border-color: #d5d5d5; }
      .control-dock label.disabled .square:hover {
        background: #f0f0f0;
        border-color: #d5d5d5; }
      .control-dock label.disabled .square.middle {
        background: #ffffff; }
        .control-dock label.disabled .square.middle:hover {
          background: #ffffff; }
  .control-dock .dashed {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none; }
    .control-dock .dashed.horizontal {
      border-top: 1px dotted #80b1ff;
      border-bottom: 1px dotted #80b1ff;
      height: 45px;
      top: 43px;
      left: 12px;
      right: 12px; }
    .control-dock .dashed.vertical {
      border-left: 1px dotted #80b1ff;
      border-right: 1px dotted #80b1ff;
      width: 63px;
      top: 12px;
      bottom: 12px;
      left: 61px; }

.drag-area-control {
  min-height: 100px; }
  .drag-area-control.with-border {
    border-radius: 14px;
    border: 2px dashed #d6e6fe;
    margin: 12px; }
    .fonts-upload-panel .drag-area-control.with-border {
      min-height: 299px; }
    .drag-area-control.with-border.dragging {
      border: 2px dashed #80b1ff; }
  .fonts-upload-panel .drag-area-control {
    min-height: 327px; }

.splitter {
  display: flex; }

.splitter-divider {
  z-index: 1500; }
  .splitter-divider-horizontal {
    width: 100%;
    height: 10px;
    margin: -5px 0;
    cursor: row-resize; }
  .splitter-divider-vertical {
    width: 10px;
    height: 100%;
    margin: 0 -5px;
    cursor: col-resize;
    color: #b6c1cd; }

.ide-splitter .splitter-divider-horizontal {
  height: 18px;
  margin: -6px 0 -12px 0; }

.splitter-divider-handle {
  background-color: #e7f0ff;
  border: 1px solid #dfe5eb;
  cursor: pointer;
  height: 52px;
  position: relative;
  text-align: center;
  top: calc(50% - (52px / 2));
  width: 11px; }
  .splitter-divider-handle.open {
    border-bottom-left-radius: 7px;
    border-right: none;
    border-top-left-radius: 7px;
    left: calc(-50% - 3px); }
  .splitter-divider-handle.closed {
    border-bottom-right-radius: 7px;
    border-left: none;
    border-top-right-radius: 7px;
    left: 50%; }
  .splitter-divider-handle .symbol {
    height: 12px;
    position: relative;
    top: 19px;
    width: 5px; }
    .splitter-divider-handle .symbol path {
      fill: #000624; }
  .splitter-divider-handle:hover .symbol path {
    fill: #116dff; }

.paginated-thumbnails {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  padding-bottom: 36px; }
  .paginated-thumbnails .paginated-thumbnails-content {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory; }
    .paginated-thumbnails .paginated-thumbnails-content .composite-thumbnail-wrapper {
      box-sizing: border-box;
      flex-shrink: 0;
      scroll-snap-align: start;
      width: 100%; }
  .paginated-thumbnails .paginated-tumbnails-pager {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 18px;
    text-align: center; }
    .paginated-thumbnails .paginated-tumbnails-pager li {
      display: inline-block;
      margin: 0 6px; }
    .paginated-thumbnails .paginated-tumbnails-pager button {
      border-radius: 50%;
      overflow: hidden;
      width: 12px;
      height: 12px;
      background: #dfe5eb; }
      .paginated-thumbnails .paginated-tumbnails-pager button.selected {
        background: #116dff; }
      .paginated-thumbnails .paginated-tumbnails-pager button span {
        visibility: hidden; }
  .paginated-thumbnails .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .paginated-thumbnails .hide-scrollbar::-webkit-scrollbar {
      display: none; }

.control-accordion {
  position: relative; }
  .control-accordion > .accordion-header {
    background-color: #fff; }
    .control-accordion > .accordion-header:hover {
      background-color: #e7f0ff; }
    .control-accordion > .accordion-header > .selected-indicator {
      margin-left: 12px;
      vertical-align: middle; }
    .control-accordion > .accordion-header > .control-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      font-weight: 500;
      text-align: left;
      padding: 19px 0 18px 0;
      display: inline-block;
      line-height: 13px; }
    .control-accordion > .accordion-header > .accordion-icon {
      margin: 19px 15px 18px 24px; }
      .control-accordion > .accordion-header > .accordion-icon.open {
        margin: 22px 12px 20px 24px; }

.light-accordion .control-accordion > .accordion-header:hover {
  background-color: #fff; }
  .light-accordion .control-accordion > .accordion-header:hover > .control-label {
    color: #116dff; }
  .light-accordion .control-accordion > .accordion-header:hover .symbol-accordionClose,
  .light-accordion .control-accordion > .accordion-header:hover .symbol-accordionOpen {
    fill: #116dff; }

.light-accordion .control-accordion > .accordion-header > .control-label {
  color: #3b4057; }

.light-accordion .control-accordion > .accordion-header .symbol-accordionClose,
.light-accordion .control-accordion > .accordion-header .symbol-accordionOpen {
  fill: #3b4057; }

.control-text-input {
  position: relative;
  text-align: left;
  display: inline-block;
  width: 100%; }
  .control-text-input > .text-input-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #3b4057;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px;
    padding: 7px 30px 6px 12px; }
    .is-disabled.control-text-input > .text-input-label {
      color: #939393; }
    .developer-toolbar-content .control-text-input > .text-input-label {
      padding: 10px 5px 10px 1px; }
    .wix-code-find-replace .control-text-input > .text-input-label {
      padding-top: 6px; }
  .success.control-text-input > .input-container .input :not(:focus), .error.control-text-input > .input-container .input :not(:focus), .warning.control-text-input > .input-container .input :not(:focus) {
    width: calc(100% - 43px);
    padding-right: 0; }
  .instant-error.control-text-input > .input-container .input:focus {
    border: 1px solid #e62214; }
  .color-format .control-text-input > .input-container .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding-left: 62px;
    height: 24px;
    line-height: 24px;
    width: 100%; }
  .developer-toolbar-content .control-text-input > .input-container .input {
    width: 100%;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #174165;
    font-weight: 700;
    padding-left: 8px; }
  .wix-code-find-replace .control-text-input > .input-container .input {
    height: 22px;
    font-size: 14px;
    color: #3b4057;
    border: 1px solid #5999ff;
    box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    .wix-code-find-replace:hover .control-text-input > .input-container .input {
      background-color: transparent;
      cursor: text; }
    .wix-code-find-replace .control-text-input > .input-container .input:hover {
      background-color: transparent;
      cursor: text; }
  .control-text-input > .input-container .developer-toolbar-content {
    flex: 1; }
  .color-format .control-text-input > .input-container {
    display: block; }
  .wix-code-find-replace .control-text-input > .input-container {
    width: 190px; }
  .control-text-input .validation-icon {
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
    visibility: hidden;
    font-size: 0; }
    .error.control-text-input:not(.focused) .validation-icon.validation-icon-error {
      visibility: visible; }
    .success.control-text-input:not(.focused) .validation-icon.validation-icon-success {
      visibility: visible; }
    .warning.control-text-input:not(.focused) .validation-icon.validation-icon-warning {
      visibility: visible; }
    .color-format .control-text-input .validation-icon {
      top: 0; }
    .wix-code-find-replace .control-text-input .validation-icon {
      display: none; }
  .control-text-input .validation-icon-wrapper {
    position: absolute;
    top: -3px;
    right: 2px; }
    .developer-toolbar-content .control-text-input .validation-icon-wrapper {
      position: relative;
      top: -20px;
      left: 36px;
      right: unset; }
    .has-label.control-text-input .validation-icon-wrapper {
      top: 37px;
      right: 25px; }
      .has-label.control-text-input .validation-icon-wrapper .validation-icon {
        top: 0;
        right: -11px; }
  .color-format .control-text-input:before {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    content: '#';
    position: absolute;
    left: 55px;
    top: 4px; }
  .developer-toolbar-content .control-text-input {
    display: flex;
    align-items: center; }
  .wix-code-file-tree .control-text-input > .input-container > .input {
    height: 22px;
    font-size: 14px; }
  .wix-code-pages-tree .control-text-input > .input-container > .input {
    height: 22px;
    font-size: 14px; }

.developer-toolbar-content .control-text-input.focused > .input-container .input {
  font-weight: 400; }

.selective-text-input {
  position: relative;
  display: flex;
  align-items: center; }
  .selective-text-input .textInput {
    margin-left: 16px;
    margin-right: 80px;
    padding: 0;
    width: auto; }
    .selective-text-input .textInput > input {
      font-size: 16px;
      line-height: 16px; }
  .selective-text-input .control-mandatory {
    display: flex;
    align-items: center;
    margin-left: 11px; }

.input-slider {
  position: relative; }
  .input-slider > label {
    display: none; }
  .input-slider.has-label > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-bottom: 5px;
    color: #3b4057;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-slider .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .input-slider .sliderArea {
    position: relative; }
  .input-slider .slider {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    .input-slider .slider .line {
      position: absolute;
      top: 17px;
      left: 0;
      height: 6px;
      width: 100%;
      background-color: #d6e6fe;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    .input-slider .slider .knobContainer {
      margin-right: 16px;
      position: relative; }
    .input-slider .slider .coloredLine {
      position: absolute;
      top: 14px;
      left: 0;
      height: 6px;
      background-color: #116dff;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #116dff;
      will-change: width;
      cursor: pointer; }
    .input-slider .slider .sliderKnob {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      .input-slider .slider .sliderKnob:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #116dff;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      .input-slider .slider .sliderKnob:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  .input-slider .input-stepper {
    position: absolute;
    right: 0; }
    .input-slider .input-stepper .input {
      text-align: right; }
  .input-slider:hover .input-stepper,
  .input-slider:hover .input-stepper > :not(.units-container) {
    background-color: #e7f0ff; }
  .input-slider:hover .info-icon {
    display: inline-block; }
  .input-slider.disabled {
    opacity: 0.5; }
  .color-picker-input-with-opacity .input-slider {
    margin-right: 40px; }

.input-slider-opacity {
  position: relative; }
  .input-slider-opacity > label {
    display: none; }
  .input-slider-opacity.has-label > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-bottom: 5px;
    color: #3b4057;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-slider-opacity .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .input-slider-opacity .sliderArea {
    position: relative; }
  .input-slider-opacity .slider {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    .input-slider-opacity .slider .empty-line {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
      background-position: 1px 1px, 5px 5px;
      background-size: 8px 8px;
      cursor: pointer; }
    .input-slider-opacity .slider .line {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    .input-slider-opacity .slider .knobContainer {
      margin-right: 16px;
      position: relative; }
    .input-slider-opacity .slider .sliderKnob {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      .input-slider-opacity .slider .sliderKnob:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #116dff;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      .input-slider-opacity .slider .sliderKnob:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  .input-slider-opacity .input-stepper {
    position: absolute;
    right: 0; }
    .input-slider-opacity .input-stepper .input {
      text-align: right; }
  .input-slider-opacity:hover .input-stepper,
  .input-slider-opacity:hover .input-stepper > :not(.units-container) {
    background-color: #e7f0ff; }
  .input-slider-opacity:hover .info-icon {
    display: inline-block; }
  .input-slider-opacity.disabled {
    opacity: 0.5; }
  .color-picker-input-with-opacity .input-slider-opacity {
    margin-right: 40px; }

.input-stepper {
  border-radius: 4px;
  border: 1px solid transparent;
  width: 56px;
  padding: 0 6px;
  position: relative;
  text-align: left;
  display: inline-block; }
  .input-stepper.no-padding {
    padding: 0; }
  .input-stepper:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    border: 1px solid transparent;
    border-radius: 4px;
    pointer-events: none; }
    .edited.input-stepper:after {
      border-color: #9cccf6;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    .bad-value.input-stepper:after {
      border-color: #f1726b; }
  .input-stepper.disabled {
    opacity: 0.5; }
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited),
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .input,
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .units-container > .units,
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .units-container, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover,
  .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .input,
  .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .units-container > .units,
  .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .units-container {
    background-color: #d6e6fe;
    color: #3b4057;
    cursor: pointer; }
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited):after, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover:after {
    border-color: #d6e6fe; }
  .input-stepper:not(.disabled):not(.bad-value):not(.edited) > .input:focus {
    background-color: #d6e6fe;
    color: #3b4057;
    cursor: pointer; }
  .input-stepper.edited {
    background-color: transparent !important; }
    .stepper-with-svg .input-stepper.edited > .input {
      width: 100%; }
  .input-stepper.small {
    width: 45px;
    padding: 2px 2px 2px 0; }
  .input-stepper.has-units.with-units-dd {
    width: 64px;
    padding-right: 0; }
    .input-stepper.has-units.with-units-dd > .input {
      width: 28px; }
  .input-stepper > .input {
    border: none;
    outline: none;
    text-align: center;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    padding: 0 7px;
    width: calc(100% - 14px);
    display: inline-block;
    color: #000624; }
    .disabled.input-stepper > .input {
      color: #7a7a7a;
      background: transparent; }
    .has-units.input-stepper > .input {
      width: 70%;
      padding: 0;
      text-align: right;
      display: inline-block; }
    .small.input-stepper > .input {
      font-size: 12px;
      height: 18px;
      line-height: 18px; }
    .edited.input-stepper > .input {
      background-color: transparent !important;
      cursor: text !important; }
    .input-stepper:not(.edited) > .input {
      cursor: default; }
    .input-stepper > .input::selection {
      background-color: #e7f0ff; }
      .input-stepper:not(.edited) > .input::selection {
        background-color: transparent; }
    .color-format .input-stepper > .input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 24px;
      line-height: 24px; }
  .input-stepper:edited > .units-container {
    background-color: transparent !important; }
  .edited.input-stepper:not(.with-units-dd) > .units-container {
    visibility: hidden; }
  .input-stepper > .units-container > .units {
    line-height: 36px;
    height: 36px;
    width: 1%;
    display: inline-block;
    white-space: nowrap;
    padding: 0 7px 0 1px; }
    .disabled.input-stepper > .units-container > .units {
      color: #7a7a7a; }
    .small.input-stepper > .units-container > .units {
      height: 18px;
      line-height: 18px;
      font-size: 12px; }
    .color-format .input-stepper > .units-container > .units {
      height: 24px;
      line-height: 24px; }
  .control-angle .input-stepper {
    position: absolute;
    top: 52px;
    left: 138px;
    width: 40px; }
    .control-angle .input-stepper > .units,
    .control-angle .input-stepper > .input {
      height: 28px;
      line-height: 28px; }
    .control-angle .input-stepper.has-units > .input {
      width: 78%; }
  .control-angle > .circle:active + .input-stepper,
  .control-angle > .circle:active + .input-stepper > .units,
  .control-angle > .circle:active + .input-stepper > .stepper,
  .control-angle:hover .input-stepper,
  .control-angle:hover .input-stepper > .units,
  .control-angle:hover .input-stepper > .stepper {
    background-color: #edf7ff; }
  .color-format .input-stepper {
    width: 100%;
    box-sizing: border-box; }
  .stepper-with-svg .input-stepper {
    text-align: left;
    padding-left: 0;
    border-radius: 4px;
    margin-top: 5px;
    overflow: hidden;
    margin-left: 12px;
    padding-right: 11px; }
    .stepper-with-svg .input-stepper:after {
      border-radius: 4px; }
    .stepper-with-svg .input-stepper > .units-container > .units,
    .stepper-with-svg .input-stepper > .input {
      height: 17px;
      line-height: 17px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .stepper-with-svg .input-stepper > .units-container > .units {
      height: 17px; }
    .stepper-with-svg .input-stepper > .input {
      height: 18px;
      color: #384f68;
      font-size: 12px;
      padding-right: 2px;
      padding-left: 1px;
      line-height: 14px; }

.stepper-with-svg {
  margin-right: 12px;
  font-size: 12px; }
  .stepper-with-svg .symbol {
    fill: #384f68;
    position: relative;
    bottom: 3px;
    margin-left: 2px; }
    .stepper-with-svg .symbol.safari {
      top: 3px;
      bottom: 0; }
    .stepper-with-svg .symbol:hover {
      fill: #2b81cb;
      cursor: pointer; }
  .stepper-with-svg .edited + .symbol {
    fill: #bcbcbc;
    pointer-events: none;
    cursor: default; }

.property-form .errorMessage {
  color: #f00; }

.property-form span.label {
  display: block;
  margin-bottom: 5px; }

.menu-panel {
  min-width: 250px;
  min-height: 300px; }
  .menu-panel header {
    font-size: 16px;
    color: #000000;
    position: relative;
    margin-bottom: 10px; }
    .menu-panel header a {
      position: absolute;
      top: -10px;
      right: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      font-size: 16px;
      color: #000;
      text-decoration: none; }
  .menu-panel ul {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden; }
    .menu-panel ul li {
      color: #333;
      border-bottom: 1px solid #fff;
      padding: 5px 5px;
      cursor: pointer; }
      .menu-panel ul li:hover {
        background-color: #b2f3ff; }

.dropdown {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  position: relative; }
  .dropdown.font-family-dd.toolbar-mode, .dropdown.text-style-dd.toolbar-mode {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 5px;
    padding-right: 0;
    min-width: 140px; }
  .dropdown.context-menu {
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 50%;
    line-height: 24px;
    text-align: center; }
    .dropdown.context-menu.wix-code-pages-tree-dd, .dropdown.context-menu.wix-code-file-tree-dd {
      border: none;
      width: 16px;
      height: 16px;
      line-height: 16px; }
  .dropdown.units-dd {
    display: inline-block;
    padding-left: 5px; }
  .dropdown:focus,
  .dropdown > .dd:focus {
    outline: none; }
  .dropdown > .dd,
  .dropdown > .dd > .expand.arrow {
    margin: 0;
    padding: 0; }
  .dropdown > .dropdown-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 3px 0;
    padding: 0;
    color: #3b4057;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 20px); }
    .select.disabled.dropdown > .dropdown-label {
      color: #939393; }
  .dropdown > .dd {
    position: relative;
    cursor: pointer; }
    .disabled.dropdown > .dd {
      cursor: default; }
    .context-menu.dropdown > .dd {
      height: 100%; }
    .dropdown > .dd > .selected-container {
      position: relative;
      box-sizing: border-box;
      color: #000624;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      padding: 0 30px 0 0;
      margin: 0; }
      .input-slider.dropdown:hover > .dd > .selected-container {
        color: #116dff; }
      .text-link-dd.dropdown > .dd > .selected-container {
        height: 32px;
        line-height: 32px;
        min-height: 32px;
        width: 32px;
        padding-right: 0; }
      .disabled.dropdown > .dd > .selected-container {
        color: #7a7a7a; }
      .context-menu.dropdown > .dd > .selected-container {
        height: 30px;
        line-height: 30px;
        min-height: 30px; }
      .units-dd.dropdown > .dd > .selected-container {
        padding-right: 12px;
        font-size: 14px;
        line-height: 14px;
        height: 14px;
        min-height: 14px; }
      .wix-code-pages-tree-dd.dropdown > .dd > .selected-container {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      .wix-code-file-tree-dd.dropdown > .dd > .selected-container {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      .dropdown > .dd > .selected-container > .symbol-more > .notification {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        width: 20px;
        height: 20px;
        top: -10px;
        font-size: 10px;
        border-radius: 10px;
        line-height: 18px;
        border: 1px solid #fff;
        position: absolute;
        left: 10px;
        background-color: #ffc233;
        color: #fff;
        box-sizing: border-box; }
      .dropdown > .dd > .selected-container > .symbol-more.shrink > .notification {
        width: 14px;
        height: 14px;
        top: -7px;
        font-size: 7px;
        border-radius: 7px;
        line-height: 12px; }
    .dropdown > .dd > .expand.arrow {
      border: 2px solid #116dff;
      border-radius: 50%;
      content: '';
      display: block;
      height: 22px;
      opacity: 0.6;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 22px; }
      .select.disabled.dropdown > .dd > .expand.arrow {
        border-color: #bcbcbc; }
        .select.disabled.dropdown > .dd > .expand.arrow:hover {
          background-color: transparent; }
      .font-family-dd.toolbar-mode.dropdown > .dd > .expand.arrow, .units-dd.dropdown > .dd > .expand.arrow {
        border: none;
        width: 9px;
        opacity: 1; }
        .font-family-dd.toolbar-mode.dropdown > .dd > .expand.arrow:hover, .units-dd.dropdown > .dd > .expand.arrow:hover {
          background-color: transparent; }
      .input-stepper.dropdown:hover > .dd > .expand.arrow {
        opacity: 1; }
      .input-slider:hover .dropdown > .dd > .expand.arrow {
        opacity: 1; }
      .units-dd.dropdown > .dd > .expand.arrow {
        opacity: 0; }
      .toolbar-mode.font-family-dd.dropdown > .dd > .expand.arrow {
        right: 8px; }

.combo-box > .combo-box-input {
  border: none;
  width: 100%;
  padding: 0; }
  .font-family-dd.toolbar-mode .combo-box > .combo-box-input {
    font-size: 16px; }
  .combo-box > .combo-box-input:focus {
    outline: none; }

.font-family-dd.toolbar-mode .combo-box.selected {
  width: 130px; }

.dropdown:not(.disabled):hover .combo-box {
  color: #116dff; }

.dropdown.combobox .combo-box {
  overflow: inherit;
  text-overflow: inherit; }

.dropdown-options {
  left: 0;
  position: fixed;
  top: 0; }
  .dropdown-options.expanded {
    bottom: 0;
    right: 0;
    z-index: 5998 !important; }
  .dropdown-options > .options {
    display: none;
    position: fixed;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
    flex-direction: column;
    max-height: 100%; }
    .expanded.dropdown-options > .options {
      display: flex !important; }
      .expanded.dropdown-options > .options.scroll.up > .arrow.top,
      .expanded.dropdown-options > .options.scroll.down > .arrow.bottom {
        visibility: visible; }
    .select.dropdown-options > .options, .combobox.dropdown-options > .options {
      overflow: hidden; }
    .context-menu.dropdown-options > .options.tag-triangle.tag-left.tag-side-top:before, .context-menu.dropdown-options > .options.tag-triangle.tag-right.tag-side-top:before {
      top: 17px; }
    .context-menu.dropdown-options > .options.tag-triangle.tag-left.tag-side-bottom:before, .context-menu.dropdown-options > .options.tag-triangle.tag-right.tag-side-bottom:before {
      bottom: 17px; }
    .dropdown-options > .options,
    .dropdown-options > .options > .top.arrow,
    .dropdown-options > .options > .list,
    .dropdown-options > .options > .options-footer,
    .dropdown-options > .options > .bottom.arrow,
    .dropdown-options > .options > .mouse-events-blocker {
      margin: 0;
      padding: 0; }
    .dropdown-options > .options:focus {
      outline: none; }
    .dropdown-options > .options > .list {
      display: block;
      max-height: 100%;
      overflow: hidden; }
      .context-menu.dropdown-options > .options > .list {
        box-sizing: border-box;
        padding: 10px 0; }
      .combobox.dropdown-options > .options > .list {
        overflow-y: auto; }
    .dropdown-options > .options > .arrow {
      background-color: rgba(255, 255, 255, 0.93);
      display: block;
      font-size: 8px;
      height: 36px;
      left: 0;
      opacity: 0.6;
      position: absolute;
      width: 100%;
      visibility: hidden;
      z-index: 1; }
      .dropdown-options > .options > .arrow.top {
        top: 0;
        transform: rotateX(180deg); }
      .dropdown-options > .options > .arrow.bottom {
        bottom: 0; }
    .dropdown-options > .options > .options-footer {
      flex-shrink: 0; }
      .combobox.dropdown-options > .options > .options-footer {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        background-color: #fff;
        box-shadow: 0 -1px 23px 0 #fff;
        color: #116dff;
        line-height: 35px;
        height: 35px;
        padding: 0 24px;
        z-index: 0; }
    .dropdown-options > .options > .mouse-events-blocker {
      display: none; }
      .dropdown-options > .options > .mouse-events-blocker.visible {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0; }

.dropdown-option {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  position: relative; }
  .dropdown-option.hovered:not(.separator):not(.disabled) {
    background-color: #e7f0ff; }
  .dropdown-option.disabled {
    cursor: default; }
    .dropdown-option.disabled .wix-code-context-menu-item {
      color: #939393; }
    .dropdown-option.disabled path {
      fill: #939393; }
  .dropdown-option.level1 {
    padding-left: 30px; }
  .dropdown-option.level2 {
    padding-left: 40px; }
  .dropdown-option.level3 {
    padding-left: 50px; }
  .dropdown-option.separator {
    height: 1px; }
  .dropdown-option.font-option {
    height: 24px;
    padding: 0 14px; }
  .dropdown-option.units-dropdown {
    text-align: center;
    padding: 0; }
  .dropdown-option.link-dd-option {
    padding: 4px 11px; }
  .optgroup-list .dropdown-option {
    padding: 0 40px; }
    .dropdown-options.combobox .optgroup-list .dropdown-option {
      padding: 0 24px; }
  .dropdown-options.select .dropdown-option.selected,
  .dropdown-options.combobox .dropdown-option.selected {
    background-color: #116dff;
    color: #fff; }
  .units-dd .dropdown-option {
    padding: 0 10px; }

.dropdown-optgroup .optgroup-label {
  background-color: #f5f5f5;
  color: #3b4057;
  font-size: 14px;
  font-weight: 300;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  margin: 0; }
  .combobox .dropdown-optgroup .optgroup-label {
    height: 36px;
    padding: 0 24px;
    background-color: #f7f8f8;
    border-top: 1px solid #dfe5eb;
    border-bottom: 1px solid #dfe5eb;
    cursor: default; }

.dropdown-optgroup > .optgroup-list {
  padding: 0; }

.dropdown.select .dropdown-selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.icons-menu-button-wrapper {
  position: relative;
  height: 34px;
  width: 34px;
  display: inline-block;
  cursor: pointer; }
  .icons-menu-button-wrapper:hover, .icons-menu-button-wrapper.active {
    background: #eaf7ff;
    border-radius: 6px; }
  .icons-menu-button-wrapper .small-indicator {
    position: absolute;
    box-sizing: border-box;
    width: 4px;
    height: 4px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 2px solid #116dff;
    border-right: 2px solid #116dff;
    bottom: 3px;
    right: 3px; }

.dropdown.icons-menu > .dd > .selected-container {
  padding: 0; }

.dropdown-options.icons-menu .options {
  padding: 12px 0; }
  .dropdown-options.icons-menu .options.tag-triangle.tag-top::before, .dropdown-options.icons-menu .options.tag-triangle.tag-bottom::before {
    left: calc(50% - 5px); }
  .dropdown-options.icons-menu .options .dropdown-option {
    padding: 0 18px; }
    .dropdown-options.icons-menu .options .dropdown-option .symbol-wrapper {
      height: 100%;
      display: inline-block; }
      .dropdown-options.icons-menu .options .dropdown-option .symbol-wrapper .symbol {
        padding: 0 10px 0 0;
        margin-bottom: -2px; }

.control-checkable-text-input {
  margin: 0 12px 0 30px; }
  .control-checkable-text-input .checkable-text-input {
    align-items: center;
    display: flex;
    height: 59px; }
    .control-checkable-text-input .checkable-text-input .control-text-input,
    .settings-panel .control-checkable-text-input .checkable-text-input .control-text-input {
      margin: 0 0 0 12px; }
  .control-checkable-text-input + hr.divider-long {
    margin: 0 24px 0 30px; }

.control-numeric-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; }
  .control-numeric-input .label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    padding: 18px 0 16px 24px;
    vertical-align: middle;
    color: #3b4057;
    font-size: 14px;
    line-height: 1.8;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .control-numeric-input .input-stepper {
    margin-right: 6px; }

.domain-input-container {
  position: relative; }
  .domain-input-container .domain-input-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 18px; }
  .domain-input-container.with-edit-buttons .domain-input-loader {
    right: 78px; }
  .domain-input-container .input-validation-icon {
    visibility: hidden; }
  .domain-input-container .input-editable-url {
    min-width: 100%; }
  .domain-input-container .control-button.btn-edit {
    width: auto;
    min-width: 66px;
    max-width: 106px;
    padding: 0 12px; }

.control-radio-list {
  padding: 10px 20px 5px 24px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  .control-radio-list .title {
    color: #3b4057;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 3px;
    text-align: left;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .control-radio-list label {
    display: block;
    padding: 15px 0 15px 4px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #dfe5eb; }
    .control-radio-list label div:nth-of-type(1) {
      margin: 0 0 0 14px;
      vertical-align: middle;
      box-sizing: border-box;
      display: inline-block; }
      .control-radio-list label div:nth-of-type(1) div {
        display: table-cell;
        width: 54px;
        height: 42px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #e5e5e5;
        border-radius: 5px; }
        .control-radio-list label div:nth-of-type(1) div span {
          font-size: 30px; }
    .control-radio-list label:first-child {
      border-top: none; }
    .control-radio-list label:last-child {
      border-bottom-color: transparent; }
    .control-radio-list label.highlight-on-hover:hover span:nth-of-type(2) {
      color: #5c99e8; }
    .control-radio-list label.highlight-on-hover:hover .editor-mode-option-symbol svg g {
      fill: #5c99e8; }
  .control-radio-list span:nth-of-type(2) {
    display: inline-block;
    color: #2a3742;
    margin-left: 14px;
    vertical-align: middle; }
  .control-radio-list .info-icon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  .control-radio-list:hover .info-icon {
    display: inline-block; }
  .control-radio-list input {
    display: none; }
    .control-radio-list input + span {
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      width: 12px;
      box-shadow: 0 0 0 1px #5c99e8;
      border: solid 5px #fff;
      transition-property: background-color, border-width;
      transition-duration: 0.15s;
      box-sizing: border-box;
      vertical-align: middle; }
    .control-radio-list input:checked + span {
      background-color: #5c99e8;
      border-width: 1px; }

.control-checkbox-with-button {
  white-space: nowrap; }
  .control-checkbox-with-button .controls-wrapper {
    height: 24px;
    display: flex;
    align-items: center; }
    .control-checkbox-with-button .controls-wrapper .checkbox-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 24px;
      line-height: 24px;
      display: none;
      border-radius: 12px;
      margin-left: auto;
      overflow: visible; }
  .control-checkbox-with-button:hover {
    background-color: #e7f0ff; }
    .control-checkbox-with-button:hover .checkbox-button {
      display: inline-block;
      opacity: 1;
      transition: 0.2s opacity; }
  .control-checkbox-with-button:not(:hover) .checkbox-button {
    opacity: 0;
    width: 0;
    padding: 0; }

.color-scheme-picker .control-switch.invert-color-toggle {
  padding-top: 10px; }

.color-scheme-picker .control-switch .label {
  align-self: baseline; }

.action-container {
  position: relative;
  height: 72px;
  cursor: pointer; }
  .action-container > .action-symbol {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #116dff;
    border-radius: 50%;
    margin: auto 26px;
    width: 24px;
    height: 24px; }
    .action-container:hover > .action-symbol {
      background: #5999ff; }
    .symbol-right.action-container > .action-symbol {
      right: 0; }
    .action-container > .action-symbol > .symbol {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-right: 1px; }
      .action-container > .action-symbol > .symbol > path {
        fill: #fff; }
  .action-container > .action-label {
    position: absolute;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 24px;
    height: 20px;
    color: #116dff;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px; }
    .action-container:hover > .action-label {
      text-decoration: underline;
      color: #5999ff; }
    .symbol-left.action-container > .action-label {
      left: 34px;
      right: 0; }
    .symbol-right.action-container > .action-label {
      right: 45px;
      left: 0; }

.media-button > .media-button-filename-text {
  text-align: center;
  margin-top: 16px; }
  .media-button > .media-button-filename-text > .wrapper > span {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000624;
    vertical-align: middle;
    display: inline-block;
    max-width: calc(100% - 48px); }

.image-preview {
  background-color: #e7f0ff;
  box-sizing: border-box;
  height: 155px;
  position: relative;
  text-align: center;
  width: 288px; }
  .image-preview .image-wrapper {
    font-size: 0;
    height: 100%; }
    .image-preview .image-wrapper > .image {
      width: 100%;
      height: 100%; }
  .image-preview .button-wrapper {
    background-color: rgba(177, 221, 248, 0.4);
    bottom: 0;
    display: block;
    height: 54px;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%; }
    .image-preview .button-wrapper button.action {
      background-color: #fff;
      border: 0;
      border-radius: 17px;
      color: #116dff;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      margin-top: 11px !important;
      padding: 0;
      outline: none;
      text-overflow: clip;
      vertical-align: top; }
      .image-preview .button-wrapper button.action.has-icon.has-label span {
        padding: 0 34px; }
      .image-preview .button-wrapper button.action.has-icon:not(.has-label) {
        padding: 0 9px;
        margin-left: 0;
        margin-right: 10px; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(1) {
        min-width: 167px;
        max-width: 204px; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 24px; }
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2),
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          width: 108px; }
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          margin-left: 0; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(3) {
        width: 152px;
        margin-left: 24px;
        margin-right: 12px;
        float: left; }
      .image-preview .button-wrapper button.action:nth-of-type(2):nth-last-of-type(2) {
        float: right;
        margin-right: 24px; }
      .image-preview .button-wrapper button.action:nth-of-type(3):nth-last-of-type(1) {
        float: right;
        margin-right: 12px; }
      .image-preview .button-wrapper button.action:hover {
        background-color: #e7f0ff; }
      .image-preview .button-wrapper button.action:active {
        background-color: #fff; }
      .image-preview .button-wrapper button.action > .button-content {
        position: relative; }
      .image-preview .button-wrapper button.action svg {
        width: 14px;
        height: 32px; }
        .image-preview .button-wrapper button.action svg.symbol-with-label {
          top: 0;
          left: 12px;
          position: absolute; }
        .image-preview .button-wrapper button.action svg * {
          fill: #116dff; }
      .image-preview .button-wrapper button.action span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #116dff;
        line-height: 17px;
        text-align: left;
        left: 3px;
        position: relative; }
  .image-preview .placeholder-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
  .image-preview .placeholder-wrapper.symbol-in-the-middle svg {
    transform: translate(-50%, -50%); }
  .image-preview .placeholder-wrapper .corner-borders:before, .image-preview .placeholder-wrapper .corner-borders:after {
    border: solid #5c99e8;
    content: '';
    height: 10px;
    margin: 25px;
    position: absolute;
    width: 10px; }
  .image-preview .placeholder-wrapper .corner-borders.top:before {
    border-width: 1px 0 0 1px;
    left: 0;
    top: 0; }
  .image-preview .placeholder-wrapper .corner-borders.top:after {
    border-width: 1px 1px 0 0;
    right: 0;
    top: 0; }
  .image-preview .placeholder-wrapper .corner-borders.bottom:before {
    border-width: 0 0 1px 1px;
    bottom: 0;
    left: 0; }
  .image-preview .placeholder-wrapper .corner-borders.bottom:after {
    border-width: 0 1px 1px 0;
    bottom: 0;
    right: 0; }
  .image-preview .arrow-button {
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.43);
    cursor: pointer;
    height: 24px;
    position: absolute;
    top: 50%;
    width: 24px; }
    .image-preview .arrow-button:after {
      border-left: 2px solid #0199ef;
      border-top: 2px solid #0199ef;
      content: '';
      height: 6px;
      left: calc(50% - 2px);
      position: absolute;
      top: calc(50% - 4px);
      transform: rotate(-45deg);
      width: 6px; }
    .image-preview .arrow-button.prev {
      left: 0;
      margin: -12px 0 0 12px; }
    .image-preview .arrow-button.next {
      right: 0;
      margin: -12px 12px 0 0; }
      .image-preview .arrow-button.next:after {
        left: calc(50% - 5px);
        top: calc(50% - 4px);
        transform: rotate(135deg); }
    .image-preview .arrow-button:hover {
      background-color: rgba(56, 153, 236, 0.9); }
      .image-preview .arrow-button:hover:after {
        border-color: #fff; }
    .image-preview .arrow-button[disabled] {
      cursor: default; }
    .image-preview .arrow-button, .image-preview .arrow-button[disabled], .image-preview .arrow-button[disabled]:hover {
      background-color: rgba(255, 255, 255, 0.9); }
    .image-preview .arrow-button[disabled]:after, .image-preview .arrow-button[disabled]:hover:after {
      border-color: #bcbcbc; }

.video-input {
  width: 288px;
  height: 155px;
  box-sizing: border-box;
  background-color: #e7f0ff;
  position: relative;
  text-align: center; }
  .video-input .video-wrapper {
    height: 100%;
    font-size: 0; }
    .video-input .video-wrapper > iframe {
      border: none; }
    .video-input .video-wrapper,
    .video-input .video-wrapper > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .video-input .button-wrapper {
    height: 54px;
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    background-color: rgba(177, 221, 248, 0.4); }
    .video-input .button-wrapper button.action {
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      border: 0;
      border-radius: 17px;
      background-color: #fff;
      cursor: pointer;
      outline: none;
      color: #116dff;
      font-size: 14px;
      vertical-align: top;
      text-overflow: clip; }
      .video-input .button-wrapper button.action.has-icon {
        padding-left: 0; }
      .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(1) {
        width: 167px; }
      .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 23px; }
        .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2),
        .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          width: calc(50% - 2 * 23px); }
      .video-input .button-wrapper button.action:hover {
        background-color: #e7f0ff; }
      .video-input .button-wrapper button.action:active {
        background-color: #fff; }
      .video-input .button-wrapper button.action svg {
        float: left;
        margin: 0 20px 0 0 !important;
        width: 14px;
        height: 32px; }
      .video-input .button-wrapper button.action span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #116dff;
        line-height: 17px;
        text-align: left; }
  .video-input .placeholder-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
    .video-input .placeholder-wrapper svg path {
      fill: #116dff; }
  .video-input .video-controls {
    width: 100%;
    height: 100%; }
    .video-input .video-controls .blocking-layer {
      background: #000;
      width: 100%;
      height: 100%; }
      .video-input .video-controls .blocking-layer.play {
        opacity: 0; }
      .video-input .video-controls .blocking-layer.pause {
        opacity: 0.4; }
    .video-input .video-controls .pause-button,
    .video-input .video-controls .play-button {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      position: absolute;
      margin: 46px 0 65px 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      top: 6px; }
    .video-input .video-controls .pause-button .pause {
      display: block;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      width: 10%;
      height: 40%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%); }
    .video-input .video-controls .pause-button:hover {
      border-color: #116dff;
      cursor: pointer; }
      .video-input .video-controls .pause-button:hover .pause {
        border-left-color: #116dff;
        border-right-color: #116dff;
        cursor: pointer; }
    .video-input .video-controls .play-button .triangle {
      width: 0;
      height: 0;
      position: relative;
      display: block;
      border-color: inherit;
      border-left: 24px solid #fff;
      border-bottom: 14px transparent solid;
      border-top: 14px transparent solid;
      top: 50%;
      left: calc(50% + 4px);
      transform: translate(-50%, -50%); }
    .video-input .video-controls .play-button:hover {
      border-color: #116dff;
      cursor: pointer; }
      .video-input .video-controls .play-button:hover .triangle {
        border-left-color: #116dff;
        border-right-color: #116dff;
        cursor: pointer; }

.video-selector.bg-panel-top {
  position: relative;
  height: 162px; }

.video-selector .bg-panel-preview {
  height: 100%; }

.video-selector .bg-panel-preview-media {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7f0ff; }

.video-selector .bg-panel-top-actions {
  position: absolute;
  bottom: 0;
  height: 54px;
  width: 100%;
  background: rgba(177, 221, 248, 0.4);
  display: flex;
  align-items: center;
  justify-content: center; }
  .video-selector .bg-panel-top-actions .bg-panel-action .btn-confirm-secondary {
    background-color: #ffffff; }
    .video-selector .bg-panel-top-actions .bg-panel-action .btn-confirm-secondary:hover {
      background-color: #e7f0ff; }

.video-selector .change-media-button-text {
  padding-left: 10px; }

.video-selector .bg-panel-preview-buttons {
  position: relative;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center; }

.video-preview {
  position: relative;
  height: 162px;
  background: #000000; }
  .video-preview__cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-preview__cover > .media-panel-play-button {
      margin: 0; }
  .video-preview__poster {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .video-preview__player {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .video-preview__embedded-player {
    height: 100%;
    font-size: 0;
    opacity: 0.8; }
    .video-preview__embedded-player > iframe {
      border: none; }
    .video-preview__embedded-player,
    .video-preview__embedded-player > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .video-preview__button-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    display: flex;
    align-items: center;
    justify-content: center; }
  .video-preview .change-media-button-text {
    padding-left: 10px; }

.control-rich-text .video-type-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 8px -6px -4px; }

.video-type-selector__item {
  flex: 0 0 51px;
  max-width: 51px;
  margin: 0 6px 12px !important; }

.video-type-selector__btn {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  width: 51px;
  height: 51px;
  text-align: center; }
  .video-type-selector__btn:focus, .video-type-selector__btn:active {
    outline: 0; }
  .video-type-selector__btn:hover .video-type-selector__btn-icon {
    padding: 0;
    border: 2px solid #d6e6fe; }
  .video-type-selector__btn.active .video-type-selector__btn-icon {
    padding: 0;
    border: 2px solid #116dff; }
  .video-type-selector__btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 1px;
    margin: 0 0 6px;
    border: 1px solid #dfe5eb;
    border-radius: 4px; }
    .video-type-selector__btn-icon > svg {
      display: block; }

.video-upload-button.control-upload-button > .control-button > .button-content .symbol > .symbol {
  padding-right: 0; }

.video-upload-button.control-upload-button > .control-button {
  margin: 0 !important; }

.control-text-input-with-button {
  position: relative; }
  .control-text-input-with-button:hover .info-icon {
    display: inline-block; }
  .control-text-input-with-button:hover .control-text-input:not(.focused) > input,
  .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea {
    background-color: #e7f0ff;
    color: #3b4057; }
    .control-text-input-with-button:hover .control-text-input:not(.focused) > input::placeholder,
    .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea::placeholder {
      color: #3b4057; }
  .control-text-input-with-button:hover .control-text-input:not(.focused) > input:hover,
  .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea:hover {
    background-color: #d6e6fe;
    color: #3b4057; }
  .control-text-input-with-button .control-text-input {
    margin: 12px 0 !important;
    width: 100% !important; }
  .control-text-input-with-button .control-button {
    margin: 0 auto 18px !important;
    display: block;
    padding: 7px 24px;
    width: auto;
    min-width: 0;
    line-height: 14px !important;
    font-size: 14px;
    height: 30px; }

.control-text-input-with-fixed-button {
  display: flex;
  align-items: center; }
  .control-text-input-with-fixed-button > .control-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex-grow: 1;
    color: #3b4057;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: solid 1px #88ccf8;
    background-color: #fff;
    height: 23px;
    padding-left: 15px;
    width: 0; }
  .control-text-input-with-fixed-button > .control-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex-shrink: 0;
    margin-left: 6px;
    height: 20px;
    border-radius: 12px;
    border-width: 2px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 9px; }
    .control-text-input-with-fixed-button > .control-button.disabled {
      background-color: #bdbdbd; }
  .control-text-input-with-fixed-button.invalid > .control-text {
    border-color: #d82646; }

.control-thumbnailsSlider {
  white-space: nowrap;
  height: 68px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: #fff; }
  .control-thumbnailsSlider .itemsContainer {
    visibility: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    transition-property: left;
    transition-duration: 0.3s;
    top: 50%; }
  .control-thumbnailsSlider .arrow {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 24px;
    height: 100%;
    cursor: pointer; }
    .control-thumbnailsSlider .arrow span {
      width: 8px;
      height: 8px;
      position: relative;
      top: calc(50% - 10px);
      left: calc(50% - 4px);
      border-top: 1px solid #116dff;
      border-right: 1px solid #116dff;
      display: inline-block; }
    .control-thumbnailsSlider .arrow.left {
      left: 0; }
      .control-thumbnailsSlider .arrow.left span {
        transform: rotate(225deg);
        left: 10px; }
    .control-thumbnailsSlider .arrow.right {
      right: 0; }
      .control-thumbnailsSlider .arrow.right span {
        transform: rotate(45deg);
        left: 5px; }
  .control-thumbnailsSlider input {
    display: none; }
    .control-thumbnailsSlider input:hover:checked + img, .control-thumbnailsSlider input:checked + img {
      border-radius: 6px;
      border-color: #ff7d38;
      background-color: transparent; }
  .control-thumbnailsSlider img {
    margin-right: 6px;
    border: solid 2px transparent; }
  .control-thumbnailsSlider label {
    height: 100%;
    display: inline-block;
    font-size: 0;
    cursor: pointer; }
    .control-thumbnailsSlider label:hover img {
      border-radius: 6px;
      border-color: #80b1ff;
      background-color: rgba(127, 204, 247, 0.2); }

.proportions-slider {
  border-radius: 4px;
  height: 25px;
  background-color: #116dff;
  margin: 13px auto 28px;
  padding: 12px; }
  .proportions-slider > .column {
    background-color: #e7f0ff;
    height: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: #116dff;
    min-width: 2px; }
    .proportions-slider > .column > .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: -32px;
      width: 100%; }
    .proportions-slider > .column > .divider {
      position: absolute;
      left: -6.5px;
      top: 0;
      width: 13px;
      height: 100%;
      background-color: #116dff;
      cursor: col-resize; }
      .proportions-slider:hover > .column > .divider {
        background-color: #0057e1; }
      .proportions-slider > .column > .divider:hover {
        background-color: #5999ff; }

.label-with-circle-button {
  position: relative;
  text-align: left;
  padding: 12px; }
  .label-with-circle-button label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    line-height: 100%;
    color: #3b4057;
    text-align: left;
    display: block;
    padding: 5px 30px 3px 12px; }
  .label-with-circle-button .link-to-icon-info {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  .label-with-circle-button:hover:not(.disabled) .link-to-icon-info {
    display: inherit; }
  .label-with-circle-button .container-action {
    padding: 0 11px; }
    .label-with-circle-button .container-action .link {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 34px;
      height: 36px; }
      .label-with-circle-button .container-action .link::placeholder {
        color: #b6c1cd; }
      .label-with-circle-button .container-action .link:hover::placeholder {
        color: #3b4057; }
    .label-with-circle-button .container-action .validation-icon {
      font-size: 0; }
      .label-with-circle-button .container-action .validation-icon.validation-icon-success {
        border: none;
        background-color: transparent; }
        .label-with-circle-button .container-action .validation-icon.validation-icon-success svg {
          fill: #25a55a; }
  .label-with-circle-button.disabled label {
    color: #939393; }
  .label-with-circle-button.disabled svg {
    border-color: #bcbcbc;
    background-color: #bcbcbc; }

.control-search {
  position: relative; }
  .control-search-input {
    position: relative; }
    .control-search-input__prefix {
      position: absolute;
      left: 14px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .control-search-input__suffix {
      position: absolute;
      right: 6px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .control-search-input__symbol {
      height: 100%;
      pointer-events: none; }
      .control-search-input__symbol * {
        fill: #116dff; }
    .control-search-input > .input-container > .input {
      border: 1px solid #a8caff;
      padding: 0 36px;
      border-radius: 100px;
      height: 30px;
      font-size: 14px; }
    .control-search-input .control-button.btn-close {
      position: relative;
      background-color: #e7f0ff;
      width: 18px;
      height: 18px; }
      .control-search-input .control-button.btn-close svg * {
        fill: #116dff; }
      .control-search-input .control-button.btn-close:hover {
        background-color: #e7f0ff; }
        .control-search-input .control-button.btn-close:hover svg * {
          fill: #5999ff; }
  .control-search-options {
    position: absolute;
    z-index: 5999;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
    max-height: 180px;
    overflow: auto;
    padding: 0;
    margin: 0; }
    .control-search-options__item {
      min-height: 36px;
      padding: 6px 24px;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000624;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box; }
      .control-search-options__item.active {
        background-color: #d6e6fe;
        color: #000624; }

.colorSpace {
  position: relative;
  display: inline-block;
  margin-bottom: 10px; }
  .colorSpace > .top-section {
    height: 123px; }
    .colorSpace > .top-section > .select-area {
      display: inline-block;
      position: relative;
      height: 100%;
      width: 210px;
      cursor: crosshair; }
      .colorSpace > .top-section > .select-area > .selector-layer {
        position: absolute;
        width: 100%;
        height: 100%; }
      .colorSpace > .top-section > .select-area > .saturation-layer {
        background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
      .colorSpace > .top-section > .select-area > .brightness-layer {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), black); }
      .colorSpace > .top-section > .select-area > .color-indicator {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 4px; }
        .colorSpace > .top-section > .select-area > .color-indicator.bright-background {
          box-shadow: 0 0 0 1px #000624; }
        .colorSpace > .top-section > .select-area > .color-indicator.dark-background {
          box-shadow: 0 0 0 1px #fff; }
    .add-color-content .colorSpace > .top-section {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden; }
  .colorSpace > .hue-scale {
    width: 100%;
    height: 10px;
    position: relative;
    background-image: linear-gradient(to right, red 0%, #ff4d00 5%, #ff9900 10%, #ffe600 15%, #ccff00 20%, #80ff00 25%, #33ff00 30%, #00ff1a 35%, #00ff66 40%, #00ffb3 45%, cyan 50%, #0066ff 60%, #001aff 65%, #3300ff 70%, #8000ff 75%, #cc00ff 80%, #ff00e6 85%, #ff0099 90%, #ff004d 95%, red 100%); }
    .colorSpace > .hue-scale > .hue-indicator {
      position: absolute;
      top: -3px;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: #fff;
      box-shadow: 0 2px 1px 0 rgba(43, 86, 114, 0.4);
      box-sizing: border-box; }
    .colorSpace > .hue-scale > .hue-indicator-drag {
      border: 2px solid #fff;
      background-color: transparent; }

.color-format > .color-values-wrapper {
  position: relative;
  margin: 18px 17px 12px 17px;
  max-height: 24px; }
  .color-format > .color-values-wrapper > .color-value-hex {
    width: 175px;
    margin: 0 auto 0 auto; }
  .color-format > .color-values-wrapper > .color-values > .color-value-item {
    position: relative;
    display: inline-block;
    width: 33%; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(1) {
      text-align: left; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(2) {
      text-align: center; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(3) {
      text-align: right; }

.palettes-list {
  position: relative;
  width: 138px;
  height: 100%;
  text-align: center; }
  .palettes-list > .palette-options {
    display: inline;
    vertical-align: top; }
    .palettes-list > .palette-options > .palette-option-wrapper {
      padding: 3px 0;
      display: inline-block;
      cursor: pointer; }
      .palettes-list > .palette-options > .palette-option-wrapper:hover > .palette-option {
        border-color: #d6e6fe;
        transition: border-color 0.4s; }
      .palettes-list > .palette-options > .palette-option-wrapper > .palette-option {
        position: relative;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        border: 1px solid transparent;
        font-size: 0;
        padding: 3px; }
        .palettes-list > .palette-options > .palette-option-wrapper > .palette-option.selected {
          border-color: #116dff; }
          .palettes-list > .palette-options > .palette-option-wrapper > .palette-option.selected:before {
            position: absolute;
            content: '';
            font-size: initial;
            left: -11px;
            cursor: initial;
            width: 0;
            height: 0;
            border-right: 6px solid #116dff;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            top: 9px; }
        .palettes-list > .palette-options > .palette-option-wrapper > .palette-option > .palette-option-color {
          display: inline-block;
          width: 18px;
          height: 100%; }
          .palettes-list > .palette-options > .palette-option-wrapper > .palette-option > .palette-option-color.white-color {
            box-sizing: border-box;
            border: 1px solid #ccc; }

.palette-color-option {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 42px;
  height: 24px; }
  .palette-color-option.white-option {
    box-sizing: border-box;
    border: 1px solid #ccc; }
  .palette-color-option.selectable {
    cursor: pointer; }
    .palette-color-option.selectable.option-selected:after, .palette-color-option.selectable:not(.option-selected):hover:after, .palette-color-option.selectable.white-option.option-selected:after, .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
      content: '';
      position: absolute;
      width: 46px;
      height: 28px;
      background-color: inherit; }
    .palette-color-option.selectable.option-selected:after, .palette-color-option.selectable:not(.option-selected):hover:after {
      top: -1px;
      left: -2px; }
    .palette-color-option.selectable.white-option.option-selected:after, .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
      top: -2px;
      left: -3px; }
    .palette-color-option.selectable.option-selected:after, .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
      box-sizing: border-box;
      box-shadow: 0 0 7px 0 rgba(22, 45, 61, 0.44);
      border: solid 1px #fff; }
    .palette-color-option.selectable.option-selected:after {
      z-index: 2; }
    .palette-color-option.selectable:not(.option-selected):hover:after {
      z-index: 1; }
    .palette-color-option.selectable.white-option.option-selected:after {
      border-color: #ccc; }

.palette-displayer-content .palette-displayer-row {
  display: flex;
  margin-bottom: 10px; }
  .palette-displayer-content .palette-displayer-row:last-child {
    margin-bottom: 0; }

.palette-displayer-content.white-option {
  box-sizing: border-box;
  border: 1px solid #dfe5eb; }

.palette-displayer-content .palette-color-option {
  height: 18px; }
  .palette-displayer-content .palette-color-option:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .palette-displayer-content .palette-color-option:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .palette-displayer-content .palette-color-option.selectable.option-selected:after, .palette-displayer-content .palette-color-option.selectable:not(.option-selected):hover:after {
    content: '';
    position: absolute;
    width: calc(100% + 2px * 2);
    height: calc(100% + 3px * 2);
    background-color: inherit;
    border-radius: 2px;
    top: calc(3px * -1);
    left: calc(2px * -1); }
  .palette-displayer-content .palette-color-option.selectable:not(.option-selected):hover:after {
    height: calc(100% + 2px * 2);
    top: calc(2px * -1); }

.color-picker-input {
  position: relative; }
  .color-picker-input > .color-picker-wrapper {
    width: 30px;
    height: 30px;
    transform: translateZ(0);
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    border-radius: 4px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
    background-position: 5px 5px, 15px 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    .color-picker-input > .color-picker-wrapper.disabled {
      opacity: 0.4; }
    .color-picker-input > .color-picker-wrapper > .color-picker-color {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute; }
    .color-picker-input > .color-picker-wrapper:hover, .color-picker-input > .color-picker-wrapper.colorPickerOpen {
      border: 2px solid #80b1ff;
      background-position: 4px 4px, 14px 14px; }
  .color-picker-input > .color-picker-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    height: 30px;
    line-height: 30px;
    width: 155px;
    cursor: pointer; }
    .color-picker-input > .color-picker-label:hover + .color-picker-wrapper {
      border: 2px solid #80b1ff; }
    .color-picker-input > .color-picker-label ~ .color-picker-wrapper {
      position: absolute;
      right: 0;
      top: 0; }
  .color-picker-input > .divider-long,
  .color-picker-input > .rich-text-paragraph {
    margin-top: 14px; }
  .color-picker-input-with-opacity .color-picker-input {
    position: absolute;
    right: 0;
    top: 2px; }

.color-picker-input-with-opacity {
  position: relative; }
  .color-picker-input-with-opacity > .color-picker-input-with-opacity-label {
    display: block;
    font-size: 14px;
    color: #3b4057;
    overflow: hidden;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    text-overflow: ellipsis; }
    .color-picker-input-with-opacity > .color-picker-input-with-opacity-label.disabled {
      opacity: 0.5; }
  .color-picker-input-with-opacity > .color-picker-input-with-opacity-slider {
    position: relative; }
  .color-picker-input-with-opacity > .divider-long {
    margin-top: 14px; }
  .color-picker-input-with-opacity > .rich-text-paragraph {
    margin-top: 14px; }

section.tabs {
  overflow: hidden;
  position: relative; }
  section.tabs > ul.tab-header > li.tab-label {
    cursor: pointer;
    display: block; }
  section.tabs > section.tab-content {
    position: relative; }
  section.tabs.left {
    display: flex; }
    section.tabs.left > section.tab-content {
      align-self: stretch;
      flex-grow: 1; }
  section.tabs.top > ul.tab-header {
    display: block;
    overflow: hidden;
    width: 100%; }
    section.tabs.top > ul.tab-header > li.tab-label {
      float: left; }
  section.tabs.radio > ul.tab-header > li.tab-label {
    line-height: 16px;
    margin-bottom: 15px;
    padding-left: 28px;
    position: relative; }
    section.tabs.radio > ul.tab-header > li.tab-label:before, section.tabs.radio > ul.tab-header > li.tab-label:after {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute; }
    section.tabs.radio > ul.tab-header > li.tab-label:before {
      top: calc(50% - 8px);
      border: 1px solid #116dff;
      box-sizing: border-box;
      height: 16px;
      left: 0;
      width: 16px; }
    section.tabs.radio > ul.tab-header > li.tab-label.selected:after {
      top: calc(50% - 6px);
      background-color: #116dff;
      height: 12px;
      left: 2px;
      width: 12px; }
    section.tabs.radio > ul.tab-header > li.tab-label:hover:before {
      background-color: #d6e6fe; }

.control-section-divider.labeled {
  position: relative; }
  .control-section-divider.labeled > .section-divider-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    cursor: default;
    color: #3b4057; }

.control-section-divider .tooltip-on-ellipsis-content.section-divider-content {
  position: static; }

.tooltip-presenter {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 5999;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  border-radius: 4px; }
  .tooltip-presenter .arrow {
    transform: rotate(45deg);
    background-color: #fff; }
    .tooltip-presenter .arrow:after {
      content: '';
      z-index: -1;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      height: 1px;
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
    .tooltip-presenter .arrow:before {
      content: '';
      z-index: -1;
      top: 0;
      left: calc(100% - 1px);
      width: 1px;
      height: 100%;
      box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-bottom .arrow:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-bottom .arrow:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-right .arrow:after {
    top: calc(100% - 1px);
    left: 0;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-right .arrow:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-left .arrow:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-left .arrow:before {
    top: 0;
    left: calc(100% - 1px);
    box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter .content-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    position: relative;
    color: #3b4057; }
  .tooltip-presenter.normal-tooltip .content-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 18px;
    color: #3b4057; }
  .tooltip-presenter.small-tooltip .content-wrapper {
    padding: 12px; }
  .tooltip-presenter.tooltip-transition {
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) left; }
  .tooltip-presenter.tooltip-behind-pop-ups {
    z-index: 1502; }
  .tooltip-presenter.tooltip-behind-pages-panel {
    z-index: 1253; }
  .tooltip-presenter.tooltip-shake {
    animation: shake-animation 0.3s linear; }
  .tooltip-presenter .content {
    word-wrap: break-word; }
  .rulers .tooltip-presenter {
    border-radius: 15px;
    height: 30px;
    white-space: nowrap;
    cursor: default; }
    .rulers .tooltip-presenter.hover-bubble {
      height: 24px; }
      .rulers .tooltip-presenter.hover-bubble .input-stepper {
        margin-top: 2px; }
    .rulers .tooltip-presenter.hover-bubble:hover {
      background-color: #d6e6fe; }
      .rulers .tooltip-presenter.hover-bubble:hover .input-stepper .input {
        background-color: #d6e6fe; }
    .rulers .tooltip-presenter .content-wrapper {
      padding: 0; }

@keyframes shake-animation {
  1% {
    transform: translate(-20px); }
  20% {
    transform: translate(10px); }
  40% {
    transform: translate(-5px); }
  60% {
    transform: translate(3px); } }

.quick-tip {
  white-space: normal;
  padding: 24px;
  width: 236px; }
  .quick-tip .close-quick-tip {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 7px;
    width: 7px;
    cursor: pointer; }
    .quick-tip .close-quick-tip svg {
      position: absolute; }
  .quick-tip .text-container {
    display: inline-block;
    vertical-align: top;
    padding-left: 12px;
    width: 190px; }
    .quick-tip .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      display: inline; }
      .quick-tip .text-container .text.link-action {
        cursor: pointer;
        color: #116dff; }
        .quick-tip .text-container .text.link-action:hover {
          color: #5999ff; }
    .quick-tip .text-container.text-no-image {
      padding: 18px; }
  .quick-tip .quick-tip-image {
    display: inline-block;
    vertical-align: top; }
    .quick-tip .quick-tip-image svg {
      width: 34px;
      height: 34px; }

.imageAndTextTooltip {
  white-space: normal; }
  .imageAndTextTooltip .text-container {
    padding: 24px; }
    .imageAndTextTooltip .text-container .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      margin-bottom: 12px; }
    .imageAndTextTooltip .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      display: inline; }
    .imageAndTextTooltip .text-container .learn-more {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      color: #116dff;
      margin-top: 10px;
      display: inline;
      padding-left: 5px; }
      .imageAndTextTooltip .text-container .learn-more :hover {
        text-decoration: underline; }
    .imageAndTextTooltip .text-container.text-no-image {
      padding: 18px; }
  .imageAndTextTooltip .image-container {
    background-color: #116dff;
    text-align: center;
    left: 0;
    right: 0; }
    .imageAndTextTooltip .image-container.upper-image {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    .imageAndTextTooltip .image-container.lower-image {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
    .imageAndTextTooltip .image-container svg {
      margin-top: 22px;
      margin-bottom: 22px; }
  .imageAndTextTooltip .close {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

.icon-and-text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  padding: 18px;
  color: #3b4057;
  display: flex;
  align-items: center; }
  .icon-and-text svg {
    margin-right: 10px; }
  .icon-and-text span {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.titleBodyAndLinkTooltip {
  white-space: normal;
  max-width: 300px;
  padding: 18px; }
  .titleBodyAndLinkTooltip .title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    margin-bottom: 6px;
    white-space: nowrap; }
  .titleBodyAndLinkTooltip .text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057; }
  .titleBodyAndLinkTooltip .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    cursor: pointer;
    margin-top: 11px; }
    .titleBodyAndLinkTooltip .link:hover {
      text-decoration: underline; }
  .titleBodyAndLinkTooltip .close {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

.titleBodyAndLinkTooltip.small {
  padding: 12px; }
  .titleBodyAndLinkTooltip.small .link {
    margin-top: 4px; }

.keyboardShortcutTooltip .label {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057; }

.keyboardShortcutTooltip .shortcut {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  color: #868aa5;
  margin-left: 2px; }

.tooltips-layer {
  top: 0;
  left: 0; }

.aiTextCreatorEntryPoint {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 26px;
  height: 26px;
  border-radius: 50% 50% 0 50%;
  background-color: #116dff;
  border: 2px solid white;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  animation: aiTextCreatorEntryPointAppearance 0.8s ease;
  box-sizing: border-box;
  transition: 0.35s ease;
  transform-origin: bottom right;
  transform: scale(1); }
  .aiTextCreatorEntryPoint:has(.aiTextCreatorEntryPoint__icon) {
    background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackground.v2.svg");
    background-size: contain; }
    .aiTextCreatorEntryPoint:has(.aiTextCreatorEntryPoint__icon):hover {
      background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackgroundHover.svg");
      background-size: contain; }
  .aiTextCreatorEntryPoint_small {
    transform: scale(0.62);
    border-width: 3px; }
    .aiTextCreatorEntryPoint_small .aiTextCreatorEntryPoint__text,
    .aiTextCreatorEntryPoint_small .aiTextCreatorEntryPoint__icon {
      opacity: 0;
      transition: opacity 0.35s; }
  .aiTextCreatorEntryPoint_withBigToSmallAppearance {
    animation: aiTextCreatorEntryPointAppearance 0.8s ease, aiTextCreatorEntryPointAppearanceScale 1.15s ease; }
    .aiTextCreatorEntryPoint_withBigToSmallAppearance .aiTextCreatorEntryPoint__text,
    .aiTextCreatorEntryPoint_withBigToSmallAppearance .aiTextCreatorEntryPoint__icon {
      animation: aiTextCreatorEntryPointAppearanceText 1.15s ease; }
  .aiTextCreatorEntryPoint__text {
    position: absolute;
    left: 4px;
    top: 6px; }
  .aiTextCreatorEntryPoint__icon {
    position: absolute;
    left: 2px;
    top: 3px; }
  .aiTextCreatorEntryPoint__wrapper {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 37px; }
    .aiTextCreatorEntryPoint__wrapper_cursorPointer {
      cursor: pointer; }
    .aiTextCreatorEntryPoint__wrapper:hover .aiTextCreatorEntryPoint_withHover {
      border: 2px solid white;
      transform: scale(1); }
      .aiTextCreatorEntryPoint__wrapper:hover .aiTextCreatorEntryPoint_withHover .aiTextCreatorEntryPoint__text,
      .aiTextCreatorEntryPoint__wrapper:hover .aiTextCreatorEntryPoint_withHover .aiTextCreatorEntryPoint__icon {
        opacity: 1; }
    .aiTextCreatorEntryPoint__wrapper svg * {
      fill: #fff; }

@keyframes aiTextCreatorEntryPointAppearance {
  0% {
    opacity: 0; }
  12.5% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes aiTextCreatorEntryPointAppearanceScale {
  0% {
    transform: scale(1);
    border-width: 2px; }
  70% {
    transform: scale(1);
    border-width: 2px; }
  100% {
    transform: scale(0.62);
    border-width: 3px; } }

@keyframes aiTextCreatorEntryPointAppearanceText {
  0% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fd05322c-bf43-4f8e-a129-24869b0942db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/74649485-cd74-443e-9d54-331ccd448900.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7732d6de-bd05-4c17-ae43-99e9a02edf31.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b4cd926c-1a31-4785-b261-1db1a68c521e.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9f93e286-eeaa-4077-b0b2-0e6901920f74.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b4a262e-3342-44e2-8ad7-719998a68134.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a5cf81d-ffbe-4c76-8982-270592fb36b0.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b39b5378-b594-45fe-8067-fc4385d08520.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/96b33a5f-d3a7-4a34-b360-76867695bb83.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/775a65da-14aa-4634-be95-6724c05fd522.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/a9e72f14-d263-440b-b2a4-fa729879fb51.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bb85cc70-1239-4a3c-b9c7-4da74afaf032.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/835e7b4f-b524-4374-b57b-9a8fc555fd4e.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/cf45dd62-4ace-47a2-abee-6fe9e95a1daa.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8ac9e38d-29c6-41ea-8e47-4ae4d2b1a4e1.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: bold;
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da3af6d8-5634-4072-8989-1ddf770e3142.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b29e833-1b7a-40ab-82a5-cfd69c8650f4.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/51f47b5d-143f-4d30-a6c3-23217fc0136b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d85949a1-c37a-43f7-9d09-fb056acf0c27.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-25UltL";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/277370bf-544b-4d3b-9f99-b9566a4b722b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/e2c2affa-fc35-4c36-aabd-33c8665d0531.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b1d7b778-bdf9-4ff3-b4e8-8cdabf234863.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-35Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9ec06c5b-556a-4dc4-9db2-425acf9fd515.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c881c21b-4148-4a11-a65d-f35e42999bc8.woff?1") format("woff"); }

.responsive-skin .spacer {
  display: flex; }
  .responsive-skin .spacer .Spacer01 {
    height: 4px;
    flex-grow: 4; }
  .responsive-skin .spacer .Spacer02 {
    height: 8px;
    flex-grow: 8; }
  .responsive-skin .spacer .Spacer03 {
    height: 12px;
    flex-grow: 12; }
  .responsive-skin .spacer .Spacer04 {
    height: 16px;
    flex-grow: 16; }
  .responsive-skin .spacer .Spacer05 {
    height: 20px;
    flex-grow: 20; }
  .responsive-skin .spacer .Spacer06 {
    height: 24px;
    flex-grow: 24; }

.spacer {
  display: flex; }
  .spacer .Spacer01 {
    height: 4px;
    flex-grow: 4; }
  .spacer .Spacer02 {
    height: 8px;
    flex-grow: 8; }
  .spacer .Spacer03 {
    height: 12px;
    flex-grow: 12; }
  .spacer .Spacer04 {
    height: 16px;
    flex-grow: 16; }
  .spacer .Spacer05 {
    height: 20px;
    flex-grow: 20; }
  .spacer .Spacer06 {
    height: 24px;
    flex-grow: 24; }

.classic-facelift-skin .control-accordion-section {
  border-top-color: #dfe5eb;
  border-bottom-color: #dfe5eb; }
  .classic-facelift-skin .control-accordion-section .accordion-section-header .accordion-section-side-icon .svg-side-arrow-poligon {
    fill: #3b4057; }
  .classic-facelift-skin .control-accordion-section .accordion-section-header:hover .accordion-section-side-icon > svg {
    fill: #116dff; }
  .classic-facelift-skin .control-accordion-section .accordion-section-header:hover .accordion-section-side-icon .svg-side-arrow-poligon {
    fill: #116dff; }
  .classic-facelift-skin .control-accordion-section .accordion-section-header:hover .accordion-section-label .control-label-base {
    color: #116dff; }

.control-accordion-section {
  display: block;
  border-top: 1px solid #dfe5eb;
  border-bottom: 1px solid #dfe5eb; }
  .control-accordion-section .accordion-section-header {
    display: flex;
    padding: 12px 12px 12px 24px;
    align-items: center;
    cursor: pointer; }
    .control-accordion-section .accordion-section-header .accordion-section-side-icon {
      transform: rotate(270deg); }
      .control-accordion-section .accordion-section-header .accordion-section-side-icon .svg-side-arrow-poligon {
        fill: #3b4057; }
    .control-accordion-section .accordion-section-header .accordion-section-label {
      flex: 1;
      padding: 0 12px;
      overflow: hidden; }
      .control-accordion-section .accordion-section-header .accordion-section-label .control-label {
        cursor: pointer; }
    .control-accordion-section .accordion-section-header .accordion-section-tooltip {
      display: none; }
    .control-accordion-section .accordion-section-header:hover .accordion-section-side-icon > svg {
      fill: #116dff; }
    .control-accordion-section .accordion-section-header:hover .accordion-section-side-icon .svg-side-arrow-poligon {
      fill: #116dff; }
    .control-accordion-section .accordion-section-header:hover .accordion-section-label .control-label-base {
      color: #116dff; }
    .control-accordion-section .accordion-section-header:hover .accordion-section-tooltip {
      display: flex; }
  .control-accordion-section .accordion-section-container {
    padding: 0 12px 12px 24px; }
  .control-accordion-section.open .accordion-section-side-icon {
    transform: rotate(0deg); }
  .control-accordion-section.open.noPadding .accordion-section-header {
    padding-bottom: 0; }
  .control-accordion-section.noPadding .accordion-section-container {
    padding: 0; }
  .control-accordion-section.noTopDivider {
    border-top: 0; }
  .control-accordion-section.noBottomDivider {
    border-bottom: 0; }
  .control-accordion-section.disabled {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec; }
    .control-accordion-section.disabled .accordion-section-header {
      cursor: default; }
      .control-accordion-section.disabled .accordion-section-header .accordion-section-side-icon .svg-side-arrow-poligon {
        fill: #939393; }
      .control-accordion-section.disabled .accordion-section-header .accordion-section-label .control-label {
        cursor: default; }
      .control-accordion-section.disabled .accordion-section-header .accordion-section-label .control-label-base {
        color: #939393; }

.classic-facelift-skin .add-thumbnail-button > .add-thumbnail-symbol-container {
  border-color: #a8caff;
  color: #116dff;
  border-radius: 4px; }
  .classic-facelift-skin .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .horizontal-line {
    background-color: #116dff; }
  .classic-facelift-skin .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .vertical-line {
    background-color: #116dff; }

.classic-facelift-skin .add-thumbnail-button > .add-thumbnail-label {
  color: #116dff; }

.classic-facelift-skin .add-thumbnail-button:hover > .add-thumbnail-symbol-container {
  background-color: #e7f0ff; }

.responsive-skin .add-thumbnail-button > .add-thumbnail-symbol-container {
  border-color: #e2e3ea;
  color: #42454C; }
  .responsive-skin .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .horizontal-line {
    background-color: #131720; }
  .responsive-skin .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .vertical-line {
    background-color: #131720; }

.responsive-skin .add-thumbnail-button > .add-thumbnail-label {
  color: #42454C;
  margin-top: 4px; }

.responsive-skin .add-thumbnail-button:hover > .add-thumbnail-symbol-container {
  background-color: #f4f5f8; }

.add-thumbnail-button {
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .add-thumbnail-button > .add-thumbnail-symbol-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #80b1ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #116dff; }
    .add-thumbnail-button:hover > .add-thumbnail-symbol-container {
      background-color: #e7f0ff; }
    .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol {
      position: relative; }
      .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .horizontal-line {
        position: absolute;
        width: 1px;
        height: 100%;
        left: 50%;
        transform: translate(-50%);
        background-color: #116dff; }
      .add-thumbnail-button > .add-thumbnail-symbol-container > .add-thumbnail-symbol > .vertical-line {
        position: absolute;
        width: 100%;
        height: 1px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #116dff; }
  .add-thumbnail-button > .add-thumbnail-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin-top: 12px;
    color: #116dff; }

.composite-with-info-icon {
  position: relative; }

.composite-with-char-limit .char-limit {
  position: absolute;
  top: 11px;
  right: 12px; }
  .input-borders-experiment .composite-with-char-limit .char-limit {
    top: 15px;
    right: 24px; }

.composite-with-char-limit .char-limit ~ .control-label {
  max-width: calc(100% - 54px); }

.responsive-skin .control-label-base {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased; }

.responsive-skin .panel-header-title .control-label-base {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased; }

.control-label-base {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px;
  display: inherit; }
  .control-label-base.disabled {
    color: inherit; }
  .bubble .bubble-content > .control-label-base {
    display: inline-block;
    color: #3b4057;
    -webkit-user-select: none;
            user-select: none;
    cursor: default;
    max-width: 100%; }
    .classic-facelift-skin .bubble .bubble-content > .control-label-base {
      color: #3b4057; }
    .responsive-skin .bubble .bubble-content > .control-label-base {
      color: #2B2E36; }
    .bubble .bubble-content > .control-label-base.disabled {
      color: #939393; }
  .control-action-with-symbol > .content-container .content .control-label-base,
  .control-section-divider .section-divider-content .control-label-base,
  .input-container > .input-prefix .control-label-base,
  .control-google-preview .control-label-base {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit; }

.control-google-preview .title .control-label-base {
  font-weight: 700; }

.classic-facelift-skin .bubble > .bubble-content {
  background-color: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.bubble > .bubble-content {
  cursor: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-width: 72px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  padding: 18px;
  word-wrap: break-word; }
  .tooltip.bubble > .bubble-content {
    position: fixed;
    z-index: 5999; }

.bubble > .bubble-arrow {
  cursor: auto;
  width: 0;
  height: 0; }
  .arrow-top.bubble > .bubble-arrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff; }
  .arrow-right.bubble > .bubble-arrow {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff; }
  .arrow-bottom.bubble > .bubble-arrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff; }
  .arrow-left.bubble > .bubble-arrow {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff; }
  .tooltip.bubble > .bubble-arrow {
    position: fixed;
    z-index: 5999;
    background-color: #fff;
    content: '';
    transform: translate(-50%, -50%) rotate(45deg);
    padding: 5px;
    border: none; }
    .arrow-bottom.tooltip.bubble > .bubble-arrow {
      box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-top.tooltip.bubble > .bubble-arrow {
      box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-left.tooltip.bubble > .bubble-arrow {
      box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-right.tooltip.bubble > .bubble-arrow {
      box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35); }

.responsive-skin .dropdown-theme-option .has-tooltip {
  max-width: calc(100% - 80px);
  padding: 0 12px; }

.responsive-skin .bubble .bubble-content {
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(19, 23, 32, 0.2);
  background-color: #fff;
  padding: 16px; }
  .responsive-skin .bubble .bubble-content .control-label-base:not(.t10) {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .bubble.hint .bubble-content {
  border-radius: 4px;
  background-color: #2B2E36;
  padding: 4px 8px;
  box-shadow: none;
  min-width: auto; }
  .responsive-skin .bubble.hint .bubble-content .control-label-base {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    letter-spacing: 1px; }

.responsive-skin .bubble .bubble-arrow {
  display: none; }

.responsive-skin.dark-mode .bubble .bubble-content {
  background-color: #131720; }
  .responsive-skin.dark-mode .bubble .bubble-content .control-label-base {
    color: #f4f5f8; }

.has-tooltip.info-icon-tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.control-label .has-tooltip {
  display: inline-flex;
  width: 100%; }
  .control-thumbnail .control-label .has-tooltip,
  .add-thumbnail-button .control-label .has-tooltip {
    justify-content: center; }

.composite-with-info-icon > .has-tooltip.info-icon-tooltip {
  position: absolute;
  top: 12px;
  right: 12px; }

.composite-banner .has-tooltip.info-icon-tooltip,
.composite-banner-premium .has-tooltip.info-icon-tooltip {
  top: calc(50% - 9px); }

.control-action-with-symbol .has-tooltip,
.control-vertical-tabs .has-tooltip {
  display: inline-flex;
  width: 100%; }

.composite-checkboxes-labeled .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip,
.composite-checkboxes .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: 0;
  right: calc(12px - 24px); }

.dropdown-detailed-option .has-tooltip {
  width: 100%;
  display: block; }

.dropdown-theme-option .has-tooltip {
  max-width: calc(100% - 84px);
  padding: 0 14px; }

.control-upload-button .bottom-message-container .has-tooltip {
  overflow: hidden; }

.composite-secondary-media-labeled .control-secondary-media-image + .has-tooltip,
.composite-secondary-media .control-secondary-media-image + .has-tooltip {
  margin-right: 6px; }

.composite-secondary-media-labeled > .has-tooltip,
.composite-secondary-media > .has-tooltip {
  display: inline-block;
  vertical-align: middle; }

.tooltip-leave {
  opacity: 1; }
  .tooltip-leave.tooltip-leave-active {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.01; }

.symbol-input-validation-error {
  fill: #e62214; }
  .symbol-input-validation-error .c1,
  .symbol-input-validation-error .c2 {
    fill: #fff; }

.symbol-input-validation-success {
  fill: #25a55a; }
  .symbol-input-validation-success .c1 {
    fill: #fff; }

.responsive-skin .composite-time-range:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-slider:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-slider-labeled:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled):hover > .input,
.responsive-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled):hover > .input {
  color: inherit; }

.responsive-skin .composite-time-range:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-slider:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input,
.responsive-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input {
  color: inherit; }

.responsive-skin .input-container {
  min-width: 48px; }
  .responsive-skin .input-container.no-outline > input {
    border-color: transparent; }
  .responsive-skin .input-container > .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    height: 32px;
    padding: 0 6px;
    border: 1px solid #e2e3ea;
    border-radius: 4px;
    color: #131720;
    background-color: #fff !important; }
    .responsive-skin .input-container > .input.small {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      padding: 0 8px;
      height: 28px; }
    .responsive-skin .input-container > .input.tiny {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 11px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      padding: 0 8px;
      height: 24px; }
    .responsive-skin .input-container > .input::placeholder {
      color: #42454C !important; }
    .responsive-skin .input-container > .input::selection {
      background-color: #c3daff; }
    .is-disabled.responsive-skin .input-container > .input {
      color: #767676; }
    .responsive-skin .input-container:not(.is-disabled) > .input:hover {
      color: #131720;
      border: 1px solid #868aa5;
      cursor: pointer; }
    .responsive-skin .input-container:not(.is-disabled) > .input:focus {
      border: 1px solid #116dff;
      box-shadow: none;
      cursor: text; }
      .responsive-skin .input-container:not(.is-disabled) > .input:focus::selection {
        background-color: #c3daff; }

.responsive-skin .composite-color-picker-input .control-slider-input .control-numeric-input.input-container,
.responsive-skin :not(.composite-text-input-labeled) > .control-numeric-input.input-container {
  min-width: 56px; }
  .responsive-skin .composite-color-picker-input .control-slider-input .control-numeric-input.input-container > .input,
  .responsive-skin :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
    width: 56px;
    text-align: left; }
    .responsive-skin .composite-color-picker-input .control-slider-input .control-numeric-input.input-container > .input:hover,
    .responsive-skin :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input:hover {
      border-radius: 4px !important;
      color: #131720 !important; }

.responsive-skin .composite-two-inputs-in-row .input-container {
  width: auto; }

.responsive-skin .composite-two-inputs-in-row > .composite-input-with-drop-down-labeled-horizontal .input-container {
  width: 52px; }

.responsive-skin .composite-two-inputs-in-row > .composite-input-with-drop-down-labeled-horizontal input {
  border-radius: 4px !important; }

.responsive-skin .composite-two-inputs-in-row > .composite-input-with-drop-down-labeled-top:not(:last-of-type) {
  margin-right: 18px; }

.responsive-skin .control-slider-input .input-container,
.responsive-skin .composite-slider-labeled .input-container,
.responsive-skin .composite-color-picker-input .input-container {
  margin-left: 16px; }
  .responsive-skin .control-slider-input .input-container .input,
  .responsive-skin .composite-slider-labeled .input-container .input,
  .responsive-skin .composite-color-picker-input .input-container .input {
    height: 32px;
    text-align: left; }
  .responsive-skin .control-slider-input .input-container .input-text-suffix,
  .responsive-skin .composite-slider-labeled .input-container .input-text-suffix,
  .responsive-skin .composite-color-picker-input .input-container .input-text-suffix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 12px;
    color: #42454C; }

.responsive-skin .composite-input-with-drop-down .input-container > .input {
  padding-right: 20px; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled:hover .input-container,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal:hover .input-container,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down:hover .input-container,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled:hover .input-container,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:hover .input-container {
  z-index: 1; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled .input-container > .input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal .input-container > .input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down .input-container > .input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled .input-container > .input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol .input-container > .input {
  text-overflow: clip; }
  .responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled .input-container > .input.small,
  .responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal .input-container > .input.small,
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down .input-container > .input.small,
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled .input-container > .input.small,
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol .input-container > .input.small {
    padding: 0 20px 0 6px;
    height: 30px; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled .composite-input-labeled .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol .composite-input-labeled .input-container input {
  border-radius: 0;
  padding-right: 18px; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled:first-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal:first-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down:first-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled:first-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:first-of-type .composite-input-labeled .input-container input {
  border-radius: 4px 0 0 4px; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled:last-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal:last-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down:last-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled:last-of-type .composite-input-labeled .input-container input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:last-of-type .composite-input-labeled .input-container input {
  border-radius: 0 4px 4px 0; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled:only-of-type .composite-input-with-drop-down .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled:only-of-type .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal:only-of-type .composite-input-with-drop-down .input-container input,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal:only-of-type .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down:only-of-type .composite-input-with-drop-down .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down:only-of-type .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled:only-of-type .composite-input-with-drop-down .input-container input,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled:only-of-type .input-container input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:only-of-type .composite-input-with-drop-down .input-container input,
.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:only-of-type .input-container input {
  border-radius: 4px; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row .composite-input-labeled:first-of-type .input-container input {
  border-radius: 4px 0 0 4px; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row .composite-input-labeled:last-of-type .input-container input {
  border-radius: 0 4px 4px 0; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row:first-of-type .composite-input-labeled:first-of-type {
  border-right: none; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row:last-of-type .composite-input-labeled:last-of-type {
  border-left: none; }

.responsive-skin .input-container.is-disabled > .input {
  background-color: #f3f3f3 !important; }
  .responsive-skin .input-container.is-disabled > .input:hover {
    cursor: default; }

.classic-facelift-skin .input-container > .input-prefix {
  color: #acafc4; }

.classic-facelift-skin .input-container.control-numeric-input > .input-prefix {
  color: #3b4057; }

.classic-facelift-skin .input-container:not(.is-disabled) > .input-prefix.clickable:hover + .input:not(:focus) {
  background-color: #e7f0ff; }

.classic-facelift-skin .input-container > .input {
  color: #000624; }
  .classic-facelift-skin .input-container > .input::placeholder {
    color: #acafc4; }

.classic-facelift-skin .input-container:not(.is-disabled).invalid > .input:focus {
  border-color: #e62214; }

.classic-facelift-skin .input-container:not(.is-disabled) > .input:hover {
  background-color: #d6e6fe;
  color: #3b4057; }
  .classic-facelift-skin .input-container:not(.is-disabled) > .input:hover::placeholder {
    color: #3b4057; }

.classic-facelift-skin .input-container:not(.is-disabled) > .input:focus {
  background-color: #fff;
  border-color: #80b1ff; }
  .classic-facelift-skin .input-container:not(.is-disabled) > .input:focus::placeholder {
    color: #acafc4; }
  .classic-facelift-skin .input-container:not(.is-disabled) > .input:focus::selection {
    background-color: #d6e6fe; }

.classic-facelift-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:focus {
  background-color: #d6e6fe;
  color: #3b4057; }

.classic-facelift-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin .composite-slider:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:hover {
  background-color: #d6e6fe; }

.classic-facelift-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin .composite-slider:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input {
  background-color: #e7f0ff;
  color: #3b4057; }
  .classic-facelift-skin .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:focus {
    background-color: #fff; }

.classic-facelift-skin .composite-corner-radius-input:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus),
.classic-facelift-skin .composite-corner-radius-input-labeled:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus),
.classic-facelift-skin .control-corner-radius-input:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus) {
  background-color: #e7f0ff;
  color: #3b4057; }

.classic-facelift-skin .input-container.is-disabled > .input-text-suffix {
  color: #7a7a7a; }

.classic-facelift-skin .input-container:not(.is-disabled):hover > .input-text-suffix {
  color: #3b4057; }

.classic-facelift-skin .control-corner .input-container > .input-text-suffix {
  color: #000624; }

.classic-facelift-skin .control-corner-radius-input:hover .input-container > .input-text-suffix {
  color: #3b4057; }

.classic-facelift-skin .control-editable-url.focus .input-container > .input-editable-url {
  border-color: #80b1ff; }

.classic-facelift-skin .control-editable-url.focus .input-container.invalid > .input-editable-url {
  border-color: #e62214; }

.classic-facelift-skin .composite-input-with-drop-down .input-container:not(.is-disabled) .control-numeric-input.input:hover {
  background-color: #e7f0ff;
  border-radius: 4px;
  color: #3b4057; }

.classic-facelift-skin .composite-input-with-drop-down .input-container:not(.is-disabled) .control-numeric-input.input:focus {
  background-color: #fff; }

.classic-facelift-skin.input-borders-experiment .input-container:not(.is-disabled) > .input:hover:not(:focus) {
  background-color: #e7f0ff;
  border-color: #a8caff; }

.classic-facelift-skin.input-borders-experiment .input-container:not(.is-disabled) > .input:focus {
  background-color: #fff;
  border-color: #116dff; }

.classic-facelift-skin.input-borders-experiment .input-container:not(.is-disabled).invalid > .input:focus {
  border-color: #e62214; }

.classic-facelift-skin.input-borders-experiment .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin.input-borders-experiment .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin.input-borders-experiment .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
.classic-facelift-skin.input-borders-experiment .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:focus {
  background-color: #fff;
  color: unset; }

.classic-facelift-skin.input-borders-experiment .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin.input-borders-experiment .composite-slider:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin.input-borders-experiment .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:hover,
.classic-facelift-skin.input-borders-experiment .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:hover {
  background-color: #e7f0ff;
  color: #3b4057; }

.classic-facelift-skin.input-borders-experiment .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin.input-borders-experiment .composite-slider:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin.input-borders-experiment .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input,
.classic-facelift-skin.input-borders-experiment .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input {
  background-color: #fff;
  color: unset; }
  .classic-facelift-skin.input-borders-experiment .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin.input-borders-experiment .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin.input-borders-experiment .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin.input-borders-experiment .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
  .classic-facelift-skin.input-borders-experiment .composite-text-input-labeled:hover .input-container:not(.is-disabled) > .input:focus {
    background-color: #fff; }

.classic-facelift-skin.input-borders-experiment .composite-corner-radius-input:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus),
.classic-facelift-skin.input-borders-experiment .composite-corner-radius-input-labeled:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus),
.classic-facelift-skin.input-borders-experiment .control-corner-radius-input:hover .input-container .control-numeric-input > .input:not(:hover):not(:focus) {
  background-color: unset;
  color: unset; }

.classic-facelift-skin.input-borders-experiment .input-container.is-disabled > .input-text-suffix {
  color: #7a7a7a; }

.classic-facelift-skin.input-borders-experiment .input-container:not(.is-disabled):hover > .input-text-suffix {
  color: #3b4057; }

.classic-facelift-skin.input-borders-experiment .control-corner .input-container > .input-text-suffix {
  color: #000624; }

.classic-facelift-skin.input-borders-experiment .control-corner-radius-input:hover .input-container > .input-text-suffix {
  color: #3b4057; }

.classic-facelift-skin.input-borders-experiment .control-editable-url.focus .input-container > .input-editable-url {
  border-color: #116dff;
  border-radius: 4px; }

.classic-facelift-skin.input-borders-experiment .control-editable-url.focus .input-container.invalid > .input-editable-url {
  border-color: #e62214; }

.classic-facelift-skin.input-borders-experiment .composite-input-with-drop-down .input-container:not(.is-disabled) .control-numeric-input.input:hover {
  background-color: #e7f0ff;
  border-radius: unset;
  color: #3b4057; }

.classic-facelift-skin.input-borders-experiment .composite-input-with-drop-down .input-container:not(.is-disabled) .control-numeric-input.input:focus {
  background-color: #000624; }

.responsive-skin.dark-mode .input-container > .input {
  border-color: transparent;
  color: #f4f5f8;
  background-color: #2B2E36 !important; }
  .responsive-skin.dark-mode .input-container > .input::selection {
    background-color: #c3daff; }
  .responsive-skin.dark-mode .input-container > .input::placeholder {
    color: #868aa5 !important; }
  .responsive-skin.dark-mode .input-container:not(.is-disabled) > .input:hover {
    color: #f4f5f8;
    border: 1px solid #868aa5; }
    .responsive-skin.dark-mode .input-container:not(.is-disabled) > .input:hover > .input-text-suffix {
      color: #f4f5f8; }
  .responsive-skin.dark-mode .input-container:not(.is-disabled) > .input:focus {
    border: 1px solid #116dff;
    color: #f4f5f8; }
    .responsive-skin.dark-mode .input-container:not(.is-disabled) > .input:focus::selection {
      background-color: #868aa5; }

.responsive-skin.dark-mode .input-container > .input-prefix {
  color: #f4f5f8; }
  .responsive-skin.dark-mode .input-container > .input-prefix svg path {
    fill: #f4f5f8; }

.responsive-skin.dark-mode .input-container > .input-text-suffix {
  color: #868aa5; }

.responsive-skin.dark-mode .input-container:hover:not(.is-disabled) > .input-text-suffix {
  color: #868aa5; }

.responsive-skin.dark-mode .input-container:not(.is-disabled).invalid > .input:focus {
  border-color: #ee4437; }

.input-container {
  display: flex;
  position: relative; }
  :not(.composite-text-input-labeled) > .input-container.control-numeric-input {
    min-width: 66px; }
  .composite-text-input > .input-container.control-numeric-input {
    width: 100%; }
  .input-container.control-numeric-input.numeric-corner {
    min-width: 48px;
    width: 48px; }
  .control-angle-input:not(.disabled) .input-container.control-numeric-input {
    -webkit-user-select: none;
            user-select: none; }
  .control-angle-input .input-container.control-numeric-input,
  .composite-color-picker-input .control-slider-input .input-container.control-numeric-input {
    min-width: 54px; }
  .color-picker-color-format .input-container.control-numeric-input {
    min-width: 48px; }
  .control-angle-input .input-container.control-numeric-input {
    width: 54px; }
    .input-borders-experiment .control-angle-input .input-container.control-numeric-input {
      width: 54px; }
  .control-slider-input .input-container.control-numeric-input {
    margin-left: 12px; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height .input-container.input-multiline,
  .composite-text-input-multiline-labeled.composite-full-height .input-container.input-multiline {
    height: calc(100% - 48px); }
  .input-container > .input-prefix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    line-height: 35px;
    padding: 0 9px 0 12px;
    color: #b6c1cd;
    display: flex; }
    .control-numeric-input.input-container > .input-prefix {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      padding-right: 18px; }
    .input-container:not(.is-disabled) > .input-prefix.clickable {
      cursor: pointer; }
      .input-container:not(.is-disabled) > .input-prefix.clickable:hover + .input:not(:focus) {
        background-color: #e7f0ff; }
    .composite-numeric-input .input-container > .input-prefix {
      padding-left: 0;
      width: 100%; }
  .input-container .with-info-icon .input-prefix-text {
    padding-right: 6px; }
  .composite-numeric-input .input-container .info-icon-tooltip ~ .input {
    margin-left: auto; }
  .input-container .infoIcon {
    display: flex; }
  .input-container > .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    height: 36px;
    padding: 0 11px 0;
    outline: none;
    color: #000624;
    text-align: left;
    text-overflow: ellipsis;
    width: 100%; }
    .input-borders-experiment .input-container > .input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      border: 1px solid #a8caff;
      border-radius: 4px; }
    .input-container > .input::placeholder {
      color: #b6c1cd; }
    .input-container > .input.has-text-suffix {
      padding: 0 5px; }
    .input-container > .input:focus ~ .input-text-suffix {
      display: none; }
    .success.input-container > .input:not(:focus), .invalid.input-container > .input:not(:focus) {
      width: calc(100% - 42px); }
      .input-borders-experiment .success.input-container > .input:not(:focus), .input-borders-experiment .invalid.input-container > .input:not(:focus) {
        width: calc(100% - 30px); }
    .input-multiline.success.input-container > .input:not(:focus), .input-multiline.invalid.input-container > .input:not(:focus) {
      width: calc(100% - 42px); }
      .input-borders-experiment .input-multiline.success.input-container > .input:not(:focus), .input-borders-experiment .input-multiline.invalid.input-container > .input:not(:focus) {
        width: calc(100% - 30px); }
    :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
      width: 66px;
      text-align: right;
      text-overflow: clip; }
      .color-picker-color-format :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
        width: 48px; }
    .input-borders-experiment :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
      width: 66px; }
    .composite-text-input .control-numeric-input.input-container > .input {
      width: 100%;
      text-align: left;
      text-overflow: ellipsis; }
    .control-corner .control-numeric-input.input-container > .input {
      width: 48px;
      height: 24px;
      font-size: 12px; }
      .input-borders-experiment .control-corner .control-numeric-input.input-container > .input {
        width: 48px; }
    .composite-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus),
    .composite-corner-radius-input-labeled:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus),
    .control-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus) {
      background-color: #e7f0ff;
      color: #3b4057; }
    .input-borders-experiment .composite-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus), .input-borders-experiment
    .composite-corner-radius-input-labeled:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus), .input-borders-experiment
    .control-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus) {
      background-color: #fff;
      color: unset; }
    .control-angle-input .control-numeric-input.input-container > .input,
    .composite-color-picker-input .control-slider-input .control-numeric-input.input-container > .input {
      width: 54px; }
      .input-borders-experiment .control-angle-input .control-numeric-input.input-container > .input, .input-borders-experiment
      .composite-color-picker-input .control-slider-input .control-numeric-input.input-container > .input {
        width: 54px; }
    .percent-unit.control-numeric-input.input-container > .input {
      padding-right: 17px; }
    .px-unit.control-numeric-input.input-container > .input {
      padding-right: 18px; }
    .spx-unit.control-numeric-input.input-container > .input {
      padding-right: 22px; }
    .custom-unit.control-numeric-input.input-container > .input {
      padding-right: 24px; }
    .seconds-unit.control-numeric-input.input-container > .input {
      padding-right: 10px; }
    .angle-unit.control-numeric-input.input-container > .input {
      padding-right: 10px; }
    .is-disabled.input-container > .input {
      color: #7a7a7a;
      background-color: transparent; }
      .input-borders-experiment .is-disabled.input-container > .input {
        border-color: #e0e0e0;
        background-color: #fff; }
    .invalid.input-container:not(.is-disabled) > .input:focus {
      border: 1px solid #e62214; }
    .input-container:not(.is-disabled) > .input:hover {
      background-color: #d6e6fe;
      color: #3b4057;
      cursor: pointer; }
      .input-borders-experiment .input-container:not(.is-disabled) > .input:hover {
        background-color: #e7f0ff; }
      .input-container:not(.is-disabled) > .input:hover::placeholder {
        color: #3b4057; }
    .input-container:not(.is-disabled) > .input:focus {
      background-color: #fff;
      border: 1px solid #80b1ff;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25);
      cursor: text; }
      .input-borders-experiment .input-container:not(.is-disabled) > .input:focus {
        border: 1px solid #116dff;
        box-shadow: none; }
      .composite-time-range:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
        background-color: #fff; }
      .input-borders-experiment .composite-time-range:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-slider:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
      .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
        background-color: unset; }
      .input-container:not(.is-disabled) > .input:focus::placeholder {
        color: #b6c1cd; }
      .input-container:not(.is-disabled) > .input:focus::selection {
        background-color: #d6e6fe; }
      .input-container:not(.is-disabled) > .input:focus:read-only {
        border-color: #d5d5d5; }
    .composite-time-range:hover .input-container:not(.is-disabled) > .input,
    .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input,
    .composite-slider:hover .input-container:not(.is-disabled) > .input,
    .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input,
    .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input,
    .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input,
    .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input {
      background-color: #e7f0ff;
      color: #3b4057; }
      .composite-time-range:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-slider:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus,
      .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
        background-color: #fff; }
    .input-borders-experiment .composite-time-range:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-slider:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input, .input-borders-experiment
    .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input {
      background-color: unset;
      color: unset; }
    .input-borders-experiment .composite-time-range:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-slider:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-text-input-multiline-labeled:hover .input-container:not(.is-disabled) > .input:focus, .input-borders-experiment
    .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
      background-color: unset; }
    .input-multiline.input-container > .input {
      height: 108px;
      line-height: 24px;
      padding-top: 5px;
      padding-bottom: 7px;
      resize: none;
      overflow: hidden;
      padding: 5px 12px 7px;
      transition: height 200ms ease; }
      .grow-on-focus.input-multiline.input-container > .input:focus {
        overflow: auto;
        height: 156px; }
      .input-large.input-multiline.input-container > .input {
        height: 252px;
        line-height: 24px;
        padding-top: 5px;
        padding-bottom: 7px; }
        .grow-on-focus.input-large.input-multiline.input-container > .input:focus {
          overflow: auto;
          height: 300px; }
      .composite-text-input-multiline-with-button-labeled.composite-full-height .input-multiline.input-container > .input,
      .composite-text-input-multiline-labeled.composite-full-height .input-multiline.input-container > .input {
        height: 100%; }
  .input-container > .input-text-suffix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    position: absolute;
    right: 5px;
    pointer-events: none; }
    .is-disabled.input-container > .input-text-suffix {
      color: #7a7a7a; }
    .input-container:hover:not(.is-disabled) > .input-text-suffix {
      color: #3b4057; }
    .color-picker-color-format-input-container .input-container > .input-text-suffix {
      line-height: 24px; }
    .percent-unit.input-container > .input-text-suffix {
      bottom: 9px; }
      .color-picker-color-format-input-container .percent-unit.input-container > .input-text-suffix {
        bottom: 0; }
    .px-unit.input-container > .input-text-suffix, .spx-unit.input-container > .input-text-suffix, .custom-unit.input-container > .input-text-suffix {
      bottom: 9px; }
    .angle-unit.input-container > .input-text-suffix {
      top: 9px; }
      .color-picker-color-format-input-container .angle-unit.input-container > .input-text-suffix {
        top: 0; }
    .control-corner .input-container > .input-text-suffix {
      color: #000624;
      bottom: 4.5px;
      -webkit-user-select: none;
              user-select: none; }
    .control-corner-radius-input:hover .input-container > .input-text-suffix {
      color: #3b4057; }
  .input-container > .input-validation-icon {
    position: absolute;
    height: calc(100% + 6px);
    width: 25px;
    right: 12px;
    top: -3px;
    z-index: 1;
    display: flex;
    align-items: center; }
    .input-borders-experiment .input-container > .input-validation-icon {
      right: 0; }
    .input-multiline.input-container > .input-validation-icon {
      height: 100%;
      top: 5px;
      align-items: flex-start; }
    .control-editable-url .input-container > .input-validation-icon {
      right: 6px; }
    .input-container > .input-validation-icon > .input-validation-icon-inner {
      display: flex;
      align-items: center; }
  .input-container > .input:focus ~ .input-validation-icon,
  .input-container > .input-editable-url:focus ~ .input-validation-icon {
    visibility: hidden; }
  .control-editable-url .input-container {
    height: 100%;
    flex: 1; }
    .control-editable-url .input-container.invalid > .input-editable-url:not(:focus), .control-editable-url .input-container.success > .input-editable-url:not(:focus) {
      width: calc(100% - 36px); }
    .control-editable-url .input-container > .input-editable-url {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      height: 100%;
      color: #3b4057;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      transition: padding ease-out 150ms; }
      .input-borders-experiment .control-editable-url .input-container > .input-editable-url {
        border-radius: unset; }
      .control-editable-url .input-container > .input-editable-url::selection {
        background-color: #d6e6fe;
        color: #fff; }
      .control-editable-url .input-container > .input-editable-url:read-only {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        cursor: default;
        background-color: #fff; }
  .control-editable-url.focus .input-container > .input-editable-url {
    border-color: #80b1ff;
    box-shadow: inset 0 0 3px 0 rgba(59, 64, 87, 0.55);
    padding-left: 5px;
    margin-left: 2px; }
    .input-borders-experiment .control-editable-url.focus .input-container > .input-editable-url {
      box-shadow: unset; }
  .control-editable-url.focus .input-container.invalid > .input-editable-url {
    border-color: #e62214; }
  .control-editable-url.with-edit-buttons:not(.focus) .input-container > .input-editable-url {
    pointer-events: none; }

.composite-input-with-drop-down .input-container .control-numeric-input {
  padding-right: 44px; }
  .composite-input-with-drop-down .input-container .control-numeric-input.input {
    width: 100%; }
    .composite-input-with-drop-down:not(.is-disabled) .input-container .control-numeric-input.input:hover {
      background-color: #e7f0ff;
      border-radius: 4px;
      color: #3b4057; }
      .input-borders-experiment .composite-input-with-drop-down:not(.is-disabled) .input-container .control-numeric-input.input:hover {
        border-radius: unset; }
    .composite-input-with-drop-down:not(.is-disabled) .input-container .control-numeric-input.input:focus {
      background-color: #fff; }

.classic-facelift-skin .composite-corner-radius-input:hover .control-corner-radius-input.locked .control-corner .corner-border,
.classic-facelift-skin .composite-corner-radius-input-labeled:hover .control-corner-radius-input.locked .control-corner .corner-border,
.classic-facelift-skin .control-corner-radius-input.locked:hover .control-corner .corner-border {
  border-color: #80b1ff; }

.classic-facelift-skin .composite-corner-radius-input:hover .control-corner-radius-input .control-corner .numeric-corner:hover ~ .corner-border,
.classic-facelift-skin .composite-corner-radius-input-labeled:hover .control-corner-radius-input .control-corner .numeric-corner:hover ~ .corner-border,
.classic-facelift-skin .control-corner-radius-input:hover .control-corner .numeric-corner:hover ~ .corner-border {
  border-color: #80b1ff; }

.control-corner {
  display: flex; }
  .control-corner.top {
    flex-direction: column; }
  .control-corner.bottom {
    flex-direction: column-reverse; }
  .control-corner.right {
    align-items: flex-end; }
  .control-corner.left {
    align-items: flex-start; }
  .control-corner .corner-border {
    width: 40px;
    height: 22px;
    border: 0 solid #868aa5; }
    .top.control-corner .corner-border {
      border-top-width: 2px;
      margin-top: 6px; }
    .bottom.control-corner .corner-border {
      border-bottom-width: 2px;
      margin-bottom: 6px; }
    .right.control-corner .corner-border {
      border-right-width: 2px;
      margin-right: 42px; }
    .left.control-corner .corner-border {
      border-left-width: 2px;
      margin-left: 42px; }
    .composite-corner-radius-input:hover .control-corner-radius-input.locked .control-corner .corner-border,
    .composite-corner-radius-input-labeled:hover .control-corner-radius-input.locked .control-corner .corner-border,
    .control-corner-radius-input.locked:hover .control-corner .corner-border {
      border-color: #80b1ff; }
  .control-corner .numeric-corner:hover ~ .corner-border {
    border-color: #80b1ff; }

.classic-facelift-skin .control-boolean.text-formatting-toggle-button {
  border-radius: 4px; }
  .classic-facelift-skin .control-boolean.text-formatting-toggle-button svg {
    fill: #3b4057; }
    .classic-facelift-skin .control-boolean.text-formatting-toggle-button svg .highlightcolor-custom-color,
    .classic-facelift-skin .control-boolean.text-formatting-toggle-button svg .texttcolor-custom-color {
      fill: transparent; }
  .classic-facelift-skin .control-boolean.text-formatting-toggle-button:not(.disabled):active, .classic-facelift-skin .control-boolean.text-formatting-toggle-button:not(.disabled).selected {
    background-color: #116dff; }
    .classic-facelift-skin .control-boolean.text-formatting-toggle-button:not(.disabled):active:hover, .classic-facelift-skin .control-boolean.text-formatting-toggle-button:not(.disabled).selected:hover {
      background-color: #5999ff; }
  .classic-facelift-skin .control-boolean.text-formatting-toggle-button:not(.disabled):not(.selected):not(:active):hover {
    background-color: #e7f0ff; }
  .classic-facelift-skin .control-boolean.text-formatting-toggle-button.disabled svg {
    fill: #bcbcbc; }

.classic-facelift-skin .control-corner-radius-input.locked .control-boolean {
  background-color: #116dff;
  border-color: #116dff; }
  .classic-facelift-skin .control-corner-radius-input.locked .control-boolean:hover {
    background-color: #5999ff;
    border-color: #5999ff; }

.classic-facelift-skin .control-corner-radius-input.unlocked .control-boolean {
  background-color: #fff;
  border-color: #dfe5eb; }
  .classic-facelift-skin .control-corner-radius-input.unlocked .control-boolean:hover {
    background-color: #d6e6fe;
    border-color: #d6e6fe; }

.responsive-skin .control-boolean.text-formatting-toggle-button {
  border-radius: 4px; }
  .responsive-skin .control-boolean.text-formatting-toggle-button svg {
    fill: #131720; }
  .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled):active, .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled).selected {
    background-color: #dce9ff; }
    .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled):active svg, .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled).selected svg {
      fill: #116dff; }
    .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled):active:hover, .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled).selected:hover {
      background-color: #dce9ff; }
  .responsive-skin .control-boolean.text-formatting-toggle-button:not(.disabled):not(.selected):not(:active):hover {
    background-color: #f4f5f8; }
  .responsive-skin .control-boolean.text-formatting-toggle-button.disabled svg {
    fill: #bebebe; }

.control-boolean {
  cursor: pointer;
  display: flex; }
  .control-boolean.control-switch {
    align-items: stretch;
    justify-content: space-between; }
  .control-boolean.disabled {
    cursor: default; }
  .control-boolean > .input {
    display: none; }
  .control-boolean.text-formatting-toggle-button {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .control-boolean.text-formatting-toggle-button > .symbol {
      display: flex;
      align-items: center;
      justify-content: center; }
    .control-boolean.text-formatting-toggle-button:not(.disabled):active, .control-boolean.text-formatting-toggle-button:not(.disabled).selected {
      background-color: #116dff; }
      .control-boolean.text-formatting-toggle-button:not(.disabled):active svg, .control-boolean.text-formatting-toggle-button:not(.disabled).selected svg {
        fill: #fff; }
      .control-boolean.text-formatting-toggle-button:not(.disabled):active:hover, .control-boolean.text-formatting-toggle-button:not(.disabled).selected:hover {
        background-color: #5999ff; }
    .control-boolean.text-formatting-toggle-button:not(.disabled):not(.selected):not(:active):hover {
      background-color: #e7f0ff; }
    .control-boolean.text-formatting-toggle-button .dropdown-selected .selected-container .selected-content .symbol svg {
      padding-top: 4px; }
  .composite-text-formatting .control-boolean {
    margin: 0 4px; }
  .control-corner-radius-input .control-boolean {
    border-radius: 6px;
    align-self: center;
    width: 24px;
    flex: 22px 0 0;
    border: 1px solid; }
    .control-corner-radius-input .control-boolean .symbol {
      width: 100%;
      display: flex;
      justify-content: center; }
      .control-corner-radius-input .control-boolean .symbol svg {
        display: block;
        align-self: center; }
  .control-corner-radius-input.locked .control-boolean {
    background-color: #116dff;
    border-color: #116dff; }
    .control-corner-radius-input.locked .control-boolean svg {
      fill: #fff; }
    .control-corner-radius-input.locked .control-boolean:hover {
      background-color: #5999ff;
      border-color: #5999ff; }
  .control-corner-radius-input.unlocked .control-boolean {
    background-color: #fff;
    border-color: #dfe5eb; }
    .control-corner-radius-input.unlocked .control-boolean svg {
      fill: #868aa5; }
    .control-corner-radius-input.unlocked .control-boolean:hover {
      background-color: #d6e6fe;
      border-color: #d6e6fe; }

.control-corner-radius-input {
  width: 192px;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .control-corner-radius-input .top,
  .control-corner-radius-input .bottom {
    display: flex;
    justify-content: space-between; }
  .composite-corner-radius-input .control-corner-radius-input {
    padding: 12px 24px 24px; }
  .composite-corner-radius-input-labeled .control-corner-radius-input {
    padding: 9px 24px 14px; }

.responsive-skin .collapse-label-switch[aria-checked="true"] {
  border-bottom: none; }

.responsive-skin .control-collapse-label > .collapse-label-switch {
  background-color: #f4f5f8;
  height: 40px; }
  .responsive-skin .control-collapse-label > .collapse-label-switch.skin-section {
    background-color: #fff;
    letter-spacing: 0; }
    .responsive-skin .control-collapse-label > .collapse-label-switch.skin-section .control-label-base {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .control-collapse-label > .collapse-label-switch.skin-section[aria-checked="true"] {
      border-bottom: none; }
  .responsive-skin .control-collapse-label > .collapse-label-switch .control-label-base {
    font-size: 12px;
    line-height: 16px; }

.responsive-skin .control-collapse-label.with-bottom-divider > .collapse-label-switch {
  border-bottom: 1px solid #e5e5e5; }

.responsive-skin .control-collapse-label.with-bottom-divider > .collapsed-content-container {
  border-bottom: 1px solid #e5e5e5; }

.responsive-skin .disabled.control-collapse-label > .collapse-label-switch > .switch-open > svg {
  fill: #bebebe; }

.responsive-skin .suffix-wrapper {
  margin-right: 8px; }
  .responsive-skin .suffix-wrapper .suffix-item:not(:first-child) {
    margin-left: 4px; }

.responsive-skin .prefix-wrapper {
  margin-right: 8px; }

.classic-facelift-skin .control-collapse-label > .collapse-label-switch {
  background-color: #fff;
  height: 42px; }
  .classic-facelift-skin .control-collapse-label > .collapse-label-switch:hover:not(.disabled) {
    background-color: #e7f0ff; }
  .classic-facelift-skin .control-collapse-label > .collapse-label-switch.skin-section {
    background-color: #f7f8f8; }
    .classic-facelift-skin .control-collapse-label > .collapse-label-switch.skin-section:hover:not(.disabled) {
      background-color: #dfe5eb; }

.classic-facelift-skin .control-collapse-label.with-bottom-divider > .collapse-label-switch {
  border-bottom: 1px solid #e0e0e0; }

.classic-facelift-skin .disabled.control-collapse-label > .collapse-text-label {
  color: #898989;
  letter-spacing: normal; }

.classic-facelift-skin .disabled.control-collapse-label > .collapse-label-switch .switch-open > svg {
  fill: #898989; }

.classic-facelift-skin .suffix-wrapper {
  margin-right: 6px; }
  .classic-facelift-skin .suffix-wrapper .suffix-item:not(:first-child) {
    margin-left: 6px; }

.classic-facelift-skin .prefix-wrapper {
  margin-right: 12px; }

.control-collapse-label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%; }
  .control-collapse-label > .collapse-label-switch {
    padding: 0 24px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    cursor: pointer; }
    .control-collapse-label > .collapse-label-switch .left-wrapper {
      margin-right: 12px;
      display: flex;
      align-items: center;
      min-width: 0; }
    .control-collapse-label > .collapse-label-switch .right-wrapper {
      display: flex;
      align-items: center; }
    .disabled.control-collapse-label > .collapse-label-switch {
      cursor: default; }
    .control-collapse-label > .collapse-label-switch .switch-open {
      display: flex;
      align-items: center; }
      .control-collapse-label > .collapse-label-switch .switch-open.closed {
        transform: rotate(270deg); }
    .control-collapse-label > .collapse-label-switch .collapse-text-label {
      margin-right: 12px;
      overflow: hidden;
      display: flex;
      align-items: center; }
    .control-collapse-label > .collapse-label-switch .suffix-wrapper {
      display: flex;
      align-items: center;
      max-width: 100%; }
      .control-collapse-label > .collapse-label-switch .suffix-wrapper .suffix-item {
        display: flex;
        align-items: center; }
        .control-collapse-label > .collapse-label-switch .suffix-wrapper .suffix-item > .control-context-menu > .dropdown-selected {
          display: inline-flex; }
    .control-collapse-label > .collapse-label-switch:not(:hover) .suffix-wrapper:not(.show-suffix) {
      visibility: hidden;
      max-width: 0;
      transition: max-width 0s 0.2s; }
  .control-collapse-label .prefix-wrapper {
    display: flex;
    align-items: center; }
  .control-collapse-label .collapsed-content-container {
    display: block; }

.control-base-modal {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: #000624;
  width: 510px;
  min-width: 510px; }
  .control-base-modal .control-base-modal-header {
    position: relative;
    border-top: 6px solid #116dff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 30px; }
    .control-base-modal .control-base-modal-header-btn-bar {
      position: absolute;
      z-index: 2;
      top: 6px;
      right: 9px;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .control-base-modal .control-base-modal-header-btn {
      margin-left: 6px; }
      .control-base-modal .control-base-modal-header-btn svg {
        fill: #000624; }
      .control-base-modal .control-base-modal-header-btn svg mask#d,
      .control-base-modal .control-base-modal-header-btn svg mask#b {
        display: none; }
  .control-base-modal.theme-destructive .control-base-modal-header {
    border-top-color: #e62214; }
  .control-base-modal.theme-premium .control-base-modal-header {
    border-top-color: #9a27d5; }
  .control-base-modal .control-base-modal-body {
    position: relative;
    padding: 0 30px 30px;
    display: flex; }
  .control-base-modal .control-base-modal-footer {
    padding: 0 30px 30px;
    display: flex;
    justify-content: flex-end; }
  .control-base-modal .control-base-modal-btn {
    margin-left: 12px; }
  .control-base-modal .control-base-modal-side-actions {
    flex: 1 1 auto;
    align-self: center;
    overflow: hidden; }
  .control-base-modal .control-base-modal-footnote {
    padding: 12px 30px;
    text-align: center; }
    .control-base-modal .control-base-modal-footnote a {
      color: #116dff; }

.responsive-skin .control-custom-modal.control-custom-modal {
  max-width: 1254px;
  min-width: 512px; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-header {
    border-top: 0;
    padding-top: 8px;
    margin-bottom: 12px; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 8px;
    padding-right: 8px;
    padding-left: 24px;
    border-bottom-color: #e5e5e5; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text {
      margin-bottom: 12px;
      margin-top: 4px; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text .control-custom-modal-title .control-text {
        font-size: 16px;
        line-height: 24px;
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        color: #131720; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text .control-custom-modal-subtitle {
        margin-top: 4px; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text .control-custom-modal-subtitle .control-text {
          font-size: 14px;
          line-height: 20px;
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          color: #42454C; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar {
      margin-top: 4px;
      margin-left: 12px;
      display: contents; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn {
        background: #fff;
        width: 24px;
        height: 24px;
        padding: 6px;
        border-radius: 4px;
        margin: 0; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn > .symbol path {
          fill: #131720; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:hover {
          background-color: #fff; }
          .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:hover > .symbol path {
            fill: #131720; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:focus-visible {
          background-color: #fff; }
          .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:focus-visible > .symbol path {
            fill: #131720; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:active {
          background-color: #fff; }
          .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:active > .symbol path {
            fill: #131720; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:disabled {
          cursor: default;
          background-color: #f3f3f3;
          border: none; }
          .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn:disabled > .symbol path {
            fill: #bebebe; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn-help {
        margin-right: 4px; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-body {
    padding-bottom: 0; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-body .control-custom-modal-content-wrapper {
      margin-bottom: 16px;
      padding-bottom: 0;
      padding-right: 24px;
      padding-left: 24px; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-body .control-custom-modal-content-wrapper.without-padding {
        padding: 0; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer {
    padding-top: 16px;
    padding-bottom: 0;
    margin-bottom: 20px;
    padding-right: 24px;
    padding-left: 24px; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .control-base-modal-side-actions {
      margin-right: 12px; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary {
      margin-left: 0;
      border-radius: 4px;
      background-color: #116dff;
      color: #fff;
      border: 1px solid transparent;
      height: 32px;
      font-size: 14px;
      line-height: 20px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      padding: 6px 16px;
      border-radius: 4px; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary:hover {
        background-color: #0f62e6;
        color: #fff; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary:focus-visible {
        background-color: #0f62e6;
        color: #fff; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary:active {
        background-color: #116dff;
        color: #fff; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary[disabled] {
        cursor: default;
        color: #bebebe;
        background-color: #f3f3f3; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary[disabled].suffix-icon svg *, .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-primary[disabled].prefix-icon svg * {
          fill: #bebebe; }
    .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary {
      margin-left: 0;
      margin-right: 4px;
      border-color: transparent;
      background: none;
      color: #116dff;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased;
      height: 32px;
      font-size: 14px;
      line-height: 20px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      padding: 6px 16px;
      border-radius: 4px; }
      .dropdown-options .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary:hover:not(:disabled) > .button-content {
        color: #116dff;
        text-decoration: none; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary:disabled {
        color: #767676; }
      .composite-button-left .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .composite-action-set-vertical .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 16px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased; }
      .composite-button-large-fixed-bottom .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary,
      .composite-button-large .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.footer-btn {
        color: #116dff; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.footer-btn:disabled {
          color: #bebebe; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:not([disabled="true"]),
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:hover > .button-content {
        color: #ee4437; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:not([disabled="true"]) svg path,
        .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:hover > .button-content svg path {
          fill: #ee4437; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary:hover {
        background-color: transparent;
        text-decoration: none; }
        .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary:hover .button-content {
          text-decoration: none; }
      .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footer .btn-confirm-secondary.btn-sm {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 12px;
        line-height: 16px;
        -webkit-font-smoothing: antialiased; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-custom-modal-footer {
    padding-top: 16px; }
  .responsive-skin .control-custom-modal.control-custom-modal .divider-long {
    background-color: #e5e5e5; }
  .responsive-skin .control-custom-modal.control-custom-modal .control-base-modal-footnote {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-right: 24px;
    padding-left: 24px; }

.control-custom-modal.control-custom-modal {
  width: auto;
  max-width: 1254px;
  display: flex;
  flex-direction: column; }
  .control-custom-modal.control-custom-modal.without-header-padding .control-base-modal-header {
    padding-top: 0; }
  .control-custom-modal.control-custom-modal .control-custom-modal-header {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    padding: 12px 30px;
    margin-bottom: 12px;
    border-bottom: 1px solid #dfe5eb; }
    .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text {
      flex: 1;
      overflow: hidden; }
      .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text .control-custom-modal-title .control-text {
        font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-bold); }
      .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-text .control-custom-modal-subtitle {
        margin-top: 3px; }
    .control-custom-modal.control-custom-modal .control-custom-modal-header .control-base-modal-header-btn-bar {
      position: relative;
      top: initial;
      right: initial;
      margin: -6px -21px 0 0; }
    .control-custom-modal.control-custom-modal .control-custom-modal-header .control-custom-modal-header-subheader {
      flex: 0 0 100%;
      order: 1; }
  .control-custom-modal.control-custom-modal .control-custom-modal-header-with-subheader {
    padding-bottom: 0; }
  .control-custom-modal.control-custom-modal .control-custom-modal-footer {
    padding: 0 30px 30px; }
  .control-custom-modal.control-custom-modal .control-base-modal-body {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0; }
    .control-custom-modal.control-custom-modal .control-base-modal-body .custom-scroll,
    .control-custom-modal.control-custom-modal .control-base-modal-body .custom-scroll > .outer-container {
      display: flex;
      flex-direction: column;
      flex: 1; }
  .control-custom-modal.control-custom-modal .control-custom-modal-content-wrapper {
    overflow: auto;
    flex: 1;
    min-height: 24px;
    padding: 0 30px; }
    .control-custom-modal.control-custom-modal .control-custom-modal-content-wrapper.without-padding {
      padding: 0; }

.classic-facelift-skin .custom-scroll .inner-handle {
  background-color: rgba(134, 138, 165, 0.3); }

.custom-scroll {
  min-height: 0;
  min-width: 0; }
  .custom-scroll .outer-container {
    overflow: hidden; }
    .custom-scroll .outer-container .positioning {
      position: relative; }
    .custom-scroll .outer-container:hover .custom-scrollbar {
      opacity: 1;
      transition-duration: 0.2s; }
  .custom-scroll .inner-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .custom-scroll .inner-container:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
      transition: height 0.1s ease-in;
      will-change: height; }
    .custom-scroll .inner-container.content-scrolled:after {
      height: 5px;
      transition: height 0.15s ease-out; }
  .custom-scroll.scroll-handle-dragged .inner-container {
    -webkit-user-select: none;
            user-select: none; }
  .custom-scroll .custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: 3px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none; }
    .custom-scroll .custom-scrollbar.custom-scrollbar-rtl {
      right: auto;
      left: 3px; }
  .custom-scroll.scroll-handle-dragged .custom-scrollbar {
    opacity: 1; }
  .custom-scroll .custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0; }
  .custom-scroll .inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: rgba(89, 153, 255, 0.7);
    border-radius: 3px; }

.tooltip-on-ellipsis-content {
  position: relative; }
  .tooltip-on-ellipsis-content::after {
    content: '';
    display: block; }
  .tooltip-on-ellipsis-content.singleLine {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .tooltip-on-ellipsis-content.singleLine :first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .tooltip-on-ellipsis-content.multiLine {
    display: -webkit-box;
    -webkit-line-clamp: var(--maxLines, 1);
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden; }
    .tooltip-on-ellipsis-content.multiLine :first-child {
      display: -webkit-box;
      -webkit-line-clamp: var(--maxLines, 1);
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden; }
  .tooltip-on-ellipsis-content :first-child::after {
    content: '';
    display: block; }

.responsive-skin.dark-mode .dropdown-options .control-section-divider {
  background-color: #131720; }
  .responsive-skin.dark-mode .dropdown-options .control-section-divider .section-divider-content {
    color: #e2e3ea; }

.responsive-skin .dropdown-options .control-section-divider {
  height: 40px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 6, 36, 0.21); }
  .responsive-skin .dropdown-options .control-section-divider .section-divider-content {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 40px;
    text-transform: uppercase;
    color: #868aa5; }

.classic-facelift-skin .control-section-divider {
  background-color: #f7f8f8; }

.classic-facelift-skin .composite-banner > .control-section-divider {
  background-color: #3b4057; }

.classic-facelift-skin .composite-banner-premium .control-section-divider {
  background-color: #f1e0f9; }

.classic-facelift-skin .control-section-divider .section-divider-content {
  color: #3b4057; }

.classic-facelift-skin .control-section-divider.disabled .section-divider-content {
  color: #939393; }

.classic-facelift-skin .composite-banner .control-section-divider .section-divider-content {
  color: #fff; }

.control-section-divider {
  height: 42px;
  box-sizing: border-box;
  background-color: #f7f8f8;
  padding: 0 24px;
  width: 100%; }
  .composite-section-divider-with-info-icon .control-section-divider {
    padding: 0 36px; }
  .control-section-divider.disabled {
    background-color: #f0f0f0; }
  .composite-banner > .control-section-divider {
    background-color: #3b4057;
    height: 30px; }
  .composite-banner-premium .control-section-divider {
    background-color: #eedbf4;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    max-height: 48px;
    overflow: hidden; }
  .control-section-divider .section-divider-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    line-height: 42px;
    text-align: center; }
    .disabled.control-section-divider .section-divider-content {
      color: #939393; }
    .dropdown-options .control-section-divider .section-divider-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 36px; }
    .composite-banner .control-section-divider .section-divider-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 30px;
      color: #fff; }
  .dropdown-options .control-section-divider {
    height: 36px; }

.responsive-skin .control-checkbox > .input ~ .label-checkbox.t07 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 20px; }

.responsive-skin .control-switch > .label-switch.t01,
.responsive-skin .control-rich-text.t01,
.responsive-skin .control-label-base.t01,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t01,
.responsive-skin .panel-header-title .control-label-base.t01,
.responsive-skin .panel-header-centered-text > .title.t01 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.responsive-skin .control-switch > .label-switch.t02,
.responsive-skin .control-rich-text.t02,
.responsive-skin .control-label-base.t02,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t02,
.responsive-skin .panel-header-title .control-label-base.t02,
.responsive-skin .panel-header-centered-text > .title.t02 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 20px; }

.responsive-skin .control-switch > .label-switch.t03,
.responsive-skin .control-rich-text.t03,
.responsive-skin .control-label-base.t03,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t03,
.responsive-skin .panel-header-title .control-label-base.t03,
.responsive-skin .panel-header-centered-text > .title.t03 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 10px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t04,
.responsive-skin .control-rich-text.t04,
.responsive-skin .control-label-base.t04,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t04,
.responsive-skin .panel-header-title .control-label-base.t04,
.responsive-skin .panel-header-centered-text > .title.t04 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t05,
.responsive-skin .control-rich-text.t05,
.responsive-skin .control-label-base.t05,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t05,
.responsive-skin .panel-header-title .control-label-base.t05,
.responsive-skin .panel-header-centered-text > .title.t05 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.responsive-skin .control-switch > .label-switch.t06,
.responsive-skin .control-rich-text.t06,
.responsive-skin .control-label-base.t06,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t06,
.responsive-skin .panel-header-title .control-label-base.t06,
.responsive-skin .panel-header-centered-text > .title.t06 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.responsive-skin .control-switch > .label-switch.t07,
.responsive-skin .control-rich-text.t07,
.responsive-skin .control-label-base.t07,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t07,
.responsive-skin .panel-header-title .control-label-base.t07,
.responsive-skin .panel-header-centered-text > .title.t07 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 20px; }

.responsive-skin .control-switch > .label-switch.t08,
.responsive-skin .control-rich-text.t08,
.responsive-skin .control-label-base.t08,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t08,
.responsive-skin .panel-header-title .control-label-base.t08,
.responsive-skin .panel-header-centered-text > .title.t08 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 22px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  line-height: 28px; }

.responsive-skin .control-switch > .label-switch.t09,
.responsive-skin .control-rich-text.t09,
.responsive-skin .control-label-base.t09,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t09,
.responsive-skin .panel-header-title .control-label-base.t09,
.responsive-skin .panel-header-centered-text > .title.t09 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 18px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.responsive-skin .control-switch > .label-switch.t10,
.responsive-skin .control-rich-text.t10,
.responsive-skin .control-label-base.t10,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t10,
.responsive-skin .panel-header-title .control-label-base.t10,
.responsive-skin .panel-header-centered-text > .title.t10 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 20px; }

.responsive-skin .control-switch > .label-switch.t11,
.responsive-skin .control-rich-text.t11,
.responsive-skin .control-label-base.t11,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t11,
.responsive-skin .panel-header-title .control-label-base.t11,
.responsive-skin .panel-header-centered-text > .title.t11 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 11px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t12,
.responsive-skin .control-rich-text.t12,
.responsive-skin .control-label-base.t12,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t12,
.responsive-skin .panel-header-title .control-label-base.t12,
.responsive-skin .panel-header-centered-text > .title.t12 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t13,
.responsive-skin .control-rich-text.t13,
.responsive-skin .control-label-base.t13,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t13,
.responsive-skin .panel-header-title .control-label-base.t13,
.responsive-skin .panel-header-centered-text > .title.t13 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 30px;
  line-height: 36px;
  -webkit-font-smoothing: antialiased;
  line-height: 36px; }

.responsive-skin .control-switch > .label-switch.t14,
.responsive-skin .control-rich-text.t14,
.responsive-skin .control-label-base.t14,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t14,
.responsive-skin .panel-header-title .control-label-base.t14,
.responsive-skin .panel-header-centered-text > .title.t14 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 11px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t15,
.responsive-skin .control-rich-text.t15,
.responsive-skin .control-label-base.t15,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t15,
.responsive-skin .panel-header-title .control-label-base.t15,
.responsive-skin .panel-header-centered-text > .title.t15 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 11px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }

.responsive-skin .control-switch > .label-switch.t16,
.responsive-skin .control-rich-text.t16,
.responsive-skin .control-label-base.t16,
.responsive-skin .control-action-with-symbol > .content-container .content .control-label-base.t16,
.responsive-skin .panel-header-title .control-label-base.t16,
.responsive-skin .panel-header-centered-text > .title.t16 {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 24px;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  line-height: 32px; }

.responsive-skin .wbu-action-item .control-label-base.t19 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  font-weight: var(--wbu-font-weight-medium); }

.control-checkbox > .label-checkbox.t01,
.control-switch > .label-switch.t01,
.control-rich-text.t01,
.control-label-base.t01,
.control-action-with-symbol > .content-container .content .control-label-base.t01,
.panel-header-centered-text > .title.t01 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t02,
.control-switch > .label-switch.t02,
.control-rich-text.t02,
.control-label-base.t02,
.control-action-with-symbol > .content-container .content .control-label-base.t02,
.panel-header-centered-text > .title.t02 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t03,
.control-switch > .label-switch.t03,
.control-rich-text.t03,
.control-label-base.t03,
.control-action-with-symbol > .content-container .content .control-label-base.t03,
.panel-header-centered-text > .title.t03 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t04,
.control-switch > .label-switch.t04,
.control-rich-text.t04,
.control-label-base.t04,
.control-action-with-symbol > .content-container .content .control-label-base.t04,
.panel-header-centered-text > .title.t04 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t05,
.control-switch > .label-switch.t05,
.control-rich-text.t05,
.control-label-base.t05,
.control-action-with-symbol > .content-container .content .control-label-base.t05,
.panel-header-centered-text > .title.t05 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t06,
.control-switch > .label-switch.t06,
.control-rich-text.t06,
.control-label-base.t06,
.control-action-with-symbol > .content-container .content .control-label-base.t06,
.panel-header-centered-text > .title.t06 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t07,
.control-switch > .label-switch.t07,
.control-rich-text.t07,
.control-label-base.t07,
.control-action-with-symbol > .content-container .content .control-label-base.t07,
.panel-header-centered-text > .title.t07 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t08,
.control-switch > .label-switch.t08,
.control-rich-text.t08,
.control-label-base.t08,
.control-action-with-symbol > .content-container .content .control-label-base.t08,
.panel-header-centered-text > .title.t08 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t09,
.control-switch > .label-switch.t09,
.control-rich-text.t09,
.control-label-base.t09,
.control-action-with-symbol > .content-container .content .control-label-base.t09,
.panel-header-centered-text > .title.t09 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t10,
.control-switch > .label-switch.t10,
.control-rich-text.t10,
.control-label-base.t10,
.control-action-with-symbol > .content-container .content .control-label-base.t10,
.panel-header-centered-text > .title.t10 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t11,
.control-switch > .label-switch.t11,
.control-rich-text.t11,
.control-label-base.t11,
.control-action-with-symbol > .content-container .content .control-label-base.t11,
.panel-header-centered-text > .title.t11 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t12,
.control-switch > .label-switch.t12,
.control-rich-text.t12,
.control-label-base.t12,
.control-action-with-symbol > .content-container .content .control-label-base.t12,
.panel-header-centered-text > .title.t12 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  line-height: 14px; }

.control-checkbox > .label-checkbox.t13,
.control-switch > .label-switch.t13,
.control-rich-text.t13,
.control-label-base.t13,
.control-action-with-symbol > .content-container .content .control-label-base.t13,
.panel-header-centered-text > .title.t13 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  line-height: 36px; }

.control-checkbox > .label-checkbox.t14,
.control-switch > .label-switch.t14,
.control-rich-text.t14,
.control-label-base.t14,
.control-action-with-symbol > .content-container .content .control-label-base.t14,
.panel-header-centered-text > .title.t14 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t15,
.control-switch > .label-switch.t15,
.control-rich-text.t15,
.control-label-base.t15,
.control-action-with-symbol > .content-container .content .control-label-base.t15,
.panel-header-centered-text > .title.t15 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t16,
.control-switch > .label-switch.t16,
.control-rich-text.t16,
.control-label-base.t16,
.control-action-with-symbol > .content-container .content .control-label-base.t16,
.panel-header-centered-text > .title.t16 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 26px;
  -webkit-font-smoothing: antialiased;
  line-height: 36px; }

.control-checkbox > .label-checkbox.t17,
.control-switch > .label-switch.t17,
.control-rich-text.t17,
.control-label-base.t17,
.control-action-with-symbol > .content-container .content .control-label-base.t17,
.panel-header-centered-text > .title.t17 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  line-height: 25px; }

.control-checkbox > .label-checkbox.t18,
.control-switch > .label-switch.t18,
.control-rich-text.t18,
.control-label-base.t18,
.control-action-with-symbol > .content-container .content .control-label-base.t18,
.panel-header-centered-text > .title.t18 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t19,
.control-switch > .label-switch.t19,
.control-rich-text.t19,
.control-label-base.t19,
.control-action-with-symbol > .content-container .content .control-label-base.t19,
.panel-header-centered-text > .title.t19 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t20,
.control-switch > .label-switch.t20,
.control-rich-text.t20,
.control-label-base.t20,
.control-action-with-symbol > .content-container .content .control-label-base.t20,
.panel-header-centered-text > .title.t20 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t21,
.control-switch > .label-switch.t21,
.control-rich-text.t21,
.control-label-base.t21,
.control-action-with-symbol > .content-container .content .control-label-base.t21,
.panel-header-centered-text > .title.t21 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t22,
.control-switch > .label-switch.t22,
.control-rich-text.t22,
.control-label-base.t22,
.control-action-with-symbol > .content-container .content .control-label-base.t22,
.panel-header-centered-text > .title.t22 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px; }

.wbu-action-item .control-label-base.t19 {
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold, 400); }

.responsive-skin .control-label {
  color: #131720; }
  .responsive-skin .control-label.disabled {
    color: #767676; }
  .control-upload-button .upload-status-message .responsive-skin .control-label {
    color: #131720; }
  .control-dropdown-option .dropdown-detailed-option-title > .responsive-skin .control-label {
    color: #131720; }
  .control-dropdown-option.active .dropdown-detailed-option-title > .responsive-skin .control-label {
    color: #2B2E36; }
  .control-button.btn-text .responsive-skin .control-label,
  .control-button.btn-confirm-secondary .responsive-skin .control-label,
  .control-button.secondary .responsive-skin .control-label {
    color: #116dff; }
  .control-button.btn-danger-secondary .responsive-skin .control-label,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary .responsive-skin .control-label {
    color: #ee4437; }
  .control-dropdown-option .dropdown-detailed-option-description .responsive-skin .control-label {
    color: #42454C; }

.responsive-skin .collapse-label-switch .control-label {
  cursor: pointer;
  color: #131720;
  padding-left: 0;
  box-sizing: border-box; }

.responsive-skin .composite-color-picker-input-labeled .control-label {
  margin-bottom: 5px; }

.responsive-skin .composite-checkboxes-labeled .control-label {
  margin-bottom: 7px; }

.responsive-skin .composite-slider-labeled .control-label,
.responsive-skin .composite-text-input-labeled .control-label,
.responsive-skin .composite-dropdown-labeled .control-label {
  margin-bottom: 12px; }

.responsive-skin .composite-slider-labeled .control-label,
.responsive-skin .composite-thumbnails .control-label,
.responsive-skin .composite-color-picker-input-labeled .control-label,
.responsive-skin .composite-color-select-labeled .control-label {
  color: #131720; }
  .responsive-skin .composite-slider-labeled .control-label .has-tooltip,
  .responsive-skin .composite-thumbnails .control-label .has-tooltip,
  .responsive-skin .composite-color-picker-input-labeled .control-label .has-tooltip,
  .responsive-skin .composite-color-select-labeled .control-label .has-tooltip {
    display: inline-block; }
  .responsive-skin .composite-slider-labeled .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-thumbnails .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-color-picker-input-labeled .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-color-select-labeled .control-label .control-label-base:not([class^="control-label-base t"]) {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal .control-label-base {
  width: 39px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.responsive-skin .composite-empty-state .control-label {
  text-align: center; }
  .responsive-skin .composite-empty-state .control-label.title {
    color: #131720; }
  .responsive-skin .composite-empty-state .control-label.description {
    color: #42454C; }

.responsive-skin .composite-panel-content .composite-two-inputs-in-row .composite-input-labeled .control-label {
  margin: 0 0 10px 0;
  text-align: left; }

.responsive-skin .composite-text-input-labeled .control-label {
  margin: 0 0 12px 0; }

.responsive-skin .composite-text-label-with-icon .control-label {
  float: right; }

.responsive-skin .composite-two-text-labels-in-row .control-label:first-child {
  padding-right: 0; }

.responsive-skin .composite-two-text-labels-in-row .control-label:nth-child(2) {
  float: right;
  padding-left: 0; }

.responsive-skin .composite-input-with-drop-down-labeled .control-label,
.responsive-skin .composite-input-with-drop-down-labeled-horizontal .control-label,
.responsive-skin .composite-input-with-drop-down-labeled-top .control-label,
.responsive-skin .composite-input-labeled .control-label {
  margin-top: 8px;
  margin-left: 0;
  margin-right: 8px;
  text-align: center;
  color: #131720; }
  .responsive-skin .composite-input-with-drop-down-labeled .control-label .has-tooltip,
  .responsive-skin .composite-input-with-drop-down-labeled-horizontal .control-label .has-tooltip,
  .responsive-skin .composite-input-with-drop-down-labeled-top .control-label .has-tooltip,
  .responsive-skin .composite-input-labeled .control-label .has-tooltip {
    display: inline-block; }
  .responsive-skin .composite-input-with-drop-down-labeled .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-input-with-drop-down-labeled-horizontal .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-input-with-drop-down-labeled-top .control-label .control-label-base:not([class^="control-label-base t"]),
  .responsive-skin .composite-input-labeled .control-label .control-label-base:not([class^="control-label-base t"]) {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 11px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .composite-input-with-drop-down-labeled-top .control-label {
  margin: 0 0 8px 0;
  text-align: start; }
  .responsive-skin .composite-input-with-drop-down-labeled-top .control-label .control-label-base:not([class^="control-label-base t"]) {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 11px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .composite-buttons-group-labeled .control-label {
  margin-bottom: 12px; }

.responsive-skin .composite-docking-control-with-label .control-label {
  margin-top: 25%; }

.responsive-skin .composite-drop-down-labeled-horizontal .control-label {
  margin-top: 7px; }

.responsive-skin .composite-numeric-input-labeled .control-label {
  color: #131720;
  margin-top: 6px; }
  .responsive-skin .composite-numeric-input-labeled .control-label .control-label-base:not([class^="control-label-base t"]) {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .composite-popup .panel-header .control-label {
  color: #131720; }
  .responsive-skin .composite-popup .panel-header .control-label.disabled {
    color: #767676; }

.classic-facelift-skin .control-label {
  color: #3b4057; }
  .control-upload-button .upload-status-message .classic-facelift-skin .control-label {
    color: #000624; }
  .control-dropdown-option .dropdown-detailed-option-title > .classic-facelift-skin .control-label {
    color: #000624; }
  .control-dropdown-option.active .dropdown-detailed-option-title > .classic-facelift-skin .control-label {
    color: #3b4057; }
  .control-button.primary .classic-facelift-skin .control-label,
  .control-dropdown-option.selected .dropdown-detailed-option-title > .classic-facelift-skin .control-label {
    color: #fff; }
  .control-button.btn-text .classic-facelift-skin .control-label,
  .control-button.btn-confirm-secondary .classic-facelift-skin .control-label,
  .control-button.secondary .classic-facelift-skin .control-label {
    color: #116dff; }
  .control-button.btn-danger-secondary .classic-facelift-skin .control-label,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary .classic-facelift-skin .control-label {
    color: #e62214; }
  .btn-md .classic-facelift-skin .control-label,
  .btn-md[disabled] .classic-facelift-skin .control-label,
  .control-button.btn-danger-secondary[disabled] .classic-facelift-skin .control-label,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled] .classic-facelift-skin .control-label {
    color: #fff; }
  .control-dropdown-option .dropdown-detailed-option-description .classic-facelift-skin .control-label {
    color: #868aa5; }

.control-label {
  display: inline-block;
  color: #3b4057;
  -webkit-user-select: none;
          user-select: none;
  cursor: default;
  max-width: 100%; }
  .classic-facelift-skin .control-label {
    color: #3b4057; }
  .responsive-skin .control-label {
    color: #2B2E36; }
  .control-label.disabled {
    color: #939393; }
  .control-upload-button .control-label.with-ellipsis {
    overflow: hidden; }
  .control-thumbnails-list .control-label.with-ellipsis {
    display: inline-flex; }
  .dropdown-detailed-option-label .control-label {
    cursor: pointer; }
  .composite-color-select-labeled .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 48px); }
  .composite-dynamic-field-with-description .control-label,
  .composite-editable-url-labeled .control-label {
    display: block; }
    .composite-dynamic-field-with-description .control-label:nth-child(1),
    .composite-editable-url-labeled .control-label:nth-child(1) {
      margin-bottom: 5px; }
    .composite-dynamic-field-with-description .control-label:nth-child(2),
    .composite-editable-url-labeled .control-label:nth-child(2) {
      margin-bottom: 15px; }
  .composite-checkboxes-labeled .control-label,
  .composite-button-large-labeled .control-label {
    margin-bottom: 14px; }
  .composite-date-picker-labeled .control-label,
  .composite-rich-text-labeled .control-label {
    margin-bottom: 6px; }
  .composite-dock-labeled .control-label {
    margin-bottom: 18px; }
  .composite-dock-labeled .control-label,
  .composite-horizontal-tabs-labeled .control-label {
    margin-bottom: 18px; }
  .composite-angle-input-labeled .control-label {
    margin-bottom: 13px; }
  .composite-vertical-tabs-labeled .control-label {
    margin-bottom: 18px; }
  .composite-text-input-labeled .control-label,
  .composite-text-input-multiline-with-button-labeled .control-label,
  .composite-text-input-multiline-labeled .control-label,
  .composite-dropdown-with-button-labeled .control-label,
  .composite-dropdown-with-color-input-labeled .control-label,
  .composite-dropdown-labeled .control-label,
  .composite-dynamic-field-labeled .control-label {
    margin-bottom: 2px; }
    .input-borders-experiment .composite-text-input-labeled .control-label, .input-borders-experiment
    .composite-text-input-multiline-with-button-labeled .control-label, .input-borders-experiment
    .composite-text-input-multiline-labeled .control-label, .input-borders-experiment
    .composite-dropdown-with-button-labeled .control-label, .input-borders-experiment
    .composite-dropdown-with-color-input-labeled .control-label, .input-borders-experiment
    .composite-dropdown-labeled .control-label, .input-borders-experiment
    .composite-dynamic-field-labeled .control-label {
      margin-bottom: 6px; }
  .composite-dropdown-labeled .dropdown-detailed-option-label .control-label {
    margin-bottom: 0; }
  .composite-action-with-symbol-labeled > .control-label {
    margin-bottom: 6px; }
  .composite-secondary-media-labeled .control-label {
    width: 100%;
    margin-bottom: 9px; }
  .composite-secondary-media-labeled .info-icon-tooltip ~ .control-label {
    width: calc(100% - 18px); }
  .composite-color-picker-input-labeled .control-label {
    margin-bottom: 3px; }
  .composite-link-button-with-color-input-labeled > .control-label {
    margin-bottom: 5px; }
  .composite-radio-buttons-labeled .control-label {
    width: calc(100% - (48px - 24px));
    max-height: 36px;
    overflow: hidden;
    display: block;
    margin-bottom: 12px;
    display: inline-flex; }
  .composite-slider-labeled .control-label,
  .composite-dropdown-with-button-labeled .control-label,
  .composite-dropdown-labeled .control-label,
  .composite-button-large-labeled .control-label,
  .composite-checkboxes-labeled .control-label,
  .composite-action-with-symbol-labeled > .control-label {
    width: calc(100% - (42px - 24px));
    max-height: 36px;
    overflow: hidden;
    display: block; }
  .composite-dropdown-with-button-labeled .dropdown-selected .control-label,
  .composite-dropdown-labeled .dropdown-selected .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .composite-text-input-multiline-with-button-labeled .control-label,
  .composite-text-input-multiline-labeled .control-label,
  .composite-text-input-labeled .control-label {
    margin-left: 12px;
    width: calc(100% - (42px - 12px));
    max-height: 36px;
    overflow: hidden;
    display: block; }
    .input-borders-experiment .composite-text-input-multiline-with-button-labeled .control-label, .input-borders-experiment
    .composite-text-input-multiline-labeled .control-label, .input-borders-experiment
    .composite-text-input-labeled .control-label {
      margin-left: unset; }
  .composite-text-input-multiline-with-button-labeled .input-prefix .control-label,
  .composite-text-input-multiline-labeled .input-prefix .control-label,
  .composite-text-input-labeled .input-prefix .control-label {
    margin-left: 0;
    width: 100%; }
  .composite-buttons-group-labeled .control-label {
    margin-bottom: 18px;
    margin-top: 0; }
  .composite-preloader .control-label {
    margin-top: 32px; }
  .composite-corner-radius-input-labeled .control-label {
    margin-left: 24px;
    margin-top: 9px; }
  .control-upload-button .button-content .control-label {
    color: inherit; }
  .control-upload-button .upload-status-message .control-label {
    color: #000624; }
  .control-tab .control-label {
    display: inline-flex;
    color: inherit;
    cursor: inherit; }
  .control-dropdown-base.disabled .dropdown-selected .dropdown-detailed-option-label > .control-label {
    cursor: inherit;
    color: inherit; }
  .control-dropdown-option .dropdown-detailed-option-title > .control-label {
    cursor: pointer;
    color: #000624; }
  .control-dropdown-option.active .dropdown-detailed-option-title > .control-label {
    color: #3b4057; }
  .control-button.primary .control-label,
  .control-dropdown-option.selected .dropdown-detailed-option-title > .control-label {
    color: #fff; }
  .control-dropdown-option.disabled .dropdown-detailed-option-title > .control-label {
    cursor: inherit;
    color: inherit; }
  .control-button.btn-text .control-label,
  .control-button.btn-confirm-secondary .control-label,
  .control-button.secondary .control-label {
    color: #116dff;
    cursor: pointer; }
  .control-button.btn-confirm-secondary .control-label,
  .control-button.secondary .control-label,
  .control-button.primary .control-label {
    cursor: pointer; }
  .control-button.btn-danger-secondary .control-label,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary .control-label {
    color: #e62214; }
  .btn-md .control-label,
  .btn-md[disabled] .control-label,
  .control-button.btn-danger-secondary[disabled] .control-label,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled] .control-label {
    color: #fff;
    cursor: inherit; }
  .control-dropdown-option .dropdown-detailed-option-description .control-label {
    cursor: pointer;
    color: #868aa5; }
  .panel-header .control-label {
    color: #fff; }
  .control-dropdown-option.selected .dropdown-detailed-option-description .control-label {
    color: #fff; }
  .control-dropdown-option.disabled .dropdown-detailed-option-description .control-label {
    cursor: inherit;
    color: inherit; }
  .control-action-with-symbol > .content-container .content .control-label {
    color: inherit;
    cursor: inherit; }
  .control-section-divider .section-divider-content .control-label,
  .input-container > .input-prefix .control-label,
  .control-google-preview .control-label {
    color: inherit; }

.control-button .button-content .control-button-content {
  display: flex;
  align-items: center; }

.responsive-skin.dark-mode .control-button[disabled] {
  background-color: #42454C;
  color: #868aa5; }

.responsive-skin.dark-mode .control-button:focus-visible {
  box-shadow: 0 0 0 3px #7cafff; }

.responsive-skin.dark-mode .control-button.link:hover {
  color: #0f62e6; }

.responsive-skin.dark-mode .control-button.link.btn-sm {
  color: #116dff; }

.responsive-skin.dark-mode .control-button.link.btn-big {
  color: #116dff; }

.responsive-skin.dark-mode .control-button.link.btn-md {
  color: #116dff; }

.responsive-skin.dark-mode .control-button.btn-back:not(.btn-header-back) {
  color: #116dff;
  background-color: #dce9ff; }
  .responsive-skin.dark-mode .control-button.btn-back:not(.btn-header-back):hover {
    color: #116dff;
    background-color: #c3daff; }
  .responsive-skin.dark-mode .control-button.btn-back:not(.btn-header-back) svg * {
    fill: #116dff; }

.responsive-skin.dark-mode .control-button.btn-danger-primary,
.composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary {
  border-radius: 4px;
  background-color: #ee4437;
  color: #fff;
  border: 1px solid transparent; }
  .responsive-skin.dark-mode .control-button.btn-danger-primary:hover,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary:hover {
    background-color: #ca3a2f;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-danger-primary:focus-visible,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary:focus-visible {
    background-color: #ca3a2f;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-danger-primary:active,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary:active {
    background-color: #ee4437;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-danger-primary:disabled, .responsive-skin.dark-mode .control-button.btn-danger-primary[disabled],
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary:disabled,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.primary[disabled] {
    color: #bebebe;
    background-color: #f3f3f3; }

.responsive-skin.dark-mode .control-button.btn-danger-secondary,
.composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary {
  border-radius: 4px;
  background-color: #fff;
  color: #ee4437;
  border: 1px solid transparent; }
  .responsive-skin.dark-mode .control-button.btn-danger-secondary:hover,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:hover {
    background-color: #f4f5f8;
    color: #ee4437; }
  .responsive-skin.dark-mode .control-button.btn-danger-secondary:focus-visible,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:focus-visible {
    background-color: #f4f5f8;
    color: #ee4437; }
  .responsive-skin.dark-mode .control-button.btn-danger-secondary:active,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:active {
    background-color: #fff;
    color: #ee4437; }
  .responsive-skin.dark-mode .control-button.btn-danger-secondary:disabled,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled {
    color: #bebebe;
    background-color: #fff; }
    .responsive-skin.dark-mode .control-button.btn-danger-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-danger-secondary:disabled.prefix-icon svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg * {
      fill: #bebebe; }
  .responsive-skin.dark-mode .control-button.btn-danger-secondary svg *,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary svg * {
    fill: #ee4437; }

.responsive-skin.dark-mode .control-button.btn-confirm-primary {
  border-radius: 4px;
  background-color: #116dff;
  color: #fff;
  border: 1px solid transparent; }
  .responsive-skin.dark-mode .control-button.btn-confirm-primary:hover {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-primary:focus-visible {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-primary:active {
    background-color: #116dff;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-primary[disabled] {
    cursor: default;
    color: #bebebe;
    background-color: #f3f3f3; }
    .responsive-skin.dark-mode .control-button.btn-confirm-primary[disabled].suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-primary[disabled].prefix-icon svg * {
      fill: #bebebe; }

.responsive-skin.dark-mode .control-button.btn-premium-primary {
  border-radius: 4px;
  background-color: #9A27D5;
  color: #fff;
  border: 1px solid transparent; }
  .responsive-skin.dark-mode .control-button.btn-premium-primary:hover {
    background-color: #7927A3;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-premium-primary:focus-visible {
    background-color: #7927A3;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-premium-primary:active {
    background-color: #9A27D5;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-premium-primary:disabled {
    color: #bebebe;
    background-color: #f3f3f3; }

.responsive-skin.dark-mode .control-button.btn-premium-secondary {
  border-radius: 4px;
  background-color: #fff;
  color: #9A27D5;
  border: 1px solid #e2e3ea; }
  .responsive-skin.dark-mode .control-button.btn-premium-secondary:hover {
    background-color: #f4f5f8;
    border-color: transparent;
    color: #9A27D5; }
  .responsive-skin.dark-mode .control-button.btn-premium-secondary:focus-visible {
    background-color: #f4f5f8;
    border-color: transparent;
    color: #9A27D5; }
  .responsive-skin.dark-mode .control-button.btn-premium-secondary:active {
    background-color: #fff;
    color: #9A27D5;
    border-color: #e2e3ea; }
  .responsive-skin.dark-mode .control-button.btn-premium-secondary:disabled {
    color: #bebebe;
    background-color: #fff;
    border-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-premium-secondary.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-premium-secondary.prefix-icon svg * {
    fill: #9A27D5; }

.responsive-skin.dark-mode .control-button.btn-ai-primary {
  border-radius: 4px;
  background-color: #5A48F5;
  color: #fff;
  border: 1px solid transparent; }
  .responsive-skin.dark-mode .control-button.btn-ai-primary:hover {
    background-color: #4D3DD0;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-ai-primary:focus-visible {
    background-color: #4D3DD0;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-ai-primary:active {
    background-color: #5A48F5;
    color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-ai-primary:disabled {
    color: #bebebe;
    background-color: #f3f3f3; }

.responsive-skin.dark-mode .control-button.btn-ai-secondary {
  border-radius: 4px;
  background-color: #fff;
  color: #5A48F5;
  border: 1px solid #e2e3ea; }
  .responsive-skin.dark-mode .control-button.btn-ai-secondary:hover {
    background-color: #f4f5f8;
    border-color: transparent;
    color: #5A48F5; }
  .responsive-skin.dark-mode .control-button.btn-ai-secondary:focus-visible {
    background-color: #f4f5f8;
    border-color: transparent;
    color: #5A48F5; }
  .responsive-skin.dark-mode .control-button.btn-ai-secondary:active {
    background-color: #fff;
    color: #5A48F5;
    border-color: #e2e3ea; }
  .responsive-skin.dark-mode .control-button.btn-ai-secondary:disabled {
    color: #bebebe;
    background-color: #fff;
    border-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-ai-secondary.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-ai-secondary.prefix-icon svg * {
    fill: #5A48F5; }

.responsive-skin.dark-mode .control-button.btn-inverted {
  border-radius: 4px;
  background-color: transparent;
  color: #116dff;
  border: 1px solid #116dff; }
  .responsive-skin.dark-mode .control-button.btn-inverted:hover {
    background-color: #116dff;
    color: #fff;
    border-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-inverted:focus-visible {
    background-color: #116dff;
    color: #fff;
    border-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-inverted:active {
    background-color: transparent;
    color: #116dff;
    border-color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-inverted:disabled {
    color: #bebebe;
    background-color: #f3f3f3;
    border-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-inverted:active svg * {
    fill: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-inverted:active.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-inverted:active.prefix-icon svg * {
    fill: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-inverted[disabled].suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-inverted[disabled].prefix-icon svg * {
    fill: #bebebe; }

.responsive-skin.dark-mode .control-button.btn-outline {
  background-color: #e5e5e5;
  color: #fff;
  border: 1px solid #fff; }
  .responsive-skin.dark-mode .control-button.btn-outline:hover {
    color: #116dff;
    background-color: #fff; }

.responsive-skin.dark-mode .control-button.btn-light, .responsive-skin.dark-mode .control-button.btn-confirm-secondary,
.composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary,
.composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary,
.composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e2e3ea;
  color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light:hover, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:hover,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:hover,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:hover,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:hover {
    background-color: #f4f5f8;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light:focus-visible, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:focus-visible,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:focus-visible,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:focus-visible,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:focus-visible {
    background-color: #f4f5f8;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light:active, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:active,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:active,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:active,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:active {
    background-color: #fff;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light:disabled, .responsive-skin.dark-mode .control-button.btn-light:disabled, .responsive-skin.dark-mode .control-button.btn-light[disabled="true"], .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"],
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"],
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"],
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary[disabled="true"] {
    cursor: default;
    color: #bebebe;
    background-color: #fff;
    border-color: #bebebe; }
    .responsive-skin.dark-mode .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light[disabled="true"].suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light[disabled="true"].prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"].suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"].prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary[disabled="true"].prefix-icon svg * {
      fill: #bebebe; }
  .responsive-skin.dark-mode .control-button.btn-light svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary svg * {
    fill: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary.prefix-icon svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary.suffix-icon svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary.prefix-icon svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary.suffix-icon svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary.prefix-icon svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary.suffix-icon svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary.prefix-icon svg * {
    fill: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-light:disabled svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled svg * {
    fill: #767676; }
  .responsive-skin.dark-mode .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.prefix-icon svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
  .composite-popup .composite-action-set-horizontal > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.suffix-icon svg *,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin.dark-mode .control-button.secondary:disabled.prefix-icon svg * {
    fill: #767676; }

.responsive-skin.dark-mode .control-button.btn-confirm-secondary {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e2e3ea;
  color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-secondary:hover {
    background-color: #f4f5f8;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-secondary:focus-visible {
    background-color: #f4f5f8;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-secondary:active {
    background-color: #fff;
    color: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"] {
    cursor: default;
    color: #bebebe;
    background-color: #fff;
    border-color: #bebebe; }
    .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"].suffix-icon svg *, .responsive-skin.dark-mode .control-button.btn-confirm-secondary[disabled="true"].prefix-icon svg * {
      fill: #bebebe; }

.responsive-skin.dark-mode .control-button.btn-text {
  color: #7cafff;
  background-color: transparent; }
  .responsive-skin.dark-mode .control-button.btn-text:hover:not(:disabled) > .button-content {
    background-color: transparent;
    color: #7cafff; }

.responsive-skin.dark-mode .control-button.got-it:hover {
  background-color: #fa815b; }

.responsive-skin.dark-mode .control-button.btn-test-drive {
  background-color: #f96132; }
  .responsive-skin.dark-mode .control-button.btn-test-drive:hover {
    background-color: #fa815b; }

.responsive-skin.dark-mode .control-button.btn-thumbnail:not([disabled]) {
  background-color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-thumbnail:not([disabled]) path {
    fill: #116dff; }
  .responsive-skin.dark-mode .control-button.btn-thumbnail:not([disabled]):active {
    background-color: #fff; }

.responsive-skin.dark-mode .control-button.btn-thumbnail[disabled] {
  background-color: #fff; }
  .responsive-skin.dark-mode .control-button.btn-thumbnail[disabled] path {
    fill: #bebebe; }

.responsive-skin.dark-mode .control-button.btn-thumbnail:hover:not([disabled]):not(:active) {
  background-color: #f4f5f8; }

.composite-secondary-media-labeled .responsive-skin.dark-mode .control-button,
.composite-secondary-media .responsive-skin.dark-mode .control-button {
  background-color: #dce9ff; }
  .composite-secondary-media-labeled .responsive-skin.dark-mode .control-button:hover,
  .composite-secondary-media .responsive-skin.dark-mode .control-button:hover {
    background-color: #c3daff; }
  .composite-secondary-media-labeled .responsive-skin.dark-mode .control-button svg *,
  .composite-secondary-media .responsive-skin.dark-mode .control-button svg * {
    fill: #116dff; }

.responsive-skin.dark-mode .control-button.animated-bg {
  background: transparent; }
  .responsive-skin.dark-mode .control-button.animated-bg:not(:disabled):hover:before, .responsive-skin.dark-mode .control-button.animated-bg:not(:disabled).active:before {
    background-color: transparent; }
  .responsive-skin.dark-mode .control-button.animated-bg:not(:disabled).active:before {
    background-color: transparent; }
  .responsive-skin.dark-mode .control-button.animated-bg:not(:disabled).active svg path {
    fill: #116dff; }

.responsive-skin.dark-mode .composite-popup .composite-action-set-horizontal > .control-button.secondary svg *,
.responsive-skin.dark-mode .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary svg *,
.responsive-skin.dark-mode .dropdown-options .control-button.secondary svg * {
  fill: #116dff; }

.responsive-skin.dark-mode .composite-popup .composite-action-set-horizontal > .control-button.secondary[disabled='true'] svg *,
.responsive-skin.dark-mode .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary[disabled='true'] svg *,
.responsive-skin.dark-mode .dropdown-options .control-button.secondary[disabled='true'] svg * {
  fill: #767676; }

.responsive-skin .control-button {
  border-radius: 4px;
  background-color: #116dff;
  color: #fff;
  border: 1px solid transparent;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  padding: 6px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; }
  .responsive-skin .control-button:hover {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin .control-button:focus-visible {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin .control-button:active {
    background-color: #116dff;
    color: #fff; }
  .responsive-skin .control-button[disabled] {
    cursor: default;
    color: #bebebe;
    background-color: #f3f3f3; }
    .responsive-skin .control-button[disabled].suffix-icon svg *, .responsive-skin .control-button[disabled].prefix-icon svg * {
      fill: #bebebe; }
  .responsive-skin .control-button:focus-visible {
    box-shadow: 0 0 0 3px #7cafff; }
  .responsive-skin .control-button.link:hover {
    color: #0f62e6; }
  .responsive-skin .control-button.link.btn-sm {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }
  .responsive-skin .control-button.link.btn-big {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }
  .responsive-skin .control-button.link.btn-md {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }
  .responsive-skin .control-button.btn-big {
    font-size: 14px;
    line-height: 20px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    padding: 7px 16px;
    border-radius: 4px;
    height: unset; }
    .responsive-skin .control-button.btn-big.suffix-icon {
      padding: 5px 10px 5px 16px; }
    .responsive-skin .control-button.btn-big.prefix-icon {
      padding: 5px 16px 5px 10px; }
  .responsive-skin .control-button.btn-md {
    height: 32px;
    font-size: 14px;
    line-height: 20px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    padding: 6px 16px;
    border-radius: 4px; }
    .responsive-skin .control-button.btn-md.suffix-icon {
      padding: 0 10px 0 16px; }
    .responsive-skin .control-button.btn-md.prefix-icon {
      padding: 0 16px 0 10px; }
  .responsive-skin .control-button.btn-sm {
    font-size: 12px;
    line-height: 16px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    padding: 3px 12px;
    border-radius: 4px; }
    .responsive-skin .control-button.btn-sm.suffix-icon {
      padding: 0 6px 0 12px; }
    .responsive-skin .control-button.btn-sm.prefix-icon {
      padding: 0 12px 0 6px; }
  .responsive-skin .control-button.btn-back:not(.btn-header-back) {
    color: #116dff;
    background-color: #dce9ff; }
    .composite-button-left .responsive-skin .control-button.btn-back:not(.btn-header-back) {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .control-button.btn-back:not(.btn-header-back):hover {
      color: #116dff;
      background-color: #c3daff; }
    .responsive-skin .control-button.btn-back:not(.btn-header-back) svg * {
      fill: #116dff; }
  .responsive-skin .control-button.btn-danger-primary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary {
    border-radius: 4px;
    background-color: #ee4437;
    color: #fff;
    border: 1px solid transparent; }
    .responsive-skin .control-button.btn-danger-primary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary:hover {
      background-color: #ca3a2f;
      color: #fff; }
    .responsive-skin .control-button.btn-danger-primary:focus-visible,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary:focus-visible {
      background-color: #ca3a2f;
      color: #fff; }
    .responsive-skin .control-button.btn-danger-primary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary:active {
      background-color: #ee4437;
      color: #fff; }
    .responsive-skin .control-button.btn-danger-primary:disabled, .responsive-skin .control-button.btn-danger-primary[disabled],
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary:disabled,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.primary[disabled] {
      color: #bebebe;
      background-color: #f3f3f3; }
  .responsive-skin .control-button.btn-danger-secondary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary {
    border-radius: 4px;
    background-color: #fff;
    color: #ee4437;
    border: 1px solid transparent; }
    .responsive-skin .control-button.btn-danger-secondary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:hover {
      background-color: #f4f5f8;
      color: #ee4437; }
    .responsive-skin .control-button.btn-danger-secondary:focus-visible,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:focus-visible {
      background-color: #f4f5f8;
      color: #ee4437; }
    .responsive-skin .control-button.btn-danger-secondary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:active {
      background-color: #fff;
      color: #ee4437; }
    .responsive-skin .control-button.btn-danger-secondary:disabled,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled {
      color: #bebebe;
      background-color: #fff; }
      .responsive-skin .control-button.btn-danger-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-danger-secondary:disabled.prefix-icon svg *,
      .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg * {
        fill: #bebebe; }
    .responsive-skin .control-button.btn-danger-secondary svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .responsive-skin .control-button.secondary svg * {
      fill: #ee4437; }
  .responsive-skin .control-button.btn-confirm-primary {
    border-radius: 4px;
    background-color: #116dff;
    color: #fff;
    border: 1px solid transparent; }
    .responsive-skin .control-button.btn-confirm-primary:hover {
      background-color: #0f62e6;
      color: #fff; }
    .responsive-skin .control-button.btn-confirm-primary:focus-visible {
      background-color: #0f62e6;
      color: #fff; }
    .responsive-skin .control-button.btn-confirm-primary:active {
      background-color: #116dff;
      color: #fff; }
    .responsive-skin .control-button.btn-confirm-primary[disabled] {
      cursor: default;
      color: #bebebe;
      background-color: #f3f3f3; }
      .responsive-skin .control-button.btn-confirm-primary[disabled].suffix-icon svg *, .responsive-skin .control-button.btn-confirm-primary[disabled].prefix-icon svg * {
        fill: #bebebe; }
  .responsive-skin .control-button.btn-premium-primary {
    border-radius: 4px;
    background-color: #9A27D5;
    color: #fff;
    border: 1px solid transparent; }
    .responsive-skin .control-button.btn-premium-primary:hover {
      background-color: #7927A3;
      color: #fff; }
    .responsive-skin .control-button.btn-premium-primary:focus-visible {
      background-color: #7927A3;
      color: #fff; }
    .responsive-skin .control-button.btn-premium-primary:active {
      background-color: #9A27D5;
      color: #fff; }
    .responsive-skin .control-button.btn-premium-primary:disabled {
      color: #bebebe;
      background-color: #f3f3f3; }
  .responsive-skin .control-button.btn-premium-secondary {
    border-radius: 4px;
    background-color: #fff;
    color: #9A27D5;
    border: 1px solid #e2e3ea; }
    .responsive-skin .control-button.btn-premium-secondary:hover {
      background-color: #f4f5f8;
      border-color: transparent;
      color: #9A27D5; }
    .responsive-skin .control-button.btn-premium-secondary:focus-visible {
      background-color: #f4f5f8;
      border-color: transparent;
      color: #9A27D5; }
    .responsive-skin .control-button.btn-premium-secondary:active {
      background-color: #fff;
      color: #9A27D5;
      border-color: #e2e3ea; }
    .responsive-skin .control-button.btn-premium-secondary:disabled {
      color: #bebebe;
      background-color: #fff;
      border-color: transparent; }
    .responsive-skin .control-button.btn-premium-secondary.suffix-icon svg *, .responsive-skin .control-button.btn-premium-secondary.prefix-icon svg * {
      fill: #9A27D5; }
  .responsive-skin .control-button.btn-ai-primary {
    border-radius: 4px;
    background-color: #5A48F5;
    color: #fff;
    border: 1px solid transparent; }
    .responsive-skin .control-button.btn-ai-primary:hover {
      background-color: #4D3DD0;
      color: #fff; }
    .responsive-skin .control-button.btn-ai-primary:focus-visible {
      background-color: #4D3DD0;
      color: #fff; }
    .responsive-skin .control-button.btn-ai-primary:active {
      background-color: #5A48F5;
      color: #fff; }
    .responsive-skin .control-button.btn-ai-primary:disabled {
      color: #bebebe;
      background-color: #f3f3f3; }
  .responsive-skin .control-button.btn-ai-secondary {
    border-radius: 4px;
    background-color: #fff;
    color: #5A48F5;
    border: 1px solid #e2e3ea; }
    .responsive-skin .control-button.btn-ai-secondary:hover {
      background-color: #f4f5f8;
      border-color: transparent;
      color: #5A48F5; }
    .responsive-skin .control-button.btn-ai-secondary:focus-visible {
      background-color: #f4f5f8;
      border-color: transparent;
      color: #5A48F5; }
    .responsive-skin .control-button.btn-ai-secondary:active {
      background-color: #fff;
      color: #5A48F5;
      border-color: #e2e3ea; }
    .responsive-skin .control-button.btn-ai-secondary:disabled {
      color: #bebebe;
      background-color: #fff;
      border-color: transparent; }
    .responsive-skin .control-button.btn-ai-secondary.suffix-icon svg *, .responsive-skin .control-button.btn-ai-secondary.prefix-icon svg * {
      fill: #5A48F5; }
  .responsive-skin .control-button.btn-inverted {
    border-radius: 4px;
    background-color: transparent;
    color: #116dff;
    border: 1px solid #116dff; }
    .responsive-skin .control-button.btn-inverted:hover {
      background-color: #116dff;
      color: #fff;
      border-color: transparent; }
    .responsive-skin .control-button.btn-inverted:focus-visible {
      background-color: #116dff;
      color: #fff;
      border-color: transparent; }
    .responsive-skin .control-button.btn-inverted:active {
      background-color: transparent;
      color: #116dff;
      border-color: #116dff; }
    .responsive-skin .control-button.btn-inverted:disabled {
      color: #bebebe;
      background-color: #f3f3f3;
      border-color: transparent; }
    .responsive-skin .control-button.btn-inverted:active svg * {
      fill: #116dff; }
    .responsive-skin .control-button.btn-inverted:active.suffix-icon svg *, .responsive-skin .control-button.btn-inverted:active.prefix-icon svg * {
      fill: #116dff; }
    .responsive-skin .control-button.btn-inverted[disabled].suffix-icon svg *, .responsive-skin .control-button.btn-inverted[disabled].prefix-icon svg * {
      fill: #bebebe; }
  .responsive-skin .control-button.btn-outline {
    background-color: #e5e5e5;
    color: #fff;
    border: 1px solid #fff; }
    .responsive-skin .control-button.btn-outline:hover {
      color: #116dff;
      background-color: #fff; }
  .responsive-skin .control-button.btn-light, .responsive-skin .control-button.btn-confirm-secondary,
  .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary,
  .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary,
  .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e2e3ea;
    color: #116dff; }
    .responsive-skin .control-button.btn-light:hover, .responsive-skin .control-button.btn-confirm-secondary:hover,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:hover,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:hover,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:hover {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .control-button.btn-light:focus-visible, .responsive-skin .control-button.btn-confirm-secondary:focus-visible,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:focus-visible,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:focus-visible,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:focus-visible {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .control-button.btn-light:active, .responsive-skin .control-button.btn-confirm-secondary:active,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:active,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:active,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:active {
      background-color: #fff;
      color: #116dff; }
    .responsive-skin .control-button.btn-light:disabled, .responsive-skin .control-button.btn-light:disabled, .responsive-skin .control-button.btn-light[disabled="true"], .responsive-skin .control-button.btn-confirm-secondary:disabled, .responsive-skin .control-button.btn-confirm-secondary:disabled, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"],
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"],
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"],
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary[disabled="true"] {
      cursor: default;
      color: #bebebe;
      background-color: #fff;
      border-color: #bebebe; }
      .responsive-skin .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-light[disabled="true"].suffix-icon svg *, .responsive-skin .control-button.btn-light[disabled="true"].prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"].suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"].prefix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"].suffix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"].prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"].suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary[disabled="true"].prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary[disabled="true"].suffix-icon svg *,
      .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary[disabled="true"].prefix-icon svg * {
        fill: #bebebe; }
    .responsive-skin .control-button.btn-light svg *, .responsive-skin .control-button.btn-confirm-secondary svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary svg * {
      fill: #116dff; }
    .responsive-skin .control-button.btn-light.suffix-icon svg *, .responsive-skin .control-button.btn-light.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary.prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary.suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary.prefix-icon svg * {
      fill: #116dff; }
    .responsive-skin .control-button.btn-light:disabled svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled svg * {
      fill: #767676; }
    .responsive-skin .control-button.btn-light:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-light:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal > .responsive-skin .control-button.secondary:disabled.prefix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.suffix-icon svg *,
    .composite-popup .composite-action-set-horizontal-spaced > .responsive-skin .control-button.secondary:disabled.prefix-icon svg * {
      fill: #767676; }
  .responsive-skin .control-button.btn-confirm-secondary {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e2e3ea;
    color: #116dff; }
    .responsive-skin .control-button.btn-confirm-secondary:hover {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .control-button.btn-confirm-secondary:focus-visible {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .control-button.btn-confirm-secondary:active {
      background-color: #fff;
      color: #116dff; }
    .responsive-skin .control-button.btn-confirm-secondary:disabled, .responsive-skin .control-button.btn-confirm-secondary:disabled, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"] {
      cursor: default;
      color: #bebebe;
      background-color: #fff;
      border-color: #bebebe; }
      .responsive-skin .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary:disabled.prefix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"].suffix-icon svg *, .responsive-skin .control-button.btn-confirm-secondary[disabled="true"].prefix-icon svg * {
        fill: #bebebe; }
  .responsive-skin .control-button.btn-text {
    border-color: transparent;
    background: none;
    color: #116dff;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased; }
    .dropdown-options .responsive-skin .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .control-button.btn-text:hover:not(:disabled) > .button-content {
      color: #116dff;
      text-decoration: none; }
    .responsive-skin .control-button.btn-text:disabled {
      color: #767676; }
    .composite-button-left .responsive-skin .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .composite-action-set-vertical .responsive-skin .control-button.btn-text {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 16px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased; }
    .composite-button-large-fixed-bottom .responsive-skin .control-button.btn-text,
    .composite-button-large .responsive-skin .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .control-button.btn-text.footer-btn {
      color: #116dff; }
      .responsive-skin .control-button.btn-text.footer-btn:disabled {
        color: #bebebe; }
    .responsive-skin .control-button.btn-text.danger-btn:not([disabled="true"]),
    .responsive-skin .control-button.btn-text.danger-btn:hover > .button-content {
      color: #ee4437; }
      .responsive-skin .control-button.btn-text.danger-btn:not([disabled="true"]) svg path,
      .responsive-skin .control-button.btn-text.danger-btn:hover > .button-content svg path {
        fill: #ee4437; }
    .responsive-skin .control-button.btn-text:hover {
      background-color: transparent;
      text-decoration: none; }
      .responsive-skin .control-button.btn-text:hover .button-content {
        text-decoration: none; }
    .responsive-skin .control-button.btn-text.btn-sm {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased; }
  .responsive-skin .control-button.got-it:hover {
    background-color: #fa815b; }
  .responsive-skin .control-button.btn-test-drive {
    background-color: #f96132; }
    .responsive-skin .control-button.btn-test-drive:hover {
      background-color: #fa815b; }
  .responsive-skin .control-button.btn-thumbnail:not([disabled]) {
    background-color: #fff; }
    .responsive-skin .control-button.btn-thumbnail:not([disabled]) path {
      fill: #116dff; }
    .responsive-skin .control-button.btn-thumbnail:not([disabled]):active {
      background-color: #fff; }
  .responsive-skin .control-button.btn-thumbnail[disabled] {
    background-color: #fff; }
    .responsive-skin .control-button.btn-thumbnail[disabled] path {
      fill: #bebebe; }
  .responsive-skin .control-button.btn-thumbnail:hover:not([disabled]):not(:active) {
    background-color: #f4f5f8; }
  .composite-secondary-media-labeled .responsive-skin .control-button,
  .composite-secondary-media .responsive-skin .control-button {
    background-color: #dce9ff; }
    .composite-secondary-media-labeled .responsive-skin .control-button:hover,
    .composite-secondary-media .responsive-skin .control-button:hover {
      background-color: #c3daff; }
    .composite-secondary-media-labeled .responsive-skin .control-button svg *,
    .composite-secondary-media .responsive-skin .control-button svg * {
      fill: #116dff; }
  .responsive-skin .control-button.icon-only {
    padding: 0; }
  .responsive-skin .control-button.animated-bg {
    position: relative;
    background: transparent; }
    .responsive-skin .control-button.animated-bg:not(:disabled) svg path {
      fill: currentColor; }
    .responsive-skin .control-button.animated-bg:not(:disabled) .button-content {
      position: relative;
      z-index: 1; }
    .responsive-skin .control-button.animated-bg:not(:disabled):before {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: none;
      box-sizing: border-box;
      transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      transform: scale(0);
      border-radius: 4px; }
    .responsive-skin .control-button.animated-bg:not(:disabled):hover:before, .responsive-skin .control-button.animated-bg:not(:disabled).active:before {
      background-color: #f4f5f8;
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: scale(1); }
    .responsive-skin .control-button.animated-bg:not(:disabled).active:before {
      background-color: #dce9ff; }
    .responsive-skin .control-button.animated-bg:not(:disabled).active svg path {
      fill: #116dff; }

.responsive-skin .button-content .suffix-icon {
  margin-left: 4px; }

.responsive-skin .button-content .prefix-icon {
  margin-right: 4px; }

.responsive-skin .composite-popup .composite-action-set-horizontal > .control-button,
.responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button,
.responsive-skin .dropdown-options .control-button {
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  padding: 6px 16px;
  border-radius: 4px;
  border-radius: 4px;
  background-color: #116dff;
  color: #fff;
  border: 1px solid transparent; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button:hover,
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button:hover,
  .responsive-skin .dropdown-options .control-button:hover {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button:focus-visible,
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button:focus-visible,
  .responsive-skin .dropdown-options .control-button:focus-visible {
    background-color: #0f62e6;
    color: #fff; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button:active,
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button:active,
  .responsive-skin .dropdown-options .control-button:active {
    background-color: #116dff;
    color: #fff; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button[disabled],
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button[disabled],
  .responsive-skin .dropdown-options .control-button[disabled] {
    cursor: default;
    color: #bebebe;
    background-color: #f3f3f3; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button[disabled].suffix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button[disabled].prefix-icon svg *,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button[disabled].suffix-icon svg *,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button[disabled].prefix-icon svg *,
    .responsive-skin .dropdown-options .control-button[disabled].suffix-icon svg *,
    .responsive-skin .dropdown-options .control-button[disabled].prefix-icon svg * {
      fill: #bebebe; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary,
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary,
  .responsive-skin .dropdown-options .control-button.secondary {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e2e3ea;
    color: #116dff; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:hover,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:hover,
    .responsive-skin .dropdown-options .control-button.secondary:hover {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:focus-visible,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:focus-visible,
    .responsive-skin .dropdown-options .control-button.secondary:focus-visible {
      background-color: #f4f5f8;
      color: #116dff; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:active,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:active,
    .responsive-skin .dropdown-options .control-button.secondary:active {
      background-color: #fff;
      color: #116dff; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary[disabled="true"],
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary[disabled="true"],
    .responsive-skin .dropdown-options .control-button.secondary:disabled,
    .responsive-skin .dropdown-options .control-button.secondary:disabled,
    .responsive-skin .dropdown-options .control-button.secondary[disabled="true"] {
      cursor: default;
      color: #bebebe;
      background-color: #fff;
      border-color: #bebebe; }
      .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled.suffix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled.prefix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled.suffix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary:disabled.prefix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary[disabled="true"].suffix-icon svg *, .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary[disabled="true"].prefix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled.suffix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled.prefix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled.suffix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary:disabled.prefix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary[disabled="true"].suffix-icon svg *,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary[disabled="true"].prefix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary:disabled.suffix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary:disabled.prefix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary:disabled.suffix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary:disabled.prefix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary[disabled="true"].suffix-icon svg *,
      .responsive-skin .dropdown-options .control-button.secondary[disabled="true"].prefix-icon svg * {
        fill: #bebebe; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary svg *,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary svg *,
    .responsive-skin .dropdown-options .control-button.secondary svg * {
      fill: #116dff; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.secondary[disabled="true"] svg *,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.secondary[disabled="true"] svg *,
    .responsive-skin .dropdown-options .control-button.secondary[disabled="true"] svg * {
      fill: #767676; }
  .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text,
  .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text,
  .responsive-skin .dropdown-options .control-button.btn-text {
    border-color: transparent;
    background: none;
    color: #116dff;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased; }
    .dropdown-options .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text, .dropdown-options
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text, .dropdown-options
    .responsive-skin .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text:hover:not(:disabled) > .button-content,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text:hover:not(:disabled) > .button-content,
    .responsive-skin .dropdown-options .control-button.btn-text:hover:not(:disabled) > .button-content {
      color: #116dff;
      text-decoration: none; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text:disabled,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text:disabled,
    .responsive-skin .dropdown-options .control-button.btn-text:disabled {
      color: #767676; }
    .composite-button-left .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text, .composite-button-left
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text, .composite-button-left
    .responsive-skin .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .composite-action-set-vertical .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text, .composite-action-set-vertical
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text, .composite-action-set-vertical
    .responsive-skin .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 16px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased; }
    .composite-button-large-fixed-bottom .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text,
    .composite-button-large .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text, .composite-button-large-fixed-bottom
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text,
    .composite-button-large
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text, .composite-button-large-fixed-bottom
    .responsive-skin .dropdown-options .control-button.btn-text,
    .composite-button-large
    .responsive-skin .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.footer-btn,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.footer-btn,
    .responsive-skin .dropdown-options .control-button.btn-text.footer-btn {
      color: #116dff; }
      .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.footer-btn:disabled,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.footer-btn:disabled,
      .responsive-skin .dropdown-options .control-button.btn-text.footer-btn:disabled {
        color: #bebebe; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.danger-btn:not([disabled="true"]),
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.danger-btn:hover > .button-content,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.danger-btn:not([disabled="true"]),
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.danger-btn:hover > .button-content,
    .responsive-skin .dropdown-options .control-button.btn-text.danger-btn:not([disabled="true"]),
    .responsive-skin .dropdown-options .control-button.btn-text.danger-btn:hover > .button-content {
      color: #ee4437; }
      .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.danger-btn:not([disabled="true"]) svg path,
      .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.danger-btn:hover > .button-content svg path,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.danger-btn:not([disabled="true"]) svg path,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.danger-btn:hover > .button-content svg path,
      .responsive-skin .dropdown-options .control-button.btn-text.danger-btn:not([disabled="true"]) svg path,
      .responsive-skin .dropdown-options .control-button.btn-text.danger-btn:hover > .button-content svg path {
        fill: #ee4437; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text:hover,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text:hover,
    .responsive-skin .dropdown-options .control-button.btn-text:hover {
      background-color: transparent;
      text-decoration: none; }
      .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text:hover .button-content,
      .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text:hover .button-content,
      .responsive-skin .dropdown-options .control-button.btn-text:hover .button-content {
        text-decoration: none; }
    .responsive-skin .composite-popup .composite-action-set-horizontal > .control-button.btn-text.btn-sm,
    .responsive-skin .composite-popup .composite-action-set-horizontal-spaced > .control-button.btn-text.btn-sm,
    .responsive-skin .dropdown-options .control-button.btn-text.btn-sm {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased; }

.classic-facelift-skin .control-button {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  background-color: #116dff;
  box-sizing: border-box; }
  .classic-facelift-skin .control-button:focus-visible {
    box-shadow: 0 0 0 3px #80b1ff; }
  .classic-facelift-skin .control-button:hover {
    background-color: #5999ff; }
  .classic-facelift-skin .control-button:active {
    background-color: #116dff; }
  .classic-facelift-skin .control-button[disabled] {
    background-color: #bcbcbc; }
  .classic-facelift-skin .control-button.btn-back:not(.btn-header-back) {
    background-color: #e7f0ff;
    color: #116dff; }
    .classic-facelift-skin .control-button.btn-back:not(.btn-header-back):hover {
      color: #116dff;
      background-color: #d6e6fe; }
    .classic-facelift-skin .control-button.btn-back:not(.btn-header-back) svg * {
      fill: #116dff; }
    .classic-facelift-skin .control-button.btn-back:not(.btn-header-back).suffix-icon svg *, .classic-facelift-skin .control-button.btn-back:not(.btn-header-back).prefix-icon svg * {
      fill: #116dff; }
  .classic-facelift-skin .control-button.btn-confirm-primary {
    background-color: #116dff; }
    .classic-facelift-skin .control-button.btn-confirm-primary:hover {
      background-color: #5999ff; }
    .classic-facelift-skin .control-button.btn-confirm-primary:active {
      background-color: #116dff; }
    .classic-facelift-skin .control-button.btn-confirm-primary[disabled] {
      background-color: #bcbcbc; }
    .classic-facelift-skin .control-button.btn-confirm-primary svg * {
      fill: #116dff; }
    .classic-facelift-skin .control-button.btn-confirm-primary.suffix-icon svg *, .classic-facelift-skin .control-button.btn-confirm-primary.prefix-icon svg * {
      fill: #116dff; }
  .classic-facelift-skin .control-button.btn-inverted {
    color: #116dff;
    border: 1px solid #116dff; }
    .classic-facelift-skin .control-button.btn-inverted:hover {
      background-color: #5999ff;
      border: 1px solid #5999ff; }
    .classic-facelift-skin .control-button.btn-inverted:focus-visible {
      background-color: #5999ff;
      border: 1px solid #5999ff; }
    .classic-facelift-skin .control-button.btn-inverted:active {
      background-color: transparent;
      border: 1px solid #116dff; }
    .classic-facelift-skin .control-button.btn-inverted svg * {
      fill: #116dff; }
    .classic-facelift-skin .control-button.btn-inverted.suffix-icon svg *, .classic-facelift-skin .control-button.btn-inverted.prefix-icon svg * {
      fill: #116dff; }
    .classic-facelift-skin .control-button.btn-inverted[disabled] {
      color: #bcbcbc;
      border: 1px solid #bcbcbc;
      background-color: transparent; }
      .classic-facelift-skin .control-button.btn-inverted[disabled].suffix-icon svg *, .classic-facelift-skin .control-button.btn-inverted[disabled].prefix-icon svg * {
        fill: #bcbcbc; }
  .classic-facelift-skin .control-button.btn-text {
    color: #116dff; }
    .classic-facelift-skin .control-button.btn-text:hover {
      background: none; }
    .classic-facelift-skin .control-button.btn-text:hover:not([disabled]) > .button-content {
      color: #116dff; }
    .classic-facelift-skin .control-button.btn-text[disabled] {
      color: #bcbcbc;
      background: none; }
    .classic-facelift-skin .control-button.btn-text svg * {
      fill: #116dff; }
    .classic-facelift-skin .control-button.btn-text.suffix-icon svg *, .classic-facelift-skin .control-button.btn-text.prefix-icon svg * {
      fill: #116dff; }
  .classic-facelift-skin .control-button.got-it:hover {
    background-color: #a8caff; }
  .classic-facelift-skin .control-button:not([disabled]).btn-ai-primary {
    position: relative;
    color: #fff;
    background: linear-gradient(60deg, #0021FF -31%, #116DFF 65%, #5FF2AE 96%); }
    .classic-facelift-skin .control-button:not([disabled]).btn-ai-primary:before {
      content: "";
      display: block;
      background: linear-gradient(60deg, #0021FF -177%, #116DFF 32%, #5FF2AE 96%);
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity .1s ease; }
    .classic-facelift-skin .control-button:not([disabled]).btn-ai-primary:not(:active):hover:before {
      opacity: 1; }
    .classic-facelift-skin .control-button:not([disabled]).btn-ai-primary .button-content {
      position: relative;
      z-index: 1; }
  .classic-facelift-skin .control-button.btn-test-drive {
    background-color: #fe620f; }
    .classic-facelift-skin .control-button.btn-test-drive:hover {
      background-color: #ff7d38; }
  .classic-facelift-skin .control-button.btn-header {
    background-color: #0057e1; }
    .classic-facelift-skin .control-button.btn-header:hover {
      background-color: #003790; }
  .classic-facelift-skin .control-button.btn-thumbnail {
    border-radius: 4px; }
    .classic-facelift-skin .control-button.btn-thumbnail:not([disabled]) {
      background-color: #e7f0ff; }
      .classic-facelift-skin .control-button.btn-thumbnail:not([disabled]):active {
        background-color: #116dff; }
    .classic-facelift-skin .control-button.btn-thumbnail[disabled] {
      background-color: #f0f0f0; }
      .classic-facelift-skin .control-button.btn-thumbnail[disabled] path {
        fill: #bcbcbc; }
    .classic-facelift-skin .control-button.btn-thumbnail:hover:not([disabled]):not(:active) {
      background-color: #d6e6fe; }
  .classic-facelift-skin .control-button.btn-big {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular); }
  .classic-facelift-skin .control-button.btn-md {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular); }
  .classic-facelift-skin .control-button.btn-sm {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium); }
  .classic-facelift-skin .control-button .control-label-base {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular); }

.classic-facelift-skin .control-button.btn-danger-primary,
.classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary,
.classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-primary {
  background-color: #e62214; }
  .classic-facelift-skin .control-button.btn-danger-primary:hover,
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:hover,
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-primary:hover {
    background-color: #ff6d63; }
  .classic-facelift-skin .control-button.btn-danger-primary:active,
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:active,
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-primary:active {
    background-color: #e62214; }
  .classic-facelift-skin .control-button.btn-danger-primary[disabled],
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary[disabled],
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-primary[disabled] {
    background-color: #bcbcbc; }

.classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-text > .button-content {
  color: #e62214; }
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-text > .button-content:hover {
    color: #ff6d63; }
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-text > .button-content:active {
    color: #e62214; }
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-text > .button-content[disabled] {
    color: #bcbcbc; }

.classic-facelift-skin .control-button.btn-danger-secondary,
.classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary,
.classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary {
  background-color: #fde3e1;
  color: #e62214; }
  .classic-facelift-skin .control-button.btn-danger-secondary:hover,
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:hover,
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary:hover {
    color: #e62214;
    background-color: #fbd0cd; }
  .classic-facelift-skin .control-button.btn-danger-secondary:active,
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:active,
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary:active {
    color: #e62214;
    background-color: #fde3e1; }
  .classic-facelift-skin .control-button.btn-danger-secondary[disabled],
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled],
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary[disabled] {
    color: #bcbcbc;
    background-color: #f0f0f0; }
    .classic-facelift-skin .control-button.btn-danger-secondary[disabled].suffix-icon svg *, .classic-facelift-skin .control-button.btn-danger-secondary[disabled].prefix-icon svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg *,
    .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary[disabled].prefix-icon svg * {
      fill: #bcbcbc; }
  .classic-facelift-skin .control-button.btn-danger-secondary svg *,
  .classic-facelift-skin .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary svg *,
  .classic-facelift-skin .control-base-modal.theme-destructive .control-button.btn-confirm-secondary svg * {
    fill: #e62214; }

.classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-primary {
  background-color: #9a27d5; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-primary:hover {
    background-color: #c161f0; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-primary:active {
    background-color: #9a27d5; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-primary[disabled] {
    background-color: #bcbcbc; }

.classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-text > .button-content {
  color: #9a27d5; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-text > .button-content:hover {
    color: #c161f0; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-text > .button-content:active {
    color: #9a27d5; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-text > .button-content[disabled] {
    color: #bcbcbc; }

.classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-secondary {
  background-color: #f1e0f9;
  color: #9a27d5; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-secondary:hover {
    color: #9a27d5;
    background-color: #e3c3f4; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-secondary:active {
    color: #9a27d5;
    background-color: #f1e0f9; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-secondary[disabled] {
    background-color: #bcbcbc; }
  .classic-facelift-skin .control-base-modal.theme-premium .control-button.btn-confirm-secondary svg * {
    fill: #9a27d5; }

.classic-facelift-skin .control-button.btn-light,
.classic-facelift-skin .control-button.btn-confirm-secondary,
.classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary,
.classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary,
.classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary {
  background-color: #e7f0ff;
  color: #116dff; }
  .classic-facelift-skin .control-button.btn-light:hover,
  .classic-facelift-skin .control-button.btn-confirm-secondary:hover,
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:hover,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary:hover,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:hover {
    color: #116dff;
    background-color: #d6e6fe; }
  .classic-facelift-skin .control-button.btn-light:active,
  .classic-facelift-skin .control-button.btn-confirm-secondary:active,
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:active,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary:active,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:active {
    color: #116dff;
    background-color: #e7f0ff; }
  .classic-facelift-skin .control-button.btn-light svg *,
  .classic-facelift-skin .control-button.btn-confirm-secondary svg *,
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary svg * {
    fill: #116dff; }
  .classic-facelift-skin .control-button.btn-light.suffix-icon svg *, .classic-facelift-skin .control-button.btn-light.prefix-icon svg *,
  .classic-facelift-skin .control-button.btn-confirm-secondary.suffix-icon svg *,
  .classic-facelift-skin .control-button.btn-confirm-secondary.prefix-icon svg *,
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.suffix-icon svg *,
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.prefix-icon svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary.suffix-icon svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary.prefix-icon svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.suffix-icon svg *,
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.prefix-icon svg * {
    fill: #116dff; }
  .classic-facelift-skin .control-button.btn-light[disabled],
  .classic-facelift-skin .control-button.btn-confirm-secondary[disabled],
  .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled],
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled],
  .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] {
    color: #bcbcbc;
    background-color: #f0f0f0; }
    .classic-facelift-skin .control-button.btn-light[disabled] svg *,
    .classic-facelift-skin .control-button.btn-confirm-secondary[disabled] svg *,
    .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] svg * {
      fill: #bcbcbc; }
    .classic-facelift-skin .control-button.btn-light[disabled].suffix-icon svg *, .classic-facelift-skin .control-button.btn-light[disabled].prefix-icon svg *,
    .classic-facelift-skin .control-button.btn-confirm-secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .control-button.btn-confirm-secondary[disabled].prefix-icon svg *,
    .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled].suffix-icon svg *,
    .classic-facelift-skin .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled].prefix-icon svg * {
      fill: #bcbcbc; }

.classic-facelift-skin .composite-secondary-media-labeled .control-button,
.classic-facelift-skin .composite-secondary-media .control-button {
  background-color: #e7f0ff; }
  .classic-facelift-skin .composite-secondary-media-labeled .control-button:hover,
  .classic-facelift-skin .composite-secondary-media .control-button:hover {
    background-color: #d6e6fe; }
  .classic-facelift-skin .composite-secondary-media-labeled .control-button svg *,
  .classic-facelift-skin .composite-secondary-media .control-button svg * {
    fill: #116dff; }
  .classic-facelift-skin .composite-secondary-media-labeled .control-button.suffix-icon svg *, .classic-facelift-skin .composite-secondary-media-labeled .control-button.prefix-icon svg *,
  .classic-facelift-skin .composite-secondary-media .control-button.suffix-icon svg *,
  .classic-facelift-skin .composite-secondary-media .control-button.prefix-icon svg * {
    fill: #116dff; }

.control-button {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  height: 36px;
  line-height: 24px;
  padding: 0 24px;
  border-radius: 18px;
  text-align: center;
  box-sizing: border-box;
  border: 0;
  outline: none;
  background-color: #116dff;
  color: #fff; }
  .control-button:focus-visible {
    box-shadow: 0 0 0 3px #80b1ff;
    background-color: #5999ff; }
  .control-button.suffix-icon {
    padding: 0 12px 0 24px; }
  .control-button.prefix-icon {
    padding: 0 24px 0 12px; }
  .control-button.with-ellipsis {
    max-width: 100%; }
  .control-button.full-width {
    width: 100%; }
  .control-button .button-content .has-tooltip {
    width: inherit;
    line-height: normal; }
  .composite-action-set-horizontal:not(.flex) .control-button:nth-of-type(1) {
    margin-left: 30px; }
  .composite-action-set-horizontal:not(.flex) .control-button:nth-of-type(2) {
    margin-left: 18px; }
  .composite-action-set-horizontal:not(.flex) .control-button.btn-text {
    display: flex; }
  .composite-action-set-horizontal.small .control-button.btn-text {
    height: 18px; }
  .composite-button-large-labeled .control-button {
    max-width: 100%;
    align-self: center; }
  .control-text-input-multiline-with-button .control-button {
    align-self: center;
    margin-top: 12px;
    margin-bottom: 6px; }
  .composite-button-medium .control-button,
  .composite-button-large-fixed-bottom .control-button,
  .composite-button-large .control-button,
  .composite-button-large-labeled .control-button {
    min-width: 84px; }
  .composite-action-set-vertical .control-button {
    min-width: 132px;
    max-width: 288px; }
    .composite-action-set-vertical .control-button svg {
      margin-left: 12px; }
  .control-upload-button .control-button {
    max-width: 100%; }
  .control-button svg {
    background: transparent; }
    .control-button svg * {
      fill: #fff; }
  .control-button:hover {
    background-color: #5999ff; }
  .control-button:active {
    background-color: #116dff; }
  .control-button[disabled] {
    cursor: default;
    color: #fff;
    background-color: #bcbcbc;
    /*
      NOTE: This fixes issues when disabled button is used with a Tooltip. The root issue
      is that browsers do not fire a mouseleave event for a disabled button. More details:
      https://github.com/facebook/react/issues/4251
    */
    pointer-events: none; }
  .control-button.btn-big {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 24px; }
    .control-button.btn-big.suffix-icon {
      padding: 0 12px 0 24px; }
    .control-button.btn-big.prefix-icon {
      padding: 0 24px 0 12px; }
  .control-button.btn-md,
  .composite-popup > .composite-action-set-horizontal > .control-button,
  .composite-popup > .composite-action-set-horizontal-spaced > .control-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 30px;
    line-height: 18px;
    padding: 0 18px;
    border-radius: 15px; }
    .control-button.btn-md.suffix-icon,
    .composite-popup > .composite-action-set-horizontal > .control-button.suffix-icon,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.suffix-icon {
      padding: 0 12px 0 18px; }
    .control-button.btn-md.prefix-icon,
    .composite-popup > .composite-action-set-horizontal > .control-button.prefix-icon,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.prefix-icon {
      padding: 0 18px 0 12px; }
    .control-button.btn-md.btn-round,
    .composite-popup > .composite-action-set-horizontal > .control-button.btn-round,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.btn-round {
      width: 30px;
      padding: 0; }
  .control-button.btn-sm {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    height: 24px;
    line-height: 18px;
    padding: 0 12px;
    border-radius: 15px; }
    .control-button.btn-sm.suffix-icon {
      padding: 0 6px 0 12px; }
    .control-button.btn-sm.prefix-icon {
      padding: 0 12px 0 6px; }
  .control-button.btn-back:not(.btn-header-back) {
    min-width: 67px;
    height: 21px;
    line-height: 13px;
    color: #116dff;
    background-color: #e7f0ff;
    font-weight: 300;
    font-size: 13px;
    margin: 15px 0 15px 12px;
    text-align: center; }
    .control-button.btn-back:not(.btn-header-back) > .button-content > svg {
      margin-right: 3px; }
    .composite-button-left .control-button.btn-back:not(.btn-header-back) {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 13px;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      min-width: 54px;
      line-height: 18px;
      padding-left: 6px;
      padding-right: 12px; }
      .composite-button-left .control-button.btn-back:not(.btn-header-back) > .button-content > span {
        max-width: 144px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
    .control-button.btn-back:not(.btn-header-back):hover {
      color: #116dff;
      background-color: #d6e6fe; }
    .control-button.btn-back:not(.btn-header-back) svg * {
      fill: #116dff; }
  .control-button.btn-danger-primary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary {
    background-color: #e62214;
    color: #fff; }
    .control-button.btn-danger-primary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:hover {
      color: #fff;
      background-color: #ff6d63; }
    .control-button.btn-danger-primary:focus-visible,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:focus-visible {
      background-color: #ff6d63; }
    .control-button.btn-danger-primary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:active {
      color: #fff;
      background-color: #e62214; }
    .control-button.btn-danger-primary[disabled],
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary[disabled] {
      cursor: default;
      color: #fff;
      background-color: #bcbcbc; }
  .control-button.btn-danger-secondary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary {
    background-color: #fde3e1;
    color: #e62214; }
    .control-button.btn-danger-secondary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:hover {
      color: #e62214;
      background-color: #fbd0cd; }
    .control-button.btn-danger-secondary:focus-visible,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:focus-visible {
      background-color: #fbd0cd; }
    .control-button.btn-danger-secondary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:active {
      color: #e62214;
      background-color: #fde3e1; }
    .control-button.btn-danger-secondary[disabled],
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled] {
      cursor: default;
      color: #fff;
      background-color: #bcbcbc; }
      .control-button.btn-danger-secondary[disabled].suffix-icon svg *, .control-button.btn-danger-secondary[disabled].prefix-icon svg *,
      .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
      .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg * {
        fill: #fff; }
    .control-button.btn-danger-secondary svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary svg * {
      fill: #e62214; }
    .control-button.btn-danger-secondary.suffix-icon svg *, .control-button.btn-danger-secondary.prefix-icon svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary.suffix-icon svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary.prefix-icon svg * {
      fill: #e62214; }
  .control-button.btn-confirm-primary {
    background-color: #116dff;
    color: #fff; }
    .control-button.btn-confirm-primary:hover {
      color: #fff;
      background-color: #5999ff; }
    .control-button.btn-confirm-primary:focus-visible {
      background-color: #5999ff; }
    .control-button.btn-confirm-primary:active {
      color: #fff;
      background-color: #116dff; }
    .control-button.btn-confirm-primary[disabled] {
      color: #fff;
      background-color: #bcbcbc; }
  .control-button.btn-inverted {
    background-color: transparent;
    color: #116dff;
    border: 1px solid #116dff; }
    .control-button.btn-inverted.suffix-icon svg *, .control-button.btn-inverted.prefix-icon svg * {
      fill: #116dff; }
    .control-button.btn-inverted:hover {
      color: #fff;
      background-color: #5999ff;
      border: 1px solid #5999ff; }
      .control-button.btn-inverted:hover.suffix-icon svg *, .control-button.btn-inverted:hover.prefix-icon svg * {
        fill: #fff; }
    .control-button.btn-inverted:focus-visible {
      color: #fff;
      background-color: #5999ff;
      border: 1px solid #5999ff; }
      .control-button.btn-inverted:focus-visible.suffix-icon svg *, .control-button.btn-inverted:focus-visible.prefix-icon svg * {
        fill: #fff; }
    .control-button.btn-inverted:active {
      color: #116dff;
      background-color: transparent; }
    .control-button.btn-inverted[disabled] {
      color: #fff;
      background-color: #bcbcbc;
      border: #bcbcbc; }
      .control-button.btn-inverted[disabled].suffix-icon svg *, .control-button.btn-inverted[disabled].prefix-icon svg * {
        fill: #fff; }
  .control-button.btn-premium-primary {
    background-color: #9a27d5;
    color: #fff; }
    .control-button.btn-premium-primary:hover {
      color: #fff;
      background-color: #c161f0; }
    .control-button.btn-premium-primary:focus-visible {
      background-color: #c161f0; }
    .control-button.btn-premium-primary:active {
      background-color: #9a27d5;
      color: #fff; }
    .control-button.btn-premium-primary[disabled] {
      background-color: #bcbcbc;
      color: #fff; }
  .control-button.btn-premium-secondary {
    color: #9a27d5;
    background-color: #eedbf4; }
    .control-button.btn-premium-secondary:hover {
      color: #9a27d5;
      background-color: #e5c9ee; }
    .control-button.btn-premium-secondary:focus-visible {
      background-color: #e5c9ee; }
    .control-button.btn-premium-secondary:active {
      color: #9a27d5;
      background-color: #eedbf4; }
    .control-button.btn-premium-secondary[disabled] {
      background-color: #f0f0f0;
      color: #bcbcbc; }
      .control-button.btn-premium-secondary[disabled].suffix-icon svg *, .control-button.btn-premium-secondary[disabled].prefix-icon svg * {
        fill: #bcbcbc; }
    .control-button.btn-premium-secondary.suffix-icon svg *, .control-button.btn-premium-secondary.prefix-icon svg * {
      fill: #9a27d5; }
  .control-button.btn-light, .control-button.btn-confirm-secondary,
  .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary,
  .composite-popup > .composite-action-set-horizontal > .control-button.secondary,
  .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary {
    background-color: #e7f0ff;
    color: #116dff; }
    .control-button.btn-light:hover, .control-button.btn-confirm-secondary:hover,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:hover,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary:hover,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:hover {
      color: #116dff;
      background-color: #d6e6fe; }
    .control-button.btn-light:focus-visible, .control-button.btn-confirm-secondary:focus-visible,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:focus-visible,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary:focus-visible,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:focus-visible {
      background-color: #d6e6fe; }
    .control-button.btn-light:active, .control-button.btn-confirm-secondary:active,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:active,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary:active,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:active {
      color: #116dff;
      background-color: #e7f0ff; }
    .control-button.btn-light svg *, .control-button.btn-confirm-secondary svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary svg *,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary svg *,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary svg * {
      fill: #116dff; }
    .control-button.btn-light.suffix-icon svg *, .control-button.btn-light.prefix-icon svg *, .control-button.btn-confirm-secondary.suffix-icon svg *, .control-button.btn-confirm-secondary.prefix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.suffix-icon svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.prefix-icon svg *,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary.suffix-icon svg *,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary.prefix-icon svg *,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.suffix-icon svg *,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.prefix-icon svg * {
      fill: #116dff; }
    .control-button.btn-light[disabled], .control-button.btn-confirm-secondary[disabled],
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled],
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled],
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] {
      cursor: default;
      color: #bcbcbc;
      background-color: #f0f0f0; }
      .control-button.btn-light[disabled] svg *, .control-button.btn-confirm-secondary[disabled] svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] svg * {
        fill: #bcbcbc; }
      .control-button.btn-light[disabled].suffix-icon svg *, .control-button.btn-light[disabled].prefix-icon svg *, .control-button.btn-confirm-secondary[disabled].suffix-icon svg *, .control-button.btn-confirm-secondary[disabled].prefix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg *,
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled].suffix-icon svg *,
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled].prefix-icon svg *,
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled].suffix-icon svg *,
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled].prefix-icon svg * {
        fill: #bcbcbc; }
    .media-button .control-button.btn-light, .media-button .control-button.btn-confirm-secondary, .media-button
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary, .media-button
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary, .media-button
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary {
      position: relative;
      display: block;
      margin: 0 auto; }
      .media-button .control-button.btn-light.has-file, .media-button .control-button.btn-confirm-secondary.has-file, .media-button
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.has-file, .media-button
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary.has-file, .media-button
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.has-file {
        padding-left: 40px; }
  .control-button.btn-text {
    background: none;
    font-size: 18px;
    color: #116dff;
    padding: 0; }
    .control-button.btn-text.suffix-icon svg *, .control-button.btn-text.prefix-icon svg * {
      fill: #116dff; }
    .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      padding: 0 24px;
      box-sizing: border-box; }
    .control-button.btn-text:hover:not([disabled]) > .button-content {
      color: #116dff;
      text-decoration: underline; }
    .control-button.btn-text[disabled] {
      cursor: default;
      color: #bcbcbc; }
      .control-button.btn-text[disabled].suffix-icon svg *, .control-button.btn-text[disabled].prefix-icon svg * {
        fill: #bcbcbc; }
    .control-button.btn-text > .button-content {
      display: inline-flex;
      align-items: baseline; }
      .control-dropdown-sticky-footer .control-button.btn-text > .button-content {
        align-items: center; }
    .composite-button-left .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 18px;
      margin-left: 12px;
      margin-top: 3px;
      height: auto; }
    .composite-action-set-vertical .control-button.btn-text {
      line-height: 20px;
      height: 20px;
      margin-top: 12px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased; }
    .composite-button-large-fixed-bottom .control-button.btn-text,
    .composite-button-large .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 16px;
      margin-top: 2px; }
      .composite-button-large-fixed-bottom .control-button.btn-text:nth-child(2),
      .composite-button-large .control-button.btn-text:nth-child(2) {
        margin-top: 8px; }
  .control-button.link {
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    border-radius: 0;
    overflow: initial; }
  .control-button.got-it {
    margin: 0;
    padding: 0 14px; }
    .control-button.got-it:hover {
      background-color: #a8caff; }
  .control-button.btn-test-drive {
    background-color: #fe620f; }
    .control-button.btn-test-drive:hover {
      background-color: #ff7d38; }
  .control-button.btn-header {
    background-color: #0057e1;
    width: 25px;
    height: 25px;
    padding: 0;
    position: absolute; }
    .control-button.btn-header:hover {
      background-color: #003790; }
  .control-button.btn-edit {
    height: 30px;
    width: 66px;
    padding: 0 6px; }
  .control-button.btn-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 4px;
    height: 100%; }
    .control-button.btn-thumbnail:not([disabled]) {
      background-color: #e7f0ff; }
      .control-button.btn-thumbnail:not([disabled]):active {
        background-color: #116dff; }
    .control-button.btn-thumbnail[disabled] {
      background-color: #f0f0f0; }
      .control-button.btn-thumbnail[disabled] path {
        fill: #bcbcbc; }
    .control-button.btn-thumbnail:hover:not([disabled]):not(:active) {
      background-color: #d6e6fe; }
  :not(.control-upload-button) > .control-button > .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }
  .composite-action-set-horizontal-spaced .control-button > .button-content > svg:first-child,
  .composite-action-set-vertical .control-button > .button-content > svg:first-child,
  .composite-action-set-horizontal .control-button > .button-content > svg:first-child {
    margin-right: 12px; }
  .composite-action-set-horizontal-spaced .control-button > .button-content > svg:last-child,
  .composite-action-set-vertical .control-button > .button-content > svg:last-child,
  .composite-action-set-horizontal .control-button > .button-content > svg:last-child {
    margin-left: 12px; }
  .control-upload-button > .control-button > .button-content {
    line-height: initial;
    font-weight: 530; }
    .control-upload-button > .control-button > .button-content .symbol:first-child {
      padding-right: 12px; }
  .composite-secondary-media-labeled .control-button,
  .composite-secondary-media .control-button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 0;
    background-color: #e7f0ff; }
    .composite-secondary-media-labeled .control-button:hover,
    .composite-secondary-media .control-button:hover {
      background-color: #d6e6fe; }
    .composite-secondary-media-labeled .control-button svg *,
    .composite-secondary-media .control-button svg * {
      fill: #116dff; }
    .composite-secondary-media-labeled .control-button.suffix-icon svg *, .composite-secondary-media-labeled .control-button.prefix-icon svg *,
    .composite-secondary-media .control-button.suffix-icon svg *,
    .composite-secondary-media .control-button.prefix-icon svg * {
      fill: #116dff; }
  .control-button .button-content .suffix-icon {
    margin-left: 3px;
    display: flex; }
  .control-button .button-content .prefix-icon {
    margin-right: 3px;
    display: flex; }
  .composite-secondary-media-labeled .control-secondary-media-image + .control-button,
  .composite-secondary-media .control-secondary-media-image + .control-button {
    margin-right: 6px; }
  .composite-secondary-media-labeled > .control-button,
  .composite-secondary-media > .control-button {
    display: inline-block;
    vertical-align: middle; }

.control-upload-button .upload-status-message {
  max-width: 100%;
  display: flex;
  margin-top: 16px; }
  .control-upload-button .upload-status-message .status-icon svg {
    width: 20px;
    height: 20px;
    margin-left: 6px; }

.control-upload-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .composite-upload-button-labeled > .control-upload-button {
    margin-top: 12px; }
  .composite-upload-button > .control-upload-button.no-bottom-message,
  .composite-upload-button-labeled > .control-upload-button.no-bottom-message {
    margin-bottom: 6px; }

.responsive-skin .control-text-button.skin-standard {
  fill: #116dff;
  color: #116dff; }
  .responsive-skin .control-text-button.skin-standard[disabled] {
    fill: #bebebe;
    color: #bebebe; }

.responsive-skin .control-text-button.skin-premium {
  fill: #9A27D5;
  color: #9A27D5; }
  .responsive-skin .control-text-button.skin-premium[disabled] {
    fill: #bebebe;
    color: #bebebe; }

.responsive-skin .control-text-button.skin-ai {
  fill: #5A48F5;
  color: #5A48F5; }
  .responsive-skin .control-text-button.skin-ai[disabled] {
    fill: #bebebe;
    color: #bebebe; }

.responsive-skin .control-text-button .control-text.skin-premium {
  fill: #9A27D5;
  color: #9A27D5; }
  .responsive-skin .control-text-button .control-text.skin-premium[disabled] {
    fill: #bebebe;
    color: #bebebe; }

.responsive-skin .skin-ai .control-text.skin-standard {
  fill: #5A48F5;
  color: #5A48F5; }
  .responsive-skin .skin-ai .control-text.skin-standard[disabled] {
    fill: #bebebe;
    color: #bebebe; }

.classic-facelift-skin .control-text-button.skin-standard {
  fill: #116dff;
  color: #116dff; }

.classic-facelift-skin .control-text-button.skin-premium {
  fill: #9a27d5;
  color: #9a27d5; }

.classic-facelift-skin .control-text-button.skin-dark {
  fill: #000624;
  color: #000624; }

.control-text-button {
  cursor: pointer;
  background: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  outline: none;
  padding: 0;
  max-width: 100%;
  vertical-align: middle;
  text-decoration: none; }
  .control-text-button.none .control-text {
    text-decoration: none; }
  .control-text-button.always .control-text {
    text-decoration: underline; }
  .control-text-button:hover.on-mouse-hover .control-text,
  .control-text-button:active.on-mouse-hover .control-text {
    text-decoration: underline; }
  .control-text-button[disabled] {
    cursor: default; }
    .control-text-button[disabled].always .control-text {
      text-decoration: none; }
    .control-text-button[disabled]:hover .control-text {
      text-decoration: none; }
  .control-text-button.skin-standard {
    fill: #116dff;
    color: #116dff; }
    .control-text-button.skin-standard[disabled] {
      fill: #939393;
      color: #939393; }
  .control-text-button.skin-premium {
    fill: #9a27d5;
    color: #9a27d5; }
    .control-text-button.skin-premium[disabled] {
      fill: #939393;
      color: #939393; }
  .control-text-button.skin-dark {
    fill: #000624;
    color: #000624; }
    .control-text-button.skin-dark[disabled] {
      fill: #939393;
      color: #939393; }
  .control-text-button .prefix {
    padding-right: 6px;
    display: inline-flex; }
  .control-text-button .suffix {
    padding-left: 6px;
    display: inline-flex; }
  .control-text .control-text-button {
    vertical-align: bottom; }

.responsive-skin .composite-toggle-switch .control-switch .symbol-switch:after {
  position: relative;
  display: inline-block; }

.responsive-skin .control-switch.switch-size-medium .symbol-switch {
  min-width: 28px;
  width: 28px;
  height: 16px; }
  .responsive-skin .control-switch.switch-size-medium .symbol-switch:after {
    width: 12px;
    height: 12px; }

.responsive-skin .control-switch .symbol-switch {
  background: #116dff;
  border-radius: 100px;
  min-width: 32px;
  width: 32px;
  height: 20px; }
  .responsive-skin .control-switch .symbol-switch:after {
    content: '';
    position: absolute;
    transform-origin: 0 0 !important;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    transform: translate(2px, 0);
    border-radius: 50%;
    background: #fff; }
  .responsive-skin .control-switch .symbol-switch > svg {
    display: none; }

.responsive-skin .control-switch .symbol-image {
  min-width: 40px; }

.responsive-skin .control-switch.control-switch-animated > .input:checked ~ .symbol-switch {
  transition: background 0.1s ease-in-out; }
  .responsive-skin .control-switch.control-switch-animated > .input:checked ~ .symbol-switch:after {
    transform: translate(14px, 0);
    transition: transform 0.1s ease-in-out; }

.responsive-skin .control-switch.control-switch-animated > .input:not(:checked) ~ .symbol-switch {
  transition: background 0.1s ease-in-out; }
  .responsive-skin .control-switch.control-switch-animated > .input:not(:checked) ~ .symbol-switch:after {
    transform: translate(2px, 0);
    transition: transform 0.1s ease-in-out; }

.responsive-skin .control-switch > .input:not(:checked):not(:disabled) ~ .symbol-switch {
  background: #42454C; }

.responsive-skin .control-switch > .input:checked ~ .symbol-switch:after {
  transform: translate(14px, 0); }

.responsive-skin .control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch {
  background: #116dff; }

.responsive-skin .control-switch:hover > .input:not(:checked):not(:disabled) ~ .symbol-switch {
  background: #868aa5; }

.responsive-skin .control-switch > .input:disabled ~ .symbol-switch {
  background: #bebebe; }

.classic-facelift-skin .control-switch > .input ~ .symbol-switch .switch-thumb-bg {
  fill: #d6e6fe; }

.classic-facelift-skin .control-switch > .input ~ .symbol-switch .switch-thumb-border {
  fill: #a8caff; }

.classic-facelift-skin .control-switch > .input ~ .symbol-switch .switch-thumb-circle {
  fill: #fff; }

.classic-facelift-skin .control-switch > .input ~ .symbol-switch .switch-thumb-minus {
  fill: #a8caff; }

.classic-facelift-skin .control-switch.emphasise > .input ~ .symbol-switch .switch-thumb-bg {
  fill: #fee8dc; }

.classic-facelift-skin .control-switch.emphasise > .input ~ .symbol-switch .switch-thumb-border {
  fill: #fdd1ba; }

.classic-facelift-skin .control-switch.emphasise > .input ~ .symbol-switch .switch-thumb-minus {
  fill: #ff7d38; }

.classic-facelift-skin .control-switch > .input:checked ~ .symbol-switch .switch-thumb-bg,
.classic-facelift-skin .control-switch > .input:checked ~ .symbol-switch .switch-thumb-border,
.classic-facelift-skin .control-switch > .input:checked ~ .symbol-switch .switch-thumb-check {
  fill: #116dff; }

.classic-facelift-skin .control-switch > .input:checked ~ .symbol-switch .switch-thumb-circle {
  fill: #fff; }

.classic-facelift-skin .control-switch.emphasise > .input:checked ~ .symbol-switch .switch-thumb-bg,
.classic-facelift-skin .control-switch.emphasise > .input:checked ~ .symbol-switch .switch-thumb-border,
.classic-facelift-skin .control-switch.emphasise > .input:checked ~ .symbol-switch .switch-thumb-check {
  fill: #fe620f; }

.classic-facelift-skin .control-switch.emphasise > .input:checked ~ .symbol-switch .switch-thumb-minus {
  fill: none; }

.classic-facelift-skin .control-switch:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
  fill: #a8caff; }

.classic-facelift-skin .control-switch.emphasise:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
  fill: #fdd1ba; }

.classic-facelift-skin .control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg,
.classic-facelift-skin .control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border,
.classic-facelift-skin .control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
  fill: #5999ff; }

.classic-facelift-skin .control-switch.emphasise:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg,
.classic-facelift-skin .control-switch.emphasise:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border {
  fill: #ff7d38; }

.classic-facelift-skin .control-switch.emphasise:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
  fill: #fe620f; }

.control-switch > .symbol-switch {
  align-self: center; }
  .control-switch > .symbol-switch > svg {
    vertical-align: middle; }
    .switch-size-medium.control-switch > .symbol-switch > svg {
      width: 40px; }
  .composite-toggle-switch .control-switch > .symbol-switch {
    margin-left: 20px; }

.control-switch > .input ~ .symbol-switch .switch-thumb-bg {
  fill: #d6e6fe; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-bg {
    fill: #fee8dc; }

.control-switch > .input ~ .symbol-switch .switch-thumb-border {
  fill: #a8caff; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-border {
    fill: #fdd1ba; }

.control-switch > .input ~ .symbol-switch .switch-thumb-circle {
  fill: #fff; }

.control-switch > .input ~ .symbol-switch .switch-thumb-minus {
  fill: #a8caff; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-minus {
    fill: #ff7d38; }

.control-switch > .input ~ .symbol-switch .switch-thumb-check {
  fill: none; }

.control-switch > .input ~ .symbol-switch .switch-thumb-regular {
  visibility: visible; }

.control-switch > .input ~ .symbol-switch .switch-thumb-selected {
  visibility: hidden; }

.control-switch.control-switch-animated > .input ~ .symbol-switch g,
.control-switch.control-switch-animated > .input ~ .symbol-switch path {
  transition: fill 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input ~ .symbol-switch .switch-thumb-regular {
  transform: translate(0, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input ~ .symbol-switch .switch-thumb-selected {
  transform: translate(-22px, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-bg,
.control-switch > .input:checked ~ .symbol-switch .switch-thumb-border {
  fill: #116dff; }
  .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-bg, .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-border {
    fill: #fe620f; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-circle {
  fill: #fff; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-minus {
  fill: none; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-check {
  fill: #116dff; }
  .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-check {
    fill: #fe620f; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-regular {
  visibility: hidden; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-selected {
  visibility: visible; }

.control-switch.control-switch-animated > .input:checked ~ .symbol-switch .switch-thumb-regular {
  transform: translate(22px, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input:checked ~ .symbol-switch .switch-thumb-selected {
  transform: translate(0, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-bg {
  fill: #ececec; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-border {
  fill: #d5d5d5; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-circle {
  filter: none; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-minus {
  fill: #c8c8c8; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-check {
  fill: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-bg {
  fill: #ececec; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-border {
  fill: #d5d5d5; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-circle {
  filter: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-minus {
  fill: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-check {
  fill: #c8c8c8; }

.control-switch:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
  fill: #a8caff; }
  .emphasise.control-switch:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
    fill: #fdd1ba; }

.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg,
.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border {
  fill: #5999ff; }
  .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg, .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border {
    fill: #ff7d38; }

.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
  fill: #5999ff; }
  .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
    fill: #fe620f; }

.control-switch.width-by-text-length > .symbol-switch {
  width: 86px;
  display: inline-block;
  text-align: right; }

.responsive-skin .control-switch {
  position: relative;
  padding: 0; }
  .responsive-skin .control-switch > .label-switch {
    outline: none;
    color: #131720; }
    .has-on-check.responsive-skin:not(.disabled) .control-switch > .label-switch {
      color: #868aa5; }

.responsive-skin .symbol-switch {
  outline: none; }

.responsive-skin .control-switch.disabled > .label-switch {
  color: #767676 !important; }

.classic-facelift-skin .control-switch > .label-switch {
  color: #3b4057; }

.control-switch.width-by-text-length {
  display: block; }

.control-switch > .label-switch {
  align-self: center;
  line-height: 18px;
  color: #3b4057;
  -webkit-user-select: none;
          user-select: none; }
  .secondary-color.control-switch > .label-switch {
    color: #000624; }
  .disabled.control-switch > .label-switch {
    color: #939393; }
  .has-on-check.control-switch:not(.disabled) > .label-switch {
    color: #116dff; }
    .has-on-check.control-switch:not(.disabled) > .label-switch:hover {
      text-decoration: underline; }
  .composite-toggle-switch .control-switch > .label-switch {
    display: inline-block;
    max-height: 36px;
    overflow: hidden; }

.control-switch .label-after-symbol {
  flex-grow: 1;
  margin-left: 12px; }

.control-switch .symbol-after-label {
  margin-left: 12px; }

.control-switch > .symbol-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #dfe5eb;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; }
  .disabled.control-switch > .symbol-image {
    border-color: #ececec; }
  .composite-toggle-switch .control-switch > .symbol-image {
    margin-top: -6px;
    margin-bottom: -6px; }
  .control-switch > .symbol-image > svg > g,
  .control-switch > .symbol-image > svg > path {
    fill: #2d3034; }
    .disabled.control-switch > .symbol-image > svg > g, .disabled.control-switch > .symbol-image > svg > path {
      fill: #898989; }

.control-switch .info-icon {
  visibility: hidden;
  display: flex;
  justify-content: center;
  padding-left: 6px; }
  .control-switch .info-icon.before-toggle {
    margin-right: auto;
    padding-right: 12px; }

.control-switch:hover .info-icon {
  visibility: visible; }

.classic-facelift-skin .control-checkbox > .input ~ .symbol-checkbox .bg {
  fill: #fff; }

.classic-facelift-skin .control-checkbox > .input ~ .symbol-checkbox .border {
  fill: #116dff; }

.classic-facelift-skin .control-checkbox > .input:disabled ~ .symbol-checkbox .bg {
  fill: #fff; }

.classic-facelift-skin .control-checkbox > .input:disabled ~ .symbol-checkbox .border {
  fill: #bcbcbc; }

.classic-facelift-skin .control-checkbox > .input:checked ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox > .input:checked ~ .symbol-checkbox .border {
  fill: #116dff; }

.classic-facelift-skin .control-checkbox > .input:checked ~ .symbol-checkbox .check {
  fill: #fff; }

.classic-facelift-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .border {
  fill: #bcbcbc; }

.classic-facelift-skin .control-checkbox:hover > .input:not(:disabled) ~ .symbol-checkbox .bg {
  fill: #d6e6fe; }

.classic-facelift-skin .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .border {
  fill: #5999ff; }

.classic-facelift-skin .control-checkbox:active > .input:not(:disabled) ~ .symbol-checkbox .bg {
  fill: #fff; }

.classic-facelift-skin .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .border {
  fill: #116dff; }

.classic-facelift-skin .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .border {
  fill: #116dff; }

.classic-facelift-skin .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .minus {
  fill: #fff; }

.classic-facelift-skin .control-checkbox:hover > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox:hover > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .border {
  fill: #5999ff; }

.classic-facelift-skin .control-checkbox:active > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg,
.classic-facelift-skin .control-checkbox:active > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .border {
  fill: #116dff; }

.classic-facelift-skin .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .bg {
  fill: #e2e2e2; }

.classic-facelift-skin .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .border,
.classic-facelift-skin .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .minus {
  fill: #d5d5d5; }

.responsive-skin .symbol-checkbox {
  display: inline-block; }
  .responsive-skin .symbol-checkbox > svg {
    display: block;
    margin: 0 auto; }

.responsive-skin .composite-checkboxes-labeled .control-checkbox .label-checkbox,
.responsive-skin .composite-checkboxes .control-checkbox .label-checkbox {
  margin-right: 12px;
  margin-left: 0; }

.responsive-skin .composite-checkboxes-labeled .control-checkbox .label-checkbox:last-child,
.responsive-skin .composite-checkboxes .control-checkbox .label-checkbox:last-child {
  margin-right: 0;
  margin-left: 12px; }

.responsive-skin .control-checkbox > .input ~ .symbol-checkbox .bg {
  fill: #fff; }

.responsive-skin .control-checkbox > .input ~ .symbol-checkbox .border {
  fill: #868aa5; }

.responsive-skin .control-checkbox > .input:disabled ~ .symbol-checkbox .bg {
  fill: #fff; }

.responsive-skin .control-checkbox > .input:disabled ~ .symbol-checkbox .border {
  fill: #767676; }

.responsive-skin .control-checkbox > .input:checked:not(:disabled) ~ .symbol-checkbox .bg,
.responsive-skin .control-checkbox > .input:checked:not(:disabled) ~ .symbol-checkbox .border,
.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:checked:not(:disabled) ~ .symbol-checkbox .bg,
.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:checked:not(:disabled) ~ .symbol-checkbox .border {
  fill: #116dff; }

.responsive-skin .control-checkbox > .input:checked:not(:disabled) ~ .symbol-checkbox .check,
.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:checked:not(:disabled) ~ .symbol-checkbox .check {
  fill: #fff; }

.responsive-skin .control-checkbox > .input:checked:not(:disabled) ~ .symbol-checkbox .minus,
.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:checked:not(:disabled) ~ .symbol-checkbox .minus {
  fill: transparent; }

.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg {
  fill: #fff; }

.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .border {
  fill: #868aa5; }

.responsive-skin .control-checkbox > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .minus {
  fill: #418aff; }

.responsive-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .bg,
.responsive-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .border {
  fill: #767676; }

.responsive-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .check {
  fill: #fff; }

.responsive-skin .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .minus {
  fill: transparent; }

.responsive-skin .control-checkbox:hover > .input:not(:checked):not(:disabled) ~ .symbol-checkbox .bg {
  fill: #f4f5f8; }

.responsive-skin .control-checkbox:hover > .input:not(:checked):not(:disabled) ~ .symbol-checkbox .minus {
  fill: transparent; }

.responsive-skin .control-checkbox:hover > .input[data-indeterminate="true"]:not(:checked):not(:disabled) ~ .symbol-checkbox .bg {
  fill: #f4f5f8; }

.responsive-skin .control-checkbox:hover > .input[data-indeterminate="true"]:not(:checked):not(:disabled) ~ .symbol-checkbox .minus {
  fill: #418aff; }

.responsive-skin .control-checkbox:hover > .input:checked:not(:disabled) ~ .symbol-checkbox .bg,
.responsive-skin .control-checkbox:hover > .input:checked:not(:disabled) ~ .symbol-checkbox .border {
  fill: #418aff; }

.responsive-skin .control-checkbox:hover > .input:checked:not(:disabled) ~ .symbol-checkbox .minus {
  fill: transparent; }

.symbol-checkbox {
  display: inline-block; }
  .symbol-checkbox > svg {
    display: block;
    margin: 0 auto; }
  .emphasise.control-checkbox > .input ~ .symbol-checkbox .border {
    fill: #fe620f; }
  .control-checkbox > .input ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input ~ .symbol-checkbox .border {
    fill: #116dff; }
  .control-checkbox > .input ~ .symbol-checkbox .check,
  .control-checkbox > .input ~ .symbol-checkbox .minus {
    fill: transparent; }
  .control-checkbox > .input:disabled ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input:disabled ~ .symbol-checkbox .border {
    fill: #bcbcbc; }
  .emphasise.control-checkbox > .input:checked ~ .symbol-checkbox .bg,
  .emphasise.control-checkbox > .input:checked ~ .symbol-checkbox .border {
    fill: #fe620f; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .bg,
  .control-checkbox > .input:checked ~ .symbol-checkbox .border {
    fill: #116dff; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .check {
    fill: #fff; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .minus {
    fill: transparent; }
  .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .bg {
    fill: #bcbcbc; }
  .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .border {
    fill: #bcbcbc; }
  .emphasise.control-checkbox:hover > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #fee8dc; }
  .control-checkbox:hover > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #d6e6fe; }
  .emphasise.control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
  .emphasise.control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #ff7d38; }
  .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
  .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #5999ff; }
  .control-checkbox:active > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
  .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #116dff; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .bg,
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox border {
    fill: #116dff; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .minus {
    fill: #fff; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .check {
    fill: transparent; }
  .control-checkbox:hover > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg,
  .control-checkbox:hover > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .border {
    fill: #5999ff; }
  .control-checkbox:active > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg,
  .control-checkbox:active > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .border {
    fill: #116dff; }
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .bg {
    fill: #e2e2e2; }
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .border,
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .minus {
    fill: #d5d5d5; }

.classic-facelift-skin .control-checkbox > .input:not(:disabled) ~ .label-checkbox {
  color: #000624; }

.composite-checkboxes-labeled .responsive-skin:not(:last-child) .control-boolean.control-checkbox,
.composite-checkboxes .responsive-skin:not(:last-child) .control-boolean.control-checkbox,
.responsive-skin .composite-checkboxes-labeled .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox,
.responsive-skin .composite-checkboxes .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox {
  margin-bottom: 8px; }

.responsive-skin .control-checkbox .label-checkbox {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased; }

.responsive-skin .control-checkbox > .input ~ .label-checkbox {
  color: #131720; }

.responsive-skin .control-checkbox.disabled > .input ~ .label-checkbox {
  color: #767676; }

.control-boolean.control-checkbox {
  justify-content: flex-start;
  align-items: center;
  line-height: 18px; }
  .control-boolean.control-checkbox.align-top {
    align-items: flex-start; }
  .control-boolean.control-checkbox.align-center {
    align-items: center; }
  .composite-checkboxes-labeled .control-boolean.control-checkbox:not(:last-child),
  .composite-checkboxes .control-boolean.control-checkbox:not(:last-child),
  .composite-checkboxes-labeled .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox,
  .composite-checkboxes .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox,
  .composite-checkboxes-labeled .checkbox-indented:not(:last-child) .control-boolean.control-checkbox,
  .composite-checkboxes .checkbox-indented:not(:last-child) .control-boolean.control-checkbox {
    margin-bottom: 11px; }
  .composite-checkboxes-labeled .control-boolean.control-checkbox,
  .composite-checkboxes .control-boolean.control-checkbox,
  .composite-checkboxes-labeled .composite-checkbox-with-info-icon .control-boolean.control-checkbox,
  .composite-checkboxes .composite-checkbox-with-info-icon .control-boolean.control-checkbox {
    width: calc(100% - (42px - 24px)); }
  .composite-action-set-horizontal .control-boolean.control-checkbox {
    flex: 1; }

.control-checkbox {
  color: #000624; }
  .control-checkbox .label-checkbox {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    margin-right: 13px;
    margin-left: 0;
    min-width: 0; }
  .control-checkbox .label-checkbox:last-child {
    margin-right: 0;
    margin-left: 13px; }
  .disabled.control-checkbox {
    color: #7a7a7a; }

.control-checkbox > .input ~ .info-checkbox {
  position: absolute;
  right: 12px;
  top: 1px; }

.composite-checkbox-with-action {
  display: flex;
  justify-content: space-between; }
  .composite-checkbox-with-action .control-boolean.control-checkbox {
    width: 100%; }

.color-picker-color-space {
  width: 100%;
  height: 133px;
  font-size: 0; }
  .color-picker-choose-color-dialog .color-picker-color-space {
    padding-bottom: 12px; }
  .color-picker-color-space > .color-space-gradient-area {
    width: calc(100% - 10px);
    height: 123px;
    position: relative;
    overflow: hidden;
    cursor: crosshair;
    display: inline-block; }
    .color-picker-color-space > .color-space-gradient-area > .color-space-marker {
      position: absolute;
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 5px;
      transform: translate(-50%, -50%);
      z-index: 1; }
    .color-picker-color-space > .color-space-gradient-area > .color-gradient-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .color-picker-color-space > .color-space-gradient-area > .color-gradient-layer.saturation-layer {
        background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
      .color-picker-color-space > .color-space-gradient-area > .color-gradient-layer.brightness-layer {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), black); }
  .color-picker-color-space > .color-picker-brightness-options {
    width: 10px;
    height: calc(100% - 10px);
    display: inline-block; }
    .color-picker-color-space > .color-picker-brightness-options > .brightness-option {
      cursor: pointer;
      height: 20%;
      width: 100%; }
  .color-picker-color-space > .color-picker-hue-slider {
    height: 10px;
    width: 100%; }

.responsive-skin.dark-mode .dropdown-options {
  background: #131720; }

.responsive-skin.dark-mode .dropdown-options-container .dropdown-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: rgba(190, 190, 190, 0.8);
  border-radius: 3px; }

.responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option {
  color: #f4f5f8; }
  .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
    background-color: #2B2E36; }
  .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option.selected {
    color: #fff;
    background-color: #116dff; }
    .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option.selected .control-illustration svg path {
      fill: #fff; }
  .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option.disabled {
    color: #42454C; }
    .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option.disabled path {
      fill: #42454C; }
  .responsive-skin.dark-mode .dropdown-options-container .control-dropdown-option .control-label {
    color: #f4f5f8; }

.responsive-skin.dark-mode .dropdown-options-container.larger-item-list-dropdown-options .control-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3b4057; }

.responsive-skin.dark-mode .dropdown-options-container.detailed-dropdown-options .control-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3b4057; }

.responsive-skin .dropdown-options-container.themes-dropdown-options .control-dropdown-option {
  height: 60px;
  line-height: 60px; }
  .responsive-skin .dropdown-options-container.themes-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: none; }

.responsive-skin .dropdown-options-container.themes-dropdown-options .dropdown-arrow-down {
  transform: translateY(-100%); }

.responsive-skin .dropdown-options-container.dropdown-options-with-sticky-footer .control-dropdown-option:not(:last-child) {
  border-bottom: none; }

.responsive-skin .dropdown-options-container.dropdown-options-with-sticky-footer .dropdown-arrow-down {
  transform: translateY(-44px); }

.responsive-skin .dropdown-options-container .dropdown-options {
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(17, 18, 25, 0.141); }

.responsive-skin .dropdown-options-container .control-dropdown-option {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  height: 36px;
  line-height: 36px;
  padding: 0 12px; }
  .responsive-skin .dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
    background-color: #f4f5f8; }
  .responsive-skin .dropdown-options-container .control-dropdown-option.selected {
    color: #116dff;
    background-color: #dce9ff; }

.classic-facelift-skin .dropdown-options-container .dropdown-options {
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08); }
  .classic-facelift-skin .dropdown-options-container .dropdown-options > .dropdown-arrow-up svg,
  .classic-facelift-skin .dropdown-options-container .dropdown-options > .dropdown-arrow-down svg {
    fill: #116dff; }

.classic-facelift-skin .dropdown-options-container .control-dropdown-option {
  color: #000624; }
  .classic-facelift-skin .dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
    background-color: #d6e6fe; }
  .classic-facelift-skin .dropdown-options-container .control-dropdown-option.selected {
    background-color: #116dff;
    color: #fff; }
  .classic-facelift-skin .dropdown-options-container .control-dropdown-option.disabled {
    color: #bcbcbc; }

.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow {
  overflow: visible; }
  .arrow-left-top.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    left: -5px;
    top: 16px; }
  .arrow-left-bottom.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    left: -5px;
    bottom: 18px; }
  .arrow-left-middle.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    left: -5px;
    top: calc(50% - 5px); }
  .arrow-right-top.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    right: -5px;
    top: 16px; }
  .arrow-right-bottom.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    right: -5px;
    bottom: 18px; }
  .arrow-right-middle.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    right: -5px;
    top: calc(50% - 5px); }
  .arrow-top-left.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    top: -5px;
    left: 18px; }
  .arrow-top-right.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    top: -5px;
    right: 18px; }
  .arrow-top-middle.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 6, 36, 0.35);
    top: -5px;
    left: calc(50% - 5px); }
  .arrow-bottom-left.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    bottom: -5px;
    left: 18px; }
  .arrow-bottom-right.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    bottom: -5px;
    right: 18px; }
  .arrow-bottom-middle.dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 6, 36, 0.35);
    bottom: -5px;
    left: calc(50% - 5px); }
  .dropdown-options-container.context-menu-options .dropdown-options.with-popover-arrow::before {
    width: 10px;
    height: 10px;
    content: '';
    background-color: inherit;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    z-index: -1;
    overflow: visible; }

.dropdown-options-container.context-menu-options .dropdown-options .dropdown-options-inner {
  padding: 6px 0;
  box-sizing: border-box;
  overflow: visible; }

.dropdown-options-container.larger-item-list-dropdown-options .control-dropdown-option {
  height: 52px;
  line-height: 52px; }
  .dropdown-options-container.larger-item-list-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: 1px solid #dfe5eb; }
  .dropdown-options-container.larger-item-list-dropdown-options .control-dropdown-option > .selected-content {
    display: flex;
    height: 100%; }

.dropdown-options-container.themes-dropdown-options .control-dropdown-option {
  height: 72px;
  line-height: 72px; }
  .dropdown-options-container.themes-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: 1px solid #dfe5eb; }
  .dropdown-options-container.themes-dropdown-options .control-dropdown-option > .selected-content {
    display: flex;
    height: 100%; }

.dropdown-options-container.dropdown-options-with-sticky-footer .control-dropdown-option:not(:last-child) {
  border-bottom: none; }

.dropdown-options-container.dropdown-options-with-sticky-footer .dropdown-arrow-down {
  transform: translateY(-35px); }

.dropdown-options-container.detailed-dropdown-options .control-dropdown-option {
  height: 72px;
  line-height: 72px; }
  .dropdown-options-container.detailed-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: 1px solid #dfe5eb; }
  .dropdown-options-container.detailed-dropdown-options .control-dropdown-option > .selected-content {
    display: flex;
    height: 100%; }

.dropdown-options-container .block-click-outside {
  z-index: 5997;
  cursor: default;
  position: fixed;
  top: -100vh;
  left: -100vw;
  height: 300vh;
  width: 300vw; }

.dropdown-options-container .dropdown-options {
  z-index: 5998;
  cursor: auto;
  overflow: hidden;
  position: fixed;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 24px); }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-up,
  .dropdown-options-container .dropdown-options > .dropdown-arrow-down {
    background-color: rgba(255, 255, 255, 0.93);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    height: 36px;
    left: 0;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 1; }
    .dropdown-options-container .dropdown-options > .dropdown-arrow-up svg,
    .dropdown-options-container .dropdown-options > .dropdown-arrow-down svg {
      fill: #116dff; }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-up {
    top: 0;
    transform: rotate(180deg); }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-down {
    bottom: 0; }
  .dropdown-options-container .dropdown-options > .dropdown-options-inner {
    overflow-y: scroll;
    height: 100%;
    width: 100%; }
    .hidden-scroll.dropdown-options-container .dropdown-options > .dropdown-options-inner {
      padding: 0 30px;
      margin: 0 -30px; }

.dropdown-options-container .control-dropdown-option {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  color: #000624;
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  text-align: left; }
  @media (max-width: 210px) {
    .dropdown-options-container .control-dropdown-option {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; } }
  .dropdown-options-container .control-dropdown-option.sub-option {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding-left: 36px; }
  .dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
    background-color: #d6e6fe; }
    .dark-skin.orange-colors.dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
      background-color: #fee8dc; }
  .dropdown-options-container .control-dropdown-option.selected {
    background-color: #116dff;
    color: #fff; }
    .dark-skin.orange-colors.dropdown-options-container .control-dropdown-option.selected {
      background-color: #fe620f; }
  .dropdown-options-container .control-dropdown-option.disabled {
    color: #bcbcbc;
    cursor: default; }

.responsive-skin .control-dropdown-sticky-footer {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  border-top: 1px solid #e5e5e5;
  padding: 0 24px;
  height: 44px; }
  .responsive-skin .control-dropdown-sticky-footer .control-button.btn-text {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    height: 44px; }
    .responsive-skin .control-dropdown-sticky-footer .control-button.btn-text:hover > .button-content {
      text-decoration: none; }

.responsive-skin.dark-mode .control-dropdown-sticky-footer {
  border-top: 1px solid #2B2E36;
  background-color: #131720; }
  .responsive-skin.dark-mode .control-dropdown-sticky-footer .control-button.btn-text {
    color: #7cafff; }
    .responsive-skin.dark-mode .control-dropdown-sticky-footer .control-button.btn-text:hover > .button-content {
      color: #7cafff;
      text-decoration: none; }

.control-dropdown-sticky-footer {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFF;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  padding: 0 24px;
  height: 35px;
  line-height: 35px; }

.responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown {
  position: absolute;
  right: 24px;
  top: 0;
  height: 100%;
  display: inline-block; }
  .responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown.label-styled-drop-down {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    padding: 0 3px 0 4px;
    height: auto;
    border-radius: 0 3px 3px 0; }
    .responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown.label-styled-drop-down .selected-content {
      font-size: 10px;
      font-weight: 500; }
    .responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown.label-styled-drop-down:not(.disabled) .selected-content {
      color: #116dff; }
    .responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown.label-styled-drop-down:hover:not(.disabled) {
      background-color: #f4f5f8;
      transition: backgroun-color 0.2s ease-in-out; }
  .responsive-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown > .dropdown-selected {
    height: 100%; }

.responsive-skin .composite-input-with-drop-down .input-container:not(.is-disabled) + .control-dropdown-base.control-dropdown.disabled .selected-content,
.responsive-skin .composite-input-with-drop-down .input-container:not(.is-disabled) + .has-tooltip .control-dropdown-base.control-dropdown.disabled .selected-content {
  color: #131720; }

.responsive-skin .composite-input-with-drop-down:hover .control-dropdown-base.control-dropdown.hidden-scroll.label-styled-drop-down {
  z-index: 1; }

.responsive-skin .composite-drop-down-labeled-horizontal .control-dropdown-base.control-dropdown .selected-content {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  width: 70px !important;
  display: inline-block; }

.classic-facelift-skin .composite-input-with-drop-down .control-dropdown-base.control-dropdown.hidden-scroll.default-skin .selected-content {
  color: #116dff; }

.classic-facelift-skin .composite-input-with-drop-down:hover .control-dropdown-base.control-dropdown.hidden-scroll .selected-content {
  color: #3b4057; }

.classic-facelift-skin .composite-input-with-drop-down:hover .control-dropdown-base .dropdown-selected .selected-container .symbol-arrow-down > svg {
  fill: #116dff; }

.control-dropdown-base {
  position: relative; }
  .composite-dropdown-with-color-input-labeled .control-dropdown-base,
  .composite-dropdown-with-button-labeled .control-dropdown-base {
    flex: 1;
    order: -1;
    max-width: 192px; }
  .input-borders-experiment .composite-dropdown-with-color-input-labeled .control-dropdown-base, .input-borders-experiment
  .composite-dropdown-with-button-labeled .control-dropdown-base {
    flex: unset;
    order: unset;
    max-width: unset; }
  .composite-text-formatting .control-dropdown-base {
    margin: 0 4px; }
  .composite-text-input-labeled .control-dropdown-base.label-styled-drop-down,
  .composite-text-input-labeled .control-dropdown-base.composite-input-with-drop-down {
    padding: 0 12px; }
  .control-dropdown-base > .dropdown-selected {
    cursor: pointer; }
    .disabled.control-dropdown-base > .dropdown-selected {
      cursor: default; }

.composite-input-with-drop-down .control-dropdown-base.control-dropdown {
  position: absolute;
  right: 24px;
  top: 0;
  height: 100%;
  display: inline-block; }
  .composite-input-with-drop-down .control-dropdown-base.control-dropdown.hidden-scroll.default-skin {
    position: absolute;
    right: 2px; }
    .composite-input-with-drop-down .control-dropdown-base.control-dropdown.hidden-scroll.default-skin .selected-content {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      font-weight: normal;
      color: #000624;
      position: absolute;
      top: 8px;
      right: 22px; }
  .input-borders-experiment .composite-input-with-drop-down .control-dropdown-base.control-dropdown > .dropdown-selected {
    height: 34px !important;
    border: none;
    padding: 0;
    margin: 1px 0;
    background-color: transparent !important; }

.composite-input-with-drop-down .control-dropdown-base .dropdown-selected .selected-container .symbol-arrow-down > svg {
  visibility: hidden; }

.composite-input-with-drop-down:hover .control-dropdown-base.control-dropdown.hidden-scroll {
  z-index: 1; }

.composite-input-with-drop-down:hover .control-dropdown-base.control-dropdown.hidden-scroll .selected-content {
  color: #3b4057; }

.composite-input-with-drop-down:hover .control-dropdown-base .dropdown-selected .selected-container .symbol-arrow-down > svg {
  fill: #116dff;
  visibility: visible; }

.classic-facelift-skin .context-menu-button {
  border: 1px solid #116dff; }
  .classic-facelift-skin .context-menu-button > svg > path {
    fill: #116dff; }
  .classic-facelift-skin .context-menu-button:hover {
    background-color: #5999ff;
    border-color: #5999ff; }
    .classic-facelift-skin .context-menu-button:hover > svg > path {
      fill: #fff; }
  .classic-facelift-skin .context-menu-button:active {
    background-color: #116dff;
    border-color: #116dff; }
    .classic-facelift-skin .context-menu-button:active > svg > path {
      fill: #fff; }

.classic-facelift-skin .is-open .context-menu-button {
  background-color: #116dff;
  border-color: #116dff; }

.classic-facelift-skin .flat .context-menu-button {
  border-color: #116dff; }
  .classic-facelift-skin .flat .context-menu-button:hover {
    background-color: #5999ff;
    border-color: #5999ff; }
    .classic-facelift-skin .flat .context-menu-button:hover > svg > path {
      fill: #fff; }
  .classic-facelift-skin .flat .context-menu-button:active {
    background: #116dff;
    border-color: #116dff; }
    .classic-facelift-skin .flat .context-menu-button:active > svg > path {
      fill: #fff; }

.classic-facelift-skin .flat .is-open .context-menu-button, .classic-facelift-skin .is-open .context-menu-button {
  background: #116dff;
  border-color: #116dff; }
  .classic-facelift-skin .flat .is-open .context-menu-button > svg > path, .classic-facelift-skin .is-open .context-menu-button > svg > path {
    fill: #fff; }

.classic-facelift-skin .disabled .context-menu-button {
  background-color: transparent;
  border-color: #bcbcbc; }
  .classic-facelift-skin .disabled .context-menu-button > svg > path {
    fill: #bcbcbc; }

.classic-facelift-skin .composite-secondary-media-labeled .context-menu-button,
.classic-facelift-skin .composite-secondary-media .context-menu-button {
  background-color: #e7f0ff;
  border-color: #e7f0ff; }
  .classic-facelift-skin .composite-secondary-media-labeled .context-menu-button svg path,
  .classic-facelift-skin .composite-secondary-media .context-menu-button svg path {
    fill: #116dff; }
  .classic-facelift-skin .composite-secondary-media-labeled .context-menu-button:hover,
  .classic-facelift-skin .composite-secondary-media .context-menu-button:hover {
    background-color: #d6e6fe;
    border-color: #d6e6fe; }
    .classic-facelift-skin .composite-secondary-media-labeled .context-menu-button:hover svg path,
    .classic-facelift-skin .composite-secondary-media .context-menu-button:hover svg path {
      fill: #116dff; }

.responsive-skin .control-dropdown-base.control-context-menu.disabled {
  border: none; }
  .responsive-skin .control-dropdown-base.control-context-menu.disabled svg path {
    fill: #bebebe; }

.responsive-skin .control-dropdown-base.control-context-menu:hover {
  background-color: transparent;
  border: none; }

.responsive-skin .context-menu-button {
  border: none; }
  .responsive-skin .context-menu-button:hover {
    background-color: transparent; }
    .responsive-skin .context-menu-button:hover svg path {
      fill: #116dff; }
  .responsive-skin .context-menu-button:active,
  .is-open .responsive-skin .context-menu-button {
    border: none;
    background-color: transparent; }
    .responsive-skin .context-menu-button:active svg path,
    .is-open .responsive-skin .context-menu-button svg path {
      fill: #116dff; }
  .responsive-skin .context-menu-button svg path {
    fill: #131720; }

.responsive-skin .is-open .context-menu-button {
  border: none;
  background-color: transparent; }
  .responsive-skin .is-open .context-menu-button svg path {
    fill: #116dff; }

.responsive-skin .flat .context-menu-button {
  background-color: transparent;
  border: none; }
  .responsive-skin .flat .context-menu-button:hover {
    background-color: transparent; }
    .responsive-skin .flat .context-menu-button:hover svg path {
      fill: #116dff; }
  .responsive-skin .flat .context-menu-button:active {
    background-color: transparent; }
    .responsive-skin .flat .context-menu-button:active svg path {
      fill: #131720; }

.responsive-skin .flat .is-open .context-menu-button {
  background-color: transparent;
  border: none; }
  .responsive-skin .flat .is-open .context-menu-button svg path {
    fill: #131720; }

.responsive-skin .composite-secondary-media-labeled .context-menu-button,
.responsive-skin .composite-secondary-media .context-menu-button {
  background-color: transparent; }
  .responsive-skin .composite-secondary-media-labeled .context-menu-button:hover,
  .responsive-skin .composite-secondary-media .context-menu-button:hover {
    background-color: transparent; }

.context-menu-button {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #116dff; }
  .context-menu-button:hover {
    background-color: #5999ff;
    border-color: #5999ff; }
  .context-menu-button:active,
  .is-open .context-menu-button {
    background-color: #116dff;
    border-color: #116dff; }
  .context-menu-button svg path {
    fill: #116dff; }
  .flat .context-menu-button {
    background: transparent;
    box-sizing: border-box;
    border-color: #116dff; }
    .flat .context-menu-button:hover {
      background-color: #5999ff;
      border-color: #5999ff; }
      .flat .context-menu-button:hover > svg > path {
        fill: #fff; }
  .flat .context-menu-button:active,
  .flat .is-open .context-menu-button {
    background: #116dff;
    border-color: #116dff; }
    .flat .context-menu-button:active svg path,
    .flat .is-open .context-menu-button svg path {
      fill: #fff; }
  .composite-secondary-media-labeled .context-menu-button,
  .composite-secondary-media .context-menu-button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #e7f0ff;
    border-color: #e7f0ff; }
    .composite-secondary-media-labeled .context-menu-button svg path,
    .composite-secondary-media .context-menu-button svg path {
      fill: #116dff; }
    .composite-secondary-media-labeled .context-menu-button:hover,
    .composite-secondary-media .context-menu-button:hover {
      background-color: #d6e6fe;
      border-color: #d6e6fe; }
      .composite-secondary-media-labeled .context-menu-button:hover svg path,
      .composite-secondary-media .context-menu-button:hover svg path {
        fill: #116dff; }

.responsive-skin .context-menu-action {
  padding: 6px 20px;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  color: #131720; }
  .responsive-skin .context-menu-action.hover {
    background-color: #f4f5f8; }
  .responsive-skin .context-menu-action.active {
    background-color: #f4f5f8; }
  .responsive-skin .context-menu-action.disabled {
    color: #bebebe; }
  .responsive-skin .context-menu-action > .symbol {
    margin-left: -4px;
    margin-right: 6px; }
    .responsive-skin .context-menu-action > .symbol path {
      fill: #131720; }

.classic-facelift-skin .context-menu-action {
  color: #3b4057; }
  .classic-facelift-skin .context-menu-action.active {
    background-color: #e7f0ff; }

.context-menu-action {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  min-width: 120px;
  max-width: var(--context-menu-action-max-width, 268px);
  height: 30px;
  padding: 0 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden; }
  .context-menu-action.active {
    background-color: #e7f0ff; }
  .context-menu-action.disabled {
    cursor: default;
    color: #939393; }
  .context-menu-action > .symbol {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
    .context-menu-action > .symbol.disabled path {
      fill: #939393; }

.control-context-menu-folder .context-menu-folder-label {
  width: 100%; }

.control-context-menu-folder .context-menu-action {
  padding-right: 12px; }

.control-context-menu-folder .symbol-folderArrow {
  width: 18px;
  height: 18px;
  margin: 0 0 0 6px; }

.control-context-menu-folder .symbol-folderIcon {
  margin-right: 6px; }

.dropdown-options-container.context-menu-options.context-menu-folder-options .dropdown-options::before {
  display: none; }

.responsive-skin .dropdown-options-container.context-menu-options .dropdown-options:before {
  display: none; }

.responsive-skin .dropdown-options-container.context-menu-options .dropdown-options .dropdown-options-inner {
  padding: 8px 0; }

.classic-facelift-skin .composite-checkbox-with-action .control-context-menu .dropdown-selected.is-open .control-action-with-symbol > .symbol-container > svg > path {
  fill: #116dff; }

.control-context-menu {
  display: inline-flex;
  cursor: pointer; }
  .control-context-menu.context-menu-block {
    display: block; }
  .composite-secondary-media-labeled > .control-context-menu,
  .composite-secondary-media > .control-context-menu {
    display: inline-block;
    vertical-align: middle; }

.composite-checkbox-with-action .control-context-menu .dropdown-selected.is-open .control-action-with-symbol {
  visibility: visible; }
  .composite-checkbox-with-action .control-context-menu .dropdown-selected.is-open .control-action-with-symbol > .symbol-container > svg > path {
    fill: #116dff; }

.control-docking-panel-outer {
  display: flex;
  height: 100px;
  width: 104px; }
  .control-docking-panel-outer .docking-panel-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    height: 100px;
    width: 104px;
    border-radius: 6px;
    border: solid 1px #e2e3ea;
    background-color: #f8faff; }
    .control-docking-panel-outer .docking-panel-inner .docking-rail {
      display: flex;
      position: relative;
      height: 8px;
      width: 100%; }
      .control-docking-panel-outer .docking-panel-inner .docking-rail.left {
        flex-direction: row-reverse; }
    .control-docking-panel-outer .docking-panel-inner .docking-element .docking-point,
    .control-docking-panel-outer .docking-panel-inner .docking-direction .docking-point {
      width: 8px;
      height: 8px;
      background-color: #c3daff;
      border: 1px solid #116dff;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      align-items: center;
      justify-content: center; }
      .control-docking-panel-outer .docking-panel-inner .docking-element .docking-point:hover,
      .control-docking-panel-outer .docking-panel-inner .docking-direction .docking-point:hover {
        background-color: #7cafff; }
    .control-docking-panel-outer .docking-panel-inner .docking-element.direction-docked .docking-point,
    .control-docking-panel-outer .docking-panel-inner .docking-direction.direction-docked .docking-point {
      opacity: 1;
      background-color: #116dff; }
    .control-docking-panel-outer .docking-panel-inner .docking-element.direction-docked .docking-line,
    .control-docking-panel-outer .docking-panel-inner .docking-direction.direction-docked .docking-line {
      visibility: visible; }
    .control-docking-panel-outer .docking-panel-inner .docking-element.direction-disabled .docking-point,
    .control-docking-panel-outer .docking-panel-inner .docking-direction.direction-disabled .docking-point {
      opacity: 1;
      border-color: #bebebe;
      background-color: #f3f3f3;
      cursor: default; }
    .control-docking-panel-outer .docking-panel-inner .docking-direction {
      display: flex; }
      .control-docking-panel-outer .docking-panel-inner .docking-direction .docking-line {
        visibility: hidden;
        position: absolute;
        border: none;
        pointer-events: none; }
      .control-docking-panel-outer .docking-panel-inner .docking-direction.top {
        position: absolute;
        transition: width 0.3s ease, height 0.3s ease;
        justify-content: center;
        height: 18px;
        bottom: 61px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.top .docking-point {
          top: -6px;
          left: -4px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.top.direction-docked {
          transition: width 0.3s ease, height 0.3s ease; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.top .docking-line {
          border-right: 1px dashed #116dff;
          height: 100%; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.top.direction-docked {
          height: 37px; }
      .control-docking-panel-outer .docking-panel-inner .docking-direction.bottom {
        position: absolute;
        transition: width 0.3s ease, height 0.3s ease;
        justify-content: center;
        height: 18px;
        top: 61px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.bottom .docking-point {
          bottom: -6px;
          left: -5px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.bottom.direction-docked {
          transition: width 0.3s ease, height 0.3s ease; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.bottom .docking-line {
          border-right: 1px dashed #116dff;
          height: 100%; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.bottom.direction-docked {
          height: 37px; }
      .control-docking-panel-outer .docking-panel-inner .docking-direction.left {
        position: absolute;
        transition: width 0.3s ease, height 0.3s ease;
        align-items: center;
        width: 18px;
        height: 8px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.left .docking-point {
          left: -6px;
          bottom: 0; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.left.direction-docked {
          transition: width 0.3s ease, height 0.3s ease; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.left .docking-line {
          border-top: 1px dashed #116dff;
          width: 100%; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.left.direction-docked {
          width: 100%; }
      .control-docking-panel-outer .docking-panel-inner .docking-direction.right {
        position: absolute;
        transition: width 0.3s ease, height 0.3s ease;
        align-items: center;
        width: 18px;
        height: 8px; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.right .docking-point {
          right: -6px;
          bottom: 0; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.right.direction-docked {
          transition: width 0.3s ease, height 0.3s ease; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.right .docking-line {
          border-top: 1px dashed #116dff;
          width: 100%; }
        .control-docking-panel-outer .docking-panel-inner .docking-direction.right.direction-docked {
          width: 100%; }
    .control-docking-panel-outer .docking-panel-inner .docking-element {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      height: 24px;
      position: relative;
      z-index: 1; }
  .control-docking-panel-outer.is-disabled .docking-panel-inner {
    background-color: #f3f3f3; }
    .control-docking-panel-outer.is-disabled .docking-panel-inner .docking-element .docking-point,
    .control-docking-panel-outer.is-disabled .docking-panel-inner .docking-direction .docking-point {
      border-color: #bebebe;
      background-color: #f3f3f3;
      cursor: default; }
    .control-docking-panel-outer.is-disabled .docking-panel-inner .docking-element.direction-docked .docking-point,
    .control-docking-panel-outer.is-disabled .docking-panel-inner .docking-direction.direction-docked .docking-point {
      border-color: #bebebe;
      background-color: #bebebe; }
    .control-docking-panel-outer.is-disabled .docking-panel-inner .docking-direction .docking-line {
      border-color: #bebebe; }

.composite-docking-control-with-label .control-docking-panel-outer {
  padding: 1px;
  height: 100px; }
  .composite-docking-control-with-label .control-docking-panel-outer .docking-panel-inner {
    width: 104px;
    height: 100%; }

.control-docking-panel-outer .docking-panel-inner .docking-element .docking-point {
  position: relative; }

.control-docking-plane-button-outer:not(.is-disabled) .control-docking-plane-button:hover {
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px; }
  .control-docking-plane-button-outer:not(.is-disabled) .control-docking-plane-button:hover .docking-point {
    background-color: #7cafff; }

.control-docking-plane-button-outer:not(.is-disabled) .control-docking-plane-button.plane-docked .docking-point,
.control-docking-plane-button-outer:not(.is-disabled) .control-docking-plane-button.plane-docked .docking-point:hover {
  background-color: #116dff; }

.control-docking-plane-button-outer .control-docking-plane-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 5px #e2e3ea;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: top 0.2s ease, bottom 0.2s ease, left 0.2s ease, right 0.2s ease; }
  .control-docking-plane-button-outer .control-docking-plane-button .docking-point {
    position: relative; }
  .control-docking-plane-button-outer .control-docking-plane-button.is-disabled .docking-point {
    opacity: 1;
    border-color: #bebebe;
    background-color: #f3f3f3;
    cursor: default; }
    .control-docking-plane-button-outer .control-docking-plane-button.is-disabled .docking-point:hover {
      background-color: #f3f3f3; }

.control-docking-plane-button-outer .control-docking-plane-popover {
  display: none;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 5px #e2e3ea;
  position: absolute;
  width: 28px;
  height: 28px;
  top: -2px;
  left: -2px;
  overflow: hidden;
  justify-content: center;
  transition: width 0.2s ease, height 0.2s ease, top 0.2s ease, left 0.2s ease; }
  .control-docking-plane-button-outer .control-docking-plane-popover.shown {
    display: flex; }
  .control-docking-plane-button-outer .control-docking-plane-popover.hover {
    overflow: visible;
    width: 72px;
    height: 40px;
    top: -8px;
    left: -24px; }
    .control-docking-plane-button-outer .control-docking-plane-popover.hover .docking-plane {
      opacity: 1; }
  .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane-spacer {
    flex: 1 0 0; }
  .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane {
    opacity: 0;
    transition: opacity 0.2s linear; }
    .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical {
      height: 24px;
      min-height: 24px;
      width: 24px;
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      background-color: #f8faff;
      border: 1px solid #c3daff;
      position: relative; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical .docking-point {
        position: relative; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical:before {
        content: '';
        position: absolute; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical:not(.is-disabled):hover {
        border-color: #7cafff; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical:not(.is-disabled):hover:before {
          border-color: #7cafff; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical:not(.is-disabled):hover .docking-point {
          background-color: #7cafff; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical.is-disabled {
        cursor: default;
        border: 1px solid #bebebe; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical.is-disabled .docking-point {
          opacity: 1;
          border-color: #bebebe;
          background-color: #f3f3f3;
          cursor: inherit; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical.plane-docked .docking-point {
        background-color: #116dff; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical:before {
        top: 0;
        left: 0;
        border-right: 1px solid #c3daff;
        height: 100%;
        width: 50%; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.vertical.is-disabled::before {
        border-right: 1px solid #bebebe; }
    .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal {
      height: 24px;
      min-height: 24px;
      width: 24px;
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      background-color: #f8faff;
      border: 1px solid #c3daff;
      position: relative; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal .docking-point {
        position: relative; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal:before {
        content: '';
        position: absolute; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal:not(.is-disabled):hover {
        border-color: #7cafff; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal:not(.is-disabled):hover:before {
          border-color: #7cafff; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal:not(.is-disabled):hover .docking-point {
          background-color: #7cafff; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal.is-disabled {
        cursor: default;
        border: 1px solid #bebebe; }
        .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal.is-disabled .docking-point {
          opacity: 1;
          border-color: #bebebe;
          background-color: #f3f3f3;
          cursor: inherit; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal.plane-docked .docking-point {
        background-color: #116dff; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal:before {
        top: 0;
        left: 0;
        border-bottom: 1px solid #c3daff;
        height: 50%;
        width: 100%; }
      .control-docking-plane-button-outer .control-docking-plane-popover .docking-plane.horizontal.is-disabled::before {
        border-bottom: 1px solid #bebebe; }

.control-docking-plane-button-outer.is-disabled .control-docking-plane-button {
  cursor: default; }

.responsive-skin .symbol-arrow-down {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none; }
  .responsive-skin .symbol-arrow-down > svg {
    fill: #000624; }

.classic-facelift-skin .symbol-arrow-down {
  border-color: #a8caff; }
  .input-borders-experiment .classic-facelift-skin .symbol-arrow-down {
    border-color: unset; }
  .classic-facelift-skin .symbol-arrow-down > svg {
    fill: #116dff; }

.classic-facelift-skin .disabled.control-dropdown .symbol-arrow-down {
  border-color: #bcbcbc; }
  .classic-facelift-skin .disabled.control-dropdown .symbol-arrow-down > svg {
    fill: #bcbcbc; }

.classic-facelift-skin.input-borders-experiment .disabled.control-dropdown .symbol-arrow-down {
  border-color: unset; }

.classic-facelift-skin .button-styled-dropdown .symbol-arrow-down > svg {
  fill: #000624; }

.classic-facelift-skin .control-dropdown:hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin
.control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin
.control-dropdown.composite-input-with-drop-down :hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin
.composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down, .classic-facelift-skin
.composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down {
  border-color: #116dff; }
  .classic-facelift-skin .control-dropdown:hover:not(.disabled) .symbol-arrow-down svg *, .classic-facelift-skin
  .control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down svg *, .classic-facelift-skin
  .control-dropdown.composite-input-with-drop-down :hover:not(.disabled) .symbol-arrow-down svg *, .classic-facelift-skin
  .composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down svg *, .classic-facelift-skin
  .composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down svg * {
    fill: #116dff; }

.classic-facelift-skin.input-borders-experiment .control-dropdown:hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin.input-borders-experiment
.control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin.input-borders-experiment
.control-dropdown.composite-input-with-drop-down :hover:not(.disabled) .symbol-arrow-down, .classic-facelift-skin.input-borders-experiment
.composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down, .classic-facelift-skin.input-borders-experiment
.composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down {
  border-color: unset; }

.classic-facelift-skin .label-styled-drop-down:not(:hover):not(.disabled) .symbol-arrow-down > svg, .classic-facelift-skin .composite-input-with-drop-down:not(:hover):not(.disabled) .symbol-arrow-down > svg {
  fill: #3b4057; }

.symbol-arrow-down {
  width: 22px;
  height: 22px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #a8caff;
  border-radius: 50%;
  flex: 0 0 auto; }
  .input-borders-experiment .symbol-arrow-down {
    width: 24px;
    height: 24px; }
  .input-borders-experiment .symbol-arrow-down {
    border: unset;
    border-radius: unset; }
  .symbol-arrow-down > svg {
    fill: #116dff; }
  .disabled.control-dropdown .symbol-arrow-down {
    border-color: #d5d5d5; }
    .input-borders-experiment .disabled.control-dropdown .symbol-arrow-down {
      border-color: unset; }
    .disabled.control-dropdown .symbol-arrow-down > svg {
      fill: #bcbcbc; }
  .label-styled-drop-down .symbol-arrow-down,
  .composite-input-with-drop-down .symbol-arrow-down {
    border: none; }
    .label-styled-drop-down .symbol-arrow-down > svg,
    .composite-input-with-drop-down .symbol-arrow-down > svg {
      width: 8px;
      height: 8px; }
      .label-styled-drop-down:not(:hover):not(.disabled) .symbol-arrow-down > svg, .composite-input-with-drop-down:not(:hover):not(.disabled) .symbol-arrow-down > svg {
        fill: #3b4057; }
  .button-styled-dropdown .symbol-arrow-down {
    border: none; }
    .button-styled-dropdown .symbol-arrow-down > svg {
      fill: #000624; }
  .control-dropdown:hover:not(.disabled) .symbol-arrow-down,
  .control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down,
  .control-dropdown.composite-input-with-drop-down :hover:not(.disabled) .symbol-arrow-down,
  .composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down,
  .composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down {
    border-color: #116dff; }
  .input-borders-experiment .control-dropdown:hover:not(.disabled) .symbol-arrow-down, .input-borders-experiment
  .control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down, .input-borders-experiment
  .control-dropdown.composite-input-with-drop-down :hover:not(.disabled) .symbol-arrow-down, .input-borders-experiment
  .composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down, .input-borders-experiment
  .composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down {
    border-color: unset; }

.responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
  color: #f4f5f8; }
  .responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected .symbol-arrow-down path {
    fill: #f4f5f8; }
  .responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected .selected-content.placeholder {
    color: #868aa5; }
  .responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected:hover .control-illustration path {
    fill: #fff; }

.responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown).disabled .selected-container .selected-content {
  color: #42454C; }

.responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown).disabled .selected-container .symbol-arrow-down path {
  fill: #42454C; }

.responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
  color: #f4f5f8; }

.responsive-skin.dark-mode .control-dropdown:not(.text-formatting-dropdown) .control-label {
  color: #f4f5f8; }

.responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
  min-height: 32px;
  height: 32px;
  border: none; }
  .responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
    width: calc(100% - 12px - 8px); }
  .responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.placeholder {
    color: #868aa5; }
  .responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input {
    font-size: 12px;
    line-height: 16px; }
    .responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input.has-arrow-icon {
      width: calc(100% - 12px - 8px); }
  .responsive-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected .selected-content {
    z-index: 1;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .control-dropdown:not(.text-formatting-dropdown).focus-searchable > .dropdown-selected.is-open {
  border: 1px solid #116dff; }

.responsive-skin .control-dropdown:not(.text-formatting-dropdown):not(.remove-borders-from-dropdown-selected) > .dropdown-selected {
  border-radius: 4px;
  border: 1px solid #e2e3ea;
  padding-left: 12px;
  padding-right: 4px; }

.responsive-skin .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected,
.responsive-skin .composite-dropdown-with-button .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected,
.responsive-skin .composite-dropdown-with-color-input-labeled .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
  border: unset;
  border-radius: unset;
  padding-left: unset;
  padding-right: unset; }

.responsive-skin .composite-dropdown .control-dropdown.disabled .dropdown-selected,
.responsive-skin .composite-dropdown-labeled .control-dropdown.disabled .dropdown-selected,
.responsive-skin .composite-drop-down-labeled-horizontal .control-dropdown.disabled .dropdown-selected,
.responsive-skin .composite-drop-down-labeled-vertical .control-dropdown.disabled .dropdown-selected {
  background-color: #f3f3f3;
  border-color: #bebebe;
  color: #bebebe; }
  .responsive-skin .composite-dropdown .control-dropdown.disabled .dropdown-selected svg path,
  .responsive-skin .composite-dropdown-labeled .control-dropdown.disabled .dropdown-selected svg path,
  .responsive-skin .composite-drop-down-labeled-horizontal .control-dropdown.disabled .dropdown-selected svg path,
  .responsive-skin .composite-drop-down-labeled-vertical .control-dropdown.disabled .dropdown-selected svg path {
    fill: #bebebe; }

.responsive-skin .composite-dropdown .control-dropdown:not(.disabled):hover .dropdown-selected,
.responsive-skin .composite-dropdown-labeled .control-dropdown:not(.disabled):hover .dropdown-selected,
.responsive-skin .composite-drop-down-labeled-horizontal .control-dropdown:not(.disabled):hover .dropdown-selected,
.responsive-skin .composite-drop-down-labeled-vertical .control-dropdown:not(.disabled):hover .dropdown-selected {
  color: #131720;
  border-color: #868aa5; }
  .responsive-skin .composite-dropdown .control-dropdown:not(.disabled):hover .dropdown-selected svg path,
  .responsive-skin .composite-dropdown-labeled .control-dropdown:not(.disabled):hover .dropdown-selected svg path,
  .responsive-skin .composite-drop-down-labeled-horizontal .control-dropdown:not(.disabled):hover .dropdown-selected svg path,
  .responsive-skin .composite-drop-down-labeled-vertical .control-dropdown:not(.disabled):hover .dropdown-selected svg path {
    fill: #131720; }

.classic-facelift-skin .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
  color: #000624; }

.classic-facelift-skin .control-dropdown :hover > .dropdown-selected {
  color: #116dff; }

.classic-facelift-skin .control-dropdown.disabled:not(.text-formatting-dropdown) > .dropdown-selected {
  color: #7a7a7a; }

.classic-facelift-skin .control-dropdown.label-styled-drop-down:not(.disabled):not(:hover) > .dropdown-selected {
  color: #3b4057; }

.classic-facelift-skin .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown):not(:hover):not(.disabled) > .dropdown-selected {
  color: #3b4057; }

.classic-facelift-skin .composite-input-with-drop-down .control-dropdown.disabled:not(.text-formatting-dropdown) > .dropdown-selected {
  color: #3b4057; }

.classic-facelift-skin .button-styled-dropdown.control-dropdown:not(.disabled):hover > .dropdown-selected {
  color: #000624;
  background-color: #fff; }

.classic-facelift-skin .selected-input::selection {
  background-color: #d6e6fe; }

.classic-facelift-skin.input-borders-experiment .control-dropdown:not(.text-formatting-dropdown):not(.text-formatting-toggle-button) > .dropdown-selected {
  color: #000624;
  background-color: #fff;
  padding: 0 6px 0 12px; }

.classic-facelift-skin.input-borders-experiment .control-dropdown:not(.disabled):hover > .dropdown-selected {
  color: #3b4057;
  background-color: #e7f0ff; }

.classic-facelift-skin.input-borders-experiment .control-dropdown.disabled > .dropdown-selected {
  color: #7a7a7a; }

.dark-skin.symbol-arrow-down {
  border: none;
  width: 5px;
  right: 0;
  top: -1px;
  margin: 0 6px;
  display: inline-block; }
  .dark-skin.symbol-arrow-down > svg {
    fill: #fff; }
  .orange-colors:not(.light-theme).control-dropdown:hover .dark-skin.symbol-arrow-down > svg {
    fill: #fe620f; }
  .light-theme:not(.orange-colors).control-dropdown .dark-skin.symbol-arrow-down > svg {
    fill: #116dff; }
  .light-theme.control-dropdown:hover .dark-skin.symbol-arrow-down > svg {
    fill: #fff; }

.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  color: #000624;
  height: 36px;
  line-height: 36px;
  min-height: 36px;
  margin: 0; }
  .input-borders-experiment .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    border: 1px solid #a8caff;
    border-radius: 4px; }
  .control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
    color: #116dff; }
  .input-borders-experiment .control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
    color: unset; }
  .disabled.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    color: #7a7a7a; }
    .input-borders-experiment .disabled.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
      border-color: #e0e0e0; }
  .label-styled-drop-down.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 18px;
    line-height: 18px;
    min-height: 18px;
    margin-bottom: 2px; }
    .label-styled-drop-down.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container {
      justify-content: flex-start; }
      .label-styled-drop-down.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content,
      .label-styled-drop-down.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
        width: auto; }
      .label-styled-drop-down.control-dropdown:not(.text-formatting-dropdown):not(:hover):not(.disabled) > .dropdown-selected > .selected-container {
        color: #3b4057; }
  .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    line-height: 18px;
    min-height: 18px;
    margin-bottom: 2px; }
    .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container {
      justify-content: flex-start; }
      .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content,
      .composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
        width: auto; }
  .button-styled-dropdown.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    height: 32px;
    line-height: 32px;
    min-height: 32px;
    padding: 0 8px;
    background-color: #fff;
    border-radius: 4px;
    transition: background-color 200ms ease; }
    .button-styled-dropdown.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
      width: calc(100% - 12px); }
    .button-styled-dropdown.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
      .button-styled-dropdown.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input.has-arrow-icon {
        width: calc(100% - 12px); }
    .button-styled-dropdown.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
      color: #000624;
      background-color: #f4f5f8; }
  .dark-skin.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #3b4057;
    width: 102px;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    height: 20px;
    min-height: 20px;
    padding-left: 6px;
    margin: 3px 4px 2px 0;
    color: #fff;
    border-radius: 2px; }
    .dark-skin.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
      color: #fff;
      background-color: #2b6b9e; }
    .light-theme.dark-skin.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
      color: #116dff;
      background-color: #a8caff; }
      .light-theme.dark-skin.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
        color: #fff;
        background-color: #80b1ff; }
    .orange-colors.dark-skin.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
      color: #fff;
      background-color: #bf5727; }
      .orange-colors.dark-skin.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
        color: #fe620f;
        background-color: #fdd1ba; }
    .orange-colors.light-theme.dark-skin.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected {
      color: #fff;
      background-color: #ff7d38; }
      .orange-colors.light-theme.dark-skin.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected {
        color: #fff;
        background-color: #fe620f; }
  .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center; }
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content > .control-dropdown-option-content,
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content .has-tooltip {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
        width: calc(100% - 18px - 24px); }
        .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon.illustration-only {
          width: 100%; }
        .dark-skin.control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
          width: 79px; }
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.placeholder {
        color: #b6c1cd; }
        .dynamic-field-container .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-content.placeholder {
          color: inherit; }
    .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      border: none;
      padding: 0; }
      .input-borders-experiment .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased; }
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input:focus {
        outline: none; }
      .control-dropdown:not(.text-formatting-dropdown) > .dropdown-selected > .selected-container > .selected-input.has-arrow-icon {
        width: calc(100% - 18px - 24px); }

.composite-input-with-drop-down .control-dropdown:not(.text-formatting-dropdown):not(:hover):not(.disabled) > .dropdown-selected {
  color: #3b4057; }

.selected-text-container {
  max-width: 100%; }

.responsive-skin .dropdown-theme-option .dropdown-theme-option-preview .dropdown-theme-option-color {
  flex: none;
  width: 18px;
  height: 18px;
  border: solid 1px rgba(0, 6, 36, 0.1);
  border-radius: 2px; }

.responsive-skin .dropdown-theme-option .dropdown-theme-option-preview .dropdown-theme-option-html-tag {
  display: none; }

.responsive-skin .dropdown-theme-option .dropdown-theme-option-size {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 15px;
  color: #42454C;
  right: 0;
  top: calc(50% - 7px); }

.responsive-skin .control-dropdown-option.selected .dropdown-theme-option-size {
  color: #42454C; }

.dropdown-theme-option {
  display: inline-block;
  width: 100%;
  position: relative; }
  .dropdown-theme-option .dropdown-theme-option-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dropdown-theme-option .dropdown-theme-option-preview {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .dropdown-theme-option .dropdown-theme-option-preview .dropdown-theme-option-color {
      flex: 0 0 22px;
      height: 22px;
      border: 1px solid #dfe5eb;
      border-radius: 50%; }
    .dropdown-theme-option .dropdown-theme-option-preview .dropdown-theme-option-html-tag {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      line-height: 12px;
      color: #868aa5;
      margin-left: auto; }
  .dropdown-theme-option .dropdown-theme-option-size {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    line-height: 12px;
    color: #868aa5;
    position: absolute;
    bottom: 6px; }

.control-dropdown-option.selected .dropdown-theme-option-size,
.control-dropdown-option.selected .dropdown-theme-option-html-tag {
  color: #FFF; }

.responsive-skin .control-dropdown-option .control-illustration {
  margin-right: 12px; }

.responsive-skin .control-dropdown-option.selected .control-illustration svg path {
  fill: #116dff; }

.control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected svg path {
  fill: #116dff; }

.classic-facelift-skin .control-dropdown:not(.text-formatting-dropdown):hover:not(.disabled) > .dropdown-selected svg path {
  fill: #116dff; }

.classic-facelift-skin.input-borders-experiment .control-dropdown:hover:not(.disabled) > .dropdown-selected svg path {
  fill: #3b4057; }

.classic-facelift-skin.input-borders-experiment .control-dropdown:hover:not(.disabled) > .dropdown-selected .symbol-arrow-down svg path {
  fill: #116dff; }

.dropdown-illustration-option {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 36px; }
  .dropdown-illustration-option .control-illustration {
    margin-right: 6px;
    display: flex; }
  .dropdown-illustration-option .dropdown-illustration-option-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dropdown-illustration-option .dropdown-illustration-option-preview {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .dropdown-illustration-option .dropdown-illustration-only.dropdown-illustration-only {
    justify-content: center; }
    .dropdown-illustration-option .dropdown-illustration-only.dropdown-illustration-only .control-illustration {
      margin-right: 0; }

.control-dropdown-option.selected svg path {
  fill: #fff; }

.dropdown-list-item-option {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 36px; }
  .larger-item-list-dropdown-options .dropdown-list-item-option {
    height: 52px; }
  .dropdown-list-item-option .dropdown-list-item-option-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dropdown-list-item-option .dropdown-list-item-option-preview {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-content {
      min-width: 0;
      max-width: 100%; }
    .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-prefix {
      margin-right: 8px;
      display: flex; }
    .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-checkbox {
      margin-right: 12px; }
    .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-detailed-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 100%; }
      .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-detailed-container .dropdown-list-item-option-title {
        max-width: 100%;
        line-height: 24px;
        height: 16px;
        padding-bottom: 8px; }
      .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-detailed-container .dropdown-list-item-option-subtitle {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        max-width: 100%;
        line-height: 16px;
        color: #868aa5; }
        .control-dropdown-option.selected .dropdown-list-item-option .dropdown-list-item-option-preview .dropdown-list-item-option-detailed-container .dropdown-list-item-option-subtitle {
          color: #fff; }
  .control-dropdown-option.selected .dropdown-list-item-option svg path {
    fill: #fff; }
  .control-dropdown-option.selected .dropdown-list-item-option .rounded-illustration-icon-content svg path {
    fill: #3b4057; }
  .control-dropdown-option.disabled .dropdown-list-item-option svg path {
    fill: #bcbcbc; }
  .dropdown-list-item-option .control-checkbox .input:not(:checked) ~ .symbol-checkbox .bg {
    fill: transparent; }

.rounded-illustration-container {
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.rounded-illustration-icon-content {
  width: 40px;
  height: 40px;
  border: 1px solid #dfe5eb;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
  color: initial;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center; }
  .rounded-illustration-icon-content > svg {
    fill: #3b4057; }

.responsive-skin .dropdown-detailed-option-label .control-label-base {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased; }

.responsive-skin .control-dropdown-base:not(.disabled) .dropdown-detailed-option-label .control-label-base {
  color: #000624; }

.responsive-skin .control-dropdown-option .dropdown-detailed-option-icon .rounded-illustration-icon-content {
  border-color: #e2e3ea; }

.responsive-skin .control-dropdown-option .dropdown-detailed-option-icon svg path {
  fill: #131720; }

.responsive-skin .control-dropdown-option .dropdown-detailed-option-content .dropdown-detailed-option-title {
  padding-bottom: 0; }
  .responsive-skin .control-dropdown-option .dropdown-detailed-option-content .dropdown-detailed-option-title .control-label-base {
    color: #131720;
    font-size: 12px;
    line-height: 16px; }

.responsive-skin .control-dropdown-option .dropdown-detailed-option-content .dropdown-detailed-option-description {
  height: 16px; }
  .responsive-skin .control-dropdown-option .dropdown-detailed-option-content .dropdown-detailed-option-description .control-label-base {
    color: #2B2E36;
    font-size: 11px;
    line-height: 16px; }

.responsive-skin .control-dropdown-option.selected .dropdown-detailed-option-title .control-label-base,
.responsive-skin .control-dropdown-option.selected .dropdown-detailed-option-description .control-label-base {
  color: #116dff; }

.responsive-skin .control-dropdown-option.selected .dropdown-detailed-option-icon .rounded-illustration-icon-content {
  border-color: #c3daff; }

.responsive-skin .control-dropdown-option.selected .dropdown-detailed-option-icon svg path {
  fill: #116dff; }

.responsive-skin .control-dropdown-option.disabled .dropdown-detailed-option-title .control-label-base,
.responsive-skin .control-dropdown-option.disabled .dropdown-detailed-option-description .control-label-base {
  color: #bebebe; }

.responsive-skin .control-dropdown-option .dropdown-detailed-option-icon {
  width: 32px;
  margin-right: 5px; }
  .responsive-skin .control-dropdown-option .dropdown-detailed-option-icon .rounded-illustration-container {
    width: 32px; }
    .responsive-skin .control-dropdown-option .dropdown-detailed-option-icon .rounded-illustration-container .rounded-illustration-icon-content {
      width: 32px;
      height: 32px; }

.responsive-skin .dropdown-options-container.detailed-dropdown-options .control-dropdown-option {
  height: 54px;
  line-height: 54px;
  border: none; }

.dropdown-detailed-option {
  width: 100%;
  display: flex;
  align-items: center; }
  .dropdown-detailed-option .dropdown-detailed-option-icon {
    float: left;
    width: 40px;
    margin-right: 8px; }
    .dropdown-detailed-option .dropdown-detailed-option-icon ~ .dropdown-detailed-option-content {
      max-width: calc(100% - 48px); }
  .dropdown-detailed-option .dropdown-detailed-option-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .dropdown-detailed-option .dropdown-detailed-option-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%; }
    .dropdown-detailed-option .dropdown-detailed-option-content .dropdown-detailed-option-title {
      max-width: 100%;
      line-height: 16px;
      height: 16px;
      padding-bottom: 8px; }
    .dropdown-detailed-option .dropdown-detailed-option-content .dropdown-detailed-option-description {
      max-width: 100%;
      line-height: normal; }
  .input-borders-experiment .dropdown-detailed-option .dropdown-detailed-option-label .control-label-base {
    font-size: 16px; }

.control-dropdown-option.selected .dropdown-detailed-option svg path {
  fill: #3b4057; }

.responsive-skin .control-dropdown-section.subheader-section {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  padding: 9px 12px;
  background-color: #f4f5f8;
  border-bottom: 1px solid #e2e3ea;
  border-top: 1px solid #e2e3ea; }

.responsive-skin .control-dropdown-section.title-section {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 11px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  color: #868aa5;
  padding: 13px 12px 6px; }

.responsive-skin .control-dropdown-section.divider-section {
  padding: 4px 0; }
  .responsive-skin .control-dropdown-section.divider-section hr.divider-horizontal {
    background-color: #e2e3ea; }

.control-dropdown-section.subheader-section {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  padding: 9px 24px;
  background-color: #f7f8f8;
  border-bottom: 1px solid #dfe5eb;
  border-top: 1px solid #dfe5eb; }

.control-dropdown-section.title-section {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-bold);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 18px;
  color: #868aa5;
  padding: 15px 24px 9px; }

.control-dropdown-section.divider-section {
  padding: 6px 0; }
  .control-dropdown-section.divider-section hr.divider-horizontal {
    background-color: #d5d5d5; }

.classic-facelift-skin .control-google-preview > .preview-content {
  background-color: #fff;
  border-color: #dfe5eb; }

.control-google-preview {
  padding: 18px; }
  .composite-google-preview-with-rich-text .control-google-preview {
    padding-top: 6px; }
  .control-google-preview > .preview-content {
    padding: 12px;
    border: 1px solid #d9e1e8;
    background-color: #fff; }
    .control-google-preview > .preview-content > .title,
    .control-google-preview > .preview-content > .url,
    .control-google-preview > .preview-content > .description {
      margin: 0;
      line-height: 18px;
      word-wrap: break-word; }
    .control-google-preview > .preview-content > .title .control-label {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 700;
      color: #1a0dab;
      font-weight: 700; }
    .control-google-preview > .preview-content > .url .control-label {
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #006621;
      -webkit-user-select: text;
              user-select: text; }
    .control-google-preview > .preview-content > .description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      font-weight: 400;
      width: calc(100% - (42px - 24px));
      max-height: 36px;
      overflow: hidden;
      display: block;
      color: #545454;
      height: 36px; }

.classic-facelift-skin .control-action-with-symbol {
  color: #000624; }
  .classic-facelift-skin .control-action-with-symbol:hover {
    color: #116dff; }
  .classic-facelift-skin .control-action-with-symbol.link-style-action {
    color: #116dff; }
    .classic-facelift-skin .control-action-with-symbol.link-style-action:hover {
      color: #5999ff; }
  .classic-facelift-skin .control-action-with-symbol.not-initialized {
    color: #acafc4; }
    .classic-facelift-skin .control-action-with-symbol.not-initialized:hover {
      color: #5999ff; }
  .classic-facelift-skin .control-action-with-symbol > .symbol-container {
    border-radius: 50%;
    background-color: #116dff; }
    .classic-facelift-skin .control-action-with-symbol > .symbol-container > svg > path {
      fill: #fff; }

.classic-facelift-skin .composite-checkbox-with-action .control-action-with-symbol:hover > .symbol-container > svg > path,
.composite-action-with-symbol:hover .classic-facelift-skin .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
  fill: #116dff; }

.classic-facelift-skin .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
  fill: #3b4057; }

.classic-facelift-skin .control-action-with-symbol:hover > .symbol-container {
  background-color: #5999ff; }

.classic-facelift-skin .inactive.control-action-with-symbol > .symbol-container {
  background-color: #e7f0ff; }

.classic-facelift-skin .inactive.control-action-with-symbol:hover:not(.disabled) > .symbol-container {
  background-color: #d6e6fe; }

.classic-facelift-skin .inactive.control-action-with-symbol:not(.disabled) > .symbol-container > svg > path {
  fill: #116dff; }

.classic-facelift-skin .composite-date-range:hover .control-action-with-symbol, .classic-facelift-skin
.composite-date-picker-labeled:hover .control-action-with-symbol, .classic-facelift-skin
.composite-action-with-symbol-labeled:hover .control-action-with-symbol, .classic-facelift-skin
.composite-action-with-symbol:hover .control-action-with-symbol {
  color: #116dff; }

.responsive-skin .control-action-with-symbol {
  color: #131720;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  line-height: 16px; }
  .responsive-skin .control-action-with-symbol:hover {
    color: #116dff; }
  .responsive-skin .control-action-with-symbol.link-style-action {
    color: #116dff; }
    .responsive-skin .control-action-with-symbol.link-style-action:hover {
      color: #418aff; }
  .responsive-skin .control-action-with-symbol.not-initialized {
    color: #868aa5; }
    .responsive-skin .control-action-with-symbol.not-initialized:hover {
      color: #418aff; }
  .responsive-skin .control-action-with-symbol > .content-container .content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    line-height: 16px; }
  .responsive-skin .control-action-with-symbol > .symbol-container {
    border-radius: 4px;
    background-color: #116dff; }
    .responsive-skin .control-action-with-symbol > .symbol-container > svg > path {
      fill: #f4f5f8; }

.responsive-skin .composite-checkbox-with-action .control-action-with-symbol:hover > .symbol-container > svg > path,
.composite-action-with-symbol:hover .responsive-skin .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
  fill: #116dff; }

.responsive-skin .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
  fill: #2B2E36; }

.responsive-skin .control-action-with-symbol:hover > .symbol-container {
  background-color: #0f62e6; }

.responsive-skin .inactive.control-action-with-symbol > .symbol-container {
  background-color: #dce9ff; }

.responsive-skin .inactive.control-action-with-symbol:hover:not(.disabled) > .symbol-container {
  background-color: #c3daff; }

.responsive-skin .inactive.control-action-with-symbol:not(.disabled) > .symbol-container > svg > path {
  fill: #116dff; }

.responsive-skin .composite-date-range:hover .control-action-with-symbol, .responsive-skin
.composite-date-picker-labeled:hover .control-action-with-symbol, .responsive-skin
.composite-action-with-symbol-labeled:hover .control-action-with-symbol, .responsive-skin
.composite-action-with-symbol:hover .control-action-with-symbol {
  color: #116dff; }

.control-action-with-symbol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #000624; }
  .composite-link-button-with-color-input-labeled .control-action-with-symbol {
    flex: 1;
    order: -1;
    width: calc(100% - 24px - 30px - 9px - 8px); }
  .control-action-with-symbol:hover,
  .composite-date-range:hover .control-action-with-symbol,
  .composite-date-picker-labeled:hover .control-action-with-symbol,
  .composite-action-with-symbol-labeled:hover .control-action-with-symbol,
  .composite-action-with-symbol:hover .control-action-with-symbol {
    color: #116dff; }
  .control-action-with-symbol.link-style-action {
    color: #116dff; }
    .control-action-with-symbol.link-style-action:hover {
      color: #5999ff;
      text-decoration: underline; }
    .control-action-with-symbol.link-style-action.disabled {
      color: #bcbcbc;
      text-decoration: none; }
  .control-action-with-symbol.not-initialized {
    color: #b6c1cd; }
    .control-action-with-symbol.not-initialized:hover {
      color: #5999ff; }
  .control-action-with-symbol.disabled {
    cursor: default;
    color: #7a7a7a; }
  .control-action-with-symbol.has-icon .content-container {
    width: calc(100% - 24px - 12px); }
  .control-action-with-symbol > .content-container {
    display: inline-flex;
    align-items: center; }
    .composite-link-button-with-color-input-labeled .control-action-with-symbol > .content-container {
      width: calc(100% - 24px - 5px); }
    .control-action-with-symbol > .content-container .content {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      line-height: 24px;
      width: 100%;
      display: flex; }
      .link-style-action.control-action-with-symbol > .content-container .content {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased; }
  .control-action-with-symbol > .symbol-container {
    border-radius: 50%;
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background-color: #116dff; }
    .composite-link-button-with-color-input-labeled .control-action-with-symbol > .symbol-container {
      width: 25px;
      height: 25px; }
    .control-action-with-symbol:hover > .symbol-container {
      background-color: #5999ff; }
    .inactive.control-action-with-symbol > .symbol-container {
      background-color: #e7f0ff; }
      .disabled.inactive.control-action-with-symbol > .symbol-container {
        background-color: #f0f0f0; }
      .inactive.control-action-with-symbol:hover:not(.disabled) > .symbol-container {
        background-color: #d6e6fe; }
    .disabled.control-action-with-symbol > .symbol-container {
      cursor: default;
      background-color: #bcbcbc; }
    .control-action-with-symbol > .symbol-container > svg > path {
      fill: #fff; }
      .inactive.control-action-with-symbol:not(.disabled) > .symbol-container > svg > path {
        fill: #116dff; }
      .disabled.inactive.control-action-with-symbol > .symbol-container > svg > path {
        fill: #bcbcbc; }

.composite-checkbox-with-action .control-action-with-symbol {
  display: flex;
  visibility: hidden; }
  .composite-checkbox-with-action .control-action-with-symbol:hover > .symbol-container > svg > path,
  .composite-action-with-symbol:hover .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
    fill: #116dff; }
  .composite-checkbox-with-action .control-action-with-symbol > .symbol-container {
    width: 24px;
    height: 24px;
    background-color: transparent; }
    .composite-checkbox-with-action:hover .control-action-with-symbol > .symbol-container {
      background-color: transparent; }
    .inactive.composite-checkbox-with-action .control-action-with-symbol > .symbol-container {
      background-color: transparent; }
      .inactive.composite-checkbox-with-action:hover:not(.disabled) .control-action-with-symbol > .symbol-container {
        background-color: transparent; }
    .disabled.composite-checkbox-with-action .control-action-with-symbol > .symbol-container {
      cursor: default;
      background-color: #bcbcbc; }
    .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg {
      width: 16px;
      height: 16px; }
      .composite-checkbox-with-action .control-action-with-symbol > .symbol-container > svg > path {
        fill: #3b4057; }

.composite-checkbox-with-action:hover .control-action-with-symbol {
  visibility: visible; }

.classic-facelift-skin .control-rich-text {
  color: #3b4057; }
  .classic-facelift-skin .control-rich-text.light {
    color: #868aa5; }
  .classic-facelift-skin .control-rich-text b,
  .classic-facelift-skin .control-rich-text strong {
    color: #3b4057; }
  .classic-facelift-skin .control-rich-text a {
    color: #116dff; }
    .composite-banner-premium .classic-facelift-skin .control-rich-text a {
      color: #9a27d5; }

.responsive-skin .control-rich-text a {
  color: #116dff; }
  .responsive-skin .control-rich-text a.disabled {
    color: #767676; }

.control-rich-text {
  color: #3b4057;
  text-align: left;
  width: 100%;
  margin: 0; }
  .composite-rich-text.composite-centered .control-rich-text,
  .composite-rich-text-labeled.composite-centered .control-rich-text,
  .composite-rich-text-with-illustration-vertical .control-rich-text,
  .composite-banner-premium .control-rich-text {
    text-align: center; }
  .composite-google-preview-with-rich-text > .control-rich-text {
    padding-left: 24px; }
  .composite-action-set-horizontal .control-rich-text {
    flex: 1; }
  .control-rich-text p {
    margin: 0; }
    .control-rich-text p:not(:last-of-type) {
      margin-bottom: 1em; }
  .control-rich-text.light {
    color: #868aa5; }
  .control-rich-text ul,
  .control-rich-text ol {
    margin: 0;
    padding-left: 16px; }
  .control-rich-text li:not(:last-child) {
    margin-bottom: 4px; }
  .control-rich-text b,
  .control-rich-text strong {
    color: #3b4057; }
  .control-rich-text a {
    color: #116dff;
    text-decoration: none; }
    .composite-banner-premium .control-rich-text a {
      color: #9a27d5; }
    .control-rich-text a:hover {
      cursor: pointer;
      text-decoration: underline; }
    .control-rich-text a.disabled {
      color: #bcbcbc;
      cursor: default;
      text-decoration: none; }

.thumb-none-symbol {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 2px; }
  .selected .thumb-none-symbol {
    width: calc(100% - 4px);
    height: calc(100% - 4px); }
  .thumb-none-symbol > .line {
    stroke: #e62214;
    stroke-width: 2; }

.responsive-skin input:checked + .control-thumbnail svg .illus-clr-1,
.responsive-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-1"] {
  fill: #7cafff !important; }

.responsive-skin input:checked + .control-thumbnail svg .illus-clr-2,
.responsive-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-2"] {
  fill: #116dff !important; }

.responsive-skin input:checked + .control-thumbnail svg .illus-clr-3,
.responsive-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-3"] {
  fill: #dce9ff !important; }

.responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) {
  background-color: #fff; }
  .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) .control-thumbnail > .illustration-container.illustration-background {
    background-color: transparent;
    border-radius: 4px; }
  .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container {
    background-color: #dce9ff; }
    .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .illustration-inner-container {
      box-shadow: inset 0 0 0 0 transparent; }
    .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .illustration-inner-container.thumbnail-skin-standard svg * {
      fill: #116dff; }
  .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) .control-thumbnail:hover > .illustration-container > .illustration-inner-container {
    box-shadow: inset 0 0 0 0 transparent; }
  .responsive-skin .composite-thumbnails .control-thumbnails-list:not(.with-border) .control-thumbnail:hover > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
    fill: #131720; }

.responsive-skin .outer-border.with-label .control-thumbnail {
  padding-bottom: 12px; }

.responsive-skin .outer-border .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) {
  box-shadow: inset 0 0 0 1px #868aa5; }

.responsive-skin .outer-border .control-thumbnail,
.responsive-skin .outer-border .control-thumbnail:hover {
  border-radius: 4px; }
  .responsive-skin .outer-border .control-thumbnail .control-label,
  .responsive-skin .outer-border .control-thumbnail:hover .control-label {
    margin-top: 8px; }
    .responsive-skin .outer-border .control-thumbnail .control-label span,
    .responsive-skin .outer-border .control-thumbnail:hover .control-label span {
      color: #42454C;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased; }
  .responsive-skin .outer-border .control-thumbnail .illustration-container,
  .responsive-skin .outer-border .control-thumbnail:hover .illustration-container {
    border-bottom: 1px solid #e5e5e5; }
    .responsive-skin .outer-border .control-thumbnail .illustration-container .illustration-inner-container,
    .responsive-skin .outer-border .control-thumbnail:hover .illustration-container .illustration-inner-container {
      box-shadow: none;
      background-color: transparent; }

.responsive-skin .outer-border .control-thumbnail:hover .control-label span {
  color: #2B2E36; }

.responsive-skin .outer-border :not(.select-hover-only-border) .control-thumbnail {
  box-shadow: inset 0 0 0 1px #e2e3ea; }

.responsive-skin .outer-border .selected .control-thumbnail {
  box-shadow: inset 0 0 0 1px #116dff; }
  .responsive-skin .outer-border .selected .control-thumbnail .control-label span {
    color: #116dff; }

.responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list {
  border-radius: 4px; }
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail,
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list :not(.select-hover-only-border) .control-thumbnail,
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) {
    box-shadow: none; }
    .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail .illustration-container,
    .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list :not(.select-hover-only-border) .control-thumbnail .illustration-container,
    .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) .illustration-container {
      border-bottom: none; }
      .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail .illustration-container .illustration-inner-container,
      .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list :not(.select-hover-only-border) .control-thumbnail .illustration-container .illustration-inner-container,
      .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) .illustration-container .illustration-inner-container {
        border-radius: 4px;
        align-items: center;
        flex-grow: 1; }
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list.with-label .control-thumbnail {
    padding: 0;
    align-items: inherit; }
    .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list.with-label .control-thumbnail .illustration-container {
      margin: 0; }
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail .control-label span {
    color: #116dff; }
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail > .illustration-container > .illustration-inner-container {
    box-shadow: none;
    background-color: #dce9ff; }
  .responsive-skin > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container .control-thumbnails-list .selected .control-thumbnail > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
    fill: #116dff; }

.responsive-skin .selected .control-thumbnail .control-label span {
  color: #116dff; }

.responsive-skin .control-thumbnail .control-label {
  margin-top: 4px; }
  .responsive-skin .control-thumbnail .control-label span {
    color: #131720;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 11px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .control-thumbnail > .illustration-container > .illustration-inner-container {
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #e2e3ea; }
  .inner-border .responsive-skin .control-thumbnail > .illustration-container > .illustration-inner-container {
    border-radius: 4px; }

.responsive-skin .control-thumbnail > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
  fill: #131720; }

.responsive-skin .control-thumbnail > .illustration-container::after {
  display: none; }

.responsive-skin .control-thumbnail > .illustration-container.illustration-image > .illustration-inner-container {
  box-shadow: none; }

.responsive-skin .control-thumbnail:hover .control-label span {
  color: #2B2E36; }

.responsive-skin .control-thumbnail:hover > .illustration-container > .illustration-inner-container {
  background-color: #f4f5f8;
  box-shadow: inset 0 0 0 1px #f4f5f8; }

.responsive-skin .control-thumbnail:hover > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
  fill: #2B2E36; }

.responsive-skin .control-thumbnail:hover > .illustration-container.illustration-image > .illustration-inner-container {
  background: none;
  box-shadow: none; }
  .responsive-skin .control-thumbnail:hover > .illustration-container.illustration-image > .illustration-inner-container::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: rgba(19, 23, 32, 0.1); }

.responsive-skin .inner-border .selected .control-thumbnail .control-label span {
  color: #116dff; }

.responsive-skin .inner-border .selected .control-thumbnail > .illustration-container > .illustration-inner-container {
  box-shadow: none;
  background-color: #dce9ff; }

.responsive-skin .inner-border .selected .control-thumbnail > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
  fill: #116dff; }

.responsive-skin .inner-border .selected .control-thumbnail > .illustration-container.illustration-image > .illustration-inner-container {
  background: none;
  border: 1px solid #116dff; }
  .responsive-skin .inner-border .selected .control-thumbnail > .illustration-container.illustration-image > .illustration-inner-container img {
    padding: 4px;
    box-sizing: border-box;
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%; }
  .responsive-skin .inner-border .selected .control-thumbnail > .illustration-container.illustration-image > .illustration-inner-container::after {
    display: none; }

.responsive-skin .inner-border .control-thumbnail:not(.fixed-ratio) > .illustration-container > .illustration-inner-container {
  height: 44px; }

.responsive-skin .composite-thumbnails-labeled-horizontal .control-thumbnails-list .control-thumbnail:not(.fixed-ratio) > .illustration-container > .illustration-inner-container {
  width: 28px;
  height: 28px; }

.responsive-skin .composite-thumbnails-labeled-horizontal .control-thumbnails-list .control-thumbnail:hover > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
  color: #f4f5f8; }

.responsive-skin .composite-thumbnails-labeled-horizontal .control-thumbnails-list > .illustration-container > .illustration-inner-container {
  background-color: #f4f5f8; }

.responsive-skin .illustration-inner-container svg .illus-clr-1,
.responsive-skin .illustration-inner-container svg *[data-ds-class="illus-clr-1"],
.responsive-skin .illustration-inner-container svg:hover .illus-clr-1,
.responsive-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-1"] {
  fill: #868aa5 !important; }

.responsive-skin .illustration-inner-container svg .illus-clr-2,
.responsive-skin .illustration-inner-container svg *[data-ds-class="illus-clr-2"],
.responsive-skin .illustration-inner-container svg:hover .illus-clr-2,
.responsive-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-2"] {
  fill: #131720 !important; }

.responsive-skin .illustration-inner-container svg .illus-clr-3,
.responsive-skin .illustration-inner-container svg *[data-ds-class="illus-clr-3"],
.responsive-skin .illustration-inner-container svg:hover .illus-clr-3,
.responsive-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-3"] {
  fill: #f4f5f8 !important; }

.composite-panel-footer-button-with-icon .control-thumbnail > .illustration-container > .illustration-inner-container.thumbnail-skin-standard svg * {
  fill: #116dff; }

.composite-panel-footer-button-with-icon .control-thumbnail:hover > .illustration-container > .illustration-inner-container {
  background-color: transparent;
  box-shadow: none; }

.composite-panel-footer-button-with-icon .control-thumbnail > .illustration-container > .illustration-inner-container {
  border-radius: 0;
  box-shadow: none; }

.classic-facelift-skin .with-border:not(.select-hover-only-border) .control-thumbnail.disabled {
  box-shadow: inset 0 0 0 1px #000624; }

.classic-facelift-skin .outer-border .control-thumbnail {
  border-radius: 4px; }

.classic-facelift-skin .outer-border:not(.select-hover-only-border) .control-thumbnail {
  box-shadow: inset 0 0 0 1px #dfe5eb; }

.classic-facelift-skin .outer-border .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) {
  box-shadow: inset 0 0 0 2px #d6e6fe; }

.classic-facelift-skin .outer-border .selected .control-thumbnail {
  box-shadow: inset 0 0 0 2px #116dff; }

.classic-facelift-skin .composite-thumbnails > .control-thumbnails-list:not(.with-border) .control-thumbnail > .illustration-container.illustration-background {
  border-radius: 4px;
  background-color: #e7f0ff; }

.classic-facelift-skin .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container {
  background-color: #116dff; }

.classic-facelift-skin .outer-border.with-label .control-thumbnail > .illustration-container {
  border-color: #dfe5eb; }

.classic-facelift-skin .inner-border .control-thumbnail > .illustration-container {
  border-radius: 4px; }
  .classic-facelift-skin .inner-border .control-thumbnail > .illustration-container:after {
    border-radius: 4px; }

.classic-facelift-skin .inner-border:not(.select-hover-only-border) .control-thumbnail > .illustration-container:after {
  box-shadow: inset 0 0 0 1px #dfe5eb; }

.classic-facelift-skin .inner-border .selected .control-thumbnail > .illustration-container:after {
  box-shadow: 0 0 0 2px #116dff;
  border-color: #fff; }

.classic-facelift-skin .inner-border .none-thumbnail.selected .control-thumbnail > .illustration-container:after,
.classic-facelift-skin .control-thumbnails-list:not(.with-border) > .none-thumbnail.selected .control-thumbnail > .illustration-container {
  box-shadow: inset 0 0 0 2px #116dff;
  border-radius: 4px; }

.classic-facelift-skin .inner-border .control-thumbnail > .illustration-container > .illustration-inner-container {
  border-radius: 4px; }

.classic-facelift-skin .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container.illustration-background {
  background-color: #d6e6fe; }

.classic-facelift-skin .inner-border .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container.illustration-background:after {
  box-shadow: inset 0 0 0 2px #d6e6fe; }

.classic-facelift-skin .composite-thumbnails .control-thumbnails-list:not(.outer-border) .control-thumbnail .label,
.classic-facelift-skin .outer-border .selected .control-thumbnail .label {
  color: #116dff; }

.classic-facelift-skin .illustration-inner-container svg .illus-clr-1,
.classic-facelift-skin .illustration-inner-container svg *[data-ds-class="illus-clr-1"],
.classic-facelift-skin .illustration-inner-container svg:hover .illus-clr-1,
.classic-facelift-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-1"] {
  fill: #80b1ff; }

.classic-facelift-skin .illustration-inner-container svg .illus-clr-2,
.classic-facelift-skin .illustration-inner-container svg *[data-ds-class="illus-clr-2"],
.classic-facelift-skin .illustration-inner-container svg:hover .illus-clr-2,
.classic-facelift-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-2"] {
  fill: #116dff; }

.classic-facelift-skin .illustration-inner-container svg .illus-clr-3,
.classic-facelift-skin .illustration-inner-container svg *[data-ds-class="illus-clr-3"],
.classic-facelift-skin .illustration-inner-container svg:hover .illus-clr-3,
.classic-facelift-skin .illustration-inner-container svg:hover *[data-ds-class="illus-clr-3"] {
  fill: #e7f0ff; }

.classic-facelift-skin input:checked + .control-thumbnail svg .illus-clr-1,
.classic-facelift-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-1"] {
  fill: #a8caff; }

.classic-facelift-skin input:checked + .control-thumbnail svg .illus-clr-2,
.classic-facelift-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-2"] {
  fill: #e7f0ff; }

.classic-facelift-skin input:checked + .control-thumbnail svg .illus-clr-3,
.classic-facelift-skin input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-3"] {
  fill: #116dff; }

input:checked + .control-thumbnail svg .illus-clr-1,
input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-1"] {
  fill: #80b1ff; }

input:checked + .control-thumbnail svg .illus-clr-2,
input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-2"] {
  fill: #e7f0ff; }

input:checked + .control-thumbnail svg .illus-clr-3,
input:checked + .control-thumbnail svg *[data-ds-class="illus-clr-3"] {
  fill: #116dff; }

.control-thumbnail {
  display: flex;
  flex-direction: column; }
  .control-thumbnail.disabled {
    cursor: default; }
  .with-border:not(.select-hover-only-border) .control-thumbnail.disabled {
    box-shadow: inset 0 0 0 1px #dfe5eb; }
  .outer-border .control-thumbnail {
    padding: 20px 0;
    border-radius: 4px;
    align-items: center;
    flex-grow: 1; }
  .outer-border:not(.select-hover-only-border) .control-thumbnail {
    box-shadow: inset 0 0 0 1px #dfe5eb; }
  .outer-border.with-label .control-thumbnail {
    padding: 20px 6px; }
  .outer-border .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) {
    box-shadow: inset 0 0 0 2px #d6e6fe; }
  .outer-border .selected .control-thumbnail {
    box-shadow: inset 0 0 0 2px #116dff; }
  .fixed-ratio.control-thumbnail > .illustration-container {
    line-height: 0;
    position: relative;
    display: block; }
    .fixed-ratio.control-thumbnail > .illustration-container::before {
      display: inline-block;
      content: '';
      padding: 50%;
      vertical-align: -webkit-baseline-middle; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) .control-thumbnail > .illustration-container.illustration-background {
    border-radius: 4px;
    background-color: #e7f0ff; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container {
    background-color: #116dff; }
  .outer-border.with-label .control-thumbnail > .illustration-container {
    border-bottom: 1px solid #dfe5eb;
    margin: 0 auto; }
  .inner-border .control-thumbnail > .illustration-container {
    border-radius: 4px;
    position: relative; }
    .inner-border .control-thumbnail > .illustration-container:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      box-sizing: border-box; }
  .inner-border:not(.select-hover-only-border) .control-thumbnail > .illustration-container:after {
    box-shadow: inset 0 0 0 1px #dfe5eb; }
  .inner-border .selected .control-thumbnail > .illustration-container:after {
    border-radius: 2px;
    box-shadow: 0 0 0 2px #116dff;
    border: 1px solid #fff;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px; }
  .inner-border .none-thumbnail.selected .control-thumbnail > .illustration-container:after,
  .control-thumbnails-list:not(.with-border) > .none-thumbnail.selected .control-thumbnail > .illustration-container {
    box-shadow: inset 0 0 0 2px #116dff; }
  .control-thumbnail > .illustration-container > .illustration-inner-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .fixed-ratio.control-thumbnail > .illustration-container > .illustration-inner-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .control-thumbnail > .illustration-container > .illustration-inner-container > .none-thumbnail-symbol-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%; }
    .inner-border .control-thumbnail > .illustration-container > .illustration-inner-container {
      border-radius: 4px;
      overflow: hidden; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container.illustration-background {
    background-color: #d6e6fe; }
  .inner-border .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container.illustration-background:after {
    box-shadow: inset 0 0 0 2px #d6e6fe; }
  .control-thumbnail .label {
    text-align: center;
    margin-top: 12px;
    cursor: pointer; }
    .control-thumbnail .label.disabled {
      cursor: default; }
    .control-thumbnails-list:not(.outer-border) .control-thumbnail .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .composite-thumbnails .control-thumbnails-list:not(.outer-border) .control-thumbnail .label {
      color: #116dff; }
    .outer-border .selected .control-thumbnail .label {
      color: #116dff; }

.illustration-inner-container svg .illus-clr-1,
.illustration-inner-container svg *[data-ds-class="illus-clr-1"],
.illustration-inner-container svg:hover .illus-clr-1,
.illustration-inner-container svg:hover *[data-ds-class="illus-clr-1"] {
  fill: #80b1ff; }

.illustration-inner-container svg .illus-clr-2,
.illustration-inner-container svg *[data-ds-class="illus-clr-2"],
.illustration-inner-container svg:hover .illus-clr-2,
.illustration-inner-container svg:hover *[data-ds-class="illus-clr-2"] {
  fill: #116dff; }

.illustration-inner-container svg .illus-clr-3,
.illustration-inner-container svg *[data-ds-class="illus-clr-3"],
.illustration-inner-container svg:hover .illus-clr-3,
.illustration-inner-container svg:hover *[data-ds-class="illus-clr-3"] {
  fill: #e7f0ff; }

.classic-facelift-skin .control-gallery-card-wrapper .gallery-card {
  border-color: #dfe5eb; }
  .classic-facelift-skin .control-gallery-card-wrapper .gallery-card .stacked-cards {
    background-color: #fff;
    border-color: #dfe5eb; }

.control-gallery-card-wrapper {
  max-height: calc(650px + 42px); }
  .control-gallery-card-wrapper .gallery-card {
    position: relative;
    top: 0;
    min-height: 164px;
    max-height: 650px;
    margin-bottom: 42px;
    box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17);
    border-radius: 4px;
    border: 1px solid #dfe5eb;
    transition: top 250ms ease-in-out, box-shadow 250ms ease-in-out, opacity 500ms linear, transform 500ms ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .control-gallery-card-wrapper .gallery-card.clickable:hover {
      top: -5px;
      box-shadow: 0 12px 20px rgba(23, 25, 28, 0.12);
      background-color: rgba(255, 255, 255, 0.88);
      cursor: pointer; }
      .control-gallery-card-wrapper .gallery-card.clickable:hover .gallery-card-button-overlay {
        opacity: 1; }
    .control-gallery-card-wrapper .gallery-card .stacked-cards {
      box-sizing: border-box;
      width: calc(100% - 14px);
      height: 8px;
      background-color: #fff;
      position: absolute;
      bottom: -9px;
      left: 8px;
      border-radius: 0 0 4px 4px;
      border: 1px solid #dfe5eb;
      border-top: none;
      box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17), inset 0 5px 7px -4px rgba(23, 25, 28, 0.16); }
    .control-gallery-card-wrapper .gallery-card .gallery-card-children {
      width: 100%;
      overflow: hidden;
      max-height: 650px; }
      .control-gallery-card-wrapper .gallery-card .gallery-card-children img {
        display: block; }
    .control-gallery-card-wrapper .gallery-card .gallery-card-title-container {
      position: absolute;
      top: 100%;
      padding: 12px 6px;
      width: calc(100% - 6px * 2);
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .control-gallery-card-wrapper .gallery-card .gallery-card-title-container.displayed-with-stacked {
        padding-top: calc(12px + 8px); }
      .control-gallery-card-wrapper .gallery-card .gallery-card-title-container.title-suffix-only {
        justify-content: flex-end; }
      .control-gallery-card-wrapper .gallery-card .gallery-card-title-container .gallery-card-title {
        max-width: 100%; }
        .control-gallery-card-wrapper .gallery-card .gallery-card-title-container .gallery-card-title.has-suffix {
          max-width: calc(100% - 24px); }
      .control-gallery-card-wrapper .gallery-card .gallery-card-title-container .gallery-card-title-suffix {
        max-width: 30%;
        height: 18px;
        margin: 0 0 0 6px; }
    .control-gallery-card-wrapper .gallery-card .gallery-card-button-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.88);
      opacity: 0;
      border-radius: 4px;
      transition: opacity 250ms cubic-bezier(0.4, 0, 1, 1);
      cursor: pointer; }
      .control-gallery-card-wrapper .gallery-card .gallery-card-button-overlay .gallery-card-secondary-button {
        margin-top: 12px; }

.classic-facelift-skin .radio-button-wrapper {
  color: #000624; }

.classic-facelift-skin .control-vertical-tabs .radio-button-wrapper {
  color: #000624;
  padding: 0 12px; }

.classic-facelift-skin .control-vertical-tabs .radio-button-wrapper,
.classic-facelift-skin .control-vertical-tabs .radio-button-wrapper .control-label-base {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }

.classic-facelift-skin .control-vertical-tabs:not(.with-color-accent) .radio-button-wrapper.selected:not(.upgrade) {
  color: #0057e1;
  background-color: #d6e6fe; }

.classic-facelift-skin .control-vertical-tabs:not(.with-color-accent) .radio-button-wrapper.upgrade {
  color: #9a27d5; }
  .classic-facelift-skin .control-vertical-tabs:not(.with-color-accent) .radio-button-wrapper.upgrade:hover, .classic-facelift-skin .control-vertical-tabs:not(.with-color-accent) .radio-button-wrapper.upgrade.selected {
    background-color: #f1e0f9; }

.classic-facelift-skin .control-vertical-tabs:not(.with-color-accent) .radio-button-wrapper:hover:not(.selected):not(.upgrade) {
  background-color: #e7f0ff;
  color: #116dff; }

.classic-facelift-skin .control-vertical-tabs.arrowed-box .radio-button-wrapper {
  background-color: #e7f0ff; }
  .classic-facelift-skin .control-vertical-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
    background-color: #d6e6fe; }
  .classic-facelift-skin .control-vertical-tabs.arrowed-box .radio-button-wrapper.selected {
    background-color: #116dff; }
  .classic-facelift-skin .control-vertical-tabs.arrowed-box .radio-button-wrapper:after {
    color: #116dff; }

.classic-facelift-skin .control-horizontal-tabs.arrowed .radio-button-wrapper {
  color: #3b4057; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed .radio-button-wrapper:hover {
    color: #116dff; }
    .classic-facelift-skin .control-horizontal-tabs.arrowed .radio-button-wrapper:hover:not(.selected) {
      border-bottom-color: #80b1ff; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed .radio-button-wrapper.selected {
    color: #116dff;
    border-bottom-color: #116dff; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed .radio-button-wrapper:after {
    border-color: transparent transparent #116dff; }

.classic-facelift-skin .control-horizontal-tabs.arrowed-box .radio-button-wrapper {
  background-color: #e7f0ff; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
    background-color: #d6e6fe; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed-box .radio-button-wrapper.selected {
    background-color: #116dff; }
    .classic-facelift-skin .control-horizontal-tabs.arrowed-box .radio-button-wrapper.selected:after {
      color: #fff; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed-box .radio-button-wrapper:after {
    color: #116dff; }

.classic-facelift-skin .control-horizontal-tabs.tabbed .radio-button-wrapper {
  color: #3b4057;
  background-color: #e7f0ff;
  border-bottom-color: #d6e6fe; }
  .classic-facelift-skin .control-horizontal-tabs.tabbed .radio-button-wrapper:hover:not(.selected) {
    background-color: #d6e6fe; }

.classic-facelift-skin .radio-button-wrapper .radio-button-symbol {
  border-color: #116dff; }
  .classic-facelift-skin .radio-button-wrapper .radio-button-symbol:hover {
    background-color: #d6e6fe; }

.classic-facelift-skin .radio-button-wrapper:hover:not(.selected) .radio-button-symbol {
  background-color: #d6e6fe; }

.classic-facelift-skin > .input:checked:not(:disabled) ~ .radio-button-symbol > .inner-fill {
  background-color: #116dff; }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper > .radio-button-label {
  color: #116dff;
  background-color: #e7f0ff;
  box-shadow: 0 2px 0 0 #d6e6fe; }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper.selected > .radio-button-label {
  color: #fff;
  background-color: #116dff;
  box-shadow: inset 0 2px 0 0 rgba(0, 6, 36, 0.13); }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper.disabled > .radio-button-label {
  background-color: #f0f0f0; }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper.selected.disabled > .radio-button-label {
  background-color: #bcbcbc; }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper.disabled:not(.selected) > .radio-button-label {
  color: #bcbcbc;
  box-shadow: 0 2px 0 0 #e2e2e2; }

.classic-facelift-skin .control-buttons-list-group .radio-button-wrapper:not(.disabled):not(.selected) > .radio-button-label:hover {
  background-color: #d6e6fe; }

.responsive-skin .radio-button-wrapper {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased; }
  .responsive-skin .radio-button-wrapper .radio-button-symbol {
    border-color: #868aa5;
    background-color: #fff; }
  .responsive-skin .radio-button-wrapper:hover:not(.selected) .radio-button-symbol {
    background-color: #f4f5f8;
    border-color: #868aa5; }
  .responsive-skin .radio-button-wrapper.disabled .radio-button-label {
    color: #bebebe; }
  .responsive-skin .radio-button-wrapper > .input:checked:not(:disabled) ~ .radio-button-symbol {
    border-color: transparent;
    background-color: #116dff; }
    .responsive-skin .radio-button-wrapper > .input:checked:not(:disabled) ~ .radio-button-symbol > .inner-fill {
      background-color: #fff;
      width: 4px;
      height: 4px;
      left: 5px;
      top: 5px; }
  .responsive-skin .radio-button-wrapper > .input:disabled:not(:checked) ~ .radio-button-symbol {
    background-color: #f3f3f3;
    border-color: #bebebe; }
  .responsive-skin .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol {
    background-color: #bebebe;
    border-color: transparent; }
    .responsive-skin .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol > .inner-fill {
      background-color: #fff;
      width: 4px;
      height: 4px;
      left: 5px;
      top: 5px; }

.responsive-skin .control-radio-buttons-list.vertical .radio-button-wrapper {
  margin-bottom: 4px; }
  .responsive-skin .control-radio-buttons-list.vertical .radio-button-wrapper .radio-button-symbol {
    margin-right: 8px; }

.responsive-skin .control-radio-buttons-list.horizontal .radio-button-wrapper {
  margin-right: 16px; }
  .responsive-skin .control-radio-buttons-list.horizontal .radio-button-wrapper .radio-button-symbol {
    margin-right: 8px; }

.responsive-skin .control-buttons-list-group .radio-button-wrapper:not(.disabled):not(.selected) > .radio-button-label:hover {
  background-color: transparent;
  color: #116dff; }

.responsive-skin .control-buttons-list-group {
  border: 1px solid #e2e3ea;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  height: 32px;
  width: 100%;
  background: #fff; }
  .responsive-skin .control-buttons-list-group .control-radio-buttons-list.horizontal {
    transform: translate(-1px, -1px);
    width: calc(100% + 2px);
    height: calc(100% + 2px); }
  .responsive-skin .control-buttons-list-group .radio-button-item {
    position: relative;
    width: 100%; }
    .responsive-skin .control-buttons-list-group .radio-button-item:first-of-type .radio-button-wrapper > .radio-button-label {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px; }
    .responsive-skin .control-buttons-list-group .radio-button-item:last-of-type .radio-button-wrapper > .radio-button-label {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px; }
    .responsive-skin .control-buttons-list-group .radio-button-item .radio-button-label {
      width: 100%;
      text-overflow: ellipsis;
      overflow-x: hidden;
      transition: color 0.25s linear 0.08s; }
    .responsive-skin .control-buttons-list-group .radio-button-item:last-of-type::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #dce9ff;
      border: solid 1px #7cafff;
      border-radius: 4px;
      left: 0;
      top: 0;
      transition: transform 0.28s cubic-bezier(0, 0, 0.6, 1.1);
      box-shadow: -2px 0 0 -1px #fff, 2px 0 0 -1px #fff;
      box-sizing: border-box; }
    .responsive-skin .control-buttons-list-group .radio-button-item.selected:nth-last-child(2):first-child ~ .radio-button-item::after {
      transform: translateX(-100%); }
    .responsive-skin .control-buttons-list-group .radio-button-item:nth-last-child(2):first-child + .selected::after {
      transform: translateX(0); }
    .responsive-skin .control-buttons-list-group .radio-button-item.selected:nth-last-child(3):first-child ~ .radio-button-item:last-child::after {
      transform: translateX(-200%); }
    .responsive-skin .control-buttons-list-group .radio-button-item:nth-last-child(3):first-child + .selected + .radio-button-item::after {
      transform: translateX(-100%); }
    .responsive-skin .control-buttons-list-group .radio-button-item .radio-button-wrapper > .radio-button-label,
    .responsive-skin .control-buttons-list-group .radio-button-item .radio-button-wrapper:not(.selected) > .radio-button-label {
      background-color: transparent;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: #131720;
      border: solid 1px transparent;
      height: 32px;
      box-shadow: none;
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: unset;
      min-width: 100%;
      box-sizing: border-box;
      z-index: 1; }
    .responsive-skin .control-buttons-list-group .radio-button-item .radio-button-wrapper.selected .radio-button-label {
      background-color: transparent;
      border: none;
      color: #116dff; }
  .responsive-skin .control-buttons-list-group .radio-button-item:not(:last-of-type) .radio-button-wrapper::after {
    border-right: 1px solid #e2e3ea;
    width: 1px;
    height: 16px;
    content: '';
    right: 0;
    position: absolute; }
  .responsive-skin .control-buttons-list-group.disabled {
    border-color: #e2e3ea;
    background-color: #f3f3f3; }
    .responsive-skin .control-buttons-list-group.disabled .control-radio-buttons-list .radio-button-item:last-of-type::after {
      background-color: #e5e5e5;
      border-color: #767676; }
    .responsive-skin .control-buttons-list-group.disabled .control-radio-buttons-list .radio-button-item .radio-button-wrapper.selected .radio-button-label {
      color: #bebebe;
      background-color: #e5e5e5;
      border: 1px solid #bebebe; }
    .responsive-skin .control-buttons-list-group.disabled .control-radio-buttons-list .radio-button-item .radio-button-wrapper .radio-button-label {
      color: #bebebe;
      border: 1px solid #e2e3ea;
      background-color: #f3f3f3;
      cursor: default; }

.responsive-skin .control-radio-buttons-list.horizontal .radio-button-wrapper {
  margin-right: 0; }

.radio-button-hover, .control-vertical-tabs .radio-button-wrapper:hover:not(.selected):not(.upgrade) {
  background-color: #d6e6fe;
  color: #116dff; }

.radio-button-wrapper {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #000624;
  line-height: 18px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative; }
  .radio-button-wrapper.selected {
    cursor: default; }
  .control-vertical-tabs .radio-button-wrapper {
    color: #3b4057;
    border-radius: 20px;
    padding: 0 14px;
    display: inline-flex;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    transition-property: background-color, color;
    transition-duration: 0.2s; }
    .control-vertical-tabs .radio-button-wrapper.upgrade {
      color: #9a27d5; }
      .control-vertical-tabs .radio-button-wrapper.upgrade:hover, .control-vertical-tabs .radio-button-wrapper.upgrade.selected {
        background-color: #eedbf4; }
    .control-vertical-tabs .radio-button-wrapper.selected:not(.upgrade) {
      background-color: #d6e6fe; }
    .control-vertical-tabs .radio-button-wrapper .control-label-base {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
  .control-vertical-tabs.arrowed-box .radio-button-wrapper {
    background-color: #e7f0ff;
    border-radius: 0;
    padding: 0;
    max-width: 138px;
    width: 138px;
    height: 42px; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
      background-color: #d6e6fe; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper.selected {
      background-color: #116dff; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper:after {
      content: counter(boxes, decimal);
      counter-increment: boxes 1;
      position: absolute;
      bottom: calc(50% - 6px);
      font-size: 12px;
      color: #116dff;
      left: 161px; }
  .control-horizontal-tabs .radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition-duration: 0.2s; }
  .control-horizontal-tabs.arrowed .radio-button-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    position: relative;
    color: #116dff;
    padding: 0 6px;
    border-bottom: solid 3px transparent;
    transition-property: border-bottom-color, color; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper:hover {
      color: #3b4057; }
      .control-horizontal-tabs.arrowed .radio-button-wrapper:hover:not(.selected) {
        border-bottom-color: #80b1ff; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper.selected {
      color: #3b4057;
      border-bottom-color: #116dff; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper:after {
      content: '';
      width: 0;
      height: 0;
      border: solid;
      border-color: transparent transparent #116dff;
      border-width: 0 9px 7px;
      position: absolute;
      bottom: -3px;
      left: calc(50% - 9px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
  .control-horizontal-tabs.arrowed-box .radio-button-wrapper {
    background-color: #e7f0ff;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    transition-property: background-color; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
      background-color: #d6e6fe; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper.selected {
      background-color: #116dff; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper:after {
      content: counter(boxes, decimal);
      counter-increment: boxes 1;
      position: absolute;
      bottom: 15px;
      font-size: 12px;
      color: #116dff;
      text-align: center;
      left: calc(50% - 3px); }
  .control-horizontal-tabs.tabbed .radio-button-wrapper {
    color: #3b4057;
    background-color: #e7f0ff;
    padding: 0 10px;
    transition-duration: 0.2s;
    border-bottom: solid 1px #d6e6fe; }
    .control-horizontal-tabs.tabbed .radio-button-wrapper:hover:not(.selected) {
      background-color: #d6e6fe; }
    .control-horizontal-tabs.tabbed .radio-button-wrapper.selected {
      background-color: #fff;
      border-bottom-color: transparent; }
  .radio-button-wrapper .radio-button-symbol {
    width: 16px;
    height: 16px;
    position: relative;
    border: 1px solid #116dff;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 14px;
    flex-shrink: 0;
    transition-property: background-color;
    transition-duration: 0.2s; }
    .radio-button-wrapper:hover:not(.selected) .radio-button-symbol {
      background-color: #d6e6fe; }
    .radio-button-wrapper .radio-button-symbol:hover {
      background-color: #d6e6fe; }
    .composite-radio-buttons-with-illustration .radio-button-wrapper .radio-button-symbol {
      align-self: center; }
    .radio-button-wrapper .radio-button-symbol .inner-fill {
      width: calc(10px);
      height: calc(10px);
      position: absolute;
      background-color: transparent;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      transition-property: background-color;
      transition-duration: 0.2s; }
  .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol {
    border: 1px solid #bcbcbc; }
    .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol > .inner-fill {
      background-color: #bcbcbc; }
  .radio-button-wrapper > .input:checked:not(:disabled) ~ .radio-button-symbol > .inner-fill {
    background-color: #116dff; }
  .radio-button-wrapper > .input:disabled:not(:checked) ~ .radio-button-symbol {
    border: 1px solid #bcbcbc;
    background-color: transparent; }
  .radio-button-wrapper.disabled {
    color: #7a7a7a;
    cursor: auto; }
  .radio-button-wrapper > .input {
    display: none; }
  .control-horizontal-tabs .radio-button-wrapper > .radio-button-label {
    max-height: 36px;
    overflow: hidden;
    text-align: center; }
  .control-horizontal-tabs.arrowed-box .radio-button-wrapper > .radio-button-label {
    display: none; }
  .control-vertical-tabs.arrowed-box .radio-button-wrapper > .radio-button-label {
    display: none; }
  .control-buttons-list-group .radio-button-wrapper > .radio-button-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    cursor: pointer;
    height: 36px;
    background-color: #e7f0ff;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    line-height: 36px;
    vertical-align: middle;
    font-size: 14px;
    padding: 0 14px;
    width: 73px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 2px 0 0 #d6e6fe; }
    .control-buttons-list-group .radio-button-wrapper > .radio-button-label:hover {
      box-shadow: 0 2px 0 0 #a8caff; }
  .control-buttons-list-group .selected.radio-button-wrapper > .radio-button-label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    position: relative;
    top: 2px;
    background-color: #116dff;
    box-shadow: inset 0 2px 0 0 rgba(0, 6, 36, 0.13); }
  .control-buttons-list-group .disabled.radio-button-wrapper > .radio-button-label {
    cursor: default;
    background-color: #f0f0f0; }
  .control-buttons-list-group .disabled.selected.radio-button-wrapper > .radio-button-label {
    background-color: #bcbcbc; }
  .control-buttons-list-group .disabled.radio-button-wrapper:not(.selected) > .radio-button-label {
    color: #bcbcbc;
    box-shadow: 0 2px 0 0 #e2e2e2; }
  .control-buttons-list-group .radio-button-wrapper:not(.disabled):not(.selected) > .radio-button-label:hover {
    background-color: #d6e6fe; }
  .control-buttons-list-group .radio-button-item:first-of-type .radio-button-wrapper > .radio-button-label {
    border-radius: 20px 0 0 20px; }
  .control-buttons-list-group .radio-button-item:last-of-type .radio-button-wrapper > .radio-button-label {
    border-radius: 0 20px 20px 0; }

.responsive-skin .control-radio-buttons-list > .radio-button-item .composite-radio-buttons-labeled:not(:last-child),
.responsive-skin .control-radio-buttons-list > .radio-button-item .composite-radio-buttons:not(:last-child) {
  margin-bottom: 8px; }

.classic-facelift-skin .control-vertical-tabs .control-radio-buttons-list > .radio-button-item {
  height: 30px; }

.classic-facelift-skin .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
  border-bottom-color: #116dff; }
  .classic-facelift-skin .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
    border-color: transparent transparent #116dff; }

.classic-facelift-skin .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item:not(:last-child) {
  border-right-color: #d6e6fe; }

.classic-facelift-skin .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
  border-right-color: #116dff; }
  .classic-facelift-skin .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
    border-color: transparent transparent transparent #116dff; }

.classic-facelift-skin .radio-button-wrapper > .input:checked:not(:disabled) ~ .radio-button-symbol > .inner-fill {
  background-color: #116dff; }

.control-radio-buttons-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex; }
  .control-radio-buttons-list.horizontal {
    flex-direction: row; }
  .control-radio-buttons-list.vertical {
    flex-direction: column; }
  .control-dock .control-radio-buttons-list {
    flex-wrap: wrap; }
  .control-horizontal-tabs.tabbed .control-radio-buttons-list {
    height: 59px; }
  .control-vertical-tabs.arrowed-box .control-radio-buttons-list,
  .control-horizontal-tabs.arrowed-box .control-radio-buttons-list {
    counter-reset: boxes 0; }
  .control-horizontal-tabs.arrowed .control-radio-buttons-list {
    box-shadow: inset 0 -2px 0 0 rgba(0, 6, 36, 0.05); }
  .control-horizontal-tabs.arrowed-no-line .control-radio-buttons-list {
    box-shadow: none; }
  .control-vertical-tabs .control-radio-buttons-list > .radio-button-item {
    height: 32px; }
    .control-vertical-tabs .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      margin-bottom: 4px; }
  .control-horizontal-tabs .control-radio-buttons-list > .radio-button-item {
    width: 100%; }
  .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
    height: 84px;
    position: relative;
    box-sizing: border-box;
    border-bottom: solid 3px #116dff;
    transition-duration: 0.2s;
    transition-property: border-bottom-color, color; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item.selected:after {
      opacity: 1; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      padding-right: 6px; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
      content: '';
      width: 0;
      border: solid;
      border-color: transparent transparent #116dff;
      border-width: 0 9px 7px;
      position: absolute;
      bottom: -3px;
      left: calc(50% - 9px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:first-child > .radio-button-wrapper {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child > .radio-button-wrapper {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px; }
  .control-horizontal-tabs.arrowed .control-radio-buttons-list > .radio-button-item {
    height: 54px; }
  .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item {
    height: 47px; }
    .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      border-right: solid 1px #d6e6fe; }
  .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
    position: relative;
    box-sizing: border-box;
    border-right: solid 3px #116dff;
    transition-duration: 0.2s;
    transition-property: border-bottom-color, color;
    padding-right: 12px;
    height: 48px;
    margin-bottom: 0; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item.selected:after {
      opacity: 1; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child {
      height: 42px; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
      content: '';
      width: 0;
      border: solid;
      border-color: transparent transparent transparent #116dff;
      border-width: 9px 0 9px 7px;
      position: absolute;
      left: 150px;
      bottom: calc(50% - 6px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:first-child > .radio-button-wrapper {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child > .radio-button-wrapper {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
  .composite-radio-buttons-labeled .control-radio-buttons-list > .radio-button-item:not(:last-child),
  .composite-radio-buttons .control-radio-buttons-list > .radio-button-item:not(:last-child) {
    margin-bottom: 12px; }
  .composite-radio-buttons-with-illustration .control-radio-buttons-list > .radio-button-item:not(:first-child) {
    padding-top: 24px; }
  .control-radio-buttons-list > .radio-button-item.selection-area > .radio-button-wrapper {
    padding: 6px 12px;
    border-radius: 4px; }
    .control-radio-buttons-list > .radio-button-item.selection-area > .radio-button-wrapper:hover {
      background-color: #e7f0ff; }
    .control-radio-buttons-list > .radio-button-item.selection-area > .radio-button-wrapper.selected {
      background-color: #d6e6fe; }
    .control-radio-buttons-list > .radio-button-item.selection-area > .radio-button-wrapper.selected.disabled {
      background-color: #f0f0f0; }

.control-tab {
  width: 100%; }
  .control-tab .dot-indicator {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #e62214;
    top: calc(50% - 2.5px);
    right: 6px; }
  .control-vertical-tabs.arrowed-box .control-tab {
    display: none; }

.classic-facelift-skin .control-vertical-tabs {
  --vertical-tabs-accent-color: var(--vertical-tabs-custom-accent-color, #f7f8f8);
  padding: 12px; }
  .classic-facelift-skin .control-vertical-tabs.border-right {
    border-right: 1px solid #dfe5eb; }
  .classic-facelift-skin .control-vertical-tabs .divider-short.divider-horizontal,
  .classic-facelift-skin .control-vertical-tabs .divider-long.divider-horizontal {
    width: calc(100% - 24px);
    margin-left: 12px;
    border-right: 1px solid #dfe5eb; }

.responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) {
  padding: 16px 0;
  --vertical-tabs-accent-color: var(--vertical-tabs-custom-accent-color, #fff); }
  .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item {
    margin-bottom: 0 !important;
    height: 100%; }
    .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item .radio-button-wrapper {
      padding: 8px 24px;
      color: #131720;
      transition-duration: 0;
      transition-property: none;
      border-radius: 0;
      margin-bottom: 0;
      width: 100%; }
      .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item .radio-button-wrapper.upgrade {
        color: #5A48F5; }
      .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item .radio-button-wrapper .dot-indicator {
        right: unset; }
    .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item.selected {
      background-color: #dce9ff;
      border-radius: 0; }
      .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item.selected .radio-button-wrapper {
        background-color: #dce9ff;
        color: #116dff; }
        .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item.selected .radio-button-wrapper.upgrade {
          color: #5A48F5;
          background-color: #e7e4fe; }
    .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item:hover:not(.selected) {
      background-color: #f4f5f8;
      border-radius: 0;
      cursor: pointer; }
      .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item:hover:not(.selected) .radio-button-wrapper {
        background-color: #f4f5f8;
        border-radius: 0;
        color: #131720; }
        .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .radio-button-item:hover:not(.selected) .radio-button-wrapper.upgrade {
          background-color: #eeecfe;
          color: #5A48F5;
          width: 100%; }
  .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box).border-right {
    border-right: 1px solid #e5e5e5; }
  .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .divider-short.divider-horizontal,
  .responsive-skin .control-vertical-tabs:not(.with-color-accent):not(.arrowed-box) .divider-long.divider-horizontal {
    width: 100%;
    margin: 10px 0;
    border-right: 1px solid #e5e5e5; }

.control-vertical-tabs {
  --vertical-tabs-pane-max-width: var(--vertical-tabs-custom-pane-max-width, 150px);
  --vertical-tabs-accent-color: var(--vertical-tabs-custom-accent-color, #f7fbff);
  padding: 15px 10px;
  box-sizing: border-box;
  background-color: var(--vertical-tabs-accent-color);
  height: 100%;
  min-width: 114px;
  max-width: var(--vertical-tabs-pane-max-width);
  flex-shrink: 0;
  width: -webkit-max-content;
  width: max-content; }
  .control-vertical-tabs.with-color-accent .radio-button-wrapper.selected, .control-vertical-tabs.with-color-accent .radio-button-wrapper:hover:not(.selected) {
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
  .control-vertical-tabs.with-color-accent .radio-button-wrapper.selected {
    background-color: rgba(0, 0, 0, 0.2); }
  .control-vertical-tabs.with-color-accent .radio-button-wrapper:hover:not(.selected) {
    background-color: rgba(0, 0, 0, 0.07); }
  .control-vertical-tabs.with-color-accent .control-tab .control-label {
    color: #fff; }
  .control-vertical-tabs.arrowed-box {
    padding: 0;
    max-width: 153px;
    width: 153px;
    background-color: transparent; }
  .control-vertical-tabs .divider-short.divider-horizontal,
  .control-vertical-tabs .divider-long.divider-horizontal {
    width: calc(100% - 28px);
    margin-left: 14px;
    margin-top: 7px;
    margin-bottom: 10px; }

.responsive-skin .control-tabs-vertical-custom {
  background-color: #fff;
  box-shadow: inset -1px 0 0 0 #e5e5e5; }
  .responsive-skin .control-tabs-vertical-custom .tab {
    border-right: 1px solid #e5e5e5; }
  .responsive-skin .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
    background-color: #dce9ff; }
    .responsive-skin .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper svg {
      fill: #116dff; }
  .responsive-skin .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper {
    background-color: #fff; }
    .responsive-skin .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper svg {
      fill: #131720; }
    .responsive-skin .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
      background-color: #f4f5f8;
      cursor: pointer; }
  .responsive-skin .control-tabs-vertical-custom .tab:first-child input:checked + span {
    border-top: none; }

.classic-facelift-skin .control-tabs-vertical-custom {
  background-color: #e7f0ff; }
  .classic-facelift-skin .control-tabs-vertical-custom .tab-text, .classic-facelift-skin .control-tabs-vertical-custom .advanced-style-panel .tab-text-composite, .advanced-style-panel .classic-facelift-skin .control-tabs-vertical-custom .tab-text-composite {
    color: #3b4057; }
  .classic-facelift-skin .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper .tab-icon svg {
    fill: #116dff; }
  .classic-facelift-skin .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
    border-top-color: #d6e6fe;
    border-bottom-color: #d6e6fe; }
    .classic-facelift-skin .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper svg {
      fill: #3b4057; }
  .classic-facelift-skin .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
    background-color: #d6e6fe; }

.control-tabs-vertical-custom {
  display: block;
  font-size: 0;
  width: 48px;
  height: 100%;
  background-color: #e7f0ff;
  box-shadow: inset -1px 0 0 0 #d6e6fe; }
  .control-tabs-vertical-custom .tab-text, .control-tabs-vertical-custom .advanced-style-panel .tab-text-composite, .advanced-style-panel .control-tabs-vertical-custom .tab-text-composite {
    position: absolute;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    color: #3b4057; }
  .control-tabs-vertical-custom .tab-text span, .control-tabs-vertical-custom .advanced-style-panel .tab-text-composite span, .advanced-style-panel .control-tabs-vertical-custom .tab-text-composite span {
    cursor: pointer; }
  .control-tabs-vertical-custom.closed {
    width: 288px;
    background-color: transparent;
    box-shadow: none; }
    .control-tabs-vertical-custom.closed svg {
      left: 12px; }
    .control-tabs-vertical-custom.closed .tab-text, .control-tabs-vertical-custom.closed .advanced-style-panel .tab-text-composite, .advanced-style-panel .control-tabs-vertical-custom.closed .tab-text-composite {
      display: inline-block; }
  .control-tabs-vertical-custom .radio-button-wrapper {
    height: 48px; }
    .control-tabs-vertical-custom .radio-button-wrapper input {
      display: none; }
      .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper {
        display: inline-block;
        width: 100%;
        height: 48px;
        position: relative;
        line-height: 48px; }
        .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper .tab-icon svg {
          background-repeat: no-repeat;
          position: absolute;
          top: calc(50% - 12px);
          left: 12px;
          fill: #116dff; }
      .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
        background-color: #fff;
        border-top: 1px solid #d6e6fe;
        border-bottom: 1px solid #d6e6fe; }
        .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper svg {
          fill: #000624; }
      .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
        background-color: #d6e6fe;
        cursor: pointer; }
  .control-tabs-vertical-custom .tab:first-child input:checked + span {
    border-top: none; }

.responsive-skin hr.divider-long, .responsive-skin hr.divider-short {
  background-color: #e5e5e5; }

.responsive-skin .settingsPanelContainer hr {
  display: none; }

.responsive-skin .context-menu-options hr.divider-long.divider-horizontal, .responsive-skin .context-menu-options hr.divider-short.divider-horizontal {
  width: 100%;
  margin: 6px 0; }

.responsive-skin .context-menu-options hr.divider-long.divider-vertical, .responsive-skin .context-menu-options hr.divider-short.divider-vertical {
  height: 100%;
  margin: 0 6px; }

.classic-facelift-skin hr.divider-long, .classic-facelift-skin hr.divider-short {
  background-color: #dfe5eb; }

.responsive-skin.dark-mode hr.divider-long, .responsive-skin.dark-mode hr.divider-short {
  background-color: #2B2E36; }

hr.divider-long, hr.divider-short {
  box-sizing: content-box;
  background-color: #dfe5eb;
  border: 0;
  margin: 0; }
  hr.divider-long.divider-horizontal, hr.divider-short.divider-horizontal {
    height: 1px; }
    .context-menu-options hr.divider-long.divider-horizontal, .context-menu-options hr.divider-short.divider-horizontal {
      width: calc(100% - 36px);
      margin: 6px 18px; }
  hr.divider-long.divider-vertical, hr.divider-short.divider-vertical {
    display: inline-block;
    width: 1px; }

hr.divider-long.divider-horizontal {
  width: 100%; }

hr.divider-long.divider-vertical {
  height: 100%; }

hr.divider-short.divider-horizontal {
  width: calc(100% - 48px);
  margin: 0 24px; }

hr.divider-short.divider-vertical {
  height: calc(100% - 48px);
  margin: 24px 0; }

.responsive-skin .control-thumbnail-button-group .control-button.btn-thumbnail:not([disabled]) path {
  fill: #131720; }

.composite-thumbnail-button-group > .control-label + .control-thumbnail-button-group {
  margin-top: 18px; }

.control-thumbnail-button-group .thumbnail-buttons-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .control-thumbnail-button-group[data-max-items-per-row="1"] .thumbnail-buttons-section .btn-thumbnail {
    width: calc((100% - (12px * (1 - 1))) / 1); }
    .control-thumbnail-button-group[data-max-items-per-row="1"] .thumbnail-buttons-section .btn-thumbnail:not(:nth-child(1n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="1"] .thumbnail-buttons-section .btn-thumbnail:nth-child(1n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="1"] .thumbnail-buttons-section .btn-thumbnail:nth-child(1n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="2"] .thumbnail-buttons-section .btn-thumbnail {
    width: calc((100% - (12px * (2 - 1))) / 2); }
    .control-thumbnail-button-group[data-max-items-per-row="2"] .thumbnail-buttons-section .btn-thumbnail:not(:nth-child(2n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="2"] .thumbnail-buttons-section .btn-thumbnail:nth-child(2n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="2"] .thumbnail-buttons-section .btn-thumbnail:nth-child(2n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="3"] .thumbnail-buttons-section .btn-thumbnail {
    width: calc((100% - (12px * (3 - 1))) / 3); }
    .control-thumbnail-button-group[data-max-items-per-row="3"] .thumbnail-buttons-section .btn-thumbnail:not(:nth-child(3n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="3"] .thumbnail-buttons-section .btn-thumbnail:nth-child(3n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="3"] .thumbnail-buttons-section .btn-thumbnail:nth-child(3n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="4"] .thumbnail-buttons-section .btn-thumbnail {
    width: calc((100% - (12px * (4 - 1))) / 4); }
    .control-thumbnail-button-group[data-max-items-per-row="4"] .thumbnail-buttons-section .btn-thumbnail:not(:nth-child(4n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="4"] .thumbnail-buttons-section .btn-thumbnail:nth-child(4n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="4"] .thumbnail-buttons-section .btn-thumbnail:nth-child(4n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="5"] .thumbnail-buttons-section .btn-thumbnail {
    width: calc((100% - (12px * (5 - 1))) / 5); }
    .control-thumbnail-button-group[data-max-items-per-row="5"] .thumbnail-buttons-section .btn-thumbnail:not(:nth-child(5n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="5"] .thumbnail-buttons-section .btn-thumbnail:nth-child(5n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="5"] .thumbnail-buttons-section .btn-thumbnail:nth-child(5n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group .thumbnail-buttons-section:not(:first-child) {
    margin-top: 12px; }

.classic-facelift-skin .control-thumbnail-color-input .control-thumbnail-color-input-item {
  border-radius: 4px; }
  .classic-facelift-skin .control-thumbnail-color-input .control-thumbnail-color-input-item:hover {
    box-shadow: inset 0 0 0 2px #80b1ff; }
  .classic-facelift-skin .control-thumbnail-color-input .control-thumbnail-color-input-item.selected-item {
    box-shadow: 0 0 0 2px #116dff; }

.control-thumbnail-color-input .btn-thumbnail {
  position: relative;
  display: block; }
  .control-thumbnail-color-input .btn-thumbnail::before {
    display: inline-block;
    content: '';
    padding: 50%;
    vertical-align: -webkit-baseline-middle; }
  .control-thumbnail-color-input .btn-thumbnail .button-content {
    display: block; }
    .control-thumbnail-color-input .btn-thumbnail .button-content .control-thumbnail-color-input-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }

.control-thumbnail-color-input .control-thumbnail-color-input-item {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #dfe5eb; }
  .control-thumbnail-color-input .control-thumbnail-color-input-item:hover {
    box-shadow: inset 0 0 0 2px #80b1ff; }
  .control-thumbnail-color-input .control-thumbnail-color-input-item.selected-item {
    border-radius: 6px;
    box-shadow: 0 0 0 2px #116dff;
    border: 1px solid #fff;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px; }

.control-thumbnails-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper {
    width: calc((100% - (12px * (1 - 1))) / 1); }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:not(:nth-child(1n)):not(:last-child) {
      margin-right: 12px; }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
      margin-top: 12px; }
  .responsive-skin .with-label.control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
    margin-top: 12px; }
  .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper {
    width: calc((100% - (12px * (2 - 1))) / 2); }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:not(:nth-child(2n)):not(:last-child) {
      margin-right: 12px; }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
      margin-top: 12px; }
  .responsive-skin .with-label.control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
    margin-top: 12px; }
  .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper {
    width: calc((100% - (12px * (3 - 1))) / 3); }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:not(:nth-child(3n)):not(:last-child) {
      margin-right: 12px; }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
      margin-top: 12px; }
  .responsive-skin .with-label.control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
    margin-top: 12px; }
  .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper {
    width: calc((100% - (12px * (4 - 1))) / 4); }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:not(:nth-child(4n)):not(:last-child) {
      margin-right: 12px; }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
      margin-top: 12px; }
  .responsive-skin .with-label.control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
    margin-top: 12px; }
  .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper {
    width: calc((100% - (12px * (5 - 1))) / 5); }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:not(:nth-child(5n)):not(:last-child) {
      margin-right: 12px; }
    .responsive-skin .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
      margin-top: 12px; }
  .responsive-skin .with-label.control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
    margin-top: 12px; }

.composite-thumbnails > .control-label + .responsive-skin .control-thumbnails-list {
  margin-top: 12px; }

.responsive-skin .composite-panel-footer-button-with-icon .composite-thumbnails .control-thumbnails-list {
  margin-bottom: 0; }

.responsive-skin .letters-thumbnails .input:not(:checked) ~ .control-thumbnail .illustration-inner-container {
  background-color: #fff;
  color: #131720; }

.responsive-skin .letters-thumbnails .input:not(:checked) ~ .control-thumbnail .illustration-inner-container:hover {
  background-color: #f4f5f8; }

.responsive-skin .letters-thumbnails .input:checked ~ .control-thumbnail .illustration-inner-container {
  background-color: #dce9ff;
  color: #116dff; }

.composite-icon-tabs > .classic-facelift-skin .control-thumbnails-list {
  border: 1px solid #f7f8f8; }
  .composite-icon-tabs > .classic-facelift-skin .control-thumbnails-list > .thumbnail-wrapper:not(:last-child) {
    border-right: 1px solid #f7f8f8; }

.control-thumbnails-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-label) {
    margin-bottom: 6px; }
  .control-thumbnails-list.big .illustration-inner-container {
    width: 76px;
    height: 76px;
    font-size: 20px; }
  .composite-thumbnails > .control-label + .control-thumbnails-list {
    margin-top: 18px; }
  .composite-icon-tabs > .control-thumbnails-list {
    border: 1px solid #e2e3ea;
    border-radius: 4px; }
    .composite-icon-tabs > .control-thumbnails-list > .thumbnail-wrapper {
      height: 28px;
      flex: 1 1 0; }
      .composite-icon-tabs > .control-thumbnails-list > .thumbnail-wrapper:not(:last-child) {
        border-right: 1px solid #e2e3ea; }
      .composite-icon-tabs > .control-thumbnails-list > .thumbnail-wrapper > .label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .composite-icon-tabs > .control-thumbnails-list + .control-label {
      align-self: center;
      margin-top: 8px; }
  .control-thumbnails-list .thumbnail-wrapper {
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    max-width: 100%; }
    .outer-border.control-thumbnails-list .thumbnail-wrapper {
      display: flex; }
    .control-thumbnails-list .thumbnail-wrapper.selected {
      cursor: default; }
    .control-thumbnails-list .thumbnail-wrapper > .label {
      cursor: inherit; }
      .outer-border.control-thumbnails-list .thumbnail-wrapper > .label {
        display: flex;
        flex-direction: column;
        width: 100%; }
      .control-thumbnails-list .thumbnail-wrapper > .label > .input {
        display: none; }
  .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper {
    width: calc((100% - (12px * (1 - 1))) / 1); }
    .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:not(:nth-child(1n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper {
    width: calc((100% - (12px * (2 - 1))) / 2); }
    .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:not(:nth-child(2n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper {
    width: calc((100% - (12px * (3 - 1))) / 3); }
    .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:not(:nth-child(3n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper {
    width: calc((100% - (12px * (4 - 1))) / 4); }
    .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:not(:nth-child(4n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper {
    width: calc((100% - (12px * (5 - 1))) / 5); }
    .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:not(:nth-child(5n)):not(:last-child) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
    margin-top: 16px; }

.responsive-skin .image-and-text > .content > .title {
  font-weight: var(--wbu-font-weight-medium); }

.image-and-text {
  display: inline-flex;
  align-items: center; }
  .composite-radio-buttons-with-illustration .image-and-text > .image {
    margin-left: 10px;
    margin-right: 14px;
    flex-shrink: 0; }
  .image-and-text > .content {
    text-align: left;
    box-sizing: border-box; }
    .composite-radio-buttons-with-illustration .image-and-text > .content {
      margin-left: 10px; }
    .image-and-text > .content > .title {
      margin: 0;
      font-weight: var(--wbu-font-weight-bold); }
      .composite-radio-buttons-with-illustration .image-and-text > .content > .title {
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-size: 18px;
        line-height: 24px;
        color: #3b4057;
        -webkit-font-smoothing: antialiased; }
    .image-and-text > .content > .disabled-title {
      margin: 0; }
      .composite-radio-buttons-with-illustration .image-and-text > .content > .disabled-title {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        line-height: 24px;
        color: #939393; }
    .image-and-text > .content > .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 18px;
      color: #3b4057; }
    .image-and-text > .content > .disabled-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 18px;
      color: #939393; }
  .composite-radio-buttons-with-illustration .image-and-text > .suffix {
    margin: auto 0 auto 10px; }

.responsive-skin .control-promotional-list .promotional-list-item > svg {
  align-self: center;
  margin-right: 12px; }

.responsive-skin .control-promotional-list .promotional-list-item .control-label > .control-label-base {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  color: #42454C; }

.control-promotional-list {
  display: inline-block;
  text-align: left; }
  .control-promotional-list > .promotional-list-item {
    display: flex;
    align-items: center; }
    .control-promotional-list > .promotional-list-item:not(:last-child) {
      margin-bottom: 12px; }
    .control-promotional-list > .promotional-list-item > svg {
      flex-shrink: 0;
      margin-right: 20px; }
    .control-promotional-list > .promotional-list-item > .control-label {
      max-width: 468px; }

.control-promotional-modal.control-promotional-modal {
  width: 600px;
  display: flex;
  flex-direction: column; }
  .control-promotional-modal.control-promotional-modal .control-base-modal-header {
    padding-top: 24px; }
  .control-promotional-modal.control-promotional-modal .control-base-modal-body {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0; }
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-content-header,
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-content-wrapper,
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-footer,
  .control-promotional-modal.control-promotional-modal .control-base-modal-footnote {
    padding-right: 78px;
    padding-left: 78px; }
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px; }
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-title,
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-subtitle {
    text-align: center; }
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-title:not(:last-child),
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-subtitle:not(:last-child) {
      margin-bottom: 12px; }
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-image,
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-illustration {
    margin-bottom: 30px; }
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-image > *,
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-illustration > * {
      width: 100%;
      height: 100%; }
  .control-promotional-modal.control-promotional-modal .control-promotional-modal-footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 36px; }
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-footer .control-button {
      margin: 0; }
    .control-promotional-modal.control-promotional-modal .control-promotional-modal-footer .control-promotional-modal-secondary-btn {
      display: flex;
      margin-top: 12px; }

.responsive-skin .control-preloader::before {
  color: #116dff; }

.responsive-skin .control-preloader::after {
  color: #116dff; }

.responsive-skin .control-preloader.light::before, .responsive-skin .control-preloader.light::after {
  color: #fff; }

.classic-facelift-skin .control-preloader::before {
  color: #80b1ff; }

.classic-facelift-skin .control-preloader::after {
  color: #116dff; }

.classic-facelift-skin .control-preloader.light::before, .classic-facelift-skin .control-preloader.light::after {
  color: #fff; }

@keyframes semi-rotate {
  0% {
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  100% {
    transform: rotate(540deg); } }

@keyframes inner-rotate {
  to {
    transform: rotate(115deg);
    opacity: 1; } }

.control-preloader {
  width: 15px;
  height: 30px;
  margin-left: -15px;
  overflow: hidden;
  transform-origin: 100% 50%;
  animation: semi-rotate 1s linear infinite; }
  .control-preloader.tiny {
    width: 9px;
    height: 18px;
    margin-left: -9px; }
    .control-preloader.tiny::before, .control-preloader.tiny::after {
      border-width: 2px; }
  .control-preloader.medium {
    width: 36px;
    height: 72px;
    margin-left: -36px; }
  .control-preloader.large {
    width: 60px;
    height: 120px;
    margin-left: -60px; }
  .control-preloader::before, .control-preloader::after {
    content: "";
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate; }
  .control-preloader::before {
    color: #80b1ff; }
  .control-preloader::after {
    color: #116dff;
    opacity: 0; }
  .control-preloader.light::before, .control-preloader.light::after {
    color: #fff; }

.responsive-skin .control-progress-bar {
  height: 4px; }
  .responsive-skin .control-progress-bar > .progress-line-container {
    background-color: #e5e5e5;
    height: 4px;
    border-radius: 0; }
    .responsive-skin .control-progress-bar > .progress-line-container > .progress-line {
      background-color: #116dff;
      height: 4px; }
  .responsive-skin .control-progress-bar > .progress-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    color: #42454C; }

.classic-facelift-skin .control-progress-bar > .progress-line-container {
  background-color: #d6e6fe; }
  .classic-facelift-skin .control-progress-bar > .progress-line-container > .progress-line {
    background-color: #116dff; }

.classic-facelift-skin .control-progress-bar > .progress-text {
  color: #868aa5; }

.control-progress-bar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
  width: 100%; }
  .composite-progress-bar .control-progress-bar,
  .composite-progress-bar-with-illustration .control-progress-bar,
  .composite-progress-bar-with-illustration-vertical .control-progress-bar {
    padding-top: 11px;
    padding-bottom: 13px; }
  .control-progress-bar > .progress-line-container {
    background-color: #d6e6fe;
    height: 6px;
    border-radius: 4px;
    position: relative;
    overflow: hidden; }
    .control-progress-bar > .progress-line-container > .progress-line {
      transition: width 0.2s;
      background-color: #116dff;
      position: absolute;
      height: 6px;
      left: 0;
      top: 0; }
  .control-progress-bar > .progress-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    color: #868aa5;
    -webkit-user-select: none;
            user-select: none; }
  .control-progress-bar.theme-ai > .progress-line-container > .progress-line {
    transition: left 0.2s;
    background: linear-gradient(90deg, #0021FF 0%, #116DFF 25%, #5FF2AE 50%, #116DFF 75%, #0021FF 100%);
    background-size: 300% 200%;
    background-repeat: repeat;
    will-change: background-position;
    animation: progressBarBackground 2s infinite linear; }

@keyframes progressBarBackground {
  0% {
    background-position: right center; }
  100% {
    background-position: left center; } }

.classic-facelift-skin .control-contextual-divider > .horizontal-line {
  background-color: #dfe5eb; }

.classic-facelift-skin .control-contextual-divider > .control-heading, .classic-facelift-skin .control-contextual-divider > .control-heading.h1, .classic-facelift-skin .control-contextual-divider > .control-heading.h5 {
  color: #000; }

.control-contextual-divider {
  display: flex;
  align-items: center; }
  .control-contextual-divider > .horizontal-line {
    display: inline;
    flex: 1;
    border: 0;
    box-sizing: content-box;
    background-color: #dfe5eb;
    height: 1px;
    margin: 0 24px; }
  .control-contextual-divider > .control-heading, .control-contextual-divider > .control-heading.h1, .control-contextual-divider > .control-heading.h5 {
    color: #000000; }

.control-editable-url .symbol-addressBarMiniToolbar {
  margin-right: 8px;
  width: 32px;
  height: 12px;
  fill: #868aa5; }

.classic-facelift-skin .control-editable-url > .address-bar-container {
  background-color: #dfe5eb; }
  .classic-facelift-skin .control-editable-url > .address-bar-container > .url-container {
    background-color: #fff; }
    .classic-facelift-skin .control-editable-url > .address-bar-container > .url-container > .domain {
      color: #868aa5; }
  .classic-facelift-skin .control-editable-url > .address-bar-container .input-editable-url {
    color: #3b4057; }
    .classic-facelift-skin .control-editable-url > .address-bar-container .input-editable-url::selection {
      background-color: #d6e6fe;
      color: #3b4057; }
    .classic-facelift-skin .control-editable-url > .address-bar-container .input-editable-url::placeholder {
      color: #acafc4; }

.classic-facelift-skin .focus.control-editable-url > .address-bar-container > .url-container {
  background-color: #f7f8f8; }

.control-editable-url {
  display: flex;
  align-items: center; }
  .control-editable-url > .address-bar-container {
    padding: 5px 5px 5px 10px;
    border-radius: 4px;
    background-color: #dfe5eb;
    display: flex;
    align-items: center;
    flex: 1; }
    .control-editable-url > .address-bar-container > .url-container {
      border-radius: 4px;
      height: 26px;
      padding-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      background-color: #fff; }
      .focus.control-editable-url > .address-bar-container > .url-container {
        background-color: #f7f8f8; }
      .control-editable-url > .address-bar-container > .url-container > .domain {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        color: #868aa5;
        cursor: default; }
  .control-editable-url > .buttons-container {
    display: flex;
    align-items: center;
    padding-left: 12px; }
    .control-editable-url > .buttons-container .control-button:nth-child(2) {
      margin-left: 6px; }

.responsive-skin.dark-mode .panel-header {
  border-bottom: 1px solid #2B2E36; }
  .responsive-skin.dark-mode .panel-header.light, .responsive-skin.dark-mode .panel-header.dark {
    background-color: #131720; }
    .responsive-skin.dark-mode .panel-header.light > .btn-header,
    .responsive-skin.dark-mode .panel-header.light > .has-tooltip button,
    .responsive-skin.dark-mode .panel-header.light > button, .responsive-skin.dark-mode .panel-header.dark > .btn-header,
    .responsive-skin.dark-mode .panel-header.dark > .has-tooltip button,
    .responsive-skin.dark-mode .panel-header.dark > button {
      color: #f4f5f8; }
      .responsive-skin.dark-mode .panel-header.light > .btn-header.control-button svg *,
      .responsive-skin.dark-mode .panel-header.light > .btn-header svg,
      .responsive-skin.dark-mode .panel-header.light > .has-tooltip button.control-button svg *,
      .responsive-skin.dark-mode .panel-header.light > .has-tooltip button svg,
      .responsive-skin.dark-mode .panel-header.light > button.control-button svg *,
      .responsive-skin.dark-mode .panel-header.light > button svg, .responsive-skin.dark-mode .panel-header.dark > .btn-header.control-button svg *,
      .responsive-skin.dark-mode .panel-header.dark > .btn-header svg,
      .responsive-skin.dark-mode .panel-header.dark > .has-tooltip button.control-button svg *,
      .responsive-skin.dark-mode .panel-header.dark > .has-tooltip button svg,
      .responsive-skin.dark-mode .panel-header.dark > button.control-button svg *,
      .responsive-skin.dark-mode .panel-header.dark > button svg {
        fill: #f4f5f8; }
    .responsive-skin.dark-mode .panel-header.light span, .responsive-skin.dark-mode .panel-header.dark span {
      color: #f4f5f8; }
    .responsive-skin.dark-mode .panel-header.light .panel-header-title, .responsive-skin.dark-mode .panel-header.dark .panel-header-title {
      color: #f4f5f8; }

.responsive-skin.responsive-popups-experiment .panel-header {
  border-bottom: 1px solid #2B2E36; }
  .responsive-skin.responsive-popups-experiment .panel-header .panel-header-title {
    color: #f4f5f8; }
  .responsive-skin.responsive-popups-experiment .panel-header.light, .responsive-skin.responsive-popups-experiment .panel-header.dark {
    background-color: #131720; }
    .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header,
    .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button,
    .responsive-skin.responsive-popups-experiment .panel-header.light > button, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header,
    .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button,
    .responsive-skin.responsive-popups-experiment .panel-header.dark > button {
      color: #fff; }
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header svg,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button svg,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button svg, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header svg,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button svg,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button svg {
        fill: #f4f5f8; }
    .responsive-skin.responsive-popups-experiment .panel-header.light .panel-header-title, .responsive-skin.responsive-popups-experiment .panel-header.dark .panel-header-title {
      color: #f4f5f8; }

.responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header {
  background-color: #131720; }
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header span {
    color: #f4f5f8; }
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header.control-button svg *,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header svg,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button.control-button svg *,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button svg,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button.control-button svg *,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button svg {
    stroke: #f4f5f8;
    fill: #f4f5f8; }

.responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .panel-header .btn-header.control-button svg * {
  stroke: #f4f5f8;
  fill: #f4f5f8; }

.responsive-skin .comp-panel-frame {
  border-radius: 0; }

.responsive-skin .panel-header {
  height: 56px;
  min-height: 56px;
  border-bottom: 1px solid #e5e5e5; }
  .responsive-skin .panel-header.without-divider {
    border-bottom: none; }
  .responsive-skin .panel-header .panel-header-title {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 16px; }
  .responsive-skin .panel-header > .btn-header {
    position: static; }
  .responsive-skin .panel-header.light, .responsive-skin .panel-header.dark {
    border-radius: 0;
    background-color: #fff; }
    .responsive-skin .panel-header.light > .btn-header,
    .responsive-skin .panel-header.light > .has-tooltip button,
    .responsive-skin .panel-header.light > button, .responsive-skin .panel-header.dark > .btn-header,
    .responsive-skin .panel-header.dark > .has-tooltip button,
    .responsive-skin .panel-header.dark > button {
      background-color: transparent;
      color: #131720;
      top: 26px; }
      .responsive-skin .panel-header.light > .btn-header.btn-close,
      .responsive-skin .panel-header.light > .has-tooltip button.btn-close,
      .responsive-skin .panel-header.light > button.btn-close, .responsive-skin .panel-header.dark > .btn-header.btn-close,
      .responsive-skin .panel-header.dark > .has-tooltip button.btn-close,
      .responsive-skin .panel-header.dark > button.btn-close {
        right: 16px;
        margin: 0px; }
      .responsive-skin .panel-header.light > .btn-header.btn-help,
      .responsive-skin .panel-header.light > .has-tooltip button.btn-help,
      .responsive-skin .panel-header.light > button.btn-help, .responsive-skin .panel-header.dark > .btn-header.btn-help,
      .responsive-skin .panel-header.dark > .has-tooltip button.btn-help,
      .responsive-skin .panel-header.dark > button.btn-help {
        right: 40px;
        margin: 0px; }
      .responsive-skin .panel-header.light > .btn-header:hover,
      .responsive-skin .panel-header.light > .has-tooltip button:hover,
      .responsive-skin .panel-header.light > button:hover, .responsive-skin .panel-header.dark > .btn-header:hover,
      .responsive-skin .panel-header.dark > .has-tooltip button:hover,
      .responsive-skin .panel-header.dark > button:hover {
        background-color: transparent; }
      .responsive-skin .panel-header.light > .btn-header.control-button svg *,
      .responsive-skin .panel-header.light > .btn-header svg,
      .responsive-skin .panel-header.light > .has-tooltip button.control-button svg *,
      .responsive-skin .panel-header.light > .has-tooltip button svg,
      .responsive-skin .panel-header.light > button.control-button svg *,
      .responsive-skin .panel-header.light > button svg, .responsive-skin .panel-header.dark > .btn-header.control-button svg *,
      .responsive-skin .panel-header.dark > .btn-header svg,
      .responsive-skin .panel-header.dark > .has-tooltip button.control-button svg *,
      .responsive-skin .panel-header.dark > .has-tooltip button svg,
      .responsive-skin .panel-header.dark > button.control-button svg *,
      .responsive-skin .panel-header.dark > button svg {
        fill: #131720;
        stroke: none; }
    .responsive-skin .panel-header.light .panel-header-title, .responsive-skin .panel-header.dark .panel-header-title {
      color: #131720; }
  .responsive-skin .panel-header > .control-icon-button {
    margin-left: 0px; }
  .responsive-skin .panel-header.with-subtitle {
    height: 75px;
    min-height: 75px; }
    .responsive-skin .panel-header.with-subtitle > button.control-button.btn-header,
    .responsive-skin .panel-header.with-subtitle.has-back > button.control-button.btn-header,
    .responsive-skin .panel-header.with-subtitle.has-back > .has-tooltip {
      margin-bottom: 18px; }
      .responsive-skin .panel-header.with-subtitle > button.control-button.btn-header.btn-help,
      .responsive-skin .panel-header.with-subtitle.has-back > button.control-button.btn-header.btn-help,
      .responsive-skin .panel-header.with-subtitle.has-back > .has-tooltip.btn-help {
        margin-bottom: 18px; }

.responsive-skin.responsive-popups-experiment .composite-popup .panel-header {
  position: static; }

.responsive-skin.responsive-popups-experiment .comp-panel-frame {
  border-radius: 0; }

.responsive-skin.responsive-popups-experiment .panel-header {
  height: 56px;
  min-height: 56px;
  border-bottom: 1px solid #e5e5e5; }
  .responsive-skin.responsive-popups-experiment .panel-header.without-divider {
    border-bottom: none; }
  .responsive-skin.responsive-popups-experiment .panel-header .panel-header-title {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 16px;
    color: #000624; }
  .responsive-skin.responsive-popups-experiment .panel-header > .btn-header {
    position: static; }
  .responsive-skin.responsive-popups-experiment .panel-header.light, .responsive-skin.responsive-popups-experiment .panel-header.dark {
    border-radius: 0;
    background-color: #fff; }
    .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header,
    .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button,
    .responsive-skin.responsive-popups-experiment .panel-header.light > button, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header,
    .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button,
    .responsive-skin.responsive-popups-experiment .panel-header.dark > button {
      background-color: transparent;
      color: #131720;
      top: 26px; }
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header.btn-close,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button.btn-close,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button.btn-close, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header.btn-close,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button.btn-close,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button.btn-close {
        right: 16px;
        margin: 0px; }
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header.btn-help,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button.btn-help,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button.btn-help, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header.btn-help,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button.btn-help,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button.btn-help {
        right: 40px;
        margin: 0px; }
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header:hover,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button:hover,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button:hover, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header:hover,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button:hover,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button:hover {
        background-color: transparent; }
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .btn-header svg,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > .has-tooltip button svg,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.light > button svg, .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .btn-header svg,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > .has-tooltip button svg,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button.control-button svg *,
      .responsive-skin.responsive-popups-experiment .panel-header.dark > button svg {
        fill: #131720;
        stroke: none; }
    .responsive-skin.responsive-popups-experiment .panel-header.light .panel-header-title, .responsive-skin.responsive-popups-experiment .panel-header.dark .panel-header-title {
      color: #131720; }
  .responsive-skin.responsive-popups-experiment .panel-header.with-subtitle {
    height: 75px;
    min-height: 75px; }

.responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header {
  background-color: #fff; }
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header span {
    color: #000624; }
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button,
  .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button {
    background-color: transparent; }
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header:hover,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button:hover,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button:hover {
      background-color: transparent; }
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header.control-button svg *,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .btn-header svg,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button.control-button svg *,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > .has-tooltip button svg,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button.control-button svg *,
    .responsive-skin.responsive-popups-experiment .composite-popup-small-size.danger > .panel-header > button svg {
      stroke: #131720;
      fill: #131720;
      stroke-width: 1; }

.responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .panel-header {
  padding: 0 36px;
  border-bottom: none; }
  .responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .panel-header .btn-header.control-button svg * {
    stroke: #131720;
    fill: #131720;
    stroke-width: 1; }
  .responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .panel-header .btn-close {
    position: absolute;
    top: 10px;
    right: 10px; }

.classic-facelift-skin .panel-header {
  height: 55px;
  min-height: 55px;
  background-color: #fff;
  border-top-width: 6px;
  border-top-style: solid;
  border-top-color: #116dff;
  border-bottom-style: solid;
  border-bottom-color: #dfe5eb;
  border-bottom-width: 1px; }
  .classic-facelift-skin .panel-header .panel-header-title {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    color: #000624; }
  .classic-facelift-skin .panel-header .control-label {
    color: #000624; }
  .classic-facelift-skin .panel-header.dark > .btn-header,
  .classic-facelift-skin .panel-header.dark > button,
  .classic-facelift-skin .panel-header.dark > .has-tooltip button,
  .classic-facelift-skin .panel-header.light > .btn-header,
  .classic-facelift-skin .panel-header.light > button,
  .classic-facelift-skin .panel-header.light > .has-tooltip button,
  .classic-facelift-skin .panel-header.red > .btn-header,
  .classic-facelift-skin .panel-header.red > button,
  .classic-facelift-skin .panel-header.red > .has-tooltip button,
  .classic-facelift-skin .panel-header > .btn-header,
  .classic-facelift-skin .panel-header > .has-tooltip button,
  .classic-facelift-skin .panel-header > button {
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #000624; }
    .classic-facelift-skin .panel-header.dark > .btn-header:hover,
    .classic-facelift-skin .panel-header.dark > button:hover,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button:hover,
    .classic-facelift-skin .panel-header.light > .btn-header:hover,
    .classic-facelift-skin .panel-header.light > button:hover,
    .classic-facelift-skin .panel-header.light > .has-tooltip button:hover,
    .classic-facelift-skin .panel-header.red > .btn-header:hover,
    .classic-facelift-skin .panel-header.red > button:hover,
    .classic-facelift-skin .panel-header.red > .has-tooltip button:hover,
    .classic-facelift-skin .panel-header > .btn-header:hover,
    .classic-facelift-skin .panel-header > .has-tooltip button:hover,
    .classic-facelift-skin .panel-header > button:hover {
      background-color: #f7f8f8; }
    .classic-facelift-skin .panel-header.dark > .btn-header:active,
    .classic-facelift-skin .panel-header.dark > button:active,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button:active,
    .classic-facelift-skin .panel-header.light > .btn-header:active,
    .classic-facelift-skin .panel-header.light > button:active,
    .classic-facelift-skin .panel-header.light > .has-tooltip button:active,
    .classic-facelift-skin .panel-header.red > .btn-header:active,
    .classic-facelift-skin .panel-header.red > button:active,
    .classic-facelift-skin .panel-header.red > .has-tooltip button:active,
    .classic-facelift-skin .panel-header > .btn-header:active,
    .classic-facelift-skin .panel-header > .has-tooltip button:active,
    .classic-facelift-skin .panel-header > button:active {
      background-color: #dfe5eb; }
    .classic-facelift-skin .panel-header.dark > .btn-header svg,
    .classic-facelift-skin .panel-header.dark > .btn-header svg *,
    .classic-facelift-skin .panel-header.dark > .btn-header.control-button svg *,
    .classic-facelift-skin .panel-header.dark > button svg,
    .classic-facelift-skin .panel-header.dark > button svg *,
    .classic-facelift-skin .panel-header.dark > button.control-button svg *,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button svg,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button svg *,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button.control-button svg *,
    .classic-facelift-skin .panel-header.light > .btn-header svg,
    .classic-facelift-skin .panel-header.light > .btn-header svg *,
    .classic-facelift-skin .panel-header.light > .btn-header.control-button svg *,
    .classic-facelift-skin .panel-header.light > button svg,
    .classic-facelift-skin .panel-header.light > button svg *,
    .classic-facelift-skin .panel-header.light > button.control-button svg *,
    .classic-facelift-skin .panel-header.light > .has-tooltip button svg,
    .classic-facelift-skin .panel-header.light > .has-tooltip button svg *,
    .classic-facelift-skin .panel-header.light > .has-tooltip button.control-button svg *,
    .classic-facelift-skin .panel-header.red > .btn-header svg,
    .classic-facelift-skin .panel-header.red > .btn-header svg *,
    .classic-facelift-skin .panel-header.red > .btn-header.control-button svg *,
    .classic-facelift-skin .panel-header.red > button svg,
    .classic-facelift-skin .panel-header.red > button svg *,
    .classic-facelift-skin .panel-header.red > button.control-button svg *,
    .classic-facelift-skin .panel-header.red > .has-tooltip button svg,
    .classic-facelift-skin .panel-header.red > .has-tooltip button svg *,
    .classic-facelift-skin .panel-header.red > .has-tooltip button.control-button svg *,
    .classic-facelift-skin .panel-header > .btn-header svg,
    .classic-facelift-skin .panel-header > .btn-header svg *,
    .classic-facelift-skin .panel-header > .btn-header.control-button svg *,
    .classic-facelift-skin .panel-header > .has-tooltip button svg,
    .classic-facelift-skin .panel-header > .has-tooltip button svg *,
    .classic-facelift-skin .panel-header > .has-tooltip button.control-button svg *,
    .classic-facelift-skin .panel-header > button svg,
    .classic-facelift-skin .panel-header > button svg *,
    .classic-facelift-skin .panel-header > button.control-button svg * {
      fill: #000624; }
    .classic-facelift-skin .panel-header.dark > .btn-header svg mask#d,
    .classic-facelift-skin .panel-header.dark > .btn-header svg mask#b,
    .classic-facelift-skin .panel-header.dark > button svg mask#d,
    .classic-facelift-skin .panel-header.dark > button svg mask#b,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button svg mask#d,
    .classic-facelift-skin .panel-header.dark > .has-tooltip button svg mask#b,
    .classic-facelift-skin .panel-header.light > .btn-header svg mask#d,
    .classic-facelift-skin .panel-header.light > .btn-header svg mask#b,
    .classic-facelift-skin .panel-header.light > button svg mask#d,
    .classic-facelift-skin .panel-header.light > button svg mask#b,
    .classic-facelift-skin .panel-header.light > .has-tooltip button svg mask#d,
    .classic-facelift-skin .panel-header.light > .has-tooltip button svg mask#b,
    .classic-facelift-skin .panel-header.red > .btn-header svg mask#d,
    .classic-facelift-skin .panel-header.red > .btn-header svg mask#b,
    .classic-facelift-skin .panel-header.red > button svg mask#d,
    .classic-facelift-skin .panel-header.red > button svg mask#b,
    .classic-facelift-skin .panel-header.red > .has-tooltip button svg mask#d,
    .classic-facelift-skin .panel-header.red > .has-tooltip button svg mask#b,
    .classic-facelift-skin .panel-header > .btn-header svg mask#d,
    .classic-facelift-skin .panel-header > .btn-header svg mask#b,
    .classic-facelift-skin .panel-header > .has-tooltip button svg mask#d,
    .classic-facelift-skin .panel-header > .has-tooltip button svg mask#b,
    .classic-facelift-skin .panel-header > button svg mask#d,
    .classic-facelift-skin .panel-header > button svg mask#b {
      display: none; }
  .classic-facelift-skin .panel-header.panel-header-flex, .classic-facelift-skin .panel-header.panel-header-flex.has-back {
    padding-right: 9px; }
    .classic-facelift-skin .panel-header.panel-header-flex .has-tooltip, .classic-facelift-skin .panel-header.panel-header-flex.has-back .has-tooltip {
      display: flex;
      height: initial;
      width: initial;
      margin: 0; }
    .classic-facelift-skin .panel-header.panel-header-flex > .btn-header,
    .classic-facelift-skin .panel-header.panel-header-flex > .has-tooltip button,
    .classic-facelift-skin .panel-header.panel-header-flex > button, .classic-facelift-skin .panel-header.panel-header-flex.has-back > .btn-header,
    .classic-facelift-skin .panel-header.panel-header-flex.has-back > .has-tooltip button,
    .classic-facelift-skin .panel-header.panel-header-flex.has-back > button {
      margin: 0 0 0 3px;
      flex: 0 0 auto; }
      .classic-facelift-skin .panel-header.panel-header-flex > .btn-header:first-of-type,
      .classic-facelift-skin .panel-header.panel-header-flex > .has-tooltip button:first-of-type,
      .classic-facelift-skin .panel-header.panel-header-flex > button:first-of-type, .classic-facelift-skin .panel-header.panel-header-flex.has-back > .btn-header:first-of-type,
      .classic-facelift-skin .panel-header.panel-header-flex.has-back > .has-tooltip button:first-of-type,
      .classic-facelift-skin .panel-header.panel-header-flex.has-back > button:first-of-type {
        margin: 0 0 0 6px; }
      .classic-facelift-skin .panel-header.panel-header-flex > .btn-header.btn-header-back,
      .classic-facelift-skin .panel-header.panel-header-flex > .has-tooltip button.btn-header-back,
      .classic-facelift-skin .panel-header.panel-header-flex > button.btn-header-back, .classic-facelift-skin .panel-header.panel-header-flex.has-back > .btn-header.btn-header-back,
      .classic-facelift-skin .panel-header.panel-header-flex.has-back > .has-tooltip button.btn-header-back,
      .classic-facelift-skin .panel-header.panel-header-flex.has-back > button.btn-header-back {
        margin: 1px 6px 0 0; }
        .classic-facelift-skin .panel-header.panel-header-flex > .btn-header.btn-header-back svg,
        .classic-facelift-skin .panel-header.panel-header-flex > .has-tooltip button.btn-header-back svg,
        .classic-facelift-skin .panel-header.panel-header-flex > button.btn-header-back svg, .classic-facelift-skin .panel-header.panel-header-flex.has-back > .btn-header.btn-header-back svg,
        .classic-facelift-skin .panel-header.panel-header-flex.has-back > .has-tooltip button.btn-header-back svg,
        .classic-facelift-skin .panel-header.panel-header-flex.has-back > button.btn-header-back svg {
          fill: #000624; }
  .classic-facelift-skin .panel-header.panel-header-flex.has-back {
    padding-left: 9px; }
  .classic-facelift-skin .panel-header.red {
    background-color: #fff; }
    .classic-facelift-skin .panel-header.red span {
      color: #000624; }
  .classic-facelift-skin .panel-header.red, .classic-facelift-skin .panel-header.theme-destructive {
    border-top-color: #e62214; }
  .classic-facelift-skin .panel-header.theme-premium {
    border-top-color: #9a27d5; }
  .classic-facelift-skin .panel-header:not(.without-stripe).theme-ai {
    position: relative; }
    .classic-facelift-skin .panel-header:not(.without-stripe).theme-ai:before {
      content: '';
      position: absolute;
      top: -6px;
      left: 0;
      width: 100%;
      height: 6px;
      background: linear-gradient(90deg, #0021FF 0%, #116DFF 50%, #5FF2AE 100%); }
  .classic-facelift-skin .panel-header.without-stripe {
    height: 49px;
    min-height: 49px;
    border-top: none; }
    .classic-facelift-skin .panel-header.without-stripe.with-subtitle {
      height: 63px;
      min-height: 63px; }
  .classic-facelift-skin .panel-header.without-divider {
    border-bottom: none; }
  .classic-facelift-skin .panel-header.with-subtitle {
    height: 69px;
    min-height: 69px; }

.classic-facelift-skin .composite-popup-small-size.danger > .panel-header {
  border-top-color: #e62214;
  background-color: #fff; }
  .classic-facelift-skin .composite-popup-small-size.danger > .panel-header span {
    color: #000624; }
  .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header,
  .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button,
  .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button {
    background-color: #fff;
    color: #000624; }
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header:hover,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button:hover,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button:hover {
      background-color: #f7f8f8; }
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header:active,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button:active,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button:active {
      background-color: #dfe5eb; }
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header svg,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .btn-header.control-button svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button svg,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > .has-tooltip button.control-button svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button svg,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button svg *,
    .classic-facelift-skin .composite-popup-small-size.danger > .panel-header > button.control-button svg * {
      fill: #000624; }

.panel-header {
  background-color: #116dff;
  height: 54px;
  min-height: 54px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  .panel-header.small {
    height: 48px;
    min-height: 48px; }
    .panel-header.small .panel-header-title {
      padding-left: 14px; }
    .panel-header.small > .btn-header,
    .panel-header.small > button {
      width: 21px;
      height: 21px; }
      .panel-header.small > .btn-header.btn-help, .panel-header.small > .btn-header.help,
      .panel-header.small > button.btn-help,
      .panel-header.small > button.help {
        right: 49px; }
      .panel-header.small > .btn-header.btn-close, .panel-header.small > .btn-header.close,
      .panel-header.small > button.btn-close,
      .panel-header.small > button.close {
        right: 14px; }
  .panel-header .panel-header-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    padding-left: 24px;
    overflow: hidden;
    position: absolute;
    color: #fff;
    max-width: 75%;
    white-space: nowrap; }
    .panel-header .panel-header-title > .panel-header-title-content {
      display: inline-flex;
      align-items: center; }
    .panel-header .panel-header-title .panel-header-title-span {
      line-height: 24px; }
  .panel-header:not(.default-cursor) .panel-header-title {
    cursor: move; }
  .panel-header > .btn-header,
  .panel-header > button {
    padding: 0;
    border-radius: 50%;
    border: 0;
    position: absolute;
    width: 25px;
    height: 25px;
    cursor: pointer;
    top: 50%;
    margin-top: -10px;
    background-color: #0057e1;
    color: #fff;
    box-sizing: border-box;
    font: inherit; }
    .panel-header > .btn-header:hover,
    .panel-header > button:hover {
      background-color: #003790; }
    .panel-header > .btn-header:focus,
    .panel-header > button:focus {
      outline: 0; }
    .panel-header > .btn-header.btn-help, .panel-header > .btn-header.help,
    .panel-header > button.btn-help,
    .panel-header > button.help {
      right: 53px; }
    .panel-header > .btn-header.btn-close, .panel-header > .btn-header.close,
    .panel-header > button.btn-close,
    .panel-header > button.close {
      right: 18px; }
  .panel-header.dark > .btn-header,
  .panel-header.dark > .has-tooltip button,
  .panel-header.dark > button {
    background-color: #0057e1;
    color: #ffffff; }
    .panel-header.dark > .btn-header:hover,
    .panel-header.dark > .has-tooltip button:hover,
    .panel-header.dark > button:hover {
      background-color: #003790; }
    .panel-header.dark > .btn-header svg,
    .panel-header.dark > .has-tooltip button svg,
    .panel-header.dark > button svg {
      fill: #ffffff; }
  .panel-header.light {
    border-radius: 0;
    background-color: #fff; }
    .panel-header.light span {
      color: #000624; }
    .panel-header.light > .btn-header,
    .panel-header.light > .has-tooltip button,
    .panel-header.light > button {
      background-color: #d6e6fe;
      color: #116dff; }
      .panel-header.light > .btn-header:hover,
      .panel-header.light > .has-tooltip button:hover,
      .panel-header.light > button:hover {
        background-color: #e7f0ff; }
      .panel-header.light > .btn-header.control-button svg *,
      .panel-header.light > .btn-header svg,
      .panel-header.light > .has-tooltip button.control-button svg *,
      .panel-header.light > .has-tooltip button svg,
      .panel-header.light > button.control-button svg *,
      .panel-header.light > button svg {
        fill: #116dff; }
  .panel-header.red,
  .composite-popup-small-size.danger > .panel-header {
    border-radius: 0;
    background-color: #e62214; }
    .panel-header.red span,
    .composite-popup-small-size.danger > .panel-header span {
      color: #fff; }
    .panel-header.red > .btn-header,
    .panel-header.red > .has-tooltip button,
    .panel-header.red > button,
    .composite-popup-small-size.danger > .panel-header > .btn-header,
    .composite-popup-small-size.danger > .panel-header > .has-tooltip button,
    .composite-popup-small-size.danger > .panel-header > button {
      background-color: #c3504d;
      color: #fff; }
      .panel-header.red > .btn-header:hover,
      .panel-header.red > .has-tooltip button:hover,
      .panel-header.red > button:hover,
      .composite-popup-small-size.danger > .panel-header > .btn-header:hover,
      .composite-popup-small-size.danger > .panel-header > .has-tooltip button:hover,
      .composite-popup-small-size.danger > .panel-header > button:hover {
        background-color: #942b28; }
      .panel-header.red > .btn-header.control-button svg *,
      .panel-header.red > .btn-header svg,
      .panel-header.red > .has-tooltip button.control-button svg *,
      .panel-header.red > .has-tooltip button svg,
      .panel-header.red > button.control-button svg *,
      .panel-header.red > button svg,
      .composite-popup-small-size.danger > .panel-header > .btn-header.control-button svg *,
      .composite-popup-small-size.danger > .panel-header > .btn-header svg,
      .composite-popup-small-size.danger > .panel-header > .has-tooltip button.control-button svg *,
      .composite-popup-small-size.danger > .panel-header > .has-tooltip button svg,
      .composite-popup-small-size.danger > .panel-header > button.control-button svg *,
      .composite-popup-small-size.danger > .panel-header > button svg {
        fill: #fff; }
  .panel-header.panel-header-flex {
    display: flex;
    align-items: center;
    padding-right: 18px;
    padding-left: 24px; }
    .panel-header.panel-header-flex.small {
      padding-right: 14px;
      padding-left: 14px; }
    .panel-header.panel-header-flex .panel-header-title {
      padding: 0;
      position: static;
      max-width: none;
      flex: 1 1 auto; }
    .panel-header.panel-header-flex .btn-header,
    .panel-header.panel-header-flex > .has-tooltip button,
    .panel-header.panel-header-flex > button {
      margin: 0 0 0 9px; }
      .panel-header.panel-header-flex .btn-header:first-of-type,
      .panel-header.panel-header-flex > .has-tooltip button:first-of-type,
      .panel-header.panel-header-flex > button:first-of-type {
        margin-left: 6px; }
    .panel-header.panel-header-flex > .btn-header,
    .panel-header.panel-header-flex > .has-tooltip > .btn-header,
    .panel-header.panel-header-flex > button {
      position: static;
      flex: 0 0 auto; }
    .panel-header.panel-header-flex.has-back {
      padding-right: 18px;
      padding-left: 18px; }
      .panel-header.panel-header-flex.has-back > .has-tooltip {
        height: 25px;
        margin: 0 12px 0 0; }
        .panel-header.panel-header-flex.has-back > .has-tooltip > .btn-header-back {
          margin: 0; }
  .panel-header.with-subtitle {
    height: 69px;
    min-height: 69px; }
    .panel-header.with-subtitle.small {
      height: 63px;
      min-height: 63px; }
    .panel-header.with-subtitle > button.control-button.btn-header,
    .panel-header.with-subtitle.has-back > button.control-button.btn-header,
    .panel-header.with-subtitle.has-back > .has-tooltip button.control-button.btn-header {
      margin-bottom: 13px; }
      .panel-header.with-subtitle > button.control-button.btn-header.btn-help,
      .panel-header.with-subtitle.has-back > button.control-button.btn-header.btn-help,
      .panel-header.with-subtitle.has-back > .has-tooltip button.control-button.btn-header.btn-help {
        margin-bottom: 13px; }
    .panel-header.with-subtitle .panel-header-subtitle {
      display: flex;
      gap: 3px;
      padding-top: 2px; }

.responsive-skin .panel-footer {
  border-top: 1px solid #e5e5e5;
  height: 60px;
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 6, 36, 0.13); }
  .responsive-skin .panel-footer .footer-btn {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }

.panel-footer {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: #9a27d5;
  display: flex;
  align-items: center;
  justify-content: center; }

.responsive-skin.responsive-popups-experiment .composite-popup .panel-header-centered-text {
  position: static;
  min-height: auto; }

.responsive-skin.responsive-popups-experiment .panel-header-centered-text {
  background-color: #fff;
  color: #000624;
  padding: 32px 36px 0; }
  .responsive-skin.responsive-popups-experiment .panel-header-centered-text > .title {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 24px;
    line-height: 32px;
    -webkit-font-smoothing: antialiased; }
    .responsive-skin.responsive-popups-experiment .panel-header-centered-text > .title.t09 {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased; }
      .responsive-skin.responsive-popups-experiment .panel-header-centered-text > .title.t09 + .subtitle {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
  .responsive-skin.responsive-popups-experiment .panel-header-centered-text > .subtitle {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    margin-top: 16px; }
  .responsive-skin.responsive-popups-experiment .panel-header-centered-text > .btn-close {
    top: 10px;
    right: 10px; }
  .responsive-skin.responsive-popups-experiment .panel-header-centered-text .control-button {
    background-color: transparent; }
    .responsive-skin.responsive-popups-experiment .panel-header-centered-text .control-button svg path {
      stroke: #131720;
      fill: #131720;
      stroke-width: 1; }

.panel-header-centered-text {
  text-align: center;
  width: 100%;
  background-color: #116dff;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 138px;
  padding: 43px 18px 0; }
  .panel-header-centered-text > .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 30px;
    -webkit-font-smoothing: antialiased; }
  .panel-header-centered-text > .subtitle {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    margin-top: 11px; }
  .panel-header-centered-text > .btn-help {
    top: 18px;
    right: 51px; }
  .panel-header-centered-text > .btn-close {
    top: 18px;
    right: 18px; }

.responsive-skin.responsive-popups-experiment .panel-header-illustration > .blue-strip {
  display: none; }

.panel-header-illustration {
  text-align: center;
  width: 100%;
  position: relative; }
  .panel-header-illustration > .blue-strip {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: #116dff; }
  .panel-header-illustration > .illustration-container {
    position: relative;
    z-index: 1; }

.responsive-skin.responsive-popups-experiment .composite-action-set-horizontal {
  padding: 20px 24px 24px 24px; }
  .responsive-skin.responsive-popups-experiment .composite-action-set-horizontal > .control-button:nth-of-type(1) {
    margin-left: 0; }

.responsive-skin.responsive-popups-experiment .composite-popup-small-size > .composite-action-set-horizontal {
  justify-content: center; }

.responsive-skin.responsive-popups-experiment .composite-popup-medium-size > .composite-action-set-horizontal {
  justify-content: center; }

.responsive-skin.responsive-popups-experiment .composite-popup-large-size > .composite-action-set-horizontal {
  justify-content: center; }

.responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .composite-action-set-horizontal {
  justify-content: flex-start; }
  .responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .composite-action-set-horizontal button:nth-of-type(1) {
    margin-left: 0; }

.responsive-skin .composite-action-set-horizontal {
  padding: 12px 24px 20px 24px; }
  .responsive-skin .composite-action-set-horizontal > .control-button:nth-of-type(2) {
    margin-left: 12px; }

.composite-action-set-horizontal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px; }
  .composite-action-set-horizontal.small {
    padding: 0 14px;
    height: 48px; }

.classic-facelift-skin .control-angle-input > .circle {
  border: solid 4px #dfe5eb; }
  .classic-facelift-skin .control-angle-input > .circle > .circle-center {
    background-color: #dfe5eb; }
  .classic-facelift-skin .control-angle-input > .circle:active:after {
    border: solid 1px #116dff; }
  .classic-facelift-skin .control-angle-input > .circle:after {
    border: solid 1px #dfe5eb; }
  .classic-facelift-skin .control-angle-input > .circle > .knob {
    background-color: #116dff;
    border: solid 4px #fff;
    box-shadow: 0 1px 3px 0 #3b4057; }

.classic-facelift-skin .control-angle-input > .circle:active > .circle-center {
  background-color: #116dff; }

.classic-facelift-skin .control-angle-input > .circle:hover > .knob {
  background-color: #80b1ff;
  box-shadow: 0 0 8px 1px #80b1ff; }

.classic-facelift-skin .control-angle-input > .circle:active > .knob {
  background-color: #116dff;
  box-shadow: 0 0 8px 1px #80b1ff; }

.responsive-skin .control-angle-input {
  padding-left: 0; }

.control-angle-input {
  display: flex;
  align-items: center;
  padding-left: 54px; }
  .control-angle-input > .circle {
    width: 72px;
    height: 72px;
    position: relative;
    border-radius: 50%;
    border: solid 4px #dfe5eb;
    box-sizing: border-box;
    margin-right: 12px; }
    .disabled.control-angle-input > .circle {
      border-color: #ececec; }
    .control-angle-input > .circle > .circle-center {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      background-color: #dfe5eb;
      top: calc(50% - 3px);
      left: calc(50% - 3px); }
      .control-angle-input > .circle:active > .circle-center {
        background-color: #116dff; }
      .disabled.control-angle-input > .circle > .circle-center {
        background-color: #ececec; }
    .control-angle-input > .circle:active:after {
      border: solid 1px #116dff; }
    .control-angle-input > .circle:after {
      content: '';
      position: absolute;
      left: 31px;
      z-index: -1;
      width: 0;
      height: 32px;
      border: solid 1px #dfe5eb; }
      .disabled.control-angle-input > .circle:after {
        border-color: #ececec; }
    .control-angle-input > .circle > .knob {
      width: 8px;
      height: 8px;
      position: absolute;
      cursor: pointer;
      background-color: #116dff;
      border-radius: 50%;
      border: solid 4px #fff;
      box-shadow: 0 1px 3px 0 #3b4057;
      box-sizing: border-box;
      top: -5px;
      left: calc(50% - 4px); }
      .control-angle-input > .circle:hover > .knob {
        background-color: #80b1ff;
        box-shadow: 0 0 8px 1px #80b1ff; }
      .control-angle-input > .circle:active > .knob {
        background-color: #116dff;
        box-shadow: 0 0 8px 1px #80b1ff; }
      .disabled.control-angle-input > .circle > .knob {
        background-color: #bcbcbc;
        box-shadow: 0 1px 3px 0 #939393;
        cursor: default; }

.composite-angle-input-labeled {
  padding: 17px 24px 24px; }

.responsive-skin .composite-action-set-horizontal-spaced {
  padding: 12px 24px 20px; }

.composite-action-set-horizontal-spaced {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px; }

.composite-action-set-vertical {
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.responsive-skin .composite-action-with-symbol {
  padding-top: 12px;
  padding-bottom: 12px; }

.composite-action-with-symbol {
  padding: 32px 24px 27px; }

.responsive-skin .control-info-icon:not(.error) > svg path {
  fill: #42454C; }

.responsive-skin .control-info-icon:not(.error) > svg:hover path {
  fill: #116dff; }

.responsive-skin .control-info-icon.error .symbol-input-validation-error {
  fill: #ee4437; }

.responsive-skin .info-icon-content > .info-icon-title {
  color: #131720;
  font-size: 14px;
  line-height: 20px;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium); }

.responsive-skin .info-icon-content > .info-icon-text {
  color: #42454C;
  font-size: 12px;
  line-height: 16px;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular); }

.responsive-skin .info-icon-content > .info-icon-link {
  color: #116dff;
  font-size: 12px;
  line-height: 16px;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  text-decoration: none; }

.responsive-skin .info-icon-content > .info-icon-illustration {
  margin-bottom: 18px; }

.responsive-skin .composite-collapse-label-with-info-icon > .info-icon-tooltip {
  display: none;
  position: absolute;
  right: 48px;
  top: 12px; }
  .responsive-skin .composite-collapse-label-with-info-icon > .info-icon-tooltip:hover {
    display: flex; }

.responsive-skin .composite-collapse-label-with-info-icon .control-collapse-label:hover:not(.child-has-hover) ~ .info-icon-tooltip {
  display: flex; }

.responsive-skin .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
  right: 24px; }

.responsive-skin .composite-section-divider-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: 9px; }

.responsive-skin.dark-mode .info-icon-content > .info-icon-title {
  color: #f8faff; }

.responsive-skin.dark-mode .info-icon-content > .info-icon-text {
  color: #f8faff; }

.classic-facelift-skin .control-info-icon:not(.error) > svg path {
  fill: #868aa5; }
  .composite-banner .classic-facelift-skin .control-info-icon:not(.error) > svg path {
    fill: #fff; }

.classic-facelift-skin .control-info-icon:not(.error) > svg:hover path {
  fill: #116dff; }
  .composite-banner .classic-facelift-skin .control-info-icon:not(.error) > svg:hover path {
    fill: #80b1ff; }
  .composite-banner-premium .classic-facelift-skin .control-info-icon:not(.error) > svg:hover path {
    fill: #9a27d5; }

.classic-facelift-skin .info-icon-content > .info-icon-title {
  color: #3b4057; }

.classic-facelift-skin .info-icon-content > .info-icon-text {
  color: #3b4057; }

.classic-facelift-skin .info-icon-content > .info-icon-link {
  color: #116dff; }

.control-info-icon {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  height: 18px; }
  .control-info-icon:not(.error) > svg circle {
    fill: transparent; }
  .control-info-icon:not(.error) > svg path {
    fill: #868aa5; }
    .composite-banner .control-info-icon:not(.error) > svg path {
      fill: #fff; }
  .control-info-icon:not(.error) > svg:hover path {
    fill: #116dff; }
    .composite-banner .control-info-icon:not(.error) > svg:hover path {
      fill: #80b1ff; }
    .composite-banner-premium .control-info-icon:not(.error) > svg:hover path {
      fill: #9a27d5; }
  .composite-with-info-icon > .info-icon-tooltip .control-info-icon:not(.error) {
    visibility: hidden; }
  .composite-with-info-icon:hover > .info-icon-tooltip .control-info-icon:not(.error) {
    visibility: visible; }

.info-icon-content > .info-icon-title {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  margin-bottom: 12px; }

.info-icon-content > .info-icon-text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  display: inline; }

.info-icon-content > .info-icon-text::after {
  display: inline;
  content: ' '; }

.info-icon-content > .info-icon-link {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #116dff;
  display: inline;
  text-decoration: underline;
  cursor: pointer; }

.info-icon-content > .info-icon-illustration {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.responsive-skin .composite-action-with-symbol-labeled {
  padding-top: 12px;
  padding-bottom: 12px; }

.composite-action-with-symbol-labeled {
  padding: 18px 24px; }

.composite-corner-radius-input {
  width: 240px; }

.composite-corner-radius-input-labeled {
  position: relative; }
  .composite-corner-radius-input-labeled .has-tooltip.info-icon-tooltip {
    position: absolute;
    opacity: 0;
    right: 12px;
    top: 12px; }
  .composite-corner-radius-input-labeled .has-tooltip.info-icon-tooltip + .control-label {
    max-width: calc(100% - 18px); }

.composite-corner-radius-input-labeled:hover .has-tooltip.info-icon-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 1; }

.responsive-skin .composite-corner-radius-input-labeled .control-label {
  color: #131720; }

.classic-facelift-skin .control-date-picker {
  background-color: #fff;
  border-radius: 4px; }
  .classic-facelift-skin .control-date-picker .dp-header {
    border-bottom-color: #f7f8f8; }
    .classic-facelift-skin .control-date-picker .dp-header .dp-navigate:hover {
      background-color: #f7f8f8; }
    .classic-facelift-skin .control-date-picker .dp-header .dp-navigate:active {
      background-color: #116dff; }
      .classic-facelift-skin .control-date-picker .dp-header .dp-navigate:active .greater-than-icon {
        fill: #fff; }
    .classic-facelift-skin .control-date-picker .dp-header .dp-navigate .greater-than-icon {
      fill: #000624; }
    .classic-facelift-skin .control-date-picker .dp-header .dp-title {
      color: #116dff; }
  .classic-facelift-skin .control-date-picker .dp-body table {
    color: #3b4057; }
  .classic-facelift-skin .control-date-picker .dp-day:not(.dp-day-selected):not(.dp-day-disabled):hover {
    background-color: #e7f0ff; }
  .classic-facelift-skin .control-date-picker .dp-day.dp-day-disabled.dp-day-selected {
    color: #fff; }
  .classic-facelift-skin .control-date-picker .dp-day-not-in-current-month {
    color: #dfe5eb; }
  .classic-facelift-skin .control-date-picker .dp-day-selected,
  .classic-facelift-skin .control-date-picker .dp-day-selected:hover :not(.dp-day-disabled) {
    background-color: #116dff;
    color: #fff; }
  .classic-facelift-skin .control-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border-color: #116dff; }
  .classic-facelift-skin .control-date-picker .dp-footer {
    border-top: 1px solid #f7f8f8; }
    .classic-facelift-skin .control-date-picker .dp-footer .delete-btn {
      background-color: #fff; }
      .classic-facelift-skin .control-date-picker .dp-footer .delete-btn path {
        fill: #3b4057; }
      .classic-facelift-skin .control-date-picker .dp-footer .delete-btn:hover path {
        fill: #e62214; }
    .classic-facelift-skin .control-date-picker .dp-footer .goto-today,
    .classic-facelift-skin .control-date-picker .dp-footer .goto-selection {
      color: #116dff; }
      .classic-facelift-skin .control-date-picker .dp-footer .goto-today:hover,
      .classic-facelift-skin .control-date-picker .dp-footer .goto-selection:hover {
        background-color: #e7f0ff; }

.responsive-skin .control-date-picker {
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer; }
  .responsive-skin .control-date-picker .dp-header {
    border-bottom-color: #e5e5e5; }
    .responsive-skin .control-date-picker .dp-header .dp-navigate:hover {
      background-color: #fff; }
    .responsive-skin .control-date-picker .dp-header .dp-navigate:active .greater-than-icon {
      fill: #f4f5f8; }
    .responsive-skin .control-date-picker .dp-header .dp-navigate .greater-than-icon {
      fill: #131720; }
    .responsive-skin .control-date-picker .dp-header .dp-title {
      color: #116dff; }
  .responsive-skin .control-date-picker .dp-body table {
    color: #2B2E36; }
  .responsive-skin .control-date-picker .dp-day:not(.dp-day-selected):not(.dp-day-disabled):hover {
    background-color: #dce9ff; }
  .responsive-skin .control-date-picker .dp-day.dp-day-disabled.dp-day-selected {
    color: #f4f5f8; }
  .responsive-skin .control-date-picker .dp-day-not-in-current-month {
    color: #dfe5eb; }
  .responsive-skin .control-date-picker .dp-day-selected,
  .responsive-skin .control-date-picker .dp-day-selected:hover:not(.dp-day-disabled) {
    background-color: #116dff;
    color: #f4f5f8; }
  .responsive-skin .control-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border-color: #116dff; }
  .responsive-skin .control-date-picker .dp-footer {
    border-top: 1px solid #e5e5e5; }
    .responsive-skin .control-date-picker .dp-footer .delete-btn {
      background-color: #fff; }
      .responsive-skin .control-date-picker .dp-footer .delete-btn path {
        fill: #2B2E36; }
      .responsive-skin .control-date-picker .dp-footer .delete-btn:active, .responsive-skin .control-date-picker .dp-footer .delete-btn:focus {
        background-color: #fff; }
      .responsive-skin .control-date-picker .dp-footer .delete-btn:hover {
        background-color: #fff; }
        .responsive-skin .control-date-picker .dp-footer .delete-btn:hover path {
          fill: #131720; }
    .responsive-skin .control-date-picker .dp-footer .goto-selection,
    .responsive-skin .control-date-picker .dp-footer .goto-today {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold); }
    .responsive-skin .control-date-picker .dp-footer .goto-selection:disabled {
      background-color: transparent;
      color: #bebebe; }
    .responsive-skin .control-date-picker .dp-footer .goto-today:not(:disabled),
    .responsive-skin .control-date-picker .dp-footer .goto-selection:not(:disabled) {
      color: #116dff;
      background-color: transparent; }
      .responsive-skin .control-date-picker .dp-footer .goto-today:not(:disabled):hover:not(:disabled), .responsive-skin .control-date-picker .dp-footer .goto-today:not(:disabled):active:not(:disabled),
      .responsive-skin .control-date-picker .dp-footer .goto-selection:not(:disabled):hover:not(:disabled),
      .responsive-skin .control-date-picker .dp-footer .goto-selection:not(:disabled):active:not(:disabled) {
        background-color: transparent;
        color: #0f62e6; }

.control-date-picker {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  -webkit-user-select: none;
          user-select: none; }
  .control-date-picker .dp-header {
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #f7f8f8; }
    .control-date-picker .dp-header .dp-previous {
      transform: rotate(-180deg); }
    .control-date-picker .dp-header .dp-navigate {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .control-date-picker .dp-header .dp-navigate:hover {
        background-color: #e7f0ff; }
      .control-date-picker .dp-header .dp-navigate:active {
        background-color: #116dff; }
        .control-date-picker .dp-header .dp-navigate:active * {
          fill: #fff; }
    .control-date-picker .dp-header .dp-title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      flex: 1;
      font-size: 14px;
      color: #116dff; }
  .control-date-picker .dp-body table {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    text-align: center;
    width: calc(100% - 24px);
    margin: 8px 12px;
    font-size: 13px;
    color: #3b4057;
    border-spacing: 0; }
    .control-date-picker .dp-body table td {
      padding: 0; }
      .control-date-picker .dp-body table td > div {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 32px; }
    .control-date-picker .dp-body table .dp-day-name {
      font-weight: bold; }
  .control-date-picker .dp-day {
    cursor: pointer; }
    .control-date-picker .dp-day:not(.dp-day-selected):not(.dp-day-disabled):hover {
      background-color: #e7f0ff; }
    .control-date-picker .dp-day.dp-day-disabled {
      cursor: not-allowed;
      color: #acafc4; }
      .control-date-picker .dp-day.dp-day-disabled.dp-day-selected {
        background-color: #bcbcbc;
        color: #fff; }
  .control-date-picker .dp-day-not-in-current-month {
    color: #dfe5eb; }
  .control-date-picker .dp-day-selected,
  .control-date-picker .dp-day-selected:hover :not(.dp-day-disabled) {
    background-color: #116dff;
    color: #fff; }
  .control-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border: 1px solid #116dff; }
  .control-date-picker .dp-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #f7f8f8;
    height: 32px;
    padding: 12px; }
    .control-date-picker .dp-footer .delete-btn {
      background-color: #fff;
      padding: 0 12px;
      margin-right: 12px;
      height: 32px;
      vertical-align: middle; }
      .control-date-picker .dp-footer .delete-btn path {
        fill: #3b4057; }
      .control-date-picker .dp-footer .delete-btn:hover path {
        fill: #e62214; }
    .control-date-picker .dp-footer .goto-today,
    .control-date-picker .dp-footer .goto-selection {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      background-color: transparent;
      color: #116dff;
      display: inline-block;
      border-radius: 32px;
      line-height: 32px;
      height: 32px;
      padding: 0 20px;
      cursor: pointer; }
      .control-date-picker .dp-footer .goto-today:hover,
      .control-date-picker .dp-footer .goto-selection:hover {
        background-color: #e7f0ff; }
      .control-date-picker .dp-footer .goto-today:disabled,
      .control-date-picker .dp-footer .goto-selection:disabled {
        cursor: inherit;
        color: #bcbcbc;
        background-color: transparent; }
    .control-date-picker .dp-footer .goto-selection {
      float: right; }

.composite-date-picker-labeled {
  padding: 12px 24px 18px 24px; }
  .composite-date-picker-labeled.composite-with-info-icon > .info-icon-tooltip {
    top: 18px; }

.classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.selected:not(.disabled) .control-dock-direction.top, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.selected:not(.disabled) .control-dock-direction.bottom, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.selected:not(.disabled) .control-dock-direction.right, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.selected:not(.disabled) .control-dock-direction.left {
  border-color: #116dff;
  background: #d6e6fe; }

.classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.selected:not(.disabled) .control-dock-direction.middle {
  background: #116dff; }

.classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.disabled .control-dock-direction.top, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.disabled .control-dock-direction.bottom, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.disabled .control-dock-direction.right, .classic-facelift-skin .control-radio-buttons-list .radio-button-wrapper.disabled .control-dock-direction.left {
  background: #f0f0f0;
  border-color: #d5d5d5; }

.classic-facelift-skin .control-dock-direction {
  background: #e7f0ff; }
  .classic-facelift-skin .control-dock-direction.top {
    border-top-color: #a8caff; }
    .classic-facelift-skin .control-dock-direction.top:hover {
      border-top-color: #5999ff; }
  .classic-facelift-skin .control-dock-direction.left {
    border-left-color: #a8caff; }
    .classic-facelift-skin .control-dock-direction.left:hover {
      border-left-color: #5999ff; }
  .classic-facelift-skin .control-dock-direction.middle:hover {
    background: #5999ff; }
  .classic-facelift-skin .control-dock-direction.right {
    border-right-color: #a8caff; }
    .classic-facelift-skin .control-dock-direction.right:hover {
      border-right-color: #5999ff; }
  .classic-facelift-skin .control-dock-direction.bottom {
    border-bottom-color: #a8caff; }
    .classic-facelift-skin .control-dock-direction.bottom:hover {
      border-bottom-color: #5999ff; }

.control-dock-direction {
  box-sizing: border-box;
  background: #e7f0ff;
  width: 60px;
  height: 42px; }
  .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).top, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).right, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).bottom, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).left {
    border-color: #116dff;
    background: #d6e6fe; }
  .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).middle {
    background: #116dff; }
  .control-dock-direction.top {
    margin-bottom: 1px;
    margin-right: 1px;
    border-top: 12px solid #a8caff; }
    .control-dock-direction.top:hover {
      border-top-color: #5999ff; }
    .control-dock-direction.top.left {
      border-top-left-radius: 5px; }
    .control-dock-direction.top.right {
      border-top-right-radius: 5px; }
  .control-dock-direction.left {
    border-left: 12px solid #a8caff;
    margin-left: 0; }
    .control-dock-direction.left:hover {
      border-left-color: #5999ff; }
  .control-dock-direction.middle {
    margin-left: 1px;
    margin-right: 1px; }
    .control-dock-direction.middle:hover {
      background: #5999ff; }
  .control-dock-direction.right {
    border-right: 12px solid #a8caff;
    margin-right: 0; }
    .control-dock-direction.right:hover {
      border-right-color: #5999ff; }
  .control-dock-direction.bottom {
    margin-top: 1px;
    margin-right: 1px;
    border-bottom: 12px solid #a8caff; }
    .control-dock-direction.bottom:hover {
      border-bottom-color: #5999ff; }
    .control-dock-direction.bottom.left {
      border-bottom-left-radius: 5px; }
    .control-dock-direction.bottom.right {
      border-bottom-right-radius: 5px;
      margin-right: 0; }
  .control-dock-direction.disabled {
    pointer-events: none;
    cursor: pointer;
    background: #f0f0f0;
    border-color: #d5d5d5; }
    .control-dock-direction.disabled:hover {
      background: #f0f0f0; }
    .control-dock-direction.disabled.middle {
      background: #fff; }
      .control-dock-direction.disabled.middle:hover {
        background: #fff; }

.classic-facelift-skin .control-dock > .grid.horizontal {
  border-top-color: #80b1ff;
  border-bottom-color: #80b1ff; }

.classic-facelift-skin .control-dock > .grid.vertical {
  border-left-color: #80b1ff;
  border-right-color: #80b1ff; }

.control-dock {
  position: relative;
  align-self: center;
  width: 182px; }
  .control-dock > .grid {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none; }
    .disabled.control-dock > .grid.horizontal, .disabled.control-dock > .grid.vertical {
      border-color: #d5d5d5; }
    .control-dock > .grid.horizontal {
      border-top: 1px dotted #80b1ff;
      border-bottom: 1px dotted #80b1ff;
      height: 44px;
      top: 42px;
      left: 12px;
      right: 12px; }
    .control-dock > .grid.vertical {
      border-left: 1px dotted #80b1ff;
      border-right: 1px dotted #80b1ff;
      width: 62px;
      left: 60px;
      top: 12px;
      bottom: 12px; }

.composite-dock-labeled {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px; }

.responsive-skin .composite-dropdown-labeled {
  padding: 12px 24px; }
  .responsive-skin .composite-dropdown-labeled .control-label-base {
    line-height: 14px;
    font-size: 12px;
    letter-spacing: 0; }
  .responsive-skin .composite-dropdown-labeled .has-tooltip.info-icon-tooltip {
    right: 24px; }

.composite-dropdown-labeled {
  display: flex;
  flex-direction: column;
  padding: 14px 24px; }

.input-borders-experiment .composite-dropdown-labeled {
  padding: 14px 24px 18px 24px; }

.composite-dropdown {
  padding: 14px 24px 10px; }

.input-borders-experiment .composite-dropdown {
  padding: 18px 24px; }

.composite-dropdown-with-button {
  display: flex;
  align-items: center; }
  .composite-dropdown-with-button::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #dfe5eb; }

.input-borders-experiment .composite-dropdown-with-button {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px; }
  .input-borders-experiment .composite-dropdown-with-button::before {
    content: none;
    margin-left: unset;
    margin-right: unset;
    height: unset;
    width: unset;
    background-color: unset; }

.composite-dropdown-with-button-labeled {
  padding: 14px 24px; }

.responsive-skin .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item, .responsive-skin .control-horizontal-tabs.arrowed-no-line .control-radio-buttons-list > .radio-button-item {
  height: 44px; }
  .responsive-skin .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item:not(:last-child), .responsive-skin .control-horizontal-tabs.arrowed-no-line .control-radio-buttons-list > .radio-button-item:not(:last-child) {
    border-right: none; }

.responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: #42454C;
  border: none; }
  .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper::after, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #e5e5e5;
    bottom: 0; }
  .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper.selected, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper.selected {
    color: #116dff; }
    .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper.selected::after, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper.selected::after {
      height: 2px;
      background-color: #116dff; }
  .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper.disabled, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper.disabled {
    color: #bebebe; }
    .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper.disabled.selected::after, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper.disabled.selected::after {
      height: 2px;
      background-color: #bebebe; }
  .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper:hover:not(.selected), .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper:hover:not(.selected) {
    background-color: #fff; }
    .responsive-skin .control-horizontal-tabs.tabbed .radio-button-wrapper:hover:not(.selected):after, .responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper:hover:not(.selected):after {
      background-color: #e2e3ea; }

.responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper::after {
  all: unset;
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0; }

.responsive-skin .control-horizontal-tabs.arrowed-no-line .radio-button-wrapper:hover:not(.selected) {
  background-color: #f4f5f8; }

.responsive-skin .control-horizontal-tabs.arrowed .radio-button-wrapper:after {
  border-color: transparent transparent #116dff; }

.responsive-skin .control-horizontal-tabs.arrowed .radio-button-wrapper.selected {
  border-bottom-color: #116dff; }

.responsive-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow {
  height: 43px;
  background-color: #fff; }
  .responsive-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow svg path {
    fill: #42454C; }
  .responsive-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow:hover {
    background-color: #f4f5f8; }

.classic-facelift-skin .right-arrow-container .tabs-with-arrows-right-arrow svg * {
  fill: #116dff; }

.classic-facelift-skin .left-arrow-container svg * {
  fill: #116dff; }

.classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item:not(:last-child) {
  border-right-color: #d6e6fe; }

.classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item > .radio-button-wrapper:not(.selected) {
  border-bottom-color: #d6e6fe; }

.classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow {
  background-color: #e7f0ff;
  border-bottom-color: #d6e6fe; }
  .classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow:hover {
    background-color: #d6e6fe; }
  .classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow.left-arrow-container {
    border-right-color: #d6e6fe; }
  .classic-facelift-skin .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow.right-arrow-container {
    border-left-color: #d6e6fe; }

.control-horizontal-tabs .tabs-with-arrows-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 47px; }
  .control-horizontal-tabs .tabs-with-arrows-container .tabs-horizontal-list {
    width: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    display: flex;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) left; }
  .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item:not(:last-child) {
    border-right: solid 1px #dfe5eb; }
  .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item > .radio-button-wrapper:not(.selected) {
    border-bottom: solid 1px #dfe5eb; }
  .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item > .radio-button-wrapper {
    padding: 0 8px; }
    .control-horizontal-tabs .tabs-with-arrows-container .control-radio-buttons-list > .radio-button-item > .radio-button-wrapper > .radio-button-label {
      word-break: break-word; }
  .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container {
    pointer-events: none;
    margin: 0;
    padding: 0;
    right: 0;
    left: auto; }
    .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container.align-to-left {
      left: 0;
      right: auto; }
    .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container.strech-to-fill-row {
      width: 100%; }
    .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow {
      position: absolute;
      left: 0;
      pointer-events: auto;
      min-width: 30px;
      height: 46px;
      background-color: #e7f0ff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: solid 1px #dfe5eb; }
      .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow:hover {
        background-color: #d6e6fe; }
      .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow.left-arrow-container {
        border-right: solid 1px #dfe5eb; }
      .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow.right-arrow-container {
        right: 0;
        left: auto;
        border-left: solid 1px #dfe5eb; }
      .control-horizontal-tabs .tabs-with-arrows-container .tabs-navigation-arrows-container .tab-navigation-arrow > .tabs-with-arrows-right-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg); }

.composite-horizontal-tabs-labeled {
  padding: 12px 24px 0; }

.responsive-skin .composite-color-picker-input {
  flex-direction: row-reverse; }
  .responsive-skin .composite-color-picker-input .color-picker-input-ctrl-wrapper {
    margin-left: 0; }

.responsive-skin .color-picker-input-ctrl-wrapper {
  width: 28px;
  height: 28px;
  background-image: linear-gradient(45deg, #868aa5 26%, transparent 26%, transparent 74%, #868aa5 74%, #868aa5), linear-gradient(45deg, #868aa5 26%, transparent 26%, transparent 74%, #868aa5 74%, #868aa5);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px, 5px 5px, 0 0;
  background-color: #fff;
  border-radius: 4px;
  background-origin: border-box; }
  .responsive-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl {
    border-radius: 4px;
    border: none; }
    .responsive-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl::after {
      border-radius: inherit; }
    .responsive-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.disabled {
      border: none; }
    .responsive-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl:not(.gradient)::after {
      border: 1px solid rgba(19, 23, 32, 0.1);
      background: var(--color);
      opacity: var(--opacity); }
    .responsive-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.disabled::after {
      border: 1px solid #bebebe;
      background-color: #f3f3f3;
      opacity: 1; }

.classic-facelift-skin .color-picker-input-ctrl-wrapper {
  border-radius: 4px; }
  .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl {
    border-color: #dfe5eb;
    border-radius: 4px; }
    .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl::before {
      background: var(--color, #fff); }
    .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.light svg > path {
      fill: #868aa5; }
    .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.dark svg > path {
      fill: #dfe5eb; }
    .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl:not(.disabled):hover {
      outline-color: #a8caff; }
    .classic-facelift-skin .color-picker-input-ctrl-wrapper .color-picker-input-ctrl:not(.disabled).selected {
      outline-color: #116dff; }

.color-picker-input-ctrl-wrapper {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 4px; }
  .composite-color-picker-input .color-picker-input-ctrl-wrapper {
    margin-left: 6px; }
  .color-picker-input-ctrl-wrapper .color-picker-input-ctrl {
    width: 100%;
    height: 100%;
    border: 1px solid #b6c1cd;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template: 1fr / 1fr;
    overflow: hidden; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl::before {
      background: var(--color, #FFFFFF);
      opacity: var(--opacity, 1);
      content: '';
      place-self: stretch;
      grid-area: 1 / 1;
      z-index: 0; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl::after {
      content: '';
      place-self: stretch;
      grid-area: 1 / 1;
      z-index: 1; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.light svg > path {
      fill: #000624; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.dark svg > path {
      fill: #fff; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.disabled {
      cursor: auto;
      border: none; }
      .color-picker-input-ctrl-wrapper .color-picker-input-ctrl.disabled::after {
        background-color: #d5d5d5; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl:not(.disabled):hover {
      outline: 2px solid #d6e6fe; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl:not(.disabled).selected {
      outline: 2px solid #116dff; }
    .color-picker-input-ctrl-wrapper .color-picker-input-ctrl .color-picker-input-symbol-wrapper {
      z-index: 2;
      place-self: center;
      grid-area: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }

.composite-link-button-with-color-input {
  display: flex;
  align-items: center; }
  .composite-link-button-with-color-input::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #dfe5eb; }

.composite-link-button-with-color-input-labeled {
  padding: 16px 24px 15px;
  position: relative; }
  .composite-link-button-with-color-input-labeled .has-tooltip.info-icon-tooltip {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0; }
  .composite-link-button-with-color-input-labeled .has-tooltip.info-icon-tooltip + .control-label {
    max-width: calc(100% - 18px); }

.composite-link-button-with-color-input-labeled:hover .has-tooltip.info-icon-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 1; }

.responsive-skin .font-option-container > .font-label {
  font-size: 14px;
  width: 220px; }
  .control-font-family-picker .responsive-skin .font-option-container > .font-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    height: 40px;
    line-height: 40px;
    min-height: 40px; }

.responsive-skin .font-option-container > .font-example {
  font-size: 12px;
  line-height: 20px;
  color: #868aa5; }

.responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 0;
  border-top: 1px solid #e5e5e5; }
  .responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container {
    height: 100%;
    flex-direction: row-reverse;
    color: #116dff; }
    .responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container svg {
      fill: #116dff; }
    .responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link {
      justify-content: center;
      min-height: 44px;
      margin-left: 0;
      padding: 12px;
      font-size: 12px;
      font-weight: bold;
      box-sizing: border-box; }
      .responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link.add-lang-link {
        border-left: 1px solid #e5e5e5;
        flex: 1; }
      .responsive-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link.upload-font-link {
        flex: 1; }

.classic-facelift-skin .font-option-container > .font-label {
  color: #000624; }
  .control-font-family-picker:not(.toolbar-mode) .classic-facelift-skin .font-option-container > .font-label {
    color: #000624; }
  .selected .classic-facelift-skin .font-option-container > .font-label {
    color: #fff; }

.classic-facelift-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container {
  color: #116dff; }
  .classic-facelift-skin .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container svg {
    fill: #116dff; }

.font-option-container {
  display: flex;
  align-items: center; }
  .font-option-container > .font-label {
    font-size: 16px;
    color: #000624;
    width: 250px; }
    .control-font-family-picker .font-option-container > .font-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      display: inline; }
    .dropdown-selected .selected-container .font-option-container > .font-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .control-font-family-picker.disabled .selected-content .font-option-container > .font-label {
      color: #7a7a7a; }
    .control-font-family-picker:not(.toolbar-mode) .font-option-container > .font-label {
      font-size: 18px;
      padding-right: 30px;
      color: #000624; }
    .selected .font-option-container > .font-label {
      color: #fff; }
  .font-option-container > .font-example {
    font-size: 16px;
    margin-left: auto; }
    .selected-container .font-option-container > .font-example {
      display: none; }
  .font-option .font-option-container {
    height: 24px; }

.control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer {
  display: flex;
  border-top: 1px solid #e2e2e2; }
  .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .font-family-footer-label {
    cursor: pointer; }
  .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container {
    display: flex;
    color: #116dff;
    width: 100%; }
    .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container svg {
      fill: #116dff;
      margin-right: 8px; }
    .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link.upload-font-link {
        margin-left: auto; }

.composite-dropdown-with-color-input {
  display: flex;
  align-items: center; }
  .composite-dropdown-with-color-input::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #dfe5eb; }

.input-borders-experiment .composite-dropdown-with-color-input {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px; }
  .input-borders-experiment .composite-dropdown-with-color-input::before {
    content: none;
    margin-left: unset;
    margin-right: unset;
    height: unset;
    width: unset;
    background-color: unset; }

.composite-dropdown-with-color-input-labeled {
  position: relative;
  padding: 14px 24px; }
  .composite-dropdown-with-color-input-labeled .has-tooltip.info-icon-tooltip {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0; }
  .composite-dropdown-with-color-input-labeled .has-tooltip.info-icon-tooltip + .control-label {
    max-width: calc(100% - 18px); }

.composite-dropdown-with-color-input-labeled:hover .has-tooltip.info-icon-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 1; }

.composite-input-with-drop-down-labeled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 24px; }

.composite-google-preview-with-rich-text {
  padding-top: 22px;
  padding-bottom: 2px; }

.responsive-skin .composite-text-input {
  padding: 12px 24px; }

.composite-text-input {
  padding: 14px 12px; }
  .input-borders-experiment .composite-text-input {
    padding: 18px 24px; }
  .composite-toggle-switch-with-selective-input .composite-text-input {
    padding-top: 0; }

.responsive-skin .composite-text-input-labeled {
  padding: 12px 24px; }

.composite-text-input-labeled {
  display: flex;
  flex-direction: column;
  padding: 14px 12px; }
  .input-borders-experiment .composite-text-input-labeled {
    padding: 14px 24px 18px 24px; }

.control-text-input-multiline {
  display: flex;
  flex-direction: column; }
  .composite-text-input-multiline-labeled.composite-full-height .control-text-input-multiline {
    height: 100%; }

.responsive-skin .composite-text-input-multiline {
  padding-top: 16px;
  padding-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px; }

.composite-text-input-multiline {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px; }
  .input-borders-experiment .composite-text-input-multiline {
    padding: 0 24px 18px; }

.responsive-skin .composite-text-input-multiline-labeled {
  padding: 12px 24px; }

.composite-text-input-multiline-labeled {
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px; }
  .input-borders-experiment .composite-text-input-multiline-labeled {
    padding: 16px 24px 18px; }
  .composite-text-input-multiline-labeled.composite-full-height {
    height: 100%;
    box-sizing: border-box; }

.control-text-input-multiline-with-button {
  display: flex;
  flex-direction: column; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height .control-text-input-multiline-with-button {
    height: 100%; }

.responsive-skin .composite-text-input-multiline-with-button {
  padding-top: 16px;
  padding-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px; }

.composite-text-input-multiline-with-button {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px; }
  .input-borders-experiment .composite-text-input-multiline-with-button {
    padding: 0 24px 18px; }

.responsive-skin .composite-text-input-multiline-with-button-labeled {
  padding: 12px 24px; }

.composite-text-input-multiline-with-button-labeled {
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px; }
  .input-borders-experiment .composite-text-input-multiline-with-button-labeled {
    padding: 16px 24px 18px; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height {
    height: 100%;
    box-sizing: border-box; }

.composite-toggle-switch {
  padding: 22px 24px; }

.responsive-skin .composite-toggle-switch {
  padding: 12px 24px; }

.responsive-skin .composite-button-large {
  padding: 12px 24px; }

.composite-button-large {
  display: flex;
  justify-content: center;
  padding: 24px;
  flex-direction: column;
  align-items: center; }

.composite-button-large-fixed-bottom {
  display: flex;
  justify-content: center;
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-direction: column;
  align-items: center; }

.composite-button-large-labeled {
  display: flex;
  flex-direction: column;
  padding: 20px 24px; }

.responsive-skin .composite-button-left {
  padding: 8px 0px 8px 24px; }

.composite-button-left {
  padding: 15px 0 18px 12px; }

.composite-button-medium {
  display: flex;
  justify-content: center;
  padding: 18px 24px; }

.composite-buttons-group .control-buttons-list-group,
.composite-buttons-group-labeled .control-buttons-list-group {
  align-self: center; }

.composite-buttons-group {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  color: #3b4057;
  padding: 24px 42px;
  display: flex;
  flex-direction: column; }

.responsive-skin .composite-buttons-group-labeled {
  padding: 12px 24px; }

.composite-buttons-group-labeled {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  color: #3b4057;
  padding: 18px 24px 24px 24px;
  display: flex;
  flex-direction: column; }

.composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: 0; }

.responsive-skin .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: -2px; }

.composite-checkboxes {
  padding: 22px 24px; }

.responsive-skin .composite-checkboxes {
  padding: 12px 24px; }
  .responsive-skin .composite-checkboxes .control-boolean.control-checkbox:not(:last-child),
  .responsive-skin .composite-checkboxes .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox {
    margin-bottom: 4px; }
  .responsive-skin .composite-checkboxes > .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
    position: relative;
    float: right;
    right: 0;
    top: 0; }

.responsive-skin .composite-checkboxes-labeled {
  padding: 9px 24px 10px; }
  .responsive-skin .composite-checkboxes-labeled .control-label {
    color: #131720; }
  .responsive-skin .composite-checkboxes-labeled .control-boolean.control-checkbox:not(:last-child),
  .responsive-skin .composite-checkboxes-labeled .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox {
    margin-bottom: 4px; }
  .responsive-skin .composite-checkboxes-labeled > .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
    position: absolute;
    right: 0;
    top: 2px; }
  .responsive-skin .composite-checkboxes-labeled .composite-with-info-icon > .has-tooltip.info-icon-tooltip .control-info-icon {
    right: 24px;
    top: 12px;
    margin-left: 12px; }

.composite-checkboxes-labeled {
  display: flex;
  flex-direction: column;
  padding: 18px 24px; }

.context-menu-options .composite-checkboxes-labeled {
  width: 100%; }

.composite-big-panel-header {
  width: 100%; }

.composite-big-panel-header-with-illustration {
  width: 100%; }

.responsive-skin .composite-panel-content {
  background-color: #fff; }

.responsive-skin.responsive-popups-experiment .composite-panel-content {
  background-color: #fff; }

.responsive-skin.responsive-popups-experiment .composite-popup-large-size > .composite-big-panel-header + .composite-panel-content {
  padding: 0 56px; }

.responsive-skin.responsive-popups-experiment .composite-popup-small-size > .composite-panel-content,
.responsive-skin.responsive-popups-experiment .composite-popup-medium-size > .composite-panel-content,
.responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .composite-panel-content {
  padding-left: 36px;
  padding-right: 36px; }

.composite-panel-content {
  padding: 36px 48px 0 48px; }
  .composite-popup > .composite-panel-content {
    flex: 1 1 auto; }
  .composite-popup-small-size > .composite-panel-content {
    padding: 24px 24px 0 24px; }
  .composite-popup-medium-size > .composite-big-panel-header + .composite-panel-content {
    padding: 24px 24px 0 24px; }
  .composite-popup-medium-size > .composite-big-panel-header-with-illustration + .composite-panel-content {
    padding: 0 24px; }
  .composite-popup-large-size > .composite-big-panel-header + .composite-panel-content {
    padding: 36px 48px 0 48px; }
  .composite-popup-large-size > .composite-big-panel-header-with-illustration + .composite-panel-content {
    padding: 0 48px; }

.responsive-skin .responsive-skin-only::after {
  display: none; }

.responsive-skin .composite-popup {
  border-radius: 8px;
  background-color: #fff; }

.responsive-skin.responsive-popups-experiment .composite-popup {
  position: relative; }

.classic-facelift-skin .composite-popup {
  border-radius: 4px; }

.responsive-skin-only::after {
  content: "Responsive skin only";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1; }

.composite-popup {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .composite-popup.composite-full-height {
    height: 100%; }

.composite-popup-tiny-size {
  width: 240px; }
  .composite-popup-tiny-size .composite-panel-content {
    padding: 12px 24px 0; }

.responsive-skin .composite-popup-small-size.responsive-component-panel {
  width: 260px; }
  .responsive-skin .composite-popup-small-size.responsive-component-panel .composite-panel-content {
    padding: 0; }

.responsive-skin.responsive-popups-experiment .composite-popup-small-size.responsive-component-panel .composite-panel-content {
  padding: 0; }

.composite-popup-small-size {
  width: 564px; }

.composite-popup-medium-size {
  width: 504px; }

.composite-popup-large-size {
  width: 744px; }

.composite-popup-jumbo-size {
  min-width: 780px;
  display: flex;
  flex-direction: row; }

.composite-popup-jumbo-media {
  width: 412px; }

.composite-popup-jumbo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 368px;
  padding-top: 32px; }

.composite-numeric-input {
  padding: 14px 12px 12px 24px; }

.input-borders-experiment .composite-numeric-input {
  padding: 14px 24px 12px 24px; }

.composite-numeric-input:hover .info-icon-tooltip {
  display: flex; }

.composite-numeric-input:not(:hover) .has-tooltip.info-icon-tooltip {
  display: none; }

.composite-panel-content-asymmetric {
  padding-left: 12px;
  padding-right: 18px; }

.composite-preloader {
  min-height: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .composite-preloader.medium {
    min-height: 72px; }
  .composite-preloader.large {
    min-height: 120px; }

.composite-popup .composite-rich-text-with-illustration > .control-illustration {
  width: 144px; }

.composite-progress-bar-with-illustration .control-illustration {
  display: flex;
  height: 84px;
  margin-right: 18px; }

.composite-progress-bar-with-illustration-vertical .control-illustration {
  margin-bottom: 18px; }

.responsive-skin .composite-rich-text-labeled {
  padding-top: 8px;
  padding-bottom: 12px; }
  .responsive-skin .composite-rich-text-labeled .control-header-root {
    margin-bottom: 8px; }
  .responsive-skin .composite-rich-text-labeled .control-rich-text {
    margin-top: 0; }
  .responsive-skin .composite-rich-text-labeled .control-text {
    padding-top: 4px;
    margin-bottom: 0px; }
  .responsive-skin .composite-rich-text-labeled .control-button {
    margin: 8px 8px 0 0; }
    .responsive-skin .composite-rich-text-labeled .control-button:last-child {
      margin-right: 0; }

.responsive-skin.responsive-popups-experiment .composite-popup-jumbo-size .composite-rich-text-labeled {
  padding-left: 0;
  padding-right: 0; }

.composite-rich-text-labeled {
  padding: 12px 24px 18px;
  box-sizing: border-box; }
  .composite-rich-text-labeled.composite-centered {
    text-align: center; }
  .composite-rich-text-with-illustration-vertical.content-width-md > .composite-rich-text-labeled,
  .composite-rich-text-with-illustration-vertical > .composite-rich-text-labeled {
    max-width: 288px; }
  .composite-rich-text-with-illustration-vertical.content-width-xl > .composite-rich-text-labeled {
    max-width: 420px; }
  .composite-rich-text-labeled .control-text {
    padding-top: 6px;
    display: inline-block;
    margin-bottom: 6px; }
  .composite-rich-text-labeled > .control-header-wrapper > .control-header-root > .label > .control-text {
    padding-top: 0px;
    display: inline-flex;
    margin-bottom: 0px; }
  .composite-rich-text-labeled .control-button {
    margin: 12px 6px 0 0; }
    .composite-rich-text-labeled .control-button:last-child {
      margin-right: 0; }

.composite-rich-text {
  padding: 18px 24px;
  box-sizing: border-box; }
  .composite-rich-text-with-illustration-vertical.content-width-md > .composite-rich-text,
  .composite-rich-text-with-illustration-vertical > .composite-rich-text {
    max-width: 288px; }
  .composite-rich-text-with-illustration-vertical.content-width-xl > .composite-rich-text {
    max-width: 420px; }
  .composite-banner-premium .composite-rich-text {
    padding: 0; }

.composite-rich-text-with-illustration {
  display: flex; }

.composite-rich-text-with-illustration-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px; }

.composite-progress-bar {
  padding: 24px;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box; }
  .composite-progress-bar-with-illustration .composite-progress-bar,
  .composite-progress-bar-with-illustration-vertical .composite-progress-bar {
    padding: 0; }

.composite-progress-bar-with-illustration {
  padding: 24px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between; }

.composite-progress-bar-with-illustration-vertical {
  padding: 24px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center; }

.composite-promotional-list {
  text-align: center;
  padding: 24px 48px; }
  .composite-panel-content .composite-promotional-list:last-child {
    padding-bottom: 0; }

.responsive-skin .composite-radio-buttons-labeled {
  padding: 16px 24px; }

.composite-radio-buttons-labeled {
  padding: 18px 24px; }

.responsive-skin .composite-radio-buttons {
  padding: 16px 24px; }

.composite-radio-buttons {
  padding: 18px 24px; }

.composite-radio-buttons-with-illustration {
  padding: 24px 24px 0; }

.composite-editable-url-labeled {
  padding: 16px 24px 28px 24px;
  text-align: left; }

.classic-facelift-skin .dynamic-field-container .field-container > .input-prefix {
  color: #3b4057; }

.classic-facelift-skin .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
  color: #000624; }

.classic-facelift-skin .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text {
  background-color: #3b4057;
  color: #fff; }

.classic-facelift-skin .dynamic-field-container > .control-dropdown .dropdown-selected {
  color: #116dff; }

.classic-facelift-skin .vertical.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
  color: #116dff; }

.classic-facelift-skin .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected {
  background-color: #e7f0ff; }
  .classic-facelift-skin .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
    background-color: #d6e6fe; }

.input-borders-experiment .dynamic-field-container > .control-dropdown .dropdown-selected {
  border: none;
  padding: 0;
  background-color: transparent; }

.input-borders-experiment .dynamic-field-container .control-dropdown:not(.disabled):hover > .dropdown-selected {
  background-color: transparent; }

.composite-dynamic-field-labeled .dynamic-field-container.vertical.un-prefixed {
  margin-left: -12px; }
  .input-borders-experiment .composite-dynamic-field-labeled .dynamic-field-container.vertical.un-prefixed {
    margin-left: 0; }

.dynamic-field-container.horizontal {
  display: flex;
  align-items: center; }

.dynamic-field-container .field-container {
  align-items: center;
  width: 100%; }
  .input-borders-experiment .dynamic-field-container .field-container {
    gap: 6px; }
  .vertical.dynamic-field-container .field-container {
    display: flex; }
  .horizontal.dynamic-field-container .field-container {
    display: inline-flex; }
  .dynamic-field-container .field-container > .input-prefix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057; }
    .disabled.dynamic-field-container .field-container > .input-prefix {
      color: #939393; }
  .dynamic-field-container .field-container > .text-container {
    position: relative;
    width: 100%; }
    .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay {
      width: 100%;
      height: 36px;
      bottom: 0;
      position: absolute;
      box-sizing: border-box;
      pointer-events: none;
      overflow: hidden;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      border-right-width: 12px;
      border-left-width: 12px;
      z-index: 1; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay.hidden {
        visibility: hidden; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text,
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
        color: #000624; }
        .disabled.dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
          color: #7a7a7a; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text {
        margin: 0 2px;
        background-color: #3b4057;
        color: #fff;
        border-radius: 6px;
        height: 24px;
        line-height: 24px; }
        .disabled.dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text {
          background-color: #939393; }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::before, .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::after {
          color: transparent; }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::before {
          content: attr(data-prefix); }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::after {
          content: attr(data-suffix); }
    .dynamic-field-container .field-container > .text-container > .input-container.success + .overlay-container > .overlay, .dynamic-field-container .field-container > .text-container > .input-container.invalid + .overlay-container > .overlay {
      width: calc(100% - 42px); }
    .dynamic-field-container .field-container > .text-container > .input-container .input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
    .dynamic-field-container .field-container > .text-container > .input-container.hidden-text .input {
      color: transparent; }

.vertical.dynamic-field-container > .control-dropdown {
  display: flex; }

.horizontal.dynamic-field-container > .control-dropdown {
  display: inline-flex; }

.un-prefixed.vertical.dynamic-field-container > .control-dropdown {
  margin-left: 12px; }
  .input-borders-experiment .un-prefixed.vertical.dynamic-field-container > .control-dropdown {
    margin-left: 0; }

.horizontal.dynamic-field-container > .control-dropdown {
  margin-left: 12px; }

.dynamic-field-container > .control-dropdown .dropdown-selected {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #116dff; }
  .vertical.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
    color: #116dff;
    text-decoration: underline; }
  .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected {
    display: flex;
    align-items: center;
    height: 30px;
    min-height: 30px;
    padding: 0 20px;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #e7f0ff; }
    .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
      background-color: #d6e6fe; }

.dropdown-options-container.dynamic-field-options > .dropdown-options {
  width: 240px; }

.composite-dynamic-field-labeled {
  padding: 20px 12px 8px 24px; }

.composite-dynamic-field-with-description {
  padding: 20px 24px; }

.responsive-skin .control-secondary-media-image {
  box-sizing: border-box;
  max-width: 112px;
  min-width: 72px;
  height: 72px;
  line-height: 72px; }
  .responsive-skin .control-secondary-media-image.no-img {
    border: 1px solid #e2e3ea;
    cursor: pointer;
    background-color: #f4f5f8; }
    .responsive-skin .control-secondary-media-image.no-img:hover {
      background-color: #e2e3ea; }
    .responsive-skin .control-secondary-media-image.no-img:focus-visible {
      outline: 2px solid #7cafff; }
  .responsive-skin .control-secondary-media-image.has-img {
    background-color: #f4f5f8; }
  .responsive-skin .control-secondary-media-image.has-img:hover {
    opacity: 1;
    filter: brightness(60%); }
  .responsive-skin .control-secondary-media-image.has-img:focus-visible {
    outline: 2px solid #7cafff; }

.classic-facelift-skin .control-secondary-media-image {
  border-radius: 4px;
  border-color: #dfe5eb;
  background-color: #e7f0ff; }
  .classic-facelift-skin .control-secondary-media-image.no-img {
    border-color: #a8caff;
    background-color: #e7f0ff; }
    .classic-facelift-skin .control-secondary-media-image.no-img:hover {
      background-color: #d6e6fe; }
    .classic-facelift-skin .control-secondary-media-image.no-img:focus-visible {
      outline: 2px solid #80b1ff; }
  .classic-facelift-skin .control-secondary-media-image.has-img:focus-visible {
    outline: 2px solid #80b1ff; }

.control-secondary-media-image {
  max-width: 154px;
  min-width: 82px;
  width: calc(100% - 86px);
  height: 82px;
  line-height: 82px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #dfe5eb;
  display: inline-block;
  overflow: hidden;
  background: #e7f0ff center no-repeat; }
  .control-secondary-media-image.small {
    max-width: 106px;
    min-width: 60px;
    width: calc(100% - 86px);
    height: 60px;
    background-size: auto 60px;
    line-height: 60px; }
  .control-secondary-media-image.no-img {
    border: 1px dashed #a8caff;
    cursor: pointer;
    background-color: #e7f0ff; }
    .control-secondary-media-image.no-img:hover {
      background-color: #d6e6fe; }
    .control-secondary-media-image.no-img:focus-visible {
      outline: 2px solid #80b1ff; }
  .control-secondary-media-image.has-img:hover {
    cursor: pointer;
    opacity: 0.5; }
  .control-secondary-media-image.has-img:focus-visible {
    outline: 2px solid #80b1ff; }
  .composite-secondary-media-labeled .control-secondary-media-image,
  .composite-secondary-media .control-secondary-media-image {
    margin-right: 18px;
    vertical-align: middle; }
  .sbdl-item-row .control-secondary-media-image {
    width: 100px;
    height: 54px;
    line-height: 54px;
    cursor: pointer; }
  .control-secondary-media-image .children-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .control-secondary-media-image svg {
    vertical-align: middle; }
  .control-secondary-media-image img {
    vertical-align: middle; }

.composite-secondary-media {
  padding: 24px; }

.composite-secondary-media-labeled {
  padding: 12px 24px 24px; }

.responsive-skin .composite-secondary-media-labeled {
  padding: 12px 24px; }
  .responsive-skin .composite-secondary-media-labeled .control-label {
    margin-bottom: 0; }
  .responsive-skin .composite-secondary-media-labeled .control-secondary-media-image {
    margin-right: 36px;
    margin-top: 12px; }
  .responsive-skin .composite-secondary-media-labeled .control-icon-button {
    display: inline-flex;
    position: relative;
    bottom: -14px; }
    .responsive-skin .composite-secondary-media-labeled .control-icon-button:not(:last-child) {
      margin-right: 8px; }

.responsive-skin .composite-color-picker-input .control-slider-input {
  flex: 1;
  margin-left: 12px; }

.responsive-skin .composite-slider > .slider.with-numeric-input {
  margin-top: 11px; }

.responsive-skin .composite-slider-labeled > .slider.with-numeric-input {
  margin-top: 13px; }

.responsive-skin .control-slider-input {
  display: flex; }
  .responsive-skin .control-slider-input.hue-slider > .slider.with-numeric-input {
    margin-top: 11px; }
  .responsive-skin .control-slider-input.hue-slider > .slider > .track {
    top: 0;
    border-radius: 0;
    box-shadow: none;
    background-image: linear-gradient(to right, hues()); }
    .responsive-skin .control-slider-input.hue-slider > .slider > .track > .colored-part {
      display: none; }
  .responsive-skin .control-slider-input.hue-slider > .slider > .knob-container {
    width: calc(100% - 6px); }
    .responsive-skin .control-slider-input.hue-slider > .slider > .knob-container > .knob {
      top: -3px;
      width: 6px;
      height: 6px;
      box-shadow: none;
      box-sizing: border-box; }
      .responsive-skin .control-slider-input.hue-slider > .slider > .knob-container > .knob::after {
        background-color: #fff; }
  .responsive-skin .control-slider-input.opacity-slider > .slider.with-numeric-input {
    margin-top: 11px; }
  .responsive-skin .control-slider-input.opacity-slider > .slider > .track {
    background-color: transparent;
    box-shadow: none;
    height: 2px;
    box-sizing: border-box; }
    .disabled.responsive-skin .control-slider-input.opacity-slider > .slider > .track {
      background-color: transparent;
      box-shadow: none; }
    .responsive-skin .control-slider-input.opacity-slider > .slider > .track > .colored-part {
      box-shadow: none;
      background-color: transparent;
      background: linear-gradient(90deg, transparent 0%, #fff 100%); }
      .disabled.responsive-skin .control-slider-input.opacity-slider > .slider > .track > .colored-part {
        background-color: transparent;
        box-shadow: none; }
  .responsive-skin .control-slider-input > .slider {
    flex: 1;
    position: relative;
    height: 6px; }
    .responsive-skin .control-slider-input > .slider.with-numeric-input {
      margin-top: 11px;
      margin-bottom: 2px; }
    .responsive-skin .control-slider-input > .slider > .track {
      cursor: pointer;
      position: absolute;
      top: 4px;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #e2e3ea;
      box-shadow: none; }
      .disabled.responsive-skin .control-slider-input > .slider > .track {
        background-color: #767676;
        cursor: default;
        box-shadow: none; }
      .responsive-skin .control-slider-input > .slider > .track > .colored-part {
        position: absolute;
        left: 0;
        height: 2px;
        background-color: #116dff;
        transform-origin: left;
        will-change: width; }
        .responsive-skin .control-slider-input > .slider > .track > .colored-part.has-fill-start {
          border-radius: 0; }
        .disabled.responsive-skin .control-slider-input > .slider > .track > .colored-part {
          background-color: #939393; }
    .responsive-skin .control-slider-input > .slider > .knob-container {
      width: calc(100% - 6px);
      position: relative; }
      .responsive-skin .control-slider-input > .slider > .knob-container > .knob {
        cursor: pointer;
        position: absolute;
        top: -1px;
        display: inline-block;
        border-radius: 50%;
        background-color: #116dff;
        width: 13px;
        height: 13px;
        box-shadow: none;
        will-change: left; }
        .disabled.responsive-skin .control-slider-input > .slider > .knob-container > .knob {
          cursor: default; }
        .responsive-skin .control-slider-input > .slider > .knob-container > .knob:hover {
          box-shadow: none; }
        .responsive-skin .control-slider-input > .slider > .knob-container > .knob:after {
          width: 0;
          height: 0; }

.classic-facelift-skin .control-slider-input > .slider > .track {
  background-color: #d6e6fe; }
  .classic-facelift-skin .control-slider-input > .slider > .track > .colored-part {
    background-color: #116dff;
    box-shadow: inset 0 1px 0 0 #116dff; }

.classic-facelift-skin .control-slider-input > .slider > .knob-container > .knob {
  background-color: #fff; }
  .classic-facelift-skin .control-slider-input > .slider > .knob-container > .knob:hover {
    box-shadow: 0 0 4px 1px #80b1ff; }
  .classic-facelift-skin .control-slider-input > .slider > .knob-container > .knob:after {
    background-color: #116dff; }

.control-slider-input {
  display: flex; }
  .composite-color-picker-input > .control-slider-input {
    flex: 1; }
  .control-slider-input.hue-slider > .slider > .track {
    top: 0;
    border-radius: 0;
    box-shadow: none;
    background-image: linear-gradient(to right, red 0%, #ff4d00 5%, #ff9900 10%, #ffe600 15%, #ccff00 20%, #80ff00 25%, #33ff00 30%, #00ff1a 35%, #00ff66 40%, #00ffb3 45%, cyan 50%, #00b3ff 55%, #0066ff 60%, #001aff 65%, #3300ff 70%, #8000ff 75%, #cc00ff 80%, #ff00e6 85%, #ff0099 90%, #ff004d 95%, red 100%); }
    .control-slider-input.hue-slider > .slider > .track > .colored-part {
      display: none; }
  .control-slider-input.hue-slider > .slider > .knob-container {
    width: calc(100% - 12px); }
    .control-slider-input.hue-slider > .slider > .knob-container > .knob {
      top: -3px;
      width: 12px;
      height: 12px;
      box-shadow: 0 2px 1px 0 rgba(59, 64, 87, 0.43);
      box-sizing: border-box; }
      .control-slider-input.hue-slider > .slider > .knob-container > .knob::after {
        background-color: #fff; }
  .control-slider-input.opacity-slider > .slider.with-numeric-input {
    margin-top: 13px; }
  .control-slider-input.opacity-slider > .slider > .track {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #d5d5d5;
    height: 8px;
    box-sizing: border-box;
    background-image: linear-gradient(45deg, #dfe5eb 26%, transparent 26%, transparent 74%, #dfe5eb 74%, #dfe5eb), linear-gradient(45deg, #dfe5eb 26%, transparent 26%, transparent 74%, #dfe5eb 74%, #dfe5eb);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px, 4px 4px, 0 0;
    background-color: #fff; }
    .disabled.control-slider-input.opacity-slider > .slider > .track {
      background-color: transparent;
      box-shadow: none; }
    .control-slider-input.opacity-slider > .slider > .track > .colored-part {
      box-shadow: none;
      background-color: transparent;
      background: linear-gradient(90deg, transparent 0%, #fff 100%); }
      .disabled.control-slider-input.opacity-slider > .slider > .track > .colored-part {
        background-color: transparent;
        box-shadow: none; }
  .control-slider-input > .slider {
    flex: 1;
    position: relative;
    height: 16px; }
    .control-slider-input > .slider.with-numeric-input {
      margin-top: 15px;
      margin-bottom: 2px; }
    .control-slider-input > .slider > .track {
      cursor: pointer;
      position: absolute;
      top: 4px;
      left: 0;
      height: 6px;
      width: 100%;
      background-color: #d6e6fe;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05); }
      .disabled.control-slider-input > .slider > .track {
        background-color: #e2e2e2;
        cursor: default;
        box-shadow: none; }
      .control-slider-input > .slider > .track > .colored-part {
        position: absolute;
        left: 0;
        height: 6px;
        background-color: #116dff;
        border-radius: 3px;
        box-shadow: inset 0 1px 0 0 #116dff;
        transform-origin: left;
        will-change: width; }
        .control-slider-input > .slider > .track > .colored-part.has-fill-start {
          border-radius: 0; }
        .disabled.control-slider-input > .slider > .track > .colored-part {
          background-color: #bcbcbc;
          box-shadow: inset 0 1px 0 0 #bcbcbc; }
    .control-slider-input > .slider > .knob-container {
      width: calc(100% - 16px);
      position: relative; }
      .control-slider-input > .slider > .knob-container > .knob {
        cursor: pointer;
        position: absolute;
        top: -1px;
        display: inline-block;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(59, 64, 87, 0.43);
        width: 16px;
        height: 16px;
        will-change: left;
        transition: box-shadow 0.25s ease-in-out 0s; }
        .disabled.control-slider-input > .slider > .knob-container > .knob {
          cursor: default; }
        .control-slider-input > .slider > .knob-container > .knob:hover {
          box-shadow: 0 0 4px 1px #80b1ff; }
        .control-slider-input > .slider > .knob-container > .knob:after {
          position: absolute;
          content: '';
          display: inline-block;
          border-radius: 50%;
          background-color: #116dff;
          width: 8px;
          height: 8px;
          left: 4px;
          top: 4px; }
          .disabled.control-slider-input > .slider > .knob-container > .knob:after {
            background-color: #bcbcbc; }

.composite-slider-labeled .control-slider-input > .slider:not(.with-numeric-input) {
  margin-top: 15px;
  margin-bottom: 2px; }

.composite-slider-labeled {
  padding: 14px 24px 12px 24px;
  display: grid;
  grid-template-areas: "label label" "slider input-with-drop-down";
  grid-auto-columns: minmax(1px, 100%) auto; }
  .composite-slider-labeled .control-label {
    padding-bottom: 2px;
    grid-area: label; }
  .composite-slider-labeled .control-slider-input {
    grid-area: slider; }
  .composite-slider-labeled .composite-input-with-drop-down {
    grid-area: input-with-drop-down; }
  .composite-slider-labeled .control-slider-input + .composite-input-with-drop-down {
    width: 72px;
    margin-left: 6px; }
    .composite-slider-labeled .control-slider-input + .composite-input-with-drop-down .control-dropdown-base.control-dropdown {
      right: 6px;
      top: 10px; }
      .composite-slider-labeled .control-slider-input + .composite-input-with-drop-down .control-dropdown-base.control-dropdown .selected-container {
        height: auto; }

.responsive-skin .composite-slider-labeled {
  padding: 16px 24px; }

.responsive-skin .control-slider-input + .composite-input-with-drop-down {
  width: 76px;
  margin-left: 0; }
  .responsive-skin .control-slider-input + .composite-input-with-drop-down .control-dropdown-base.control-dropdown {
    right: 8px;
    top: 8px; }

.composite-slider {
  padding: 12px 12px 12px 24px; }

.composite-slider {
  padding: 16px 24px; }

.responsive-skin .composite-thumbnails {
  padding: 12px 24px 12px 24px; }
  .responsive-skin .composite-thumbnails .info-icon-tooltip {
    right: 24px;
    margin-left: 12px; }
  .responsive-skin .composite-thumbnails .control-thumbnails-list {
    margin-top: 12px; }

.responsive-skin .composite-panel-footer-button-with-icon .composite-thumbnails {
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-left: 1px solid #e5e5e5;
  align-items: center;
  padding: 0; }
  .responsive-skin .composite-panel-footer-button-with-icon .composite-thumbnails svg * {
    fill: #116dff; }

.composite-thumbnails {
  padding: 24px 23px 18px 24px; }

.responsive-skin .composite-thumbnail-button-group {
  padding: 12px 24px; }
  .responsive-skin .composite-thumbnail-button-group .control-thumbnail-button-group {
    margin-top: 12px; }
    .responsive-skin .composite-thumbnail-button-group .control-thumbnail-button-group .thumbnail-buttons-section .control-button.btn-thumbnail {
      border-radius: 4px;
      padding: 5px 2px 0 2px; }

.composite-thumbnail-button-group {
  padding: 20px 24px 24px; }

.composite-color-picker-input {
  padding: 9px 24px;
  display: flex;
  align-items: center; }
  .composite-color-picker-input-labeled .composite-color-picker-input {
    padding: 0; }

.composite-color-picker-input-labeled {
  position: relative;
  padding: 15px 24px 12px; }
  .composite-color-picker-input-labeled .has-tooltip.info-icon-tooltip {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0; }
  .composite-color-picker-input-labeled .has-tooltip.info-icon-tooltip + .control-label {
    max-width: calc(100% - 18px); }

.composite-color-picker-input-labeled:hover .has-tooltip.info-icon-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 1; }

.responsive-skin .composite-color-picker-input-labeled {
  padding: 11px 24px 12px; }
  .responsive-skin .composite-color-picker-input-labeled > .has-tooltip.info-icon-tooltip {
    right: 24px;
    top: 9px; }

.composite-color-select-labeled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px; }

.responsive-skin .composite-color-select-labeled {
  padding: 12px 24px; }
  .responsive-skin .composite-color-select-labeled .control-label {
    position: relative;
    top: 3px; }

.composite-text-formatting {
  padding: 19px 18px;
  display: flex; }

.composite-upload-button {
  padding: 24px 24px 18px 24px; }

.composite-upload-button-labeled {
  padding: 12px 24px 18px 24px; }

.composite-vertical-tabs-labeled {
  padding: 12px 24px 18px; }

.composite-input-with-drop-down {
  position: relative;
  padding: unset; }
  .composite-input-with-drop-down:only-of-type {
    width: 100%; }
  .composite-input-with-drop-down-labeled:only-of-type .composite-input-with-drop-down {
    padding: 0; }

.responsive-skin .composite-buttons-group {
  padding: 12px 24px; }

.composite-stepper {
  padding: 24px 0 24px 24px; }

.responsive-skin .font-family-picker-labeled {
  padding: 9px 24px 12px; }
  .responsive-skin .font-family-picker-labeled .control-label {
    margin-bottom: 9px; }
  .responsive-skin .font-family-picker-labeled .has-tooltip.info-icon-tooltip {
    top: 9px; }

.responsive-skin .control-icon-button {
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 4px;
  background: #fff; }
  .responsive-skin .control-icon-button > .symbol path {
    fill: #131720; }
  .responsive-skin .control-icon-button:hover {
    background-color: #fff; }
    .responsive-skin .control-icon-button:hover > .symbol path {
      fill: #131720; }
  .responsive-skin .control-icon-button:focus-visible {
    background-color: #fff; }
    .responsive-skin .control-icon-button:focus-visible > .symbol path {
      fill: #131720; }
  .responsive-skin .control-icon-button:active {
    background-color: #fff; }
    .responsive-skin .control-icon-button:active > .symbol path {
      fill: #131720; }
  .responsive-skin .control-icon-button:disabled {
    cursor: default;
    background-color: #f3f3f3;
    border: none; }
    .responsive-skin .control-icon-button:disabled > .symbol path {
      fill: #bebebe; }
  .responsive-skin .control-icon-button.icon-btn-size-medium {
    width: 28px;
    height: 28px;
    padding: 6px;
    border-radius: 4px; }
  .responsive-skin .control-icon-button.icon-btn-size-small {
    width: 24px;
    height: 24px;
    padding: 6px;
    border-radius: 4px; }
  .responsive-skin .control-icon-button.icon-btn-size-large {
    width: 36px;
    height: 36px;
    padding: 10px;
    border-radius: 4px; }
  .responsive-skin .control-icon-button.standard-primary {
    background: #116dff; }
    .responsive-skin .control-icon-button.standard-primary > .symbol path {
      fill: #fff; }
    .responsive-skin .control-icon-button.standard-primary:hover {
      background-color: #0f62e6; }
      .responsive-skin .control-icon-button.standard-primary:hover > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.standard-primary:focus-visible {
      background-color: #0f62e6; }
      .responsive-skin .control-icon-button.standard-primary:focus-visible > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.standard-primary:active {
      background-color: #116dff; }
      .responsive-skin .control-icon-button.standard-primary:active > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.standard-primary:disabled {
      cursor: default;
      background-color: #f3f3f3;
      border: none; }
      .responsive-skin .control-icon-button.standard-primary:disabled > .symbol path {
        fill: #bebebe; }
  .responsive-skin .control-icon-button.standard-secondary {
    background: #fff;
    border: 1px solid #e2e3ea; }
    .responsive-skin .control-icon-button.standard-secondary > .symbol path {
      fill: #0f62e6; }
    .responsive-skin .control-icon-button.standard-secondary:hover {
      background-color: #f4f5f8; }
      .responsive-skin .control-icon-button.standard-secondary:hover > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.standard-secondary:focus-visible {
      background-color: #f4f5f8; }
      .responsive-skin .control-icon-button.standard-secondary:focus-visible > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.standard-secondary:active {
      background-color: #fff; }
      .responsive-skin .control-icon-button.standard-secondary:active > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.standard-secondary:disabled {
      cursor: default;
      background-color: #fff;
      border: 1px solid #bebebe; }
      .responsive-skin .control-icon-button.standard-secondary:disabled > .symbol path {
        fill: #bebebe; }
  .responsive-skin .control-icon-button.standard-inverted {
    background: transparent;
    border: 1px solid #116dff; }
    .responsive-skin .control-icon-button.standard-inverted > .symbol path {
      fill: #116dff; }
    .responsive-skin .control-icon-button.standard-inverted:hover {
      background-color: #116dff; }
      .responsive-skin .control-icon-button.standard-inverted:hover > .symbol path {
        fill: #116dff; }
    .responsive-skin .control-icon-button.standard-inverted:focus-visible {
      background-color: #116dff; }
      .responsive-skin .control-icon-button.standard-inverted:focus-visible > .symbol path {
        fill: #116dff; }
    .responsive-skin .control-icon-button.standard-inverted:active {
      background-color: transparent; }
      .responsive-skin .control-icon-button.standard-inverted:active > .symbol path {
        fill: #116dff; }
    .responsive-skin .control-icon-button.standard-inverted:disabled {
      cursor: default;
      background-color: #f3f3f3;
      border: none; }
      .responsive-skin .control-icon-button.standard-inverted:disabled > .symbol path {
        fill: #bebebe; }
    .responsive-skin .control-icon-button.standard-inverted:hover > .symbol path {
      fill: #fff; }
    .responsive-skin .control-icon-button.standard-inverted:focus-visible > .symbol path {
      fill: #fff; }
    .responsive-skin .control-icon-button.standard-inverted:active {
      background-color: #fff;
      border: 1px solid #116dff; }
      .responsive-skin .control-icon-button.standard-inverted:active > .symbol path {
        fill: #116dff; }
  .responsive-skin .control-icon-button.light-primary {
    background: transparent; }
    .responsive-skin .control-icon-button.light-primary > .symbol path {
      fill: #0f62e6; }
    .responsive-skin .control-icon-button.light-primary:hover {
      background-color: transparent; }
      .responsive-skin .control-icon-button.light-primary:hover > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.light-primary:focus-visible {
      background-color: transparent; }
      .responsive-skin .control-icon-button.light-primary:focus-visible > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.light-primary:active {
      background-color: transparent; }
      .responsive-skin .control-icon-button.light-primary:active > .symbol path {
        fill: #0f62e6; }
    .responsive-skin .control-icon-button.light-primary:disabled {
      cursor: default;
      background-color: transparent;
      border: none; }
      .responsive-skin .control-icon-button.light-primary:disabled > .symbol path {
        fill: #bebebe; }
  .responsive-skin .control-icon-button.premium-primary {
    background: #5A48F5; }
    .responsive-skin .control-icon-button.premium-primary > .symbol path {
      fill: #fff; }
    .responsive-skin .control-icon-button.premium-primary:hover {
      background-color: #4D3DD0; }
      .responsive-skin .control-icon-button.premium-primary:hover > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.premium-primary:focus-visible {
      background-color: #4D3DD0; }
      .responsive-skin .control-icon-button.premium-primary:focus-visible > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.premium-primary:active {
      background-color: #5A48F5; }
      .responsive-skin .control-icon-button.premium-primary:active > .symbol path {
        fill: #fff; }
    .responsive-skin .control-icon-button.premium-primary:disabled {
      cursor: default;
      background-color: #f3f3f3;
      border: none; }
      .responsive-skin .control-icon-button.premium-primary:disabled > .symbol path {
        fill: #bebebe; }
  .responsive-skin .control-icon-button.premium-secondary {
    background: #fff; }
    .responsive-skin .control-icon-button.premium-secondary > .symbol path {
      fill: #5A48F5; }
    .responsive-skin .control-icon-button.premium-secondary:hover {
      background-color: #f4f5f8; }
      .responsive-skin .control-icon-button.premium-secondary:hover > .symbol path {
        fill: #5A48F5; }
    .responsive-skin .control-icon-button.premium-secondary:focus-visible {
      background-color: #f4f5f8; }
      .responsive-skin .control-icon-button.premium-secondary:focus-visible > .symbol path {
        fill: #5A48F5; }
    .responsive-skin .control-icon-button.premium-secondary:active {
      background-color: #fff; }
      .responsive-skin .control-icon-button.premium-secondary:active > .symbol path {
        fill: #5A48F5; }
    .responsive-skin .control-icon-button.premium-secondary:disabled {
      cursor: default;
      background-color: #fff;
      border: none; }
      .responsive-skin .control-icon-button.premium-secondary:disabled > .symbol path {
        fill: #bebebe; }
  .responsive-skin .control-icon-button.light-dark-primary {
    background: #fff; }
    .responsive-skin .control-icon-button.light-dark-primary > .symbol path {
      fill: #131720; }
    .responsive-skin .control-icon-button.light-dark-primary:hover {
      background-color: #fff; }
      .responsive-skin .control-icon-button.light-dark-primary:hover > .symbol path {
        fill: #131720; }
    .responsive-skin .control-icon-button.light-dark-primary:focus-visible {
      background-color: #fff; }
      .responsive-skin .control-icon-button.light-dark-primary:focus-visible > .symbol path {
        fill: #131720; }
    .responsive-skin .control-icon-button.light-dark-primary:active {
      background-color: #fff; }
      .responsive-skin .control-icon-button.light-dark-primary:active > .symbol path {
        fill: #131720; }
    .responsive-skin .control-icon-button.light-dark-primary:disabled {
      cursor: default;
      background-color: #f3f3f3;
      border: none; }
      .responsive-skin .control-icon-button.light-dark-primary:disabled > .symbol path {
        fill: #bebebe; }

.classic-facelift-skin .control-icon-button {
  background-color: #116dff; }
  .classic-facelift-skin .control-icon-button > .symbol path {
    fill: #fff; }
  .classic-facelift-skin .control-icon-button:hover {
    background-color: #5999ff; }
  .classic-facelift-skin .control-icon-button:focus-visible {
    outline: 2px solid #80b1ff;
    background-color: #5999ff; }
  .classic-facelift-skin .control-icon-button:active {
    background-color: #116dff; }
  .classic-facelift-skin .control-icon-button:disabled {
    background-color: #bcbcbc; }
    .classic-facelift-skin .control-icon-button:disabled > .symbol path {
      fill: #fff; }
  .classic-facelift-skin .control-icon-button.standard-secondary {
    background: #e7f0ff; }
    .classic-facelift-skin .control-icon-button.standard-secondary > .symbol path {
      fill: #116dff; }
    .classic-facelift-skin .control-icon-button.standard-secondary:hover {
      background-color: #d6e6fe; }
    .classic-facelift-skin .control-icon-button.standard-secondary:focus-visible {
      background-color: #d6e6fe; }
    .classic-facelift-skin .control-icon-button.standard-secondary:active {
      background-color: #e7f0ff; }
    .classic-facelift-skin .control-icon-button.standard-secondary:disabled {
      background-color: #f0f0f0; }
      .classic-facelift-skin .control-icon-button.standard-secondary:disabled > .symbol path {
        fill: #bcbcbc; }
  .classic-facelift-skin .control-icon-button.standard-inverted {
    border: 1px solid #116dff; }
    .classic-facelift-skin .control-icon-button.standard-inverted > .symbol path {
      fill: #116dff; }
    .classic-facelift-skin .control-icon-button.standard-inverted:hover {
      background-color: #5999ff; }
      .classic-facelift-skin .control-icon-button.standard-inverted:hover > .symbol path {
        fill: #fff; }
    .classic-facelift-skin .control-icon-button.standard-inverted:focus-visible {
      background-color: #5999ff; }
      .classic-facelift-skin .control-icon-button.standard-inverted:focus-visible > .symbol path {
        fill: #fff; }
    .classic-facelift-skin .control-icon-button.standard-inverted:active {
      background-color: transparent;
      border: 1px solid #116dff; }
      .classic-facelift-skin .control-icon-button.standard-inverted:active > .symbol path {
        fill: #116dff; }
    .classic-facelift-skin .control-icon-button.standard-inverted:disabled {
      border: 1px solid #bcbcbc; }
      .classic-facelift-skin .control-icon-button.standard-inverted:disabled > .symbol path {
        fill: #bcbcbc; }
  .classic-facelift-skin .control-icon-button.light-dark-primary {
    background: #fff; }
    .classic-facelift-skin .control-icon-button.light-dark-primary > .symbol path {
      fill: #000624; }
    .classic-facelift-skin .control-icon-button.light-dark-primary:hover {
      background-color: #f7f8f8; }
    .classic-facelift-skin .control-icon-button.light-dark-primary:focus-visible {
      background-color: #f7f8f8; }
    .classic-facelift-skin .control-icon-button.light-dark-primary:active {
      background-color: #dfe5eb; }
    .classic-facelift-skin .control-icon-button.light-dark-primary:disabled {
      background-color: #fff; }
      .classic-facelift-skin .control-icon-button.light-dark-primary:disabled > .symbol path {
        fill: #939393; }
  .classic-facelift-skin .control-icon-button.light-primary {
    background: #fff; }
    .classic-facelift-skin .control-icon-button.light-primary > .symbol path {
      fill: #116dff; }
    .classic-facelift-skin .control-icon-button.light-primary:hover {
      background-color: #d6e6fe; }
      .classic-facelift-skin .control-icon-button.light-primary:hover > .symbol path {
        fill: #116dff; }
    .classic-facelift-skin .control-icon-button.light-primary:focus-visible {
      background-color: #d6e6fe; }
      .classic-facelift-skin .control-icon-button.light-primary:focus-visible > .symbol path {
        fill: #116dff; }
    .classic-facelift-skin .control-icon-button.light-primary:active {
      background-color: #fff; }
    .classic-facelift-skin .control-icon-button.light-primary:disabled {
      background-color: #fff; }
      .classic-facelift-skin .control-icon-button.light-primary:disabled > .symbol path {
        fill: #bcbcbc; }
  .classic-facelift-skin .control-icon-button.premium-primary {
    background: #9a27d5; }
    .classic-facelift-skin .control-icon-button.premium-primary > .symbol path {
      fill: #fff; }
    .classic-facelift-skin .control-icon-button.premium-primary:hover {
      background-color: #c161f0; }
      .classic-facelift-skin .control-icon-button.premium-primary:hover > .symbol path {
        fill: #fff; }
    .classic-facelift-skin .control-icon-button.premium-primary:focus-visible {
      background-color: #c161f0; }
      .classic-facelift-skin .control-icon-button.premium-primary:focus-visible > .symbol path {
        fill: #fff; }
    .classic-facelift-skin .control-icon-button.premium-primary:active {
      background-color: #9a27d5; }
    .classic-facelift-skin .control-icon-button.premium-primary:disabled {
      background-color: #bcbcbc; }
      .classic-facelift-skin .control-icon-button.premium-primary:disabled > .symbol path {
        fill: #fff; }
  .classic-facelift-skin .control-icon-button.premium-secondary {
    background: #f1e0f9; }
    .classic-facelift-skin .control-icon-button.premium-secondary > .symbol path {
      fill: #9a27d5; }
    .classic-facelift-skin .control-icon-button.premium-secondary:hover {
      background-color: #e3c3f4; }
      .classic-facelift-skin .control-icon-button.premium-secondary:hover > .symbol path {
        fill: #9a27d5; }
    .classic-facelift-skin .control-icon-button.premium-secondary:focus-visible {
      background-color: #e3c3f4; }
      .classic-facelift-skin .control-icon-button.premium-secondary:focus-visible > .symbol path {
        fill: #9a27d5; }
    .classic-facelift-skin .control-icon-button.premium-secondary:active {
      background-color: #f1e0f9; }
    .classic-facelift-skin .control-icon-button.premium-secondary:disabled {
      background-color: #f0f0f0; }
      .classic-facelift-skin .control-icon-button.premium-secondary:disabled > .symbol path {
        fill: #bcbcbc; }

.control-icon-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  padding: 2px;
  outline: none;
  background-color: #116dff; }
  .control-icon-button > .symbol {
    display: flex;
    align-items: center;
    justify-content: center; }
  .control-icon-button > .symbol path {
    fill: #fff; }
  .control-icon-button:hover {
    background-color: #5999ff; }
  .control-icon-button:focus-visible {
    outline: 2px solid #80b1ff;
    background-color: #5999ff; }
  .control-icon-button:active {
    background-color: #116dff; }
  .control-icon-button:disabled {
    background-color: #bcbcbc;
    cursor: default; }
    .control-icon-button:disabled > .symbol path {
      fill: #fff; }
  .control-icon-button.icon-btn-size-medium {
    width: 30px;
    height: 30px; }
    .control-icon-button.icon-btn-size-medium > .symbol {
      width: 24px;
      height: 24px; }
  .control-icon-button.icon-btn-size-large {
    width: 36px;
    height: 36px; }
    .control-icon-button.icon-btn-size-large > .symbol {
      width: 24px;
      height: 24px; }
  .control-icon-button.standard-secondary {
    background: #e7f0ff; }
    .control-icon-button.standard-secondary > .symbol path {
      fill: #116dff; }
    .control-icon-button.standard-secondary:hover {
      background-color: #d6e6fe; }
    .control-icon-button.standard-secondary:focus-visible {
      background-color: #d6e6fe; }
    .control-icon-button.standard-secondary:active {
      background-color: #e7f0ff; }
    .control-icon-button.standard-secondary:disabled {
      cursor: default;
      background-color: #f0f0f0; }
      .control-icon-button.standard-secondary:disabled > .symbol path {
        fill: #bcbcbc; }
  .control-icon-button.standard-inverted {
    background: none;
    border: 1px solid #116dff;
    padding: 1px; }
    .control-icon-button.standard-inverted > .symbol path {
      fill: #116dff; }
    .control-icon-button.standard-inverted:hover {
      border: none;
      background-color: #5999ff; }
      .control-icon-button.standard-inverted:hover > .symbol path {
        fill: #fff; }
    .control-icon-button.standard-inverted:focus-visible {
      border: none;
      background-color: #5999ff; }
      .control-icon-button.standard-inverted:focus-visible > .symbol path {
        fill: #fff; }
    .control-icon-button.standard-inverted:active {
      background: none;
      border: 1px solid #116dff;
      padding: 1px; }
      .control-icon-button.standard-inverted:active > .symbol path {
        fill: #116dff; }
    .control-icon-button.standard-inverted:disabled {
      background: none;
      border: 1px solid #bcbcbc;
      cursor: default; }
      .control-icon-button.standard-inverted:disabled > .symbol path {
        fill: #bcbcbc; }
  .control-icon-button.light-dark-primary {
    background: #fff; }
    .control-icon-button.light-dark-primary > .symbol path {
      fill: #000624; }
    .control-icon-button.light-dark-primary:hover {
      background-color: #f7f8f8; }
    .control-icon-button.light-dark-primary:focus-visible {
      background-color: #f7f8f8; }
    .control-icon-button.light-dark-primary:active {
      background-color: #dfe5eb; }
    .control-icon-button.light-dark-primary:disabled {
      cursor: default;
      background-color: #fff; }
      .control-icon-button.light-dark-primary:disabled > .symbol path {
        fill: #939393; }
  .control-icon-button.light-primary {
    background: #fff; }
    .control-icon-button.light-primary > .symbol path {
      fill: #116dff; }
    .control-icon-button.light-primary:hover {
      background-color: #d6e6fe; }
      .control-icon-button.light-primary:hover > .symbol path {
        fill: #116dff; }
    .control-icon-button.light-primary:focus-visible {
      background-color: #d6e6fe; }
      .control-icon-button.light-primary:focus-visible > .symbol path {
        fill: #116dff; }
    .control-icon-button.light-primary:active {
      background-color: #fff; }
    .control-icon-button.light-primary:disabled {
      cursor: default;
      background-color: #fff; }
      .control-icon-button.light-primary:disabled > .symbol path {
        fill: #bcbcbc; }
  .control-icon-button.premium-primary {
    background: #9a27d5; }
    .control-icon-button.premium-primary > .symbol path {
      fill: #fff; }
    .control-icon-button.premium-primary:hover {
      background-color: #c161f0; }
      .control-icon-button.premium-primary:hover > .symbol path {
        fill: #fff; }
    .control-icon-button.premium-primary:focus-visible {
      background-color: #c161f0; }
      .control-icon-button.premium-primary:focus-visible > .symbol path {
        fill: #fff; }
    .control-icon-button.premium-primary:active {
      background-color: #9a27d5; }
    .control-icon-button.premium-primary:disabled {
      cursor: default;
      background-color: #bcbcbc; }
      .control-icon-button.premium-primary:disabled > .symbol path {
        fill: #fff; }
  .control-icon-button.premium-secondary {
    background: #eedbf4; }
    .control-icon-button.premium-secondary > .symbol path {
      fill: #9a27d5; }
    .control-icon-button.premium-secondary:hover {
      background-color: #e5c9ee; }
      .control-icon-button.premium-secondary:hover > .symbol path {
        fill: #9a27d5; }
    .control-icon-button.premium-secondary:focus-visible {
      background-color: #e5c9ee; }
      .control-icon-button.premium-secondary:focus-visible > .symbol path {
        fill: #9a27d5; }
    .control-icon-button.premium-secondary:active {
      background-color: #eedbf4; }
    .control-icon-button.premium-secondary:disabled {
      cursor: default;
      background-color: #f0f0f0; }
      .control-icon-button.premium-secondary:disabled > .symbol path {
        fill: #bcbcbc; }

.responsive-skin .composite-time-range {
  padding: 6px 24px 4px 24px; }
  .responsive-skin .composite-time-range .input-container {
    max-width: 108px;
    margin-right: 4px; }
  .responsive-skin .composite-time-range .control-text {
    margin-left: 0; }
  .responsive-skin .composite-time-range .control-icon-button {
    margin-left: 4px; }

.composite-time-range {
  padding: 6px 12px 6px 12px;
  align-items: center;
  display: grid;
  grid-template-areas: "text-label text-label second-text-label second-text-label" "text-input second-text-input icon-button icon-button"; }
  .input-borders-experiment .composite-time-range {
    padding: 6px 24px 6px 24px; }
  .composite-time-range .control-text {
    margin-left: 12px;
    grid-area: text-label;
    max-width: calc(49% - 0.77px);
    min-width: 0; }
  .composite-time-range .control-text + .input-container {
    margin-top: 6px; }
  .composite-time-range .control-text ~ .control-text {
    grid-area: second-text-label;
    grid-column-start: 2;
    max-width: 72%; }
  .composite-time-range .input-container {
    grid-area: text-input;
    margin-right: 6px; }
  .composite-time-range .input-container ~ .input-container {
    grid-area: second-text-input;
    margin-right: 0; }
  .composite-time-range .control-text + .input-container + .control-icon-button {
    margin-top: 8px; }
  .composite-time-range .control-icon-button {
    margin-left: 6px;
    margin-right: 12px;
    grid-area: icon-button; }

.responsive-skin .composite-date-range .control-date-picker-input {
  margin-right: 2px; }

.responsive-skin .composite-date-range .control-date-picker-input ~ .control-date-picker-input {
  margin-right: 0;
  margin-left: 2px; }

.responsive-skin .composite-date-range .control-date-picker-input .control-action-with-symbol > .content-container .content {
  font-size: 14px; }

.responsive-skin .composite-date-range .control-text ~ .control-text {
  margin-left: 2px; }

.composite-date-range {
  padding: 12px 24px 12px 24px;
  align-items: center;
  display: grid;
  grid-template-areas: "text-label text-label second-text-label" "date-picker-input second-date-picker-input icon-button";
  grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .composite-date-range .control-text {
    grid-area: text-label;
    max-width: calc(49% - 0.88px);
    min-width: 0; }
  .composite-date-range .control-text + .control-date-picker-input {
    margin-top: 12px; }
  .composite-date-range .control-text ~ .control-text {
    grid-area: second-text-label;
    grid-column-start: 2;
    min-width: calc(78% - 1.51px);
    margin-left: 4px; }
  .composite-date-range .control-date-picker-input {
    grid-area: date-picker-input;
    margin-right: 3px; }
  .composite-date-range .control-date-picker-input ~ .control-date-picker-input {
    grid-area: second-date-picker-input;
    margin-right: 0;
    margin-left: 3px; }
  .composite-date-range .control-text + .control-date-picker-input + .control-icon-button {
    margin-top: 8px; }
  .composite-date-range .control-icon-button {
    margin-left: 4px;
    grid-area: icon-button; }

.checkbox-indented {
  -webkit-margin-start: 18px;
          margin-inline-start: 18px; }

.responsive-skin .secondary-media-small-labeled .control-header-root {
  margin-bottom: 2px; }

.secondary-media-small-labeled {
  padding: 12px 24px 18px 24px;
  align-items: center; }
  .secondary-media-small-labeled .control-header-root {
    margin-bottom: 6px; }
  .secondary-media-small-labeled .control-icon-button {
    display: inline;
    vertical-align: middle;
    padding: 3px;
    margin-right: 6px; }
  .secondary-media-small-labeled .control-button {
    display: inline;
    vertical-align: middle;
    padding: 3px 12px;
    margin-right: 6px; }
  .secondary-media-small-labeled :last-child {
    margin-right: 0; }
  .secondary-media-small-labeled .control-secondary-media-image {
    margin-right: 24px;
    vertical-align: middle;
    max-width: 106px; }
  .secondary-media-small-labeled .control-button,
  .secondary-media-small-labeled .control-icon-button,
  .secondary-media-small-labeled .control-secondary-media-image {
    margin-top: 6px; }

.responsive-skin .text-input-with-suffix {
  padding: 8px 24px 12px 24px; }
  .responsive-skin .text-input-with-suffix .control-header-root {
    margin-bottom: 4px; }
  .responsive-skin .text-input-with-suffix .control-button,
  .responsive-skin .text-input-with-suffix .control-icon-button,
  .responsive-skin .text-input-with-suffix .input-container {
    margin-top: 4px; }

.text-input-with-suffix {
  align-items: center;
  padding: 12px 24px 18px 24px;
  display: flex;
  flex-wrap: wrap; }
  .text-input-with-suffix .control-header-wrapper {
    width: 100%; }
  .text-input-with-suffix .control-header-root {
    margin: 0; }
  .text-input-with-suffix .input-container {
    flex: 1 1 0; }
  .text-input-with-suffix .control-button,
  .text-input-with-suffix .control-icon-button {
    margin-left: 6px; }
  .text-input-with-suffix .control-button,
  .text-input-with-suffix .control-icon-button,
  .text-input-with-suffix .input-container {
    margin-top: 6px; }

.radio-buttons-with-illustration-small {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: #9a27d5;
  padding: 12px 24px; }
  .radio-buttons-with-illustration-small .radio-button-wrapper {
    padding: 3px 6px;
    margin: 3px 0;
    border-radius: 4px; }
    .radio-buttons-with-illustration-small .radio-button-wrapper > .radio-button-symbol {
      margin-right: 0; }
  .radio-buttons-with-illustration-small .image-and-text {
    align-items: center;
    width: 100%; }
    .radio-buttons-with-illustration-small .image-and-text > .image {
      margin-left: 12px; }
    .radio-buttons-with-illustration-small .image-and-text > .content {
      margin: auto 12px;
      width: 100%; }

.responsive-skin .composite-input-with-drop-down {
  position: relative;
  padding: unset; }
  .responsive-skin .composite-input-with-drop-down:only-of-type {
    width: 100%; }
  .composite-input-with-drop-down-labeled:only-of-type .responsive-skin .composite-input-with-drop-down {
    padding: 0; }
  .composite-input-with-drop-down-labeled-horizontal:only-of-type .responsive-skin .composite-input-with-drop-down {
    padding: 0; }

.responsive-skin .composite-one-input-in-row .composite-input-with-drop-down:only-of-type {
  width: 60px; }

.responsive-skin .composite-input-with-drop-down-labeled {
  padding: 8px 20px;
  display: flex;
  flex-direction: column-reverse;
  margin-right: -1px; }
  .responsive-skin .composite-input-with-drop-down-labeled:only-of-type {
    padding: 14px 0;
    width: 100%; }

.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-labeled,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled {
  padding: 0; }

.responsive-skin .composite-input-with-drop-down-labeled-horizontal {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  padding: 8px 24px; }
  .responsive-skin .composite-input-with-drop-down-labeled-horizontal:only-of-type {
    padding: 14px 0;
    width: 100%; }

.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-labeled-horizontal,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled-horizontal,
.responsive-skin .composite-one-input-in-row .composite-input-with-drop-down-labeled-horizontal,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-horizontal {
  padding: 0; }

.responsive-skin .composite-one-input-in-row .composite-input-with-drop-down-labeled-horizontal {
  justify-content: space-between; }
  .responsive-skin .composite-one-input-in-row .composite-input-with-drop-down-labeled-horizontal .composite-input-with-drop-down {
    width: 76px;
    margin-left: 0; }
    .responsive-skin .composite-one-input-in-row .composite-input-with-drop-down-labeled-horizontal .composite-input-with-drop-down .input-container {
      margin-left: 16px; }
    .responsive-skin .composite-one-input-in-row .composite-input-with-drop-down-labeled-horizontal .composite-input-with-drop-down .control-dropdown-base.control-dropdown {
      right: 5px; }

.responsive-skin .composite-input-with-drop-down-labeled-top {
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  width: 100%;
  box-sizing: border-box; }

.responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-labeled-top,
.responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-labeled-top,
.responsive-skin .composite-two-inputs-in-row .composite-input-with-drop-down-labeled-top {
  padding: 0; }

.responsive-skin .composite-input-with-drop-down-symbol {
  display: flex;
  flex-direction: column;
  align-items: center; }

.responsive-skin .composite-multi-input-with-drop-down-labeled,
.responsive-skin .composite-multi-input-with-drop-down {
  display: grid;
  align-items: center;
  row-gap: 12px;
  column-gap: 4px;
  grid-auto-columns: 1fr;
  padding: 12px 24px; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .control-text,
  .responsive-skin .composite-multi-input-with-drop-down .control-text {
    grid-column: 1/10;
    grid-row: 1/2; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .info-icon-tooltip,
  .responsive-skin .composite-multi-input-with-drop-down-labeled .control-icon-button,
  .responsive-skin .composite-multi-input-with-drop-down .info-icon-tooltip,
  .responsive-skin .composite-multi-input-with-drop-down .control-icon-button {
    grid-column: 10/13;
    grid-row: 1/2;
    justify-self: end; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .info-icon-tooltip + .control-icon-button,
  .responsive-skin .composite-multi-input-with-drop-down .info-icon-tooltip + .control-icon-button {
    margin-right: 22px; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .control-icon-button + .info-icon-tooltip,
  .responsive-skin .composite-multi-input-with-drop-down .control-icon-button + .info-icon-tooltip {
    margin-right: 32px; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .control-icon-button .symbol,
  .responsive-skin .composite-multi-input-with-drop-down .control-icon-button .symbol {
    width: 16px;
    height: 16px; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(1),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(1) {
    grid-column: 1/13; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(2),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(2) {
    grid-column: 1/7; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(3),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(3) {
    grid-column: 1/5; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(4),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(1):nth-last-of-type(4) {
    grid-column: 1/4; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(1),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(1) {
    grid-column: 7/13; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(2),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(2) {
    grid-column: 5/9; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(3),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(2):nth-last-of-type(3) {
    grid-column: 4/7; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(3):nth-last-of-type(1),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(3):nth-last-of-type(1) {
    grid-column: 9/13; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(3):nth-last-of-type(2),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(3):nth-last-of-type(2) {
    grid-column: 7/10; }
  .responsive-skin .composite-multi-input-with-drop-down-labeled .composite-input-with-drop-down-symbol:nth-of-type(4):nth-last-of-type(1),
  .responsive-skin .composite-multi-input-with-drop-down .composite-input-with-drop-down-symbol:nth-of-type(4):nth-last-of-type(1) {
    grid-column: 10/13; }

.responsive-skin .composite-one-input-in-row {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between; }
  .responsive-skin .composite-one-input-in-row > * {
    width: calc(50% - 6px); }

.responsive-skin .composite-two-inputs-in-row {
  padding: 6px 24px;
  display: flex;
  justify-content: space-between; }
  .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label {
    padding: 6px 24px 6px 24px; }
    .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label div {
      width: initial; }
    .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label .composite-input-with-drop-down-labeled-horizontal {
      width: initial; }
      .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label .composite-input-with-drop-down-labeled-horizontal .control-label {
        margin-right: 4px; }
        .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label .composite-input-with-drop-down-labeled-horizontal .control-label .control-label-base {
          width: 20px; }
      .responsive-skin .composite-two-inputs-in-row.enforce-condense-input-label .composite-input-with-drop-down-labeled-horizontal .input-container {
        width: 56px; }
  .responsive-skin .composite-two-inputs-in-row > * {
    width: calc(50% - 6px); }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row {
  padding: 0; }

.responsive-skin .composite-panel-content .composite-two-inputs-in-row {
  justify-content: flex-start; }

.responsive-skin .composite-multi-rows-of-two-inputs-in-row .composite-two-inputs-in-row {
  padding-left: 0;
  padding-right: 0; }

.responsive-skin .composite-two-inputs-with-label {
  padding: 12px 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .responsive-skin .composite-two-inputs-with-label input {
    max-width: 56px; }
  .responsive-skin .composite-two-inputs-with-label > * {
    width: calc(33%);
    padding: 0; }
  .responsive-skin .composite-two-inputs-with-label > span {
    flex-grow: 1; }
  .responsive-skin .composite-two-inputs-with-label > div:first-of-type {
    margin-right: 8px; }
  .responsive-skin .composite-two-inputs-with-label .composite-input-with-drop-down-labeled {
    flex-direction: row; }
    .responsive-skin .composite-two-inputs-with-label .composite-input-with-drop-down-labeled > * {
      margin-top: 0; }
    .responsive-skin .composite-two-inputs-with-label .composite-input-with-drop-down-labeled .control-label {
      margin-right: 0; }
    .responsive-skin .composite-two-inputs-with-label .composite-input-with-drop-down-labeled .control-label-base {
      font-size: 9px; }
  .responsive-skin .composite-two-inputs-with-label .control-numeric-input {
    min-width: unset; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input > * {
      margin-top: 0;
      padding: 0; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input .control-label-base {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 11px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      font-size: 9px;
      color: #131720; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input label {
      margin-right: 4px;
      align-self: center; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input .input-prefix {
      line-height: unset; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input .input-text-suffix {
      top: 6px;
      right: 5px;
      margin-top: 1px; }
    .responsive-skin .composite-two-inputs-with-label .control-numeric-input input.has-text-suffix.control-numeric-input {
      width: 100%; }

.responsive-skin .composite-input-with-label-and-symbol {
  padding: 12px 24px 5px;
  display: flex;
  justify-content: space-between; }
  .responsive-skin .composite-input-with-label-and-symbol > * {
    padding: 0; }
  .responsive-skin .composite-input-with-label-and-symbol .control-label {
    flex-grow: 2; }
  .responsive-skin .composite-input-with-label-and-symbol .symbol {
    width: 10px;
    align-self: center;
    padding-left: 4px; }
  .responsive-skin .composite-input-with-label-and-symbol .control-numeric-input.input-container {
    width: calc(33% - 22px);
    margin-right: 8px;
    margin-top: -6px;
    min-width: unset; }
    .responsive-skin .composite-input-with-label-and-symbol .control-numeric-input.input-container > * {
      margin-top: 0; }
    .responsive-skin .composite-input-with-label-and-symbol .control-numeric-input.input-container .input {
      width: 100%; }
    .responsive-skin .composite-input-with-label-and-symbol .control-numeric-input.input-container .input-text-suffix {
      right: 10px; }

.responsive-skin .composite-input-labeled {
  padding: 8px 20px;
  display: flex;
  flex-direction: column-reverse; }
  .composite-two-inputs-in-row .responsive-skin .composite-input-labeled {
    padding: 0; }

.responsive-skin .composite-panel-content .composite-two-inputs-in-row .composite-input-labeled {
  display: flex;
  justify-items: flex-start;
  padding: 0;
  width: auto; }
  .responsive-skin .composite-panel-content .composite-two-inputs-in-row .composite-input-labeled:not(:last-of-type) {
    padding-right: 20px; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row:first-child {
  float: right; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row:last-child {
  float: left; }

.responsive-skin .composite-multi-inputs-in-row .composite-two-inputs-in-row .composite-input-labeled {
  padding: 0;
  width: 52px; }

.responsive-skin .composite-icon-tabs {
  display: flex;
  flex-direction: column;
  margin: 8px 20px; }

.responsive-skin .composite-collapse-label-with-info-icon {
  position: relative; }

.responsive-skin .composite-text-label-with-icon {
  display: flex;
  align-items: center; }
  .responsive-skin .composite-text-label-with-icon .control-icon-button {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    fill: #42454C;
    vertical-align: middle;
    padding-right: 8px;
    float: left; }

.responsive-skin .composite-two-text-labels-in-row .composite-text-label-with-icon .symbol {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  float: left;
  vertical-align: middle;
  padding-right: 8px; }

.responsive-skin .composite-two-text-labels-in-row .composite-text-label-with-icon .control-label {
  float: right; }

.responsive-skin .composite-two-text-labels-in-row > .composite-text-label-with-icon:first-child,
.responsive-skin .composite-text-label-with-icon:first-child {
  padding-right: 0; }

.responsive-skin .composite-two-text-labels-in-row > .composite-text-label-with-icon:nth-child(2),
.responsive-skin .composite-text-label-with-icon:nth-child(2) {
  float: right;
  padding-left: 0; }

.responsive-skin .composite-two-text-labels-in-row {
  padding: 12px 24px; }

.responsive-skin .composite-default-panel-padding {
  padding: 12px 24px; }

.responsive-skin .composite-docking-control-with-label {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between; }

.responsive-skin .composite-multi-inputs-in-row {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between; }

.responsive-skin .composite-drop-down-labeled-horizontal {
  padding: 12px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr; }

.responsive-skin .composite-drop-down-labeled-vertical {
  padding: 12px 24px;
  display: grid;
  grid-template-rows: 1fr 1fr; }

.responsive-skin .composite-multi-rows-of-two-inputs-in-row {
  padding: 12px 24px; }
  .responsive-skin .composite-multi-rows-of-two-inputs-in-row:first-child {
    padding-top: 0; }
  .responsive-skin .composite-multi-rows-of-two-inputs-in-row:last-child {
    padding-bottom: 0; }

.responsive-skin .composite-numeric-input-labeled {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px; }

.responsive-skin .composite-two-inputs-in-row .composite-numeric-input-labeled {
  padding: 0; }

.responsive-skin .composite-thumbnails-labeled-horizontal {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px; }

.responsive-skin .composite-panel-footer-button-with-icon {
  display: grid;
  grid-template-columns: 240px 60px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 6, 36, 0.13);
  background-color: #fff; }

.responsive-skin .composite-input-with-symbol-below {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  height: 52px; }
  .responsive-skin .composite-input-with-symbol-below .control-dropdown {
    border-radius: 4px;
    border: solid 1px #e2e3ea;
    height: 30px; }
    .responsive-skin .composite-input-with-symbol-below .control-dropdown .selected-content.has-arrow-icon {
      width: calc(100% - 12px); }
  .responsive-skin .composite-input-with-symbol-below .control-numeric-input.input-container {
    min-width: 44px;
    width: 44px; }
    .responsive-skin .composite-input-with-symbol-below .control-numeric-input.input-container .input {
      width: 44px; }
  .responsive-skin .composite-input-with-symbol-below svg {
    width: 12px;
    height: 12px; }
    .responsive-skin .composite-input-with-symbol-below svg path {
      fill: #868aa5; }

.responsive-skin .composite-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  margin: 0 24px; }

.responsive-skin .composite-input-with-mentions {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 22px; }

.responsive-skin .composite-button-text-label-and-icon-button-in-row {
  display: flex;
  justify-content: space-between; }
  .responsive-skin .composite-button-text-label-and-icon-button-in-row .control-label .control-button {
    padding-right: 0; }
  .responsive-skin .composite-button-text-label-and-icon-button-in-row .control-label .composite-text-label-with-icon {
    float: right;
    padding-left: 0; }

.classic-facelift-skin .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle > svg {
  fill: #116dff; }

.sbdl-item-row-base {
  display: flex;
  min-height: 47px;
  padding: 0 24px; }
  .sbdl-item-row-base.skin-general {
    background-color: #f7f8f8; }
  .sbdl-item-row.disabled > .sbdl-item-row-base .drag-handle-container .symbol-dragHandle {
    fill: #bcbcbc; }
  .sbdl-item-row-base .content {
    width: 100%;
    min-width: 0;
    display: flex; }
  .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 12px;
    fill: #116dff; }
  .sbdl-item-row-base.disabled-drag-handle .symbol-dragHandle {
    fill: #bcbcbc !important; }

.classic-facelift-skin .sbdl-text-edit .input-container .input:hover,
.classic-facelift-skin .sbdl-text-edit .input-container .input:focus,
.classic-facelift-skin.input-borders-experiment .sbdl-text-edit .input-container .input:hover,
.classic-facelift-skin.input-borders-experiment .sbdl-text-edit .input-container .input:focus {
  background-color: #fff;
  color: #000624; }

.invalid.sbdl-text-edit .input-container .classic-facelift-skin .input {
  border-color: #e62214; }

.sbdl-text-edit {
  cursor: default;
  display: flex;
  gap: 12px;
  width: 100%; }
  .sbdl-text-edit > * {
    flex: 1 1 auto; }
  .sbdl-text-edit .input-container .input:hover,
  .sbdl-text-edit .input-container .input:focus {
    background-color: #fff; }
  .invalid.sbdl-text-edit .input-container .input {
    border: 1px solid #e62214; }

.classic-facelift-skin .sbdl-item-row {
  background-color: #fff; }
  .classic-facelift-skin .sbdl-item-row.edit-mode {
    background-color: #d6e6fe; }
  .classic-facelift-skin .sbdl-item-row.selected {
    background-color: #d6e6fe; }
  .classic-facelift-skin .sbdl-item-row.dragging {
    background-color: #e7f0ff; }
  .classic-facelift-skin .sbdl-item-row:not(.selected):hover.selectable {
    background-color: #e7f0ff; }
  .classic-facelift-skin .sbdl-item-row.has-value .view-mode-content .value {
    color: #868aa5; }
    .classic-facelift-skin .sbdl-item-row.has-value .view-mode-content .value.placeholder {
      color: #acafc4; }
  .classic-facelift-skin .sbdl-item-row .view-mode-content {
    color: #3b4057; }
    .classic-facelift-skin .sbdl-item-row .view-mode-content .label.placeholder {
      color: #acafc4; }

.sbdl-item-row {
  width: 100%;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  -webkit-user-select: none;
          user-select: none;
  cursor: default; }
  .sbdl-item-row.edit-mode {
    background-color: #d6e6fe; }
  .sbdl-item-row.disabled {
    pointer-events: none;
    cursor: default; }
    .sbdl-item-row.disabled .view-mode-content .label,
    .sbdl-item-row.disabled .view-mode-content .value {
      color: #939393; }
  .sbdl-item-row.draggable:not(.selectable) {
    cursor: move; }
  .sbdl-item-row.draggable .drag-handle-container {
    cursor: move; }
  .sbdl-item-row:not(.draggable) {
    cursor: default; }
  .sbdl-item-row.selected {
    background-color: #d6e6fe; }
    .sbdl-item-row.selected .view-mode-content .item-row-action-container .item-row-action-wrapper {
      visibility: visible; }
  .sbdl-item-row.dragging {
    background-color: #e7f0ff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    cursor: move; }
    .sbdl-item-row.dragging .view-mode-content .item-row-action-container .item-row-action-wrapper {
      visibility: visible; }
  .sbdl-item-row:not(.selected) .view-mode-content .item-row-action-container .item-row-action-wrapper {
    visibility: hidden; }
  .sbdl-item-row:not(.selected):hover.selectable {
    background-color: #e7f0ff;
    cursor: pointer; }
  .sbdl-item-row:not(.selected):hover .view-mode-content .item-row-action-container .item-row-action-wrapper {
    visibility: visible; }
  .sbdl-item-row .text-edit-container {
    height: 47px;
    display: flex;
    align-items: center; }
    .sbdl-item-row .text-edit-container .input-container > input {
      height: 24px;
      border-radius: 4px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
    .sbdl-item-row .text-edit-container .control-button {
      height: 24px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      padding: 0 12px;
      flex: 1 0 auto;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content; }
  .large.sbdl-item-row.custom-row:not(.has-value) .view-mode-content {
    height: 78px; }
  .sbdl-item-row.has-value .view-mode-content {
    min-height: 65px; }
    .multiline.sbdl-item-row.has-value .view-mode-content {
      height: auto; }
    .multiline .sbdl-item-row.has-value .view-mode-content {
      height: auto; }
    .sbdl-item-row.has-value .view-mode-content .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased; }
    .sbdl-item-row.has-value .view-mode-content .value {
      padding-top: 3px;
      color: #868aa5;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .multiline.sbdl-item-row.has-value .view-mode-content .value {
        white-space: normal; }
      .multiline .sbdl-item-row.has-value .view-mode-content .value {
        white-space: normal; }
      .sbdl-item-row.has-value .view-mode-content .value.placeholder {
        color: #b6c1cd; }
  .sbdl-item-row.has-value .text-edit-container {
    height: 65px; }
    .sbdl-item-row.has-value .text-edit-container.label-text-edit .input-container > input {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased; }
    .sbdl-item-row.has-value .text-edit-container.value-text-edit .input-container > input {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased; }
  .sbdl-item-row.bold .view-mode-content .label {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-weight: 700; }
  .sbdl-item-row .view-mode-content {
    position: relative;
    width: 100%;
    color: #3b4057;
    display: flex;
    flex: 1 1 auto; }
    .large.sbdl-item-row .view-mode-content {
      height: 78px; }
    .sbdl-item-row .view-mode-content .text-container {
      display: flex;
      align-items: center;
      gap: 6px;
      overflow: auto;
      width: 100%; }
      .sbdl-item-row .view-mode-content .text-container > .content-before,
      .sbdl-item-row .view-mode-content .text-container > .content-after {
        flex-shrink: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center; }
      .large.sbdl-item-row .view-mode-content .text-container > .content-before {
        width: 100px; }
      .sbdl-item-row .view-mode-content .text-container > .dynamic-width {
        width: auto; }
      .sbdl-item-row .view-mode-content .text-container > .content-after {
        max-width: 18px; }
      .sbdl-item-row .view-mode-content .text-container > .text-inner-content {
        flex: 1 1 auto;
        min-width: 0;
        margin: 12px 0; }
    .sbdl-item-row .view-mode-content .label {
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .sbdl-item-row .view-mode-content .label.placeholder {
        color: #b6c1cd; }
    .sbdl-item-row .view-mode-content .item-row-action-container .item-row-action-wrapper .dropdown-selected.is-open {
      visibility: visible; }
  .sbdl-item-row .dynamic-height {
    height: auto; }

.sbdl-item-row-context-menu > .dropdown-options {
  width: auto !important; }

.responsive-skin .sort-by-drag-list {
  width: 100%; }
  .responsive-skin .sort-by-drag-list:not(.no-border-top) {
    border-top: 1px solid #e5e5e5; }
  .responsive-skin .sort-by-drag-list .sbdl-item-row {
    width: 100%;
    border-bottom: 0; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.selected {
      background-color: #dce9ff; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row.selected svg {
        fill: #116dff; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row.selected .view-mode-content .label,
      .responsive-skin .sort-by-drag-list .sbdl-item-row.selected .view-mode-content .value {
        color: #116dff; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row:hover.selectable {
      background-color: #f4f5f8; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.disabled svg {
      fill: #bebebe; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.disabled .view-mode-content .label,
    .responsive-skin .sort-by-drag-list .sbdl-item-row.disabled .view-mode-content .value {
      padding-top: 0;
      color: #bebebe; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.disabled .text-container > .content-before,
    .responsive-skin .sort-by-drag-list .sbdl-item-row.disabled .text-container > .content-after {
      opacity: 0.3; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.dragging {
      background-color: #f8faff; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row .sbdl-item-row-base {
      min-height: 44px;
      width: auto; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle {
        fill: #42454C; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row .text-edit-container {
      height: 44px; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row .text-edit-container .control-button {
        flex: 0 1 auto;
        font-size: 12px;
        line-height: 16px;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular); }
    .responsive-skin .sort-by-drag-list .sbdl-item-row .view-mode-content {
      height: 100%;
      color: #131720; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row .view-mode-content .text-container {
        gap: 8px; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row .view-mode-content .label {
        font-size: 12px;
        line-height: 16px; }
      .responsive-skin .sort-by-drag-list .sbdl-item-row .view-mode-content .text-inner-content {
        margin: 10px 0; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .view-mode-content,
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .text-edit-container {
      min-height: 56px; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .label {
      font-size: 14px;
      line-height: 20px; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .value {
      padding-top: 0;
      color: #42454C;
      font-size: 12px;
      line-height: 16px; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .text-edit-container.label-text-edit .input-container > input {
      font-size: 12px;
      line-height: 16px; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.has-value .text-edit-container.value-text-edit .input-container > input {
      font-size: 12px;
      line-height: 16px; }
    .responsive-skin .sort-by-drag-list .sbdl-item-row.edit-mode {
      background-color: #fff; }
  .responsive-skin .sort-by-drag-list .sbdl-text-edit {
    gap: 8px; }
    .responsive-skin .sort-by-drag-list .sbdl-text-edit .input-container {
      width: 100%; }
      .responsive-skin .sort-by-drag-list .sbdl-text-edit .input-container .input:hover {
        border: 1px solid #868aa5; }
      .responsive-skin .sort-by-drag-list .sbdl-text-edit .input-container .input:focus {
        border: 1px solid #116dff; }
      .invalid.responsive-skin .sort-by-drag-list .sbdl-text-edit .input-container .input {
        border: 1px solid #ee4437; }

.classic-facelift-skin .sort-by-drag-list:not(.no-border-top) {
  border-top-color: #dfe5eb; }

.classic-facelift-skin .sort-by-drag-list .sbdl-drag-placeholder {
  background-color: #f7f8f8;
  border-top-color: #dfe5eb; }

.classic-facelift-skin .sort-by-drag-list .sbdl-item-row {
  border-bottom-color: #dfe5eb; }

.sort-by-drag-list {
  width: 100%;
  overflow-x: hidden; }
  .sort-by-drag-list:not(.no-border-top) {
    border-top: 1px solid #dfe5eb; }
  .sort-by-drag-list .sbdl-drag-placeholder {
    background-color: #f7f8f8;
    border-top: 1px dashed #dfe5eb; }
  .sort-by-drag-list.disabled {
    pointer-events: none; }
  .sort-by-drag-list .sbdl-item-row {
    border-bottom: 1px solid #dfe5eb; }

.control-drill-in-list-item.disabled .symbol-arrowRight > svg > path {
  fill: #bcbcbc; }

.classic-facelift-skin .control-drill-in-list-item {
  border-bottom-color: #dfe5eb; }
  .classic-facelift-skin .control-drill-in-list-item > .content > .label {
    color: #3b4057; }
  .classic-facelift-skin .control-drill-in-list-item > .content > .description {
    color: #000624; }
  .classic-facelift-skin .control-drill-in-list-item > .symbol {
    width: 30px;
    height: 30px;
    background-color: #fff; }
    .classic-facelift-skin .control-drill-in-list-item > .symbol svg * {
      fill: #116dff; }
  .classic-facelift-skin .control-drill-in-list-item .content-after {
    margin: 0 3px; }
  .classic-facelift-skin .control-drill-in-list-item .symbol-arrowRight {
    margin-left: 3px; }

.classic-facelift-skin .control-drill-in-list-item:not(.disabled):hover {
  background: #e7f0ff; }

.responsive-skin .control-drill-in-list-item {
  padding: 8px 24px;
  background-color: #fff; }
  .responsive-skin .control-drill-in-list-item > .content {
    padding: 0; }
    .responsive-skin .control-drill-in-list-item > .content > .label {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: #131720;
      letter-spacing: 1px; }
    .responsive-skin .control-drill-in-list-item > .content > .description {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: #42454C;
      margin-top: 0; }
  .responsive-skin .control-drill-in-list-item > .symbol {
    height: 24px;
    width: 24px; }
    .responsive-skin .control-drill-in-list-item > .symbol svg * {
      fill: #131720; }

.responsive-skin .content-after,
.responsive-skin .symbol-arrowRight {
  margin-left: 8px; }

.responsive-skin .control-drill-in-list-item:not(.disabled):hover {
  background: #fff; }

.responsive-skin .disabled.control-drill-in-list-item > .content > .label, .responsive-skin .disabled.control-drill-in-list-item > .content > .description {
  color: #bebebe; }

.responsive-skin .disabled.control-drill-in-list-item > .symbol svg * {
  fill: #bebebe; }

.control-drill-in-list-item {
  -webkit-user-select: none;
          user-select: none;
  padding: 15px 18px 14px 24px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #dfe5eb; }
  .control-drill-in-list-item.disabled {
    cursor: default; }
  .control-drill-in-list-item:not(.disabled):hover {
    background: #e7f0ff; }
  .control-drill-in-list-item > .content {
    flex: 1 1 auto;
    min-width: 0; }
    .control-drill-in-list-item > .content > .label,
    .control-drill-in-list-item > .content > .description {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #3b4057;
      flex: 1;
      max-width: 100%; }
      .disabled.control-drill-in-list-item > .content > .label, .disabled.control-drill-in-list-item > .content > .description {
        color: #939393; }
    .control-drill-in-list-item > .content > .description {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      margin-top: 3px; }
  .control-drill-in-list-item > .content-after {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto; }
  .control-drill-in-list-item > .symbol {
    flex: 0 0 auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff; }

.classic-facelift-skin .add-item-icon.default .plus {
  fill: #80b1ff; }

.classic-facelift-skin .add-item-icon.default .border {
  fill: #a8caff; }

.classic-facelift-skin .add-item-icon.default .checkmark {
  visibility: hidden; }

.classic-facelift-skin .add-item-icon.default .fill {
  visibility: hidden; }

.classic-facelift-skin .add-item-icon.default:hover .border {
  fill: #80b1ff; }

.disabled.classic-facelift-skin .add-item-icon.default .border {
  fill: #d5d5d5; }

.disabled.classic-facelift-skin .add-item-icon.default .plus {
  fill: #d5d5d5; }

.classic-facelift-skin .add-item-icon.in-progress .checkmark {
  fill: #fff; }

.classic-facelift-skin .add-item-icon.in-progress .fill {
  fill: #25a55a; }

.classic-facelift-skin .add-item-icon.in-progress .border {
  visibility: hidden; }

.classic-facelift-skin .add-item-icon.in-progress .plus {
  visibility: hidden; }

.classic-facelift-skin .add-item-icon.added .checkmark {
  fill: #25a55a; }

.classic-facelift-skin .add-item-icon.added .border {
  fill: #c8e8d6; }

.classic-facelift-skin .add-item-icon.added .fill {
  visibility: hidden; }

.classic-facelift-skin .add-item-icon.added .plus {
  visibility: hidden; }

.add-item-icon.default .plus {
  fill: #80b1ff; }

.add-item-icon.default .border {
  fill: #a8caff; }

.add-item-icon.default .checkmark {
  visibility: hidden; }

.add-item-icon.default .fill {
  visibility: hidden; }

.add-item-icon.default:hover .border {
  fill: #80b1ff; }

.disabled.add-item-icon.default .border {
  fill: #d5d5d5; }

.disabled.add-item-icon.default .plus {
  fill: #d5d5d5; }

.add-item-icon.in-progress .checkmark {
  fill: #fff; }

.add-item-icon.in-progress .fill {
  fill: #25a55a; }

.add-item-icon.in-progress .border {
  visibility: hidden; }

.add-item-icon.in-progress .plus {
  visibility: hidden; }

.add-item-icon.added .checkmark {
  fill: #25a55a; }

.add-item-icon.added .border {
  fill: #c8e8d6; }

.add-item-icon.added .fill {
  visibility: hidden; }

.add-item-icon.added .plus {
  visibility: hidden; }

.classic-facelift-skin .control-addable-list-item {
  border-top-color: #dfe5eb;
  box-shadow: 0 1px 0 #dfe5eb; }
  .classic-facelift-skin .control-addable-list-item > .list-item-content > .list-item-description {
    color: #3b4057; }
  .classic-facelift-skin .control-addable-list-item > .list-item-content-after {
    color: #000624; }
  .classic-facelift-skin .control-addable-list-item > .list-item-content-before {
    color: #000624; }
    .classic-facelift-skin .control-addable-list-item > .list-item-content-before > .circle {
      border-color: #dfe5eb; }

.classic-facelift-skin .control-addable-list-item:not(.disabled):hover {
  background: #e7f0ff; }

.control-addable-list-item {
  -webkit-user-select: none;
          user-select: none;
  padding: 15px 18px 14px 24px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-top: 1px solid #dfe5eb;
  box-shadow: 0 1px 0 #dfe5eb;
  transition: opacity 0.8s; }
  .control-addable-list-item.after-click {
    opacity: 0; }
  .control-addable-list-item.disabled {
    cursor: default; }
  .control-addable-list-item:not(.disabled):hover {
    background: #e7f0ff; }
  .control-addable-list-item > .list-item-content {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column; }
    .control-addable-list-item > .list-item-content > .list-item-description {
      color: #3b4057; }
      .disabled.control-addable-list-item > .list-item-content > .list-item-description {
        color: #939393; }
    .disabled.control-addable-list-item > .list-item-content > .list-item-label {
      color: #939393; }
  .control-addable-list-item > .list-item-content-after {
    color: #000624;
    display: flex;
    margin-right: 7px;
    margin-left: 7px; }
    .disabled.control-addable-list-item > .list-item-content-after {
      fill: #939393; }
  .control-addable-list-item > .list-item-content-before {
    color: #000624;
    display: flex;
    margin-right: 8px; }
    .disabled.control-addable-list-item > .list-item-content-before {
      fill: #939393; }
    .control-addable-list-item > .list-item-content-before > .circle {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border: 1px solid #dfe5eb;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; }
      .disabled.control-addable-list-item > .list-item-content-before > .circle {
        border-color: #939393; }
  .control-addable-list-item > .symbol {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .control-addable-list-item * {
    cursor: pointer; }

.responsive-skin .composite-input-with-drop-down-symbol .symbol {
  margin-top: 8px;
  fill: #868aa5;
  display: flex;
  justify-content: center; }
  .responsive-skin .composite-input-with-drop-down-symbol .symbol .has-tooltip {
    display: inline-block; }

.responsive-skin .composite-text-label-with-icon .symbol {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  fill: #868aa5;
  float: left;
  vertical-align: middle;
  padding-right: 8px; }

.control-input-with-confirm {
  display: flex;
  align-items: center; }
  .control-input-with-confirm .input-container {
    flex: 1; }
  .control-input-with-confirm .buttons-container {
    padding-left: 6px; }
    .control-input-with-confirm .buttons-container .control-button:nth-child(2) {
      margin-left: 6px; }

.color-picker-reset-color {
  width: 134px;
  height: 12px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #dfe5eb;
  box-sizing: border-box; }
  .color-picker-reset-color > .color-picker-reset-color-option {
    width: 50%;
    height: 100%; }
    .color-picker-reset-color > .color-picker-reset-color-option.color-picker-reset-color-button {
      cursor: pointer; }

.color-picker-color-format > .color-picker-color-format-input-container {
  display: flex;
  height: 58px;
  padding: 18px 14px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center; }
  .color-picker-color-format > .color-picker-color-format-input-container .input-container.color-picker-color-format-hex {
    flex: 1; }
    .color-picker-color-format > .color-picker-color-format-input-container .input-container.color-picker-color-format-hex > .input.color-picker-color-format-input {
      padding-left: calc(50% - 24px);
      width: 100%;
      text-align: left; }
    .color-picker-color-format > .color-picker-color-format-input-container .input-container.color-picker-color-format-hex::before {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      content: "#";
      position: absolute;
      left: calc(50% - 31px);
      height: 100%;
      display: flex;
      align-items: center; }
  .color-picker-color-format > .color-picker-color-format-input-container .input-container .input:not(.has-text-suffix) {
    text-align: center; }
  .color-picker-color-format > .color-picker-color-format-input-container .input-container .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 24px;
    line-height: 24px; }
  .color-picker-color-format > .color-picker-color-format-input-container .input-container .symbol-input-validation-success,
  .color-picker-color-format > .color-picker-color-format-input-container .input-container .symbol-input-validation-error {
    height: 18px; }

.color-picker-my-colors {
  display: flex;
  flex-wrap: wrap; }
  .color-picker-my-colors > .color-picker-my-colors-option {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    width: 32px; }
    .color-picker-my-colors > .color-picker-my-colors-option .color-picker-my-colors-add-inner {
      border-radius: 50%;
      height: 22px;
      width: 22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border: 1px solid #116dff; }
    .color-picker-my-colors > .color-picker-my-colors-option:hover:not(.selected-color) > .color-picker-my-colors-option-inner {
      height: 26px;
      width: 26px; }
    .color-picker-my-colors > .color-picker-my-colors-option:hover > .color-picker-my-colors-add-inner {
      box-shadow: 0 0 0 3px rgba(56, 153, 236, 0.3); }
    .color-picker-my-colors > .color-picker-my-colors-option.selected-color > .color-picker-my-colors-option-inner {
      box-shadow: 0 0 1px 2px #fff, 0 0 0 3px #868aa5; }
    .color-picker-my-colors > .color-picker-my-colors-option.light > .color-picker-my-colors-option-inner {
      box-sizing: border-box;
      border: 1px solid #ccc; }
    .color-picker-my-colors > .color-picker-my-colors-option > .color-picker-my-colors-option-inner {
      border-radius: 50%;
      height: 22px;
      width: 22px; }

.color-picker-choose-color-dialog {
  width: 210px; }
  .color-picker-choose-color-dialog > .choose-color-dialog-reset {
    margin: 0 auto; }
  .color-picker-choose-color-dialog > .choose-color-dialog-my-colors-label {
    margin: 20px 14px 12px; }
  .color-picker-choose-color-dialog > .choose-color-dialog-my-colors {
    margin-left: 9px; }
    .color-picker-choose-color-dialog > .choose-color-dialog-my-colors .choose-color-dialog-my-colors-content {
      height: 100px; }
  .color-picker-choose-color-dialog > .choose-color-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 14px; }

.color-picker-palette {
  width: 100%;
  font-size: 0; }
  .color-picker-palette .palette-single-color-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    z-index: 0;
    height: 24px;
    width: 20%;
    overflow: visible; }
    .color-picker-palette .palette-single-color-container:hover {
      z-index: 1; }
      .color-picker-palette .palette-single-color-container:hover .palette-single-color {
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px); }
    .color-picker-palette .palette-single-color-container.selected-color {
      z-index: 2; }
      .color-picker-palette .palette-single-color-container.selected-color .palette-single-color {
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        box-shadow: 0 0 7px 0 rgba(22, 45, 61, 0.44);
        border: solid 1px #fff; }
        .color-picker-palette .palette-single-color-container.selected-color .palette-single-color.light {
          border: 1px solid #ccc; }
    .color-picker-palette .palette-single-color-container .palette-single-color {
      position: absolute;
      width: 100%;
      height: 100%;
      display: inline-block;
      box-sizing: border-box; }
      .color-picker-palette .palette-single-color-container .palette-single-color.light:hover {
        box-shadow: 0 0 7px 0 rgba(22, 45, 61, 0.44); }
      .color-picker-palette .palette-single-color-container .palette-single-color.light:not(:hover) {
        border: 1px solid #ccc; }
  .color-picker-palette .palette-main-color-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 38px;
    margin-top: 12px; }
    .color-picker-palette .palette-main-color-container .palette-main-color {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      justify-content: initial;
      cursor: pointer;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%; }
      .color-picker-palette .palette-main-color-container .palette-main-color .change-main-color-arrow {
        content: '';
        position: relative;
        top: -12px;
        width: 0;
        height: 0;
        border-bottom: 6px solid;
        border-right: 3.5px solid transparent;
        border-left: 3.5px solid transparent; }
      .color-picker-palette .palette-main-color-container .palette-main-color .change-main-color-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        visibility: hidden; }
      .color-picker-palette .palette-main-color-container .palette-main-color:hover .change-main-color-icon {
        visibility: visible; }

.color-picker-palette-picker > .color-picker-palette-picker-palette {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  height: 30px;
  width: 96px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative; }
  .color-picker-palette-picker > .color-picker-palette-picker-palette.selected-palette {
    border: 1px solid #116dff; }
    .color-picker-palette-picker > .color-picker-palette-picker-palette.selected-palette::before {
      position: absolute;
      content: '';
      font-size: initial;
      left: -11px;
      cursor: initial;
      width: 0;
      height: 0;
      border-right: 6px solid #116dff;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      top: 9px; }
  .color-picker-palette-picker > .color-picker-palette-picker-palette:hover:not(.selected-palette) {
    border: 1px solid #d6e6fe; }
  .color-picker-palette-picker > .color-picker-palette-picker-palette > .color-picker-palette-picker-color {
    height: 24px;
    width: 18px;
    box-sizing: border-box; }
    .color-picker-palette-picker > .color-picker-palette-picker-palette > .color-picker-palette-picker-color.light {
      border: 1px solid #CCC; }

.color-picker-palette-dialog .control-button.btn-back:not(.btn-header-back) {
  width: 132px;
  margin-left: 0;
  position: relative;
  top: -1px; }
  .color-picker-palette-dialog .control-button.btn-back:not(.btn-header-back) span {
    color: #116dff; }
  .color-picker-palette-dialog .control-button.btn-back:not(.btn-header-back) .palette-dialog-arrow-left {
    display: flex;
    position: absolute;
    left: 10px;
    top: 0;
    height: 21px;
    align-items: center; }

.color-picker-palette-dialog > .palette-dialog-change-colors-label {
  display: flex;
  align-items: center;
  height: 48px;
  margin: 0 14px; }

.color-picker-palette-dialog > .palette-dialog-my-colors-label {
  margin: 20px 14px 12px; }

.color-picker-palette-dialog > .palette-dialog-my-colors {
  margin-left: 9px; }
  .color-picker-palette-dialog > .palette-dialog-my-colors .palette-dialog-my-colors-content {
    height: 132px; }

.color-picker-palette-dialog > .palette-picker-info-divider {
  margin: 12px 0 20px 0; }

.color-picker-palette-dialog > .palette-picker-info-text {
  margin: 0 14px;
  width: calc(100% - 28px);
  height: 99px; }

.color-picker-palette-dialog > .palette-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 0 14px; }

.color-picker-palette-picker-dialog {
  width: 140px;
  padding-left: 16px; }
  .color-picker-palette-picker-dialog > .palette-picker-dialog-header {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .color-picker-palette-picker-dialog .palette-picker-dialog-palettes {
    display: flex;
    justify-content: center;
    height: 393px; }

.responsive-skin .corvid-site-structure .control-button.btn-text {
  color: #116dff; }
  .responsive-skin .corvid-site-structure .control-button.btn-text:hover {
    color: #0f62e6; }
  .responsive-skin .corvid-site-structure .control-button.btn-text.btn-sm {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased; }
  .responsive-skin .corvid-site-structure .control-button.btn-text.btn-md {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased; }

.responsive-skin .corvid-site-structure .tree-section-divider {
  grid-template-columns: 10fr 1fr 1fr; }

.responsive-skin .corvid-site-structure .tab-container .tab-header .info-icon-tooltip {
  padding-right: 5px; }

.responsive-skin .corvid-site-structure .tab-container .tab-header .tab-header-label {
  padding-left: 5px; }
  .responsive-skin .corvid-site-structure .tab-container .tab-header .tab-header-label .control-label-base {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    color: #131720; }

.responsive-skin .corvid-site-structure .tab-container .tree-section-divider {
  margin-bottom: 4px; }

.responsive-skin .corvid-site-structure > .vertical-tabs > .empty-space {
  background-color: #fff;
  box-shadow: inset -1px 0 0 0 #e5e5e5; }

.responsive-skin .corvid-site-structure > .vertical-tabs > .empty-space.box-shadow-no-right-line {
  background-color: #f7fbff; }

.responsive-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:hover + .tab-icon-text-wrapper,
.responsive-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
  background-color: #f4f5f8; }

.responsive-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
  background-color: #dce9ff;
  border-top: none;
  border-bottom: none;
  border-right: none; }
  .responsive-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper .tab-icon svg {
    fill: #116dff; }

.classic-facelift-skin .corvid-site-structure .tab-container .tab-header {
  border-bottom-color: #dfe5eb; }
  .classic-facelift-skin .corvid-site-structure .tab-container .tab-header .tab-header-label {
    color: #000624; }
    .classic-facelift-skin .corvid-site-structure .tab-container .tab-header .tab-header-label span.t21 {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }

.classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom {
  background-color: #f7f8f8;
  box-shadow: inset -1px 0 0 0 #dfe5eb; }
  .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .tab-text, .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .advanced-style-panel .tab-text-composite, .advanced-style-panel .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .tab-text-composite {
    color: #3b4057; }
  .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper .tab-icon svg {
    fill: #000624; }
  .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
    background-color: #e7f0ff;
    border-top-color: #dfe5eb;
    border-bottom-color: #dfe5eb; }
    .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper .tab-icon svg {
      fill: #116dff; }
  .classic-facelift-skin .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
    background-color: #e7f0ff;
    box-shadow: inset -1px 0 0 0 #dfe5eb; }

.classic-facelift-skin .corvid-site-structure > .vertical-tabs > .empty-space {
  background-color: #f7f8f8;
  box-shadow: inset -1px 0 0 0 #dfe5eb; }

.corvid-site-structure {
  height: 100%;
  display: flex; }
  .corvid-site-structure .tab-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden; }
    .corvid-site-structure .tab-container .tab-header {
      flex: 0 0 auto;
      display: flex;
      align-content: center;
      justify-content: space-between;
      padding: 11px 12px 11px 10px;
      border-bottom: 1px solid #dfe5eb; }
      .corvid-site-structure .tab-container .tab-header .tab-header-label {
        color: #3b4057;
        overflow-x: hidden; }
    .corvid-site-structure .tab-container .tab-content {
      height: 100%;
      overflow-y: auto; }
  .corvid-site-structure > .vertical-tabs {
    display: flex;
    flex-direction: column; }
    .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom {
      background-color: #f7f8f8;
      box-shadow: inset -1px 0 0 0 #dfe5eb;
      height: unset; }
      .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .tab-text, .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .advanced-style-panel .tab-text-composite, .advanced-style-panel .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .tab-text-composite {
        color: #3b4057; }
      .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper .tab-icon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input + .tab-icon-text-wrapper .tab-icon svg {
          width: unset;
          height: unset;
          position: unset;
          top: unset;
          left: unset;
          fill: #3b4057; }
      .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper {
        background-color: #fff;
        border-top: 1px solid #dfe5eb;
        border-bottom: 1px solid #dfe5eb;
        border-right: 1px solid #dfe5eb;
        box-shadow: none; }
        .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:checked + .tab-icon-text-wrapper .tab-icon svg {
          fill: #000624; }
      .corvid-site-structure > .vertical-tabs .control-tabs-vertical-custom .radio-button-wrapper input:not(:checked) + .tab-icon-text-wrapper:hover {
        background-color: #dfe5eb; }
    .corvid-site-structure > .vertical-tabs .control-vertical-tabs {
      width: 100%; }
    .corvid-site-structure > .vertical-tabs > .empty-space {
      flex: 1;
      background-color: #f7f8f8; }
    .corvid-site-structure > .vertical-tabs > .bottom-tabs {
      flex: 0; }

.responsive-skin .tree-list-item {
  background-color: #fff;
  padding-right: 16px; }
  .responsive-skin .tree-list-item .control-label-base {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    color: #131720; }
  .responsive-skin .tree-list-item .tree-list-item-suffix > .control-context-menu > .dropdown-selected > .context-menu-button {
    margin-top: 7px; }
  .responsive-skin .tree-list-item.selected {
    background-color: #dce9ff; }
    .responsive-skin .tree-list-item.selected .control-label-base {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: #116dff; }
    .responsive-skin .tree-list-item.selected .tree-list-item-icon svg * {
      fill: #116dff; }
  .responsive-skin .tree-list-item.placeholder {
    background-color: #e2e3ea; }
  .responsive-skin .tree-list-item:hover:not(.selected):not(.disabled):not(.underDragging), .responsive-skin .tree-list-item:focus-within:not(.selected):not(.disabled):not(.underDragging), .responsive-skin .tree-list-item.highlight:not(.selected):not(.disabled):not(.underDragging) {
    background-color: #f4f5f8; }
  .responsive-skin .tree-list-item .tree-list-item-handle {
    color: #116dff; }
    .responsive-skin .tree-list-item .tree-list-item-handle.disabled {
      color: #868aa5; }
  .responsive-skin .tree-list-item .tree-list-item-icon {
    margin-right: 8px; }
    .responsive-skin .tree-list-item .tree-list-item-icon svg * {
      fill: #868aa5; }
  .responsive-skin .tree-list-item .tree-list-item-text-wrapper .label-suffix {
    color: #42454C;
    padding-left: 16px; }

.classic-facelift-skin .tree-list-item {
  background-color: #fff;
  color: #000624; }
  .classic-facelift-skin .tree-list-item.selected {
    background-color: #e7f0ff; }
    .classic-facelift-skin .tree-list-item.selected .tree-list-item-text-wrapper .tree-list-item-text span {
      color: #116dff; }
    .classic-facelift-skin .tree-list-item.selected .tree-list-item-text-wrapper .label-suffix {
      color: #5999ff; }
  .classic-facelift-skin .tree-list-item.placeholder {
    background-color: #f7f8f8; }
  .classic-facelift-skin .tree-list-item.dragging {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08); }
  .classic-facelift-skin .tree-list-item:hover:not(.selected):not(.disabled):not(.underDragging), .classic-facelift-skin .tree-list-item:focus-within:not(.selected):not(.disabled):not(.underDragging), .classic-facelift-skin .tree-list-item.highlight:not(.selected):not(.disabled):not(.underDragging) {
    background-color: #e7f0ff; }
  .classic-facelift-skin .tree-list-item .tree-list-item-handle {
    color: #116dff; }
    .classic-facelift-skin .tree-list-item .tree-list-item-handle.disabled {
      color: #bcbcbc; }
  .classic-facelift-skin .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow path {
    fill: #868aa5; }
  .classic-facelift-skin .tree-list-item .tree-list-item-text-wrapper .label-suffix {
    color: #868aa5; }

.tree-list-item {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3b4057;
  padding: 0 12px 0 10px;
  cursor: pointer; }
  .tree-list-item.small {
    height: 28px; }
  .tree-list-item.medium {
    height: 36px; }
  .tree-list-item.selected {
    background-color: #d6e6fe; }
  .tree-list-item:hover:not(.selected):not(.disabled):not(.underDragging), .tree-list-item:focus-within:not(.selected):not(.disabled):not(.underDragging), .tree-list-item.highlight:not(.selected):not(.disabled):not(.underDragging) {
    background-color: #e7f0ff;
    outline: none; }
  .tree-list-item .depth-margin {
    margin-left: calc(var(--baseui-tree-list-item-depth) * 12px); }
  .tree-list-item.disabled {
    cursor: default;
    -webkit-user-select: none;
            user-select: none; }
    .tree-list-item.disabled .tree-list-item-arrow-wrapper path,
    .tree-list-item.disabled .tree-list-item-icon path {
      fill: #bcbcbc !important; }
  .tree-list-item .tree-list-item-handle {
    display: flex;
    margin-left: -10px;
    align-items: center;
    justify-content: center;
    flex: 0 0 20px;
    height: 100%; }
  .tree-list-item .tree-list-item-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin: 0 4px 0 -2px;
    flex: 0 0 12px;
    outline-color: #80b1ff; }
    .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow {
      display: flex;
      transition: all 0.2s; }
      .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow path {
        fill: #868aa5; }
      .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow.collapsed {
        transform: rotate(-90deg); }
      .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow.collapsed, .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow.expanded {
        cursor: pointer; }
      .tree-list-item .tree-list-item-arrow-wrapper .tree-list-item-arrow.none {
        display: none; }
  .tree-list-item .tree-list-item-icon {
    display: flex;
    margin-right: 6px; }
  .tree-list-item .tree-list-item-text-wrapper {
    flex: 1 1 auto;
    display: flex; }
    .tree-list-item .tree-list-item-text-wrapper,
    .tree-list-item .tree-list-item-text-wrapper .tree-list-item-text {
      outline-color: #80b1ff;
      overflow: hidden; }
    .tree-list-item .tree-list-item-text-wrapper .label-suffix {
      color: #868aa5; }
  .tree-list-item .tree-list-item-suffix {
    margin-left: 6px;
    opacity: 1; }
  .tree-list-item .tree-list-item-indication {
    margin: auto 0 auto 6px;
    display: flex; }
  .tree-list-item:not(:hover):not(:focus-within) .tree-list-item-suffix:not(.show-suffix).reserve-space, .tree-list-item.underDragging .tree-list-item-suffix:not(.show-suffix).reserve-space {
    visibility: hidden; }
  .tree-list-item:not(:hover):not(:focus-within) .tree-list-item-suffix:not(.show-suffix):not(.reserve-space), .tree-list-item.underDragging .tree-list-item-suffix:not(.show-suffix):not(.reserve-space) {
    overflow: hidden;
    opacity: 0;
    width: 0;
    margin: 0; }
  .tree-list-item:not(:hover):not(:focus-within) .tree-list-item-handle:not(.show-handle), .tree-list-item.underDragging .tree-list-item-handle:not(.show-handle) {
    visibility: hidden; }
  .tree-list-item.underDragging:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: grab; }
  .tree-list-item .tree-list-item-handle.move {
    cursor: move; }
  .tree-list-item .control-label:not(.disabled) {
    cursor: pointer; }
  .tree-list-item .context-menu-button {
    height: 20px;
    width: 20px;
    margin-top: 5px; }

.classic-facelift-skin .tree-list-rename-item {
  background-color: #d6e6fe; }
  .classic-facelift-skin .tree-list-rename-item .tree-list-rename-input.input-container:not(.invalid) .input {
    border-color: #80b1ff; }
  .classic-facelift-skin .tree-list-rename-item .tree-list-rename-input.input-container .input {
    color: #3b4057; }
    .classic-facelift-skin .tree-list-rename-item .tree-list-rename-input.input-container .input:focus {
      background-color: #fff; }
    .classic-facelift-skin .tree-list-rename-item .tree-list-rename-input.input-container .input:hover {
      background-color: #fff; }

.tree-list-rename-item {
  display: flex;
  align-items: center;
  background-color: #d6e6fe;
  padding: 0 12px;
  height: 28px; }
  .tree-list-rename-item > .depth-margin {
    margin-left: calc(var(--baseui-tree-list-rename-item-depth) * 18px); }
  .tree-list-rename-item .tree-list-rename-input.input-container:not(.invalid) .input {
    border: solid 1px #80b1ff; }
  .tree-list-rename-item .tree-list-rename-input.input-container .input {
    border-radius: 4px;
    height: 24px;
    font-size: 14px;
    color: #3b4057; }
    .tree-list-rename-item .tree-list-rename-input.input-container .input:focus {
      background-color: #fff; }
    .tree-list-rename-item .tree-list-rename-input.input-container .input:hover {
      background-color: #fff; }
  .tree-list-rename-item .tree-list-rename-input.input-container {
    width: 100%; }
    .tree-list-rename-item .tree-list-rename-input.input-container .input-validation-icon {
      visibility: hidden; }
    .tree-list-rename-item .tree-list-rename-input.input-container .input {
      width: 100%; }

.responsive-skin .tree-section-divider.large {
  grid-column-gap: 14px;
  height: 40px; }
  .responsive-skin .tree-section-divider.large .tree-section-arrow svg {
    width: 10px;
    height: 5px; }
  .responsive-skin .tree-section-divider.large .tree-section-text {
    margin-left: 10px; }
    .responsive-skin .tree-section-divider.large .tree-section-text .control-label-base {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 11px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: #131720; }

.responsive-skin .tree-section-divider.top {
  border-top: 1px solid #e5e5e5; }

.responsive-skin .tree-section-divider.bottom {
  border-bottom: 1px solid #e5e5e5; }

.responsive-skin .tree-section-divider .tree-section-suffix > .control-context-menu > .dropdown-selected > .context-menu-button {
  margin-top: 6px; }

.responsive-skin .tree-section-divider,
.responsive-skin .tree-section-divider.small {
  background-color: #f4f5f8;
  display: grid;
  grid-template-columns: 10fr 0.4fr 0.35fr;
  position: relative;
  height: 40px;
  padding-right: 24px; }
  .responsive-skin .tree-section-divider .tree-section-text,
  .responsive-skin .tree-section-divider.small .tree-section-text {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-left: 14px; }
    .responsive-skin .tree-section-divider .tree-section-text .control-label-base,
    .responsive-skin .tree-section-divider.small .tree-section-text .control-label-base {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 12px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 1px;
      color: #131720; }
  .responsive-skin .tree-section-divider .tree-section-arrow,
  .responsive-skin .tree-section-divider.small .tree-section-arrow {
    grid-column-start: 3;
    grid-column-end: 4;
    position: absolute;
    justify-self: center;
    margin-left: 12px; }
    .responsive-skin .tree-section-divider .tree-section-arrow svg *,
    .responsive-skin .tree-section-divider.small .tree-section-arrow svg * {
      fill: #131720; }
  .responsive-skin .tree-section-divider .tree-section-suffix,
  .responsive-skin .tree-section-divider.small .tree-section-suffix {
    display: flex;
    column-gap: 8px;
    color: #131720;
    justify-self: center;
    margin-left: 0px; }

.classic-facelift-skin .tree-section-divider {
  background-color: #f7f8f8;
  color: #000624; }
  .classic-facelift-skin .tree-section-divider.top {
    border-top-color: #dfe5eb; }
  .classic-facelift-skin .tree-section-divider.bottom {
    border-bottom-color: #dfe5eb; }
  .classic-facelift-skin .tree-section-divider .tree-section-arrow path {
    fill: #868aa5; }
  .classic-facelift-skin .tree-section-divider .tree-section-text {
    flex: 1;
    margin-left: 6px; }
    .classic-facelift-skin .tree-section-divider .tree-section-text span.t21 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 13px;
      -webkit-font-smoothing: antialiased;
      color: #000624; }

.tree-section-divider {
  display: flex;
  align-items: center;
  background-color: #f7f8f8;
  color: #000624;
  padding: 0 12px 0 10px;
  height: 28px;
  cursor: pointer; }
  .tree-section-divider.small {
    height: 28px; }
  .tree-section-divider.top {
    border-top: 1px solid #dfe5eb; }
  .tree-section-divider.bottom {
    border-bottom: 1px solid #dfe5eb; }
  .tree-section-divider .tree-section-arrow {
    display: flex;
    transition: all 0.2s; }
    .tree-section-divider .tree-section-arrow path {
      fill: #868aa5; }
    .tree-section-divider .tree-section-arrow.collapsed {
      transform: rotate(-90deg); }
  .tree-section-divider .tree-section-text {
    flex: 1;
    margin-left: 6px; }
  .tree-section-divider .tree-section-suffix {
    margin-left: 6px; }
  .tree-section-divider:not(:hover) .tree-section-suffix:not(.show-suffix) {
    display: none; }
  .tree-section-divider .tree-section-text-hack {
    overflow: hidden; }
  .tree-section-divider .control-label {
    cursor: pointer; }
  .tree-section-divider .context-menu-button {
    height: 20px;
    width: 20px;
    margin-top: 5px; }

.responsive-skin .step-control-wrapper {
  position: relative; }
  .responsive-skin .step-control-wrapper .step-up {
    border-color: #e2e3ea;
    height: 16px;
    padding: 0; }
  .responsive-skin .step-control-wrapper .step-down {
    height: 16px;
    border-bottom: 0;
    padding: 0; }
  .responsive-skin .step-control-wrapper button {
    font-size: 6px;
    border-color: #e2e3ea;
    color: #42454C; }
    .responsive-skin .step-control-wrapper button.control-button {
      background-color: transparent; }
      .responsive-skin .step-control-wrapper button.control-button:hover {
        background-color: #f4f5f8; }
        .responsive-skin .step-control-wrapper button.control-button:hover svg path {
          fill: #42454C; }
        .responsive-skin .step-control-wrapper button.control-button:hover:disabled {
          border-color: #bebebe;
          background-color: #f3f3f3; }
          .responsive-skin .step-control-wrapper button.control-button:hover:disabled svg path {
            fill: #bebebe; }
      .responsive-skin .step-control-wrapper button.control-button:disabled {
        border-color: #bebebe; }
        .responsive-skin .step-control-wrapper button.control-button:disabled svg path {
          fill: #bebebe; }
    .responsive-skin .step-control-wrapper button svg path {
      fill: #868aa5; }
  .responsive-skin .step-control-wrapper .input-container > .input {
    border-color: #e2e3ea; }
    .responsive-skin .step-control-wrapper .input-container > .input:disabled {
      border-color: #bebebe;
      background-color: #f3f3f3; }
  .responsive-skin .step-control-wrapper.small button {
    height: 14px; }
  .responsive-skin .step-control-wrapper.small .step-down {
    top: 13px; }
  .responsive-skin .step-control-wrapper:hover .input-container:not(.is-disabled) input {
    border-color: #868aa5; }
    .responsive-skin .step-control-wrapper:hover .input-container:not(.is-disabled) input:focus {
      border-color: #116dff; }
  .responsive-skin .step-control-wrapper:hover .control-button {
    border-color: #868aa5; }
    .responsive-skin .step-control-wrapper:hover .control-button:disabled {
      border-color: #bebebe; }

.classic-facelift-skin .step-control-wrapper button {
  color: #000624;
  background-color: transparent; }
  .classic-facelift-skin .step-control-wrapper button:hover {
    background-color: transparent; }
    .classic-facelift-skin .step-control-wrapper button:hover svg path {
      fill: #3b4057; }
  .classic-facelift-skin .step-control-wrapper button svg path {
    fill: #868aa5; }

.classic-facelift-skin.input-borders-experiment .step-control-wrapper button {
  border-left: 1px solid #a8caff; }

.responsive-skin.dark-mode .step-control-wrapper button.control-button:hover {
  background-color: transparent; }
  .responsive-skin.dark-mode .step-control-wrapper button.control-button:hover svg path {
    fill: #868aa5; }

.responsive-skin.dark-mode .step-control-wrapper .step-up,
.responsive-skin.dark-mode .step-control-wrapper .step-down {
  border-color: transparent; }
  .responsive-skin.dark-mode .step-control-wrapper .step-up:hover,
  .responsive-skin.dark-mode .step-control-wrapper .step-down:hover {
    border-color: transparent; }

.responsive-skin.dark-mode .step-control-wrapper:hover .step-up,
.responsive-skin.dark-mode .step-control-wrapper:hover .step-down {
  border-color: #868aa5; }

.responsive-skin.dark-mode .step-control-wrapper .input-container > .input {
  border-color: transparent; }
  .responsive-skin.dark-mode .step-control-wrapper .input-container > .input:disabled {
    border-color: transparent;
    background-color: #2B2E36;
    color: #42454C; }

.responsive-skin.dark-mode .step-control-wrapper button.control-button:disabled {
  border-color: transparent; }
  .responsive-skin.dark-mode .step-control-wrapper button.control-button:disabled svg path {
    fill: #42454C; }

.step-control-wrapper {
  position: relative; }
  .step-control-wrapper .step-up {
    top: 0;
    border-radius: 0 4px 0 0; }
    .step-control-wrapper .step-up > .button-content > svg {
      transform: rotate(180deg); }
  .step-control-wrapper .step-down {
    top: 15px;
    border-radius: 0 0 4px 0; }
  .step-control-wrapper button {
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    padding: 0;
    font-size: 6px;
    background-color: transparent;
    color: #000624; }
    .step-control-wrapper button:hover {
      background-color: transparent; }
      .step-control-wrapper button:hover svg path {
        fill: #3b4057; }
    .step-control-wrapper button svg path {
      fill: #868aa5; }
  .step-control-wrapper .input-container > .input {
    padding-right: 20px;
    height: 32px;
    border-radius: 4px; }

.classic-facelift-skin .control-stepper .step .title-wrapper .title {
  color: #116dff; }

.classic-facelift-skin .control-stepper .step .content-wrapper .divider {
  background-color: #116dff; }

.control-stepper .step:not(:first-child) {
  padding-top: 12px; }

.control-stepper .step .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  margin-bottom: 6px; }
  .control-stepper .step .title-wrapper .tooltip {
    display: none; }
  .control-stepper .step .title-wrapper .title {
    color: #116dff; }

.control-stepper .step .content-wrapper {
  display: flex; }
  .control-stepper .step .content-wrapper .divider {
    width: 2px;
    height: auto;
    background-color: #116dff; }
  .control-stepper .step .content-wrapper .content {
    flex: 1; }

.control-stepper .step:hover .title-wrapper .tooltip {
  display: flex; }

.responsive-skin:focus-visible {
  background-color: #f4f5f8; }

.responsive-skin .control-label .disabled {
  color: #bebebe; }

.responsive-skin .illustration-wrapper .disabled {
  opacity: 0.3; }

.responsive-skin .wbu-action-item:hover {
  background-color: #f4f5f8; }

.responsive-skin .wbu-action-item.selected {
  background-color: #dce9ff; }

.responsive-skin .wbu-action-item .info .illustration-wrapper {
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
  min-inline-size: 40px; }

.classic-facelift-skin .wbu-action-item {
  background-color: #fff; }
  .classic-facelift-skin .wbu-action-item:hover, .classic-facelift-skin .wbu-action-item.selected {
    background-color: #e7f0ff; }
  .classic-facelift-skin .wbu-action-item .info .description-wrapper .description {
    color: #868aa5; }

.wbu-action-item {
  display: flex;
  -webkit-padding-before: 12px;
          padding-block-start: 12px;
  -webkit-padding-after: 12px;
          padding-block-end: 12px;
  -webkit-padding-start: 24px;
          padding-inline-start: 24px;
  -webkit-padding-end: 24px;
          padding-inline-end: 24px;
  block-size: 114px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer; }
  .wbu-action-item:hover {
    background-color: #e7f0ff;
    cursor: pointer; }
  .wbu-action-item.disabled {
    pointer-events: none; }
  .wbu-action-item .info {
    overflow: hidden;
    flex: 1 1 auto;
    display: flex; }
    .wbu-action-item .info .illustration-wrapper {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      -webkit-margin-end: 18px;
              margin-inline-end: 18px;
      min-inline-size: 20px;
      max-inline-size: 90px; }
    .wbu-action-item .info .title-and-description {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .wbu-action-item .info .title-and-description:hover {
        cursor: pointer; }
      .wbu-action-item .info .title-and-description .title-wrapper {
        overflow: hidden; }
      .wbu-action-item .info .title-and-description .description-wrapper {
        max-block-size: 54px;
        overflow: hidden; }
        .wbu-action-item .info .title-and-description .description-wrapper .description {
          color: #868aa5; }
        .wbu-action-item .info .title-and-description .description-wrapper .inline-link-button {
          font-size: 14px;
          block-size: auto;
          line-height: normal; }
      .wbu-action-item .info .title-and-description .control-label {
        cursor: pointer; }
  .wbu-action-item.tiny-size {
    block-size: 48px; }
    .wbu-action-item.tiny-size .description-wrapper {
      display: none; }
  .wbu-action-item.small-size {
    block-size: 66px; }
    .wbu-action-item.small-size .description-wrapper {
      display: none; }
  .wbu-action-item.auto-size {
    block-size: auto; }
  .wbu-action-item .action-icon-wrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    -webkit-margin-start: 12px;
            margin-inline-start: 12px; }

.classic-facelift-skin .char-limit .char-limit-label, .classic-facelift-skin .char-limit .char-limit-label-error {
  color: #868aa5; }

.classic-facelift-skin .char-limit .char-limit-label-error {
  color: #e62214; }

.char-limit .char-limit-label, .char-limit .char-limit-label-error {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #868aa5; }

.char-limit .char-limit-label-error {
  color: #e62214; }

.composite-with-info-icon:hover > .info-icon-tooltip ~ .char-limit {
  right: 36px; }
  .composite-with-info-icon:hover > .info-icon-tooltip ~ .char-limit ~ .control-label {
    max-width: calc(100% - 18px - 54px); }

.responsive-skin .control-search:not(.is-disabled) .input-container > .input:hover {
  border-color: #e5e5e5; }

.responsive-skin .control-search:not(.is-disabled) .input-container > .input:focus {
  border-color: #e5e5e5; }

.responsive-skin .control-search-input__prefix {
  fill: #868aa5; }

.responsive-skin .control-search-input__suffix {
  fill: transparent; }

.responsive-skin .control-search-input__symbol * {
  fill: #868aa5; }
  .responsive-skin .control-search-input__symbol *:disabled {
    fill: #e5e5e5; }

.responsive-skin .control-search-input > .input-container > .input {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 11px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  border-radius: 4px;
  border-color: #e5e5e5;
  color: #131720;
  padding-left: 44px; }
  .responsive-skin .control-search-input > .input-container > .input:not(:focus) {
    background-color: #f4f5f8 !important; }
  .responsive-skin .control-search-input > .input-container > .input:focus {
    color: #131720;
    background-color: #fff; }
  .responsive-skin .control-search-input > .input-container > .input:disabled {
    color: #f3f3f3;
    background-color: #767676; }
  .responsive-skin .control-search-input > .input-container > .input:hover::placeholder {
    color: #868aa5; }

.responsive-skin .control-search-input .control-button.btn-close {
  background-color: transparent; }
  .responsive-skin .control-search-input .control-button.btn-close svg * {
    fill: #868aa5; }
  .responsive-skin .control-search-input .control-button.btn-close:hover {
    background-color: transparent;
    color: #2B2E36; }
    .responsive-skin .control-search-input .control-button.btn-close:hover svg * {
      fill: #2B2E36; }

.responsive-skin .control-search-input:hover > .input-container > .input {
  cursor: text; }

.responsive-skin .control-search-options__item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased; }
  .responsive-skin .control-search-options__item.active {
    background-color: #dce9ff;
    color: #116dff; }

.responsive-skin .control-search-input__prefix {
  margin-right: 8px; }
  .responsive-skin .control-search-input__prefix svg {
    fill: #868aa5; }

.classic-facelift-skin .control-search-input__symbol * {
  fill: #116dff; }

.classic-facelift-skin .control-search-input > .input-container > .input {
  border-color: #a8caff;
  padding-left: 42px; }
  .classic-facelift-skin .control-search-input > .input-container > .input:hover::placeholder {
    color: #acafc4; }

.classic-facelift-skin .control-search-input .control-button.btn-close {
  background-color: #e7f0ff; }
  .classic-facelift-skin .control-search-input .control-button.btn-close svg * {
    fill: #116dff; }
  .classic-facelift-skin .control-search-input .control-button.btn-close:hover {
    background-color: #d6e6fe; }
    .classic-facelift-skin .control-search-input .control-button.btn-close:hover svg * {
      fill: #116dff; }

.classic-facelift-skin .control-search-input:hover > .input-container > .input {
  background-color: #e7f0ff; }

.classic-facelift-skin .control-search-options__item {
  color: #000624; }
  .classic-facelift-skin .control-search-options__item.active {
    background-color: #d6e6fe;
    color: #000624; }

.classic-facelift-skin .control-search-input__prefix {
  margin-right: 6px; }
  .classic-facelift-skin .control-search-input__prefix svg {
    fill: #116dff; }

.control-search {
  position: relative; }
  .control-search-input {
    position: relative;
    border-radius: 25%; }
    .control-search-input__prefix {
      position: absolute;
      left: 14px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .control-search-input__suffix {
      position: absolute;
      right: 6px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .control-search-input__symbol {
      height: 100%;
      pointer-events: none; }
      .control-search-input__symbol * {
        fill: #116dff; }
    .control-search-input > .input-container > .input {
      border: 1px solid #a8caff;
      padding: 0 36px;
      border-radius: 100px;
      height: 30px;
      font-size: 14px; }
      .control-search-input > .input-container > .input:hover::placeholder {
        color: #b6c1cd; }
    .control-search-input .control-button.btn-close {
      position: relative;
      background-color: #e7f0ff;
      width: 18px;
      height: 18px; }
      .control-search-input .control-button.btn-close svg * {
        fill: #116dff; }
      .control-search-input .control-button.btn-close:hover {
        background-color: #d6e6fe; }
        .control-search-input .control-button.btn-close:hover svg * {
          fill: #116dff; }
    .control-search-input:hover > .input-container > .input {
      background-color: #e7f0ff; }
  .control-search-options {
    position: absolute;
    z-index: 5999;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    max-height: 180px;
    overflow: auto;
    padding: 0;
    margin: 0; }
    .control-search-options__item {
      min-height: 36px;
      padding: 6px 24px;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000624;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box; }
      .control-search-options__item.active {
        background-color: #d6e6fe;
        color: #000624; }

.responsive-skin .control-text.size-tiny {
  font-size: 11px;
  line-height: 16px; }

.responsive-skin .control-text.size-small {
  font-size: 12px;
  line-height: 16px; }

.responsive-skin .control-text.size-medium {
  font-size: 14px;
  line-height: 20px; }

.responsive-skin .control-text.size-large {
  font-size: 16px;
  line-height: 24px; }

.responsive-skin .control-text.weight-thin {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular); }

.responsive-skin .control-text.weight-normal {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular); }

.responsive-skin .control-text.weight-bold {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium); }

.responsive-skin .control-text.skin-standard {
  color: #131720; }

.responsive-skin .control-text.skin-standard-light {
  color: #f4f5f8; }

.responsive-skin .control-text.skin-placeholder {
  color: #868aa5; }

.responsive-skin .control-text.skin-premium {
  color: #5A48F5; }

.responsive-skin .control-text.skin-alert {
  color: #ee4437; }

.responsive-skin .control-text.skin-success {
  color: #25a55a; }

.responsive-skin .control-text.skin-link {
  color: #116dff; }

.responsive-skin .control-text.skin-secondary {
  color: #42454C; }

.responsive-skin .control-text.skin-secondary-light {
  color: #e2e3ea; }

.responsive-skin .control-text.skin-disabled {
  color: #bebebe; }

.responsive-skin .composite-buttons-group-labeled .control-text {
  margin-bottom: 12px;
  margin-right: 30px; }

.composite-text-input-labeled .responsive-skin {
  margin-bottom: 12px; }

.classic-facelift-skin .control-text.skin-standard {
  color: #000624; }

.classic-facelift-skin .control-text.skin-standard-light {
  color: #fff; }

.classic-facelift-skin .control-text.skin-placeholder {
  color: #acafc4; }

.classic-facelift-skin .control-text.skin-premium {
  color: #9a27d5; }

.classic-facelift-skin .control-text.skin-alert {
  color: #e62214; }

.classic-facelift-skin .control-text.skin-success {
  color: #25a55a; }

.classic-facelift-skin .control-text.skin-link {
  color: #116dff; }

.classic-facelift-skin .control-text.skin-secondary {
  color: #3b4057; }

.classic-facelift-skin .control-text.skin-secondary-light {
  color: #dfe5eb; }

.responsive-skin.dark-mode .control-text.skin-standard {
  color: #f4f5f8; }

.responsive-skin.dark-mode .control-text.skin-standard-light {
  color: #fff; }

.responsive-skin.dark-mode .control-text.skin-placeholder {
  color: #868aa5; }

.responsive-skin.dark-mode .control-text.skin-premium {
  color: #A59BFA; }

.responsive-skin.dark-mode .control-text.skin-alert {
  color: #f69891; }

.responsive-skin.dark-mode .control-text.skin-success {
  color: #87cea5; }

.responsive-skin.dark-mode .control-text.skin-link {
  color: #7cafff; }

.responsive-skin.dark-mode .control-text.skin-secondary {
  color: #e2e3ea; }

.responsive-skin.dark-mode .control-text.skin-secondary-light {
  color: #42454C; }

.responsive-skin.dark-mode .control-text.skin-disabled {
  color: #42454C; }

.control-text {
  display: inline;
  max-width: 100%;
  -webkit-font-smoothing: antialiased; }
  .control-text.size-tiny {
    font-size: 12px;
    line-height: 18px; }
  .control-text.size-small {
    font-size: 14px;
    line-height: 18px; }
  .control-text.size-medium {
    font-size: 16px;
    line-height: 24px; }
  .control-text.size-large {
    font-size: 18px;
    line-height: 24px; }
  .control-text.weight-thin {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular); }
  .control-text.weight-normal {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular); }
  .control-text.weight-bold {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium); }
  .control-text.skin-standard {
    color: #000624; }
  .control-text.skin-standard-light {
    color: #fff; }
  .control-text.skin-placeholder {
    color: #b6c1cd; }
  .control-text.skin-premium {
    color: #9a27d5; }
  .control-text.skin-alert {
    color: #e62214; }
  .control-text.skin-success {
    color: #25a55a; }
  .control-text.skin-link {
    color: #116dff; }
  .control-text.skin-secondary {
    color: #3b4057; }
  .control-text.skin-secondary-light {
    color: #dfe5eb; }
  .control-text.skin-disabled {
    color: #939393; }

.responsive-skin .control-badge span {
  color: #2B2E36; }

.responsive-skin .control-badge.badge-size-small {
  padding: 2px 8px;
  height: 16px; }

.responsive-skin .control-badge.badge-size-tiny {
  padding: 2px;
  height: 12px; }

.responsive-skin .control-badge.badge-standard {
  background-color: #c3daff; }

.responsive-skin .control-badge.badge-premium {
  background-color: #d6d1fc; }

.responsive-skin .control-badge.badge-attention {
  background-color: #fdead2; }

.responsive-skin .control-badge.badge-success {
  background-color: #c8e8d6; }

.responsive-skin .control-badge.badge-alert {
  background-color: #fbd0cd; }

.responsive-skin .control-badge.badge-urgent {
  background-color: #fdd7cc; }

.responsive-skin .control-badge.badge-general {
  background-color: #e2e3ea; }

.classic-facelift-skin .control-badge {
  color: #3b4057; }
  .classic-facelift-skin .control-badge.badge-standard {
    background-color: #d6e6fe; }
  .classic-facelift-skin .control-badge.badge-premium {
    background-color: #e3c3f4; }
  .classic-facelift-skin .control-badge.badge-attention {
    background-color: #fff0d1; }
  .classic-facelift-skin .control-badge.badge-success {
    background-color: #c8e8d6; }
  .classic-facelift-skin .control-badge.badge-alert {
    background-color: #fbd0cd; }
  .classic-facelift-skin .control-badge.badge-urgent {
    background-color: #fdd1ba; }
  .classic-facelift-skin .control-badge.badge-general {
    background-color: #dfe5eb; }

.control-badge {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  line-height: 12px;
  height: 12px;
  border-radius: 2px;
  border: 0;
  outline: none;
  color: #3b4057; }
  .control-badge.badge-size-small {
    padding: 3px 6px; }
  .control-badge.badge-size-tiny {
    padding: 3px; }
  .control-badge .control-label-base.t03 {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 1px; }
  .control-badge.to-uppercase {
    text-transform: uppercase; }
  .control-badge.badge-standard {
    background-color: #d6e6fe; }
  .control-badge.badge-premium {
    background-color: #e5c9ee; }
  .control-badge.badge-attention {
    background-color: #ffedc1; }
  .control-badge.badge-success {
    background-color: #c8e8d6; }
  .control-badge.badge-alert {
    background-color: #fbd0cd; }
  .control-badge.badge-urgent {
    background-color: #fdd1ba; }
  .control-badge.badge-general {
    background-color: #dfe5eb; }

.classic-facelift-skin .control-heading.h1 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-weight: var(--wbu-font-weight-bold);
  /* TODO- remove after Madefor experiment is merged */
  font-size: 28px;
  line-height: 36px; }

.classic-facelift-skin .control-heading.h2 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-weight: var(--wbu-font-weight-bold);
  /* TODO- remove after Madefor experiment is merged */
  font-size: 24px;
  line-height: 30px; }

.classic-facelift-skin .control-heading.h4 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 18px;
  line-height: 24px; }

.classic-facelift-skin .control-heading.h1, .classic-facelift-skin .control-heading.h2, .classic-facelift-skin .control-heading.h4 {
  color: #000624; }

.classic-facelift-skin .control-heading.light {
  color: #fff; }

.responsive-skin .control-heading {
  color: #131720; }
  .responsive-skin .control-heading.h1 {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 24px;
    line-height: 32px; }
  .responsive-skin .control-heading.h2 {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 22px;
    line-height: 28px; }
  .responsive-skin .control-heading.h3 {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 20px;
    line-height: 28px; }
  .responsive-skin .control-heading.h4 {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 18px;
    line-height: 24px; }
  .responsive-skin .control-heading.h5 {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-bold);
    font-size: 11px;
    line-height: 16px;
    color: #131720; }

.responsive-skin.light {
  color: #f4f5f8; }

.control-heading {
  display: inline;
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
  color: #3b4057; }
  .control-heading.h1 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 30px;
    line-height: 36px; }
  .control-heading.h2 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 26px;
    line-height: 36px; }
  .control-heading.h3 {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 20px;
    line-height: 24px; }
  .control-heading.h4 {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 18px;
    line-height: 24px; }
  .control-heading.h5 {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 10px;
    line-height: 12px;
    color: #868aa5;
    text-transform: uppercase; }
  .control-heading.light {
    color: #fff; }

.responsive-skin .control-message-modal.control-message-modal {
  width: 500px; }
  .responsive-skin .control-message-modal.control-message-modal .control-base-modal-header {
    border-top: 0 transparent;
    height: 32px;
    padding-top: 0; }
    .responsive-skin .control-message-modal.control-message-modal .control-base-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn-close {
      right: 16px; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-header .control-base-modal-header-btn-bar .control-base-modal-header-btn-close:hover {
        background: transparent; }
  .responsive-skin .control-message-modal.control-message-modal .control-base-modal-body {
    padding: 0 36px 36px; }
    .responsive-skin .control-message-modal.control-message-modal .control-base-modal-body .control-message-modal-title .control-heading {
      font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-bold);
      font-size: 18px;
      line-height: 24px; }
    .responsive-skin .control-message-modal.control-message-modal .control-base-modal-body .control-message-modal-content {
      margin-bottom: 0;
      min-height: 24px; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-body .control-message-modal-content .control-text {
        font-size: 14px;
        line-height: 20px;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular); }
  .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer {
    padding: 0 36px 24px; }
    .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary {
      margin-left: 16px;
      border-color: transparent;
      background: none;
      color: #116dff;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 14px;
      line-height: 20px;
      -webkit-font-smoothing: antialiased; }
      .dropdown-options .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary:hover:not(:disabled) > .button-content {
        color: #116dff;
        text-decoration: none; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary:disabled {
        color: #767676; }
      .composite-button-left .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .composite-action-set-vertical .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 16px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased; }
      .composite-button-large-fixed-bottom .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary,
      .composite-button-large .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.footer-btn {
        color: #116dff; }
        .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.footer-btn:disabled {
          color: #bebebe; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:not([disabled="true"]),
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:hover > .button-content {
        color: #ee4437; }
        .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:not([disabled="true"]) svg path,
        .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.danger-btn:hover > .button-content svg path {
          fill: #ee4437; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary:hover {
        background-color: transparent;
        text-decoration: none; }
        .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary:hover .button-content {
          text-decoration: none; }
      .responsive-skin .control-message-modal.control-message-modal .control-base-modal-footer .btn-confirm-secondary.btn-sm {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 12px;
        line-height: 16px;
        -webkit-font-smoothing: antialiased; }

.control-message-modal.control-message-modal.with-illustration {
  width: 600px;
  min-width: 600px; }

.control-message-modal.control-message-modal .control-message-modal-image,
.control-message-modal.control-message-modal .control-message-modal-illustration {
  display: flex;
  flex: 0 0 120px;
  height: 120px;
  margin-right: 24px;
  align-items: center;
  justify-content: center; }

.control-message-modal.control-message-modal .control-message-modal-content-wrapper {
  flex: 1 1 auto;
  overflow: hidden; }

.control-message-modal.control-message-modal .control-message-modal-title {
  margin-bottom: 12px;
  padding-right: 46px; }

.control-message-modal.control-message-modal .control-message-modal-content {
  margin-bottom: 6px;
  padding-right: 12px; }

.mentions-container {
  position: relative; }
  .mentions-container .control-icon-button {
    position: absolute;
    right: 6px;
    bottom: 7px;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    background-color: #116dff; }
  .mentions-container .control-icon-button:disabled {
    background-color: #e5e5e5; }
    .mentions-container .control-icon-button:disabled path {
      fill: #767676 !important; }
  .mentions-container.mentions-container-disabled .mentions__control {
    border: 1px #bebebe solid; }
  .mentions-container.mentions-container-withButton .mentions__input {
    padding-right: 44px; }
  .mentions-container.mentions-container-withButton .mentions__highlighter {
    padding-right: 44px; }
  .mentions-container.mentions-container-readonly .mentions__input {
    caret-color: transparent;
    overflow-y: hidden !important;
    padding: 0; }
  .mentions-container.mentions-container-readonly .mentions__control {
    border: none;
    outline: none;
    background-color: transparent; }
  .mentions-container.mentions-container-readonly .mentions__highlighter {
    padding: 0; }
  .mentions-container.mentions-container-limited .mentions__control,
  .mentions-container.mentions-container-limited .mentions__highlighter {
    max-height: 116px; }

.mentions {
  border-radius: 4px; }

.mentions__control {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  background-color: #fff;
  border: 1px #116dff solid;
  border-radius: 4px; }

.mentions__highlighter {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 11px;
  padding-bottom: 8px;
  box-sizing: content-box;
  font-size: 12px !important;
  line-height: 16px; }

.mentions__input {
  caret-color: #116dff;
  padding: 8px;
  outline: 0;
  border: 0;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 11px;
  padding-bottom: 8px;
  overflow-y: auto !important;
  font-size: 12px !important;
  line-height: 16px; }

.mentions__suggestions {
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(19, 23, 32, 0.16);
  background-color: #fff; }

.mentions__suggestions__list {
  padding-top: 8px !important; }

.mentions__mention {
  background-color: #cee4e5; }

#suggestionPortal {
  position: relative; }
  #suggestionPortal .mentions__suggestions {
    width: 100%;
    position: absolute !important;
    left: 0 !important;
    bottom: 100%;
    top: auto !important; }

.responsive-skin .notification-banner {
  min-height: 16px;
  padding: 12px; }
  .responsive-skin .notification-banner-skin-standard {
    background-color: #dce9ff; }
  .responsive-skin .notification-banner-skin-premium {
    background-color: #F1E0F9; }
    .responsive-skin .notification-banner-skin-premium .notification-banner-icon:not(.error) > svg:hover path {
      fill: #9A27D5; }
  .responsive-skin .notification-banner-skin-alert {
    background-color: #fbd0cd; }
  .responsive-skin .notification-banner-skin-success {
    background-color: #c8e8d6; }
  .responsive-skin .notification-banner-skin-warning {
    background-color: #fdead2; }
  .responsive-skin .notification-banner-skin-neutral {
    background-color: #e2e3ea; }
  .responsive-skin .notification-banner .control-text.weight-thin {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium); }
  .responsive-skin .notification-banner .control-text-button .control-text.size-small {
    font-size: 12px;
    line-height: 16px; }
  .responsive-skin .notification-banner .control-text-button .control-text.weight-thin {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium); }

.responsive-skin .notification-banner-contents {
  gap: 4px; }

.responsive-skin .notification-banner-icon {
  margin-left: 8px; }

.classic-facelift-skin .notification-banner-skin-neutral {
  background-color: #f7f8f8; }

.classic-facelift-skin .notification-banner .control-text.weight-thin {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular); }

.notification-banner {
  display: flex;
  align-items: center;
  min-height: 30px; }
  .notification-banner-skin-standard {
    background-color: #e7f0ff; }
  .notification-banner-skin-premium {
    background-color: #eedbf4; }
    .notification-banner-skin-premium .notification-banner-icon:not(.error) > svg:hover path {
      fill: #9a27d5; }
  .notification-banner-skin-alert {
    background-color: #fde3e1; }
  .notification-banner-skin-success {
    background-color: #e1f4eb; }
  .notification-banner-skin-warning {
    background-color: #fff3d6; }
  .notification-banner-skin-neutral {
    background-color: #f7f8f8; }
  .notification-banner-type-inline {
    padding: 6px 24px; }
  .notification-banner-type-floating {
    border-radius: 4px;
    padding: 9px 18px; }
  .notification-banner-side-start, .notification-banner-side-end {
    flex: 1; }
  .notification-banner-alignment-start {
    text-align: start; }
    .notification-banner-alignment-start .notification-banner-side-start {
      flex: 0; }
  .notification-banner-alignment-center {
    text-align: center; }
  .notification-banner:hover .notification-banner-icon {
    opacity: 1; }

.notification-banner-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.notification-banner-icon {
  opacity: 0;
  margin-left: 6px; }

.notification-banner-contents {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 6px; }
  .notification-banner-contents .notification-banner-text .control-text .has-tooltip {
    display: inline-grid; }
    .notification-banner-contents .notification-banner-text .control-text .has-tooltip .tooltip-on-ellipsis-content {
      white-space: normal !important;
      display: -webkit-box !important;
      display: -moz-box !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--maxLines, 1); }

.responsive-skin .control-header-root {
  margin-bottom: 8px;
  padding: 1px 0; }
  .responsive-skin .control-header-root .label {
    margin-right: 8px; }
  .responsive-skin .control-header-root .suffix {
    column-gap: 4px; }
    .responsive-skin .control-header-root .suffix > .info-icon-tooltip > .control-info-icon {
      padding: 0px; }

.control-header-root {
  margin-bottom: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 18px; }
  .control-header-root .label {
    min-width: 24px;
    flex: 1 1 auto;
    margin-right: 6px; }
  .control-header-root .suffix {
    display: flex;
    margin-left: auto;
    column-gap: 6px;
    align-items: center; }
    .control-header-root .suffix > * {
      flex: 1 1 auto; }

.control-header-wrapper > .control-header-root > .suffix > .info-icon-tooltip:not(.show-info-icon) {
  visibility: hidden;
  max-width: 0;
  transition: max-width 0s 0.2s; }

*:hover > .control-header-wrapper > .control-header-root > .suffix > .info-icon-tooltip,
.show-info-icon {
  visibility: visible;
  display: inline-flex;
  max-width: 100%;
  transition: visibility 0s 0.2s; }

.classic-facelift-skin .text-formatting-toggle-button .symbol > svg {
  fill: #3b4057; }

.responsive-skin .text-formatting-toggle-button .symbol > svg {
  fill: #131720; }

.responsive-skin .text-formatting-toggle-button .input:active ~ .symbol > svg,
.responsive-skin .text-formatting-toggle-button .input:checked ~ .symbol > svg {
  fill: #116dff; }

.responsive-skin .text-formatting-toggle-button .input:disabled ~ .symbol > svg {
  fill: #bebebe; }

.text-formatting-toggle-button .symbol {
  display: flex; }
  .text-formatting-toggle-button .symbol > svg {
    fill: #3b4057; }

.text-formatting-toggle-button .input:active ~ .symbol > svg,
.text-formatting-toggle-button .input:checked ~ .symbol > svg {
  fill: #fff; }

.text-formatting-toggle-button .input:disabled ~ .symbol > svg {
  fill: #bcbcbc; }

.classic-facelift-skin .text-formatting-dropdown:hover:not(.disabled) {
  background: #e7f0ff; }
  .classic-facelift-skin .text-formatting-dropdown:hover:not(.disabled) .dropdown-selected svg path {
    fill: #3b4057; }

.classic-facelift-skin .text-formatting-dropdown.disabled .dropdown-selected svg path,
.classic-facelift-skin .text-formatting-dropdown.disabled .symbol-arrow-down svg path {
  fill: #898989; }

.classic-facelift-skin .text-formatting-dropdown-opened {
  background: #116dff; }
  .classic-facelift-skin .text-formatting-dropdown-opened .dropdown-selected svg path {
    fill: #fff; }
  .classic-facelift-skin .text-formatting-dropdown-opened .symbol-arrow-down svg path {
    fill: #f4f5f8; }

.responsive-skin .text-formatting-dropdown .symbol-arrow-down svg path {
  fill: #116dff; }

.responsive-skin .text-formatting-dropdown:hover:not(.disabled) {
  background: #f4f5f8; }
  .responsive-skin .text-formatting-dropdown:hover:not(.disabled) .symbol-arrow-down svg path {
    fill: #116dff; }

.responsive-skin .text-formatting-dropdown.disabled .dropdown-selected svg path,
.responsive-skin .text-formatting-dropdown.disabled .symbol-arrow-down svg path {
  fill: #bebebe; }

.responsive-skin .text-formatting-dropdown.text-formatting-dropdown-opened {
  background: #dce9ff; }
  .responsive-skin .text-formatting-dropdown.text-formatting-dropdown-opened .dropdown-selected svg path {
    fill: #116dff; }
  .responsive-skin .text-formatting-dropdown.text-formatting-dropdown-opened .symbol-arrow-down svg path {
    fill: #116dff; }

.classic-facelift-skin .text-formatting-dropdown,
.responsive-skin .text-formatting-dropdown {
  width: 52px;
  border-radius: 4px; }
  .classic-facelift-skin .text-formatting-dropdown .selected-container,
  .responsive-skin .text-formatting-dropdown .selected-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
  .classic-facelift-skin .text-formatting-dropdown .selected-content,
  .responsive-skin .text-formatting-dropdown .selected-content {
    width: 100%;
    display: flex;
    align-items: center; }
  .classic-facelift-skin .text-formatting-dropdown .dropdown-selected,
  .responsive-skin .text-formatting-dropdown .dropdown-selected {
    height: 32px;
    min-height: 32px; }
  .classic-facelift-skin .text-formatting-dropdown .dropdown-illustration-option,
  .responsive-skin .text-formatting-dropdown .dropdown-illustration-option {
    height: 32px; }
  .classic-facelift-skin .text-formatting-dropdown .symbol-arrow-down,
  .responsive-skin .text-formatting-dropdown .symbol-arrow-down {
    border: none; }

.classic-facelift-skin .text-formatting-dropdown-options .control-dropdown-option,
.responsive-skin .text-formatting-dropdown-options .control-dropdown-option {
  height: 32px; }
  .classic-facelift-skin .text-formatting-dropdown-options .control-dropdown-option .dropdown-illustration-option,
  .responsive-skin .text-formatting-dropdown-options .control-dropdown-option .dropdown-illustration-option {
    height: 32px; }

.classic-facelift-skin .text-formatting-dropdown .has-tooltip,
.classic-facelift-skin .text-formatting-dropdown-options .has-tooltip,
.responsive-skin .text-formatting-dropdown .has-tooltip,
.responsive-skin .text-formatting-dropdown-options .has-tooltip {
  display: flex;
  align-items: center;
  height: 32px; }

.classic-facelift-skin .text-formatting-button-with-dropdown {
  border-radius: 4px; }
  .classic-facelift-skin .text-formatting-button-with-dropdown.selected-has-background {
    background-color: #116dff; }
    .classic-facelift-skin .text-formatting-button-with-dropdown.selected-has-background .control-illustration svg path {
      fill: #fff !important; }
    .classic-facelift-skin .text-formatting-button-with-dropdown.selected-has-background:hover {
      background-color: #5999ff; }
  .input-borders-experiment .classic-facelift-skin .text-formatting-button-with-dropdown > .dropdown-selected {
    border: none; }

.responsive-skin .text-formatting-button-with-dropdown.selected-has-background {
  background-color: #dce9ff; }
  .responsive-skin .text-formatting-button-with-dropdown.selected-has-background .control-illustration svg path {
    fill: #116dff; }
  .responsive-skin .text-formatting-button-with-dropdown.selected-has-background:hover {
    background-color: #dce9ff; }

.classic-facelift-skin .text-formatting-button-with-dropdown,
.responsive-skin .text-formatting-button-with-dropdown {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }

.classic-facelift-skin .text-formatting-button-with-dropdown-options .control-dropdown-option,
.responsive-skin .text-formatting-button-with-dropdown-options .control-dropdown-option {
  padding: 0 4px;
  width: 32px; }

html,
body,
div,
span,
applet,
object,
iframe,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
nav,
button,
section,
header,
footer,
title {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

.main-top-bar > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
  display: none; }

.main-top-bar > .top-bar-right-section .top-bar-button-preview .symbol {
  display: none; }

.main-top-bar > .top-bar-right-section .symbol-topBarMobile,
.main-top-bar > .top-bar-right-section .symbol-topBarDesktop {
  margin-right: 8px; }

.main-top-bar > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
.main-top-bar > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
  display: none; }

.main-top-bar > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
  display: none; }

.main-top-bar > .top-bar-left-section .symbol-topBarMobile,
.main-top-bar > .top-bar-left-section .symbol-topBarDesktop {
  margin-right: 8px; }

.main-top-bar > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
.main-top-bar > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
  display: none; }

@media (max-width: 1440px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-device .label {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-arena {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1402px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 48px;
    padding: 0; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-device {
    padding: 0;
    min-width: 0;
    width: 48px; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-editor-search {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1317px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    padding: 0 9px; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1275px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1227px) {
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 600px) {
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; } }

@media (max-width: 1315px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-device .label {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-arena {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1277px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 48px;
    padding: 0; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-device {
    padding: 0;
    min-width: 0;
    width: 48px; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-editor-search {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1229px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    padding: 0 9px; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1186px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1139px) {
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 600px) {
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; } }

@media (max-width: 1405px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-device .label {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-arena {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1360px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 48px;
    padding: 0; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-device {
    padding: 0;
    min-width: 0;
    width: 48px; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-editor-search {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1292px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    padding: 0 9px; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1241px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1193px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 600px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; } }

@media (max-width: 1650px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-device .label {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-arena {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1521px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 48px;
    padding: 0; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-device {
    padding: 0;
    min-width: 0;
    width: 48px; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-editor-search {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1444px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    padding: 0 9px; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1352px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1308px) {
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 600px) {
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.top-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1450;
  -webkit-user-select: none;
  user-select: none;
  color: #000624;
  background-color: #fff; }
  .top-bar-wrapper svg path {
    fill: #000624; }
  .top-bar-wrapper::before {
    content: '';
    height: var(--g-top-bar-height);
    width: 100%;
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    background-color: #fff;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
  .top-bar-wrapper.with-top-bar-banner::before {
    content: '';
    height: calc( 30px + var(--g-top-bar-height));
    width: 100%;
    display: block;
    position: absolute; }
  .top-bar-wrapper.with-top-bar-banner > .main-top-bar {
    padding-bottom: 30px; }
  .top-bar-wrapper.is-hidden::before {
    z-index: 1451;
    animation: fade-in linear 0.2s;
    top: calc( 6px - var(--g-top-bar-height));
    transition-duration: 0.4s; }
  .top-bar-wrapper.is-hidden::after {
    content: '';
    height: calc(5px + var(--g-top-bar-height));
    width: 100%;
    display: block;
    position: absolute;
    z-index: 1452;
    pointer-events: none; }
  .top-bar-wrapper.is-hidden.is-hidden-completely {
    height: 0; }
    .top-bar-wrapper.is-hidden.is-hidden-completely::before {
      height: 0; }
    .top-bar-wrapper.is-hidden.is-hidden-completely::after {
      height: 0; }
  .top-bar-wrapper > .main-top-bar {
    display: flex;
    justify-content: space-between;
    height: var(--g-top-bar-height);
    position: absolute;
    width: 100%;
    min-width: 600px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 1450;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
    .is-hidden.top-bar-wrapper > .main-top-bar {
      top: calc( 6px - var(--g-top-bar-height));
      transition-duration: 0.4s; }
      .is-hidden-completely.is-hidden.top-bar-wrapper > .main-top-bar {
        top: calc(-2px - var(--g-top-bar-height)); }
    .top-bar-wrapper > .main-top-bar > .top-bar-left-section {
      display: flex;
      height: var(--g-top-bar-height); }
      .top-bar-wrapper > .main-top-bar > .top-bar-left-section > .top-bar-menu-bar-wrapper {
        padding-left: 18px;
        display: flex; }
      .top-bar-wrapper > .main-top-bar > .top-bar-left-section .top-bar-language-button-icon {
        height: 30px; }
    .top-bar-wrapper > .main-top-bar > .top-bar-right-section {
      display: flex;
      height: var(--g-top-bar-height); }
    .top-bar-wrapper > .main-top-bar > .top-bar-center-section {
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--g-top-bar-height); }
  .top-bar-wrapper .language-selector-top-bar-button {
    border-right: 1px #eaf7ff solid;
    border-left: none; }
  .top-bar-wrapper .flag-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    position: relative; }
    .top-bar-wrapper .flag-icon img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%; }

.top-bar-tooltip-content-wrapper > :first-child {
  margin-bottom: 6px; }

.top-bar-tooltip-content-wrapper_se-madefor-font :first-child {
  display: block;
  font-weight: var(--wbu-font-weight-medium); }

.editor-layout-wrapper .top-bar-wrapper {
  position: relative;
  height: var(--g-top-bar-height);
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .editor-layout-wrapper .top-bar-wrapper.is-hidden {
    height: 6px;
    transition-duration: 0.4s; }
    .editor-layout-wrapper .top-bar-wrapper.is-hidden.is-hidden-completely {
      height: 0; }
  .editor-layout-wrapper .top-bar-wrapper.with-top-bar-banner {
    height: calc( 30px + var(--g-top-bar-height)); }

.next-main-top-bar {
  flex-direction: column;
  color: #000624; }
  .top-bar-wrapper > .next-main-top-bar {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08); }
  .next-main-top-bar .top-bar-menu-bar-wrapper {
    display: flex;
    height: 100%; }
    .next-main-top-bar .top-bar-menu-bar-wrapper.with-separator:after {
      content: '';
      height: 55%;
      align-self: center;
      border-right: 1px solid #dfe5eb; }
    .next-main-top-bar .top-bar-menu-bar-wrapper .top-bar-drop-down-panel .bubble-content {
      padding-left: 0; }
    .next-main-top-bar .top-bar-menu-bar-wrapper .top-bar-drop-down-panel_code .bubble-content {
      padding: 0;
      min-width: 360px;
      min-height: 180px; }
  .next-main-top-bar .top-bar-left-wix-logo {
    border: none;
    padding: 0 11px;
    margin-right: 6px; }
  .next-main-top-bar .top-bar-menu-bar-item {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 0 15px;
    height: 44px;
    line-height: 44px; }
    .next-main-top-bar .top-bar-menu-bar-item.active {
      color: #116dff;
      background: #fff; }
  .next-main-top-bar .top-bar-btn {
    border: none;
    padding: 0 15px;
    height: 44px; }
    .next-main-top-bar .top-bar-btn.toggled .label {
      color: #116dff; }
    .next-main-top-bar .top-bar-btn.toggled .btn-symbol,
    .next-main-top-bar .top-bar-btn.toggled .btn-symbol path {
      fill: #116dff; }
    .next-main-top-bar .top-bar-btn.toggled .top-bar-btn-content-wrapper:after {
      height: 3px;
      width: 100%;
      background-color: #116dff;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0; }
    .next-main-top-bar .top-bar-btn.toggled.top-bar-symbol-btn .btn-symbol {
      border-bottom: none;
      padding-top: 0;
      height: auto; }
  .next-main-top-bar .top-bar-menu-bar-item.menu-bar-upgrade-item {
    color: #9a27d5; }
    .next-main-top-bar .top-bar-menu-bar-item.menu-bar-upgrade-item.active {
      color: #c161f0; }
  .next-main-top-bar .top-bar-btn.top-bar-publish-btn {
    color: #fff;
    fill: #fff;
    background-color: #116dff;
    padding: 0 24px; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-btn:hover {
      background-color: #5999ff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn:hover .action-success-symbol {
        background-color: #116dff; }
        .next-main-top-bar .top-bar-btn.top-bar-publish-btn:hover .action-success-symbol > .symbol-mask {
          background-color: #5999ff; }
        .next-main-top-bar .top-bar-btn.top-bar-publish-btn:hover .action-success-symbol .circle {
          border-color: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled, .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled:hover {
      background-color: #bcbcbc;
      border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled .label, .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled .action-success-symbol .symbol-mask, .next-main-top-bar .top-bar-btn.top-bar-publish-btn.disabled:hover .action-success-symbol .symbol-mask {
        background-color: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-btn .action-success-symbol {
      background-color: #116dff;
      height: auto; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn .action-success-symbol .checkmark-symbol {
        background-color: transparent; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn .action-success-symbol .symbol-mask {
        background-color: #116dff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn .action-success-symbol .circle {
        border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-btn .action-success-symbol .check {
        fill: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-btn-devmode {
      margin-right: 0;
      padding: 0 21px 0 24px; }
  .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn {
    color: #fff;
    fill: #fff;
    background-color: #116dff;
    padding: 0 24px;
    border-left: 1px solid #80b1ff;
    padding: 0 12px; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn:hover {
      background-color: #5999ff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn:hover .action-success-symbol {
        background-color: #116dff; }
        .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn:hover .action-success-symbol > .symbol-mask {
          background-color: #5999ff; }
        .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn:hover .action-success-symbol .circle {
          border-color: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled, .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled:hover {
      background-color: #bcbcbc;
      border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled .label, .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled .action-success-symbol .symbol-mask, .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn.disabled:hover .action-success-symbol .symbol-mask {
        background-color: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .action-success-symbol {
      background-color: #116dff;
      height: auto; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .action-success-symbol .checkmark-symbol {
        background-color: transparent; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .action-success-symbol .symbol-mask {
        background-color: #116dff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .action-success-symbol .circle {
        border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .action-success-symbol .check {
        fill: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-publish-rc-btn .symbol {
      width: 10px;
      height: 10px; }
  .next-main-top-bar .top-bar-btn.top-bar-test-site-btn {
    color: #fff;
    fill: #fff;
    background-color: #e7f0ff;
    padding: 0 24px;
    margin-right: 0;
    padding: 0 21px 0 24px; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-btn .label {
      color: #116dff; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-btn:hover {
      background-color: #d6e6fe; }
      .next-main-top-bar .top-bar-btn.top-bar-test-site-btn:hover .label {
        color: #116dff; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-btn.disabled, .next-main-top-bar .top-bar-btn.top-bar-test-site-btn.disabled:hover {
      background-color: #f0f0f0;
      border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-test-site-btn.disabled .label, .next-main-top-bar .top-bar-btn.top-bar-test-site-btn.disabled:hover .label {
        color: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-btn.disabled svg path {
      fill: #bcbcbc; }
  .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn {
    color: #fff;
    fill: #fff;
    background-color: #e7f0ff;
    padding: 0 24px;
    border-left: 1px solid #116dff;
    padding: 0 12px; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn .label {
      color: #116dff; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn:hover {
      background-color: #d6e6fe; }
      .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn:hover .label {
        color: #116dff; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled, .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled:hover {
      background-color: #f0f0f0;
      border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled .label, .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled:hover .label {
        color: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled svg path {
      fill: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn .symbol {
      width: 10px;
      height: 10px; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn svg path {
      fill: #116dff; }
    .next-main-top-bar .top-bar-btn.top-bar-test-site-rc-btn.disabled {
      border-left: 1px solid #bcbcbc; }
  .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved, .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved:hover {
    background-color: #bcbcbc;
    border-color: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved .label, .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved:hover .label {
      color: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved .action-success-symbol .symbol-mask, .next-main-top-bar .top-bar-btn.top-bar-save-btn.top-bar-primary-btn.top-bar-btn-idle-saved:hover .action-success-symbol .symbol-mask {
      background-color: #bcbcbc; }
  .next-main-top-bar .top-bar-btn.top-bar-primary-btn {
    color: #fff;
    fill: #fff;
    background-color: #116dff;
    padding: 0 24px; }
    .next-main-top-bar .top-bar-btn.top-bar-primary-btn:hover {
      background-color: #5999ff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn:hover .action-success-symbol {
        background-color: #116dff; }
        .next-main-top-bar .top-bar-btn.top-bar-primary-btn:hover .action-success-symbol > .symbol-mask {
          background-color: #5999ff; }
        .next-main-top-bar .top-bar-btn.top-bar-primary-btn:hover .action-success-symbol .circle {
          border-color: #fff; }
    .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled, .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled:hover {
      background-color: #bcbcbc;
      border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled .label, .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled:hover .label {
        color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled .action-success-symbol .symbol-mask, .next-main-top-bar .top-bar-btn.top-bar-primary-btn.disabled:hover .action-success-symbol .symbol-mask {
        background-color: #bcbcbc; }
    .next-main-top-bar .top-bar-btn.top-bar-primary-btn .action-success-symbol {
      background-color: #116dff;
      height: auto; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn .action-success-symbol .checkmark-symbol {
        background-color: transparent; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn .action-success-symbol .symbol-mask {
        background-color: #116dff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn .action-success-symbol .circle {
        border-color: #fff; }
      .next-main-top-bar .top-bar-btn.top-bar-primary-btn .action-success-symbol .check {
        fill: #fff; }
  .next-main-top-bar .publish-buttons-wrapper {
    display: flex;
    margin-left: 6px; }
  .next-main-top-bar .test-site-buttons-wrapper {
    display: flex;
    margin-left: 6px; }
  .next-main-top-bar .top-bar-btn.top-bar-back-to-editor-btn {
    color: #fff;
    background-color: #25a55a;
    padding: 0 24px;
    margin: 0 0 0 12px; }
    .next-main-top-bar .top-bar-btn.top-bar-back-to-editor-btn:hover {
      background-color: #51b77b; }
      .next-main-top-bar .top-bar-btn.top-bar-back-to-editor-btn:hover .label {
        color: #fff; }
  .next-main-top-bar .quick-navigation-pp {
    height: 100%; }
  .next-main-top-bar .top-bar-center-notification {
    font-size: 14px;
    height: 44px;
    line-height: 44px; }
  .next-main-top-bar.preview-mode .top-bar-left-wix-logo {
    padding: 0 17px;
    margin-right: 0; }
  .next-main-top-bar.preview-mode .top-bar-btn.top-bar-save-btn {
    padding: 0 10px 0 11px; }
  .next-main-top-bar.zoom-mode .top-bar-right-section .top-bar-btn:first-of-type {
    border-left: none;
    margin-left: 3px; }
  .next-main-top-bar.zoom-mode .top-bar-right-section > *:first-child {
    border-left: none; }
  .next-main-top-bar.zoom-mode .top-bar-left-section .top-bar-left-wix-logo {
    border-right: none; }
  .next-main-top-bar.zoom-mode .top-bar-left-section .top-bar-left-wix-logo + *:not(.top-bar-center-notification) {
    border-left: 1px solid rgba(217, 225, 232, 0.6); }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-back-to-editor-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px;
    margin: 0 12px; }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-publish-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px; }
    .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-publish-btn-devmode {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 15px 0 18px; }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-publish-rc-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 12px 0 10px; }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-test-site-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 15px 0 18px; }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-test-site-rc-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 12px 0 10px; }
  .next-main-top-bar.with-rounded-buttons .top-bar-btn.top-bar-primary-btn {
    height: 30px;
    border-radius: 15px;
    padding: 0 18px; }
  .next-main-top-bar.with-rounded-buttons .publish-buttons-wrapper {
    margin-left: 12px; }

.top-bar-upper-deck,
.top-bar-lower-deck {
  position: relative;
  height: 44px; }
  .top-bar-upper-deck .top-bar-left-section,
  .top-bar-upper-deck .top-bar-center-section,
  .top-bar-upper-deck .top-bar-right-section,
  .top-bar-lower-deck .top-bar-left-section,
  .top-bar-lower-deck .top-bar-center-section,
  .top-bar-lower-deck .top-bar-right-section {
    z-index: 0;
    display: flex;
    align-items: center;
    height: 100%; }
  .top-bar-upper-deck .top-bar-center-section,
  .top-bar-lower-deck .top-bar-center-section {
    flex-shrink: 1; }
    .top-bar-upper-deck .top-bar-center-section.intro-fixparent,
    .top-bar-lower-deck .top-bar-center-section.intro-fixparent {
      transform: translateX(-50%) !important;
      z-index: 1000000000 !important; }
      .top-bar-upper-deck .top-bar-center-section.intro-fixparent > *:not(.tour-maker-highlight-element),
      .top-bar-lower-deck .top-bar-center-section.intro-fixparent > *:not(.tour-maker-highlight-element) {
        opacity: 0; }
  .top-bar-upper-deck .top-bar-left-section.with-separators > *,
  .top-bar-lower-deck .top-bar-left-section.with-separators > * {
    border-right: 1px solid rgba(217, 225, 232, 0.6); }
  .top-bar-upper-deck .top-bar-center-section.with-separators > *,
  .top-bar-lower-deck .top-bar-center-section.with-separators > * {
    border-right: 1px solid rgba(217, 225, 232, 0.6); }
    .top-bar-upper-deck .top-bar-center-section.with-separators > *:first-child,
    .top-bar-lower-deck .top-bar-center-section.with-separators > *:first-child {
      border-left: 1px solid rgba(217, 225, 232, 0.6); }
  .top-bar-upper-deck .top-bar-right-section.with-separators > *,
  .top-bar-lower-deck .top-bar-right-section.with-separators > * {
    border-left: 1px solid rgba(217, 225, 232, 0.6); }

.top-bar-upper-deck {
  z-index: 1;
  display: flex;
  justify-content: space-between; }
  .top-bar-upper-deck .top-bar-right-section {
    padding-right: 16px; }

.top-bar-lower-deck {
  z-index: 0;
  border-top: 1px solid rgba(217, 225, 232, 0.6);
  display: grid;
  grid-template-columns: auto minmax(350px, 1fr) auto;
  grid-template-areas: 'left center right'; }
  .top-bar-lower-deck .top-bar-left-section {
    grid-area: left; }
  .top-bar-lower-deck .top-bar-center-section {
    overflow: hidden;
    grid-area: center; }
  .top-bar-lower-deck .top-bar-right-section {
    grid-area: right; }

.classic-facelift-skin .next-main-top-bar.zoom-mode .top-bar-left-section .top-bar-left-wix-logo + *:not(.top-bar-center-notification) {
  border-left-color: #dfe5eb; }

.classic-facelift-skin .top-bar-upper-deck,
.classic-facelift-skin .top-bar-lower-deck {
  border-color: #dfe5eb; }
  .classic-facelift-skin .top-bar-upper-deck .top-bar-left-section.with-separators > *,
  .classic-facelift-skin .top-bar-upper-deck .top-bar-right-section.with-separators > *,
  .classic-facelift-skin .top-bar-upper-deck .top-bar-center-section.with-separators > *,
  .classic-facelift-skin .top-bar-lower-deck .top-bar-left-section.with-separators > *,
  .classic-facelift-skin .top-bar-lower-deck .top-bar-right-section.with-separators > *,
  .classic-facelift-skin .top-bar-lower-deck .top-bar-center-section.with-separators > * {
    border-color: #dfe5eb; }

@media (max-width: 1100px) {
  .next-main-top-bar .top-bar-menu-bar-item,
  .next-main-top-bar .top-bar-btn {
    padding: 0 12px; } }

@media (max-width: 980px) {
  .next-main-top-bar .top-bar-front-section {
    background-color: #ffffff;
    z-index: 1; } }

.top-bar-drop-down-panel {
  position: fixed;
  z-index: 1501; }
  .top-bar-drop-down-panel.intro-fixparent {
    z-index: 1000000000 !important; }
  .top-bar-drop-down-panel .tooltip.bubble > .bubble-arrow,
  .top-bar-drop-down-panel .tooltip.bubble > .bubble-content {
    position: absolute;
    overflow: visible; }
  .top-bar-drop-down-panel .drop-panel-content {
    display: flex; }
  .top-bar-drop-down-panel-shutter {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%; }

.top-bar-drop-down-anchor {
  position: relative;
  display: block; }

.top-bar-drop-down-overlay {
  position: fixed;
  cursor: default;
  z-index: 1450;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

@keyframes top-bar-pulse {
  0% {
    transform: scale(1);
    opacity: 0.8; }
  20% {
    opacity: 0.5; }
  100% {
    transform: scale(1.25);
    opacity: 0; } }

@keyframes top-bar-slide {
  100% {
    transform: translateX(15px); } }

.action-success-symbol {
  height: 48px; }
  .action-success-symbol > .symbol-mask {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    position: absolute;
    background-color: #ffffff;
    width: 10px;
    height: 8px;
    animation: top-bar-slide 0.85s ease-out forwards; }
    .top-bar-edit-mode-last-btn .action-success-symbol > .symbol-mask {
      background-color: #116dff; }
    .top-bar-edit-mode-last-btn:hover .action-success-symbol > .symbol-mask {
      background-color: #5999ff; }
    .top-bar-edit-mode-last-btn.app-studio-package .action-success-symbol > .symbol-mask {
      background-color: #2d3034; }
    .top-bar-edit-mode-last-btn.app-studio-package:hover .action-success-symbol > .symbol-mask {
      background-color: #3b4057; }
  .action-success-symbol > .circle {
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    box-sizing: border-box;
    margin: auto;
    background-color: transparent;
    border: 2px solid #116dff;
    border-radius: 50%;
    animation: top-bar-pulse 0.75s linear forwards; }
    .action-success-symbol > .circle.app-studio-package {
      border: 2px solid #2d3034; }
    .top-bar-edit-mode-last-btn .action-success-symbol > .circle {
      border-color: #ffffff; }

.top-bar-left-wix-logo {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  border-right: 1px #eaf7ff solid; }
  .top-bar-left-wix-logo > svg {
    width: 50px;
    height: 48px; }
    .top-bar-left-wix-logo > svg > path {
      fill: #000 !important; }

.info-drop-panel-illustration {
  min-height: 344px;
  width: 204px;
  box-sizing: border-box;
  padding: 0 24px 24px 24px;
  border-radius: 4px;
  background-color: #d6e6fe;
  word-wrap: break-word;
  cursor: pointer; }
  .info-drop-panel-illustration.disabled-action {
    cursor: default; }
  .info-drop-panel-illustration.illustration-upgrade {
    background-color: #eedbf4; }
  .drop-panel-mobile .info-drop-panel-illustration {
    min-height: 262px;
    width: 246px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default; }
  .info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
    display: flex;
    justify-content: center;
    align-self: center; }
    .personal-sale-campaign.info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
      position: relative;
      height: 163px; }
    .drop-panel-mobile .info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
      margin: 34px 0 18px 18px; }
    .personal-sale-campaign.info-drop-panel-illustration > .symbol-container-drop-panel-illustration > .img-drop-panel-illustration {
      position: relative;
      height: 125px;
      top: 8px; }
    .drop-panel-mobile .info-drop-panel-illustration > .symbol-container-drop-panel-illustration > .img-drop-panel-illustration {
      width: 155px;
      height: 119px; }
  .info-drop-panel-illustration > .title-info-drop-panel-illustration {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057; }
    .drop-panel-mobile .info-drop-panel-illustration > .title-info-drop-panel-illustration {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
  .info-drop-panel-illustration > .description-drop-panel-illustration {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    margin-top: 12px; }
  .info-drop-panel-illustration > .promotion-drop-panel-illustration {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 22px;
    gap: 6px; }
    .info-drop-panel-illustration > .promotion-drop-panel-illustration .symbol-promotion-drop-panel-illustration {
      display: flex;
      align-self: end;
      padding-bottom: 1px; }

.classic-facelift-skin .info-drop-panel-illustration {
  padding: 24px;
  background-color: rgba(231, 240, 255, 0.8); }
  .classic-facelift-skin .info-drop-panel-illustration.illustration-upgrade {
    background-color: #eedbf4; }
  .classic-facelift-skin .info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
    margin-bottom: 18px; }
    .classic-facelift-skin .info-drop-panel-illustration > .symbol-container-drop-panel-illustration svg {
      height: 120px;
      width: 120px; }
  .classic-facelift-skin .info-drop-panel-illustration > .title-info-drop-panel-illustration {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #000624; }
  .classic-facelift-skin .info-drop-panel-illustration > .description-drop-panel-illustration {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    margin-top: 6px; }

.drop-panel-list {
  min-width: 180px;
  margin-right: 24px;
  padding-left: 12px; }
  .drop-panel-list.drop-panel-check-list {
    min-width: 203px; }
    .drop-panel-list.drop-panel-check-list.margin-none {
      margin-right: 0; }
  .drop-panel-list > .list-item-developer-mode {
    padding: 6px 12px; }
  .drop-panel-list > .drop-panel-list-item-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 6px 12px; }
  .drop-panel-list > .drop-panel-list-separator > .drop-panel-menu-bar-separator {
    margin: 11px 0 10px 12px;
    box-sizing: content-box;
    border: 0;
    height: 1px;
    background-color: #dfe5eb; }
  .drop-panel-list-item-notification {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #e62214;
    vertical-align: top; }
  .drop-panel-list-item-counter {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    font-size: 11px;
    display: inline-block;
    position: absolute;
    background-color: #25a55a;
    border-radius: 15px;
    min-width: 12px;
    padding: 0 4px;
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    line-height: 20px;
    left: calc(100% - 12px);
    top: -6px; }
  .drop-panel-list .drop-panel-list-item {
    display: flex;
    height: 30px;
    cursor: pointer; }
    .drop-panel-list .drop-panel-list-item:first-child {
      margin-top: 4px; }
    .drop-panel-list .drop-panel-list-item.disabled {
      cursor: default; }
    .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      color: #000624;
      padding: 6px 12px;
      border-radius: 20px; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.disabled {
        color: #7a7a7a; }
        .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.disabled > .list-item-label {
          cursor: default; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.custom-item > .list-item-label {
        cursor: default; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.selected {
        background-color: #e7f0ff; }
        .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.selected.list-item-upgrade {
          background-color: #eedbf4; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.with-icon {
        margin: 0;
        padding: 3px 0 3px 8px; }
        .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.with-icon .list-item-label {
          display: flex;
          align-items: center; }
        .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.with-icon .symbol {
          width: 24px;
          height: 24px;
          margin-right: 6px; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list:has(.icon-right) {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list:has(.icon-right) .icon-right {
          display: flex;
          align-items: center; }
      .drop-panel-check-list.drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list {
        pointer-events: none;
        cursor: pointer;
        padding-right: 0; }
        .drop-panel-check-list.drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list.nested {
          margin-left: 30px; }
      .drop-panel-list .drop-panel-list-item > .title-item-container-drop-panel-list > .list-item-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        cursor: pointer; }

.top-bar-drop-down-panel .drop-panel-list {
  padding-left: 5px; }

.top-bar-drop-down-panel .drop-panel-list-separator .drop-panel-menu-bar-separator {
  margin-left: 0; }

.classic-facelift-skin .top-bar-drop-down-panel .drop-panel-list {
  padding-left: 6px; }

.classic-facelift-skin .drop-panel-list {
  margin-right: 24px; }
  .classic-facelift-skin .drop-panel-list > .drop-panel-list-item:first-child {
    margin-top: 0; }
  .classic-facelift-skin .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.3; }
    .classic-facelift-skin .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.selected {
      color: #116dff; }
      .classic-facelift-skin .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.selected.list-item-upgrade {
        color: #9a27d5; }
  .classic-facelift-skin .drop-panel-list .drop-panel-list-separator .drop-panel-menu-bar-separator {
    margin: 9px 0; }

.drop-panel-mobile-controls {
  position: relative;
  width: 278px; }
  .drop-panel-mobile-controls .composite-radio-buttons-with-illustration {
    padding: 14px 24px; }
    .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item {
      cursor: pointer; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .image,
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .content .text {
        color: #116dff; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .image svg > g,
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .content .text svg > g {
          fill: #116dff; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:not(:first-child) {
        padding-top: 0; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .radio-button-symbol {
        margin-top: 0;
        align-self: center;
        height: 14px;
        width: 14px; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .radio-button-symbol .inner-fill {
          height: 10px;
          width: 10px; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text {
        align-items: center; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text .image {
          margin: 0 10px 0 0; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text .content {
          padding-bottom: 0; }
  .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom {
    position: absolute;
    width: 100%;
    bottom: 0; }
    .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch {
      padding: 18px 24px 6px 24px; }
      .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch .control-switch > .symbol-switch {
        margin-left: 0; }
        .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch .control-switch > .symbol-switch svg {
          width: 40px; }

.default-upgrade-panel-content {
  display: flex;
  flex-direction: column; }

.upgrade-promotional-panel.new-upgrade-content > div {
  margin-bottom: 0; }

.top-bar-drop-down-panel.upgrade-drop-panel-top-bar .bubble-content {
  padding: 0;
  min-width: 540px; }

.top-bar-drop-down-panel .default-upgrade-panel-content {
  padding: 24px 0; }

.top-bar-drop-down-panel .upgrade-promotional-panel {
  padding: 24px 24px 30px 30px; }
  .top-bar-drop-down-panel .upgrade-promotional-panel .footer-comment {
    display: block;
    color: #868aa5;
    margin-bottom: 12px; }

.drop-panel-top-bar .drop-panel-content {
  display: flex;
  max-width: 100%; }

.upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container {
  display: flex; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container {
    max-width: 352px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-label {
      margin-left: 23px;
      margin-bottom: 14px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item {
      align-items: center; }
      .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item > .control-label .control-label-base {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
      .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item > svg {
        width: 11px;
        height: 10px;
        align-self: flex-start;
        margin-right: 13px;
        margin-top: 4px;
        fill: #9a27d5; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .symbol-container {
    padding: 16px 12px 0;
    align-self: flex-end; }

.upgrade-drop-panel-top-bar .drop-panel-content > .footer-container {
  padding: 0 24px; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .light-purple {
    background: #e5c9ee;
    color: #9a27d5; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .light-purple:hover {
      background: #eedbf4;
      color: #9a27d5; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .dark-purple {
    background: #9a27d5;
    color: #fff; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .dark-purple:hover {
      background: #c161f0;
      color: #fff; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .regular-footer {
    margin-top: 24px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .regular-footer > .control-button:first-child {
      margin-right: 18px; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .first-save-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 13px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .first-save-footer > .comment {
      color: #868aa5;
      margin-bottom: 12px; }

.code-drop-panel-corvid.drop-panel-content {
  display: block; }
  .code-drop-panel-corvid.drop-panel-content > .header-container {
    padding-top: 24px;
    padding-left: 24px; }
  .code-drop-panel-corvid.drop-panel-content > .middle-container {
    padding-left: 24px;
    white-space: nowrap; }
    .code-drop-panel-corvid.drop-panel-content > .middle-container > .symbol-container {
      width: 144px;
      display: inline-block;
      vertical-align: top; }
    .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container {
      width: 294px;
      display: inline-block;
      vertical-align: top;
      margin-right: 30px; }
      .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container .subtitle {
        margin-bottom: 12px;
        font-size: 16px; }
      .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container .control-promotional-list {
        display: block;
        margin-bottom: 14px; }
        .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item {
          align-items: center;
          margin-bottom: 10px; }
          .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item > .control-label .control-label-base {
            font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
            font-weight: var(--wbu-font-weight-regular);
            font-size: 14px;
            -webkit-font-smoothing: antialiased; }
          .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item > svg {
            width: 11px;
            height: 10px;
            align-self: flex-start;
            margin-right: 13px;
            margin-top: 4px;
            fill: #116dff; }
      .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container > .text-link-list {
        margin-bottom: 14px; }
        .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item {
          cursor: pointer;
          margin-bottom: 10px; }
          .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .text-link {
            display: inline-block;
            vertical-align: middle; }
          .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .link-symbol-container {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px; }
            .code-drop-panel-corvid.drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .link-symbol-container > .symbol-link_icon > path {
              fill: #116dff; }
  .code-drop-panel-corvid.drop-panel-content > .footer-container {
    padding-bottom: 18px; }
    .code-drop-panel-corvid.drop-panel-content > .footer-container .regular-footer {
      padding-left: 24px; }
    .code-drop-panel-corvid.drop-panel-content > .footer-container .enabled-footer {
      padding-top: 18px; }
      .code-drop-panel-corvid.drop-panel-content > .footer-container .enabled-footer .enabled-text-container {
        padding-top: 18px;
        padding-left: 24px; }
        .code-drop-panel-corvid.drop-panel-content > .footer-container .enabled-footer .enabled-text-container .enabled-footer-link {
          display: inline;
          padding-left: 4px; }
    .code-drop-panel-corvid.drop-panel-content > .footer-container button {
      height: 30px;
      padding-left: 18px;
      padding-right: 18px;
      font-size: 14px; }

.top-bar-drop-down-panel .code-drop-panel-corvid.drop-panel-content .header-container,
.top-bar-drop-down-panel .code-drop-panel-corvid.drop-panel-content .middle-container,
.top-bar-drop-down-panel .code-drop-panel-corvid.drop-panel-content .regular-footer {
  padding-left: 6px; }

.top-bar-drop-down-panel .code-drop-panel-corvid.drop-panel-content .header-container {
  padding-top: 0; }

.top-bar-drop-down-panel .code-drop-panel-corvid.drop-panel-content .footer-container {
  padding-bottom: 0; }

.corvid-promotional-panel .footer-divider {
  margin: 18px 0; }

.corvid-promotional-panel .footer-link {
  display: inline;
  padding-left: 4px; }

.arena-drop-panel-content {
  max-width: 510px;
  padding-top: 6px;
  padding-left: 24px; }
  .arena-drop-panel-content > .middle-container {
    display: flex; }
    .arena-drop-panel-content > .middle-container > .text-container {
      width: 300px;
      display: inline-block;
      vertical-align: top;
      margin-right: 30px; }
      .arena-drop-panel-content > .middle-container > .text-container .subtitle {
        white-space: nowrap;
        margin-bottom: 12px;
        font-size: 16px; }
      .arena-drop-panel-content > .middle-container > .text-container .control-promotional-list {
        margin-bottom: 14px; }
        .arena-drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item {
          align-items: center;
          margin-bottom: 10px; }
          .arena-drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item > .control-label .control-label-base {
            font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
            font-weight: var(--wbu-font-weight-regular);
            font-size: 14px;
            -webkit-font-smoothing: antialiased; }
          .arena-drop-panel-content > .middle-container > .text-container .control-promotional-list .promotional-list-item > svg {
            width: 11px;
            height: 10px;
            align-self: flex-start;
            margin-right: 13px;
            margin-top: 4px;
            fill: #116dff; }
      .arena-drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item {
        cursor: pointer;
        margin-bottom: 10px; }
        .arena-drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .text-link {
          display: inline-block;
          vertical-align: middle; }
        .arena-drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .link-symbol-container {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px; }
          .arena-drop-panel-content > .middle-container > .text-container > .text-link-list .text-link-list-item > .link-symbol-container > .symbol-link_icon > path {
            fill: #116dff; }
    .arena-drop-panel-content > .middle-container > .symbol-container {
      width: 144px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px; }
  .arena-drop-panel-content > .footer-container button {
    height: 30px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 14px; }

.top-bar-drop-down-panel .arena-drop-panel-content {
  padding-top: 0;
  padding-left: 6px; }

.drop-panel-language-bubble .bubble-content {
  left: -20px;
  padding: 0; }

.drop-panel-language-bubble .languages-panel-container {
  overflow: hidden;
  border-radius: 4px; }
  .drop-panel-language-bubble .languages-panel-container .panel-header {
    border-top: none; }
  .drop-panel-language-bubble .languages-panel-container .languages-panel-languages-container {
    max-height: 297px;
    overflow-y: auto; }
  .drop-panel-language-bubble .languages-panel-container_se-madefor-font .composite-action-set-vertical {
    padding: 18px 24px; }

.drop-panel-language-bubble .click-outside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.drop-panel-language-bubble .language-status {
  right: 24px;
  position: absolute; }
  .drop-panel-language-bubble .language-status path {
    fill: #7a92a5; }

.drop-panel-language-bubble .main-language-indicator {
  position: absolute;
  width: auto;
  right: 24px;
  height: 18px;
  line-height: 18px;
  border-radius: 9px;
  padding: 0 6px 0 6px;
  background-color: #2b5672;
  color: #ffffff;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px; }

.drop-panel-language-bubble .manage-panel-button.manage-panel-button-small .composite-button-large {
  padding: 18px 24px; }

.language-selector-top-bar-button > .flag-icon {
  margin-left: 1px; }

.language-selector-top-bar-button > .language-label {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-left: 3px;
  margin-right: 9px; }

.language-selector-top-bar-button > .flag-arrow-down {
  margin: 0 3px;
  fill: #116dff; }

.language-selector-top-bar-button.top-bar-btn.top-bar-symbol-btn {
  width: 48px; }
  .language-selector-top-bar-button.top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol {
    border-bottom: 3px solid #4e9c4e; }
    .language-selector-top-bar-button.top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol,
    .language-selector-top-bar-button.top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol path {
      fill: #4e9c4e; }

.language-selector-top-bar-button-new:hover {
  background-color: #e7f0ff; }

.language-selector-top-bar-button-new.top-bar-btn.top-bar-symbol-btn {
  width: 100%;
  padding-left: 24px;
  padding-right: 18px; }

.language-selector-top-bar-button-new .flag-icon {
  margin-right: 10px; }
  .previewMode .language-selector-top-bar-button-new .flag-icon.flag-icon-invisible {
    position: relative; }
    .previewMode .language-selector-top-bar-button-new .flag-icon.flag-icon-invisible img {
      opacity: 0.4; }
      .previewMode .language-selector-top-bar-button-new .flag-icon.flag-icon-invisible img + svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }

.language-selector-top-bar-button-new .languageCode {
  margin-right: 10px; }

.language-selector-top-bar-button-new .symbol-arrowDown {
  fill: #5999ff; }

.classic-facelift-skin .language-selector-top-bar-button-new .symbol-arrowDown {
  fill: #000624; }

.classic-facelift-skin .language-selector-top-bar-button-new:hover {
  background-color: #fff; }
  .classic-facelift-skin .language-selector-top-bar-button-new:hover .symbol-arrowDown {
    fill: #116dff; }
  .classic-facelift-skin .language-selector-top-bar-button-new:hover .languageCode {
    color: #116dff; }

.languageRow {
  position: relative;
  line-height: initial;
  height: 65px;
  width: 336px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  cursor: pointer; }
  .languageRow.languageRowSmall {
    height: 54px;
    width: 288px; }
  .languageRow.languageRowMid {
    height: 54px;
    width: 448px; }
    .languageRow.languageRowMid .btn-make-language-visible {
      font-size: 14px;
      margin-right: 24px; }
  .languageRow.languageRowMid_se-madefor-font {
    width: 312px; }
  .languageRow.languageRowSelected {
    background-color: #d6e6fe; }
  .languageRow:hover {
    background-color: #eaf7ff; }
    .languageRow:hover .languageRowEditButton:not(.current) {
      background-color: #daeffe; }
  .languageRow:not(:hover) .info-icon-tooltip {
    display: none; }
  .languageRow > .languageRowFlag {
    width: 40px;
    display: flex;
    justify-content: center; }
    .languageRow > .languageRowFlag .flag-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      position: relative; }
      .previewMode .languageRow > .languageRowFlag .flag-icon.flag-icon-invisible {
        position: relative; }
        .previewMode .languageRow > .languageRowFlag .flag-icon.flag-icon-invisible img {
          opacity: 0.4; }
          .previewMode .languageRow > .languageRowFlag .flag-icon.flag-icon-invisible img + svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); }
      .languageRow > .languageRowFlag .flag-icon img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%; }
  .languageRow .languageRowTextContainer {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    margin-left: 8px; }
    .languageRow .languageRowTextContainer > .languageRowCode {
      color: #868aa5;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased; }
    .languageRow .languageRowTextContainer > .languageRowSubtitle {
      color: #3b4057;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased; }
  .languageRow > .languageRowSwitch {
    display: inline-block;
    position: absolute;
    right: 0; }
    .languageRow > .languageRowSwitch .languageRowEditButton {
      height: 30px;
      padding-left: 18px;
      padding-right: 12px;
      border-radius: 18px;
      margin-right: 24px;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #116dff; }
      .languageRow > .languageRowSwitch .languageRowEditButton.current {
        color: #3b4057;
        margin-right: 37px; }
      .languageRow > .languageRowSwitch .languageRowEditButton svg {
        width: 7px;
        height: 7px;
        margin-left: 6px; }
        .languageRow > .languageRowSwitch .languageRowEditButton svg path {
          fill: #116dff; }
    .languageRow > .languageRowSwitch .control-switch {
      overflow: visible; }
    .languageRow > .languageRowSwitch .hidden-tooltip {
      margin-right: 35px; }
      .languageRow > .languageRowSwitch .hidden-tooltip path {
        fill: #7a92a5; }

.localeRowRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  height: 48px;
  width: 274px;
  cursor: pointer; }
  .localeRowRoot.localeRowSelected {
    background-color: #116dff; }
  .localeRowRoot:hover {
    background-color: #d6e6fe; }
  .localeRowRoot .leftSideContainer {
    display: flex;
    align-items: center; }
    .localeRowRoot .leftSideContainer .leftSideTextsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .localeRowRoot .leftSideContainer .flagContainer {
      width: 24px;
      height: 16px;
      margin-right: 12px; }
  .localeRowRoot .rightSideContainer {
    display: flex;
    align-items: center; }

.localeNameWrapper {
  margin: 0 12px; }

.flagWrapper {
  width: 28px;
  height: 17px; }

.localeName:hover {
  color: #116dff; }

.text-and-link-drop-panel {
  flex-direction: column;
  width: 204px; }
  .text-and-link-drop-panel > .drop-panel-body {
    margin-top: 6px; }
  .text-and-link-drop-panel > .drop-panel-body > .drop-panel-link,
  .text-and-link-drop-panel > .drop-panel-body .drop-panel-devider,
  .text-and-link-drop-panel > .drop-panel-body > .drop-panel-secondary-link {
    display: block;
    margin-top: 12px; }
  .text-and-link-drop-panel .drop-panel-body-autosave-keyText {
    color: #7a92a5;
    font-size: 12px;
    margin-left: 2px;
    white-space: nowrap; }

.publish-rc-drop-panel {
  width: 382px;
  padding-top: -4px;
  padding-bottom: 6px; }
  .publish-rc-drop-panel .control-button {
    margin-top: 19px;
    font-size: 14px; }
  .publish-rc-drop-panel .icon {
    position: absolute;
    right: 0; }
  .publish-rc-drop-panel .container {
    position: relative; }
  .publish-rc-drop-panel .content {
    width: 243px;
    padding-top: 6px;
    padding-left: 6px; }
  .publish-rc-drop-panel .label {
    margin-bottom: 2px; }
  .publish-rc-drop-panel .divider {
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 14px;
    margin-left: 0; }
  .publish-rc-drop-panel .illustration {
    position: absolute;
    top: 0;
    right: 0; }

.test-site-rc-drop-panel {
  width: 382px;
  padding-top: -4px;
  padding-bottom: 6px; }
  .test-site-rc-drop-panel .control-button {
    margin-top: 16px; }
  .test-site-rc-drop-panel .icon {
    position: absolute;
    right: 0; }
  .test-site-rc-drop-panel .container {
    position: relative; }
  .test-site-rc-drop-panel .content {
    width: 380px;
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px; }
  .test-site-rc-drop-panel .label {
    margin-bottom: 6px;
    margin-right: 8px; }
  .test-site-rc-drop-panel .label-wrapper {
    flex-direction: row;
    align-items: baseline;
    display: flex; }
  .test-site-rc-drop-panel .divider {
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0; }
  .test-site-rc-drop-panel .description {
    display: flex;
    flex-wrap: wrap; }

.code-drop-panel-branch-indicator.drop-panel-content {
  display: block;
  width: 484px; }
  .code-drop-panel-branch-indicator.drop-panel-content .header-container {
    padding: 4px 3px 0 3px; }
    .code-drop-panel-branch-indicator.drop-panel-content .header-container .title,
    .code-drop-panel-branch-indicator.drop-panel-content .header-container .version-name {
      margin-right: 7px;
      display: inline-block;
      width: auto; }
  .code-drop-panel-branch-indicator.drop-panel-content .middle-container {
    padding: 20px 5px 0 5px; }
    .code-drop-panel-branch-indicator.drop-panel-content .middle-container .control-promotional-list .promotional-list-item {
      font-size: 14px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .code-drop-panel-branch-indicator.drop-panel-content .middle-container .control-promotional-list .promotional-list-item svg {
        width: 10px;
        margin-right: 14px; }
      .code-drop-panel-branch-indicator.drop-panel-content .middle-container .control-promotional-list .promotional-list-item .control-label .control-label-base {
        font-size: 14px;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .code-drop-panel-branch-indicator.drop-panel-content .middle-container .control-promotional-list > .promotional-list-item:not(:last-child) {
      margin-bottom: 10px; }
    .code-drop-panel-branch-indicator.drop-panel-content .middle-container .control-rich-text.description-holder {
      padding: 20px 0; }
  .code-drop-panel-branch-indicator.drop-panel-content .footer-container .manage-branches-link {
    padding: 18px 5px 0 5px; }

@keyframes progress-indicator {
  0% {
    width: 0; }
  50% {
    width: 100%; }
  100% {
    width: 0; } }

@keyframes top-bar-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.top-bar-left-section .top-bar-btn-device {
  border-left: 0;
  border-right: 1px #e7f0ff solid; }

.top-bar-center-section .top-bar-switcher-divider {
  width: 0;
  height: 20px;
  border: 1px solid #d8d8d8;
  border-right: 0;
  margin-left: 13px; }

.top-bar-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  border: 0;
  border-left: 1px #e7f0ff solid;
  position: relative;
  white-space: nowrap;
  overflow: hidden; }
  .top-bar-btn-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff; }
  .top-bar-btn_primary {
    color: #116dff; }
  .top-bar-btn.top-bar-symbol-btn {
    padding: 0 9px; }
    .top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol {
      border-bottom: 3px solid #116dff;
      height: 48px;
      box-sizing: border-box;
      padding-top: 3px; }
      .top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol,
      .top-bar-btn.top-bar-symbol-btn.toggled .btn-symbol path {
        fill: #116dff; }
  .top-bar-btn.top-bar-small-symbol-btn {
    padding: 0 12px; }
  .top-bar-btn.top-bar-btn-auto-save-in-progress-indicator .top-bar-btn-content-wrapper .label {
    color: #868aa5; }
    .top-bar-btn.top-bar-btn-auto-save-in-progress-indicator .top-bar-btn-content-wrapper .label:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(4, end) 1200ms infinite;
      content: '\2026';
      width: 0;
      position: absolute; }

@keyframes ellipsis {
  to {
    width: 1.25em; } }
  .top-bar-btn.top-bar-btn-in-progress-indicator::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #116dff;
    border-right: 300px solid #fff;
    animation: progress-indicator-anim 1.5s infinite ease-out; }
  .top-bar-btn.top-bar-btn-device {
    padding: 0 20px;
    min-width: 60px;
    display: block; }
    .top-bar-btn.top-bar-btn-device.active {
      background-color: #116dff; }
    .top-bar-btn.top-bar-btn-device.mobile {
      border-left: 0;
      padding: 0 15px 0 4px;
      min-width: 20px; }
    .top-bar-btn.top-bar-btn-device.desktop {
      border-right: 0;
      min-width: 20px;
      padding: 0 4px 0 15px; }
  .top-bar-btn.top-bar-btn-save {
    min-width: 85px; }
  .top-bar-btn.top-bar-publish-rc {
    fill: #fff;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #116dff;
    color: #fff;
    border-left: 1px solid #80b1ff; }
    .top-bar-btn.top-bar-publish-rc:hover {
      background-color: #5999ff;
      border-left: 1px solid #80b1ff; }
    .top-bar-btn.top-bar-publish-rc.active {
      background-color: #5999ff; }
    .top-bar-btn.top-bar-publish-rc.disabled {
      background-color: #116dff; }
  .top-bar-btn.top-bar-edit-mode-last-btn:not(.top-bar-btn-in-progress-indicator) {
    background-color: #116dff;
    color: #fff;
    border-left-color: #116dff; }
    .top-bar-btn.top-bar-edit-mode-last-btn:not(.top-bar-btn-in-progress-indicator):hover {
      background-color: #5999ff;
      border-left-color: #5999ff; }
  .top-bar-btn.top-bar-edit-mode-last-btn.disabled.top-bar-btn-in-progress-indicator:hover, .top-bar-btn.top-bar-edit-mode-last-btn.disabled:not(.top-bar-btn-done-successfully) {
    background-color: #fff;
    border-left-color: #e7f0ff; }
  .top-bar-btn.top-bar-edit-mode-last-btn.app-studio-package {
    background-color: #2d3034;
    border-left-color: #2d3034; }
    .top-bar-btn.top-bar-edit-mode-last-btn.app-studio-package:hover {
      background-color: #3b4057;
      border-left-color: #3b4057; }
  .top-bar-btn.top-bar-btn-back-to-editor {
    padding: 0;
    color: #fff;
    background-color: #25a55a;
    border-left-color: #25a55a; }
    .top-bar-btn.top-bar-btn-back-to-editor:hover:not(.disabled) {
      background-color: #51b77b;
      border-left-color: #51b77b; }
  .top-bar-btn.top-bar-fake-layout .fake-layout-item {
    display: inline-block;
    height: 0;
    overflow: hidden; }
    .top-bar-btn.top-bar-fake-layout .fake-layout-item.fake-layout-type-text {
      padding-left: 20px;
      padding-right: calc( 20px + 1px); }
    .top-bar-btn.top-bar-fake-layout .fake-layout-item:last-of-type {
      padding-right: 20px; }
  .top-bar-btn.top-bar-fake-layout .label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .top-bar-btn.hidden {
    display: none; }
  .top-bar-btn.disabled {
    cursor: default;
    background-color: #fff;
    color: #bcbcbc; }
  .top-bar-btn .top-bar-btn-content-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .top-bar-btn:hover:not(.disabled):not(.top-bar-btn-done-successfully):not(.active):not(.top-bar-edit-mode-last-btn) .btn-symbol g, .top-bar-btn:hover:not(.disabled):not(.top-bar-btn-done-successfully):not(.active):not(.top-bar-edit-mode-last-btn) .btn-symbol path {
    fill: #116dff; }
  .top-bar-btn-active.top-bar-btn .btn-symbol g, .top-bar-btn-active.top-bar-btn .btn-symbol path {
    fill: #116dff; }
  .top-bar-edit-mode-last-btn.top-bar-btn .btn-symbol g, .top-bar-edit-mode-last-btn.top-bar-btn .btn-symbol path {
    fill: #fff; }
  .active.top-bar-btn-device.top-bar-btn .btn-symbol g, .active.top-bar-btn-device.top-bar-btn .btn-symbol path {
    fill: #fff; }
  .disabled.top-bar-btn:not(.top-bar-btn-done-successfully) .btn-symbol g, .disabled.top-bar-btn:not(.top-bar-btn-done-successfully) .btn-symbol path {
    fill: #bcbcbc; }
  .top-bar-btn .label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    display: block; }
    .top-bar-btn-done-successfully.top-bar-btn .label {
      visibility: hidden;
      height: 0; }
    .active.top-bar-btn-device.top-bar-btn .label {
      color: #fff; }
    .top-bar-btn-device.top-bar-btn .label {
      display: inline-flex;
      align-items: center; }
      .active.top-bar-btn-device.top-bar-btn .label, .top-bar-btn-device.top-bar-btn:hover .label {
        color: #fff; }
    .top-bar-btn-idle-saved.top-bar-btn .label {
      animation: top-bar-fade 0.3s ease-out; }
    .top-bar-btn-idle-saved.top-bar-btn:not(.top-bar-edit-mode-last-btn) .label {
      color: #000624; }
    .disabled.top-bar-btn .label {
      color: #bcbcbc; }
    .top-bar-btn:hover:not(.top-bar-publish-btn):not(.top-bar-test-site-btn):not(.top-bar-back-to-editor-btn):not(.top-bar-upgrade-btn):not(.top-bar-edit-mode-last-btn):not(.top-bar-btn-back-to-editor):not(.disabled):not(.active) .label {
      color: #116dff; }

@keyframes border-blink {
  0% {
    border: 2px dashed #7e7eee;
    border-top: none; }
  50% {
    border: 2px dashed rgba(126, 126, 238, 0);
    border-top: none; }
  100% {
    border: 2px dashed #7e7eee;
    border-top: none; } }

@keyframes background-blink {
  0% {
    background: #0099ef; }
  50% {
    background: #006aa9; }
  100% {
    background: #0099ef; } }

.hide-tools-button {
  z-index: 1502;
  position: absolute;
  top: -1px;
  left: calc(50% - 57px);
  width: 115px;
  height: 12px;
  border: 1px solid #dfe5eb;
  background-color: #e7f0ff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  text-align: center;
  cursor: pointer;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .hide-tools-button.hide-tools-button-with-banner {
    top: 48px;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
  .hide-tools-button.tools-hidden {
    transition-duration: 0.4s;
    height: 18px; }
    .hide-tools-button.tools-hidden.tools-hidden-with-banner {
      top: -1px; }
    .hide-tools-button.tools-hidden .hide-tools-symbol {
      transition-duration: 0.4s;
      margin-top: 7px; }
  .hide-tools-button.blinking-border {
    animation: border-blink 1.2s linear infinite; }
  .hide-tools-button.stand-out {
    background: #116dff;
    animation: background-blink 1.4s linear infinite; }
    .hide-tools-button.stand-out:hover {
      background: #5999ff; }
    .hide-tools-button.stand-out .hide-tools-symbol {
      fill: #fff; }
  .hide-tools-button:not(.tools-hidden) .hide-tools-symbol {
    transform: rotateX(180deg); }
  .hide-tools-button:not(.stand-out):hover .hide-tools-symbol {
    fill: #116dff; }
  .hide-tools-button .hide-tools-symbol {
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) margin-top;
    color: #162d3c;
    width: 7px;
    height: 4px;
    vertical-align: top;
    margin-top: 3px;
    margin-left: -2px; }

.top-bar-banner {
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1; }

.top-bar-center-notification {
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0 12px; }
  .top-bar-center-notification .symbol {
    margin-right: 3px; }
  .top-bar-center-notification a {
    color: #116dff; }
    .top-bar-center-notification a:hover {
      cursor: pointer;
      text-decoration: underline; }

.top-bar-menu-bar-item {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  cursor: pointer;
  float: left;
  height: 48px;
  line-height: 48px;
  padding: 0 18px;
  text-align: center;
  transition: color 0.1s ease;
  white-space: nowrap; }
  .top-bar-menu-bar-item.active {
    color: #5999ff; }
    .top-bar-menu-bar-item.active.menu-bar-upgrade-item {
      color: #9a4cc6; }
  .top-bar-menu-bar-item .top-bar-menu-bar-item-counter {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    font-size: 11px;
    display: inline-block;
    position: absolute;
    background-color: #25a55a;
    border-radius: 15px;
    min-width: 12px;
    padding: 0 4px;
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    line-height: 20px;
    left: calc(100% - 18px);
    top: 4px; }
  .top-bar-menu-bar-item .top-bar-menu-bar-item-notification {
    position: absolute;
    right: 8px;
    top: 12px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
    border: 1px solid #ffffff;
    background: #e62214; }
  .top-bar-menu-bar-item-prefix {
    display: inline-flex;
    float: left;
    align-items: center;
    padding-right: 6px;
    height: 100%; }

.top-bar-device-switch {
  display: flex;
  height: 100%; }
  .top-bar-device-switch .top-bar-btn-device.desktop {
    padding: 0 5px 0 21px;
    border: none; }
  .top-bar-device-switch .top-bar-btn-device.mobile {
    padding: 0 21px 0 4px;
    border: none; }
  .classic-facelift-skin .top-bar-device-switch {
    align-items: center;
    justify-content: center;
    padding: 0 5px; }
    .classic-facelift-skin .top-bar-device-switch .top-bar-btn-content-wrapper:after {
      display: none; }
    .classic-facelift-skin .top-bar-device-switch .top-bar-btn-device.desktop,
    .classic-facelift-skin .top-bar-device-switch .top-bar-btn-device.mobile {
      padding: 0 8px; }
    .classic-facelift-skin .top-bar-device-switch.tour-maker-highlight-element {
      background-color: #fff; }
    .classic-facelift-skin .top-bar-device-switch::after {
      z-index: 0;
      content: '';
      width: 34px;
      height: 34px;
      border-radius: 4px;
      pointer-events: none;
      position: absolute;
      background-color: #116dff;
      opacity: 0.1;
      transition: transform 0.2s ease-in-out; }
    .classic-facelift-skin .top-bar-device-switch_desktop::after {
      transform: translate(-20px, 0); }
    .classic-facelift-skin .top-bar-device-switch_mobile::after {
      transform: translate(20px, 0); }

.top-bar-undo-redo {
  display: flex;
  height: 100%; }
  .top-bar-undo-redo .top-bar-button-undo {
    padding: 10px 0 9px 18px; }
  .top-bar-undo-redo .top-bar-button-redo {
    padding: 10px 18px 9px 0; }
  .classic-facelift-skin .top-bar-undo-redo {
    padding: 0 6px; }
    .classic-facelift-skin .top-bar-undo-redo .top-bar-button-undo {
      padding: 10px 0 9px 12px; }
    .classic-facelift-skin .top-bar-undo-redo .top-bar-button-redo {
      padding: 10px 12px 9px 0; }

.tools-panel-content {
  padding: 0;
  margin: 0 -6px; }
  .tools-panel-content.drop-panel-check-list.drop-panel-list {
    min-width: auto; }
    .tools-panel-content.drop-panel-check-list.drop-panel-list .drop-panel-list-item {
      margin: 0; }
      .tools-panel-content.drop-panel-check-list.drop-panel-list .drop-panel-list-item .title-item-container-drop-panel-list {
        padding: 6px 12px; }
    .tools-panel-content.drop-panel-check-list.drop-panel-list .drop-panel-list-separator .drop-panel-menu-bar-separator {
      margin: 12px; }
    .tools-panel-content.drop-panel-check-list.drop-panel-list .drop-panel-list-item-title {
      padding: 6px 12px;
      color: #3b4057; }
    .tools-panel-content.drop-panel-check-list.drop-panel-list .control-checkbox .label-checkbox {
      margin: 0 0 0 13px; }
  .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder {
    color: #116dff;
    margin-left: 12px;
    margin-right: 18px; }
    .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder .symbol path {
      fill: #116dff; }
    .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder:hover, .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder.selected {
      color: #5999ff;
      background: none; }
      .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder:hover .symbol path, .tools-panel-content .title-item-container-drop-panel-list.top-bar-tools-item-reorder.selected .symbol path {
        fill: #5999ff; }

.top-bar-btn.top-bar-tools-btn {
  padding: 0 24px 0 18px; }
  .top-bar-btn.top-bar-tools-btn svg {
    margin-right: 6px; }
  .top-bar-btn.top-bar-tools-btn.lower-position {
    padding: 0 18px 0 12px; }
    .top-bar-btn.top-bar-tools-btn.lower-position svg {
      margin-right: 6px;
      vertical-align: bottom; }
  .top-bar-btn.top-bar-tools-btn.toggled .top-bar-btn-content-wrapper:after {
    display: none; }
  .top-bar-btn.top-bar-tools-btn.small-paddings {
    padding: 0 18px 0 12px; }
  .top-bar-btn.top-bar-tools-btn.minimized {
    padding: 0 12px; }

.next-main-top-bar .top-bar-search {
  height: 100%; }

.next-main-top-bar .top-bar-btn.top-bar-button-search {
  padding: 0 18px; }
  .next-main-top-bar .top-bar-btn.top-bar-button-search svg {
    margin-right: 6px;
    margin-left: -8px; }
    .next-main-top-bar .top-bar-btn.top-bar-button-search svg.small-paddings {
      margin-right: 3px; }

.next-main-top-bar .top-bar-btn.top-bar-button-search.minimized {
  padding: 0 8px 0 20px; }

#mediaGalleryFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6002; }

.panel-frame-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.screen-centered-frame {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.transparent-frame-overlay,
.dark-frame-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; }

.dark-frame-overlay {
  background-color: rgba(0, 0, 0, 0.5); }
  .classic-facelift-skin .dark-frame-overlay {
    background-color: rgba(23, 25, 28, 0.66); }

.center-top-position {
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.panel-drag-handle {
  cursor: move; }

.content-iframe {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box; }

.message-panel-frame-z-index {
  z-index: 1719; }

.message-panel-frame {
  background-color: #fff; }
  .message-panel-frame .composite-rich-text-with-illustration .control-illustration > .symbol {
    width: 100%; }
  .message-panel-frame .composite-rich-text-with-illustration .control-illustration + .control-rich-text,
  .message-panel-frame .composite-rich-text-with-illustration .control-illustration + .composite-rich-text,
  .message-panel-frame .composite-rich-text-with-illustration .control-illustration + .composite-rich-text-labeled {
    flex: 1; }
  .message-panel-frame .control-rich-text.t01 p {
    margin: 16px 0; }
    .message-panel-frame .control-rich-text.t01 p:first-child {
      margin-top: 0; }
    .message-panel-frame .control-rich-text.t01 p:last-child {
      margin-bottom: 0; }
  .message-panel-frame .footer-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.confirmation-panel-frame-z-index {
  z-index: 1719; }

.confirmation-panel-frame {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  background-color: #fff;
  min-width: 500px;
  min-height: 200px;
  max-width: 800px;
  max-height: 650px; }
  .confirmation-panel-frame > .panel-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .confirmation-panel-frame > .content {
    overflow-y: auto;
    padding: 25px; }
  .confirmation-panel-frame > footer {
    position: relative;
    bottom: 30px;
    text-align: right; }
    .confirmation-panel-frame > footer .control-button.btn-confirm-secondary, .confirmation-panel-frame > footer .control-button.btn-danger-secondary {
      margin-right: 15px; }
    .confirmation-panel-frame > footer .control-button.btn-danger-primary {
      margin-right: 30px; }
    .confirmation-panel-frame > footer > .divider {
      margin-top: 15px; }
    .confirmation-panel-frame > footer > .footer-massage {
      margin: 25px 45px 0 45px; }
  .confirmation-panel-frame.important-confirmation {
    cursor: default;
    width: 564px; }
    .confirmation-panel-frame.important-confirmation > .panel-header {
      background-color: #e62214;
      height: 60px; }
      .confirmation-panel-frame.important-confirmation > .panel-header > .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 20px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        max-height: 60px;
        line-height: 60px; }
      .confirmation-panel-frame.important-confirmation > .panel-header > .panel-header.close {
        background-color: rgba(22, 45, 61, 0.2);
        color: #fbd0cd; }
      .confirmation-panel-frame.important-confirmation > .panel-header .close {
        background-color: #c3504d; }
        .confirmation-panel-frame.important-confirmation > .panel-header .close:hover {
          background-color: #942b28; }
    .confirmation-panel-frame.important-confirmation .content {
      margin-right: 15px;
      line-height: 22px; }
      .confirmation-panel-frame.important-confirmation .content > p {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        font-weight: 300; }
      .confirmation-panel-frame.important-confirmation .content > p:first-child {
        margin-top: 0; }
      .confirmation-panel-frame.important-confirmation .content a {
        color: #116dff;
        text-decoration: none; }
    .confirmation-panel-frame.important-confirmation .icon {
      height: 180px;
      width: 150px;
      float: left;
      margin-right: 10px; }
  .confirmation-panel-frame.concurrent-save-panel {
    width: 576px;
    background-color: #fff; }
    .confirmation-panel-frame.concurrent-save-panel .panel-header {
      height: 54px; }
      .confirmation-panel-frame.concurrent-save-panel .panel-header .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 20px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        line-height: 54px; }
      .confirmation-panel-frame.concurrent-save-panel .panel-header .close {
        top: 14px;
        margin-top: 0; }
    .confirmation-panel-frame.concurrent-save-panel .content {
      padding: 0; }
    .confirmation-panel-frame.concurrent-save-panel > footer {
      position: static;
      text-align: right;
      margin: 0 30px 30px 0; }
      .confirmation-panel-frame.concurrent-save-panel > footer .btn-confirm-primary,
      .confirmation-panel-frame.concurrent-save-panel > footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased; }

.background-panel-frame {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 32px;
  left: 78px;
  max-height: calc( 100vh - var(--g-top-bar-height) - 64px);
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1105; }

.general-panel-frame-z-index {
  z-index: 1719; }

.popup-small-size-danger {
  background-color: #ffffff; }

#danger-message-panel-frame .composite-popup-small-size {
  width: 610px; }

#danger-message-panel-frame .danger-message-content {
  color: #000624; }

#danger-message-panel-frame .danger-panel-footer {
  display: flex;
  align-items: center; }
  #danger-message-panel-frame .danger-panel-footer .danger-panel-footer-text {
    margin: 0;
    padding-left: 44px;
    text-align: left; }
    #danger-message-panel-frame .danger-panel-footer .danger-panel-footer-text span {
      color: #3b4057; }
    #danger-message-panel-frame .danger-panel-footer .danger-panel-footer-text .link-part {
      padding-left: 0.4em;
      color: #116dff; }
      #danger-message-panel-frame .danger-panel-footer .danger-panel-footer-text .link-part:hover {
        cursor: pointer;
        text-decoration: underline; }
    #danger-message-panel-frame .danger-panel-footer .danger-panel-footer-text .error-code-part {
      padding-left: 0.4em; }
  #danger-message-panel-frame .danger-panel-footer .action-set-danger-message .control-button {
    margin-left: 0; }
  #danger-message-panel-frame .danger-panel-footer .action-set-danger-message .danger-confirm-Button.hidden:hover {
    background-color: #e62214; }
  #danger-message-panel-frame .danger-panel-footer .action-set-danger-message .danger-confirm-Button.hidden .confirm-button-label {
    visibility: hidden; }

#danger-message-panel-frame .composite-preloader {
  position: absolute; }
  #danger-message-panel-frame .composite-preloader .button-on-load {
    width: 9px;
    height: 18px;
    margin-left: -9px; }

.notification-panel-frame {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  position: fixed;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  min-width: 500px;
  min-height: 200px;
  max-width: 700px;
  max-height: 500px;
  top: 48px !important;
  z-index: 1710; }
  .notification-panel-frame > .content {
    overflow-y: auto; }

.left-panel-frame {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 32px;
  left: 70px;
  max-height: calc( 100vh - var(--g-top-bar-height) - 64px);
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1105;
  padding-left: 6px;
  display: flex;
  flex-direction: column; }
  .left-panel-frame.next-left-panel-frame {
    left: 0;
    top: 0;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    position: absolute; }
    .opened-panels .left-panel-frame.next-left-panel-frame {
      display: none; }
    .left-panel-frame.next-left-panel-frame.left-panel-frame-full-height {
      height: 100%;
      max-height: 100%; }
    .left-panel-frame.next-left-panel-frame:not(.dynamic-height) {
      max-height: 100%; }
    .left-panel-frame.next-left-panel-frame:after {
      border-radius: 0; }
  .left-panel-frame .left-panel-frame-content {
    flex: 1;
    overflow: hidden; }
  .left-panel-frame.panel-index-0,
  .left-panel-frame.panel-index-0 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-1,
  .left-panel-frame.panel-index-1 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-2,
  .left-panel-frame.panel-index-2 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-3,
  .left-panel-frame.panel-index-3 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-4,
  .left-panel-frame.panel-index-4 .left-panel-frame-content {
    min-height: 330px; }
  .left-panel-frame.panel-index-5,
  .left-panel-frame.panel-index-5 .left-panel-frame-content {
    min-height: 390px; }
  .left-panel-frame.panel-index-6,
  .left-panel-frame.panel-index-6 .left-panel-frame-content {
    min-height: 450px; }
  .left-panel-frame.panel-index-7,
  .left-panel-frame.panel-index-7 .left-panel-frame-content {
    min-height: 510px; }
  .left-panel-frame.panel-index-8,
  .left-panel-frame.panel-index-8 .left-panel-frame-content {
    min-height: 570px; }
  .left-panel-frame.panel-index-9,
  .left-panel-frame.panel-index-9 .left-panel-frame-content {
    min-height: 630px; }
  .left-panel-frame.panel-index-10,
  .left-panel-frame.panel-index-10 .left-panel-frame-content {
    min-height: 690px; }
  .left-panel-frame.panel-index-11,
  .left-panel-frame.panel-index-11 .left-panel-frame-content {
    min-height: 750px; }
  .left-panel-frame.panel-index-12,
  .left-panel-frame.panel-index-12 .left-panel-frame-content {
    min-height: 810px; }
  .left-panel-frame.panel-index-13,
  .left-panel-frame.panel-index-13 .left-panel-frame-content {
    min-height: 870px; }
  .left-panel-frame.panel-index-14,
  .left-panel-frame.panel-index-14 .left-panel-frame-content {
    min-height: 930px; }
  .left-panel-frame.panel-index-15,
  .left-panel-frame.panel-index-15 .left-panel-frame-content {
    min-height: 990px; }
  .left-panel-frame.panel-index-16,
  .left-panel-frame.panel-index-16 .left-panel-frame-content {
    min-height: 1050px; }
  .left-panel-frame.panel-index-17,
  .left-panel-frame.panel-index-17 .left-panel-frame-content {
    min-height: 1110px; }
  .left-panel-frame.panel-index-18,
  .left-panel-frame.panel-index-18 .left-panel-frame-content {
    min-height: 1170px; }
  .left-panel-frame.panel-index-19,
  .left-panel-frame.panel-index-19 .left-panel-frame-content {
    min-height: 1230px; }
  .left-panel-frame.panel-index-20,
  .left-panel-frame.panel-index-20 .left-panel-frame-content {
    min-height: 1290px; }
  .left-panel-frame .panel-header {
    border-bottom: 1px solid #d5d5d5; }
  .left-panel-frame.first-panel, .left-panel-frame.first-panel:after {
    border-top-left-radius: 0; }
  .left-panel-frame header.white-panel-header {
    background-color: #fff; }
  .left-panel-frame:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .left-panel-frame.app-manager-panel:after {
    background: #5a48f5; }
  .left-panel-frame.app-manager-panel-new:after {
    background: #1b3689; }
  .left-panel-frame.am-leftBarAppManagerFitnessIcon:after {
    background: #5a48f5; }
  .left-panel-frame.pages-panel-pp:after {
    background: #00aaaf; }
  .left-panel-frame.theme-panel:after {
    background: #003790; }
  .left-panel-frame.bg-panel:after {
    background: #f8a239; }
  .left-panel-frame.add-panel:after {
    background: #3899ec; }
  .left-panel-frame.app-market-panel:after {
    background: #d0427c; }
  .left-panel-frame.app-market-panel-20:after {
    background: #d0427c; }
  .left-panel-frame.app-market-panel-new:after {
    background: #d0427c; }
  .left-panel-frame.app-market-panel-no-header:after {
    background: #d0427c; }
  .left-panel-frame.my-uploads-panel:after {
    background: #fa7c33; }
  .left-panel-frame.media-manager-panel:after {
    background: #fa7c33; }
  .left-panel-frame.media-manager-panel-icon-b:after {
    background: #fa7c33; }
  .left-panel-frame.media-manager-panel-icon-c:after {
    background: #fa7c33; }
  .left-panel-frame.wix-data-panel:after {
    background: #00A65F; }
  .left-panel-frame.wix-data-panel-icon-b:after {
    background: #00A65F; }
  .left-panel-frame.interactions-hidden-components:after {
    background: #d0427c; }
  .left-panel-frame.blog-panel:after {
    background: #25a55a; }
  .left-panel-frame.new-blog-panel:after {
    background: #faad4d; }
  .left-panel-frame.wix-store-panel:after {
    background: #00adf5; }
  .left-panel-frame.wix-bookings-panel:after {
    background: #6C48EF; }
  .left-panel-frame.hidden-items-panel:after {
    background: #d0427d; }
  .left-panel-frame.mobile-elements-panel:after {
    background: #3899eb; }
  .left-panel-frame.layout-optimizer-panel:after {
    background: #45c8c1; }
  .left-panel-frame.ascend-panel:after {
    background: #1463DA; }
  .left-panel-frame.add-panel {
    display: block; }
    .left-panel-frame.add-panel > .left-panel-frame-content {
      color: #000;
      height: calc( 100vh - var(--g-top-bar-height) - 64px);
      white-space: nowrap; }
  .left-panel-frame.left-panel-frame-full-height {
    height: calc( 100vh - var(--g-top-bar-height) - 64px); }

.help-panel-frame {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  position: fixed;
  top: calc(50vh - (550px / 2));
  left: calc(50vw - (500px / 2));
  z-index: 1720;
  font-size: 0;
  border-radius: 4px;
  overflow: hidden; }
  .help-panel-frame .panel-header {
    width: calc(100% - 44px);
    margin-left: 44px;
    padding-left: 0;
    display: flex;
    align-items: flex-end; }
    .help-panel-frame .panel-header .close {
      position: static;
      margin: auto 13px auto 0 !important;
      top: auto; }
  .help-panel-frame iframe {
    width: 500px;
    height: 550px;
    background-color: #fff;
    border: 0; }
  .help-panel-frame .help-panel-frame-drag-handle {
    position: absolute;
    background-color: rgba(0, 0, 0, 0); }
  .help-panel-frame svg.symbol-preloader {
    position: absolute;
    top: calc(50% - (30px / 2));
    left: calc(50% - (30px / 2)); }
  .help-panel-frame .hidden {
    display: none; }
  .help-panel-frame .panel-header-title {
    width: 100%;
    height: 100%; }

.classic-facelift-skin .help-panel-frame .panel-header,
.classic-facelift-skin .help-panel-frame .panel-header.dark {
  border: none; }
  .classic-facelift-skin .help-panel-frame .panel-header > button,
  .classic-facelift-skin .help-panel-frame .panel-header > button.control-button,
  .classic-facelift-skin .help-panel-frame .panel-header > button.btn-header,
  .classic-facelift-skin .help-panel-frame .panel-header.dark > button,
  .classic-facelift-skin .help-panel-frame .panel-header.dark > button.control-button,
  .classic-facelift-skin .help-panel-frame .panel-header.dark > button.btn-header {
    margin-right: 0 !important;
    background-color: #fff; }
    .classic-facelift-skin .help-panel-frame .panel-header > button:hover,
    .classic-facelift-skin .help-panel-frame .panel-header > button.control-button:hover,
    .classic-facelift-skin .help-panel-frame .panel-header > button.btn-header:hover,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button:hover,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.control-button:hover,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.btn-header:hover {
      background-color: #f7f8f8; }
    .classic-facelift-skin .help-panel-frame .panel-header > button:active,
    .classic-facelift-skin .help-panel-frame .panel-header > button.control-button:active,
    .classic-facelift-skin .help-panel-frame .panel-header > button.btn-header:active,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button:active,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.control-button:active,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.btn-header:active {
      background-color: #dfe5eb; }
    .classic-facelift-skin .help-panel-frame .panel-header > button svg,
    .classic-facelift-skin .help-panel-frame .panel-header > button svg *,
    .classic-facelift-skin .help-panel-frame .panel-header > button.control-button svg,
    .classic-facelift-skin .help-panel-frame .panel-header > button.control-button svg *,
    .classic-facelift-skin .help-panel-frame .panel-header > button.btn-header svg,
    .classic-facelift-skin .help-panel-frame .panel-header > button.btn-header svg *,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button svg,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button svg *,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.control-button svg,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.control-button svg *,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.btn-header svg,
    .classic-facelift-skin .help-panel-frame .panel-header.dark > button.btn-header svg * {
      fill: #000624; }

.focus-panel-frame-z-index {
  z-index: 1710; }
  .focus-panel-frame-z-index.save-publish-panel-frame {
    z-index: 1730; }
  .focus-panel-frame-z-index.how-to-video-gallery-frame {
    min-height: calc(45vw + 2 * 60px); }

.focus-panel-frame {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  margin-top: 48px;
  border-radius: 4px;
  overflow: hidden;
  transform: translateZ(0); }
  .focus-panel-frame.popup-medium {
    width: 504px; }
  .focus-panel-frame .focus-panel-frame-content {
    height: calc(100% - 54px);
    overflow: hidden;
    background-color: #fff; }
  .focus-panel-frame .focus-panel-frame-content-no-header {
    height: 100%;
    overflow: hidden;
    background-color: #fff; }
  .focus-panel-frame.aviary-panel {
    width: 850px;
    height: 600px; }
  .focus-panel-frame.blog-manager-panel {
    width: 100%;
    height: 100%; }
  .focus-panel-frame.shoutout-templates-panel {
    width: 95%;
    height: 95%;
    margin-top: 0; }
  .focus-panel-frame.site-settings-panel {
    margin-top: 0; }
  .focus-panel-frame.example-focus-panel, .focus-panel-frame.itunes-link-maker {
    width: 66%;
    height: 66%; }
  .focus-panel-frame.organize-images-panel {
    width: 80%;
    height: 70%;
    min-width: 861px;
    min-height: 465px; }
  .focus-panel-frame.spotify-search-panel {
    width: 735px;
    height: 590px; }
  .focus-panel-frame.video-search-panel {
    width: 790px;
    height: 490px; }
  .focus-panel-frame.list-dbe-frame {
    height: 90%;
    position: relative; }
    @media screen and (max-width: 1200px) {
      .focus-panel-frame.list-dbe-frame {
        width: 900px; } }
    @media screen and (min-width: 1200px) {
      .focus-panel-frame.list-dbe-frame {
        width: 1170px; } }

.panel-frame-container.tool-panel-frame-container {
  height: calc(100% - var(--g-top-bar-height));
  top: var(--g-top-bar-height); }
  .panel-frame-container.tool-panel-frame-container.tool-panel-frame-z-index {
    z-index: 1710; }
  .panel-frame-container.tool-panel-frame-container .scrolled-content {
    max-height: 525px; }
  .panel-frame-container.tool-panel-frame-container .tool-panel-frame {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
    position: fixed;
    border-radius: 4px;
    background-color: #fff; }
    .panel-frame-container.tool-panel-frame-container .tool-panel-frame.hidden {
      visibility: hidden; }
    .panel-frame-container.tool-panel-frame-container .tool-panel-frame > .panel-header {
      border-radius: 4px 4px 0 0; }
  .panel-frame-container.tool-panel-frame-container .transparent-frame-overlay {
    height: calc(100% - var(--g-top-bar-height));
    top: var(--g-top-bar-height); }
  .panel-frame-container.tool-panel-frame-container.full-screen {
    height: 100%;
    top: 0; }
    .panel-frame-container.tool-panel-frame-container.full-screen .transparent-frame-overlay {
      height: 100%;
      top: 0; }

.tpa-settings-panel-frame {
  position: fixed;
  z-index: 1320;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  background-color: #fdfdfd;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  overflow-x: hidden; }
  .tpa-settings-panel-frame.version2 {
    background-color: #fdfdfd;
    overflow: hidden; }
  .tpa-settings-panel-frame .content {
    max-height: calc( 100vh - 166px); }
    .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper {
      padding: 0;
      overflow: hidden; }
      .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper .tpa-iframe {
        overflow: hidden; }
    .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper-padding {
      padding: 1px 12px 5px 12px; }
    .tpa-settings-panel-frame .content .tpa-settings-panel-frame-padding {
      padding-bottom: 10px; }

.background-sub-panel-frame {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1200; }

.popup-template-panel {
  width: 498px; }
  .popup-template-panel .panel-header {
    height: auto;
    position: relative;
    background-color: #116dff;
    padding: 42px 48px 0;
    color: #fff;
    text-align: center; }
    .popup-template-panel .panel-header button.close {
      position: absolute;
      top: 24px;
      right: 18px;
      z-index: 1; }
    .popup-template-panel .panel-header h1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 30px;
      -webkit-font-smoothing: antialiased;
      margin-top: 0; }
    .popup-template-panel .panel-header h2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      line-height: 26px;
      margin: 0; }
    .popup-template-panel .panel-header .image {
      position: relative;
      top: 35px; }
  .popup-template-panel .content {
    padding: 48px 48px;
    color: #000624;
    background-color: #fff; }
    .popup-template-panel .content .panel-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      line-height: 22px;
      margin-bottom: 27px; }
      .popup-template-panel .content .panel-text .learn-more-link {
        color: #116dff;
        cursor: pointer; }
    .popup-template-panel .content .bottom-controls {
      padding-bottom: 18px; }
      .popup-template-panel .content .bottom-controls .dont-show-again {
        float: left;
        position: relative;
        top: 7px; }
      .popup-template-panel .content .bottom-controls .btn-ok {
        float: right; }
  .popup-template-panel.out-of-grid-panel.mobile .image {
    height: 125px; }
    .popup-template-panel.out-of-grid-panel.mobile .image svg {
      width: 78px; }
  .popup-template-panel.out-of-grid-panel.desktop h1 {
    font-size: 24px;
    line-height: 30px;
    margin: 0; }
  .popup-template-panel.out-of-grid-panel.desktop .image {
    height: 140px; }

.classic-facelift-skin .popup-template-panel .panel-header {
  background-color: #fff;
  color: #000624;
  border-bottom: none; }

.error-section .package-panel-content {
  height: 132px;
  padding-top: 34px; }
  .error-section .package-panel-content svg {
    padding-left: 24px; }

.image.container-out-of-grid {
  height: 95px; }
  .image.container-out-of-grid::after {
    position: relative;
    height: 140px;
    width: 262px;
    margin: 0 auto;
    content: '';
    display: block;
    top: -36px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/columnsOutOfGrid.v4.gif") center -13px / 262px no-repeat; }

.out-of-grid-desktop-panel .panel-header {
  padding-top: 39px; }

.out-of-grid-desktop-panel .image {
  top: 26px; }

.out-of-grid-desktop-panel h1 {
  font-size: 27px;
  margin-bottom: 11px; }

.out-of-grid-desktop-panel h2 {
  font-size: 17px; }

.image.page-out-of-grid {
  height: 95px; }
  .image.page-out-of-grid::after {
    position: relative;
    height: 140px;
    width: 262px;
    margin: 0 auto;
    content: '';
    display: block;
    top: -36px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/pageOutOfGrid.v2.gif") center -13px / 262px no-repeat; }

.aviary-dialog {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 11px; }
  .aviary-dialog .tmp-image {
    display: none; }
  .aviary-dialog #aviary_dialog_editor_content_wrapper {
    position: relative;
    height: 100%;
    width: 100%; }
  .aviary-dialog #message {
    font-size: 16px;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    width: 100%;
    top: 63%;
    color: #eee; }
  .aviary-dialog .message-bg {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5); }
  .aviary-dialog #cancel-button {
    cursor: pointer;
    font-size: 12px;
    color: #b99;
    text-decoration: underline; }
  .aviary-dialog #progress:after,
  .aviary-dialog #cancel-button:before {
    content: '\a';
    white-space: pre; }
  .aviary-dialog .hidden {
    display: none; }

.avpw_is_embed #avpw_controls {
  z-index: 0; }

body .avpw {
  background: #fdfdfd;
  border: none; }
  body .avpw .avpw_tool_icon_image {
    background-color: #f4f4f4;
    border-radius: 4px; }
  body .avpw .avpw_tool_icon:hover .avpw_tool_icon_image {
    background-color: #eeeeee;
    border: 1px solid #999999;
    box-sizing: border-box; }
  body .avpw .avpw_button {
    background-color: #f4f4f4;
    border-radius: 4px; }
    body .avpw .avpw_button:hover {
      background-color: #eeeeee; }
  body .avpw .avpw_primary_button, body .avpw .avpw_primary_button:link, body .avpw .avpw_primary_button:visited, body .avpw .avpw_primary_button:active {
    background-color: #2aa7ea;
    border: 1px solid #5b8fa7;
    border-radius: 4px;
    color: #ffffff; }
  body .avpw .avpw_primary_button:hover, body .avpw .avpw_primary_button:focus {
    background-color: #179ce1;
    border: 1px solid #237aa7; }
  body .avpw .avpw_current_tool_icon {
    background: #fdfdfd;
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -3px 5px 0 rgba(0, 0, 0, 0.1); }

.feedback-iframe {
  width: 720px;
  height: 487px;
  border: none; }

.how-to-video-gallery-panel {
  width: 80vw;
  height: 45vw;
  min-height: 452.25px;
  min-width: 804px;
  margin: 0; }
  .how-to-video-gallery-panel .panel-content {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .how-to-video-gallery-panel .panel-content > .gallery-iframe {
      width: calc(100% - 3px);
      flex: 1;
      border: none; }

.attach-to-sosp-panel .composite-panel-content {
  padding: 0 24px; }

.attach-to-sosp-panel .illustration {
  height: 138px;
  width: 394px; }

.detached-from-sosp-panel .composite-panel-content {
  padding: 0 24px; }

.detached-from-sosp-panel .illustration {
  height: 138px;
  width: 394px; }

.welcome-screen-panel.getTrafficPanel .content {
  margin-top: 70px; }

.welcome-screen-panel.getTrafficPanel .text-after-link {
  padding-right: 22px; }

.welcome-screen-panel.getTrafficPanel .get-found-link {
  text-decoration: none;
  color: #116dff; }

.blog-manager-panel {
  transform: none !important; }
  .blog-manager-panel .dark-frame-overlay,
  .blog-manager-panel .focus-panel-frame-content-no-header {
    background: transparent; }
  .blog-manager-panel .focus-panel-frame {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0; }

.organize-images-panel {
  position: relative; }
  .organize-images-panel #organizeImages {
    visibility: visible;
    opacity: 1; }
  .organize-images-panel svg.symbol-preloader {
    opacity: 1;
    visibility: visible; }
    .organize-images-panel svg.symbol-preloader.hidden {
      transition-delay: 0.5s; }
  .organize-images-panel .hidden {
    opacity: 0 !important;
    visibility: hidden !important; }
  .organize-images-panel .transition {
    transition-property: opacity, visibility;
    transition-duration: 0.5s; }

.promo-popup-panel {
  position: relative;
  width: 504px; }
  .promo-popup-panel .panel-header {
    background: #116dff;
    height: 210px;
    text-align: center; }
    .promo-popup-panel .panel-header .close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px; }
    .promo-popup-panel .panel-header .title-line-1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 30px;
      -webkit-font-smoothing: antialiased;
      color: #fff;
      padding-top: 57px; }
    .promo-popup-panel .panel-header .title-line-2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      color: #fff;
      padding-top: 12px; }
    .promo-popup-panel .panel-header .image-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-top: 42px; }
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-1,
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-2,
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-3 {
        display: none; }
    .promo-popup-panel .panel-header a[class~='image-wrapper'] {
      cursor: pointer; }
  .promo-popup-panel .content {
    background: #fff;
    text-align: center;
    padding: 0 48px; }
    .promo-popup-panel .content p:first-child {
      margin-top: 85px;
      line-height: 24px; }
    .promo-popup-panel .content p {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin-bottom: 0;
      text-align: start; }
    .promo-popup-panel .content p:not(:first-child) {
      margin-top: 19px; }
    .promo-popup-panel .content button {
      margin-top: 33px;
      margin-bottom: 48px;
      height: 30px; }
    .promo-popup-panel .content a {
      color: #116dff;
      cursor: pointer; }
      .promo-popup-panel .content a:hover {
        text-decoration: underline;
        cursor: pointer; }
  @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    .promo-popup-panel.music-promo-popup .panel-header .image-wrapper img {
      width: 189px; } }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-1,
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-2,
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-3 {
    content: '';
    position: absolute;
    display: block;
    border-radius: 3px; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-1 {
    background: rgba(17, 109, 255, 0.45);
    top: 0;
    left: 22px;
    width: 145px;
    height: 21px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-2 {
    background: rgba(17, 109, 255, 0.45);
    top: 0;
    left: 167px;
    width: 22px;
    height: 95px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-3 {
    background: rgba(17, 109, 255, 0.25);
    top: 21px;
    left: 0;
    width: 167px;
    height: 95px; }
  .promo-popup-panel.photo-promo-popup .panel-header {
    height: 252px; }
    .promo-popup-panel.photo-promo-popup .panel-header .title-line-1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      padding-top: 42px; }
    .promo-popup-panel.photo-promo-popup .panel-header .title-line-2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 30px;
      -webkit-font-smoothing: antialiased;
      padding-top: 9px; }
    .promo-popup-panel.photo-promo-popup .panel-header .image-wrapper {
      margin-top: 27px; }
      @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
        .promo-popup-panel.photo-promo-popup .panel-header .image-wrapper img {
          width: 270px; } }
  .promo-popup-panel.photo-promo-popup .content p:first-child {
    margin-top: 71px; }

.welcome-screen-panel {
  width: 742px;
  text-align: center; }
  .welcome-screen-panel .panel-header {
    height: 281px;
    text-align: center; }
    .welcome-screen-panel .panel-header > .close {
      top: 20px;
      right: 17px;
      margin-top: 0; }
    .welcome-screen-panel .panel-header > .videoInput-wrapper > iframe {
      width: 559px;
      height: 314px;
      background-color: #000000; }
    .welcome-screen-panel .panel-header > .videoInput-wrapper > .video-pixel-cover {
      display: inline-block;
      width: 559px;
      height: 1px;
      background-color: #ffffff;
      position: relative;
      top: -16px; }
  .welcome-screen-panel .welcome-panel-header-title {
    font-size: 30px;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 11px; }
  .welcome-screen-panel .welcome-panel-header-subtitle {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    padding-bottom: 25px; }
  .welcome-screen-panel .content {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    color: #000624;
    line-height: 20px;
    margin-top: 50px; }
    .welcome-screen-panel .content.only-new {
      padding-left: 90px;
      padding-right: 145px; }
    .welcome-screen-panel .content.switch-to-old {
      padding-left: 90px;
      padding-right: 110px; }
    .welcome-screen-panel .content.dev-mode-welcome {
      padding-left: 90px;
      padding-right: 110px;
      margin-top: 0; }
      .welcome-screen-panel .content.dev-mode-welcome a {
        margin-left: 6px;
        color: #116dff; }
    .welcome-screen-panel .content p {
      margin: 0; }
      .welcome-screen-panel .content p:first-child {
        padding-top: 134px; }
        .welcome-screen-panel .content p:first-child a {
          color: #116dff;
          cursor: pointer; }
  .welcome-screen-panel .buttons {
    padding: 30px 0; }
    .welcome-screen-panel .buttons .control-button {
      margin: 0 12px; }

.classic-facelift-skin .welcome-screen-panel .panel-header {
  border-bottom: none; }

.classic-facelift-skin .welcome-screen-panel .welcome-panel-header-title,
.classic-facelift-skin .welcome-screen-panel .welcome-panel-header-subtitle {
  color: #000624; }

.classic-facelift-skin .welcome-screen-panel .content {
  color: #3b4057; }

.how-to-video-player {
  z-index: 1740;
  min-height: calc(45vw + 2 * 60px); }
  .how-to-video-player > .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .how-to-video-player > .content > .dark-overlay {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5); }
    .how-to-video-player > .content > .close-overlay {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0; }
    .how-to-video-player > .content > .container-iframe {
      height: 45vw;
      width: 80vw;
      min-height: 452.25px;
      min-width: 804px;
      position: relative; }
      .how-to-video-player > .content > .container-iframe.with-shadow {
        box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36); }
      .how-to-video-player > .content > .container-iframe > .close {
        cursor: pointer;
        position: absolute;
        right: -60px;
        top: 0; }
        .how-to-video-player > .content > .container-iframe > .close:hover > svg > path {
          fill: #5999ff; }
      .how-to-video-player > .content > .container-iframe > iframe {
        height: 100%;
        width: 100%;
        border: none; }

.fail-panel-general {
  width: 600px; }
  .fail-panel-general .content-wrapper {
    padding: 30px 24px 35px 24px; }
    .fail-panel-general .content-wrapper .icon {
      display: inline-block;
      padding: 20px 30px 0 30px; }
    .fail-panel-general .content-wrapper .body-text {
      display: inline-block;
      max-width: 410px;
      vertical-align: top; }
    .fail-panel-general .content-wrapper .close-button {
      display: block;
      margin: 0 0 0 auto; }

.site-image-optimisation-panel {
  width: 414px; }
  .site-image-optimisation-panel .rich-text-paragraph {
    padding: 18px 0; }
  .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons {
    padding: 0 24px 24px 24px; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label {
      margin-right: 12px; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label:nth-of-type(3n) {
      margin-right: 0; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label:nth-of-type(-n + 3) {
      margin-bottom: 12px; }
  .site-image-optimisation-panel .action-buttons {
    padding: 24px;
    height: 35px; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel {
      float: left;
      background-color: #e7f0ff; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel .label {
      color: #116dff; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel:hover {
      background-color: #d6e6fe; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-done {
      float: right; }

.member-settings-panel-content {
  display: flex;
  flex-direction: column;
  height: 100%; }

.site-members-settings-panel {
  width: 440px; }
  .site-members-settings-panel .social-login-settings .header {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    background-color: #eff3f5;
    color: #075673;
    text-align: center;
    line-height: 40px; }
  .site-members-settings-panel .social-login-settings .options {
    padding: 0 24px; }
    .site-members-settings-panel .social-login-settings .options .social-option {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      display: flex;
      align-items: center;
      margin: 16px 0; }
      .site-members-settings-panel .social-login-settings .options .social-option > label {
        width: 100%;
        color: #000624; }
      .site-members-settings-panel .social-login-settings .options .social-option .icon {
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(43, 86, 114, 0.18);
        background-color: #fff;
        margin-right: 25px; }
        .site-members-settings-panel .social-login-settings .options .social-option .icon.google {
          padding: 13px 11.9px; }
        .site-members-settings-panel .social-login-settings .options .social-option .icon.facebook {
          padding: 3px 4.5px; }
  .site-members-settings-panel .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 18px 18px 18px; }
    .site-members-settings-panel .actions .link-container {
      display: flex;
      align-items: center;
      max-width: 296px; }
      .site-members-settings-panel .actions .link-container .link {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        flex: 1;
        color: #116dff;
        margin: 0 18px 0 12px;
        cursor: pointer; }

.site-members-settings-new-panel {
  width: 440px;
  max-height: 670px;
  height: calc( 100vh - 166px); }
  .site-members-settings-new-panel hr {
    flex-shrink: 0; }
  .site-members-settings-new-panel .policies-segment-header {
    flex-shrink: 0; }
  .site-members-settings-new-panel .tab-content {
    max-height: calc(100% - 47px - 1px - 65px);
    overflow: auto;
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 1;
    margin-top: -12px; }
  .site-members-settings-new-panel .tab-content.with-banner {
    max-height: calc( 100% - 47px - 1px - 65px - 30px); }
  .site-members-settings-new-panel .settings {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    top: 50px;
    bottom: 0; }
    .site-members-settings-new-panel .settings .header {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      background-color: #eff3f5;
      color: #075673;
      text-align: center;
      line-height: 40px; }
    .site-members-settings-new-panel .settings .options {
      padding: 0 24px;
      flex-shrink: 0; }
      .site-members-settings-new-panel .settings .options .social-option {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        margin: 16px 0; }
        .site-members-settings-new-panel .settings .options .social-option > label {
          width: 100%;
          color: #000624; }
        .site-members-settings-new-panel .settings .options .social-option .icon {
          border-radius: 50%;
          box-shadow: 0 0 6px rgba(43, 86, 114, 0.18);
          background-color: #fff;
          margin-right: 25px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.google {
            padding: 13px 11.9px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.facebook {
            padding: 3px 4.5px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.community {
            padding: 13px 11.5px; }
      .site-members-settings-new-panel .settings .options .option {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        margin: 21px 0; }
        .site-members-settings-new-panel .settings .options .option > label {
          width: 100%;
          color: #000624; }
    .site-members-settings-new-panel .settings .open-settings-button {
      margin-left: 9px; }
    .site-members-settings-new-panel .settings .open-settings-button-editorx {
      margin-left: -2px; }
  .site-members-settings-new-panel .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 18px 18px 18px; }
    .site-members-settings-new-panel .actions .done-button {
      margin-left: auto; }

.mobile-editor-first-time h1 {
  margin-bottom: -29px; }

.mobile-editor-first-time .image.check-your-mobile-site-image {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/checkYourMobileSite.v2.gif") -1px 0 no-repeat;
  width: 406px;
  height: 146px;
  transform: translateY(12px); }

.mobile-editor-first-time .image {
  transform: translateY(34px); }

.mobile-editor-first-time .content {
  padding-top: 83px; }

.back-to-adi-panel {
  margin-top: 0;
  width: 564px;
  height: 275px; }
  .back-to-adi-panel .back-to-adi-panel-content {
    width: 504px;
    margin: 34px 30px 30px; }
    .back-to-adi-panel .back-to-adi-panel-content .svg-container {
      display: inline-block;
      width: 132px;
      height: 94px; }
    .back-to-adi-panel .back-to-adi-panel-content .description-container {
      display: inline-block;
      padding-left: 24px;
      width: 348px;
      vertical-align: top; }
      .back-to-adi-panel .back-to-adi-panel-content .description-container .control-button.btn-text {
        font-size: 14px;
        padding-left: 4px; }
      .back-to-adi-panel .back-to-adi-panel-content .description-container > .description-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        line-height: 22px;
        display: block; }
    .back-to-adi-panel .back-to-adi-panel-content .footer-buttons {
      text-align: right; }
      .back-to-adi-panel .back-to-adi-panel-content .footer-buttons .footer-button {
        margin: 13px 0 0 10px; }

.custom-signup-name-panel {
  min-width: 505px;
  display: flex;
  flex-direction: column; }
  .custom-signup-name-panel .name-input-container {
    padding: 40px auto; }

.object-properties-editor {
  display: flex;
  flex-direction: column;
  height: calc(100% - 35px - 87px); }

.list-view {
  height: calc(100% - 79px);
  display: flex;
  flex: 1; }
  .list-view.with-title {
    height: calc(100% - 115px); }
  .list-view .empty-state {
    flex: 1; }
    .list-view .empty-state .add-property-btn-text-container {
      padding-top: 0;
      padding-bottom: 36px; }
    .list-view .empty-state .add-property-btn-text {
      font-size: 14px; }
    .list-view .empty-state .empty-state-rich-text-labeled {
      padding-bottom: 5px;
      width: 380px; }
    .list-view .empty-state .rich-text-with-illustration-container {
      padding-top: 36px; }
  .list-view .list-container {
    display: flex;
    position: relative; }
    .list-view .list-container .add-item-btn {
      position: absolute;
      bottom: 5px;
      left: 3px;
      padding: 0; }
  .list-view .list-border {
    padding-bottom: 42px; }
    .list-view .list-border.narrow {
      background-color: #f7fbff;
      border-right: 1px solid #cbe3f8;
      width: 173px; }
  .list-view .drag-list {
    margin-top: -1px; }
    .list-view .drag-list.narrow {
      margin: 8px 6px 0 6px;
      border-top: none; }
  .list-view .object-editor-container {
    flex: 1; }
  .list-view .narrow.sort-by-drag-list {
    width: 160px; }
  .list-view .narrow .sbdl-item-row {
    width: 160px;
    border-radius: 100px;
    border-bottom: none;
    margin-bottom: 2px; }
    .list-view .narrow .sbdl-item-row:not(:hover):not(.selected) {
      background-color: rgba(0, 0, 0, 0); }
  .list-view .narrow .sbdl-item-row-base {
    width: 160px;
    min-height: 32px; }
    .list-view .narrow .sbdl-item-row-base .content {
      left: 38px;
      width: calc(100% - 38px); }
    .list-view .narrow .sbdl-item-row-base .view-mode-content {
      height: 32px; }
      .list-view .narrow .sbdl-item-row-base .view-mode-content .context-menu-container {
        width: 30px; }
    .list-view .narrow .sbdl-item-row-base .drag-handle-container {
      left: -10px; }
  .list-view .wide.sort-by-drag-list {
    width: 474px; }
  .list-view .wide .sbdl-item-row {
    width: 474px; }
    .list-view .wide .sbdl-item-row input.input {
      width: 390px; }
  .list-view .wide .sbdl-item-row-base {
    width: 474px; }
  .list-view .sbdl-item-row.empty .content-before {
    color: #b6c1cd; }

.custom-type-value-edit-panel {
  min-height: 418px;
  height: 630px;
  max-height: calc(100vh - 140px);
  margin-top: 0; }
  .custom-type-value-edit-panel .property-value-panel {
    height: 100%;
    display: flex;
    flex-direction: column; }
    .custom-type-value-edit-panel .property-value-panel .prop-name-container .prop-name-label {
      margin: 8px 22px; }

.language-publish-success-panel .composite-panel-content {
  margin-top: -40px; }

.custom-error-page-panel {
  width: 744px; }
  .custom-error-page-panel__label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 30px;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1; }
  .custom-error-page-panel__content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .custom-error-page-panel__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px; }
  .custom-error-page-panel__symbol {
    margin-bottom: 13px; }
  .custom-error-page-panel__btn {
    margin-bottom: 24px; }
  .custom-error-page-panel__link.btn-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased; }
    .custom-error-page-panel__link.btn-text:hover {
      text-decoration: underline; }
  .custom-error-page-panel__rich-text {
    margin-top: 24px; }
  .custom-error-page-panel__title_centered {
    text-align: center; }
  .custom-error-page-panel_freemium .custom-error-page-panel__header .control-button.btn-header {
    background-color: #c161f0; }
    .custom-error-page-panel_freemium .custom-error-page-panel__header .control-button.btn-header:hover {
      background-color: #e5c9ee; }
  .custom-error-page-panel_freemium .custom-error-page-panel__header-text {
    background-color: #9a27d5; }
  .custom-error-page-panel_freemium .custom-error-page-panel__header-illustration .blue-strip {
    background-color: #9a27d5; }
  .custom-error-page-panel_freemium .custom-error-page-panel__symbol {
    transform: translateY(-28px);
    margin-bottom: 0; }
  .custom-error-page-panel_freemium .custom-error-page-panel__promotional-list .promotional-list-item > svg {
    fill: #9a27d5; }
  .custom-error-page-panel_freemium .custom-error-page-panel__link {
    color: #9a27d5; }
  .custom-error-page-panel_freemium .custom-error-page-panel__btn {
    background: #9a27d5;
    color: #fff;
    margin-bottom: 10px; }
    .custom-error-page-panel_freemium .custom-error-page-panel__btn:hover {
      background: #c161f0; }

.new-workspace-custom-error-page-panel_freemium .custom-error-page-panel__promotional-list .promotional-list-item > svg {
  fill: #9a27d5; }

.delete-saved-components-panel .control-illustration {
  padding-top: 18px; }

.add-to-my-elements-panel .save-to-my-elements-content {
  display: flex; }

.corvid-dev-mode-panel .composite-panel-content {
  padding: 9px 96px 53px; }

.corvid-dev-mode-panel .composite-action-set-vertical {
  margin-bottom: 16px; }

.corvid-dev-mode-panel .control-promotional-list .promotional-list-item > svg {
  margin-right: 8px;
  margin-top: 3px;
  width: 16px;
  height: 10px; }

.new-workspace-corvid-dev-mode-panel .corvid-dev-mode-panel-content {
  text-align: center; }

.welcome-tour-panel {
  width: 742px;
  text-align: center; }
  .welcome-tour-panel .panel-header {
    height: 258px; }
    .welcome-tour-panel .panel-header > .close {
      top: 20px;
      right: 17px;
      margin-top: 0;
      color: #000624;
      background: rgba(255, 255, 255, 0.3); }
      .welcome-tour-panel .panel-header > .close:hover {
        background: rgba(255, 255, 255, 0.5); }
      .welcome-tour-panel .panel-header > .close svg {
        fill: #000624; }
    .classic-facelift-skin .welcome-tour-panel .panel-header {
      border-top: 0; }
  .welcome-tour-panel__content {
    padding: 60px 72px 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .welcome-tour-panel__title {
    display: block;
    padding-bottom: 12px; }
  .welcome-tour-panel__description {
    display: block;
    padding-bottom: 12px; }
  .welcome-tour-panel__footer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .welcome-tour-panel__btn-secondary.btn-text {
    font-size: 16px; }
  .welcome-tour-panel__cover img {
    max-width: 100%; }

.qrcode-published-modal .control-base-modal .control-base-modal-body {
  text-align: center; }
  .qrcode-published-modal .control-base-modal .control-base-modal-body .control-promotional-modal-content-header .control-promotional-modal-illustration .qrcode-published-modal-image {
    width: 150px;
    height: 150px; }

.qrcode-published-modal .control-base-modal .control-base-modal-footnote .qrcode-published-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center; }
  .qrcode-published-modal .control-base-modal .control-base-modal-footnote .qrcode-published-modal-footer .qrcode-published-modal-link {
    cursor: pointer;
    font-size: 14px; }

.roles-manager-panel-wrapper {
  position: fixed;
  left: calc((100vw - 210px) / 2);
  top: calc((100vh - 303px) / 2); }
  .roles-manager-panel-wrapper .roles-manager-panel {
    width: 210px;
    display: flex;
    flex-direction: column; }
  .roles-manager-panel-wrapper .content-section {
    padding: 12px 24px 0; }
    .roles-manager-panel-wrapper .content-section .content-wrapper {
      min-height: 20px;
      max-height: 303px; }
      .roles-manager-panel-wrapper .content-section .content-wrapper .checkbox-container {
        margin-bottom: 11px; }
    .roles-manager-panel-wrapper .content-section .content-section-divider {
      margin-top: 19px; }
    .roles-manager-panel-wrapper .content-section .add-new-role {
      font-size: 14px;
      height: 17px;
      object-fit: contain;
      margin: 12px 0 12px; }
  .roles-manager-panel-wrapper .footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto; }
    .roles-manager-panel-wrapper .footer-section .pp-apply {
      min-width: 110px; }
  .roles-manager-panel-wrapper .actions-section {
    margin: 12px; }

.custom-signup-settings-panel-wrapper {
  left: 65%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -65%); }
  .custom-signup-settings-panel-wrapper .custom-signup-settings-panel {
    width: 480px;
    max-height: 325px;
    height: calc( 100vh - 166px);
    display: flex;
    flex-direction: column; }
    .custom-signup-settings-panel-wrapper .custom-signup-settings-panel hr {
      flex-shrink: 0; }
    .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .tab-content {
      flex-shrink: 0;
      flex-grow: 1;
      padding: 20px 24px 48px 24px;
      margin-bottom: auto; }
    .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .settings {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      left: 0;
      top: 104px; }
      .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .settings .header {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        background-color: #eff3f5;
        color: #075673;
        text-align: center;
        line-height: 40px; }
      .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .settings .options {
        padding: 0 24px;
        flex-shrink: 0; }
        .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .settings .options .option {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 14px;
          -webkit-font-smoothing: antialiased;
          display: flex;
          align-items: center;
          margin: 21px 0;
          padding-top: 24px; }
          .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .settings .options .option > label {
            width: 100%;
            color: #000624; }
    .custom-signup-settings-panel-wrapper .custom-signup-settings-panel .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 18px 18px 18px;
      margin-left: auto; }

.fonts-upload-panel .fonts-upload-header {
  height: 53px;
  border-bottom: 1px solid #d6e6fe;
  display: flex;
  align-items: center;
  background: #e7f0ff;
  padding: 0 18px 0 24px; }
  .fonts-upload-panel .fonts-upload-header .general-explanation-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    color: #868aa5; }
  .fonts-upload-panel .fonts-upload-header .font-upload-button {
    margin-left: auto; }

.fonts-upload-panel .empty-message-container {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 72px;
  width: 324px;
  margin: 0 auto; }
  .fonts-upload-panel .empty-message-container .empty-symbol-container {
    margin-bottom: 21px; }
  .fonts-upload-panel .empty-message-container .empty-text-title {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    color: #3b4057;
    display: inline-block;
    margin-bottom: 14px; }
  .fonts-upload-panel .empty-message-container .empty-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #868aa5;
    margin-right: 5px; }
  .fonts-upload-panel .empty-message-container .fonts-upload-link {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    margin-left: 6px; }
    .fonts-upload-panel .empty-message-container .fonts-upload-link:hover {
      text-decoration: underline;
      cursor: hand; }

.fonts-upload-panel .font-list-container {
  max-height: 352px; }

.fonts-upload-panel .fonts-upload-footer {
  border-top: 1px solid #dfe5eb;
  display: flex;
  align-items: center;
  padding: 0 18px 0 24px;
  height: 54px; }
  .fonts-upload-panel .fonts-upload-footer .footer-msg,
  .fonts-upload-panel .fonts-upload-footer .footer-error-msg {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding-right: 18px; }
  .fonts-upload-panel .fonts-upload-footer .footer-msg {
    color: #868aa5; }
  .fonts-upload-panel .fonts-upload-footer .footer-error-msg {
    color: #e62214; }
  .fonts-upload-panel .fonts-upload-footer .btn-md {
    min-width: 85px; }
  .fonts-upload-panel .fonts-upload-footer .close-panel {
    margin-left: auto; }

.fonts-upload-panel .big-fonts-upload-warning {
  height: 100%;
  background: #fff7e5;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px; }
  .fonts-upload-panel .big-fonts-upload-warning .big-fonts-learn-more-link {
    padding-left: 3px;
    color: #116dff;
    cursor: pointer; }
    .fonts-upload-panel .big-fonts-upload-warning .big-fonts-learn-more-link:hover {
      color: #5999ff;
      text-decoration: underline; }

@keyframes hide-fileName {
  0%,
  30% {
    transform: translate(0, 0);
    opacity: 1; }
  100% {
    transform: translate(0, 30px);
    opacity: 0; } }

@keyframes show-displayName {
  0%,
  70% {
    transform: translate(0, -20px);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes hide-fontMsg {
  0%,
  30% {
    transform: translate(0, 0);
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    transform: translate(0, 30px);
    opacity: 0; } }

.font-item {
  position: relative;
  min-height: 72px;
  border-bottom: 1px solid #f7f8f8;
  padding: 0 24px; }
  .font-item.queue .font-title.display-name {
    opacity: 0; }
  .font-item.queue .font-title.file-name {
    opacity: 1; }
  .font-item.animated .font-title.file-name {
    animation: hide-fileName 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.animated .font-title.display-name {
    animation: show-displayName 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.animated .font-status-msg {
    animation: hide-fontMsg 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.list .font-title.display-name {
    opacity: 1; }
  .font-item.list .font-title.file-name {
    opacity: 0; }
  .font-item .font-description .font-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #3b4057;
    max-width: 498px;
    position: absolute;
    display: block; }
    .font-item .font-description .font-title.display-name {
      min-height: 25px;
      top: 25px;
      font-size: 24px; }
    .font-item .font-description .font-title.file-name {
      height: 20px;
      top: 22px; }
  .font-item .font-description.uploaded .font-status-msg {
    opacity: 0; }
  .font-item .font-description .font-status-msg {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    color: #868aa5;
    display: block;
    position: absolute;
    top: 41px; }
    .queue .font-item .font-description .font-status-msg {
      min-height: 14px; }
  .font-item.error .font-status-msg {
    color: #e62214; }
  .font-item .font-indicator {
    position: absolute;
    right: 24px;
    top: 23px; }
  .font-item .cancel-icon {
    position: absolute;
    right: 26px;
    top: 23px;
    display: none; }
    .font-item .cancel-icon .cancel-btn {
      width: 25px;
      height: 25px;
      padding: 0;
      border-radius: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
      background-color: #fff; }
  .font-item .actions {
    min-height: 29px;
    display: none;
    position: absolute;
    right: 18px;
    top: 29px; }
    .font-item .actions .action-btn-container {
      display: inline-block; }
      .font-item .actions .action-btn-container .action-btn {
        cursor: pointer; }
      .font-item .actions .action-btn-container .download {
        margin-right: 12px; }
  .font-item:hover {
    background: #e7f0ff;
    cursor: default; }
  .font-item.cancelable:hover .cancel-icon {
    display: block;
    cursor: pointer; }
  .font-item.cancelable:hover .font-indicator {
    display: none; }
  .font-item.with-actions:hover .actions {
    display: inline-block; }
  .font-item.with-actions:hover .font-indicator {
    display: none; }

.link-panel-options-container .dropdown-options {
  max-width: 300px;
  max-height: 300px; }

.link-panel-options-container .selected-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.link-panel-options-container .link-panel-options-sticky-footer {
  align-items: start; }

.new-link-panel {
  position: fixed;
  width: 560px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%); }
  .new-link-panel .dropdown.combobox .combo-box {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .new-link-panel > .content {
    color: #000624;
    font-size: 14px; }
    .new-link-panel > .content p,
    .new-link-panel > .content button.control-button {
      font-size: 14px; }
    .new-link-panel > .content .tabs {
      border-bottom: 1px solid #dfe5eb;
      min-height: 235px; }
      .new-link-panel > .content .tabs > .tab-content {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        overflow-y: scroll;
        max-height: 296px; }
        .new-link-panel > .content .tabs > .tab-content.EdgeAnchorLinks {
          padding-right: 62px; }
        .new-link-panel > .content .tabs > .tab-content > .tab {
          height: 100%; }
          .new-link-panel > .content .tabs > .tab-content > .tab .scroll-container {
            display: flex;
            height: 100%; }
            .new-link-panel > .content .tabs > .tab-content > .tab .scroll-container-page-link, .new-link-panel > .content .tabs > .tab-content > .tab .scroll-container-anchor-link {
              max-height: 296px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.phone, .new-link-panel > .content .tabs > .tab-content > .tab.popup, .new-link-panel > .content .tabs > .tab-content > .tab.whatsapp, .new-link-panel > .content .tabs > .tab-content > .tab.page, .new-link-panel > .content .tabs > .tab-content > .tab.address {
            width: 100%;
            height: 100%; }
            .new-link-panel > .content .tabs > .tab-content > .tab.phone .extra-info, .new-link-panel > .content .tabs > .tab-content > .tab.popup .extra-info, .new-link-panel > .content .tabs > .tab-content > .tab.whatsapp .extra-info, .new-link-panel > .content .tabs > .tab-content > .tab.page .extra-info, .new-link-panel > .content .tabs > .tab-content > .tab.address .extra-info {
              font-size: 14px;
              line-height: 19px;
              margin: 0;
              padding: 20px 24px; }
              .new-link-panel > .content .tabs > .tab-content > .tab.phone .extra-info.with-business-number, .new-link-panel > .content .tabs > .tab-content > .tab.popup .extra-info.with-business-number, .new-link-panel > .content .tabs > .tab-content > .tab.whatsapp .extra-info.with-business-number, .new-link-panel > .content .tabs > .tab-content > .tab.page .extra-info.with-business-number, .new-link-panel > .content .tabs > .tab-content > .tab.address .extra-info.with-business-number {
                color: #3b4057; }
              .new-link-panel > .content .tabs > .tab-content > .tab.phone .extra-info:not(.with-business-number), .new-link-panel > .content .tabs > .tab-content > .tab.popup .extra-info:not(.with-business-number), .new-link-panel > .content .tabs > .tab-content > .tab.whatsapp .extra-info:not(.with-business-number), .new-link-panel > .content .tabs > .tab-content > .tab.page .extra-info:not(.with-business-number), .new-link-panel > .content .tabs > .tab-content > .tab.address .extra-info:not(.with-business-number) {
                color: #868aa5;
                position: absolute;
                bottom: 0; }
              .new-link-panel > .content .tabs > .tab-content > .tab.phone .extra-info .inline-link-button, .new-link-panel > .content .tabs > .tab-content > .tab.popup .extra-info .inline-link-button, .new-link-panel > .content .tabs > .tab-content > .tab.whatsapp .extra-info .inline-link-button, .new-link-panel > .content .tabs > .tab-content > .tab.page .extra-info .inline-link-button, .new-link-panel > .content .tabs > .tab-content > .tab.address .extra-info .inline-link-button {
                font-size: 14px;
                height: 16px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.none,
          .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups,
          .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            justify-content: center; }
            .new-link-panel > .content .tabs > .tab-content > .tab.none .symbol-container,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups .symbol-container,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors .symbol-container {
              height: 106px;
              line-height: 106px;
              margin-bottom: 12px;
              text-align: center; }
              .new-link-panel > .content .tabs > .tab-content > .tab.none .symbol-container > svg,
              .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups .symbol-container > svg,
              .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors .symbol-container > svg {
                vertical-align: bottom; }
            .new-link-panel > .content .tabs > .tab-content > .tab.none h3,
            .new-link-panel > .content .tabs > .tab-content > .tab.none p,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups h3,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups p,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors h3,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors p {
              padding: 0 24px;
              text-align: center; }
            .new-link-panel > .content .tabs > .tab-content > .tab.none h3,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups h3,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors h3 {
              color: #3b4057;
              font-size: 18px;
              font-weight: bold;
              line-height: 18px;
              margin: 0 0 10px 0; }
            .new-link-panel > .content .tabs > .tab-content > .tab.none p,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups p,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors p {
              color: #868aa5;
              font-weight: 300;
              line-height: 18px;
              margin: 0; }
            .new-link-panel > .content .tabs > .tab-content > .tab.none button.control-button.btn-text,
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups button.control-button.btn-text,
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors button.control-button.btn-text {
              height: 18px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page .control-button.btn-text {
            font-size: 14px;
            height: 18px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page p {
            margin: 0; }
          .new-link-panel > .content .tabs > .tab-content > .tab.anchor .anchor-link-description-container {
            padding-top: 18px;
            padding-right: 24px;
            padding-left: 24px;
            text-align: center; }
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor .anchor-link-description-container .anchor-link-description {
              margin-top: 10px;
              margin-bottom: 18px;
              display: inline-block; }
            .new-link-panel > .content .tabs > .tab-content > .tab.anchor .anchor-link-description-container .control-button.btn-text {
              font-size: 14px;
              height: 18px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.none {
            padding: 0;
            justify-content: center; }
          .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups {
            padding-top: 0;
            height: 100%;
            justify-content: center;
            align-items: center; }
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups .btn-add-lightbox {
              margin-top: 14px; }
            .new-link-panel > .content .tabs > .tab-content > .tab.popup > .no-popups button.control-button.btn-text:not(.no-margins) {
              margin-top: 10px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page .dropdown-title, .new-link-panel > .content .tabs > .tab-content > .tab.anchor .dropdown-title, .new-link-panel > .content .tabs > .tab-content > .tab.popup .dropdown-title {
            color: #3b4057; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page .control-dropdown .dropdown-selected, .new-link-panel > .content .tabs > .tab-content > .tab.anchor .control-dropdown .dropdown-selected, .new-link-panel > .content .tabs > .tab-content > .tab.popup .control-dropdown .dropdown-selected {
            margin-bottom: 5px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page .page-link-drop-divider, .new-link-panel > .content .tabs > .tab-content > .tab.anchor .page-link-drop-divider, .new-link-panel > .content .tabs > .tab-content > .tab.popup .page-link-drop-divider {
            margin-top: -5px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page > .dropdown.select:first-child, .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .dropdown.select:first-child, .new-link-panel > .content .tabs > .tab-content > .tab.popup > .dropdown.select:first-child {
            border-bottom: 1px solid #dfe5eb; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page > .dropdown.select:nth-child(2), .new-link-panel > .content .tabs > .tab-content > .tab.anchor > .dropdown.select:nth-child(2), .new-link-panel > .content .tabs > .tab-content > .tab.popup > .dropdown.select:nth-child(2) {
            padding-top: 20px;
            padding-bottom: 8px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.page > button.control-button.btn-text, .new-link-panel > .content .tabs > .tab-content > .tab.anchor > button.control-button.btn-text, .new-link-panel > .content .tabs > .tab-content > .tab.popup > button.control-button.btn-text {
            margin-top: 8px;
            text-align: left; }
          .new-link-panel > .content .tabs > .tab-content > .tab.edge-anchors .control-label {
            padding: 14px 24px 0; }
          .new-link-panel > .content .tabs > .tab-content > .tab.document .control-label {
            padding: 14px 24px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.document > .media-button {
            margin-bottom: 22px; }
          .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf {
            font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
            font-weight: var(--wbu-font-weight-regular);
            font-size: 14px;
            -webkit-font-smoothing: antialiased;
            font-weight: 300; }
            .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf > .search-results-pdf-text {
              margin: 17px 25px;
              color: #3899ec;
              line-height: 18px;
              display: inline-block;
              cursor: pointer; }
              .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf > .search-results-pdf-text:hover {
                cursor: pointer;
                text-decoration: underline; }
            .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf .search-results-pdf-inner-container {
              margin: 17px 25px;
              line-height: 18px; }
              .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf .search-results-pdf-inner-container .search-results-pdf-link-text {
                color: #3899ec;
                display: inline-block; }
                .new-link-panel > .content .tabs > .tab-content > .tab.document .search-results-pdf .search-results-pdf-inner-container .search-results-pdf-link-text:hover {
                  text-decoration: underline;
                  cursor: pointer; }
        .new-link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .dropdown.select,
        .new-link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .boolean-control.control-switch {
          border-bottom: 1px solid #dfe5eb;
          width: 100%; }
          .new-link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .dropdown.select > svg,
          .new-link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .boolean-control.control-switch > svg {
            float: none;
            margin-left: 20px;
            margin-top: 7px; }
        .new-link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .control-text-input {
          padding-bottom: 0; }
      .new-link-panel > .content .tabs > .tab-header {
        padding: 20px 24px;
        border-right: 1px solid #dfe5eb;
        box-sizing: border-box;
        min-width: 210px;
        width: 210px; }
        .new-link-panel > .content .tabs > .tab-header > .tab-label {
          margin-bottom: 14px; }
          .new-link-panel > .content .tabs > .tab-header > .tab-label:last-child {
            margin-bottom: 0; }
    .new-link-panel > .content footer {
      padding: 18px; }
      .new-link-panel > .content footer button.control-button {
        height: 30px; }
      .new-link-panel > .content footer .link-panel-done-button-tooltip {
        float: right; }
  .new-link-panel .link-page-description {
    padding: 18px 24px; }
  .new-link-panel .link-panel-options-container .selected-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .new-link-panel .link-panel-options-container .dropdown-options {
    max-width: 300px;
    max-height: 300px;
    box-shadow: 0 0 13px 0 rgba(22, 45, 61, 0.2); }
    .new-link-panel .link-panel-options-container .dropdown-options .dropdown-options-inner .control-section-divider {
      padding: 0 24px;
      height: 36px; }
      .new-link-panel .link-panel-options-container .dropdown-options .dropdown-options-inner .control-section-divider.upper:not(:first-child) {
        margin-top: 12px; }
      .new-link-panel .link-panel-options-container .dropdown-options .dropdown-options-inner .control-section-divider .section-divider-content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        line-height: 36px;
        text-align: inherit; }
    .new-link-panel .link-panel-options-container .dropdown-options .dropdown-options-inner .control-dropdown-option {
      height: 30px; }
      .new-link-panel .link-panel-options-container .dropdown-options .dropdown-options-inner .control-dropdown-option .control-dropdown-option-content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        line-height: 30px; }

.footer-link-container {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .footer-link-container .control-text-button .prefix {
    padding: 0; }

.language-support-panel {
  position: fixed;
  width: 360px;
  top: calc(50vh - (580px / 2));
  left: calc(50vw - (360px / 2)); }

.language-support-panel-content-wrapper .label {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  padding: 20px 24px 10px; }

.language-support-panel-content-wrapper .language-option {
  border-bottom: 1px solid #dfe5eb;
  height: 60px;
  margin: 0 24px;
  display: flex;
  align-items: center; }
  .language-support-panel-content-wrapper .language-option .example-img {
    margin-left: auto; }

.language-support-panel-content-wrapper footer {
  text-align: center;
  padding-bottom: 20px; }
  .language-support-panel-content-wrapper footer .copy-right-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 20px 24px;
    color: #868aa5;
    line-height: 21px;
    font-weight: 300; }
    .language-support-panel-content-wrapper footer .copy-right-text a {
      color: #116dff;
      text-decoration: none;
      margin-left: 5px; }
      .language-support-panel-content-wrapper footer .copy-right-text a:hover {
        cursor: pointer;
        text-decoration: underline; }

.api-definition-event .input-multiline.input-container.widget-event-description > .input {
  height: 156px; }

.add-color-content {
  width: 210px;
  position: relative;
  background-color: #fff;
  border-radius: 4px; }
  .add-color-content .colorSpace {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .add-color-content .color-indicator {
    text-align: center;
    margin-top: 10px;
    font-size: 0; }
    .add-color-content .color-indicator .color-sample {
      display: inline-block;
      width: 67px;
      height: 12px;
      border: 1px solid #dfe5eb;
      box-sizing: border-box; }
    .add-color-content .color-indicator .initial-color {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      border-right: none; }
    .add-color-content .color-indicator .current-color {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none; }
  .add-color-content .custom-colors-separator {
    border-top: 1px solid #f7f8f8;
    margin-top: 16px; }
  .add-color-content .custom-colors-section-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 20px 14px 3px 14px;
    color: #3b4057; }
  .add-color-content .custom-colors-section {
    height: 100px;
    padding: 0 9px; }
  .add-color-content .footer {
    text-align: center;
    padding: 12px 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around; }
    .add-color-content .footer button {
      height: 24px;
      line-height: 24px;
      width: 72px;
      padding: 0; }

.site-custom-colors {
  width: 193px;
  padding: 4px 0 0 0;
  font-size: 0; }
  .site-custom-colors .add-color-button {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #116dff; }
    .site-custom-colors .add-color-button:hover {
      box-shadow: 0 0 0 3px rgba(17, 109, 255, 0.3); }
  .site-custom-colors .color-option {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px; }
    .site-custom-colors .color-option .color-displayer {
      border-radius: 50%;
      width: 22px;
      height: 22px; }
      .site-custom-colors .color-option .color-displayer.white-option {
        box-sizing: border-box;
        border: 1px solid #ccc; }
    .site-custom-colors .color-option input {
      display: none; }
      .site-custom-colors .color-option input:checked + .color-displayer {
        border-radius: 50%;
        width: 22px;
        height: 22px;
        box-shadow: 0 0 1px 2px #fff, 0 0 0 3px #868aa5; }
    .site-custom-colors .color-option:hover {
      cursor: pointer; }
      .site-custom-colors .color-option:hover input:not(:checked) + .color-displayer {
        border-radius: 50%;
        width: 26px;
        height: 26px; }
  .site-custom-colors .empty-color-option {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: -1px;
    width: 21px;
    height: 21px;
    margin-right: 7px;
    margin-bottom: 8px;
    border-radius: 50%;
    border: solid 1px #dfe5eb; }

.color-picker-panel.tool-panel-frame {
  overflow: visible;
  background: none !important;
  border: none !important;
  box-shadow: none !important; }

.color-picker-panel-content-wrapper {
  --panel-content-height: 440px;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap; }
  .color-picker-panel-content-wrapper .panel-section {
    padding: 0 14px; }
  .color-picker-panel-content-wrapper .color-picker-header {
    position: relative;
    border-bottom: solid 1px #dfe5eb;
    cursor: move; }
    .color-picker-panel-content-wrapper .color-picker-header .color-picker-header-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      height: 47px;
      line-height: 47px;
      margin-right: 21px; }
    .color-picker-panel-content-wrapper .color-picker-header .btn-back:not(.btn-header-back) {
      text-align: left;
      padding-left: 9px;
      width: auto;
      margin-left: 0; }
      .color-picker-panel-content-wrapper .color-picker-header .btn-back:not(.btn-header-back) svg {
        margin-right: 12px; }
        .color-picker-panel-content-wrapper .color-picker-header .btn-back:not(.btn-header-back) svg > path {
          color: #5c99e9; }
    .color-picker-panel-content-wrapper .color-picker-header .close-panel-button {
      position: absolute;
      top: calc(50% - (21px / 2));
      right: 14px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: #d6e6fe;
      cursor: pointer; }
      .color-picker-panel-content-wrapper .color-picker-header .close-panel-button svg {
        width: 21px;
        height: 21px;
        fill: #116dff; }
      .color-picker-panel-content-wrapper .color-picker-header .close-panel-button:hover {
        background-color: #e7f0ff; }
  .color-picker-panel-content-wrapper .main-content {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    width: 210px;
    border-radius: 4px;
    background-color: #fff;
    white-space: normal; }
  .color-picker-panel-content-wrapper .choose-color-content {
    height: calc( var(--panel-content-height) - 47px - 1px); }
    .color-picker-panel-content-wrapper .choose-color-content .site-colors-section {
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-left {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        flex: 2;
        text-align: left;
        color: #3b4057; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-gap {
        width: 18px; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right {
        flex: 1;
        text-align: right;
        -webkit-user-select: text;
                user-select: text; }
        .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right a {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 14px;
          -webkit-font-smoothing: antialiased;
          color: #116dff;
          cursor: pointer; }
        .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right :hover {
          text-decoration: underline; }
    .color-picker-panel-content-wrapper .choose-color-content .custom-colors-header {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 3px; }
    .color-picker-panel-content-wrapper .choose-color-content .custom-colors-title-section {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057; }
    .color-picker-panel-content-wrapper .choose-color-content .custom-colors-section {
      height: 132px;
      margin: 0 9px; }
    .color-picker-panel-content-wrapper .choose-color-content .footer {
      height: 48px;
      display: flex; }
      .color-picker-panel-content-wrapper .choose-color-content .footer .section-left {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        flex: 1;
        text-align: left;
        color: #116dff; }
        .color-picker-panel-content-wrapper .choose-color-content .footer .section-left a {
          cursor: pointer; }
          .color-picker-panel-content-wrapper .choose-color-content .footer .section-left a:hover {
            text-decoration: underline; }
      .color-picker-panel-content-wrapper .choose-color-content .footer .section-right {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        flex: 1;
        text-align: right;
        color: #3b4057;
        -webkit-user-select: text;
                user-select: text; }
        .color-picker-panel-content-wrapper .choose-color-content .footer .section-right span {
          -webkit-user-select: text;
          user-select: text; }
  .color-picker-panel-content-wrapper .content-change-palette {
    height: calc( var(--panel-content-height) - 47px - 1px); }
    .color-picker-panel-content-wrapper .content-change-palette .change-palette-section {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 45px;
      line-height: 45px;
      color: #3b4057; }
    .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper {
      margin-top: 7px;
      padding-bottom: 11px;
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #dfe5eb; }
      .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box {
        flex: 1 1 auto;
        text-align: center; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box.white-color .root-color-circle {
          box-sizing: border-box;
          border: 1px solid #ccc; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box.white-color .root-color-arrow {
          border-bottom-color: #ccc !important; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-arrow {
          margin-left: 50%;
          transform: translateX(-50%);
          margin-bottom: 5px;
          width: 0;
          height: 0;
          border-bottom: 6px solid;
          border-right: 3.5px solid transparent;
          border-left: 3.5px solid transparent; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-circle {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 50%;
          cursor: pointer; }
          .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-circle svg {
            margin-top: 6px; }
    .color-picker-panel-content-wrapper .content-change-palette .help-text {
      padding: 20px 17px 0; }
      .color-picker-panel-content-wrapper .content-change-palette .help-text a {
        display: block;
        color: #116dff;
        cursor: pointer; }
        .color-picker-panel-content-wrapper .content-change-palette .help-text a:hover {
          text-decoration: underline; }
    .color-picker-panel-content-wrapper .content-change-palette .footer {
      position: absolute;
      bottom: 0;
      width: 100%; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .cancel-button,
      .color-picker-panel-content-wrapper .content-change-palette .footer .confirm-button {
        margin-bottom: 12px;
        width: 72px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        cursor: pointer; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .cancel-button {
        margin-left: 18px; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .confirm-button {
        float: right;
        margin-right: 18px; }
  .color-picker-panel-content-wrapper .secondary-content {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: -18px;
    background-color: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    white-space: normal; }
    .color-picker-panel-content-wrapper .secondary-content.palette-list-section {
      height: var(--panel-content-height);
      text-align: center; }
      .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        height: 53px;
        line-height: 60px;
        padding-left: 19px;
        color: #3b4057; }
      .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-comp-wrapper {
        height: calc(100% - 53px); }
        .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-comp-wrapper .change-palette-palette-list {
          margin-left: 19px; }
  .classic-facelift-skin .color-picker-panel-content-wrapper .main-content {
    overflow: hidden; }

.stylable-editor-panel .panel-header {
  height: 48px;
  min-height: 48px;
  border-bottom: 1px solid #dfe5eb; }
  .stylable-editor-panel .panel-header .panel-header-title {
    line-height: 48px;
    padding-left: 14px; }

.stylable-editor-box-shadow-picker-panel-content-wrapper {
  padding: 0 15px; }

.panel-frame-container.tool-panel-frame-container .tool-panel-frame.stylable-editor-css-panel-wrapper {
  background-color: #f7f8f8; }

.stylable-editor-css-panel-input.input-multiline.input-container .input {
  height: 446px;
  background-color: #f7f8f8;
  font: 12px Menlo, Monaco, 'Courier New', monospace;
  color: #000624;
  border: none;
  border-radius: 0;
  cursor: text; }
  .stylable-editor-css-panel-input.input-multiline.input-container .input:focus {
    box-shadow: none; }
  .stylable-editor-css-panel-input.input-multiline.input-container .input::selection {
    background: #add6ff; }
  .stylable-editor-css-panel-input.input-multiline.input-container .input::-moz-selection {
    background: #add6ff; }

.main-content-change-qab-icon {
  width: 288px;
  height: 546px; }
  .main-content-change-qab-icon .panel-header {
    cursor: move;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper {
    position: relative; }
    .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list {
      padding: 18px;
      height: calc(546px - 108px); }
      .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper {
        padding: 6px; }
        .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .add-thumbnail-symbol-container {
          width: 50px;
          height: 50px; }
        .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper.selected .illustration-container .illustration-inner-container {
          box-shadow: 0 0 0 2px #116dff; }
        .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper:not(.selected):hover .illustration-container .illustration-inner-container {
          box-shadow: 0 0 0 2px #d6e6fe; }
        .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container {
          border-radius: 4px; }
          .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container .symbol-plusBig .circle {
            stroke: #ffffff;
            cursor: pointer; }
          .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > img {
            padding: 5px 13px 6px 13px;
            height: 38px; }
          .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > svg {
            width: 51px;
            height: 51px; }
            .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > svg > path {
              stroke: #868aa5;
              stroke-opacity: 0.6;
              stroke-width: 1px; }
    .main-content-change-qab-icon .qab-icons-wrapper .thumbnails-qab-wrapper .change-uploaded-custom-icon {
      position: absolute;
      top: 13px;
      left: 64px;
      cursor: pointer; }
  .main-content-change-qab-icon .qab-icon-change-color .btn-back-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, #fff 90%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 50px;
    width: calc(100% - 9px);
    top: 0;
    bottom: auto;
    z-index: 1;
    text-align: left; }
    .main-content-change-qab-icon .qab-icon-change-color .btn-back-wrapper .btn-back:not(.btn-header-back) {
      text-align: left;
      padding-left: 9px; }
      .main-content-change-qab-icon .qab-icon-change-color .btn-back-wrapper .btn-back:not(.btn-header-back) svg {
        margin-right: 12px; }
        .main-content-change-qab-icon .qab-icon-change-color .btn-back-wrapper .btn-back:not(.btn-header-back) svg > path {
          color: #5c99e9; }
  .main-content-change-qab-icon .qab-icon-change-color .change-color-content-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px; }
    .main-content-change-qab-icon .qab-icon-change-color .change-color-content-wrapper .color-picker-text {
      line-height: 27px; }
      .main-content-change-qab-icon .qab-icon-change-color .change-color-content-wrapper .color-picker-text span {
        font-size: 14px; }

html:not(.classic-facelift-skin) .main-content-change-qab-icon .panel-header .control-button {
  background-color: #0057e1;
  color: #ffffff;
  line-height: 25px; }

.actionbar-sub-panel {
  height: 545px;
  width: 340px; }
  .actionbar-sub-panel .actionbar-sub-panel-content-wrapper {
    padding: 10px;
    height: 465px; }

.social-link-item {
  margin: 10px 10px 10px 7px;
  height: 36px; }
  .social-link-item .control-text-input {
    width: 87%;
    margin-left: 0; }
  .social-link-item .symbol {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px; }
  .social-link-item:first-child {
    margin-top: 0; }
  .social-link-item:last-child {
    margin-bottom: 0; }

.pages-selector-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #a8caff;
  border-radius: 4px;
  color: #7a7a7a;
  height: 48px;
  line-height: 48px;
  margin-bottom: 5px;
  padding: 0 10px;
  position: relative; }
  .pages-selector-item.item-is-page {
    color: #000624; }
    .pages-selector-item.item-is-page .page-name {
      margin-left: 10px; }
  .pages-selector-item.current-page {
    background-color: #d6e6fe; }
  .pages-selector-item.item-hidden {
    color: #868aa5; }
  .pages-selector-item:not(.item-disabled).item-is-page:hover {
    background-color: #e7f0ff; }
    .pages-selector-item:not(.item-disabled).item-is-page:hover,
    .pages-selector-item:not(.item-disabled).item-is-page:hover > .item-label {
      cursor: pointer; }
  .pages-selector-item.item-second-level {
    margin-left: 25px; }
    .pages-selector-item.item-second-level:before {
      border-top: 1px solid #a8caff;
      content: '';
      height: 0;
      left: -11px;
      position: absolute;
      top: 50%;
      width: 11px; }
    .pages-selector-item.item-second-level:after {
      border-left: 1px solid #a8caff;
      content: '';
      height: 55px;
      left: -11px;
      position: absolute;
      top: -6px;
      width: 11px; }
    .pages-selector-item.item-second-level.item-second-level-last:after {
      height: 30px; }
  .pages-selector-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .pages-selector-item .page-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }
  .pages-selector-item .symbol-homePage {
    height: 10px;
    width: 13px; }
  .pages-selector-item .symbol-hiddenPage {
    height: 15px;
    width: 17px; }

.pages-selector-panel {
  overflow: hidden; }
  .pages-selector-panel > .content {
    background-color: #f7f8f8;
    padding: 20px 0 0; }
    .pages-selector-panel > .content .description-block {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      padding: 0 27px 20px; }
    .pages-selector-panel > .content .select-all-block {
      padding: 0 28px 10px; }
    .pages-selector-panel > .content .pages-selector-scrollbox {
      max-height: calc( 100vh - 293px);
      min-height: 166px;
      padding: 0 18px 40px; }

.image-mask-panel .content {
  position: relative;
  height: calc(100% - 54px); }

.image-mask-panel .image-crop-change-mask-indicator {
  position: absolute;
  top: 13px;
  left: 84px; }

.image-mask-panel .thumbnail-wrapper .illustration-container::after {
  box-shadow: none !important; }

.image-mask-panel .thumbnail-wrapper .custom-mask .illustration-container::after {
  box-shadow: 0 0 0 1px #dfe5eb !important; }

.image-mask-panel .thumbnail-wrapper:hover .illustration-container::after {
  box-shadow: 0 0 0 2px #e7f0ff !important; }

.image-mask-panel .thumbnail-wrapper.selected .illustration-container::after {
  background-color: transparent !important;
  box-shadow: 0 0 0 2px #116dff !important; }

.add-a11y-attribute-panel .a11y-attribute-panel-container {
  width: 240px;
  min-height: 320px; }

.multiselect-panel {
  width: 240px;
  height: 471px;
  position: relative; }
  .multiselect-panel .button-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    height: 66px;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }

.multiselect-panel__wrapper {
  height: 417px; }

.multiselect-panel__content {
  padding: 12px 0 81px; }

.multiselect-panel__folder {
  margin-bottom: 18px; }

.multiselect-panel__folder-name {
  margin: 0 24px 6px;
  height: 30px;
  display: flex;
  align-items: center; }

.multiselect-panel__checkbox {
  margin: 0 24px 12px; }

.multiselect-panel__section-name {
  height: 36px !important;
  margin-bottom: 12px;
  display: flex;
  align-items: center; }
  .multiselect-panel__section-name > .section-divider-content {
    font-size: 14px; }
  .multiselect-panel__section-name--main > .section-divider-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }

.multiselect-panel__add-button {
  display: flex;
  align-items: center;
  height: 100%; }

.multiselect-panel__add-icon {
  display: inline-flex;
  width: 18px;
  height: 18px; }

.concurrent-user-panel.important-confirmation,
.concurrent-user-panel {
  width: 564px; }
  .concurrent-user-panel.important-confirmation .panel-header > .panel-header-title,
  .concurrent-user-panel .panel-header > .panel-header-title {
    font-size: 18px; }
  .concurrent-user-panel.important-confirmation .panel-header.dark > .btn-header.btn-help,
  .concurrent-user-panel .panel-header.dark > .btn-header.btn-help {
    background-color: #c3504d; }
    .concurrent-user-panel.important-confirmation .panel-header.dark > .btn-header.btn-help:hover,
    .concurrent-user-panel .panel-header.dark > .btn-header.btn-help:hover {
      background-color: #942b28; }
  .concurrent-user-panel.important-confirmation .content,
  .concurrent-user-panel .content {
    margin: 0; }
  .concurrent-user-panel.important-confirmation .panel-inner,
  .concurrent-user-panel .panel-inner {
    padding: 5px; }
    .concurrent-user-panel.important-confirmation .panel-inner .concurrent-users-panel-img,
    .concurrent-user-panel .panel-inner .concurrent-users-panel-img {
      display: inline-block;
      position: relative;
      left: 0;
      top: 0;
      padding-right: 24px;
      vertical-align: top; }
    .concurrent-user-panel.important-confirmation .panel-inner .textual-content,
    .concurrent-user-panel .panel-inner .textual-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      padding-bottom: 18px;
      max-width: 318px;
      display: inline-block;
      line-height: 21px; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .sub-title,
      .concurrent-user-panel .panel-inner .textual-content .sub-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        padding-bottom: 24px;
        max-width: 300px;
        word-wrap: break-word; }
        .concurrent-user-panel.important-confirmation .panel-inner .textual-content .sub-title .concurrent-user,
        .concurrent-user-panel .panel-inner .textual-content .sub-title .concurrent-user {
          color: #116dff;
          margin-right: 5px; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .recommendation-list .number,
      .concurrent-user-panel .panel-inner .textual-content .recommendation-list .number {
        display: inline-block;
        position: relative;
        left: 0;
        top: 0;
        width: 15px;
        margin-right: 4px; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .recommendation-list .description,
      .concurrent-user-panel .panel-inner .textual-content .recommendation-list .description {
        display: inline-block;
        max-width: calc(100% - 15px - 4px);
        vertical-align: top; }
  .concurrent-user-panel.important-confirmation footer .btn-danger-primary,
  .concurrent-user-panel.important-confirmation footer .btn-confirm-primary,
  .concurrent-user-panel footer .btn-danger-primary,
  .concurrent-user-panel footer .btn-confirm-primary {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    height: 30px;
    min-width: 72px;
    margin-right: 30px; }

.new-workspace-concurrent-user-panel .textual-content:not(:last-child) {
  margin-bottom: 1em; }

.new-workspace-concurrent-user-panel .concurrent-user {
  color: #116dff;
  margin-right: 5px; }

.autopilot-update-panel > .content {
  padding: 0; }

.autopilot-update-panel .panel-header-centered-text {
  min-height: 100px; }

.autopilot-update-panel .illustration {
  height: 132px;
  width: 250px; }

.delete-page-confirmation .btn-text {
  height: 1em; }

.delete-interaction-message {
  vertical-align: middle;
  width: 564px; }
  .delete-interaction-message .panel-header {
    height: 60px; }
    .delete-interaction-message .panel-header .panel-header-title {
      font-size: 20px; }
  .delete-interaction-message .panel-footer {
    padding-top: 27px; }
  .delete-interaction-message .control-button:not(.btn-header) {
    font-size: 16px;
    line-height: 16px;
    height: 30px;
    padding: 0 18px; }
  .delete-interaction-message .content {
    padding-top: 31px; }
  .delete-interaction-message .icon {
    padding-left: 30px;
    padding-right: 21px;
    display: inline-block; }
  .delete-interaction-message .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #000624;
    font-size: 18px;
    display: inline-block;
    line-height: 22px;
    vertical-align: top;
    max-width: 325px;
    margin: 5px 0 0 3px; }
  .delete-interaction-message footer {
    text-align: right; }
  .delete-interaction-message footer button {
    font-size: 16px;
    line-height: 16px; }

.link-to-button {
  border-radius: 50%;
  background-color: #fff;
  border-color: #d6e1e8;
  display: flex;
  align-items: center;
  justify-content: center; }
  .link-to-button svg path {
    fill: #3b4057; }
  .link-to-button:hover {
    background-color: #d6e6fe; }

.btn-with-spinner {
  position: relative; }
  .btn-with-spinner.success-status {
    animation: greanify 0.4s;
    background-color: #25a55a; }
  .btn-with-spinner.error-status {
    animation: redify 0.4s;
    background-color: #e62214; }
  .btn-with-spinner .btn-content.hidden-content {
    visibility: hidden; }
  .btn-with-spinner .intermidiate-states {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .btn-with-spinner .spinner {
    font-size: 2px;
    width: 10em;
    height: 10em;
    border: 1em solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: relative;
    border-top-color: #ffffff;
    animation: spin 0.5s infinite linear; }
  .btn-with-spinner .appearance-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: appearance 0.4s; }
    .btn-with-spinner .appearance-container .success-icon {
      width: 15px;
      height: auto; }
    .btn-with-spinner .appearance-container .error-container {
      animation: appearance 0.4s;
      font-size: 20px; }

@keyframes redify {
  0% {
    background-color: #116dff; }
  85% {
    background-color: #e62214; } }

@keyframes greanify {
  0% {
    background-color: #116dff; }
  85% {
    background-color: #25a55a; } }

@keyframes appearance {
  0% {
    transform: translateY(100%); }
  85% {
    transform: translateY(0%); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.grid-add-column-panel {
  border-radius: 4px;
  background-color: #fff;
  width: 222px;
  z-index: 1055;
  padding-bottom: 18px; }
  .grid-add-column-panel .header-separator {
    margin: 0 0 6px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f7f8f8; }
  .grid-add-column-panel .header {
    height: 42px;
    min-height: 42px;
    border-radius: 50%; }
  .grid-add-column-panel .panel-header-title {
    line-height: 42px; }
  .grid-add-column-panel .grid-add-column-toolbar-content {
    padding: 12px; }
  .grid-add-column-panel .dropdown {
    padding: 7px 30px 14px 12px; }
  .grid-add-column-panel .input-container {
    padding-bottom: 6px; }
  .grid-add-column-panel .text-input-label,
  .grid-add-column-panel .dropdown-label {
    color: #888888; }
  .grid-add-column-panel .button-row {
    text-align: center; }
  .grid-add-column-panel .button {
    margin-top: 23px; }
  .classic-facelift-skin .grid-add-column-panel {
    overflow: hidden; }

.error-page-panel-frame-z-index {
  z-index: 1719; }

.error-page-panel {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  border-radius: 4px;
  background-color: #fff;
  min-width: 500px;
  min-height: 200px;
  max-width: 800px;
  max-height: 650px; }
  .error-page-panel > .panel-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .error-page-panel > .content {
    overflow-y: auto;
    padding: 25px; }
  .error-page-panel > footer {
    position: relative;
    bottom: 30px;
    text-align: right;
    padding: 40px 30px 0 30px; }
    .error-page-panel > footer .control-button.btn-confirm-secondary, .error-page-panel > footer .control-button.btn-danger-secondary {
      margin-right: 15px; }
    .error-page-panel > footer .control-button.btn-danger-primary {
      margin-right: 30px; }
  .error-page-panel.important-confirmation {
    cursor: default;
    width: 564px; }
    .error-page-panel.important-confirmation > .panel-header {
      background-color: #e62214;
      height: 60px; }
      .error-page-panel.important-confirmation > .panel-header > .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 20px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        height: 60px;
        line-height: 60px; }
      .error-page-panel.important-confirmation > .panel-header > .panel-header.close {
        background-color: rgba(22, 45, 61, 0.2);
        color: #fbd0cd; }
      .error-page-panel.important-confirmation > .panel-header .close {
        background-color: #c3504d; }
        .error-page-panel.important-confirmation > .panel-header .close:hover {
          background-color: #942b28; }
    .error-page-panel.important-confirmation .content {
      margin-right: 15px;
      line-height: 22px; }
      .error-page-panel.important-confirmation .content > p {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        font-weight: 300; }
      .error-page-panel.important-confirmation .content > p:first-child {
        margin-top: 0; }
      .error-page-panel.important-confirmation .content a {
        color: #116dff;
        text-decoration: none; }
    .error-page-panel.important-confirmation .icon {
      height: 180px;
      width: 150px;
      float: left;
      margin-right: 10px; }
  .error-page-panel.concurrent-save-panel {
    width: 576px;
    background-color: #fff; }
    .error-page-panel.concurrent-save-panel .panel-header {
      height: 54px; }
      .error-page-panel.concurrent-save-panel .panel-header .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 20px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        line-height: 54px; }
      .error-page-panel.concurrent-save-panel .panel-header .close {
        top: 14px;
        margin-top: 0; }
    .error-page-panel.concurrent-save-panel .content {
      padding: 0; }
    .error-page-panel.concurrent-save-panel > footer {
      position: static;
      text-align: right;
      margin: 0 30px 30px 0; }
      .error-page-panel.concurrent-save-panel > footer .btn-confirm-primary,
      .error-page-panel.concurrent-save-panel > footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased; }
  .error-page-panel .content-wrapper {
    display: inline-block;
    font-size: 0;
    position: relative;
    margin: 36px 0 30px 0;
    width: 100%; }
  .error-page-panel .error-logo {
    float: left;
    text-align: center;
    width: 186px; }
  .error-page-panel .description-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    max-width: 294px;
    line-height: 22px;
    margin: -1px 50px 0 0;
    color: #000624; }
    .error-page-panel .description-wrapper .description-line {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      padding-left: 0.2em; }
      .error-page-panel .description-wrapper .description-line:first-child, .error-page-panel .description-wrapper .description-line.initialPadding {
        padding-left: initial; }

.mobile-friendly-panel {
  width: 744px; }
  .mobile-friendly-panel .mobile-friendly-panel-content {
    margin: 50px 67px 0 48px; }
    .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block {
      display: flex; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block:first-child {
        margin-bottom: 24px; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block:last-child {
        margin-top: 14px; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-block-icon {
        flex: 0 0 145px;
        text-align: center; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-block-control {
        align-self: center;
        display: flex;
        justify-content: flex-end;
        flex: 0 0 120px; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-block-info {
        font-size: 10px;
        margin: 0 25px; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-block-title {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        color: #3b4057;
        margin-bottom: 6px; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-block-description {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #3b4057; }
      .mobile-friendly-panel .mobile-friendly-panel-content .mobile-friendly-settings .mobile-friendly-settings-block .mobile-friendly-settings-reordering-btn {
        height: 30px; }
  .mobile-friendly-panel .mobile-friendly-panel-footer {
    height: 62px;
    margin-top: 42px; }
    .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content .mobile-friendly-learn-more {
        margin-left: 36px; }
      .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content .mobile-friendly-learn-more-link {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        height: 16px; }
        .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content .mobile-friendly-learn-more-link:hover {
          text-decoration: underline; }
      .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content .mobile-friendly-learn-more-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #3b4057;
        margin-right: 0.3em; }
      .mobile-friendly-panel .mobile-friendly-panel-footer .mobile-friendly-panel-footer-content .mobile-friendly-done-btn {
        margin-right: 29px; }

.new-workspace-mobile-friendly-panel .mobile-friendly-panel-header {
  text-align: center; }

.new-workspace-mobile-friendly-panel .mobile-friendly-panel-content {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.new-workspace-mobile-friendly-panel .mobile-friendly-panel-content-text {
  margin: 0 24px;
  max-width: 332px; }
  .new-workspace-mobile-friendly-panel .mobile-friendly-panel-content-text .control-heading {
    margin-bottom: 3px;
    font-size: 16px; }

.browser-theme-color-settings-panel {
  display: flex; }
  .browser-theme-color-settings-panel .sidebar {
    width: 287px; }
  .browser-theme-color-settings-panel .preview-area {
    width: 416px;
    height: 311px;
    border-right: 1px solid #dfe5eb;
    display: flex;
    justify-content: center;
    overflow: hidden; }
    .browser-theme-color-settings-panel .preview-area .mobile-frame-container {
      width: 320px;
      position: relative;
      height: calc(311px - 72px);
      top: 72px; }
      .browser-theme-color-settings-panel .preview-area .mobile-frame-container .preview-container {
        width: 100%;
        height: 100%;
        overflow: hidden; }
        .browser-theme-color-settings-panel .preview-area .mobile-frame-container .preview-container .color-changeable-area {
          height: 60px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .browser-theme-color-settings-panel .preview-area .mobile-frame-container .preview-container .color-changeable-area .address-area {
            background-color: #eaeaea;
            opacity: 0.5;
            width: 270px;
            height: 24px;
            border-radius: 24px; }

.progress-bar-panel .illustration {
  width: 84px;
  height: 84px; }

.layers-panel .spacer {
  margin: 9px 0;
  border-top: 1px solid #dfe5eb; }

.layers-panel .node {
  font-size: 13px;
  cursor: default; }
  .layers-panel .node .item {
    padding: 3px 18px 4px 23px; }
    .layers-panel .node .item .title > span {
      line-height: 25px; }
    .layers-panel .node .item .item-inner {
      display: flex; }
      .layers-panel .node .item .item-inner .node-info {
        display: flex;
        flex-grow: 1;
        width: 100%;
        align-items: center; }
        .layers-panel .node .item .item-inner .node-info.extra-margin {
          margin-left: 10px; }
    .layers-panel .node .item .toggle-show-children {
      flex-grow: 0;
      align-self: center;
      cursor: pointer;
      width: 20px;
      flex-shrink: 0;
      text-align: center; }
      .layers-panel .node .item .toggle-show-children .open {
        width: 8px;
        height: 6px; }
      .layers-panel .node .item .toggle-show-children .close {
        width: 6px;
        height: 8px; }
      .layers-panel .node .item .toggle-show-children path {
        fill: #3b4057; }
    .layers-panel .node .item .icon {
      width: 20px;
      height: 20px;
      flex-grow: 0;
      padding-right: 6px;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-shrink: 0; }
      .layers-panel .node .item .icon .symbol {
        width: 14px;
        height: 14px;
        margin-bottom: -1px; }
        .layers-panel .node .item .icon .symbol path {
          fill: #3b4057; }
      .layers-panel .node .item .icon .image {
        width: 15px;
        height: 15px;
        border-radius: 2px;
        display: inline-block; }
  .layers-panel .node .item-empty-state {
    width: 100%;
    padding: 12px 24px 18px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    gap: 6px; }
  .layers-panel .node.headline {
    font-size: 14px;
    font-weight: 500;
    color: #3b4057;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    margin: 6px 0; }
    .layers-panel .node.headline > .item .node-info {
      margin-left: 0; }
    .layers-panel .node.headline .item .toggle-show-children .open {
      position: relative;
      top: -3px; }
    .layers-panel .node.headline .item .toggle-show-children .close {
      margin-bottom: 1px; }
  .layers-panel .node.clickable-headline .item {
    color: #3b4057; }
    .layers-panel .node.clickable-headline .item:hover {
      background: #e7f0ff;
      color: #3b4057; }
    .layers-panel .node.clickable-headline .item.selected {
      color: #fff;
      background: #116dff; }
      .layers-panel .node.clickable-headline .item.selected .title > span {
        color: #fff; }
      .layers-panel .node.clickable-headline .item.selected .toggle-show-children path {
        fill: #fff; }
    .layers-panel .node.clickable-headline .item.SOAP:hover {
      background: #fee8dc;
      color: #3b4057; }
    .layers-panel .node.clickable-headline .item.SOAP.selected {
      color: #fff;
      background: #fe620f; }
    .layers-panel .node.clickable-headline .item .title {
      flex-grow: 1;
      cursor: move;
      align-self: center;
      overflow: hidden;
      width: 100%; }
  .layers-panel .node.component .item {
    color: #3b4057;
    font-weight: normal;
    cursor: move; }
    .layers-panel .node.component .item.SOAP:hover {
      background: #fee8dc;
      color: #3b4057; }
    .layers-panel .node.component .item.SOAP.selected {
      color: #fff;
      background: #fe620f; }
      .layers-panel .node.component .item.SOAP.selected .toggle-visibility .symbol-showComp path,
      .layers-panel .node.component .item.SOAP.selected .toggle-visibility .symbol-hiddenPageStatus path,
      .layers-panel .node.component .item.SOAP.selected .toggle-spotlight .symbol-spotlight path,
      .layers-panel .node.component .item.SOAP.selected .icon path,
      .layers-panel .node.component .item.SOAP.selected .toggle-show-children path {
        fill: #fff !important; }
      .layers-panel .node.component .item.SOAP.selected .title > span {
        color: #fff; }
    .layers-panel .node.component .item:hover {
      background: #e7f0ff;
      color: #3b4057; }
      .layers-panel .node.component .item:hover.spotlight .toggle-spotlight {
        margin-right: 10px; }
      .layers-panel .node.component .item:hover .toggle-visibility,
      .layers-panel .node.component .item:hover .toggle-spotlight {
        display: block; }
        .layers-panel .node.component .item:hover .toggle-visibility .symbol-showComp:hover path,
        .layers-panel .node.component .item:hover .toggle-visibility .symbol-hiddenPageStatus:hover path,
        .layers-panel .node.component .item:hover .toggle-visibility .symbol-spotlight:hover path,
        .layers-panel .node.component .item:hover .toggle-spotlight .symbol-showComp:hover path,
        .layers-panel .node.component .item:hover .toggle-spotlight .symbol-hiddenPageStatus:hover path,
        .layers-panel .node.component .item:hover .toggle-spotlight .symbol-spotlight:hover path {
          fill: #116dff; }
    .layers-panel .node.component .item.hidden {
      color: #868aa5; }
      .layers-panel .node.component .item.hidden .toggle-visibility {
        display: block; }
      .layers-panel .node.component .item.hidden .toggle-spotlight {
        margin-right: 10px; }
      .layers-panel .node.component .item.hidden.spotlight .toggle-spotlight {
        margin-right: 10px; }
    .layers-panel .node.component .item.spotlight .toggle-spotlight {
      display: block;
      margin-right: 25px; }
    .layers-panel .node.component .item.selected {
      color: #fff;
      background: #116dff; }
      .layers-panel .node.component .item.selected .toggle-visibility .symbol-showComp path,
      .layers-panel .node.component .item.selected .toggle-visibility .symbol-hiddenPageStatus path,
      .layers-panel .node.component .item.selected .toggle-spotlight .symbol-spotlight path,
      .layers-panel .node.component .item.selected .icon path,
      .layers-panel .node.component .item.selected .toggle-show-children path {
        fill: #fff !important; }
      .layers-panel .node.component .item.selected .title > span {
        color: #fff; }
    .layers-panel .node.component .item .toggle-show-children .open {
      position: relative;
      top: -1px; }
    .layers-panel .node.component .item .toggle-show-children .close {
      margin-bottom: auto; }
    .layers-panel .node.component .item .title {
      flex-grow: 1;
      cursor: move;
      align-self: center;
      overflow: hidden;
      width: 100%; }
      .layers-panel .node.component .item .title .with-ellipsis {
        max-width: 150px; }
    .layers-panel .node.component .item .toggle-visibility,
    .layers-panel .node.component .item .toggle-spotlight {
      display: none;
      flex-grow: 0;
      margin-bottom: -7px;
      align-self: center;
      cursor: pointer; }
      .layers-panel .node.component .item .toggle-visibility .symbol-spotlight,
      .layers-panel .node.component .item .toggle-visibility .symbol-showComp,
      .layers-panel .node.component .item .toggle-visibility .symbol-hiddenPageStatus,
      .layers-panel .node.component .item .toggle-spotlight .symbol-spotlight,
      .layers-panel .node.component .item .toggle-spotlight .symbol-showComp,
      .layers-panel .node.component .item .toggle-spotlight .symbol-hiddenPageStatus {
        width: 15px;
        height: 15px; }
        .layers-panel .node.component .item .toggle-visibility .symbol-spotlight path,
        .layers-panel .node.component .item .toggle-visibility .symbol-showComp path,
        .layers-panel .node.component .item .toggle-visibility .symbol-hiddenPageStatus path,
        .layers-panel .node.component .item .toggle-spotlight .symbol-spotlight path,
        .layers-panel .node.component .item .toggle-spotlight .symbol-showComp path,
        .layers-panel .node.component .item .toggle-spotlight .symbol-hiddenPageStatus path {
          fill: #1d2d3c; }
    .layers-panel .node.component .item .toggle-spotlight {
      margin-right: 10px;
      margin-bottom: -5px; }
  .layers-panel .node .drag-container {
    opacity: 0.8;
    z-index: 99; }
    .layers-panel .node .drag-container .item {
      background: #e7f0ff !important;
      color: #3b4057 !important;
      box-shadow: 0 2px 10px 0 rgba(43, 86, 114, 0.32); }
  .layers-panel .node .drag-placeholder {
    background: #116dff;
    height: 30px !important;
    width: 84% !important;
    margin: 0 24px; }
  .layers-panel .node.SOAP .drag-container {
    opacity: 0.8;
    z-index: 99; }
    .layers-panel .node.SOAP .drag-container .item {
      background: #eba554 !important;
      color: #3b4057 !important;
      box-shadow: 0 2px 10px 0 rgba(43, 86, 114, 0.32); }
  .layers-panel .node.SOAP .drag-placeholder {
    background: #fe620f;
    height: 30px !important;
    width: 84% !important;
    margin: 0 24px; }
  .layers-panel .node .warning-icon-container {
    display: flex; }
    .layers-panel .node .warning-icon-container .warning-icon {
      cursor: pointer;
      fill: #ffc233;
      margin-left: 10px;
      background: #fff;
      border-radius: 100%;
      width: 18px;
      min-width: 18px;
      height: 18px; }
      .layers-panel .node .warning-icon-container .warning-icon.warning-icon-red {
        fill: #e62214; }

.with-wbu-icons .node .item {
  padding: 4px 18px; }
  .with-wbu-icons .node .item .toggle-show-children .open,
  .with-wbu-icons .node .item .toggle-show-children .close {
    width: 8px;
    height: 8px;
    top: 0; }
  .with-wbu-icons .node .item .icon {
    width: 24px;
    height: 24px;
    padding-right: 2px; }
  .with-wbu-icons .node .item .item-inner .node-info.extra-margin {
    margin-left: 20px; }

.with-wbu-icons .node .header {
  padding: 6px 18px; }

.with-wbu-icons .node.headline .item .toggle-show-children .open {
  top: 0; }

.with-wbu-icons .node .warning-icon-container .warning-icon {
  margin-left: 2px; }

.warning-icon-tooltip-link {
  cursor: pointer;
  color: #116dff;
  text-decoration: underline; }
  .warning-icon-tooltip-link:hover {
    color: #5999ff; }

.sort-list .item-inner {
  padding-left: 12px; }

.sort-list .sort-list .item-inner {
  padding-left: 24px; }

.sort-list .sort-list .sort-list .item-inner {
  padding-left: 36px; }

.sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 48px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 60px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 72px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 84px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 96px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 108px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 120px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 132px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 144px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 156px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 168px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 180px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 192px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 204px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 216px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 228px; }

.sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .sort-list .item-inner {
  padding-left: 240px; }

.business-type-panel .control-base-modal {
  width: 600px; }
  .business-type-panel .control-base-modal .control-message-modal-content-wrapper {
    overflow: inherit; }

.package-panel .tasks-section {
  width: 564px;
  height: 132px; }

.app-settings-panel {
  display: flex;
  height: 528px; }
  .app-settings-panel .content {
    height: 528px;
    width: 288px; }

.create-namespace-panel {
  width: 564px; }
  .create-namespace-panel .hidden {
    visibility: hidden; }
  .create-namespace-panel .namespace-input {
    padding-bottom: 0; }
  .create-namespace-panel .primary .composite-preloader {
    position: absolute; }
    .create-namespace-panel .primary .composite-preloader .button-on-load {
      width: 9px;
      height: 18px;
      margin-left: -9px; }
  .create-namespace-panel .add-namespace-panel-content {
    height: 148px;
    padding: 6px 0 0 0; }
  .create-namespace-panel .add-namespace-with-suggestion-panel-content {
    height: 184px;
    padding: 6px 0 0 0; }
  .create-namespace-panel .unknown-error-panel-content,
  .create-namespace-panel .save-namespace-panel-content {
    height: 140px; }
  .create-namespace-panel .loading-panel-content {
    padding-top: 0; }

.widget-design-panel-frame {
  height: 100%;
  background-color: #f7fbff; }

.app-internals-panel {
  position: relative; }
  .app-internals-panel .hidden {
    display: none; }

.variations-empty-state {
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 460px;
  background-color: #f7f8f8;
  padding-top: 48px; }

.dev-center-settings-panel {
  display: flex;
  height: 528px; }
  .dev-center-settings-panel .content {
    height: 528px;
    width: 444px; }

.api-property-definition-panel-container {
  height: 630px;
  margin-top: 0; }
  .api-property-definition-panel-container .api-property-definition-panel {
    height: calc(100% - 79px); }
    .api-property-definition-panel-container .api-property-definition-panel .api-definition-property-schema-panel-content {
      padding-bottom: 78px;
      height: 464px; }
      .api-property-definition-panel-container .api-property-definition-panel .api-definition-property-schema-panel-content .control-dropdown-base {
        max-width: none; }
    .api-property-definition-panel-container .api-property-definition-panel .api-definition-property-bottom-button {
      height: 78px; }
  .api-property-definition-panel-container .api-property-definition-panel.with-back-button {
    height: calc(100% - 133px); }

.custom-type-property-panel-container {
  margin-top: 0; }
  .custom-type-property-panel-container .content {
    height: calc(100% - 54px); }
  .custom-type-property-panel-container .custom-type-property-panel {
    height: calc(100% - 79px); }

.empty-state {
  flex: 1; }
  .empty-state .illustration-vertical-container {
    padding-top: 52px; }
  .empty-state .add-property-btn-text-container {
    padding-top: 0;
    padding-bottom: 36px; }
  .empty-state .add-property-btn-text {
    font-size: 14px; }
  .empty-state .illustration-rich-text {
    padding-bottom: 5px;
    padding-top: 5px;
    width: 380px; }

.responsive-skin .app-custom-types-panel-container .list-view {
  height: calc(100% - 87px); }
  .responsive-skin .app-custom-types-panel-container .list-view .properties-container {
    height: 355px; }

.app-custom-types-panel-container {
  height: 630px;
  margin-top: 0; }
  .app-custom-types-panel-container .app-custom-types-panel {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .app-custom-types-panel-container .list-view {
    height: calc(100% - 79px);
    display: flex;
    flex: 1; }
    .app-custom-types-panel-container .list-view .list-container {
      display: flex;
      position: relative; }
      .app-custom-types-panel-container .list-view .list-container .list-border {
        padding-bottom: 42px;
        background-color: #f7fbff;
        border-right: 1px solid #cbe3f8;
        width: 186px; }
        .app-custom-types-panel-container .list-view .list-container .list-border .drag-list {
          margin: 8px 6px 0 6px;
          border-top: none; }
          .app-custom-types-panel-container .list-view .list-container .list-border .drag-list.sort-by-drag-list {
            width: 176px; }
          .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row {
            width: 176px;
            border-radius: 100px;
            border-bottom: none;
            margin-bottom: 2px; }
            .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row:not(:hover):not(.selected) {
              background-color: rgba(0, 0, 0, 0); }
          .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base {
            width: 176px;
            min-height: 32px; }
            .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .sbdl-text-edit {
              width: 176px; }
              .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .sbdl-text-edit .input {
                width: 86px; }
            .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .view-mode-content {
              height: 32px; }
              .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .view-mode-content .text-container {
                width: calc(100% - 35px); }
                .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .view-mode-content .text-container .text-inner-content {
                  min-width: 93px; }
              .app-custom-types-panel-container .list-view .list-container .list-border .drag-list .sbdl-item-row-base .view-mode-content .item-row-action-container {
                width: 30px; }
    .app-custom-types-panel-container .list-view .properties-container {
      display: flex;
      position: relative;
      height: 371px; }
      .app-custom-types-panel-container .list-view .properties-container .properties-border {
        padding-bottom: 42px;
        border-right: 1px solid #cbe3f8; }
        .app-custom-types-panel-container .list-view .properties-container .properties-border .drag-list.sort-by-drag-list {
          width: 288px; }
        .app-custom-types-panel-container .list-view .properties-container .properties-border .drag-list .sbdl-item-row {
          width: 288px; }
        .app-custom-types-panel-container .list-view .properties-container .properties-border .drag-list .sbdl-item-row-base {
          width: 288px; }
    .app-custom-types-panel-container .list-view .sbdl-item-row.empty .content-before {
      color: #b6c1cd; }
    .app-custom-types-panel-container .list-view .add-item-btn {
      position: absolute;
      bottom: 5px;
      left: 3px;
      padding: 0; }

.applipcation-builder-left-tree-header .application-name-left-tree-title {
  padding: 12px; }
  .applipcation-builder-left-tree-header .application-name-left-tree-title.temporary {
    color: #bcbcbc; }

.widgets-view {
  padding: 0 5px;
  margin-top: 8px; }
  .widgets-view .add-widget-link {
    margin-left: 26px;
    font-size: 14px; }
  .widgets-view svg.add-widget * {
    fill: #116dff; }
  .widgets-view .wix-code-tree-view.widget-item > .tree-entry.selected {
    background-color: transparent;
    border-color: transparent;
    font-weight: bold; }
  .widgets-view .wix-code-tree-view.widget-item .menu-item .tree-entry {
    margin-left: 12px; }
  .widgets-view .wix-code-tree-view .tree-entry .title-tooltip .title {
    color: #3b4057; }

.add-widget-root svg.symbol {
  width: 14px;
  height: 14px; }
  .add-widget-root svg.symbol * {
    fill: #3b4057; }

.widget-actions-divider.divider-short {
  margin: 5px 18px 6px 18px; }

.widget-design-section {
  height: 100%; }
  .widget-design-section .add-variation {
    margin-left: 56px;
    font-size: 14px; }
  .widget-design-section .design-content {
    height: calc(100% - 42px); }
    .widget-design-section .design-content .design-content-inner {
      padding: 12px; }
  .widget-design-section .wix-code-tree-view .rename-input {
    background-color: #ffffff;
    border-radius: 4px;
    margin-left: 1px; }
  .widget-design-section .wix-code-tree-view .tree-entry.rename-mode {
    padding-left: 0; }
  .widget-design-section .wix-code-tree-view .tree-entry.selected {
    background-color: #d3ecff;
    border-color: #c5e3f7; }
  .widget-design-section .wix-code-tree-view .tree-entry.show-highlight:hover {
    border-color: #d6eeff; }
  .widget-design-section .wix-code-tree-view .tree-entry .title-tooltip .title {
    color: #3b4057; }
  .widget-design-section .variation-tree-view {
    margin-top: 6px; }

.outline-section {
  height: 100%; }
  .outline-section .header-bottom-divider {
    margin-bottom: 12px; }
  .outline-section .outline-content {
    height: calc(100% - 55px); }
    .outline-section .outline-content .wix-code-tree-view.outline-tree {
      padding-right: 8px; }
    .outline-section .outline-content .wix-code-tree-view .tree-entry {
      height: 30px; }
      .outline-section .outline-content .wix-code-tree-view .tree-entry .title-tooltip .title {
        color: #3b4057; }
        .outline-section .outline-content .wix-code-tree-view .tree-entry .title-tooltip .title.removed-component {
          color: #bcbcbc; }
      .outline-section .outline-content .wix-code-tree-view .tree-entry .tree-symbol {
        margin: 0 6px 0 0; }
        .outline-section .outline-content .wix-code-tree-view .tree-entry .tree-symbol .removed-component * {
          fill: #bcbcbc; }
        .outline-section .outline-content .wix-code-tree-view .tree-entry .tree-symbol svg {
          width: 14px;
          height: 14px; }
    .outline-section .outline-content .wix-code-tree-view .sub-tree {
      margin-left: 0;
      padding-left: 21px; }
      .outline-section .outline-content .wix-code-tree-view .sub-tree .sub-tree-entry {
        margin: 0; }

.name-input-root {
  position: relative; }
  .name-input-root .name-input-loader {
    position: absolute;
    bottom: 23px;
    right: 13px;
    display: flex;
    align-items: center;
    width: 18px; }

.secondary-left-panel-header {
  height: 42px;
  display: flex;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center; }
  .secondary-left-panel-header .secondary-left-panel-header-title {
    width: calc(100% - 20px); }
  .secondary-left-panel-header .help-button {
    background-color: transparent;
    min-width: 20px;
    width: 20px;
    padding: 0; }
    .secondary-left-panel-header .help-button svg * {
      fill: #2d3034; }
    .secondary-left-panel-header .help-button:hover svg * {
      fill: #116dff; }

.app-link-section {
  height: 100%; }
  .app-link-section .pre-loader-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-top: -40px; }

.empty-state-section {
  height: 100%; }
  .empty-state-section .create-action-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .empty-state-section .create-action-content .main-button {
      margin-top: 6px;
      min-width: 192px; }
    .empty-state-section .create-action-content .action-required-content {
      text-align: center;
      width: 180px;
      margin-top: 8px; }

.post-creation-section {
  height: 100%; }
  .post-creation-section .read-only-input {
    margin: 0 6px; }
  .post-creation-section .title-comp {
    padding-bottom: 6px; }
    .post-creation-section .title-comp .title-content {
      color: #868aa5; }
  .post-creation-section .actions {
    margin: 12px 24px;
    display: flex; }
    .post-creation-section .actions .copy-button .hidden {
      visibility: hidden; }
    .post-creation-section .actions .copy-button.on-copy-state:hover {
      background-color: #116dff; }
    .post-creation-section .actions .copy-button .copy-icon {
      margin-right: 5px; }
    .post-creation-section .actions .copy-button .copied-icon {
      position: absolute; }
    .post-creation-section .actions .round-btn {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0;
      color: #116dff;
      margin-left: 12px;
      background-color: #e7f0ff; }
      .post-creation-section .actions .round-btn * {
        fill: #116dff; }
      .post-creation-section .actions .round-btn:hover {
        background-color: #d6e6fe; }

.robob-section {
  height: 100%; }
  .robob-section .error-section div {
    color: #f30707; }
  .robob-section .read-only-input {
    margin: 0 6px; }
  .robob-section .title-comp {
    padding-bottom: 6px; }
    .robob-section .title-comp .title-content {
      color: #868aa5; }
  .robob-section .actions {
    margin: 12px 24px;
    display: flex; }
    .robob-section .actions .copy-button .hidden {
      visibility: hidden; }
    .robob-section .actions .copy-button.on-copy-state:hover {
      background-color: #116dff; }
    .robob-section .actions .copy-button .copy-icon {
      margin-right: 5px; }
    .robob-section .actions .copy-button .copied-icon {
      position: absolute; }
    .robob-section .actions .round-btn {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0;
      color: #116dff;
      margin-left: 12px;
      background-color: #e7f0ff; }
      .robob-section .actions .round-btn * {
        fill: #116dff; }
      .robob-section .actions .round-btn:hover {
        background-color: #d6e6fe; }

.internal-widget-data-section {
  height: 100%; }
  .internal-widget-data-section pre {
    width: 90%;
    margin: 0 auto;
    white-space: break-spaces;
    -webkit-user-select: text;
            user-select: text; }
  .internal-widget-data-section .variations-label {
    padding: 15px; }

.comp-panel-frame {
  -webkit-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1441;
  width: 288px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  text-align: left;
  background-color: #fff; }
  .comp-panel-frame.first-time-panel > .panel-header {
    height: 160px; }
    .comp-panel-frame.first-time-panel > .panel-header > button {
      position: absolute;
      top: 15px;
      right: 12px;
      background-color: rgba(255, 255, 255, 0.2); }
      .comp-panel-frame.first-time-panel > .panel-header > button:hover {
        background-color: rgba(255, 255, 255, 0.4); }
  .comp-panel-frame .comp-panel-content {
    max-height: 525px;
    background-color: #fff; }
  .comp-panel-frame .btn-back-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 50px;
    width: calc(100% - 9px);
    top: 0;
    bottom: auto;
    z-index: 1;
    text-align: left; }
  .comp-panel-frame .btn-back:not(.btn-header-back) {
    text-align: left;
    padding-left: 9px; }
    .comp-panel-frame .btn-back:not(.btn-header-back) svg {
      margin-right: 12px; }
      .comp-panel-frame .btn-back:not(.btn-header-back) svg > path {
        color: #5c99e9; }
  .comp-panel-frame .composite-checkboxes-labeled .control-boolean.control-checkbox,
  .comp-panel-frame .composite-checkboxes-labeled .control-label {
    width: 100%; }
  .comp-panel-frame.widePanel {
    width: 560px; }

.background-sub-panel,
.animation-panel,
.settings-panel {
  padding: 0;
  margin: 0; }
  .background-sub-panel .dropdown,
  .animation-panel .dropdown,
  .settings-panel .dropdown {
    padding: 19px 12px 17px 12px;
    margin: 0 11px; }
  .background-sub-panel > .control-switch,
  .background-sub-panel :not(.composite-toggle-switch) > .control-switch,
  .animation-panel > .control-switch,
  .animation-panel :not(.composite-toggle-switch) > .control-switch,
  .settings-panel > .control-switch,
  .settings-panel :not(.composite-toggle-switch) > .control-switch {
    margin: 24px; }
    .background-sub-panel > .control-switch .label-switch,
    .background-sub-panel :not(.composite-toggle-switch) > .control-switch .label-switch,
    .animation-panel > .control-switch .label-switch,
    .animation-panel :not(.composite-toggle-switch) > .control-switch .label-switch,
    .settings-panel > .control-switch .label-switch,
    .settings-panel :not(.composite-toggle-switch) > .control-switch .label-switch {
      width: calc(100% - 57px); }
  .background-sub-panel .control-buttons-group,
  .animation-panel .control-buttons-group,
  .settings-panel .control-buttons-group {
    padding: 19px 24px 26px 24px; }
  .background-sub-panel .control-button-in-panel-content,
  .animation-panel .control-button-in-panel-content,
  .settings-panel .control-button-in-panel-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 48px); }
  .background-sub-panel .selective-text-input .control-text-input,
  .animation-panel .selective-text-input .control-text-input,
  .settings-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .background-sub-panel .control-alignment,
  .animation-panel .control-alignment,
  .settings-panel .control-alignment {
    padding: 19px 24px 24px 24px; }
    .background-sub-panel .control-alignment .label,
    .animation-panel .control-alignment .label,
    .settings-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .background-sub-panel .first-time-info-box,
  .animation-panel .first-time-info-box,
  .settings-panel .first-time-info-box {
    margin-bottom: 12px; }
  .background-sub-panel .control-section-divider,
  .animation-panel .control-section-divider,
  .settings-panel .control-section-divider {
    padding: 0 42px; }
  .background-sub-panel .composite-banner-premium .control-section-divider,
  .animation-panel .composite-banner-premium .control-section-divider,
  .settings-panel .composite-banner-premium .control-section-divider {
    padding-top: 6px;
    padding-bottom: 6px; }
  .background-sub-panel .rich-text-paragraph,
  .animation-panel .rich-text-paragraph,
  .settings-panel .rich-text-paragraph {
    padding: 18px 24px; }
  .background-sub-panel .rich-text-paragraph-content,
  .animation-panel .rich-text-paragraph-content,
  .settings-panel .rich-text-paragraph-content {
    overflow: hidden;
    text-overflow: ellipsis; }
  .background-sub-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close) ,
  .animation-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close) ,
  .settings-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close) {
    margin: 12px 24px; }
  .background-sub-panel .composite-secondary-media-labeled .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close),
  .animation-panel .composite-secondary-media-labeled .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close),
  .settings-panel .composite-secondary-media-labeled .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(
  .btn-help):not(.btn-close) {
    margin: 0; }
  .background-sub-panel .thumbnails-with-icons,
  .animation-panel .thumbnails-with-icons,
  .settings-panel .thumbnails-with-icons {
    margin: 24px; }
  .background-sub-panel .media-button,
  .animation-panel .media-button,
  .settings-panel .media-button {
    margin: 24px; }
    .background-sub-panel .media-button .control-button,
    .animation-panel .media-button .control-button,
    .settings-panel .media-button .control-button {
      margin: 0 auto;
      max-width: calc(100% - 24px); }
  .background-sub-panel .control-button2-wrapper,
  .animation-panel .control-button2-wrapper,
  .settings-panel .control-button2-wrapper {
    padding: 24px 0;
    text-align: center; }
    .background-sub-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing,
    .animation-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing,
    .settings-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing {
      padding-top: 0; }
    .background-sub-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing,
    .animation-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing,
    .settings-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing {
      padding-bottom: 0; }
  .background-sub-panel > .control-button,
  .animation-panel > .control-button,
  .settings-panel > .control-button {
    margin: 24px auto;
    max-width: calc(100% - 48px);
    display: block; }
  .background-sub-panel .inner-control-button,
  .animation-panel .inner-control-button,
  .settings-panel .inner-control-button {
    margin: 24px auto;
    max-width: calc(100% - 48px);
    display: block; }
    .background-sub-panel .inner-control-button.no-margin-top,
    .animation-panel .inner-control-button.no-margin-top,
    .settings-panel .inner-control-button.no-margin-top {
      margin-top: 0; }
  .background-sub-panel .control-text-input,
  .animation-panel .control-text-input,
  .settings-panel .control-text-input {
    margin: 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .control-text-input-with-button,
  .animation-panel .control-text-input-with-button,
  .settings-panel .control-text-input-with-button {
    margin: 0 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .input-link-to,
  .animation-panel .input-link-to,
  .settings-panel .input-link-to {
    padding: 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .c22,
  .animation-panel .c22,
  .settings-panel .c22 {
    margin: 0 23px; }
  .background-sub-panel .button-row,
  .animation-panel .button-row,
  .settings-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .background-sub-panel .button-row .dropdown,
    .animation-panel .button-row .dropdown,
    .settings-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .background-sub-panel .input-slider,
  .animation-panel .input-slider,
  .settings-panel .input-slider {
    margin: 17px 10px 7px 24px; }
  .background-sub-panel .control-line-height > .slider-wrapper > .input-slider,
  .animation-panel .control-line-height > .slider-wrapper > .input-slider,
  .settings-panel .control-line-height > .slider-wrapper > .input-slider {
    margin-top: 0; }
  .background-sub-panel .writable-drop-down,
  .animation-panel .writable-drop-down,
  .settings-panel .writable-drop-down {
    padding: 17px 12px;
    margin-right: 11px; }
  .background-sub-panel .image-control-action-link,
  .animation-panel .image-control-action-link,
  .settings-panel .image-control-action-link {
    min-height: 150px; }
  .background-sub-panel .color-picker-input-with-opacity,
  .animation-panel .color-picker-input-with-opacity,
  .settings-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .background-sub-panel .color-picker-input-with-opacity .input-slider,
    .animation-panel .color-picker-input-with-opacity .input-slider,
    .settings-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 35px 10px 0; }
  .background-sub-panel .text-block,
  .animation-panel .text-block,
  .settings-panel .text-block {
    margin: 24px;
    display: block;
    color: #868aa5;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.first-time-panel {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }
  .first-time-panel .content-wrapper {
    padding: 22px; }
    .first-time-panel .content-wrapper .content-title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      padding-bottom: 18px; }
    .first-time-panel .content-wrapper .content-text {
      margin: 0 0 18px 0; }
      .first-time-panel .content-wrapper .content-text > .space-at-end::after {
        content: '\0000a0'; }
      .first-time-panel .content-wrapper .content-text > .link-action {
        display: inline-block;
        color: #116dff;
        cursor: pointer; }
        .first-time-panel .content-wrapper .content-text > .link-action:hover {
          color: #80b1ff;
          text-decoration: underline; }

.text-settings-panel {
  position: relative;
  max-height: 1000px !important; }
  .text-settings-panel .selected .symbol-textAlignLeft,
  .text-settings-panel .selected .symbol-textAlignCenter,
  .text-settings-panel .selected .symbol-textAlignRight,
  .text-settings-panel .selected .symbol-textAlignBlock {
    fill: #3b4057; }
  .text-settings-panel .custom-scroll .inner-container {
    max-height: 400px;
    height: calc(var(--comp-Panel-Frame-Height) * 1px); }
  .text-settings-panel .text-theme-settings-section + .custom-scroll .inner-container {
    height: calc( (var(--comp-Panel-Frame-Height) - 85) * 1px); }

.more-styling-options {
  position: relative; }
  .more-styling-options .fonts-added-indicator {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-align: right;
    font-weight: 300;
    position: absolute;
    top: 17px;
    right: -20px;
    opacity: 0;
    visibility: hidden; }
    .more-styling-options .fonts-added-indicator.visible {
      visibility: visible;
      right: 24px;
      opacity: 1;
      transition: right 400ms ease-out 300ms, opacity 220ms ease-out 330ms; }
    .more-styling-options .fonts-added-indicator .fonts-added-text {
      line-height: 21px;
      color: #25a55a; }
  .more-styling-options .reset-style-container {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-align: right;
    font-weight: 300; }
    .more-styling-options .reset-style-container > .reset-style-text {
      margin: 17px 25px;
      color: #3899ec;
      line-height: 21px;
      display: inline-block; }
      .more-styling-options .reset-style-container > .reset-style-text:hover {
        cursor: pointer;
        text-decoration: underline; }

.text-styling-section-flex .button-row {
  display: flex;
  justify-content: space-around; }

.text-styling-section-flex > div {
  padding: 0 20px;
  font-size: 12px;
  line-height: 16px; }

.text-styling-section-flex hr {
  padding: 0; }

.text-theme-settings-section .save-theme-text-indicator {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-align: right;
  font-weight: 300;
  position: absolute;
  top: 17px;
  right: -20px;
  opacity: 0;
  visibility: hidden; }
  .text-theme-settings-section .save-theme-text-indicator.visible {
    visibility: visible;
    right: 24px;
    opacity: 1;
    transition: right 400ms ease-out, opacity 220ms ease-out 30ms; }
  .text-theme-settings-section .save-theme-text-indicator > .save-theme-button {
    color: #116dff;
    line-height: 21px; }
    .text-theme-settings-section .save-theme-text-indicator > .save-theme-button:hover {
      cursor: pointer;
      text-decoration: underline; }
  .text-theme-settings-section .save-theme-text-indicator .save-success-indicator {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 21px;
    color: #25a55a; }
  .text-theme-settings-section .save-theme-text-indicator.saving-theme .save-success-indicator {
    visibility: visible;
    opacity: 1;
    transition: opacity 600ms ease-out 100ms; }
  .text-theme-settings-section .save-theme-text-indicator.saving-theme .save-theme-button {
    visibility: hidden; }

.save-theme-tooltip-link {
  cursor: pointer;
  color: #116dff;
  text-decoration: underline; }
  .save-theme-tooltip-link:hover {
    color: #5999ff; }

.themes-dropdown-sticky-footer {
  height: 54px;
  line-height: unset; }
  .themes-dropdown-sticky-footer .themes-dropdown-sticky-footer-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%; }
  .themes-dropdown-sticky-footer .themes-dropdown-sticky-footer-rich-text {
    padding-left: 0; }

.video-layout-panel .thumbnails-with-icons {
  padding: 24px; }

.video-player-empty-state-image {
  text-align: center;
  margin: 26px 0 0; }

.video-player-empty-state-title {
  text-align: center; }
  .video-player-empty-state-title .control-rich-text {
    text-align: center; }

.skype-call-button-settings-panel .control-text-input .info-icon {
  padding-top: 4px; }

.skype-call-button-settings-panel .control-buttons-group .info-icon {
  right: 5px;
  top: 5px; }

.bg-image-strip-settings-panel .image-control-with-buttons {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .bg-image-strip-settings-panel .image-control-with-buttons .button-bar {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }

.bg-image-strip-settings-panel .image-control-action-link {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .bg-image-strip-settings-panel .image-control-action-link span {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }

.background-media-panel {
  background-color: #fff; }
  .background-media-panel .bg-panel-top {
    position: relative; }
  .background-media-panel .bg-panel-preview {
    height: 174px;
    width: 288px; }
  .background-media-panel .bg-panel-preview-media {
    position: absolute;
    width: 100%;
    height: 174px; }
  .background-media-panel .bg-panel-preview-overlay {
    position: absolute;
    width: 100%;
    height: 174px; }
  .background-media-panel .bg-panel-preview-color {
    width: 44px;
    height: 44px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
    .background-media-panel .bg-panel-preview-color .symbol-fold-clipart {
      width: 50px;
      height: 50px;
      position: relative;
      top: 0;
      left: 0; }
  .background-media-panel .bg-panel-preview-buttons {
    width: 100%;
    height: 120px;
    text-align: center;
    position: absolute; }
    .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin-top: 52px;
      padding: 0 4px;
      position: relative; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button.adjust-image-control {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        fill: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button.adjust-image-control:hover {
        border-color: #5999ff;
        fill: #5999ff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button.adjust-image-control:active {
        border: #fff 2px solid;
        fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-play-video,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .symbol-play-video {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5); }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video {
        border-color: #5999ff; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video .st0 {
          fill: #5999ff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video {
        border-color: #5999ff; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st0 {
          fill: transparent; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
          fill: #5999ff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video {
        border-color: #fff !important; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st0 {
          fill: transparent !important; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
          fill: #fff !important; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video {
        border: #fff 2px solid; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video .st0 {
          fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
          fill: #bcbcbc; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
          fill: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st0 {
          fill: transparent; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon {
        position: absolute;
        left: 5px;
        top: 1px; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon .st0,
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon .st0 {
          fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .symbol-settings-icon .st0 {
        fill: #5999ff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .label-settings-icon,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .label-settings-icon {
        border-radius: 18px;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        font-size: 14px;
        height: 36px;
        line-height: 30px;
        padding: 0 12px 0 34px;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .label-settings-icon {
        border-color: #5999ff;
        color: #5999ff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .symbol-settings-icon .st0 {
        fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .symbol-settings-icon .st0 {
        fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader {
        width: 18px;
        height: 36px;
        margin: 0;
        top: 0;
        left: 4px;
        animation-duration: 2s; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::before, .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::after {
          color: #fff;
          border-width: 2px;
          animation-duration: 1s; }
  .background-media-panel .bg-panel-top-actions {
    position: absolute;
    bottom: 0;
    height: 54px;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    text-align: center; }
  .background-media-panel .bg-panel-action {
    padding: 11px 3px 0 3px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    box-sizing: border-box; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn {
      background-color: #fff;
      height: 32px;
      line-height: 32px;
      width: 82px;
      text-align: center; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol {
      margin-left: -1px; }
      .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol .st0 {
        fill: #116dff; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      color: #116dff;
      padding: 0 8px 0 0;
      margin-left: -2px; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn:hover {
      background-color: #e7f0ff; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .label {
      color: #bcbcbc; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn[disabled] {
      background-color: #fff; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .symbol .st0 {
      fill: #bcbcbc; }
    .background-media-panel .bg-panel-action .control-button2-wrapper {
      padding: 0; }
    .background-media-panel .bg-panel-action:first-child {
      padding-left: 8px; }
    .background-media-panel .bg-panel-action:last-child {
      padding-right: 8px; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(2),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(2) ~ .bg-panel-action {
      width: 44%; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(3),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(3) ~ .bg-panel-action {
      width: 33%; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(4),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(4) ~ .bg-panel-action {
      width: 25%; }
  .background-media-panel .image-control-with-buttons {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .background-media-panel .image-control-with-buttons .button-bar {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit; }
  .background-media-panel .image-control-action-link {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .background-media-panel .image-control-action-link span {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit; }
  .background-media-panel .scroll-type-thumbs {
    padding-top: 24px; }
  .background-media-panel .background-media-preset-list {
    overflow-y: scroll;
    min-height: 60px;
    width: 288px;
    box-sizing: border-box; }
    .background-media-panel .background-media-preset-list .background-media-preset-list-sections {
      padding: 20px 24px 0; }
      .background-media-panel .background-media-preset-list .background-media-preset-list-sections.extra-padding {
        padding-bottom: 84px; }
        .background-media-panel .background-media-preset-list .background-media-preset-list-sections.extra-padding .preset-list-item:last-child {
          margin-bottom: 0; }
    .background-media-panel .background-media-preset-list .preset-list-item {
      margin-bottom: 18px; }
  .background-media-panel .background-media-controls {
    overflow-y: scroll;
    height: calc(100%);
    border-bottom: 1px solid rgba(0, 0, 0, 0); }
  .background-media-panel .button-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    height: 84px;
    width: 100%;
    position: absolute;
    text-align: center; }
    .background-media-panel .button-wrapper .button {
      bottom: -12px;
      position: relative; }

.responsive-skin .section-design-panel .section-design-media {
  margin: 12px 24px; }
  .responsive-skin .section-design-panel .section-design-media .empty-state {
    margin: 12px 0 28px;
    background-color: #f4f5f8;
    outline: 1px solid #e5e5e5;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 119px;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .responsive-skin .section-design-panel .section-design-media .empty-state .symbol-plus {
      border: solid 1px #116dff;
      width: 9px;
      height: 9px;
      padding: 7px;
      border-radius: 50%;
      margin-bottom: 4px; }
    .responsive-skin .section-design-panel .section-design-media .empty-state .control-label-base {
      color: #116dff; }
    .responsive-skin .section-design-panel .section-design-media .empty-state:hover .control-label-base {
      text-decoration: underline; }
  .responsive-skin .section-design-panel .section-design-media .symbol-focal-point {
    width: 11px;
    height: 11px; }
  .responsive-skin .section-design-panel .section-design-media .symbol-remove {
    width: 10px;
    height: 12px; }
  .responsive-skin .section-design-panel .section-design-media .control-icon-button {
    outline: none !important; }
  .responsive-skin .section-design-panel .section-design-media .media-preview {
    position: relative;
    margin: 12px 0 8px;
    background-color: #f4f5f8;
    outline: 1px solid #e5e5e5; }
  .responsive-skin .section-design-panel .section-design-media .video-preview-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .responsive-skin .section-design-panel .section-design-media .media-controls {
    width: calc(100% + 6px);
    display: flex;
    margin: 0 0 6px; }
    .responsive-skin .section-design-panel .section-design-media .media-controls > * {
      flex-shrink: 0; }
      .responsive-skin .section-design-panel .section-design-media .media-controls > *:first-child {
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1; }

.responsive-skin .section-design-panel .section-info {
  padding: 4px 24px; }

.responsive-skin .section-design-panel .composite-text-input-labeled {
  padding: 14px 24px; }

.responsive-skin .section-design-panel .composite-slider-labeled {
  padding-top: 0;
  padding-bottom: 12px; }

.responsive-skin .section-design-panel .composite-dropdown-labeled {
  padding-top: 0;
  padding-bottom: 12px; }
  .responsive-skin .section-design-panel .composite-dropdown-labeled .control-label {
    margin-bottom: 12px; }

.responsive-skin .section-design-panel .focal-point-selected path {
  fill: #116dff !important; }

.responsive-skin .section-design-panel .control-label-base {
  color: #000624; }

.drop-down-menu-layout-panel .thumbnails-with-icons {
  margin-bottom: 17px;
  margin-top: 21px; }
  .drop-down-menu-layout-panel .thumbnails-with-icons label {
    font-size: 0; }
    .drop-down-menu-layout-panel .thumbnails-with-icons label svg {
      width: 78px;
      max-width: 78px;
      height: 36px;
      max-height: 36px; }

.combobox-manage-items-panel.comp-panel-content {
  height: 525px; }

.combobox-manage-items-panel .control-text-input-with-button {
  margin: 0 12px; }

.combobox-manage-items-panel .list-view-mode .sort-by-drag-list {
  padding-bottom: 156px; }

.combobox-manage-items-panel .list-view-mode .actions {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  height: 156px;
  width: 100%;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 21px; }
  .combobox-manage-items-panel .list-view-mode .actions .action-buttons {
    display: flex;
    flex-direction: column;
    margin: 12px 56px 0; }
  .combobox-manage-items-panel .list-view-mode .actions .action-buttons > button {
    margin-bottom: 10px; }
  .combobox-manage-items-panel .list-view-mode .actions .action-item {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #116dff;
    line-height: 18px;
    cursor: pointer; }

.header-scroll-panel .scroll-behaviors-thumbnails .illustration-container .header-behavior-illustration {
  position: relative;
  height: 114px;
  width: 114px; }
  .header-scroll-panel .scroll-behaviors-thumbnails .illustration-container .header-behavior-illustration img {
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0; }

.facebook-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.vk-share-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.widget-settings-panel .main-panel-content {
  padding-bottom: 90px; }

.widget-settings-panel .bottom-link-to-preview {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, #fff 90%); }

.widget-add-elements-panel {
  height: 525px; }
  .widget-add-elements-panel .root-container {
    display: flex;
    flex-direction: column; }
    .widget-add-elements-panel .root-container .root-container-loaded .empty-state-container {
      display: flex;
      flex: 1; }
    .widget-add-elements-panel .root-container .root-container-loaded .addable-components {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      padding-bottom: 108px; }

.spotify-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.youtube-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.pinterest-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.twitter-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.googleplus-thumbnails .control-thumbnail {
  align-items: flex-start;
  padding-left: 24px; }

.radio-group-manage-items-panel.comp-panel-content {
  height: 525px; }

.radio-group-manage-items-panel .control-text-input-with-button {
  margin: 0 12px; }

.radio-group-manage-items-panel .sort-by-drag-list {
  padding-bottom: 114px; }

.radio-group-manage-items-panel .item-list-row {
  height: 65px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #dfe5eb;
  cursor: move;
  position: relative; }
  .radio-group-manage-items-panel .item-list-row.defaultRadio .radio-text {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .radio-group-manage-items-panel .item-list-row .radio-text {
    display: inline-block;
    width: 182px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #3b4057; }
  .radio-group-manage-items-panel .item-list-row .radio-value {
    width: 182px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #868aa5; }
  .radio-group-manage-items-panel .item-list-row .symbol-dragHandle {
    width: 13px;
    height: 13px;
    padding-right: 16px; }
    .radio-group-manage-items-panel .item-list-row .symbol-dragHandle path {
      fill: #80b1ff; }
    .radio-group-manage-items-panel .item-list-row .symbol-dragHandle:hover path {
      fill: #116dff; }
  .radio-group-manage-items-panel .item-list-row .item-details {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%; }
    .radio-group-manage-items-panel .item-list-row .item-details.cant-be-drag {
      cursor: auto; }
    .radio-group-manage-items-panel .item-list-row .item-details .control-text-input-with-fixed-button > .control-text {
      -webkit-user-select: text;
              user-select: text; }
  .radio-group-manage-items-panel .item-list-row:hover {
    background-color: #e7f0ff; }
    .radio-group-manage-items-panel .item-list-row:hover .quick-actions {
      visibility: visible;
      border: 2px solid #b3e3f9; }
  .radio-group-manage-items-panel .item-list-row .quick-actions {
    position: absolute;
    right: 24px;
    top: 20px;
    border: 0;
    visibility: hidden; }
    .radio-group-manage-items-panel .item-list-row .quick-actions svg {
      position: relative;
      left: 6px;
      top: -7px; }
      .radio-group-manage-items-panel .item-list-row .quick-actions svg .cls-b1 {
        fill: #116dff; }
    .radio-group-manage-items-panel .item-list-row .quick-actions:hover {
      border: 2px solid #54ccf9; }
  .radio-group-manage-items-panel .item-list-row.expanded {
    background-color: #d6e6fe; }
    .radio-group-manage-items-panel .item-list-row.expanded .quick-actions.expanded {
      background-color: #116dff;
      visibility: visible;
      border: 2px solid #116dff; }
      .radio-group-manage-items-panel .item-list-row.expanded .quick-actions.expanded path {
        fill: #fff; }

.radio-group-manage-items-panel .control-text-input-with-fixed-button {
  flex: 1; }

.radio-group-manage-items-panel .drag-placeholder {
  background-color: #f8f8f8;
  border-top: 1px dashed #dfe5eb; }

.radio-group-manage-items-panel .drag-container {
  background-color: #e7f0ff;
  box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22); }

.radio-group-manage-items-panel .add-multiple-choices-btn {
  margin-top: 10px;
  margin-bottom: 8px; }

.radio-group-manage-items-panel .add-button-plus-icon {
  margin-right: 6px; }

.radio-group-manage-items-panel.multiple-items .sort-by-drag-list {
  padding-bottom: 156px; }

.twitter-tweet-settings .control-text-input-with-button .control-button {
  display: none !important; }

.anchor-menu-manage-panel .rich-text-paragraph {
  padding-bottom: 4px;
  display: flex; }
  .anchor-menu-manage-panel .rich-text-paragraph .rich-text-paragraph-info-icon {
    margin-left: auto; }

.anchor-menu-manage-panel .anchors-wrapper {
  margin-bottom: 8px; }

.anchor-menu-manage-panel .anchor-wrapper {
  padding: 0 24px 12px 24px;
  display: flex;
  align-items: center; }
  .anchor-menu-manage-panel .anchor-wrapper .anchor-checkbox {
    flex-shrink: 0; }
  .anchor-menu-manage-panel .anchor-wrapper .anchor-icon {
    display: flex;
    flex-shrink: 0;
    padding-left: 20px; }
  .anchor-menu-manage-panel .anchor-wrapper .anchor-text-input {
    display: inline-block;
    margin: 0;
    height: auto;
    padding-left: 10px; }
    .anchor-menu-manage-panel .anchor-wrapper .anchor-text-input .anchor-text-inner-input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased; }

.anchor-menu-manage-panel.anchors-sections .anchor-text-input {
  padding-left: 0; }

.multi-select-layout-panel .align-content-container {
  padding-right: 98px; }

.multi-select-layout-panel .radio-control svg.disabled {
  cursor: default;
  background-color: #f0f0f0; }
  .multi-select-layout-panel .radio-control svg.disabled:hover {
    background-color: #f0f0f0; }
  .multi-select-layout-panel .radio-control svg.disabled .cls-b3 {
    fill: #d5d5d5; }
  .multi-select-layout-panel .radio-control svg.disabled .st2 {
    fill: #d5d5d5; }
  .multi-select-layout-panel .radio-control svg.disabled .cls-b1 {
    fill: #bcbcbc; }
  .multi-select-layout-panel .radio-control svg.disabled .st1 {
    fill: #bcbcbc; }

.show-popup-on-load {
  display: flex; }
  .show-popup-on-load .control-buttons-group {
    width: 100%; }
    .show-popup-on-load .control-buttons-group .group-buttons-container {
      text-align: center; }

.lightbox-schedule .input-container {
  width: 115px; }

.lightbox-schedule .composite-time-range .control-text {
  margin-left: 0; }

#quickActionBarManagementPanel .comp-panel-content {
  height: 494px;
  min-height: 260px; }

#quickActionBarManagementPanel .button-wrapper {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 126px;
  width: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%); }
  #quickActionBarManagementPanel .button-wrapper .button {
    line-height: 26px; }

#quickActionBarManagementPanel .action-container {
  padding-bottom: 126px;
  min-height: 305px; }

#quickActionBarManagementPanel .dragItemContentBefore {
  width: 40px;
  height: 40px;
  border: 1px solid #dfe5eb;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  #quickActionBarManagementPanel .dragItemContentBefore svg {
    fill: #3b4057; }
  #quickActionBarManagementPanel .dragItemContentBefore.disabled {
    border-color: #ececec; }
    #quickActionBarManagementPanel .dragItemContentBefore.disabled svg {
      fill: #939393; }

#quickActionBarManagementPanel .dragItemAfter {
  margin-top: 5px; }

#quickActionBarManagementPanel .dynamicAction {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  padding: 12px 0 12px 8px;
  border-bottom: 1px solid #dfe5eb; }
  #quickActionBarManagementPanel .dynamicAction .handle-wrapper {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center; }
    #quickActionBarManagementPanel .dynamicAction .handle-wrapper svg {
      fill: #bcbcbc; }
  #quickActionBarManagementPanel .dynamicAction .symbolWrapper {
    width: 40px;
    height: 40px;
    border: 1px solid #dfe5eb;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    #quickActionBarManagementPanel .dynamicAction .symbolWrapper svg {
      fill: #3b4057; }
  #quickActionBarManagementPanel .dynamicAction .contentWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    width: 112px; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .label,
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .value {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .label {
      font-size: 16px;
      font-weight: 300;
      color: #3b4057; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .value {
      font-size: 12px;
      padding-top: 3px;
      color: #868aa5; }
  #quickActionBarManagementPanel .dynamicAction .content-after {
    flex-grow: 1;
    padding-left: 8px;
    display: flex;
    align-items: center; }

#quickActionBarManagementPanel .sort-by-drag-list {
  -ms-overflow-style: none; }

#quickActionBarManagementPanel .control-button .button-content {
  flex-direction: column; }

#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(4) .sbdl-item-row .view-mode-content .label,
#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .label {
  color: #939393; }

#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(4) .sbdl-item-row .view-mode-content .value,
#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .value {
  color: #bcbcbc; }

#quickActionBarManagementPanel .disabled-after-4 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .label {
  color: #939393; }

#quickActionBarManagementPanel .disabled-after-4 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .value {
  color: #bcbcbc; }

.qab-more-actions-context-menu-content {
  width: 200px;
  -ms-overflow-style: none; }

.qab-more-actions-context-menu-symbol {
  fill: #3b4057; }

.divider-short.qab-more-actions-context-menu-divider {
  margin: 6px 18px; }

.manage-columns-panel .controller-section-wrapper {
  margin: 0 24px; }
  .manage-columns-panel .controller-section-wrapper > .actions-container {
    margin-bottom: 24px; }
    .manage-columns-panel .controller-section-wrapper > .actions-container .control-button2-wrapper {
      padding: 0;
      display: inline-block; }

.manage-columns-panel .title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  margin: 18px 0;
  display: block;
  color: #3b4057;
  text-align: left;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.manage-columns-panel .btn-with-symbol {
  padding-left: 16px; }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-up .symbol {
    transform: rotate(90deg); }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-down .symbol {
    transform: rotate(-90deg); }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-right .symbol {
    transform: rotate(180deg); }
  .manage-columns-panel .btn-with-symbol .label {
    color: #116dff;
    padding-left: 8px; }
  .manage-columns-panel .btn-with-symbol:hover {
    color: #116dff;
    background-color: #d6e6fe; }
  .manage-columns-panel .btn-with-symbol:active {
    color: #116dff;
    background-color: #e7f0ff; }
  .manage-columns-panel .btn-with-symbol .symbol path {
    fill: #116dff; }
  .manage-columns-panel .btn-with-symbol[disabled] {
    color: #bcbcbc;
    background-color: #f0f0f0; }
    .manage-columns-panel .btn-with-symbol[disabled] .label {
      color: #bcbcbc; }
    .manage-columns-panel .btn-with-symbol[disabled] .symbol path {
      fill: #bcbcbc; }
  .manage-columns-panel .btn-with-symbol.squared {
    margin-right: 9px;
    border-radius: 6px;
    height: 38px;
    width: 38px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle; }

.members-login-button-settings-panel .site-members-link-container {
  display: flex;
  padding: 18px;
  align-items: flex-start; }
  .members-login-button-settings-panel .site-members-link-container .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex: 1;
    color: #116dff;
    margin: -2px 18px 0 12px;
    cursor: pointer; }

.login-social-bar-settings-panel .login-social-bar-links {
  padding-top: 16px; }
  .login-social-bar-settings-panel .login-social-bar-links .login-social-bar-link-container {
    display: flex;
    padding-bottom: 16px;
    align-items: center; }
    .login-social-bar-settings-panel .login-social-bar-links .login-social-bar-link-container .link {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      flex: 1;
      color: #116dff;
      margin: -2px 18px 0 12px;
      cursor: pointer;
      text-decoration: none; }
    .login-social-bar-settings-panel .login-social-bar-links .login-social-bar-link-container .symbol {
      margin-left: 22px; }
      .login-social-bar-settings-panel .login-social-bar-links .login-social-bar-link-container .symbol path {
        fill: #116dff; }

.grid-column-manager-panel {
  min-height: 525px; }
  .grid-column-manager-panel.comp-panel-content {
    height: 525px; }
  .grid-column-manager-panel .sort-by-drag-list {
    padding-bottom: 82px; }
  .grid-column-manager-panel .button-row {
    padding-top: 23px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%); }
  .grid-column-manager-panel .button {
    margin-bottom: 23px; }

.mediaPlayer-layout-panel .control-alignment {
  padding: 13px 24px 16px; }

.mediaPlayer-layout-panel .controls-padding {
  padding: 24px; }

.mediaOverlayControls-change-panel .has-customize-part {
  padding-bottom: 60px; }

.mediaOverlayControls-change-panel .customize-part {
  position: absolute;
  height: 84px;
  width: 100%;
  bottom: 0; }
  .mediaOverlayControls-change-panel .customize-part .customize-text {
    padding-left: 10px; }

.media-panel-play-button {
  position: relative; }
  .media-panel-play-button.normal {
    width: 36px;
    height: 36px; }
  .media-panel-play-button.small {
    width: 24px;
    height: 24px; }
  .media-panel-play-button.large {
    width: 44px;
    height: 44px; }
  .media-panel-play-button > input[type='checkbox'] {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .media-panel-play-button > input[type='checkbox'] + .play-pause-symbol {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: #fff 2px solid;
      box-sizing: border-box;
      background-color: rgba(22, 45, 61, 0.5); }
      .media-panel-play-button > input[type='checkbox'] + .play-pause-symbol .st0 {
        fill: #fff;
        stroke: transparent; }
    .media-panel-play-button > input[type='checkbox']:hover + .play-pause-symbol {
      border-color: #5999ff; }
      .media-panel-play-button > input[type='checkbox']:hover + .play-pause-symbol .st0 {
        fill: #5999ff; }
    .media-panel-play-button > input[type='checkbox']:checked + .play-pause-symbol .st0 {
      fill: transparent; }
    .media-panel-play-button > input[type='checkbox']:checked + .play-pause-symbol .st1 {
      fill: #fff; }
    .media-panel-play-button > input[type='checkbox']:checked:hover + .play-pause-symbol .st0 {
      fill: transparent; }
    .media-panel-play-button > input[type='checkbox']:checked:hover + .play-pause-symbol .st1 {
      fill: #5999ff; }
    .media-panel-play-button > input[type='checkbox']:disabled {
      pointer-events: none; }
      .media-panel-play-button > input[type='checkbox']:disabled + .play-pause-symbol {
        border-color: #bcbcbc; }
        .media-panel-play-button > input[type='checkbox']:disabled + .play-pause-symbol .st0 {
          fill: #bcbcbc; }
      .media-panel-play-button > input[type='checkbox']:disabled:checked + .play-pause-symbol .st0 {
        fill: transparent; }
      .media-panel-play-button > input[type='checkbox']:disabled:checked + .play-pause-symbol .st1 {
        fill: #bcbcbc; }
  .media-panel-play-button > .composite-preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }
    .media-panel-play-button > .composite-preloader > .control-preloader {
      width: 18px;
      height: 36px;
      margin-left: -18px; }

.mediaPlayer-Settings-panel .control-action-with-symbol {
  position: relative;
  height: 72px;
  cursor: pointer; }
  .mediaPlayer-Settings-panel .control-action-with-symbol > .symbol-container {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #116dff;
    border-radius: 50%;
    margin: auto 26px;
    width: 24px;
    height: 24px;
    right: 0; }
    .symbol-right.mediaPlayer-Settings-panel .control-action-with-symbol > .symbol-container {
      right: 0; }
    .mediaPlayer-Settings-panel .control-action-with-symbol > .symbol-container:hover {
      background: #5999ff; }
    .mediaPlayer-Settings-panel .control-action-with-symbol > .symbol-container > .symbol {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-right: 1px; }
      .mediaPlayer-Settings-panel .control-action-with-symbol > .symbol-container > .symbol > path {
        fill: #fff; }
  .mediaPlayer-Settings-panel .control-action-with-symbol > .content-container {
    position: absolute;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 24px;
    height: 20px; }
    .mediaPlayer-Settings-panel .control-action-with-symbol > .content-container > .content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px; }
      .symbol-left.mediaPlayer-Settings-panel .control-action-with-symbol > .content-container > .content {
        left: 34px;
        right: 0; }
      .symbol-right.mediaPlayer-Settings-panel .control-action-with-symbol > .content-container > .content {
        right: 45px;
        left: 0; }

.mediaPlayer-Settings-panel .bg-panel-preview-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7f0ff; }

.mediaPlayer-Settings-panel .bg-panel-thumbnails-with-indicator {
  position: relative; }

.media-add-poster-indicator .symbol-video-player-change-poster {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 100px;
  left: 98px;
  cursor: pointer; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st1 {
    fill: #116dff; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st2 {
    fill: #fff; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st3 {
    fill: #fff; }
  .media-add-poster-indicator .symbol-video-player-change-poster:hover .st1 {
    fill: #5999ff; }

.media-alpha-colorizing-indicator .symbol-video-player-recolor {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 118px;
  left: 98px;
  cursor: pointer; }

.responsive-skin .media-add-poster-indicator .symbol-video-player-change-poster {
  top: 45px; }

.responsive-skin .media-alpha-colorizing-indicator .symbol-video-player-recolor {
  top: 95px; }

.media-player-design-panel.comp-panel-content {
  max-height: 550px; }

.media-player-design-panel .customize-colorizing-mode {
  padding-bottom: 10px; }

.media-player-design-panel .design-data-panel {
  height: 500px; }
  .media-player-design-panel .design-data-panel .tab-text, .media-player-design-panel .design-data-panel .advanced-style-panel .tab-text-composite, .advanced-style-panel .media-player-design-panel .design-data-panel .tab-text-composite {
    top: 10px;
    line-height: 32px; }
  .media-player-design-panel .design-data-panel .composite-with-info-icon .control-label {
    padding-right: 10px; }
  .media-player-design-panel .design-data-panel .control-radio-buttons-list {
    justify-content: center; }

.media-player-behaviors-panel .design-data-panel .tab-text, .media-player-behaviors-panel .design-data-panel .advanced-style-panel .tab-text-composite, .advanced-style-panel .media-player-behaviors-panel .design-data-panel .tab-text-composite {
  line-height: 48px; }

.media-player-behaviors-panel .design-data-panel.flex-column {
  height: 400px; }

.media-player-behaviors-panel .design-data-panel .composite-with-info-icon .control-label {
  padding-right: 10px; }

.media-player-behaviors-panel .design-data-panel .behavior-panel-mute-tooltip {
  position: absolute;
  top: 65px;
  left: 165px;
  width: 53px;
  height: 32px; }

.media-player-behaviors-panel .design-data-panel .behavior-panel-slowmo-tooltip {
  position: absolute;
  top: 323px;
  left: 165px;
  width: 53px;
  height: 32px; }

.mask-panel .content {
  position: relative;
  height: calc(100% - 54px); }

.mask-panel .image-crop-change-mask-indicator {
  position: absolute;
  top: 13px;
  left: 84px; }

.mask-panel .inner-border .control-thumbnail > .illustration-container > .illustration-inner-container {
  border-radius: unset; }

.mask-panel .thumbnail-wrapper .illustration-container::after {
  box-shadow: none !important; }

.mask-panel .thumbnail-wrapper .custom-mask .illustration-container::after {
  box-shadow: 0 0 0 1px #dfe5eb !important; }

.mask-panel .thumbnail-wrapper:hover .illustration-container::after {
  box-shadow: 0 0 0 2px #e7f0ff !important; }

.mask-panel .thumbnail-wrapper.selected .illustration-container::after {
  background-color: transparent !important;
  box-shadow: 0 0 0 2px #116dff !important; }

.mask-panel .symbol-video-player-change-poster {
  width: 26px;
  height: 26px;
  position: absolute;
  cursor: pointer; }
  .mask-panel .symbol-video-player-change-poster .st1 {
    fill: #116dff; }
  .mask-panel .symbol-video-player-change-poster .st2 {
    fill: #fff; }
  .mask-panel .symbol-video-player-change-poster .st3 {
    fill: #fff; }
  .mask-panel .symbol-video-player-change-poster:hover .st1 {
    fill: #5999ff; }

.mask-panel .thumbnails-list-with-toggle {
  padding-bottom: 46px; }

.mask-panel .mask-aspect-control {
  position: absolute;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #dfe5eb; }
  .mask-panel .mask-aspect-control .control-action-with-symbol > .content-container .content {
    font-size: 16px; }

.mask-panel .classic-facelift-skin .mask-aspect-control {
  background: #fff;
  border-top-color: #dfe5eb; }

.mask-panel .responsive-skin .mask-aspect-control {
  background: #fff;
  border-top-color: #e5e5e5; }

.scaling-svg-container {
  position: relative;
  width: 15px;
  height: 15px;
  padding: 0 0 100%; }

.scaling-svg {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

.contactform-manage-fields-panel.comp-panel-content {
  height: 525px; }

.contactform-manage-fields-panel .sticky-message {
  border-top: none; }

.contactform-manage-fields-panel .shadow-button-wrapper {
  height: 84px;
  width: 288px; }

.contactform-manage-fields-panel .button-wrapper {
  height: 84px;
  width: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  position: absolute;
  text-align: center;
  box-sizing: border-box; }
  .contactform-manage-fields-panel .button-wrapper .menu-source {
    margin: 0 15px; }

.contact-form-settings-panel .composite-button-large {
  padding: 0 0 12px; }
  .contact-form-settings-panel .composite-button-large .control-button:not(.btn-text) {
    margin: 0 !important; }

.contact-form-settings-panel .promotional-list-wrapper-contact-form {
  padding: 0 20px 20px 20px; }

.search-results-pdf {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #7a92a5; }
  .search-results-pdf > .search-results-pdf-text {
    margin: 17px 25px;
    color: #3899ec;
    line-height: 18px;
    display: inline-block; }
    .search-results-pdf > .search-results-pdf-text:hover {
      cursor: pointer;
      text-decoration: underline; }
  .search-results-pdf .search-results-pdf-inner-container {
    margin: 17px 25px;
    line-height: 18px; }
    .search-results-pdf .search-results-pdf-inner-container .search-results-pdf-link-text {
      color: #3899ec;
      display: inline-block; }
      .search-results-pdf .search-results-pdf-inner-container .search-results-pdf-link-text:hover {
        text-decoration: underline;
        cursor: pointer; }

.document-media-seo-confirmation-panel {
  background-color: #fff;
  width: 564px; }
  .document-media-seo-confirmation-panel .panel-header {
    height: 60px; }
  .document-media-seo-confirmation-panel .panel-header-title {
    font-size: 20px;
    line-height: 60px; }
  .document-media-seo-confirmation-panel .content {
    padding: 30px 55px; }
    .document-media-seo-confirmation-panel .content > * {
      display: inline-block; }
    .document-media-seo-confirmation-panel .content .error-img {
      width: 76px;
      height: 126px; }
    .document-media-seo-confirmation-panel .content .msg-text {
      position: absolute;
      margin-left: 55px;
      line-height: 22px;
      width: 350px;
      margin-top: 15px; }
    .document-media-seo-confirmation-panel .content .support-link {
      color: #116dff;
      text-decoration: none;
      padding: 0 9px; }
      .document-media-seo-confirmation-panel .content .support-link:hover {
        text-decoration: underline; }
    .document-media-seo-confirmation-panel .content .control-button.btn-confirm-primary {
      margin-right: 30px; }
    .document-media-seo-confirmation-panel .content > svg .shadow {
      fill: #ebf7fe; }
    .document-media-seo-confirmation-panel .content > svg .document {
      fill: #a7e3fa; }
    .document-media-seo-confirmation-panel .content > svg .logo {
      fill: #ffffff; }
    .document-media-seo-confirmation-panel .content > svg .magnifier {
      fill: #3899eb; }
    .document-media-seo-confirmation-panel .content > svg .corner {
      fill: #4db6f4; }
  .document-media-seo-confirmation-panel > footer {
    padding: 0 30px; }

.repeater-manage-items-panel {
  height: 525px; }
  .repeater-manage-items-panel .mouse-leave-indicator {
    width: 100%;
    height: 100%; }
    .repeater-manage-items-panel .mouse-leave-indicator .repeater-manage-items-drag-list {
      padding-bottom: 114px; }
  .repeater-manage-items-panel .repeater-manage-items-bottom-button {
    background: #fff;
    position: absolute;
    height: 114px; }

.repeater-layout-panel .align-thumbnail {
  width: 51px;
  height: 51px; }
  .repeater-layout-panel .align-thumbnail.left {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.left:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.left.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.left.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.right {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.right:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.right.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.right.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.center {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.center:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.center.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.center.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.justify {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.justify:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.justify.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.justify.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-selected.gif") no-repeat 50% 50%; }

.wphoto-settings-panel .composite-action-with-symbol {
  padding: 20px 24px 16px; }

.imageX-settings-panel {
  max-height: 540px !important; }
  .imageX-settings-panel .composite-text-input-labeled {
    margin: 0 0 12px 0; }
    .imageX-settings-panel .composite-text-input-labeled .control-label {
      margin: 0 0 12px 0; }
  .imageX-settings-panel .input-link-to {
    padding: 0 2px 12px 4px;
    width: auto; }
  .imageX-settings-panel .composite-action-with-symbol {
    padding: 8px 24px; }
    .imageX-settings-panel .composite-action-with-symbol .control-action-with-symbol {
      display: flex;
      align-items: center; }
      .imageX-settings-panel .composite-action-with-symbol .control-action-with-symbol .control-label {
        margin: 0; }

.video-player-settings-panel {
  overflow-y: scroll; }
  .video-player-settings-panel .composite-rich-text {
    padding-bottom: 12px;
    padding-top: 12px; }

.backToTopButton-change-panel .back-to-top-thumbnails-part {
  padding-bottom: 60px; }
  .backToTopButton-change-panel .back-to-top-thumbnails-part .change-icon-indicator {
    position: absolute;
    top: 13px;
    left: 64.25px;
    cursor: pointer; }
  .backToTopButton-change-panel .back-to-top-thumbnails-part .custom-icon {
    display: flex; }
    .backToTopButton-change-panel .back-to-top-thumbnails-part .custom-icon svg {
      height: 39px;
      padding: 6.125px; }
  .backToTopButton-change-panel .back-to-top-thumbnails-part .upload-button {
    height: 51.25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #80b1ff;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #e7f0ff;
    color: #116dff;
    cursor: pointer; }
    .backToTopButton-change-panel .back-to-top-thumbnails-part .upload-button:hover {
      background-color: #d6e6fe; }

.backToTopButton-change-panel .back-to-top-customize-part {
  position: absolute;
  height: 84px;
  width: 100%;
  bottom: 0; }
  .backToTopButton-change-panel .back-to-top-customize-part .customize-text {
    padding-left: 10px; }

.upgradeToMenuContainer .control-illustration {
  padding-left: 24px;
  align-self: center; }

.google-map-manage-locations.with-back-button header {
  display: flex !important; }

.google-map-manage-locations .manage-locations-panel .pin {
  fill: #116dff; }

.google-map-manage-locations .manage-locations-panel .river {
  fill: #80b1ff; }

.google-map-manage-locations .manage-locations-panel .road {
  fill: #edf7ff; }

.google-map-manage-locations .manage-locations-panel .background {
  fill: #d6e6fe; }

.google-map-manage-locations .manage-locations-panel .outer-border.with-label .control-thumbnail > .illustration-container {
  border-bottom: none; }

.multiple-locations-items .multiple-locations-bottom-button {
  height: 114px; }

.multiple-locations-items .default-location.has-value .view-mode-content .label {
  font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.context-menu-action .multiple-locations-action-symbol path {
  fill: #3b4057; }

.single-location-panel .input-link-to {
  padding-top: 16px; }
  .single-location-panel .input-link-to .with-button {
    padding-bottom: 15px; }
  .single-location-panel .input-link-to .container-action {
    padding-bottom: 5px; }
  .single-location-panel .input-link-to label {
    padding-bottom: 15px; }

.vector-image-design-panel.comp-panel-content {
  max-height: 545px; }

.vector-image-design-panel .reset-colors-button {
  position: relative; }

.vector-image-design-panel .shadow-controls {
  padding-bottom: 10px; }

.dividers-panel {
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* fix base-ui selection on thumb 'none' */ }
  .dividers-panel::-webkit-scrollbar {
    background-color: #f7f8f8;
    width: 6px; }
  .dividers-panel::-webkit-scrollbar-track {
    background-color: #f7f8f8; }
  .dividers-panel::-webkit-scrollbar-thumb {
    background-color: #dfe5eb;
    opacity: 0.3; }
  .dividers-panel::-webkit-scrollbar-button {
    display: none; }
  .dividers-panel:hover::-webkit-scrollbar-thumb {
    opacity: 0.5; }
  .dividers-panel .control-horizontal-tabs {
    position: -webkit-sticky;
    position: sticky;
    top: 0%;
    z-index: 2;
    background-color: white; }
  .dividers-panel .none-thumbnail-symbol-container {
    background-color: #e7f0ff; }
  .dividers-panel .content {
    position: relative;
    height: calc(100% - 54px); }
  .dividers-panel .image-crop-change-mask-indicator {
    position: absolute;
    top: 16px;
    left: 248px; }
  .dividers-panel .inner-border .control-thumbnail > .illustration-container > .illustration-inner-container {
    border-radius: unset; }
  .dividers-panel .inner-border .selected .control-thumbnail > .illustration-container > .illustration-inner-container {
    border-radius: 4px; }
  .dividers-panel .thumbnail-wrapper .illustration-container::after {
    box-shadow: none;
    border: none !important;
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
    margin: 0 !important;
    border-radius: 4px !important;
    transition: box-shadow 0.1s; }
  .dividers-panel .thumbnail-wrapper .illustration-container img {
    border-radius: 4px !important; }
  .dividers-panel .thumbnail-wrapper:nth-child(2):not(.selected) .illustration-container::after {
    box-shadow: 0 0 0 1px #dfe5eb inset !important; }
  .dividers-panel .thumbnail-wrapper:hover .illustration-container::after {
    box-shadow: 0 0 0 1.5px #80b1ff inset !important; }
  .dividers-panel .thumbnail-wrapper.selected .illustration-container::after {
    box-shadow: 0 0 0 2px #116dff inset !important; }
  .dividers-panel .inner-border .none-thumbnail.selected .control-thumbnail > .illustration-container:after {
    box-shadow: 0 0 0 2px #116dff; }
  .dividers-panel .flip-invert-control {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    margin: 22px 24px; }
    .dividers-panel .flip-invert-control .flip-invert-label {
      order: 0;
      flex: 0 1 auto;
      align-self: center; }
    .dividers-panel .flip-invert-control .flip-invert-thumbs {
      display: flex;
      gap: 12px;
      order: 0;
      align-self: flex-end; }
      .dividers-panel .flip-invert-control .flip-invert-thumbs .thumbnail-wrapper {
        width: 2em; }
        .dividers-panel .flip-invert-control .flip-invert-thumbs .thumbnail-wrapper:hover .illustration-container::after {
          box-shadow: none !important; }
        .dividers-panel .flip-invert-control .flip-invert-thumbs .thumbnail-wrapper:hover .illustration-inner-container {
          background-color: #d6e6fe; }
    .dividers-panel .flip-invert-control .illustration-inner-container {
      background-color: #e7f0ff; }
    .dividers-panel .flip-invert-control .flip-invert-icon {
      fill: #116dff; }
      .dividers-panel .flip-invert-control .flip-invert-icon > path {
        fill: #116dff; }

.responsive-skin .dividers-panel {
  /* fix base-ui selection on thumb 'none' for responsive*/ }
  .responsive-skin .dividers-panel .inner-border .selected.none-thumbnail .control-thumbnail .illustration-container:after {
    display: block;
    box-shadow: 0 0 0 1px #116dff !important; }
  .responsive-skin .dividers-panel .thumbnail-wrapper .illustration-container .illustration-inner-container {
    height: auto !important; }
    .responsive-skin .dividers-panel .thumbnail-wrapper .illustration-container .illustration-inner-container img {
      padding: 0px !important; }

.media-add-poster-indicator .symbol-video-player-change-poster {
  width: 26px;
  height: 26px;
  position: absolute;
  cursor: pointer; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st1 {
    fill: #116dff; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st2 {
    fill: #fff; }
  .media-add-poster-indicator .symbol-video-player-change-poster .st3 {
    fill: #fff; }
  .media-add-poster-indicator .symbol-video-player-change-poster:hover .st1 {
    fill: #5999ff; }

.widget-presets-panel {
  display: flex;
  height: 521px; }
  .widget-presets-panel .categories {
    width: 150px; }
  .widget-presets-panel .presets-panel-content-with-footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto; }
    .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content {
      flex: 1;
      padding: 24px 30px 0; }
      .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container {
        margin-top: 24px;
        columns: 3;
        column-gap: 24px; }
        .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container .preset-image {
          border-radius: 4px; }
        .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container .gallery-card {
          margin-bottom: 22px;
          min-height: unset;
          box-sizing: border-box;
          display: inline-block; }
        .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container .selected .gallery-card {
          outline: 2px solid #116dff;
          border-radius: 4px; }
          .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container .selected .gallery-card:before {
            content: '';
            background: url("https://static.parastorage.com/services/santa-resources/dist/editor/compPanels/widgetPresetsPanel/selectedPresetIcon.png");
            position: absolute;
            top: -11px;
            right: -11px;
            width: 22px;
            height: 22px; }
        .widget-presets-panel .presets-panel-content-with-footer .presets-panel-content .presets-container .with-preset-name .gallery-card {
          margin-bottom: 60px; }
    .widget-presets-panel .presets-panel-content-with-footer .footer {
      background: #fff;
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: column; }
      .widget-presets-panel .presets-panel-content-with-footer .footer .footer-note {
        margin: 12px auto; }
        .widget-presets-panel .presets-panel-content-with-footer .footer .footer-note .gfpp-entry-point-button {
          vertical-align: bottom; }

.animation-panel .comp-panel-content {
  max-height: 545px; }

.animation-panel .control-button.btn-back:not(.btn-header-back) {
  margin-top: 12px;
  margin-bottom: 12px; }

.animation-panel .control-switch.toggle-play-once {
  margin-top: 20px;
  margin-bottom: 20px; }

.animation-panel .panel-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 72px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0, white 70%); }
  .animation-panel .panel-bottom button {
    margin-top: 20px; }

.animation-panel .customize-mode {
  border-bottom: 1px solid transparent; }

.animation-panel .preview-animation-panel {
  overflow: hidden; }
  .animation-panel .preview-animation-panel .current-animation-details {
    float: left; }
    .animation-panel .preview-animation-panel .current-animation-details .current-animation-symbol {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
    .animation-panel .preview-animation-panel .current-animation-details .current-animation-label {
      display: inline-block;
      color: #3b4057;
      font-size: 14px;
      font-weight: 300;
      text-align: left; }
  .animation-panel .preview-animation-panel .btn-preview-button {
    cursor: pointer;
    border: none;
    background: none;
    outline: 0;
    float: right;
    margin: 25px 23px 0 0;
    padding: 0; }

.behavior-thumbnails {
  width: 252px;
  margin: 0 auto;
  padding: 15px 0 64px; }
  .behavior-thumbnails li {
    display: inline-block;
    margin: 9px 6px;
    width: 72px;
    vertical-align: top; }
    .behavior-thumbnails li .behavior-symbol {
      width: 100%;
      background-color: #e7f0ff;
      border-radius: 4px;
      transition: background-color 0.2s ease-in;
      position: relative;
      font-size: 12px;
      color: #116dff;
      height: 72px;
      cursor: pointer;
      overflow: hidden; }
      .behavior-thumbnails li .behavior-symbol .behavior-icon-preview {
        display: none; }
        .behavior-thumbnails li .behavior-symbol .behavior-icon-preview svg {
          cursor: pointer;
          padding: 0; }
      .behavior-thumbnails li .behavior-symbol .behavior-icon svg {
        cursor: pointer;
        padding: 0; }
      .behavior-thumbnails li .behavior-symbol.behavior-selected {
        background: #116dff; }
        .behavior-thumbnails li .behavior-symbol.behavior-selected.none {
          background: #e7f0ff; }
        .behavior-thumbnails li .behavior-symbol.behavior-selected.playing .behavior-icon {
          display: none; }
        .behavior-thumbnails li .behavior-symbol.behavior-selected.playing .behavior-icon-preview {
          display: inherit; }
      .behavior-thumbnails li .behavior-symbol:hover {
        background: #d6e6fe;
        cursor: pointer; }
        .behavior-thumbnails li .behavior-symbol:hover.behavior-selected {
          background: #116dff; }
          .behavior-thumbnails li .behavior-symbol:hover.behavior-selected.allow-preview .behavior-icon {
            display: none; }
          .behavior-thumbnails li .behavior-symbol:hover.behavior-selected .behavior-icon-preview {
            display: inherit; }
          .behavior-thumbnails li .behavior-symbol:hover.behavior-selected.none {
            background: #d6e6fe; }
            .behavior-thumbnails li .behavior-symbol:hover.behavior-selected.none .behavior-icon {
              display: inherit; }
            .behavior-thumbnails li .behavior-symbol:hover.behavior-selected.none .behavior-icon-preview {
              display: none; }
    .behavior-thumbnails li .behavior-name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      color: #5999ff;
      padding-top: 9px;
      font-size: 12px;
      line-height: 1; }

.effects-panel .scroll-behaviors-thumbnails .illustration-container {
  width: 72px; }
  .effects-panel .scroll-behaviors-thumbnails .illustration-container .behavior-illustration {
    position: relative;
    height: 72px;
    width: 72px; }
    .effects-panel .scroll-behaviors-thumbnails .illustration-container .behavior-illustration img {
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 0; }

.panel-dock {
  width: 288px;
  height: 500px; }
  .panel-dock > .content {
    color: #000624;
    font-size: 14px; }
  .panel-dock hr {
    background-color: #dfe5eb; }
  .panel-dock .control-dock {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 20px;
    width: 100%; }
  .panel-dock .dock-offset-slider {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: 15px; }
  .panel-dock .unPinLabel {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #116dff;
    position: relative;
    cursor: pointer;
    top: 0;
    display: block;
    margin-bottom: 20px; }
    .panel-dock .unPinLabel:hover {
      text-decoration: underline; }
  .panel-dock .disabled {
    cursor: default;
    pointer-events: none; }
  .panel-dock .transparent {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
    .panel-dock .transparent:hover {
      text-decoration: none; }

.apply-panel .rich-text-paragraph {
  padding: 19px 12px 17px 12px;
  margin: 0 11px; }

.apply-panel .control-button2-wrapper {
  text-align: center; }
  .apply-panel .control-button2-wrapper .control-button2 {
    margin: 24px 0; }
    .apply-panel .control-button2-wrapper .control-button2 .label {
      margin-bottom: 2px;
      padding-left: 12px; }
    .apply-panel .control-button2-wrapper .control-button2 .symbol {
      padding-left: 12px; }

.popup-full-layout-panel .comp-panel-content {
  display: flex; }
  .popup-full-layout-panel .comp-panel-content .control-buttons-group {
    margin: 20px auto 35px; }

.panel-dock {
  width: 288px;
  height: auto; }
  .panel-dock > .content {
    color: #000624;
    font-size: 14px; }
  .panel-dock hr {
    background-color: #dfe5eb; }
  .panel-dock .control-dock {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 20px; }
  .panel-dock .dock-offset-slider {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: 15px; }
  .panel-dock .unPinLabel {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #116dff;
    position: relative;
    cursor: pointer;
    top: 0;
    display: block;
    margin-bottom: 20px; }
    .panel-dock .unPinLabel:hover {
      text-decoration: underline; }
  .panel-dock .disabled {
    cursor: default;
    pointer-events: none; }
  .panel-dock .transparent {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
    .panel-dock .transparent:hover {
      text-decoration: none; }

.filters-panel .filters-list {
  transform: translateZ(0); }

.deprecation-comp-panel {
  display: block; }
  .deprecation-comp-panel .content {
    padding: 17px 22px 22px 22px; }
    .deprecation-comp-panel .content .content-text {
      padding-top: 11px; }
    .deprecation-comp-panel .content .control-button.primary {
      float: right;
      height: 30px;
      margin: 18px 0;
      padding: 0 18px; }
    .deprecation-comp-panel .content .control-checkbox {
      padding-top: 18px; }
  .deprecation-comp-panel .panel-header {
    height: 161px;
    background-color: #ffffff; }
    .deprecation-comp-panel .panel-header .btn-close {
      position: absolute;
      top: 13px;
      right: 13px;
      background-color: #e7f0ff; }
      .deprecation-comp-panel .panel-header .btn-close:hover {
        background-color: #d6e6fe; }
      .deprecation-comp-panel .panel-header .btn-close svg path {
        fill: #116dff; }

.stretching-panel .input-slider {
  margin: 24px; }

.stretching-panel .input-stepper.has-units.with-units-dd > .input {
  width: 30px; }

.ally-settings-panel {
  height: 400px; }
  .ally-settings-panel .a11y-manage-items-drag-list {
    padding-bottom: 84px; }
  .ally-settings-panel .a11y-manage-items-bottom-button {
    height: 84px; }
  .ally-settings-panel .empty-state-container {
    height: 100%; }
    .ally-settings-panel .empty-state-container .add-property-content {
      display: flex;
      align-items: center;
      flex-direction: column; }

.smart-writer-panel {
  width: 354px; }
  .smart-writer-panel-search {
    padding: 18px 24px;
    box-sizing: border-box; }
    .smart-writer-panel-search .control-label {
      margin-bottom: 6px; }
  .smart-writer-panel-next {
    padding-top: 16px;
    padding-bottom: 16px; }
    .smart-writer-panel-next .has-tooltip {
      display: block;
      padding: 8px 16px; }
  .smart-writer-panel-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px; }
    .smart-writer-panel-options .control-button {
      border-radius: 6px;
      border: 1px solid #dfe5eb;
      background-color: #fff;
      font-size: 12px;
      color: #3b4057;
      height: 24px;
      padding: 0 12px;
      margin: 0 6px 12px 0;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      -webkit-font-smoothing: antialiased; }
      .smart-writer-panel-options .control-button:hover {
        background-color: #fff;
        border-color: #5999ff;
        color: #3b4057; }
      .smart-writer-panel-options .control-button.active {
        background-color: #fff;
        border-color: #116dff;
        color: #116dff; }
  .smart-writer-panel-business-type-options {
    min-height: 80px;
    padding-top: 0;
    padding-bottom: 0; }
    .smart-writer-panel-business-type-options .control-label {
      margin-bottom: 0; }
    .smart-writer-panel-business-type-options .control-button {
      height: 18px;
      font-size: 12px;
      padding-right: 5px; }
  .smart-writer-panel-topics-options {
    padding-bottom: 0; }
  .smart-writer-panel-description {
    font-size: 14px;
    color: #3b4057; }
    .smart-writer-panel-description .control-label {
      margin-bottom: 0; }
    .smart-writer-panel-description .btn-text {
      font-size: inherit;
      height: auto; }
  .smart-writer-panel-loader {
    height: 390px;
    display: flex;
    justify-content: center; }
    .smart-writer-panel-loader-container {
      padding-top: 78px;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .smart-writer-panel-loader-gif {
      width: 170px;
      height: 116px;
      padding-bottom: 13px; }
    .smart-writer-panel-loader-gif-new {
      width: 116px; }
    .smart-writer-panel-loader-progressbar {
      width: 282px;
      padding-top: 18px; }
  .smart-writer-panel-paragraphs {
    padding: 0 24px; }
    .smart-writer-panel-paragraphs__item {
      padding: 18px 0; }
    .smart-writer-panel-paragraphs__title {
      margin-bottom: 8px; }
    .smart-writer-panel-paragraphs__placeholder {
      color: #868aa5;
      text-decoration: underline;
      -webkit-text-decoration-style: dashed;
              text-decoration-style: dashed;
      white-space: nowrap;
      font-size: 12px; }
    .smart-writer-panel-paragraphs__text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      display: block;
      color: #3b4057;
      padding-bottom: 18px; }
    .smart-writer-panel-paragraphs__control {
      display: flex;
      justify-content: space-between; }
    .smart-writer-panel-paragraphs__btn.control-button {
      font-size: 14px;
      padding: 0 12px;
      height: 24px; }
    .smart-writer-panel-paragraphs__btn-text.control-button {
      font-size: 14px;
      height: 24px; }
  .smart-writer-panel-typing-symbol {
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center; }

.blog-post-filter span.label-checkbox {
  margin-right: 0;
  font-size: 14px;
  max-width: 199px; }

.tpa-preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #373737;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center; }
  .tpa-preloader .circle-preloader {
    margin-top: -40px; }
  .tpa-preloader .text {
    margin-top: 65px; }

.tpa-unresponsive {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 54px 43px;
  background-color: #fff; }
  .tpa-unresponsive header {
    color: #3b4057;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px; }
    .tpa-unresponsive header svg.symbol-alert {
      margin-bottom: -3px; }
  .tpa-unresponsive #textContainer {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-medium);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #868aa5;
    margin-top: 10px; }
  .tpa-unresponsive a {
    color: #116dff;
    cursor: pointer;
    margin: 4px; }
    .tpa-unresponsive a:hover {
      text-decoration: underline; }
  .tpa-unresponsive #dismissButton {
    display: inline-block; }
  .tpa-unresponsive #text {
    line-height: 24px; }
  .tpa-unresponsive img {
    margin-left: 10px; }

.more {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  padding-top: 12px;
  border-top: 1px #dfe5eb solid;
  margin: 0 20px;
  color: #868aa5;
  font-weight: 300;
  line-height: 21px;
  text-align: left; }
  .more .symbol-market {
    position: relative;
    left: 3px;
    top: 3px; }
  .more span {
    margin-left: 11px; }
  .more a {
    display: block;
    color: #116dff;
    cursor: pointer;
    margin-left: 28px; }
    .more a:hover {
      text-decoration: underline; }

.box-app-wrapper {
  cursor: pointer;
  height: 118px;
  width: 328px;
  position: relative;
  display: inline-flex;
  padding-left: 24px; }
  .box-app-wrapper:hover {
    background-color: #e7f0ff; }
  .box-app-wrapper .box-app {
    border-radius: 4px;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
    border: 5px solid #fff;
    background-size: cover;
    background-position: center;
    height: 60px;
    width: 60px;
    float: left;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0; }
    .box-app-wrapper .box-app .box-app-logo {
      width: 100%;
      height: 100%;
      background-size: contain;
      border-radius: 5px; }
  .box-app-wrapper .content {
    cursor: pointer;
    left: 60px;
    position: relative;
    width: 160px;
    margin: 26px 31px;
    height: 52px; }
    .box-app-wrapper .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin-bottom: 6px; }
    .box-app-wrapper .content label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      color: #3b4057;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%; }
  .box-app-wrapper .plus-tooltip {
    position: absolute;
    margin: 31px 0;
    right: 24px;
    top: 17px;
    width: 28px;
    height: 28px; }
  .box-app-wrapper .icon-app {
    right: 23.6px;
    bottom: 23.6px;
    position: absolute; }

.tpaSection .section-header {
  margin: 0 auto; }

.app-market-frame {
  width: 848px;
  height: 100%;
  max-height: 681px; }

.app-market-frame-20 {
  width: 1164px;
  height: 100%; }

.app-market-frame-responsive {
  width: 100%;
  height: 100%; }

.app-market-responsive {
  width: 85%;
  max-width: 1254px;
  min-width: 594px; }

.app-market-frame-new {
  min-width: 1166px;
  height: 100%;
  display: flex; }

.app-market-frame-new > div {
  flex: 1;
  overflow-y: auto;
  height: 100%; }

.app-market-panel-new {
  min-width: 1166px;
  max-width: 1600px;
  z-index: 1105; }

.app-market-content-new {
  height: calc(100vh - 166px); }

.app-market-panel {
  height: 735px; }

.app-market-panel-20 {
  height: calc(100vh - 96px);
  z-index: 1105; }

.app-market-panel-no-header {
  height: 100vh;
  z-index: 1105; }

.app-market-panel .panel-header,
.app-market-panel-20 .app-market-panel-no-header .panel-header {
  border-bottom: 1px solid #dfe5eb; }

.app-market-panel.left-panel-frame-content,
.app-market-panel-20 .app-market-panel-no-header.left-panel-frame-content {
  height: calc(100% - 54px); }
  .app-market-panel.left-panel-frame-content.no-header,
  .app-market-panel-20 .app-market-panel-no-header.left-panel-frame-content.no-header {
    height: 100%; }

.app-market-app-modal-container {
  width: 906px;
  height: 576px; }

.app-market-permissions-modal-container {
  width: 534px;
  height: 216px; }

.app-market-modal-container {
  width: 100%;
  height: 100%; }

.app-market-frame, .app-market-frame-20, .app-market-app-modal-container, .app-market-permissions-modal-container, .app-market-modal-container {
  background-color: #fff; }

.tpa-settings-modal.focus-panel-frame {
  margin-top: 0;
  box-shadow: none; }

.tpa-settings-modal .focus-panel-frame-content-no-header {
  height: calc(100% - 54px); }

.tpa-settings-modal.no-margin {
  border-radius: 0; }
  .tpa-settings-modal.no-margin .focus-panel-frame-content-no-header {
    height: 100%; }

.tpa-settings-modal.bare .focus-panel-frame-content-no-header {
  height: 100%; }

.item-list {
  display: flex;
  width: 426px;
  height: 66px;
  align-items: center;
  padding: 0 24px; }
  .item-list.app-studio {
    justify-content: space-between; }
  .item-list .label-container {
    display: flex;
    align-items: flex-end;
    width: 309px; }
  .item-list .label {
    max-width: 309px; }
    .item-list .label.import-disabled {
      max-width: 268px; }
  .item-list .import-tooltip {
    width: 73px;
    height: 24px; }
  .item-list .suffix {
    width: 35px;
    margin-left: 6px;
    color: #868aa5;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased; }
  .item-list .edit-tooltip {
    margin-left: 8px;
    margin-right: 12px; }
  .item-list .edit-button {
    background-color: #e7f0ff;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    padding: 0; }
    .item-list .edit-button svg * {
      fill: #116dff; }
    .classic-facelift-skin .item-list .edit-button:hover {
      background: #d6e6fe; }

.app-import-panel .app-Import-panel-content {
  height: 504px; }

.app-import-panel .no-created-apps-state {
  padding-top: 40px; }

.app-import-panel .empty-state {
  padding-top: 48px; }
  .app-import-panel .empty-state .empty-text {
    max-width: 336px; }

.app-import-panel .error-state {
  text-align: center;
  padding-top: 86px; }
  .app-import-panel .error-state svg {
    width: 100%;
    margin-bottom: 22px; }
    .app-import-panel .error-state svg * {
      fill: #3b4057; }

.app-import-panel .apps-list-search-wrapper {
  padding: 12px 24px; }

.app-import-panel .app-studio-edit-link {
  color: #fff;
  text-decoration: none;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center; }

.shadow-control {
  margin-top: 15px;
  position: relative; }
  .shadow-control .blocking-layer {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute; }

.settings-panel.ui-lib-text-design-panel {
  width: 288px; }
  .settings-panel.ui-lib-text-design-panel .color-picker-header {
    position: relative;
    cursor: move;
    border-bottom: none;
    padding: 0 24px; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .color-picker-header-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 47px;
      line-height: 47px;
      margin-right: 21px; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back:not(.btn-header-back) {
      text-align: left;
      padding-left: 9px;
      width: auto;
      margin-left: 0; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back:not(.btn-header-back) > svg {
        transform: translateY(-50%);
        margin-right: 12px; }
        .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back:not(.btn-header-back) > svg > path {
          color: #5c99e9; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button {
      position: absolute;
      top: calc(50% - (21px / 2));
      right: 14px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: #d6e6fe;
      cursor: pointer; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button svg {
        width: 21px;
        height: 21px;
        fill: #116dff; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button:hover {
        background-color: #e7f0ff; }
  .settings-panel.ui-lib-text-design-panel .style-options {
    width: 130px;
    padding: 20px 24px; }
    .settings-panel.ui-lib-text-design-panel .style-options .icon-button-container {
      margin-right: 10px; }
  .settings-panel.ui-lib-text-design-panel .text-style-dd .style-option-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .settings-panel.ui-lib-text-design-panel .accessibility-section {
    border-bottom: 0; }
    .settings-panel.ui-lib-text-design-panel .accessibility-section .accordion-section-container {
      padding: 0; }

.section-already-installed .composite-rich-text-with-illustration > .control-illustration {
  width: 80px;
  padding: 24px;
  text-align: center; }
  .section-already-installed .composite-rich-text-with-illustration > .control-illustration img {
    width: 60px;
    height: 60px;
    border-radius: 4px; }

.tpa-new-page-added {
  width: 498px;
  background: #fff;
  border-radius: 4px; }
  .tpa-new-page-added .content {
    padding: 76px 48px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    color: #000624;
    line-height: 22px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .tpa-new-page-added .content .ok-btn {
      margin: 13px 0 48px;
      float: right; }
      .tpa-new-page-added .content .ok-btn button {
        height: 30px;
        line-height: 30px;
        padding: 0 22px; }
  .tpa-new-page-added .panel-header {
    padding: 0;
    height: 167px; }
    .tpa-new-page-added .panel-header.close {
      top: 19px;
      height: 25px; }
    .tpa-new-page-added .panel-header button.close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px;
      z-index: 1; }
    .tpa-new-page-added .panel-header .panel-header-title {
      max-width: 100%;
      white-space: normal;
      text-align: center;
      font-size: 20px;
      padding-top: 36px;
      margin-left: -24px; }
      .tpa-new-page-added .panel-header .panel-header-title .title-text {
        color: #fff;
        font-weight: 300;
        font-size: 20px;
        line-height: 31px;
        display: inline-block;
        margin-top: 7px;
        width: 84%; }
      .tpa-new-page-added .panel-header .panel-header-title .bold {
        font-weight: bold; }
    .tpa-new-page-added .panel-header .symbol-newPageAdded {
      position: absolute;
      height: 129px;
      width: 176px;
      left: calc(50% - 88px);
      top: 115px; }

.tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation,
.tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  width: 574px; }
  .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content,
  .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content {
    margin: 0;
    padding: 24px 24px 0 24px;
    background-color: #fff; }
    .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper,
    .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper {
      width: 100%; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper .icon,
      .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper .icon {
        width: 150px;
        height: initial;
        float: left;
        text-align: center; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper .icon .symbol-deleteConfirmationIcon,
        .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper .icon .symbol-deleteConfirmationIcon {
          margin-left: -20px; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content,
      .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper > .content {
        width: calc(100% - 173px);
        float: left;
        margin-right: 0;
        color: #000624; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content p,
        .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper > .content p {
          margin-bottom: 12px;
          margin-top: 0; }
          .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content p a:hover,
          .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper > .content p a:hover {
            text-decoration: underline; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content :last-child,
        .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > .content .content-wrapper > .content :last-child {
          margin-bottom: 0; }
  .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer,
  .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > footer {
    background-color: #fff;
    position: static;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    text-align: right; }
    .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer > button,
    .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > footer > button {
      margin-right: 18px; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer > button:last-child,
      .tpa-refresh-for-upgrade-confirmation-wrapper .confirmation-panel-frame.normal-confirmation > footer > button:last-child {
        margin-right: 0; }

.add-ecom {
  width: 498px;
  background: #fff;
  border-radius: 4px; }
  .add-ecom .content {
    padding: 57px 26px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #000624;
    line-height: 22px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .add-ecom .content .ok-btn {
      margin: 13px 0 20px;
      float: right; }
      .add-ecom .content .ok-btn button {
        height: 30px;
        line-height: 30px;
        padding: 0 22px; }
  .add-ecom .panel-header {
    padding: 0;
    height: 57px; }
    .add-ecom .panel-header.close {
      top: 19px;
      height: 25px; }
    .add-ecom .panel-header button.close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px;
      z-index: 1; }
    .add-ecom .panel-header .panel-header-title {
      max-width: 100%;
      white-space: normal;
      text-align: center;
      font-size: 20px;
      padding-top: 36px;
      margin-left: -24px; }
      .add-ecom .panel-header .panel-header-title .title-text {
        color: #fff;
        font-weight: 300;
        font-size: 20px;
        line-height: 31px;
        display: inline-block;
        margin-top: 7px;
        width: 84%; }
      .add-ecom .panel-header .panel-header-title .bold {
        font-weight: bold; }
    .add-ecom .panel-header .symbol-newPageAdded {
      position: absolute;
      height: 71px;
      width: 176px;
      left: calc(50% - 88px);
      top: 13px; }

.benefits-modal {
  width: 498px; }
  .benefits-modal .custom-header {
    height: 83px;
    background-color: #116dff; }
    .benefits-modal .custom-header .panel-header-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      color: #fff;
      font-size: 24px;
      position: relative;
      top: -28px;
      max-width: calc(100% - 85px);
      margin: 0 auto; }
    .benefits-modal .custom-header .symbol-messageConfetti {
      position: absolute;
      left: calc(50% - 93px);
      top: 92px; }
    .benefits-modal .custom-header .symbol-messageShadow {
      position: absolute;
      left: calc(50% - 44px);
      top: 146px; }
    .benefits-modal .custom-header .app-icon {
      height: 60px;
      width: 60px;
      background-size: contain;
      border-radius: 9px;
      position: absolute;
      left: calc(50% - 30px);
      top: 98px;
      box-shadow: 0 0 10px #fff;
      background-color: #fff; }
  .benefits-modal .content {
    text-align: center;
    background-color: #fff;
    padding-bottom: 40px; }
    .benefits-modal .content .benefit-list {
      color: #3b4057;
      margin: 65px 0 0;
      font-size: 16px;
      display: inline-block;
      max-width: 80%; }
      .benefits-modal .content .benefit-list li {
        position: relative;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        line-height: 20px;
        text-align: left;
        margin-bottom: 11px; }
      .benefits-modal .content .benefit-list svg {
        position: absolute;
        top: 7px; }
      .benefits-modal .content .benefit-list span {
        display: inline-block;
        margin-left: 28px; }
    .benefits-modal .content .price {
      font-size: 18px;
      text-align: center;
      margin: 34px 0; }
    .benefits-modal .content .actions {
      text-align: center; }
      .benefits-modal .content .actions .btn-later {
        background-color: #eedbf4;
        color: #9a27d5;
        margin-right: 18px; }
        .benefits-modal .content .actions .btn-later:hover {
          background-color: #e5c9ee; }
        .benefits-modal .content .actions .btn-later:active {
          background-color: #eedbf4; }
      .benefits-modal .content .actions .btn-upgrade {
        background-color: #9a27d5; }
        .benefits-modal .content .actions .btn-upgrade:hover {
          background-color: #c161f0; }
        .benefits-modal .content .actions .btn-upgrade:active {
          background-color: #9a27d5; }

.base-tpa-panel {
  position: relative; }
  .base-tpa-panel .x-button {
    -webkit-user-select: none;
    user-select: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 9px;
    height: 10px;
    line-height: 4px;
    padding: 20px;
    cursor: pointer;
    z-index: 1; }

.picker-header {
  height: 74px;
  width: 100%;
  border-bottom: 1px solid #dad9d9;
  background-color: #fff;
  border-top: 12px solid #fff;
  box-sizing: border-box; }
  .picker-header .app-logo {
    height: 32px;
    width: 32px;
    background-size: contain;
    margin: 7px 0 0 24px;
    display: inline-block; }
  .picker-header .app-textual-info {
    position: absolute;
    top: 21px;
    left: 70px;
    border-left: 1px solid #dfdfdf;
    padding-left: 14px;
    height: 32px;
    color: #555;
    font-size: 13px;
    width: 170px; }
    .picker-header .app-textual-info .app-name {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      position: absolute;
      top: 0; }
    .picker-header .app-textual-info .app-owner {
      position: absolute;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      bottom: 0; }
  .picker-header .upgrade-text {
    position: absolute;
    top: 9px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #363636;
    line-height: 62px; }

.panel-header.cycle-picker {
  height: 84px; }
  .panel-header.cycle-picker .close {
    top: 29px;
    right: 19px; }
  .panel-header.cycle-picker .app-logo {
    height: 46px;
    width: 46px;
    background-size: contain;
    margin: 19px 19px 19px 24px;
    display: inline-block;
    border-radius: 7px;
    position: relative;
    box-shadow: 0 0 26px 2px rgba(255, 255, 255, 0.32); }
  .panel-header.cycle-picker .panel-header-title {
    display: inline-block;
    max-width: 400px;
    height: 84px;
    line-height: 84px;
    position: absolute;
    padding-left: 0; }
  .panel-header.cycle-picker span {
    position: relative; }

.cycles-modal-tooltip-content-wrapper {
  max-width: 280px;
  display: inline-block;
  white-space: initial; }

.cycles-modal {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  height: 663px;
  width: 757px; }
  .cycles-modal .wrapper {
    background-color: #fff;
    height: 582px; }
    .cycles-modal .wrapper .tableWrapper {
      width: 713px;
      padding: 44px 44px 44px 0;
      background-color: #ffffff;
      display: inline-block; }
      .cycles-modal .wrapper .tableWrapper div,
      .cycles-modal .wrapper .tableWrapper span {
        box-sizing: border-box; }
      .cycles-modal .wrapper .tableWrapper .header {
        color: #162d3c;
        vertical-align: bottom;
        text-align: right; }
        .cycles-modal .wrapper .tableWrapper .header .free {
          width: 172px;
          height: 46px;
          text-align: center;
          display: inline-block;
          border: 1px solid #dfe5eb;
          border-right: none;
          border-radius: 5px 0 0 0;
          font-size: 16px;
          line-height: 46px; }
        .cycles-modal .wrapper .tableWrapper .header .premium {
          width: 288px;
          height: 66px;
          text-align: center;
          display: inline-block;
          border: 1px solid #9a27d5;
          border-bottom: none;
          border-radius: 5px 5px 0 0;
          font-size: 20px;
          line-height: 66px;
          position: relative; }
          .cycles-modal .wrapper .tableWrapper .header .premium:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            border-bottom: 1px solid #dfe5eb; }
      .cycles-modal .wrapper .tableWrapper .feature-table-wrapper {
        display: block;
        width: calc(100% + 20px);
        padding-right: 20px; }
        .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature {
          text-align: right;
          vertical-align: middle;
          height: 36px;
          line-height: 36px;
          overflow: hidden; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature > * {
            height: 100%;
            float: right;
            font-size: 14px;
            font-weight: 500; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .symbol {
            padding: 12px; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .title {
            width: 212px;
            font-size: 13px;
            font-weight: normal;
            color: #3b4057;
            text-align: right;
            padding-right: 23px;
            cursor: default; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .basic {
            width: 172px;
            height: 36px;
            border: solid #dfe5eb;
            border-width: 0 0 1px 1px;
            text-align: center; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .premium {
            width: 288px;
            border-style: solid;
            border-color: #9a27d5;
            border-width: 0 1px 0 1px;
            text-align: center;
            position: relative; }
            .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .premium:after {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              bottom: 0;
              border-bottom: 1px solid #dfe5eb; }
        .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature-display:nth-child(n + 8) {
          display: none; }
      .cycles-modal .wrapper .tableWrapper .cycle-modal-footer {
        margin-top: -1px;
        position: relative;
        height: 176px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade {
          float: right;
          width: 288px;
          height: 174px;
          text-align: center;
          border: 1px solid #9a27d5;
          border-top: none;
          border-radius: 0 0 5px 5px;
          position: relative; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            border-bottom: 1px solid #dfe5eb; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options {
            width: 100%;
            display: flex;
            justify-content: center; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options label {
              text-align: center;
              cursor: pointer;
              display: inline-block;
              max-width: 50%;
              margin: 20px 0 12px 0; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options label:nth-child(2):last-child {
                margin-left: 20px; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input {
              display: none;
              font-size: initial; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div {
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #d6e6fe;
                background-color: #fff;
                min-height: 90px;
                width: 112px;
                position: relative; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .text-node {
                  font-size: 14px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .circle {
                  border-radius: 50%;
                  border: 1px solid #d6e6fe;
                  background-color: #fff;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: -10px;
                  left: -10px; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .circle svg {
                    display: none;
                    margin: 5px; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .title {
                  color: #000624;
                  font-size: 14px;
                  font-weight: 300;
                  text-align: center;
                  margin-top: 8px; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .save {
                  color: #9a27d5;
                  font-size: 12px;
                  font-weight: 500;
                  text-align: center;
                  margin-top: 4px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover {
                background-color: #e7f0ff; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover .circle {
                  background-color: #e7f0ff; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover .circle svg {
                    display: none; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div {
                background-color: #e7f0ff; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div .circle {
                  background-color: #e7f0ff; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div .circle svg {
                    display: block; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price {
              font-size: 16px;
              color: #000624;
              text-align: center;
              font-weight: 500;
              display: inline-block;
              left: 9px;
              position: absolute;
              width: 100%; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .currency {
                font-size: 10px;
                top: -11px;
                display: inline-block;
                position: relative;
                margin-right: 4px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .price-whole-number {
                font-size: 24px;
                line-height: 24px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .price-fragment-number {
                width: 13px;
                top: -13px;
                display: inline-block;
                position: relative;
                font-size: 10px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .month {
                position: relative;
                left: -17px;
                font-size: 12px;
                margin-left: 8px; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price-monthly,
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price-yearly {
              top: 60px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .basic-bottom-border {
          float: right;
          height: 1px;
          width: 172px;
          box-sizing: border-box;
          border-top: 1px solid #dfe5eb; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .features {
          color: #116dff;
          font-size: 14px;
          font-weight: 300;
          line-height: 40px;
          text-align: right;
          box-sizing: border-box;
          position: absolute;
          cursor: pointer;
          width: 253px;
          padding-right: 22px; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .features svg {
            margin-left: 10px;
            margin-bottom: 2px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn {
          background-color: #9a27d5;
          border-radius: 4px;
          height: 38px;
          width: 137px;
          box-shadow: 1px 2px 0 0 #9936ba;
          cursor: pointer;
          padding: 0 18px;
          max-width: 193px; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn:hover {
            background-color: #b85ad6; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn:active {
            background-color: #9a27d5; }
      .cycles-modal .wrapper .tableWrapper .cancel-btn {
        bottom: 42px;
        left: 45px;
        color: #116dff;
        font-size: 16px;
        box-sizing: border-box;
        width: 50px;
        height: 22px;
        position: absolute;
        cursor: pointer; }
        .cycles-modal .wrapper .tableWrapper .cancel-btn:hover {
          text-decoration: underline; }

.package-picker-modal-tooltip-content-wrapper {
  max-width: 295px;
  display: block;
  padding: 24px;
  width: initial; }

.package-picker-container {
  font-smoothing: initial;
  width: 870px;
  background-repeat: no-repeat;
  border-radius: 5px;
  letter-spacing: 0.5px; }
  .package-picker-container .packages {
    height: 502px;
    width: 100%;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    background-color: #fff;
    text-align: center;
    padding: 44px 0 0;
    z-index: 0; }
    .package-picker-container .packages button {
      color: #fff;
      background-color: #9a27d5;
      margin: 10px auto 0;
      width: 137px;
      height: 38px;
      font-size: 15px;
      text-align: center;
      border-radius: 4px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      border: none;
      letter-spacing: 0.5px;
      line-height: 39px;
      box-shadow: 0 2px 0 #9936ba; }
      .package-picker-container .packages button span {
        margin-top: -2px;
        display: block; }
      .package-picker-container .packages button:focus {
        outline: none; }
      .package-picker-container .packages button:hover {
        background-color: #bf60d6;
        cursor: pointer; }
    .package-picker-container .packages .radio-button {
      border: 1px solid #5c5a5a;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: inline-block; }
      .package-picker-container .packages .radio-button:hover {
        cursor: pointer; }
    .package-picker-container .packages .selected .radio-button:after {
      content: '';
      height: 8px;
      width: 8px;
      margin: 1px;
      background-color: #9a27d5;
      border-radius: 50px;
      display: block; }
    .package-picker-container .packages .packages-wrapper {
      direction: rtl;
      background-color: #ffffff;
      display: inline-block; }
    .package-picker-container .packages .package {
      position: relative;
      width: 193px;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 44px;
      direction: ltr; }
      .package-picker-container .packages .package .plane {
        font-size: 13px;
        border: 1px solid #dad9d9;
        border-width: 0 1px 1px 1px; }
        .package-picker-container .packages .package .plane.yearly {
          background-color: #fff;
          height: 210px; }
        .package-picker-container .packages .package .plane.monthly {
          background-color: #fafafa;
          height: 0;
          border-bottom-width: 0;
          transition: height 0s;
          overflow: hidden; }
          .package-picker-container .packages .package .plane.monthly .price {
            margin-bottom: 21px; }
          .package-picker-container .packages .package .plane.monthly.monthly-open {
            overflow: inherit;
            height: 164px;
            border-bottom-width: 1px; }
          .package-picker-container .packages .package .plane.monthly.no-cycles {
            height: 210px;
            background-color: #fff; }
        .package-picker-container .packages .package .plane .original-price {
          color: #868686;
          text-decoration: line-through;
          padding: 4px; }
          .package-picker-container .packages .package .plane .original-price .currency {
            font-size: 12px; }
        .package-picker-container .packages .package .plane .you-save {
          color: #449fed;
          margin-top: 2px;
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
          .package-picker-container .packages .package .plane .you-save .discount-percent {
            margin-left: 3px; }
        .package-picker-container .packages .package .plane .best-selling-feature {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          letter-spacing: 0.5px;
          padding: 5px;
          border: solid #ececec;
          border-width: 1px 0;
          width: 80%;
          margin: 11px auto 14px;
          color: #363636; }
        .package-picker-container .packages .package .plane .select-package button {
          display: none; }
        .package-picker-container .packages .package .plane .select-package.selected button {
          display: block; }
        .package-picker-container .packages .package .plane.disabled .select-package button,
        .package-picker-container .packages .package .plane.disabled .select-package .radio-button {
          display: none; }
      .package-picker-container .packages .package header {
        margin-top: 9px;
        height: 67px;
        line-height: 85px;
        color: #fff;
        background-color: #449fed; }
        .package-picker-container .packages .package header .package-name {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 19px;
          letter-spacing: 0.3px;
          line-height: 67px; }
      .package-picker-container .packages .package:last-child {
        right: -1px; }
        .package-picker-container .packages .package:last-child header {
          border-radius: 5px 0 0 0; }
        .package-picker-container .packages .package:last-child .plane {
          position: relative; }
        .package-picker-container .packages .package:last-child .features {
          border-left: 1px solid #dfdfdf; }
      .package-picker-container .packages .package.popular {
        z-index: 1;
        box-shadow: rgba(140, 138, 138, 0.4) 0 0 6px;
        width: 226px;
        border-radius: 5px 5px 0 0; }
        .package-picker-container .packages .package.popular .plane .you-save {
          color: #9a27d5; }
        .package-picker-container .packages .package.popular header {
          margin-top: 0;
          padding-top: 9px;
          background-color: #9a27d5;
          border-radius: 5px 5px 0 0;
          position: relative; }
          .package-picker-container .packages .package.popular header .badge {
            display: block;
            width: 61px;
            height: 61px;
            background-position: 0 -72px;
            position: absolute;
            top: -4px;
            right: -4px; }
            .package-picker-container .packages .package.popular header .badge .badge-caption {
              font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
              font-weight: bold;
              display: block;
              transform: rotate(45deg);
              position: absolute;
              line-height: 17px;
              height: 17px;
              width: 85px;
              right: -20px;
              top: 16px;
              color: #000;
              font-size: 9.5px;
              letter-spacing: 0.5px; }
        .package-picker-container .packages .package.popular .features {
          width: 224px; }
      .package-picker-container .packages .package:first-child {
        left: -1px; }
        .package-picker-container .packages .package:first-child header {
          border-radius: 0 5px 0 0; }
      .package-picker-container .packages .package .features {
        border-right: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf; }
        .package-picker-container .packages .package .features .feature {
          height: 34px;
          line-height: 34px;
          padding: 0 10px;
          background-color: #fff;
          color: #363636;
          font-size: 12px;
          position: relative; }
          .package-picker-container .packages .package .features .feature:nth-child(even) {
            background-color: #f1f6fb; }
          .package-picker-container .packages .package .features .feature.highlighted {
            font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-weight: bold; }
          .package-picker-container .packages .package .features .feature:last-child {
            border-bottom: 1px solid #dfdfdf; }
      .package-picker-container .packages .package .price {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        color: #393939;
        font-size: 13px;
        padding: 19px 0 0 17px; }
        .package-picker-container .packages .package .price .currency {
          vertical-align: top;
          line-height: 32px;
          margin-right: 3px; }
        .package-picker-container .packages .package .price .yearly-per-month-whole {
          font-size: 38px;
          letter-spacing: -1.2px; }
        .package-picker-container .packages .package .price .yearly-per-month-fragment {
          width: 13px;
          top: -17px;
          display: inline-block;
          position: relative;
          margin-left: 4px; }
        .package-picker-container .packages .package .price .per-month {
          position: relative;
          left: -11px; }
      .package-picker-container .packages .package.plane-cycles {
        position: relative;
        display: none;
        width: 162px;
        margin-top: 75px;
        vertical-align: top;
        margin-right: -1px;
        z-index: 1; }
        .package-picker-container .packages .package.plane-cycles .best-value-badge {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 10px;
          color: #0d0d0d;
          position: absolute;
          height: 39px;
          width: 57px;
          top: -8px;
          left: 15px;
          z-index: 1; }
          .package-picker-container .packages .package.plane-cycles .best-value-badge > svg {
            position: absolute;
            left: 0; }
          .package-picker-container .packages .package.plane-cycles .best-value-badge > div {
            position: absolute;
            text-align: center;
            overflow: hidden;
            height: 100%;
            margin-left: 13%;
            margin-top: 9%;
            font-weight: bold; }
        .package-picker-container .packages .package.plane-cycles .cycle-title {
          font-size: 36px;
          margin-top: 42px;
          color: #000;
          letter-spacing: 0;
          padding-left: 15px; }
        .package-picker-container .packages .package.plane-cycles .cycle-subtitle {
          font-size: 15px;
          padding-left: 15px;
          text-align: left;
          color: #000; }
        .package-picker-container .packages .package.plane-cycles .yearly {
          height: 210px;
          border: 1px solid #dfdfdf;
          border-right: none;
          border-radius: 5px 0 0 0;
          background-color: #fff;
          position: relative; }
          .package-picker-container .packages .package.plane-cycles .yearly .cycle-subtitle {
            text-align: left; }
          .package-picker-container .packages .package.plane-cycles .yearly .cycle-title {
            text-align: left; }
          .package-picker-container .packages .package.plane-cycles .yearly .open-monthly {
            width: 100%;
            display: block;
            transition: height 0s;
            position: absolute;
            bottom: 15px;
            text-align: left;
            padding-left: 15px; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly .arrow {
              display: inline-block;
              width: 16px;
              height: 14px;
              position: relative;
              top: 2px;
              margin-right: 5px; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly .caption {
              line-height: 10px;
              font-size: 15px;
              color: #000; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly.monthly-open {
              display: none; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly:hover {
              cursor: pointer; }
        .package-picker-container .packages .package.plane-cycles .monthly {
          background-color: #fafafa;
          height: 0;
          border-bottom-width: 0;
          transition: height 0s;
          border-radius: 0 0 0 5px;
          position: relative;
          border: 1px solid #dfdfdf;
          border-right: none;
          display: none; }
          .package-picker-container .packages .package.plane-cycles .monthly .cycle-subtitle {
            text-align: left;
            overflow: hidden; }
          .package-picker-container .packages .package.plane-cycles .monthly .cycle-title {
            margin-top: 0;
            padding-top: 20px;
            text-align: left;
            overflow: hidden; }
          .package-picker-container .packages .package.plane-cycles .monthly.monthly-open {
            display: block;
            height: 164px;
            border-top: none; }
        .package-picker-container .packages .package.plane-cycles.has-cycles {
          display: inline-block; }
        .package-picker-container .packages .package.plane-cycles .yearly:before,
        .package-picker-container .packages .package.plane-cycles .monthly:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -13px;
          border: solid transparent;
          border-width: 16px 8px;
          border-left-color: #dfdfdf;
          right: -17px; }
        .package-picker-container .packages .package.plane-cycles .yearly:after,
        .package-picker-container .packages .package.plane-cycles .monthly:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -13px;
          border: solid transparent;
          border-width: 16px 8px;
          border-left-color: #fff;
          right: -16px; }
        .package-picker-container .packages .package.plane-cycles .monthly:after {
          border-left-color: #fafafa; }

.super-app-first-time-tour-panel {
  border-radius: 0 4px 4px 0;
  height: calc( 100vh - var(--g-top-bar-height) - 64px);
  max-height: 626px;
  overflow: hidden;
  position: relative;
  width: 522px;
  z-index: 0; }
  .super-app-panel-new-workspace .super-app-first-time-tour-panel {
    height: 100%;
    max-height: none; }
  .classic-facelift-skin .super-app-first-time-tour-panel {
    border-radius: 0; }

.get-started-slide {
  text-align: center;
  color: #395671;
  width: 522px;
  height: 100%; }
  .get-started-slide .button {
    border-radius: 50%;
    background: #d0e9fa;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 14px;
    display: inline-block; }
    .get-started-slide .button path {
      fill: #116dff; }
    .get-started-slide .button .collapsed {
      display: none; }
    .get-started-slide .button.close {
      margin-right: 14px; }
    .get-started-slide .button:hover {
      background-color: #e7f0ff; }
  .get-started-slide .main-image {
    margin-top: 36px;
    margin-bottom: 14px; }
  .get-started-slide img {
    display: inline-block;
    margin: 20px auto 20px auto; }
  .get-started-slide .title {
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
    font-weight: 300; }
  .get-started-slide .sub-title {
    font-size: 16px;
    line-height: 17px;
    margin: 10px 20px; }
  .get-started-slide .get-started-add-btn {
    margin: 0 auto 24px auto;
    display: block;
    height: 36px; }
  .get-started-slide .teaser {
    margin: 40px auto 20px auto; }
  .get-started-slide .bullets-wrapper {
    margin-bottom: 10px; }
  .get-started-slide .tip-bullets {
    text-align: left;
    margin: 0 72px;
    display: inline-block; }
    .get-started-slide .tip-bullets .bullet {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
      margin: 18px 0; }
      .get-started-slide .tip-bullets .bullet .bullet-symbol {
        float: left; }
      .get-started-slide .tip-bullets .bullet .bullet-text {
        padding-left: 20px;
        margin: 0; }
  .get-started-slide footer {
    background: #ffffff;
    min-height: 54px;
    max-height: 90px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    border-top: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center; }
    .get-started-slide footer > * {
      display: inline-block;
      margin-right: 3px;
      padding: 0 54px; }
      .get-started-slide footer > * > a {
        text-decoration: none;
        color: #116dff; }
  .super-app-panel-new-workspace .get-started-slide {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .super-app-panel-new-workspace .get-started-slide .slides-container {
      flex: 1 0 auto; }
    .super-app-panel-new-workspace .get-started-slide .button {
      background: #fff; }
      .super-app-panel-new-workspace .get-started-slide .button path {
        fill: #000624; }
      .super-app-panel-new-workspace .get-started-slide .button:hover {
        background: #fff; }
    .super-app-panel-new-workspace .get-started-slide footer {
      flex-shrink: 0; }

.first-time-install-app {
  text-align: center;
  color: #395671; }
  .first-time-install-app .close {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #116dff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    z-index: 3; }
    .first-time-install-app .close:hover {
      background-color: #e7f0ff; }
    .first-time-install-app .close:active {
      background-color: #d6e6fe; }
    .first-time-install-app .close.white {
      background-color: #fff; }
      .first-time-install-app .close.white:hover {
        background-color: #e7f0ff; }
      .first-time-install-app .close.white:active {
        background-color: #fff; }
      .super-app-panel-new-workspace .first-time-install-app .close.white {
        background-color: rgba(255, 255, 255, 0.5); }
        .super-app-panel-new-workspace .first-time-install-app .close.white:hover, .super-app-panel-new-workspace .first-time-install-app .close.white:active {
          background-color: rgba(255, 255, 255, 0.5); }
    .super-app-panel-new-workspace .first-time-install-app .close {
      fill: #000624;
      top: 12px;
      right: 12px; }
  .first-time-install-app .pre-install-section .inner-image-gallery {
    width: 522px; }
  .first-time-install-app .pre-install-section .title {
    font-size: 30px;
    line-height: 38px;
    margin-top: 0;
    margin-bottom: 3px;
    font-weight: normal;
    white-space: pre-line; }
  .first-time-install-app .pre-install-section .sub-title,
  .first-time-install-app .pre-install-section .helper-text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 20px; }
  .first-time-install-app .pre-install-section .helper-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    margin-top: 18px;
    margin-bottom: 0; }
  .first-time-install-app .pre-install-section .tip-bullets {
    text-align: left;
    margin: 0 20px;
    display: inline-block; }
    .first-time-install-app .pre-install-section .tip-bullets .bullet {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      text-align: left;
      margin-bottom: 14px; }
      .first-time-install-app .pre-install-section .tip-bullets .bullet .bullet-symbol {
        float: left;
        margin-right: 14px; }
      .first-time-install-app .pre-install-section .tip-bullets .bullet .bullet-text {
        padding-left: 32px;
        margin: 0; }
  .first-time-install-app .pre-install-section svg.symbol-plus rect {
    fill: #ffffff; }
  .first-time-install-app .pre-install-section .promotional-add-btn {
    margin: 20px auto 0 auto;
    display: block;
    width: 192px; }
  .first-time-install-app .pre-install-section .promotional-link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    margin-top: 20px; }
  .first-time-install-app .pre-install-section a {
    margin: 0 auto 25px auto;
    color: #116dff;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    display: inline-block; }
    .first-time-install-app .pre-install-section a:hover {
      text-decoration: underline; }
  .first-time-install-app .pre-install-section footer {
    height: 45px;
    font-size: 14px;
    line-height: 22px;
    border-top: 1px solid #d5d5d5; }
    .first-time-install-app .pre-install-section footer > * {
      display: inline-block;
      margin-right: 3px; }
    .first-time-install-app .pre-install-section footer > a {
      text-decoration: none;
      color: #116dff;
      font-size: 14px; }
  .first-time-install-app .install-section {
    margin: 0 auto;
    width: 522px;
    padding-bottom: 225px; }
    .first-time-install-app .install-section .installed-image {
      margin: 40px auto 30px auto; }
    .first-time-install-app .install-section p {
      text-align: center;
      font-size: 30px; }
    .first-time-install-app .install-section .install-preloader {
      margin-top: 80px; }
  .first-time-install-app .banner {
    width: 100%;
    margin-bottom: 14px; }

.welcome-slide {
  text-align: center;
  color: #395671;
  margin: 0 auto;
  width: 522px; }
  .welcome-slide .close {
    position: absolute;
    top: 15px;
    right: 20px;
    border-radius: 50%;
    background-color: #d6e6fe;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: 1px solid transparent; }
    .welcome-slide .close path {
      fill: #116dff; }
    .welcome-slide .close:hover {
      background-color: #e7f0ff; }
    .welcome-slide .close:active {
      background-color: #d6e6fe; }
    .welcome-slide .close.white {
      background-color: #fff; }
      .welcome-slide .close.white:hover {
        background-color: #e7f0ff;
        border: 1px solid #80b1ff; }
      .welcome-slide .close.white:active {
        background-color: #fff;
        border: 1px solid #116dff; }
  .welcome-slide .installed-image {
    margin: 36px auto 14px auto; }
  .welcome-slide .title {
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 13px;
    font-weight: 300; }
  .welcome-slide .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    padding: 0 73px;
    text-align: center; }
  .welcome-slide .welcome-add-btn {
    margin: 40px auto 20px auto;
    display: block;
    width: 197px; }

.inner-image-gallery .gallery-images {
  overflow: hidden;
  white-space: nowrap;
  position: relative; }
  .inner-image-gallery .gallery-images .slide {
    opacity: 0;
    display: inline-block;
    width: 100%; }
    .inner-image-gallery .gallery-images .slide.left, .inner-image-gallery .gallery-images .slide.center, .inner-image-gallery .gallery-images .slide.right {
      transition: all 0.45s ease-out;
      position: absolute;
      top: 0;
      left: 0; }
    .inner-image-gallery .gallery-images .slide.left {
      transform: translate3d(-100%, 0, 0); }
    .inner-image-gallery .gallery-images .slide.center {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
      .inner-image-gallery .gallery-images .slide.center.relative {
        position: relative; }
    .inner-image-gallery .gallery-images .slide.right {
      transform: translate3d(100%, 0, 0); }
    .inner-image-gallery .gallery-images .slide img {
      width: 100%; }

.inner-image-gallery .gallery-navigation-wrapper {
  margin: 19px auto 20px auto; }
  .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet {
    border-radius: 50%;
    background-color: #d6e6fe;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    display: inline-block;
    cursor: pointer; }
    .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet:hover {
      background-color: #80b1ff; }
    .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet.active {
      background-color: #116dff; }

.super-app-panel {
  background: #fff; }

.app-manager-panel .super-app-menu-panel > .tabs {
  background: #5a48f5; }

.app-manager-panel-new .super-app-menu-panel > .tabs {
  background: #1b3689; }

.am-leftBarAppManagerFitnessIcon .super-app-menu-panel > .tabs {
  background: #5a48f5; }

.pages-panel-pp .super-app-menu-panel > .tabs {
  background: #00aaaf; }

.theme-panel .super-app-menu-panel > .tabs {
  background: #003790; }

.bg-panel .super-app-menu-panel > .tabs {
  background: #f8a239; }

.add-panel .super-app-menu-panel > .tabs {
  background: #3899ec; }

.app-market-panel .super-app-menu-panel > .tabs {
  background: #d0427c; }

.app-market-panel-20 .super-app-menu-panel > .tabs {
  background: #d0427c; }

.app-market-panel-new .super-app-menu-panel > .tabs {
  background: #d0427c; }

.app-market-panel-no-header .super-app-menu-panel > .tabs {
  background: #d0427c; }

.my-uploads-panel .super-app-menu-panel > .tabs {
  background: #fa7c33; }

.media-manager-panel .super-app-menu-panel > .tabs {
  background: #fa7c33; }

.media-manager-panel-icon-b .super-app-menu-panel > .tabs {
  background: #fa7c33; }

.media-manager-panel-icon-c .super-app-menu-panel > .tabs {
  background: #fa7c33; }

.wix-data-panel .super-app-menu-panel > .tabs {
  background: #00A65F; }

.wix-data-panel-icon-b .super-app-menu-panel > .tabs {
  background: #00A65F; }

.interactions-hidden-components .super-app-menu-panel > .tabs {
  background: #d0427c; }

.blog-panel .super-app-menu-panel > .tabs {
  background: #25a55a; }

.new-blog-panel .super-app-menu-panel > .tabs {
  background: #faad4d; }

.wix-store-panel .super-app-menu-panel > .tabs {
  background: #00adf5; }

.wix-bookings-panel .super-app-menu-panel > .tabs {
  background: #6C48EF; }

.hidden-items-panel .super-app-menu-panel > .tabs {
  background: #d0427d; }

.mobile-elements-panel .super-app-menu-panel > .tabs {
  background: #3899eb; }

.layout-optimizer-panel .super-app-menu-panel > .tabs {
  background: #45c8c1; }

.ascend-panel .super-app-menu-panel > .tabs {
  background: #1463DA; }

.super-app-menu-panel {
  display: flex;
  height: calc( 100vh - var(--g-top-bar-height) - 64px);
  white-space: nowrap; }
  .super-app-menu-panel:not(.dynamic-height) {
    max-height: 626px; }
  .super-app-menu-panel > .tabs {
    flex-shrink: 0;
    vertical-align: top;
    height: calc(100% - 30px);
    padding: 15px 7px 15px 0;
    color: #ffffff; }
    .super-app-menu-panel > .tabs .custom-header {
      margin-left: 19px; }
    .super-app-menu-panel > .tabs .tab {
      font-size: 14px;
      cursor: pointer;
      max-height: 30px;
      min-height: 14px;
      padding: 0 4px 0 5px;
      position: relative;
      margin-bottom: 4px; }
      .super-app-menu-panel > .tabs .tab .tabNameWrapper {
        position: relative;
        height: 30px;
        display: inline-block;
        border-radius: 20px;
        overflow: hidden;
        padding: 0 14px; }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper.isSelected {
          background-color: rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
          .new-blog-panel .super-app-menu-panel > .tabs .tab .tabNameWrapper.isSelected {
            background-color: #e78e1f; }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper:not(.isSelected):hover {
          background-color: rgba(0, 0, 0, 0.07);
          box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
          .new-blog-panel .super-app-menu-panel > .tabs .tab .tabNameWrapper:not(.isSelected):hover {
            background-color: rgba(231, 142, 31, 0.5); }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper .tabName {
          display: inline-block;
          position: relative;
          height: 14px;
          top: 7px; }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper .dotIndicator {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #e62214;
          margin-left: 4px;
          margin-bottom: 5px; }
    .super-app-menu-panel > .tabs .separator {
      width: calc(100% - 35px);
      background: #dfe5eb;
      margin: 14px auto 21px auto;
      height: 1px; }
    .super-app-menu-panel > .tabs .premium {
      margin: 0 17px 25px 18px;
      cursor: pointer;
      white-space: pre-wrap; }
      .super-app-menu-panel > .tabs .premium:hover, .super-app-menu-panel > .tabs .premium.selected {
        text-decoration: underline; }
  .super-app-menu-panel > .content {
    flex-grow: 1;
    background: #fff;
    height: 100%;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    width: 354px; }
    .super-app-menu-panel > .content.inner-navigation-open {
      border-top-right-radius: 0; }
    .super-app-menu-panel > .content .custom-scroll > .outer-container {
      position: static; }
    .super-app-menu-panel > .content > .selected-content-wrapper {
      white-space: normal;
      height: 100%; }
      .super-app-menu-panel > .content > .selected-content-wrapper:not(.dynamic-height) > *, .super-app-menu-panel > .content > .selected-content-wrapper.dynamic-height {
        height: calc( 100% - 55px); }
        .super-app-menu-panel > .content > .selected-content-wrapper:not(.dynamic-height) > * > *, .super-app-menu-panel > .content > .selected-content-wrapper.dynamic-height > * {
          height: 100%; }
        .super-app-panel-new-workspace .super-app-menu-panel > .content > .selected-content-wrapper:not(.dynamic-height) > *, .super-app-panel-new-workspace .super-app-menu-panel > .content > .selected-content-wrapper.dynamic-height {
          height: 100%; }

.super-app-panel-new-workspace .super-app-menu-panel:not(.dynamic-height) {
  max-height: 100%; }

.super-app-panel-new-workspace .super-app-menu-panel.dynamic-height {
  max-height: 100%; }

.super-app-panel-new-workspace .super-app-menu-panel > .tabs {
  width: 205px;
  background: #f7f8f8;
  border-right: 1px solid #dfe5eb;
  height: auto;
  padding: 0; }
  .super-app-panel-new-workspace .super-app-menu-panel > .tabs > .control-vertical-tabs {
    height: auto; }
  .super-app-panel-new-workspace .super-app-menu-panel > .tabs > .separator {
    margin: 0 auto; }
  .super-app-panel-new-workspace .super-app-menu-panel > .tabs .premium {
    margin-top: 26px; }
  .super-app-panel-new-workspace .super-app-menu-panel > .tabs .control-tab .dot-indicator {
    right: 5px; }

.super-app-panel-new-workspace .super-app-menu-panel .symbol-arrowWithStates:hover:not(.disabled) .st0 {
  fill: #116dff;
  stroke: #116dff; }

.super-app-panel-new-workspace .super-app-menu-panel .premium {
  color: #3b4057; }
  .super-app-panel-new-workspace .super-app-menu-panel .premium:hover {
    color: #116dff; }

.super-app-full-panel {
  background: #fff;
  width: 400px;
  margin: 0 60px;
  max-height: 580px;
  height: calc( 100vh - var(--g-top-bar-height) - 64px); }
  .super-app-full-panel-content {
    padding-top: 60px;
    text-align: center; }
  .super-app-full-panel .content-wrapper {
    text-align: center; }
  .super-app-full-panel .actions {
    position: absolute;
    right: 15px; }
  .super-app-full-panel-image, .super-app-full-panel-symbol {
    margin-bottom: 30px; }
  .super-app-full-panel-title {
    font-size: 30px;
    margin-bottom: 12px; }
  .super-app-full-panel-description {
    font-size: 18px;
    margin-bottom: 30px;
    white-space: pre; }
  .super-app-full-panel .button {
    color: #116dff;
    bottom: 16px;
    position: relative;
    border-radius: 50%;
    background: #d0e9fa;
    cursor: pointer;
    width: 25px;
    height: 25px;
    top: 15px;
    display: inline-block; }
    .super-app-full-panel .button path {
      fill: #116dff; }
    .super-app-full-panel .button .collapsed {
      display: none; }
    .super-app-full-panel .button:hover {
      background-color: #e7f0ff; }

.superAppTooltipUpgrade {
  width: 384px;
  height: 161px;
  margin: 6px;
  white-space: normal; }
  .superAppTooltipUpgrade .wix-store-upgrade-left {
    float: left;
    width: 234px; }
  .superAppTooltipUpgrade .upgrade-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #000624;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 5px; }
  .superAppTooltipUpgrade .upgrade-content {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 20px; }
  .superAppTooltipUpgrade .upgrade-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    background-color: #9a27d5;
    max-width: 234px;
    height: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    position: relative;
    bottom: 0;
    margin-bottom: 6px; }
  .superAppTooltipUpgrade svg {
    margin-top: 2px;
    position: relative;
    left: 24px; }

.superAppTooltip {
  width: 384px;
  height: 161px;
  margin: 6px;
  white-space: normal; }
  .superAppTooltip .left {
    float: left;
    width: 234px; }
  .superAppTooltip .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #000624;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 5px; }
  .superAppTooltip .content {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 20px; }
  .superAppTooltip .button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    background-color: #116dff;
    max-width: 234px;
    height: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    position: relative;
    bottom: 0;
    margin-bottom: 6px; }
  .superAppTooltip svg {
    margin-top: 2px;
    position: relative;
    left: 35px;
    top: 25px; }

.site-app-learn-more {
  overflow: auto; }
  .site-app-learn-more .image-container {
    height: 163px;
    width: 313px;
    margin: 13px 18px;
    border: 2px solid transparent;
    border-radius: 11px;
    background-color: #fff;
    box-shadow: 0 0 9px 0.5px rgba(43, 86, 114, 0.13);
    cursor: pointer;
    display: block; }
    .site-app-learn-more .image-container:first-child {
      margin-top: 0; }
    .site-app-learn-more .image-container .active-layer {
      visibility: hidden;
      position: absolute;
      height: 153px;
      width: 303px;
      margin: 3px 3px;
      border-radius: 6px;
      border: 2px solid transparent; }
    .site-app-learn-more .image-container img {
      margin: 3px 3px;
      height: 157px;
      width: 307px; }
    .site-app-learn-more .image-container:hover {
      border: 2px solid #5999ff; }
      .site-app-learn-more .image-container:hover .link-container {
        background: #d6e6fe; }
    .site-app-learn-more .image-container:active {
      border: 2px solid #5999ff; }
      .site-app-learn-more .image-container:active .active-layer {
        opacity: 0.4;
        background: #d6e6fe;
        visibility: visible; }
    .site-app-learn-more .image-container .link-container {
      height: 48px;
      width: 308px;
      float: left;
      margin-top: -54px;
      margin-left: 2px;
      background: #e7f0ff;
      opacity: 0.85;
      display: inline-block; }
      .site-app-learn-more .image-container .link-container p {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        color: #3b4057;
        margin-left: 17px;
        margin-top: 14px;
        position: relative;
        display: inline-block;
        text-decoration: none;
        width: calc( 100% - 12px - 17px - 12px - 25px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .site-app-learn-more .image-container .link-container svg {
        display: inline-block;
        text-align: center;
        float: right;
        margin-right: 12px;
        margin-top: 13px;
        width: 25px;
        height: 25px; }
      .site-app-learn-more .image-container .link-container .symbol-arrowWithStates .st0 {
        fill: transparent;
        stroke: #116dff;
        stroke-width: 2px; }
      .site-app-learn-more .image-container .link-container .symbol-arrowWithStates .st1 {
        fill: transparent;
        stroke: #116dff;
        stroke-miterlimit: 10;
        stroke-width: 2px; }
  .site-app-learn-more .first-view {
    padding-top: 17px; }
  .site-app-learn-more .second-view {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #3b4057; }
    .site-app-learn-more .second-view .section-top {
      border-bottom: 1px solid #dfe5eb;
      margin: 0 25px 0 25px;
      overflow: hidden;
      padding: 20px 0 5px 0; }
      .site-app-learn-more .second-view .section-top .title {
        font-size: 15px;
        font-weight: 400;
        float: left;
        width: 70%;
        margin: 0; }
      .site-app-learn-more .second-view .section-top .see-all-link {
        color: #116dff;
        font-size: 13px;
        line-height: 18px;
        text-decoration: none;
        float: right;
        width: 30%;
        text-align: right; }
        .site-app-learn-more .second-view .section-top .see-all-link:hover {
          text-decoration: underline; }
    .site-app-learn-more .second-view .content-wrapper {
      height: 90px;
      cursor: pointer; }
      .site-app-learn-more .second-view .content-wrapper:first-child {
        margin-top: 10px; }
      .site-app-learn-more .second-view .content-wrapper:hover {
        background-color: #e7f0ff; }
        .site-app-learn-more .second-view .content-wrapper:hover svg path {
          fill: #116dff;
          color: #fff; }
      .site-app-learn-more .second-view .content-wrapper img {
        display: inline-block;
        padding-left: 17px; }
      .site-app-learn-more .second-view .content-wrapper .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #868aa5;
        margin: 0 15px;
        width: 140px;
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: top; }
      .site-app-learn-more .second-view .content-wrapper .arrow-wrapper {
        border-radius: 50%;
        background-color: #e7f0ff;
        width: 25px;
        height: 25px;
        display: inline-block;
        position: relative;
        top: calc((25px - 90px) / 2); }
        .site-app-learn-more .second-view .content-wrapper .arrow-wrapper svg path {
          fill: #e7f0ff; }
        .site-app-learn-more .second-view .content-wrapper .arrow-wrapper:hover {
          background-color: #116dff; }

.site-app-pages-panel {
  text-align: left;
  height: 100%; }
  .site-app-pages-panel .content-wrapper .site-app-pages-first-time {
    top: 24px; }
  .site-app-pages-panel .pages-panel-shadow {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%; }
  .site-app-pages-panel .pageSettings {
    align-self: flex-end; }
    .site-app-pages-panel .pageSettings .page-settings-panel-pp {
      position: absolute;
      left: auto;
      margin-left: -3px;
      z-index: -1;
      top: 0; }
    .site-app-pages-panel .pageSettings .page-settings-panel {
      left: auto;
      margin-left: -3px;
      z-index: -1; }
  .site-app-pages-panel .page-list-container {
    flex: 1 1 auto;
    order: 0;
    padding-top: 24px; }
    .site-app-pages-panel .page-list-container .page-list {
      width: 304px;
      padding: 0 24px 20px 24px; }
      .site-app-pages-panel .page-list-container .page-list > .hidden-page .page-title {
        color: #b6c1cd; }
      .site-app-pages-panel .page-list-container .page-list .pages-tree-item {
        cursor: pointer;
        padding: 0 0 0 10px;
        margin-bottom: 8px;
        line-height: 37px; }
      .site-app-pages-panel .page-list-container .page-list .pages-quick-actions .action-button {
        cursor: pointer; }
      .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item {
        background-color: #d6e6fe; }
        .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item .action-button {
          box-shadow: 0 1px 1px 0 #868aa5;
          visibility: visible; }
        .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item > .page-title svg path {
          fill: #868aa5; }
      .site-app-pages-panel .page-list-container .page-list .handle {
        display: none; }

.super-app-add-elements-panel {
  border-top-right-radius: 0 !important;
  width: 100%; }
  .super-app-add-elements-panel.category-view-wrapper {
    display: inline; }
  .super-app-add-elements-panel .category-view {
    height: 100% !important; }
  .super-app-add-elements-panel .super-app-mobile-view {
    text-align: center; }
    .super-app-add-elements-panel .super-app-mobile-view .image-container {
      margin: 19px auto 35px auto; }
    .super-app-add-elements-panel .super-app-mobile-view .symbol-mobileToEditor {
      margin-top: 30px;
      margin-bottom: 3px; }
    .super-app-add-elements-panel .super-app-mobile-view p {
      margin: 14px 50px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      color: #3b4057; }
      .super-app-add-elements-panel .super-app-mobile-view p.bigger-text {
        font-size: 18px; }
    .super-app-add-elements-panel .super-app-mobile-view a {
      color: #116dff;
      cursor: pointer; }
      .super-app-add-elements-panel .super-app-mobile-view a:hover {
        text-decoration: underline; }

.super-app-manage-panel,
.super-app-extra-panel {
  text-align: center; }
  .super-app-manage-panel .image-container,
  .super-app-extra-panel .image-container {
    margin: 19px auto 35px auto; }
  .super-app-manage-panel p,
  .super-app-extra-panel p {
    width: 224px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #3b4057;
    line-height: 18px;
    margin: 0 auto 20px; }
  .super-app-manage-panel .buttons-container,
  .super-app-extra-panel .buttons-container {
    display: inline-block; }
    .super-app-manage-panel .buttons-container .manage-btn,
    .super-app-extra-panel .buttons-container .manage-btn {
      width: 100%;
      margin-bottom: 12px;
      height: 36px; }

.pages-tree-item {
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #cde;
  box-sizing: border-box;
  color: #3b4057;
  height: 39px;
  line-height: 39px;
  padding-left: 12px; }
  .pages-tree-item:before {
    display: none;
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    bottom: 50%;
    left: -12px;
    border: 1px solid #cde;
    border-top: 0;
    border-right: 0;
    padding: 4px 0 0; }
  .pages-tree-item .symbol-dragIcon {
    display: none;
    margin-right: 10px; }
  .pages-tree .pages-tree-item {
    cursor: move; }
    .pages-tree .pages-tree-item.disable-pages-drag {
      cursor: pointer; }
    .pages-tree .pages-tree-item .symbol-dragIcon {
      display: inline; }
  .pages-tree-item.hidden-page > .wrapper-view-mode > .page-title {
    color: #868aa5; }
  .pages-tree-item.hidden-page.selected > .wrapper-view-mode > .page-title {
    color: #868aa5; }
  .pages-tree-item.has-notification .pages-quick-actions .action-button {
    box-shadow: 0 1px 1px 0 #868aa5;
    visibility: visible; }
  .pages-tree-item:hover {
    background-color: #e7f0ff; }
    .pages-tree-item:hover .pages-quick-actions .action-button {
      box-shadow: 0 1px 1px 0 #868aa5;
      visibility: visible; }
    .pages-tree-item:hover .symbol-dragIcon path {
      fill: #80b1ff; }
  .pages-tree-item.selected {
    background-color: #d6e6fe; }
    .pages-tree-item.selected .pages-quick-actions .action-button {
      box-shadow: 0 1px 1px 0 #868aa5;
      visibility: visible; }
      .pages-panel-settings-opened .pages-tree-item.selected .pages-quick-actions .action-button.action-settings {
        box-shadow: inset 0 1px 1px 0 #868aa5;
        background-color: #80b1ff; }
        .pages-panel-settings-opened .pages-tree-item.selected .pages-quick-actions .action-button.action-settings .symbol-quickActionsSettings path {
          stroke: none;
          fill: #3b4057; }
    .pages-tree-item.selected .symbol-dragIcon path {
      fill: #3b4057; }
    .pages-tree-item.selected > .wrapper-view-mode > .page-title svg path {
      fill: #868aa5; }
  .pages-tree-item > .handle {
    position: absolute;
    left: 12px;
    font-size: 8px;
    color: #116dff; }
  .pages-tree-item.depth-0.icon-cnt-0 .page-title-name {
    max-width: 200px; }
  .pages-tree-item.depth-0.icon-cnt-0.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-0:hover .page-title-name {
    max-width: 200px; }
  .pages-tree-item.depth-0.icon-cnt-0.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-0:hover.qa-2 .page-title-name {
    max-width: 170px; }
  .pages-tree-item.depth-0.icon-cnt-1 .page-title-name {
    max-width: 206px; }
  .pages-tree-item.depth-0.icon-cnt-1.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-1:hover .page-title-name {
    max-width: 178px; }
  .pages-tree-item.depth-0.icon-cnt-1.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-1:hover.qa-2 .page-title-name {
    max-width: 150px; }
  .pages-tree-item.depth-0.icon-cnt-2 .page-title-name {
    max-width: 186px; }
  .pages-tree-item.depth-0.icon-cnt-2.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-2:hover .page-title-name {
    max-width: 160px; }
  .pages-tree-item.depth-0.icon-cnt-2.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-2:hover.qa-2 .page-title-name {
    max-width: 130px; }
  .pages-tree-item.depth-1.icon-cnt-0 .page-title-name {
    max-width: 173px; }
  .pages-tree-item.depth-1.icon-cnt-0.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-0:hover .page-title-name {
    max-width: 173px; }
  .pages-tree-item.depth-1.icon-cnt-0.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-0:hover.qa-2 .page-title-name {
    max-width: 144px; }
  .pages-tree-item.depth-1.icon-cnt-1 .page-title-name {
    max-width: 182px; }
  .pages-tree-item.depth-1.icon-cnt-1.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-1:hover .page-title-name {
    max-width: 154px; }
  .pages-tree-item.depth-1.icon-cnt-1.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-1:hover.qa-2 .page-title-name {
    max-width: 124px; }
  .pages-tree-item.depth-1.icon-cnt-2 .page-title-name {
    max-width: 160px; }
  .pages-tree-item.depth-1.icon-cnt-2.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-2:hover .page-title-name {
    max-width: 134px; }
  .pages-tree-item.depth-1.icon-cnt-2.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-2:hover.qa-2 .page-title-name {
    max-width: 102px; }
  .pages-tree-item > .wrapper-view-mode > .page-title {
    color: #3b4057;
    margin-right: 0.5em;
    position: absolute;
    top: 0;
    height: 100%; }
    .pages-tree-item > .wrapper-view-mode > .page-title > .page-title-name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .pages-tree-item > .wrapper-view-mode > .page-title svg {
      vertical-align: top;
      margin-left: 6px;
      margin-top: 12px; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg path {
        fill: #868aa5; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemHomeIcon {
        margin-top: 13px; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemDropdownIcon {
        margin-top: 15px; }
  .pages-tree-item.edit-mode {
    padding-left: 6px;
    padding-right: 7px; }
  .pages-tree-item > .control-text-input-with-fixed-button {
    height: 100%; }
  .pages-tree-item .pages-quick-actions {
    position: absolute;
    right: 6px;
    top: 7px;
    text-align: center;
    width: 120px;
    margin-right: -5px; }
  .pages-tree-item .action-button {
    box-shadow: 0 1px 1px 0 #868aa5;
    position: relative;
    background-color: #fff;
    visibility: hidden;
    float: right;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    width: 24px;
    margin-right: 5px; }
    .pages-tree-item .action-button svg {
      margin-top: 20%; }
      .pages-tree-item .action-button svg path {
        fill: #5999ff; }
    .pages-tree-item .action-button .symbol-quickActionsSettings {
      margin-top: 3px; }
      .pages-tree-item .action-button .symbol-quickActionsSettings path {
        stroke: #5999ff;
        fill: none; }
    .pages-tree-item .action-button .symbol-actionButtonInfo {
      margin-top: 0; }
    .pages-tree-item .action-button > .info-icon {
      height: 100%;
      width: 100%; }
    .pages-tree-item .action-button.dropdown.context-menu {
      border: 0; }
      .pages-tree-item .action-button.dropdown.context-menu .symbol-moreRegular {
        position: relative;
        left: 6px;
        top: -7px; }
        .pages-tree-item .action-button.dropdown.context-menu .symbol-moreRegular .cls-b1 {
          fill: #116dff; }
      .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.selected-container {
        height: 17px;
        min-height: 17px; }
      .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option {
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        line-height: 32px;
        height: 32px;
        padding-left: 43px; }
        .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option.primary {
          font-weight: 500; }
        .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option svg {
          position: absolute;
          top: 23%;
          left: 18px; }
          .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option svg path {
            fill: #2d3034; }
    .pages-tree-item .action-button:hover {
      box-shadow: 0 1px 1px 0 #868aa5;
      background-color: #80b1ff; }
      .pages-tree-item .action-button:hover .symbol-actionButtonSpecialPages path,
      .pages-tree-item .action-button:hover .symbol-actionButtonBlogPages path {
        fill: #fff; }
      .pages-tree-item .action-button:hover .symbol-quickActionsSettings path {
        stroke: #3b4057; }
    .pages-tree-item .action-button.expanded.action-button.dropdown.context-menu, .pages-tree-item .action-button.action-button:active {
      box-shadow: inset 0 1px 1px 0 #868aa5;
      background-color: #80b1ff; }

.pages-tree-shrinked .pages-tree-item {
  height: 23px;
  line-height: 23px; }
  .pages-tree-shrinked .pages-tree-item:before {
    padding: 5px 0 0; }
  .pages-tree-shrinked .pages-tree-item .pages-quick-actions {
    top: 2px; }
    .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button {
      height: 19px;
      line-height: 19px;
      width: 19px; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button svg {
        margin-top: 0; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button .symbol-actionButtonSpecialPages {
        margin-top: 10%; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button.dropdown.context-menu .symbol-moreRegular {
        left: 4px;
        top: -9px; }
  .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg {
    margin-top: 5px; }
    .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemDropdownIcon {
      margin-top: 9px; }
    .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemHomeIcon {
      margin-top: 6px; }

.page-settings-panel {
  border-radius: 0 4px 4px 0;
  width: 480px;
  height: 100%;
  left: 293px;
  top: 0;
  position: absolute;
  background-color: #fff;
  z-index: 1254;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35); }
  .page-settings-panel > .page-settings-panel-inner {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .page-settings-panel > .page-settings-panel-inner .panel-header {
      padding-left: 49px;
      border-radius: 0 4px 0 0; }
      .page-settings-panel > .page-settings-panel-inner .panel-header > .btn-close .symbol-arrowLeft path {
        fill: #fff; }
        .classic-facelift-skin .page-settings-panel > .page-settings-panel-inner .panel-header > .btn-close .symbol-arrowLeft path {
          fill: #000624; }
    .page-settings-panel > .page-settings-panel-inner .page-settings-tabs {
      height: 100%; }
    .page-settings-panel > .page-settings-panel-inner > div {
      display: flex;
      flex-direction: column;
      height: calc(100% - 118px); }
  .page-settings-panel .page-settings-panel-content {
    height: calc(100% - 47px);
    color: #395671;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box; }
    .page-settings-panel .page-settings-panel-content,
    .page-settings-panel .page-settings-panel-content .tab,
    .page-settings-panel .page-settings-panel-content .custom-scroll,
    .page-settings-panel .page-settings-panel-content .outer-container,
    .page-settings-panel .page-settings-panel-content .inner-container {
      display: flex;
      flex-direction: column; }
    .page-settings-panel .page-settings-panel-content .tab,
    .page-settings-panel .page-settings-panel-content .custom-scroll {
      height: 100%; }
    .page-settings-panel .page-settings-panel-content .tab-inner {
      padding: 0 15px 10px 30px; }
      .page-settings-panel .page-settings-panel-content .tab-inner.empty {
        padding-top: 18px;
        padding-left: 38px; }
    .page-settings-panel .page-settings-panel-content :not(.bubble-content) > section {
      border-bottom: 1px solid #dfe5eb;
      padding: 20px 25px 15px 5px; }
      .page-settings-panel .page-settings-panel-content :not(.bubble-content) > section > *:not(.control-text-input):not(.info-icon) {
        box-sizing: border-box;
        padding-left: 12px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage section {
      border-bottom: 0;
      padding: 0 25px 0 5px;
      margin-bottom: 34px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage p:not(.title) {
      margin: 0 0 13px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage .title {
      color: #3b4057;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 8px; }
    .page-settings-panel .page-settings-panel-content .control-text-input {
      width: 100%; }
      .page-settings-panel .page-settings-panel-content .control-text-input > label {
        color: #3b4057; }
      .page-settings-panel .page-settings-panel-content .control-text-input > input {
        width: 100%;
        color: #1d2d3c; }
        .page-settings-panel .page-settings-panel-content .control-text-input > input::placeholder {
          color: #b6c1cd; }
    .page-settings-panel .page-settings-panel-content .control-toggle {
      width: 100%;
      display: inline-block; }
      .page-settings-panel .page-settings-panel-content .control-toggle > .text-label {
        line-height: 26px;
        float: left;
        width: auto; }
      .page-settings-panel .page-settings-panel-content .control-toggle > .icons-container {
        float: right; }
      .page-settings-panel .page-settings-panel-content .control-toggle .toggle-with-icon {
        width: 100%; }
    .page-settings-panel .page-settings-panel-content .control-button.btn-text {
      line-height: 16px;
      height: 16px;
      font-size: 14px;
      padding: 0;
      min-width: 0; }
  .page-settings-panel .button-control {
    border-radius: 50%;
    border: 0;
    width: 25px;
    height: 25px;
    background-color: rgba(13, 72, 127, 0.3);
    color: #fff;
    box-sizing: border-box;
    line-height: 25px;
    min-width: 0;
    padding: 0; }
    .page-settings-panel .button-control:hover {
      background-color: rgba(13, 72, 127, 0.6); }
    .page-settings-panel .button-control svg {
      margin: 0; }
  .page-settings-panel .symbol-settingsHome {
    margin-right: 10px; }
  .page-settings-panel .page-settings-tabs > .tab-header {
    margin-left: 8px;
    width: 472px;
    min-height: 47px; }
    .page-settings-panel .page-settings-tabs > .tab-header > .tab-label {
      color: rgba(43, 86, 114, 0.9);
      font-size: 14px;
      width: 119px;
      border: 1px solid #dfe5eb;
      border-top: 0;
      box-sizing: border-box;
      position: relative;
      margin-left: -1px;
      background-color: #e7f0ff;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding: 0 10px; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label:last-child {
        border-right: 0; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label:hover {
        background-color: #d6e6fe;
        border-bottom: 1px solid #d6e6fe; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label.selected {
        border-bottom: 1px solid #fff;
        background-color: #fff;
        color: #3b4057; }
  .page-settings-panel .page-settings-tabs > .tab-content .tabs {
    border-bottom: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label {
      border-bottom: 1px solid #dfe5eb;
      padding-bottom: 15px; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:last-child {
        border-bottom: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:before {
        top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:after {
        top: 2px; }
  .page-settings-panel .page-settings-tabs > .tab-content .action-done {
    border-radius: 50%;
    border: 0;
    width: 24px;
    height: 24px;
    background-color: #25a55a;
    color: #fff;
    line-height: 24px;
    min-width: 0;
    padding: 0;
    display: inline-block;
    text-align: center; }
  .page-settings-panel .page-settings-tabs > .tab-content .copy-url {
    margin-top: 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .copy-url .btn-text {
      margin-right: 5px;
      height: 24px;
      line-height: 24px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info .section-page-title {
    padding-top: 19px;
    padding-bottom: 14px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info .section-hide-page {
    padding-top: 23px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description {
    padding-top: 26px;
    padding-bottom: 25px; }
    .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button {
      height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg path {
          fill: #fff; }
  .page-settings-panel .page-settings-tabs > .tab-content .layout > section {
    padding: 7px 25px 0 5px; }
  .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label {
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div {
      float: right;
      width: 227px; }
      .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div h3 {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        margin-top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div p {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 13px;
        margin-bottom: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:before {
      top: 26px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:after {
      top: 28px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label svg {
      margin-left: 20px;
      float: left; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingSelected {
      display: none; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardNotSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingNotSelected {
      display: block; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingSelected {
      display: block; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardNotSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingNotSelected {
      display: none; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy > section {
    padding-top: 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header {
      width: 100%; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label {
        padding-bottom: 13px;
        margin-bottom: 13px;
        color: #3b4057; }
        .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label > h4 {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 14px;
          margin-bottom: 8px; }
        .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label > span {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 13px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .password-settings-content {
    cursor: default; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy hr {
    border: none;
    border-top: 1px solid #f7f8f8;
    margin: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy hr.separator-permissions-password {
      margin-top: 15px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy hr.separator-permissions-password-language {
      margin-bottom: 13px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled {
    margin: 0 -12px 0 -24px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container {
      margin-top: 6px;
      margin-bottom: -6px; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container input::placeholder {
        color: #b6c1cd; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container:hover input:not(:focus)::placeholder {
        color: #000624; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-password-language > label,
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-member-language > label {
    margin-bottom: 0; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-password-language .selected-container,
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-member-language .selected-container {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #000624; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .site-members-info {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 13px;
    color: #868aa5;
    margin-top: 0; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .separator-site-members-info {
    margin-top: 15px;
    margin-bottom: 17px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .separator-permissions-member-language {
    margin-top: 10px;
    margin-bottom: 20px; }
  .page-settings-panel .page-settings-tabs > .tab-content .seo.tab {
    padding-bottom: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-title,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-bottom: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-top: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz-inner-div {
      margin-left: -12px;
      margin-bottom: -22px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz {
      padding-top: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old {
      position: relative;
      padding-bottom: 20px; }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input {
        margin-bottom: 7px; }
        .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input input {
          margin-left: 130px;
          width: calc(100% - 130px); }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .page-address-prefix {
        position: absolute;
        top: 52px;
        left: 5px;
        line-height: 35px;
        font-size: 18px;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        color: #b6c1cd; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .tab-inner {
      margin-bottom: 50px; }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .tab-inner.google-preview-expanded {
        margin-bottom: 190px; }
  .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview {
    position: static;
    border-radius: 0;
    border: none;
    width: calc(100% - 10px);
    height: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    padding: 17px 11px; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > p {
      padding: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-title {
      margin: 0 0 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-url {
      margin-bottom: 2px; }
  .page-settings-panel .google-preview {
    border-radius: 15px 15px 0 0;
    transition: bottom 0.4s;
    position: absolute;
    bottom: -140px;
    height: 150px;
    width: calc(100% - 7px);
    left: 6px;
    border: 1px solid #dfe5eb;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff; }
    .page-settings-panel .google-preview .google-preview-corner-container {
      position: absolute;
      width: 15px;
      height: 15px;
      bottom: -5px;
      overflow: hidden; }
      .page-settings-panel .google-preview .google-preview-corner-container .google-preview-corner {
        width: 31px;
        height: 31px;
        position: relative;
        border: solid 8px #fff;
        top: -8px;
        left: -8px;
        box-sizing: border-box; }
      .page-settings-panel .google-preview .google-preview-corner-container.left {
        left: -15px; }
        .page-settings-panel .google-preview .google-preview-corner-container.left .google-preview-corner {
          border-radius: 0 0 15px; }
      .page-settings-panel .google-preview .google-preview-corner-container.right {
        right: -15px; }
        .page-settings-panel .google-preview .google-preview-corner-container.right .google-preview-corner {
          border-radius: 0 0 0 15px; }
    .page-settings-panel .google-preview.expanded {
      bottom: -1px; }
      .page-settings-panel .google-preview.expanded .google-preview-header > .symbol-equals {
        display: none; }
      .page-settings-panel .google-preview.expanded .google-preview-header > .symbol-arrowDown {
        display: block; }
    .page-settings-panel .google-preview > p {
      text-align: left;
      margin: 0 40px;
      word-wrap: break-word; }
    .page-settings-panel .google-preview > .google-preview-header {
      border-radius: 15px 15px 0 0;
      position: relative;
      cursor: pointer;
      height: 32px;
      padding-top: 5px;
      text-align: center;
      border: 1px solid #dfe5eb;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      width: 200px;
      background-color: #fff;
      border-bottom: 0;
      margin: 0 auto;
      top: -43px;
      color: #116dff; }
      .page-settings-panel .google-preview > .google-preview-header > svg {
        fill: #116dff; }
      .page-settings-panel .google-preview > .google-preview-header > .symbol-equals {
        margin: 6px auto 5px;
        display: block; }
      .page-settings-panel .google-preview > .google-preview-header > .symbol-arrowDown {
        margin: 2px auto 1px;
        display: none; }
      .page-settings-panel .google-preview > .google-preview-header > .google-preview-header-title {
        margin-top: 5px; }
      .page-settings-panel .google-preview > .google-preview-header > .google-preview-bottom-header-border {
        background-color: #fff;
        width: 100%;
        position: absolute;
        height: 8px;
        bottom: -8px; }
    .page-settings-panel .google-preview > .google-preview-title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      color: #1a0dab;
      line-height: 21px;
      margin-top: -10px; }
      .page-settings-panel .google-preview > .google-preview-title > .google-preview-title-site-name {
        font-weight: 700; }
    .page-settings-panel .google-preview > .google-preview-url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #006621;
      margin-top: 4px;
      margin-bottom: 4px;
      -webkit-user-select: text;
              user-select: text; }
    .page-settings-panel .google-preview > .google-preview-description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      -webkit-font-smoothing: antialiased;
      color: #545454; }
  .page-settings-panel footer {
    height: 64px;
    width: 100%;
    border-top: 1px solid #dfe5eb;
    flex-shrink: 0;
    z-index: 1; }
    .page-settings-panel footer .page-settings-controls {
      float: left;
      display: inline-block;
      line-height: 54px;
      margin-top: 10px;
      padding-left: 24px; }
      .page-settings-panel footer .page-settings-controls svg {
        margin-right: 5px; }
      .page-settings-panel footer .page-settings-controls > span {
        color: #395671;
        cursor: pointer;
        display: inline-block;
        line-height: 16px;
        margin-left: 24px; }
        .page-settings-panel footer .page-settings-controls > span > span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 110px; }
        .page-settings-panel footer .page-settings-controls > span:hover > span {
          color: #116dff;
          text-decoration: underline; }
    .page-settings-panel footer .control-button {
      float: right;
      margin: 15px 20px;
      font-size: 16px;
      min-width: 92px;
      max-width: 115px; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .label {
    color: #3b4057;
    font-size: 14px;
    line-height: 24px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page section:first-child {
    border-top: 1px solid #dfe5eb;
    margin-top: 19px;
    padding-top: 24px;
    padding-bottom: 24px; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon {
    position: relative; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileHidden {
      position: absolute;
      top: 3px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileVisible {
      position: absolute;
      top: 5px;
      left: 3px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .label {
      margin-left: 25px;
      overflow: inherit; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop {
    padding-top: 23px;
    padding-bottom: 23px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > span {
      line-height: 24px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg path {
          fill: #fff; }
  .page-settings-panel .page-setting-mobile .symbol-desktopHidden {
    margin-right: 10px; }
  .page-settings-panel .page-setting-mobile .symbol-desktopVisible {
    margin-right: 10px; }

.seo-section-page-address {
  padding-left: 17px;
  top: 15px;
  position: relative; }
  .seo-section-page-address .seo-section-title-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #3b4057;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px; }
  .seo-section-page-address .seo-section-site-url {
    margin-bottom: 7px; }
    .seo-section-page-address .seo-section-site-url > .control-text-input-seo {
      margin-left: 7px;
      width: inherit; }
      .seo-section-page-address .seo-section-site-url > .control-text-input-seo input {
        width: calc(100% - 130px); }
  .seo-section-page-address .page-address-prefix {
    position: relative;
    top: 1px;
    left: 0;
    line-height: 35px;
    font-size: 18px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd; }
  .seo-section-page-address.blog-seo-setting {
    padding-left: 11px; }

.page-settings-change-homepage-tooltip {
  float: right; }

.tab-seo .translated-by {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #000624;
  float: right;
  font-size: 12px;
  transform: translateY(-0.5em);
  font-weight: 300; }

.tab-seo .symbol-google-logo {
  width: 42px;
  height: 13px;
  transform: translateY(0.3em); }

.tab-seo .seo-section-description textarea.input :not(.focused) {
  height: 100px; }

.page-settings-permissions-tab .site-members-settings {
  display: flex;
  align-items: flex-start;
  margin-top: 30px; }
  .page-settings-permissions-tab .site-members-settings .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex: 1;
    color: #116dff;
    margin: 0 18px 0 12px; }

.wix-data-learn-more-panel .image-container {
  width: 372px;
  height: 194.70255px;
  margin: 13px 24px; }
  .wix-data-learn-more-panel .image-container img {
    width: 366px;
    height: 188.70255px; }
  .wix-data-learn-more-panel .image-container .active-layer {
    width: 362px;
    height: 184.70255px; }
  .wix-data-learn-more-panel .image-container .link-container {
    width: 367px; }

.responsive-skin .wix-data-learn-more-panel .image-container {
  width: 381px;
  height: 199.34278px;
  margin: 13px 24px; }
  .responsive-skin .wix-data-learn-more-panel .image-container img {
    width: 375px;
    height: 193.34278px; }
  .responsive-skin .wix-data-learn-more-panel .image-container .active-layer {
    width: 371px;
    height: 189.34278px; }
  .responsive-skin .wix-data-learn-more-panel .image-container .link-container {
    width: 376px; }

.pages-panel-pp {
  height: calc( 100vh - var(--g-top-bar-height) - 64px);
  z-index: 1105; }
  .pages-panel-pp:not(.dynamic-height) {
    max-height: 626px; }
  .pages-panel-pp .left-panel-frame-pages-panel-pp {
    height: 100%;
    display: flex; }
  .pages-panel-pp.pages-panel-pp-new-workspace {
    height: 100%; }
    .pages-panel-pp.pages-panel-pp-new-workspace:not(.dynamic-height) {
      max-height: 100%; }
    .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp {
      background-color: #f7f8f8;
      color: #3b4057;
      border-right: 1px solid #dfe5eb;
      padding: 12px 0 12px 12px; }
      .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background {
        color: #000624;
        transition-property: background-color, color;
        transition-duration: 0.2s; }
        .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background.selected {
          color: #0057e1;
          background-color: #d6e6fe;
          box-shadow: none; }
          .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background.selected .actions-tooltip .control-context-menu {
            opacity: 1; }
            .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background.selected .actions-tooltip .control-context-menu .symbol path {
              fill: #116dff; }
            .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background.selected .actions-tooltip .control-context-menu:hover .symbol path {
              fill: #0057e1; }
        .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background:hover:not(.selected) {
          color: #116dff;
          background-color: #e7f0ff;
          box-shadow: none; }
          .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background:hover:not(.selected) .actions-tooltip .control-context-menu .symbol path {
            fill: #116dff; }
        .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .container-for-background .title {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 14px;
          -webkit-font-smoothing: antialiased;
          line-height: 18px; }
      .pages-panel-pp.pages-panel-pp-new-workspace .tabs-pp .tab .divider-short {
        width: auto; }
    .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp {
      background-color: #fff; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-node {
        margin-bottom: 6px; }
        .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-node:first-child {
          margin-top: 0; }
          .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-node:first-child > .menu-item-pp:before {
            height: 55%; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-node > .menu-item-pp:before {
        height: 110%; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp > .pages-tree-container {
        margin-top: 0; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-container .tree-root,
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp .pages-tree-docker .tree-root {
        padding-left: 24px;
        padding-right: 24px; }
    .pages-panel-pp.pages-panel-pp-new-workspace .menu-view-pp.menu-view-pp_custom-menus .expander::before {
      top: 7px; }
    .pages-panel-pp.pages-panel-pp-new-workspace .menu-item-pp {
      height: 42px;
      margin-bottom: 6px;
      border-radius: 4px;
      border: 1px solid #dfe5eb; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-item-pp.selected {
        background-color: #d6e6fe;
        border-color: #80b1ff; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-item-pp:hover:not(.selected) {
        background-color: #e7f0ff;
        border-color: #d6e6fe; }
      .pages-panel-pp.pages-panel-pp-new-workspace .menu-item-pp .input-container:not(.is-disabled) > .input {
        background-color: #fff; }

.app-manager-panel .tabs-pp {
  background: #5a48f5; }

.app-manager-panel-new .tabs-pp {
  background: #1b3689; }

.am-leftBarAppManagerFitnessIcon .tabs-pp {
  background: #5a48f5; }

.pages-panel-pp .tabs-pp {
  background: #00aaaf; }

.theme-panel .tabs-pp {
  background: #003790; }

.bg-panel .tabs-pp {
  background: #f8a239; }

.add-panel .tabs-pp {
  background: #3899ec; }

.app-market-panel .tabs-pp {
  background: #d0427c; }

.app-market-panel-20 .tabs-pp {
  background: #d0427c; }

.app-market-panel-new .tabs-pp {
  background: #d0427c; }

.app-market-panel-no-header .tabs-pp {
  background: #d0427c; }

.my-uploads-panel .tabs-pp {
  background: #fa7c33; }

.media-manager-panel .tabs-pp {
  background: #fa7c33; }

.media-manager-panel-icon-b .tabs-pp {
  background: #fa7c33; }

.media-manager-panel-icon-c .tabs-pp {
  background: #fa7c33; }

.wix-data-panel .tabs-pp {
  background: #00A65F; }

.wix-data-panel-icon-b .tabs-pp {
  background: #00A65F; }

.interactions-hidden-components .tabs-pp {
  background: #d0427c; }

.blog-panel .tabs-pp {
  background: #25a55a; }

.new-blog-panel .tabs-pp {
  background: #faad4d; }

.wix-store-panel .tabs-pp {
  background: #00adf5; }

.wix-bookings-panel .tabs-pp {
  background: #6C48EF; }

.hidden-items-panel .tabs-pp {
  background: #d0427d; }

.mobile-elements-panel .tabs-pp {
  background: #3899eb; }

.layout-optimizer-panel .tabs-pp {
  background: #45c8c1; }

.ascend-panel .tabs-pp {
  background: #1463DA; }

.tabs-pp {
  max-width: 205px;
  height: 100%;
  padding: 17px 0 17px 6px;
  box-sizing: border-box;
  color: #fff; }
  .tabs-pp .tab .container-for-background {
    cursor: pointer;
    border-radius: 20px;
    height: 30px;
    padding: 0 12px;
    margin-bottom: 6px;
    display: inline-flex;
    align-items: center; }
    .tabs-pp .tab .container-for-background.selected {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .tabs-pp .tab .container-for-background.selected .actions-tooltip .control-context-menu {
        opacity: 0.7; }
    .tabs-pp .tab .container-for-background:not(.selected):hover {
      background-color: rgba(0, 0, 0, 0.07);
      box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .tabs-pp .tab .container-for-background:not(.selected):hover .actions-tooltip .control-context-menu {
        opacity: 0.7; }
    .tabs-pp .tab .container-for-background .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      max-width: 145px; }
    .tabs-pp .tab .container-for-background .actions-tooltip {
      height: 24px; }
      .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu {
        opacity: 0;
        margin-left: 6px; }
        .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu:hover {
          opacity: 1; }
        .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu .symbol {
          height: 24px; }
          .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu .symbol path {
            fill: #fff; }
  .tabs-pp .tab .divider-short {
    margin: 0 12px 5px 12px; }
  .tabs-pp .custom-scroll {
    height: 100%; }
    .tabs-pp .custom-scroll .outer-container {
      height: 100%; }
      .tabs-pp .custom-scroll .outer-container .positioning .custom-scrollbar .custom-scroll-handle .inner-handle {
        background-color: rgba(255, 255, 255, 0.35); }
      .tabs-pp .custom-scroll .outer-container .inner-container {
        height: 100%; }
        .tabs-pp .custom-scroll .outer-container .inner-container .content-wrapper {
          padding-right: 12px; }

.panel-tab-action-pp .control-text {
  max-width: 200px; }

.quick-navigation-pp {
  float: left;
  width: 222px;
  position: relative;
  border-right: 1px #e7f0ff solid; }
  .quick-navigation-pp.minimized {
    width: 172px; }
  .quick-navigation-pp:hover {
    background-color: #e7f0ff; }
  .quick-navigation-pp .quick-navigation-pp-content-wrapper {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    position: relative; }
    .quick-navigation-pp .quick-navigation-pp-content-wrapper .quick-navigation-pp-loader {
      position: absolute;
      right: 59px;
      top: 50%;
      transform: translateY(-50%); }
  .quick-navigation-pp .exit-lightbox {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px 0;
    box-sizing: border-box;
    background-color: #25a55a;
    z-index: 0;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 300ms ease;
    cursor: pointer; }
    .quick-navigation-pp .exit-lightbox > span {
      color: #fff; }
    .quick-navigation-pp .exit-lightbox > svg {
      position: absolute;
      right: 20px;
      height: 25px;
      width: 25px; }
    .quick-navigation-pp .exit-lightbox:hover {
      background-color: #51b77b; }
    .quick-navigation-pp .exit-lightbox.active-exit-lightbox {
      z-index: 1;
      opacity: 1;
      transform: translateY(0); }
  .quick-navigation-pp .control-dropdown {
    padding: 0 18px 0 24px;
    display: flex;
    align-items: center;
    height: 100%; }
    .quick-navigation-pp .control-dropdown::before {
      content: attr(data-label);
      margin-right: 3px; }
    .quick-navigation-pp .control-dropdown .dropdown-selected {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1; }
      .quick-navigation-pp .control-dropdown .dropdown-selected .control-dropdown-option-content {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #116dff; }
      .quick-navigation-pp .control-dropdown .dropdown-selected > .selected-container > .selected-input {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
      .quick-navigation-pp .control-dropdown .dropdown-selected .symbol-arrow-down {
        border: none; }
  .classic-facelift-skin .quick-navigation-pp .control-dropdown::before {
    color: #000624; }
  .classic-facelift-skin .quick-navigation-pp .control-dropdown:hover .dropdown-selected {
    background-color: #fff; }
  .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected,
  .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected.is-open {
    border: none;
    padding: 0;
    line-height: inherit; }
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected .control-dropdown-option-content,
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected.is-open .control-dropdown-option-content {
      color: #000624; }
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected > .selected-container > .selected-input,
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected.is-open > .selected-container > .selected-input {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected .symbol-arrow-down > svg,
    .classic-facelift-skin .quick-navigation-pp .control-dropdown .dropdown-selected.is-open .symbol-arrow-down > svg {
      fill: #000624; }
  .classic-facelift-skin .quick-navigation-pp:hover {
    background-color: #fff; }
    .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown::before {
      color: #116dff; }
    .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown:hover .dropdown-selected {
      background-color: #fff; }
    .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected,
    .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected.is-open {
      border: none;
      padding: 0;
      line-height: inherit; }
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected .control-dropdown-option-content,
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected.is-open .control-dropdown-option-content {
        color: #116dff; }
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected > .selected-container > .selected-input,
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected.is-open > .selected-container > .selected-input {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected .symbol-arrow-down > svg,
      .classic-facelift-skin .quick-navigation-pp:hover .control-dropdown .dropdown-selected.is-open .symbol-arrow-down > svg {
        fill: #116dff; }
  .quick-navigation-pp__tooltip-content > :first-child {
    display: block;
    padding-bottom: 6px;
    min-width: 190px; }

.dropdown-quick-navigation-pp .dropdown-options {
  width: 222px;
  border-radius: 0 0 4px 4px;
  border-top: 1px #e3eaf6 solid;
  box-shadow: 0 9px 13px 0 rgba(22, 45, 61, 0.2); }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider {
    padding: 0 24px;
    height: 36px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider.upper:not(:first-child) {
      margin-top: 12px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider .section-divider-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      line-height: 36px;
      text-align: inherit; }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option {
    height: 30px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option.nested-page {
      padding-left: 42px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option .control-dropdown-option-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      line-height: 30px; }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer {
    align-items: center; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer .control-button {
      padding: 0; }
      .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer .control-button .manage-pages-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 13px;
        -webkit-font-smoothing: antialiased;
        line-height: 40px; }

.menu-item-pp {
  padding: 0 6px 0 8px;
  background-color: #fff;
  border: 1px solid #fff;
  position: relative;
  border-radius: 7px;
  box-sizing: border-box;
  color: #3b4057;
  height: 39px;
  line-height: 39px;
  cursor: move; }
  .menu-item-pp:before {
    display: none;
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    bottom: 50%;
    left: -12px;
    border: 1px solid #cde;
    border-top: 0;
    border-right: 0;
    padding: 4px 0 0; }
  .menu-item-pp.selected {
    background-color: #d6e6fe;
    border-color: #a8caff; }
  .menu-item-pp:hover:not(.selected) {
    background-color: #e7f0ff;
    border-color: #d6e6fe; }
  .menu-item-pp .title-action-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .menu-item-pp .title-action-container .left-container {
      display: flex;
      align-items: center;
      overflow: hidden; }
      .menu-item-pp .title-action-container .left-container > .has-tooltip {
        display: flex;
        align-items: center;
        margin-left: 10px; }
        .menu-item-pp .title-action-container .left-container > .has-tooltip .symbol {
          margin-right: 6px; }
          .menu-item-pp .title-action-container .left-container > .has-tooltip .symbol path {
            fill: #3b4057; }
            .hidden-page.menu-item-pp .title-action-container .left-container > .has-tooltip .symbol path {
              fill: #868aa5; }
      .menu-item-pp .title-action-container .left-container .symbol-dragPage {
        display: none;
        overflow: visible; }
        .selected.menu-item-pp .title-action-container .left-container .symbol-dragPage {
          display: inline-flex; }
          .selected.menu-item-pp .title-action-container .left-container .symbol-dragPage + .has-tooltip {
            margin-left: 5px; }
        .menu-item-pp:hover .title-action-container .left-container .symbol-dragPage {
          display: inline-flex; }
          .menu-item-pp:hover .title-action-container .left-container .symbol-dragPage + .has-tooltip {
            margin-left: 5px; }
      .menu-item-pp .title-action-container .left-container .menu-item-title {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #3b4057;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        .hidden-page.menu-item-pp .title-action-container .left-container .menu-item-title {
          color: #868aa5; }
    .menu-item-pp .title-action-container .right-container {
      display: flex;
      align-items: center; }
      .menu-item-pp .title-action-container .right-container > .symbol path {
        fill: #868aa5; }
      .menu-item-pp .title-action-container .right-container > .symbol + .symbol {
        margin-left: 3px; }
      .menu-item-pp .title-action-container .right-container .control-context-menu {
        display: none;
        margin-left: 6px; }
        .selected.menu-item-pp .title-action-container .right-container .control-context-menu {
          display: inline-flex; }
        .show-context-menu-button.menu-item-pp .title-action-container .right-container .control-context-menu {
          display: inline-flex; }
        .menu-item-pp:hover .title-action-container .right-container .control-context-menu {
          display: inline-flex; }
  .menu-item-pp .input-button-container {
    height: 100%;
    display: flex;
    align-items: center; }
    .menu-item-pp .input-button-container .input-container {
      display: inline-flex;
      flex: 1;
      height: 23px; }
      .menu-item-pp .input-button-container .input-container .input {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        height: 23px;
        color: #3b4057;
        border: 1px solid #80b1ff;
        border-radius: 4px;
        box-shadow: none;
        background-color: #fff; }
        .menu-item-pp .input-button-container .input-container .input:hover {
          background-color: #fff; }
    .menu-item-pp .input-button-container .done-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 20px;
      margin-left: 6px;
      border-radius: 12px;
      padding: 0 10px; }
  .menu-item-pp .menu-item-loader {
    width: 20px;
    padding-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center; }

.pages-tree-shrinked .menu-item-pp {
  height: 23px;
  line-height: 23px; }
  .pages-tree-shrinked .menu-item-pp:before {
    padding: 5px 0 0; }
  .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg {
    margin-top: 5px; }
    .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg.symbol-pageItemDropdownIcon {
      margin-top: 9px; }
    .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg.symbol-pageItemHomeIcon {
      margin-top: 6px; }

.menu-item-settings-action-pp + .divider-short {
  margin: 5px 18px 6px 18px; }

.menu-view-pp {
  background-color: #f7f8f8;
  height: 100%;
  width: 336px;
  display: flex;
  flex-direction: column; }
  .menu-view-pp .title-menu-view-pp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px; }
  .menu-view-pp > .pages-tree-container {
    height: calc(100% - 54px - 2 * 12px);
    width: 100%;
    position: relative;
    margin-top: 12px; }
    .menu-view-pp > .pages-tree-container > .pages-tree-docker {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
      .menu-view-pp > .pages-tree-container > .pages-tree-docker .menu-item-pp {
        line-height: inherit; }
    .menu-view-pp > .pages-tree-container.disable-drag .menu-item-pp {
      cursor: default; }
  .menu-view-pp .pages-tree-container > .custom-scroll,
  .menu-view-pp .pages-tree-docker > .custom-scroll {
    position: relative;
    height: 100%; }
    .menu-view-pp .pages-tree-container > .custom-scroll > .outer-container,
    .menu-view-pp .pages-tree-docker > .custom-scroll > .outer-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
      .menu-view-pp .pages-tree-container > .custom-scroll > .outer-container > .inner-container,
      .menu-view-pp .pages-tree-docker > .custom-scroll > .outer-container > .inner-container {
        height: 100%; }
  .menu-view-pp .pages-tree-container .tree-root,
  .menu-view-pp .pages-tree-docker .tree-root {
    box-sizing: border-box;
    padding: 0 17px 20px;
    width: 100%; }
  .menu-view-pp > footer {
    padding: 18px 24px;
    border-top: 1px solid #d9e1e8;
    background-color: #fff;
    box-sizing: border-box; }
    .menu-view-pp > footer > .add-options-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      .menu-view-pp > footer > .add-options-container > .add-menu-page-button {
        display: inline-block; }
        .menu-view-pp > footer > .add-options-container > .add-menu-page-button > .button-content > .symbol {
          margin-right: 8px; }
        .menu-view-pp > footer > .add-options-container > .add-menu-page-button > .button-content > .add-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 157px;
          line-height: 20px; }
      .menu-view-pp > footer > .add-options-container .add-menu-link-button-container,
      .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container {
        margin-left: 12px; }
        .menu-view-pp > footer > .add-options-container .add-menu-link-button-container .control-button,
        .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container .control-button {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 0; }
          .menu-view-pp > footer > .add-options-container .add-menu-link-button-container .control-button > .button-content > .symbol > path,
          .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container .control-button > .button-content > .symbol > path {
            fill: #116dff; }
      .menu-view-pp > footer > .add-options-container .add-menu-page-button-container .add-menu-page-button .symbol-addPage {
        margin-right: 5px; }

.add-tooltip-pp .composite-rich-text-labeled {
  padding: 0; }

.too-many-pages-banner {
  text-align: center;
  border-bottom: 1px solid #e3e3e3; }
  .too-many-pages-banner .control-section-divider {
    height: 44px; }
    .too-many-pages-banner .control-section-divider .control-rich-text {
      text-align: center;
      overflow-wrap: break-word;
      color: #3b4057; }
      .too-many-pages-banner .control-section-divider .control-rich-text a {
        color: #116dff; }
  .too-many-pages-banner .control-section-divider.info {
    background-color: #e7f0ff; }
  .too-many-pages-banner .control-section-divider.count {
    background-color: #fff3d6; }
  .too-many-pages-banner .control-section-divider.blocked {
    background-color: #fde3e1; }
    .too-many-pages-banner .control-section-divider.blocked .control-rich-text a {
      color: #116dff; }

.add-tooltip-pp .custom-learn-more p span {
  display: inline-block;
  padding-top: 6px; }

.add-tooltip-pp_se-madefor-font__title {
  padding-bottom: 6px; }

.menu-view-pp .pages-tree-node {
  margin: 4px 0 0;
  border: 0;
  position: relative;
  z-index: 1; }
  .menu-view-pp .pages-tree-node.pages-tree-node_folder.empty-dropdown .pages-tree {
    height: 48px; }
    .menu-view-pp .pages-tree-node.pages-tree-node_folder.empty-dropdown .pages-tree .dropdown-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px dashed #dfe5eb;
      background: #fff;
      width: 263px;
      height: 42px;
      position: absolute;
      top: 48px;
      right: 0;
      color: #acafc4; }
      .menu-view-pp .pages-tree-node.pages-tree-node_folder.empty-dropdown .pages-tree .dropdown-placeholder:before {
        display: block;
        content: '';
        position: absolute;
        width: 11px;
        height: 55%;
        padding-top: 3px;
        top: -6px;
        left: -12px;
        border: 1px solid #cde;
        border-top: 0;
        border-right: 0; }
      .menu-view-pp .pages-tree-node.pages-tree-node_folder.empty-dropdown .pages-tree .dropdown-placeholder .dropdown-placeholder-text {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
  .menu-view-pp .pages-tree-node:first-child > .menu-item-pp:before {
    height: 50%;
    padding-top: 3px; }
  .menu-view-pp .pages-tree-node .pages-tree-node {
    margin-left: 25px; }
    .menu-view-pp .pages-tree-node .pages-tree-node > .menu-item-pp:before {
      display: block; }
  .menu-view-pp .pages-tree-node .expander {
    display: none;
    background-color: #80b1ff;
    border-radius: 100%;
    color: #1a92df;
    cursor: pointer;
    height: 14px;
    left: -7px;
    font-size: 14px;
    line-height: 10px;
    position: absolute;
    text-align: center;
    top: 14.5px;
    width: 14px;
    z-index: 1445; }

.menu-view-pp .pages-tree-node.drag-id-disabled .menu-item-pp {
  cursor: pointer; }

.menu-view-pp .pages-tree-node.drag-id-disabled .page-item-drag {
  visibility: hidden;
  width: 0; }

.menu-view-pp .pages-tree-shrinked .pages-tree {
  margin: 5px 0 0; }
  .menu-view-pp .pages-tree-shrinked .pages-tree:first-child > .menu-item-pp:before {
    padding-top: 4px; }
  .menu-view-pp .pages-tree-shrinked .pages-tree .expander {
    top: 14.5px; }

.menu-view-pp .has-children.pages-tree-node > .expander {
  display: block; }

.menu-view-pp .tree-collapsed.pages-tree-node + .pages-tree-node {
  margin-top: 12px; }

.menu-view-pp .tree-collapsed .pages-tree-node {
  display: none; }
  .menu-view-pp .tree-collapsed .pages-tree-node.shadow {
    display: block;
    margin-top: 12px; }
    .menu-view-pp .tree-collapsed .pages-tree-node.shadow > .menu-item-pp:before {
      height: 50%;
      padding-top: 4px; }

.menu-view-pp .tree-collapsed > .menu-item-pp,
.menu-view-pp .rotated.has-children > .menu-item-pp {
  margin-bottom: 6px; }

.menu-view-pp .tree-collapsed > .menu-item-pp {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #fff, 0 4px 0 #cde, 0 6px 0 #fff, 0 7px 0 #cde; }

.menu-view-pp .tree-collapsed > .menu-item-pp:hover,
.menu-view-pp .tree-collapsed > .menu-item-pp.selected,
.menu-view-pp .rotated.has-children > .menu-item-pp {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #e7f0ff, 0 4px 0 #cde, 0 6px 0 #e7f0ff, 0 7px 0 #cde; }

.menu-view-pp .rotated > .menu-item-pp {
  background-color: #e7f0ff;
  transform-origin: 50% 50%;
  transform: rotate(-6deg); }
  .menu-view-pp .rotated > .menu-item-pp:before {
    display: none !important; }
  .menu-view-pp .rotated > .menu-item-pp .control-context-menu {
    display: none !important; }

.menu-view-pp .shadow .pages-tree {
  display: none; }

.menu-view-pp .shadow > .expander {
  display: none !important; }

.menu-view-pp .shadow > .menu-item-pp {
  background-color: #dfe5eb;
  border: 1px solid #cde; }
  .menu-view-pp .shadow > .menu-item-pp > * {
    display: none; }

.menu-view-pp_custom-menus {
  background-color: #fff; }
  .menu-view-pp_custom-menus .menu-item-pp {
    border-radius: 4px;
    border: solid 1px #dfe5eb;
    padding-left: 6px;
    height: 36px; }
    .menu-view-pp_custom-menus .menu-item-pp .left-container > .has-tooltip {
      margin-left: 12px !important; }
  .menu-view-pp_custom-menus .menu-item-pp:not(.selected):hover,
  .menu-view-pp_custom-menus .pages-tree-node_hover-box:hover > .menu-item-pp:not(.selected) {
    border: solid 1px #d6e6fe !important;
    background-color: #e7f0ff !important; }
    .menu-view-pp_custom-menus .menu-item-pp:not(.selected):hover .control-context-menu,
    .menu-view-pp_custom-menus .pages-tree-node_hover-box:hover > .menu-item-pp:not(.selected) .control-context-menu {
      display: inline-flex; }
  .menu-view-pp_custom-menus .menu-item-pp.selected {
    border: solid 1px #80b1ff !important; }
  .menu-view-pp_custom-menus .pages-tree-node {
    margin-bottom: 3px; }
  .menu-view-pp_custom-menus .pages-tree-node .pages-tree-node {
    margin-left: 14px; }
  .menu-view-pp_custom-menus .tree-collapsed.pages-tree-node + .pages-tree-node {
    margin: 0; }
  .menu-view-pp_custom-menus .pages-tree-node .pages-tree-node > .menu-item-pp:before {
    visibility: hidden; }
  .menu-view-pp_custom-menus .pages-tree-node_folder > .pages-tree-node_hover-box .menu-item-pp {
    border-color: transparent;
    margin-bottom: 4px !important; }
  .menu-view-pp_custom-menus .pages-tree-node_folder .pages-tree-node:last-child {
    margin-bottom: 12px; }
  .menu-view-pp_custom-menus .pages-tree-node:not(.pages-tree-node_folder) + .pages-tree-node_folder {
    margin-top: 12px; }
  .menu-view-pp_custom-menus .tree-collapsed > .menu-item-pp {
    box-shadow: none !important; }
  .menu-view-pp_custom-menus .has-children > .pages-tree-node_hover-box > .expander {
    display: block; }
  .menu-view-pp_custom-menus .expander {
    width: 16px !important;
    height: 16px !important;
    left: 0 !important;
    top: 10px !important;
    background-color: unset !important;
    border-radius: unset !important; }
  .menu-view-pp_custom-menus .expander svg {
    display: none; }
  .menu-view-pp_custom-menus .expander::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #3b4057;
    position: absolute;
    top: 4px;
    left: 10px;
    transition: all 0.2s ease-out;
    transform: rotate(90deg); }
  .menu-view-pp_custom-menus .tree-collapsed .expander::before {
    transform: rotate(0deg); }

.pages-panel-ui-fixes .pages-tree-node .menu-item-pp::before {
  border: 1px solid #dfe5eb;
  border-top: 0;
  border-right: 0; }

.pages-panel-ui-fixes .pages-tree-node .menu-item-pp .symbol-dragPage g {
  fill: #5999ff; }

.pages-panel-ui-fixes .pages-tree-node.pages-tree-node_folder.empty-dropdown .pages-tree .dropdown-placeholder::before {
  border: 1px solid #dfe5eb;
  border-top: 0;
  border-right: 0; }

.pages-panel-ui-fixes .pages-tree-node .expander {
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #80b1ff;
  color: #116dff;
  top: 14.5px; }
  .pages-panel-ui-fixes .pages-tree-node .expander path {
    fill: #116dff; }

.pages-panel-ui-fixes .has-children.pages-tree-node > .expander {
  display: flex; }

.pages-panel-ui-fixes .tree-collapsed > .menu-item-pp {
  box-shadow: 0 0 0 1px #dfe5eb, 0 3px 0 #fff, 0 4px 0 #dfe5eb, 0 6px 0 #fff, 0 7px 0 #dfe5eb; }

.pages-panel-ui-fixes .shadow > .menu-item-pp,
.pages-panel-ui-fixes .shadow > .menu-item-pp.selected {
  background-color: #f7f8f8;
  border: 1px solid #dfe5eb; }

.pages-view-pp .pages-container-outer {
  height: calc(100% - 54px); }
  .pages-view-pp .pages-container-outer .group-title {
    padding: 0 24px;
    box-sizing: border-box;
    margin-bottom: 9px; }
  .pages-view-pp .pages-container-outer .group-title:first-child {
    padding: 0 24px;
    box-sizing: border-box; }

.pages-view-pp .add-container {
  padding: 18px 0;
  border-top: 1px solid #dfe5eb;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .pages-view-pp .add-container .main-action-row {
    display: flex;
    justify-content: center; }
    .pages-view-pp .add-container .main-action-row .add-button .symbol-addPage {
      margin-right: 8px; }
    .pages-view-pp .add-container .main-action-row .secondary-action-container {
      margin-left: 12px; }
      .pages-view-pp .add-container .main-action-row .secondary-action-container .secondary-action {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 0;
        background-color: #d6e6fe; }
        .pages-view-pp .add-container .main-action-row .secondary-action-container .secondary-action .button-content svg path {
          fill: #116dff; }
  .pages-view-pp .add-container .secondary-action-row {
    margin-top: 8px; }

.page-pp {
  cursor: pointer; }
  .page-pp .title-action-container .right-container {
    display: flex;
    align-items: center;
    line-height: 100%;
    overflow: hidden; }
    .page-pp .title-action-container .right-container .subtitle {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #868aa5;
      margin: 0 6px;
      white-space: nowrap;
      text-align: right; }
    .page-pp .title-action-container .right-container .permission-symbol-container .symbol path {
      fill: #868aa5; }
    .page-pp .title-action-container .right-container .deactivated-symbol-tooltip .symbol path {
      fill: #868aa5; }
    .page-pp .title-action-container .right-container .page-actions {
      display: none;
      margin-left: 6px;
      height: 24px;
      width: 24px;
      padding: 0; }
      .selected.page-pp .title-action-container .right-container .page-actions {
        display: inline-flex; }
      .page-pp:hover .title-action-container .right-container .page-actions {
        display: inline-flex; }
      .page-pp .title-action-container .right-container .page-actions.single-action:hover svg path {
        fill: #fff; }
      .page-pp .title-action-container .right-container .page-actions.single-action:active {
        background-color: #116dff;
        border-color: #116dff; }
        .page-pp .title-action-container .right-container .page-actions.single-action:active svg path {
          fill: #fff; }
      .page-pp .title-action-container .right-container .page-actions.single-action svg {
        width: 16px;
        height: 16px; }
    .page-pp .title-action-container .right-container .info-icon {
      display: none;
      padding-left: 3px;
      align-items: center;
      height: 15px;
      width: 15px; }
      .selected.page-pp .title-action-container .right-container .info-icon {
        display: inline-flex; }
      .page-pp:hover .title-action-container .right-container .info-icon {
        display: inline-flex; }
  .page-pp .title-action-container .left-container {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden; }
    .page-pp .title-action-container .left-container .page-entry-symbol {
      margin-left: 10px;
      margin-right: 6px;
      overflow: visible; }
      .page-pp .title-action-container .left-container .page-entry-symbol.deactivated path {
        fill: #868aa5; }
      .loading.page-pp .title-action-container .left-container .page-entry-symbol {
        visibility: hidden; }
    .page-pp .title-action-container .left-container .page-pp-loader {
      position: absolute;
      width: 20px;
      padding-left: 10px;
      display: none; }
      .loading.page-pp .title-action-container .left-container .page-pp-loader {
        display: inline-flex;
        justify-content: center; }
    .page-pp .title-action-container .left-container .title-text-container,
    .page-pp .title-action-container .left-container .menu-item-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.page-settings-action-pp,
.page-custom-action-pp {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1; }
  .page-settings-action-pp .symbol,
  .page-custom-action-pp .symbol {
    overflow: visible; }
  .page-settings-action-pp .page-disabled-action-text-pp,
  .page-custom-action-pp .page-disabled-action-text-pp {
    color: #939393; }

.group-title-inner {
  padding: 0 6px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: #868aa5;
  max-width: 280px; }

.group-container {
  display: flex;
  justify-content: space-between;
  max-width: 300px; }
  .group-container .group-context-menu-symbol path {
    fill: #868aa5; }
  .group-container .group-context-menu-symbol:hover path {
    fill: #116dff; }
  .group-container .dropdown-selected.is-open .group-context-menu-symbol path {
    fill: #116dff; }

.context-menu-action-icon {
  flex-shrink: 0; }

.transitions-view-pp {
  height: 100%;
  width: 336px; }
  .transitions-view-pp .composite-thumbnails {
    padding: 24px 48px 18px 48px; }
    .transitions-view-pp .composite-thumbnails .t12.label {
      line-height: 14px; }
  .classic-facelift-skin .transitions-view-pp .composite-thumbnails {
    padding-top: 0; }

.dynamic-pages-intro-view-pp {
  height: 100%;
  width: 336px; }
  .dynamic-pages-intro-view-pp .composite-action-set-vertical .control-button {
    margin-top: 12px; }
    .dynamic-pages-intro-view-pp .composite-action-set-vertical .control-button .button-content .symbol-addPage {
      margin-right: 8px;
      margin-left: 0; }
  .dynamic-pages-intro-view-pp > .banner {
    width: 100%;
    height: 162px;
    margin-bottom: 24px; }
  .dynamic-pages-intro-view-pp > .composite-rich-text-labeled {
    padding-bottom: 6px;
    padding-top: 0; }
  .dynamic-pages-intro-view-pp > .composite-promotional-list {
    padding: 6px 24px 12px; }
    .dynamic-pages-intro-view-pp > .composite-promotional-list > .control-promotional-list > .promotional-list-item {
      align-items: center; }
      .dynamic-pages-intro-view-pp > .composite-promotional-list > .control-promotional-list > .promotional-list-item > svg {
        width: 11px;
        height: 10px;
        align-self: flex-start;
        margin-right: 10px;
        margin-top: 4px;
        fill: #25a55a; }
  .classic-facelift-skin .dynamic-pages-intro-view-pp {
    width: 352px; }
    .classic-facelift-skin .dynamic-pages-intro-view-pp > .banner {
      height: 140px; }

.custom-menus-first-time {
  position: relative;
  border: 1px solid #dfe5eb;
  border-radius: 4px;
  background-color: #fff;
  padding: 6px 24px 12px;
  margin: 6px 18px 12px; }

.custom-menus-first-time__header {
  display: flex;
  align-items: center; }

.custom-menus-first-time__header-icon {
  position: absolute;
  top: 12px;
  right: 12px; }

.custom-menus-first-time__image {
  width: 48px;
  height: 48px;
  margin-right: 18px;
  padding-top: 4px;
  flex-shrink: 0; }

.custom-menus-first-time_title {
  color: #3b4057; }

.custom-menus-first-time_text {
  color: #868aa5;
  margin-bottom: 6px; }
  .custom-menus-first-time_text.single-footer-btn {
    margin-bottom: 0; }

.custom-menus-first-time__footer {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  align-items: center; }
  .custom-menus-first-time__footer.single-btn {
    margin-bottom: -6px; }

.custom-menus-first-time-manage-menu-button {
  margin-right: 12px; }

.custom-menus-first-time-help-button {
  font-size: 14px !important; }

.custom-menus-first-time_close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  top: 0;
  right: 0;
  height: auto;
  width: auto;
  cursor: pointer; }

.custom-menus-first-time_updated {
  padding: 12px 18px;
  position: relative;
  margin: 0 18px 12px 18px; }
  .custom-menus-first-time_updated .custom-menus-first-time-manage-menu-button {
    margin-top: 12px; }
  .custom-menus-first-time_updated .custom-menus-first-time_title {
    margin-bottom: 6px; }
  .custom-menus-first-time_updated .custom-menus-first-time_text {
    margin-bottom: 0; }

.classic-facelift-skin .custom-menus-first-time {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 0; }

.signup-settings-dropdown {
  padding-left: 36px; }
  .signup-settings-dropdown .signup-settings-dropdown-label {
    overflow: visible; }

.site-members-settings-pages-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }
  .site-members-settings-pages-panel .settings-section {
    padding-left: 0; }
  .site-members-settings-pages-panel .settings-button-container {
    margin-left: -12px; }
  .site-members-settings-pages-panel .settings-button-container-dev-mode {
    margin-left: 7px; }
  .site-members-settings-pages-panel .custom-signup {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .site-members-settings-pages-panel .first-time-custom-signup {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .site-members-settings-pages-panel .first-time-custom-signup .first-form-splash-container {
      max-width: 310px; }
      .site-members-settings-pages-panel .first-time-custom-signup .first-form-splash-container .text-label-centered .control-label-base.t16 {
        font-size: 24px; }
      .site-members-settings-pages-panel .first-time-custom-signup .first-form-splash-container .paragraph-centered {
        height: 75px;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center; }
    .site-members-settings-pages-panel .first-time-custom-signup .learn-more-text-container {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal; }
  .site-members-settings-pages-panel .top-banner {
    align-items: center;
    margin: 18px 24px 18px 12px; }
    .site-members-settings-pages-panel .top-banner.velo {
      margin: 0; }
    .site-members-settings-pages-panel .top-banner > .topbanner-illustration {
      margin-right: 24px;
      margin-left: 21px; }
  .site-members-settings-pages-panel .disclaimer {
    align-items: center;
    margin: 18px 24px 18px 12px; }
    .site-members-settings-pages-panel .disclaimer.velo {
      margin: 0; }
    .site-members-settings-pages-panel .disclaimer > .disclaimer-illustration {
      margin-right: 24px;
      margin-left: 0px; }
  .site-members-settings-pages-panel .settings-margin {
    margin: 0 36px; }
  .site-members-settings-pages-panel .divider-margin {
    margin-top: 12px; }
  .site-members-settings-pages-panel .learn-more-btn {
    margin-left: 3px; }
  .site-members-settings-pages-panel .backend-calls-settings .settings-margin {
    margin: 0 24px 0 36px; }
  .site-members-settings-pages-panel .backend-calls-settings .info-icon {
    margin-left: 12px; }
  .site-members-settings-pages-panel .backend-calls-settings .backend-calls-toggle {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .site-members-settings-pages-panel .backend-calls-settings .backend-calls-toggle .backend-calls-toggle-title {
      display: flex;
      max-width: 320px;
      align-items: center; }
  .site-members-settings-pages-panel .backend-calls-settings .loader {
    display: flex;
    justify-content: center;
    padding: 12px; }
  .site-members-settings-pages-panel .settings {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .site-members-settings-pages-panel .settings .header {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      background-color: #eff3f5;
      color: #075673;
      text-align: center;
      line-height: 40px; }
    .site-members-settings-pages-panel .settings .policy-link-area {
      padding-left: 36px; }
    .site-members-settings-pages-panel .settings .option {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      display: flex;
      align-items: center; }
      .site-members-settings-pages-panel .settings .option > label {
        width: 100%;
        color: #000624; }
      .site-members-settings-pages-panel .settings .option.unnested {
        margin-left: -22px;
        display: block; }
      .site-members-settings-pages-panel .settings .option.social-option {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        padding: 21px 0;
        margin-left: 2px; }
        .site-members-settings-pages-panel .settings .option.social-option > label {
          width: 100%;
          color: #000624; }
    .site-members-settings-pages-panel .settings .policy-option {
      margin: 21px 0; }
    .site-members-settings-pages-panel .settings .privacy-note-type-option {
      padding-left: 0;
      padding-top: 24px; }
    .site-members-settings-pages-panel .settings .join-community-option {
      padding-top: 24px; }
    .site-members-settings-pages-panel .settings .policy-link {
      padding-left: 0; }
    .site-members-settings-pages-panel .settings .social-icon {
      border-radius: 50%;
      box-shadow: 0 0 6px rgba(43, 86, 114, 0.18);
      background-color: #fff;
      margin-right: 25px;
      flex-shrink: 0; }
      .site-members-settings-pages-panel .settings .social-icon.google {
        padding: 13px 11.9px; }
      .site-members-settings-pages-panel .settings .social-icon.facebook {
        padding: 3px 4.5px; }
      .site-members-settings-pages-panel .settings .social-icon.community {
        padding: 13px 11.5px; }
  .site-members-settings-pages-panel .actions {
    padding-left: 0;
    margin-top: auto; }
  .site-members-settings-pages-panel .control-button svg {
    margin: 0 12px; }
    .site-members-settings-pages-panel .control-button svg * {
      fill: #116dff; }

.page-settings-panel-pp {
  border-radius: 0 4px 4px 0;
  width: 480px;
  height: 100%;
  background-color: #fff;
  z-index: 1254;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35);
  white-space: normal; }
  .page-settings-panel-pp > .page-settings-panel-pp-inner {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner .panel-header {
      border-radius: 0 4px 0 0; }
      .pages-panel-pp-new-workspace .page-settings-panel-pp > .page-settings-panel-pp-inner .panel-header {
        border-radius: 0 0 0 0; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner .page-settings-tabs {
      height: 100%; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner > div {
      display: flex;
      flex-direction: column;
      height: calc(100% - 54px);
      max-height: 572px; }
      .classic-facelift-skin .page-settings-panel-pp > .page-settings-panel-pp-inner > div {
        max-height: 100%; }
  .page-settings-panel-pp .page-settings-panel-pp-content {
    height: calc(100% - 47px);
    color: #395671;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box; }
    .page-settings-panel-pp .page-settings-panel-pp-content,
    .page-settings-panel-pp .page-settings-panel-pp-content .tab,
    .page-settings-panel-pp .page-settings-panel-pp-content .custom-scroll,
    .page-settings-panel-pp .page-settings-panel-pp-content .outer-container,
    .page-settings-panel-pp .page-settings-panel-pp-content .inner-container {
      display: flex;
      flex-direction: column; }
    .page-settings-panel-pp .page-settings-panel-pp-content .tab,
    .page-settings-panel-pp .page-settings-panel-pp-content .container-preloader-iframe,
    .page-settings-panel-pp .page-settings-panel-pp-content .custom-scroll {
      height: 100%; }
    .page-settings-panel-pp .page-settings-panel-pp-content .tab-inner.empty {
      padding-top: 18px;
      padding-left: 38px; }
    .page-settings-panel-pp .page-settings-panel-pp-content :not(.bubble-content) > section {
      border-bottom: 1px solid #dfe5eb;
      padding: 20px 12px 15px 12px; }
      .page-settings-panel-pp .page-settings-panel-pp-content :not(.bubble-content) > section > *:not(.control-text-input):not(.info-icon):not(.social-icon) {
        box-sizing: border-box; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage section {
      border-bottom: 0;
      padding: 0 25px 0 5px;
      margin-bottom: 34px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage p:not(.title) {
      margin: 0 0 13px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage .title {
      color: #3b4057;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 8px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input {
      width: 100%; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > label {
        color: #3b4057; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > input {
        width: 100%;
        color: #1d2d3c; }
        .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > input::placeholder {
          color: #b6c1cd; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle {
      width: 100%;
      display: inline-block; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle > .text-label {
        line-height: 26px;
        float: left;
        width: auto; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle > .icons-container {
        float: right; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle .toggle-with-icon {
        width: 100%; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-button.btn-text {
      line-height: 16px;
      height: 16px;
      font-size: 14px;
      padding: 0;
      min-width: 0; }
  .page-settings-panel-pp .button-control {
    border-radius: 50%;
    border: 0;
    width: 25px;
    height: 25px;
    background-color: rgba(13, 72, 127, 0.3);
    color: #fff;
    box-sizing: border-box;
    line-height: 25px;
    min-width: 0;
    padding: 0; }
    .page-settings-panel-pp .button-control:hover {
      background-color: rgba(13, 72, 127, 0.6); }
    .page-settings-panel-pp .button-control svg {
      margin: 0; }
  .page-settings-panel-pp .symbol-settingsHome {
    margin-right: 10px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-header {
    width: 480px;
    min-height: 47px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label {
      color: rgba(43, 86, 114, 0.9);
      font-size: 14px;
      width: 120px;
      border: 1px solid #dfe5eb;
      border-top: 0;
      border-right: 0;
      box-sizing: border-box;
      position: relative;
      background-color: #e7f0ff;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding: 0 10px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:last-child {
        border-right: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:hover {
        background-color: #d6e6fe;
        border-bottom: 1px solid #d6e6fe; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label.selected {
        border-bottom: 1px solid #fff;
        background-color: #fff;
        color: #3b4057; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:first-child {
        border-left: 0; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs {
    border-bottom: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label {
      border-bottom: 1px solid #dfe5eb;
      padding-bottom: 15px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:last-child {
        border-bottom: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:before {
        top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:after {
        top: 2px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .action-done {
    border-radius: 50%;
    border: 0;
    width: 24px;
    height: 24px;
    background-color: #25a55a;
    color: #fff;
    line-height: 24px;
    min-width: 0;
    padding: 0;
    display: inline-block;
    text-align: center; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .copy-url {
    margin-top: 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .copy-url .btn-text {
      margin-right: 5px;
      height: 24px;
      line-height: 24px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-page-title {
    padding-top: 19px;
    padding-bottom: 14px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-hide-page {
    padding-top: 23px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-hide-page .hide-page-toggle-text {
      margin-left: 12px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-hide-page .hide-page-toggle-text .symbol-switch {
        margin-right: 12px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description {
    padding-top: 26px;
    padding-bottom: 25px;
    min-height: 18px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description .label-with-icon {
      margin-left: 12px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button {
      height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button:not(.btn-text) {
        min-width: 90px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button:not(.btn-text) svg {
          margin-bottom: 1px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: 1px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg path {
          fill: #fff; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label {
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
    margin-left: 12px;
    padding-top: 0;
    padding-bottom: 40px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div {
      display: inline-block;
      width: 227px;
      margin-left: 24px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div h3 {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        margin-top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div p {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 13px;
        margin-bottom: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:before {
      top: 26px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:after {
      top: 28px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label svg {
      margin-left: 11px;
      float: left; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardMobileSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingMobileSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingSelected {
      display: none; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardMobileNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingMobileNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingNotSelected {
      display: block; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardMobileSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingMobileSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingSelected {
      display: block; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardMobileNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingMobileNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingNotSelected {
      display: none; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .composite-thumbnails + .composite-rich-text {
    padding-top: 0; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text {
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol {
      margin-right: 5px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol.symbol-goToURLAction {
        width: 17px;
        height: 17px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol.symbol-goToURLAction path {
          fill: #116dff; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab {
    padding-bottom: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-title,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-bottom: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-top: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz-inner-div {
      margin-left: -12px;
      margin-bottom: -22px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz-inner-div-new {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz {
      padding-top: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .tab-inner {
      margin-bottom: 50px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .tab-inner.google-preview-expanded {
        margin-bottom: 190px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview {
    position: static;
    border-radius: 0;
    border: none;
    width: calc(100% - 10px);
    height: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    padding: 17px 11px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > p {
      padding: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-title {
      margin: 0 0 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-url {
      margin-bottom: 2px; }
  .page-settings-panel-pp .page-settings-tabs + .preloader-page-settings-pp {
    left: 75%; }
  .page-settings-panel-pp .google-preview {
    border-radius: 15px 15px 0 0;
    transition: bottom 0.4s;
    position: absolute;
    bottom: -140px;
    height: 150px;
    width: calc(100% - 7px);
    left: 6px;
    border: 1px solid #dfe5eb;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff; }
    .page-settings-panel-pp .google-preview .google-preview-corner-container {
      position: absolute;
      width: 15px;
      height: 15px;
      bottom: -5px;
      overflow: hidden; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container .google-preview-corner {
        width: 31px;
        height: 31px;
        position: relative;
        border: solid 8px #fff;
        top: -8px;
        left: -8px;
        box-sizing: border-box; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container.left {
        left: -15px; }
        .page-settings-panel-pp .google-preview .google-preview-corner-container.left .google-preview-corner {
          border-radius: 0 0 15px; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container.right {
        right: -15px; }
        .page-settings-panel-pp .google-preview .google-preview-corner-container.right .google-preview-corner {
          border-radius: 0 0 0 15px; }
    .page-settings-panel-pp .google-preview.expanded {
      bottom: -1px; }
      .page-settings-panel-pp .google-preview.expanded .google-preview-header > .symbol-equals {
        display: none; }
      .page-settings-panel-pp .google-preview.expanded .google-preview-header > .symbol-arrowDown {
        display: block; }
    .page-settings-panel-pp .google-preview > p {
      text-align: left;
      margin: 0 40px;
      word-wrap: break-word; }
    .page-settings-panel-pp .google-preview > .google-preview-header {
      border-radius: 15px 15px 0 0;
      position: relative;
      cursor: pointer;
      height: 32px;
      padding-top: 5px;
      text-align: center;
      border: 1px solid #dfe5eb;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      width: 200px;
      background-color: #fff;
      border-bottom: 0;
      margin: 0 auto;
      top: -43px;
      color: #116dff; }
      .page-settings-panel-pp .google-preview > .google-preview-header > svg {
        fill: #116dff; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .symbol-equals {
        margin: 6px auto 5px;
        display: block; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .symbol-arrowDown {
        margin: 2px auto 1px;
        display: none; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .google-preview-header-title {
        margin-top: 5px; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .google-preview-bottom-header-border {
        background-color: #fff;
        width: 100%;
        position: absolute;
        height: 8px;
        bottom: -8px; }
    .page-settings-panel-pp .google-preview > .google-preview-title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      color: #1a0dab;
      line-height: 21px;
      margin-top: -10px; }
      .page-settings-panel-pp .google-preview > .google-preview-title > .google-preview-title-site-name {
        font-weight: 700; }
    .page-settings-panel-pp .google-preview > .google-preview-url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #006621;
      margin-top: 4px;
      margin-bottom: 4px;
      -webkit-user-select: text;
              user-select: text; }
    .page-settings-panel-pp .google-preview > .google-preview-description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      -webkit-font-smoothing: antialiased;
      color: #545454; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .label {
    color: #3b4057;
    font-size: 14px;
    line-height: 24px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page section:first-child {
    border-top: 1px solid #dfe5eb;
    margin-top: 19px;
    padding-top: 24px;
    padding-bottom: 24px; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon {
    position: relative; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileHidden {
      position: absolute;
      top: 3px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileVisible {
      position: absolute;
      top: 5px;
      left: 3px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .label {
      margin-left: 25px;
      overflow: inherit; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop {
    padding-top: 23px;
    padding-bottom: 23px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > span {
      line-height: 24px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg path {
          fill: #fff; }
  .page-settings-panel-pp .page-setting-mobile .symbol-desktopHidden {
    margin-right: 10px; }
  .page-settings-panel-pp .page-setting-mobile .symbol-desktopVisible {
    margin-right: 10px; }

.seo-section-page-address {
  padding-left: 24px;
  position: relative; }
  .seo-section-page-address.blog-seo-setting {
    padding-left: 11px; }

.page-settings-change-homepage-tooltip {
  float: right; }

.indented-members-access-options {
  margin-left: 6px;
  margin-bottom: -21px; }
  .indented-members-access-options .indented-divider {
    margin-top: 20px; }
  .indented-members-access-options .action-with-symbol-container {
    margin-bottom: -5px; }

.tab-seo-new .seo-tab-title-input-section {
  padding: 5px 0; }

.tab-seo-new .google-preview-hover-link:hover {
  text-decoration: none; }

.tab-seo-new .tab-seo-title {
  padding: 6px 0; }

.tab-seo-new .seo-tab-input-section {
  padding: 12px; }

.tab-seo-new .seo-tab-url-section {
  padding: 19px 0; }

.tab-seo-new .seo-tab-page-address-section {
  padding: 12px 12px 20px 12px;
  position: relative; }
  .tab-seo-new .seo-tab-page-address-section .control-text-input {
    margin-bottom: 7px; }
    .tab-seo-new .seo-tab-page-address-section .control-text-input input {
      margin-left: 137px;
      width: calc(100% - 137px); }
  .tab-seo-new .seo-tab-page-address-section .page-address-prefix {
    padding-left: 12px;
    position: absolute;
    top: 43px;
    left: 12px;
    line-height: 35px;
    font-size: 18px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd; }
  .tab-seo-new .seo-tab-page-address-section .page-address-link {
    padding-left: 12px; }

.tab-seo-new .composite-secondary-media {
  padding-top: 0; }

.tab-seo-new .preview-link-container {
  padding: 10px 24px; }
  .tab-seo-new .preview-link-container .symbol {
    margin-right: 3px;
    vertical-align: middle; }
  .tab-seo-new .preview-link-container .control-rich-text {
    vertical-align: middle;
    display: inline; }

.tab-seo-new .seo-image-desc {
  padding: 0 24px;
  box-sizing: border-box; }

.google-preview-hover-link:hover {
  text-decoration: none; }

.tab-seo-title {
  padding: 6px 0; }

.seo-tab-title-input-section {
  padding-top: 8px;
  padding-bottom: 7px; }

.seo-tab-input-section {
  padding: 12px 0 12px 12px; }

.seo-tab-url-section {
  padding: 19px 0; }

.seo-tab-page-address-section {
  padding: 12px 0 20px 12px;
  position: relative; }
  .seo-tab-page-address-section .control-text-input {
    margin-bottom: 7px; }
    .seo-tab-page-address-section .control-text-input input {
      margin-left: 137px;
      width: calc(100% - 137px); }
  .seo-tab-page-address-section .page-address-prefix {
    padding-left: 12px;
    position: absolute;
    top: 43px;
    left: 12px;
    line-height: 35px;
    font-size: 18px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd; }
  .seo-tab-page-address-section .page-address-link {
    padding-left: 12px; }

.social-share-preview-tooltip {
  width: 438px;
  height: 370px; }
  .social-share-preview-tooltip .control-label {
    display: block;
    margin-left: 6px; }
  .social-share-preview-tooltip .preview-container {
    width: 412px;
    height: 295px;
    background-color: #f7f8f8;
    border: 1px #dfe5eb solid;
    margin-top: 6px;
    margin-bottom: 10px; }
    .social-share-preview-tooltip .preview-container .control-rich-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 10px;
      width: 380px; }
  .social-share-preview-tooltip .seo-image-desc {
    padding: 0 6px;
    box-sizing: border-box; }
  .social-share-preview-tooltip .link {
    color: #868aa5;
    margin-top: 7px; }
  .social-share-preview-tooltip .description {
    margin-top: 2px; }

.page-info .page-settings-control-description .home-page-span-text {
  padding-left: 12px; }

.page-info .page-settings-control-description .control-button {
  margin-right: 12px; }

.page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info {
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 0; }
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .accordion-header:hover {
    background-color: #fff; }
    .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .accordion-header:hover .control-label {
      color: #116dff;
      cursor: pointer; }
    .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .accordion-header:hover .symbol-newAccordionClose path,
    .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .accordion-header:hover .symbol-newAccordionOpen path {
      fill: #116dff; }
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion > .accordion-header > .control-label {
    margin-left: 128px;
    font-size: 16px;
    padding: 13px 0 14px 0;
    color: #3b4057;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .symbol-newAccordionClose path,
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .control-accordion .symbol-newAccordionOpen path {
    fill: #3b4057; }
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .accordion-icon {
    margin-left: 12px; }
  .page-info .pages-info-advanced-settings-info section.pages-info-advanced-settings-info .accordion-icon.open {
    margin-left: 13px;
    margin-right: 12px; }

.page-info .pages-info-advanced-settings-info .advanced-setting-info-text .control-rich-text {
  padding-left: 12px;
  padding-right: 12px; }

.page-info .pages-info-advanced-settings-switch {
  padding-left: 21px;
  padding-top: 20px;
  padding-right: 20px;
  margin-bottom: 17px; }
  .page-info .pages-info-advanced-settings-switch .pages-info-ssr-cache-switch {
    margin-left: 4px; }

.page-info .pages-info-ssr-cache-drop-down-status {
  margin-right: 24px;
  margin-left: 24px;
  padding-top: 16px;
  padding-bottom: 6px; }

.page-info .ssr-cache-warning {
  width: 100%; }
  .page-info .ssr-cache-warning .ssr-cache-warning-section {
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-right: 12px; }
    .page-info .ssr-cache-warning .ssr-cache-warning-section .ssr-cache-warning-icon {
      float: left;
      fill: #3b4057;
      margin-left: 4px; }
    .page-info .ssr-cache-warning .ssr-cache-warning-section .ssr-cache-warning-text {
      padding-left: 46px;
      padding-right: 10px; }
    .page-info .ssr-cache-warning .ssr-cache-warning-section .ssr-cache-warning-link:hover {
      text-decoration: underline;
      cursor: pointer; }

.page-info .ssr-cache-drop-down-container {
  margin-right: 24px;
  margin-left: 24px;
  padding-top: 16px;
  padding-bottom: 6px; }

.layout-mobile {
  padding: 20px 12px 15px 12px; }
  .layout-mobile .tab-header {
    padding-right: 24px; }
    .layout-mobile .tab-header .tab-label {
      margin-top: 0;
      margin-left: 12px; }
      .layout-mobile .tab-header .tab-label > div {
        color: #395671; }
        .layout-mobile .tab-header .tab-label > div h3 {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 18px;
          margin-top: 0; }
        .layout-mobile .tab-header .tab-label > div p {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 13px;
          margin-bottom: 0; }
          .layout-mobile .tab-header .tab-label > div p span {
            line-height: 16px;
            font-size: 14px;
            color: #116dff; }
            .layout-mobile .tab-header .tab-label > div p span:hover {
              text-decoration: underline; }
      .layout-mobile .tab-header .tab-label:before {
        top: 40px; }
      .layout-mobile .tab-header .tab-label:after {
        top: 42px; }
      .layout-mobile .tab-header .tab-label:last-child {
        margin-top: 20px; }
  .layout-mobile .tab-mobile-layout-footer {
    padding: 18px 12px;
    position: absolute;
    bottom: 0; }
    .layout-mobile .tab-mobile-layout-footer .tab-mobile-layout-footer-text {
      line-height: 18px;
      font-size: 12px;
      color: #3b4057; }

.blog-manage-panel {
  height: 100%; }

.blog-manage-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  .blog-manage-panel-container .content {
    min-height: 350px; }
    .blog-manage-panel-container .content p {
      font-size: 18px; }
    .blog-manage-panel-container .content .illustration {
      margin-top: 30px;
      margin-bottom: 14px; }

.blog-manage-panel {
  height: 100%; }
  .blog-manage-panel p {
    font-size: 18px; }
  .blog-manage-panel svg {
    margin-top: 30px;
    margin-bottom: 14px; }

.delete-old-blog-panel {
  display: flex;
  box-sizing: border-box;
  padding: 18px;
  border-top: 1px solid #dfe5eb;
  text-align: justify; }
  .delete-old-blog-panel .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 272px; }
  .delete-old-blog-panel p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    padding-bottom: 10px;
    color: #3b4057;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    line-height: 21px;
    font-size: 14px; }
  .delete-old-blog-panel a {
    color: #116dff;
    text-decoration: none;
    cursor: pointer; }
  .delete-old-blog-panel h4 {
    margin-top: 0;
    margin-bottom: 6px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #3b4057;
    font-size: 15px;
    line-height: 22px;
    font-weight: normal; }
  .delete-old-blog-panel .warning {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-right: 12px; }

.new-blog-migration-panel {
  display: flex;
  box-sizing: border-box;
  padding: 18px;
  border-top: 1px solid #dfe5eb;
  text-align: justify; }
  .new-blog-migration-panel .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 272px; }
  .new-blog-migration-panel p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    padding-bottom: 10px;
    color: #3b4057;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .new-blog-migration-panel a {
    color: #116dff;
    text-decoration: none;
    cursor: pointer; }
  .new-blog-migration-panel .newBlogIcon {
    flex: none;
    margin-right: 12px; }
  .new-blog-migration-panel h4 {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap; }
  .new-blog-migration-panel .btn-switch {
    height: 32px;
    font-size: 14px; }

.blog-migration-success-slide {
  color: #3b4057;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .blog-migration-success-slide .content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .blog-migration-success-slide .migration-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 33px auto 23px auto; }
  .blog-migration-success-slide .title {
    margin-top: 0;
    margin-bottom: 18px;
    line-height: 36px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 30px;
    font-weight: normal; }
  .blog-migration-success-slide .articles-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    padding: 0 100px;
    margin-bottom: 14px; }
  .blog-migration-success-slide .articles {
    text-align: left;
    margin-bottom: 18px; }
    .blog-migration-success-slide .articles .article {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      margin-bottom: 18px; }
      .blog-migration-success-slide .articles .article .article-text {
        display: flex;
        align-items: center; }
        .blog-migration-success-slide .articles .article .article-text .article-link {
          padding-left: 5px; }
        .blog-migration-success-slide .articles .article .article-text .article-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 42px;
          margin-right: 12px;
          color: #162d3d;
          border: 1px solid #d6e1e8;
          border-radius: 21px;
          box-sizing: border-box; }
  .blog-migration-success-slide .btn-confirm-primary {
    min-width: 140px; }
  .blog-migration-success-slide a {
    color: #116dff;
    text-decoration: none; }

.blog-migration-failure-slide {
  color: #3b4057;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .blog-migration-failure-slide .content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .blog-migration-failure-slide .migration-failed-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 55px auto 36px auto; }
  .blog-migration-failure-slide .title {
    margin-top: 0;
    margin-bottom: 18px;
    line-height: 36px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 30px;
    font-weight: normal; }
  .blog-migration-failure-slide .subtitle {
    width: 354px;
    margin-top: 0;
    margin-bottom: 37px;
    line-height: 24px;
    text-align: center;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased; }
  .blog-migration-failure-slide .btn-confirm-primary {
    min-width: 140px; }
  .blog-migration-failure-slide a {
    color: #116dff;
    text-decoration: none; }
  .blog-migration-failure-slide .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 57px;
    text-align: center;
    border-top: 1px solid #d6e1e8; }
  .blog-migration-failure-slide .footer-link {
    margin-left: 5px; }

.magic-migration-slide .blog-promo-slide {
  padding: 0 20px; }
  .magic-migration-slide .blog-promo-slide .title {
    font-size: 26px;
    line-height: 36px; }
  .magic-migration-slide .blog-promo-slide .subTitle {
    font-size: 14px; }
  .magic-migration-slide .blog-promo-slide .pros {
    padding: 0 60px; }
    .magic-migration-slide .blog-promo-slide .pros .bullet {
      font-size: 14px; }
  .magic-migration-slide .blog-promo-slide .btn-close.dark {
    background-color: #d6e6fe; }
    .magic-migration-slide .blog-promo-slide .btn-close.dark:hover {
      background-color: #e7f0ff; }
    .magic-migration-slide .blog-promo-slide .btn-close.dark:active {
      background-color: #d6e6fe; }

.blog-promo-illustration-container {
  display: flex;
  justify-content: center;
  padding: 16px 0; }

.blog-magic-migration-success-slide,
.blog-magic-migration-failure-slide {
  color: #3b4057;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .blog-magic-migration-success-slide .content,
  .blog-magic-migration-failure-slide .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 188px);
    padding: 0 70px; }
  .blog-magic-migration-success-slide .illustration,
  .blog-magic-migration-failure-slide .illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 48px auto 30px auto; }
  .blog-magic-migration-success-slide .title,
  .blog-magic-migration-failure-slide .title {
    margin-top: 0;
    margin-bottom: 18px;
    line-height: 36px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 26px;
    font-weight: normal;
    text-align: center; }
  .blog-magic-migration-success-slide .subtitle,
  .blog-magic-migration-failure-slide .subtitle {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    text-align: center;
    margin-bottom: 32px; }
  .blog-magic-migration-success-slide .btn-confirm-primary,
  .blog-magic-migration-failure-slide .btn-confirm-primary {
    min-width: 140px;
    margin-bottom: 18px; }
  .blog-magic-migration-success-slide a,
  .blog-magic-migration-failure-slide a {
    color: #116dff;
    text-decoration: none; }
  .blog-magic-migration-success-slide .footer,
  .blog-magic-migration-failure-slide .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #d9e1e8;
    height: 66px; }
    .blog-magic-migration-success-slide .footer .footer-link,
    .blog-magic-migration-failure-slide .footer .footer-link {
      margin-left: 5px; }
  .blog-magic-migration-success-slide .btn-close,
  .blog-magic-migration-failure-slide .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    display: inline-block;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    fill: #116dff;
    cursor: pointer;
    text-align: center;
    background-color: #d6e6fe; }
    .blog-magic-migration-success-slide .btn-close:hover,
    .blog-magic-migration-failure-slide .btn-close:hover {
      background-color: #e7f0ff; }
    .blog-magic-migration-success-slide .btn-close:active,
    .blog-magic-migration-failure-slide .btn-close:active {
      background-color: #d6e6fe; }

.blog-promo-slide {
  color: #3b4057;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .blog-promo-slide .content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .blog-promo-slide .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    display: inline-block;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    fill: #116dff;
    cursor: pointer;
    text-align: center;
    background-color: #fff; }
    .blog-promo-slide .btn-close:hover {
      background-color: #e7f0ff; }
    .blog-promo-slide .btn-close:active {
      background-color: #fff; }
  .blog-promo-slide .banner {
    width: 100%;
    margin-bottom: 30px; }
  .blog-promo-slide .title {
    margin: 0 0 18px;
    line-height: 38px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 30px;
    font-weight: normal; }
  .blog-promo-slide .subTitle {
    margin: 0 36px 24px;
    line-height: 22px;
    text-align: center;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px; }
  .blog-promo-slide .pros {
    text-align: left;
    margin-bottom: 18px; }
    .blog-promo-slide .pros .bullet {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      margin-bottom: 18px; }
      .blog-promo-slide .pros .bullet .bullet-symbol {
        float: left;
        margin-right: 14px; }
      .blog-promo-slide .pros .bullet .bullet-text {
        padding-left: 32px;
        margin: 0; }
  .blog-promo-slide .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6px; }
    .blog-promo-slide .buttons .control-button {
      min-width: 140px;
      margin-bottom: 12px; }
  .blog-promo-slide a {
    color: #116dff;
    text-decoration: none; }

.blog-upgrade-panel .panel-header-illustration > .blue-strip {
  display: block; }

.blog-upgrade-panel .blog-upgrade-panel-info {
  margin: 10px 50px 0;
  padding-bottom: 0;
  line-height: 1.5; }

.blog-upgrade-panel .blog-upgrade-migration-label {
  margin: 8px 0 0 50px;
  padding-bottom: 10px; }
  .blog-upgrade-panel .blog-upgrade-migration-label .control-rich-text {
    display: flex; }

.blog-upgrade-panel .blog-upgrade-migration-selection {
  margin-left: 50px;
  padding-top: 0; }

.blog-upgrade-panel .blog-upgrade-migrations-cta {
  margin-top: 18px; }

.blog-upgrade-panel .blog-upgrade-info-icon {
  cursor: pointer;
  fill: #116dff;
  margin-left: 5px; }

.blog-upgrade-migration-tooltip-first-section {
  margin-bottom: 15px; }

.ascend-marketing-panel {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-top: 36px; }

.upgrade-ascend-panel {
  position: relative; }
  .upgrade-ascend-panel .body {
    display: flex;
    flex-direction: column;
    padding: 18px 22px 0 22px; }
    .upgrade-ascend-panel .body .title {
      height: 72px;
      margin: 29px 0 18px 0; }
    .upgrade-ascend-panel .body .upgrade-button {
      background-color: #9a27d5;
      margin: 20px 0 59px 0; }
      .upgrade-ascend-panel .body .upgrade-button:hover {
        background-color: #cd68ed; }
    .upgrade-ascend-panel .body .logo {
      margin: auto; }
    .upgrade-ascend-panel .body .button-logo {
      margin-right: 7px; }
      .upgrade-ascend-panel .body .button-logo #ascend-upper-fill {
        fill: #ffa4a7; }
  .upgrade-ascend-panel .footer {
    background-color: #f7f8f8;
    display: flex;
    flex-direction: column;
    height: 75px;
    position: absolute;
    bottom: 0;
    width: 100%; }
    .upgrade-ascend-panel .footer .plans {
      display: flex; }
      .upgrade-ascend-panel .footer .plans .plan-box {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 16px 24px 18px 24px;
        width: 30%; }
      .upgrade-ascend-panel .footer .plans .footer-divider {
        width: 1px;
        height: 41px;
        background-color: #dfe5eb;
        margin-top: 16px; }

.analytics-panel .body {
  display: flex;
  flex-direction: column;
  padding: 18px 24px 0 24px;
  align-items: center; }
  .analytics-panel .body .title {
    margin: 24px 0 10px 0; }
  .analytics-panel .body .subtitle .control-label {
    color: #868aa5; }
  .analytics-panel .body .analytics-button {
    margin: 24px 0 70px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .analytics-panel .body .logo {
    margin: auto; }

.virtual-phone-number-panel .body {
  display: flex;
  flex-direction: column;
  padding: 18px 24px 0 24px;
  align-items: center; }
  .virtual-phone-number-panel .body .title {
    margin: 24px 0 10px 0; }
  .virtual-phone-number-panel .body .subtitle .control-label {
    color: #868aa5; }
  .virtual-phone-number-panel .body .virtual-phone-number-button {
    margin: 24px 0 70px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .virtual-phone-number-panel .body .logo {
    margin: auto; }

.scrollable-wrapper {
  overflow-y: auto; }
  .scrollable-wrapper .title-with-list-panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
    .scrollable-wrapper .title-with-list-panel .description-and-button {
      padding: 36px 24px 0 24px; }
      .scrollable-wrapper .title-with-list-panel .description-and-button .title {
        padding-bottom: 10px; }
      .scrollable-wrapper .title-with-list-panel .description-and-button .subtitle .control-label {
        color: #868aa5; }
      .scrollable-wrapper .title-with-list-panel .description-and-button .button {
        padding-top: 24px; }

.ascend-product-item {
  display: flex;
  align-items: center;
  padding: 18px 24px 18px 24px;
  height: 70px; }
  .ascend-product-item:hover {
    background-color: #e7f0ff;
    cursor: pointer; }
  .ascend-product-item .title-and-description {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 205px; }
    .ascend-product-item .title-and-description .description .control-label {
      color: #868aa5; }
  .ascend-product-item .control-label:hover {
    cursor: pointer; }
  .ascend-product-item .logo {
    margin-right: 24px; }
  .ascend-product-item .tooltip {
    margin-left: 12px; }

.ascend-section-title {
  margin: 0 auto 5px; }
  .ascend-section-title .title-line {
    margin-top: 52px;
    font-size: 14px;
    position: relative;
    border-bottom: 1px solid #8092a4;
    padding-bottom: 4px;
    color: #3b4057;
    display: flex; }
    .ascend-section-title .title-line .title {
      display: inline-block;
      width: 100%;
      margin-top: -1px; }
  .ascend-section-title .info-icon svg path {
    fill: #116dff; }
    .composite-banner .ascend-section-title .info-icon svg path {
      fill: #fff; }

.info-icon-image-container {
  position: relative;
  cursor: pointer; }
  .info-icon-image-container .info-icon-image {
    border: 4px solid #fff;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #dfe5eb;
    width: 204px; }
    .info-icon-image-container:hover .info-icon-image {
      box-shadow: 0 0 0 2px #d6e6fe; }
  .info-icon-image-container .symbol-play-video {
    position: absolute;
    top: calc(50% - 42px / 2);
    left: calc(50% - 42px / 2);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: rgba(22, 45, 61, 0.7);
    border: 2px solid #fff; }
    .info-icon-image-container:hover .symbol-play-video {
      border-color: #80b1ff; }
    .info-icon-image-container .symbol-play-video .st0 {
      fill: #fff; }
      .info-icon-image-container:hover .symbol-play-video .st0 {
        fill: #80b1ff; }
    .info-icon-image-container .symbol-play-video .st1 {
      fill: transparent; }

.new-workspace-ascend-section-title {
  margin: 52px 24px 0 24px; }

.ascend-product-list .list-title {
  display: flex;
  border-bottom: 1px solid #3b4057;
  padding-bottom: 3px;
  margin: 52px 24px 16px 24px;
  color: #3b4057; }

.ascend-first-time-experience {
  background: #fff;
  height: 100%;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .ascend-first-time-experience .get-started-slide .main-image {
    margin-top: 0; }
  .ascend-first-time-experience .get-started-slide .title {
    margin: 0; }
  .ascend-first-time-experience .get-started-slide .sub-title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    margin-bottom: 0; }
  .ascend-first-time-experience .get-started-slide .tip-bullets {
    margin: 0 30px; }
    .ascend-first-time-experience .get-started-slide .tip-bullets .bullet .bullet-text {
      padding-left: 32px; }

.ascend-custom-header {
  margin: 5px 0 25px 0; }

.em-marketing-bullets .em-bullet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 12px; }
  .em-marketing-bullets .em-bullet .em-bullet-point {
    margin-right: 18px;
    margin-top: 2px; }

.em-marketing-bullets :last-child {
  margin-bottom: 0px; }

.em-marketing-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .em-marketing-panel .em-body {
    padding: 18px 18px 0; }
    .em-marketing-panel .em-body .em-title {
      margin: 24px 0; }
    .em-marketing-panel .em-body .em-logo {
      margin: auto; }
    .em-marketing-panel .em-body .em-marketing-actions {
      margin: 18px 0 0; }

.em-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .em-panel .em-body {
    padding: 18px 18px 0; }
    .em-panel .em-body .em-title {
      margin: 24px 0; }
    .em-panel .em-body .em-logo {
      margin: auto; }
    .em-panel .em-body .em-buttons-container {
      display: flex;
      flex-direction: column; }
      .em-panel .em-body .em-buttons-container .em-button {
        margin-top: 24px; }
      .em-panel .em-body .em-buttons-container .em-text-button {
        margin-top: 18px; }
  .em-panel .em-footer {
    border-top: 1px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    padding: 18px; }
    .em-panel .em-footer .em-footer-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left; }
    .em-panel .em-footer .em-footer-right {
      display: flex;
      align-items: center; }

.blog-pages-panel {
  height: 100%; }

.wix-store-pages-panel,
.blog-pages-panel {
  background-color: #f5f7f9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .wix-store-pages-panel ul,
  .blog-pages-panel ul {
    margin: 0 auto; }
  .wix-store-pages-panel .first-time-info-box,
  .blog-pages-panel .first-time-info-box {
    margin-top: 0;
    order: 0;
    flex: 0 0 auto;
    margin-bottom: 24px; }
  .wix-store-pages-panel .pages-panel-add-gallery-section,
  .blog-pages-panel .pages-panel-add-gallery-section {
    min-height: 90px;
    padding: 14px 40px 25px 40px;
    word-wrap: break-word;
    border-top: solid 1px #cde;
    background: #ffffff;
    z-index: 1;
    box-sizing: border-box;
    order: 0;
    flex: 0 0 auto; }
    .wix-store-pages-panel .pages-panel-add-gallery-section .pages-panel-add-label,
    .blog-pages-panel .pages-panel-add-gallery-section .pages-panel-add-label {
      color: #3b4057;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
      margin: 0; }
    .wix-store-pages-panel .pages-panel-add-gallery-section svg.addPage,
    .blog-pages-panel .pages-panel-add-gallery-section svg.addPage {
      position: relative;
      top: -25px;
      left: 65px; }
      .wix-store-pages-panel .pages-panel-add-gallery-section svg.addPage path,
      .blog-pages-panel .pages-panel-add-gallery-section svg.addPage path {
        fill: #ffffff; }
    .wix-store-pages-panel .pages-panel-add-gallery-section .add-page-btn,
    .blog-pages-panel .pages-panel-add-gallery-section .add-page-btn {
      margin: 13px auto 2px auto;
      display: block;
      min-width: 185px;
      max-width: 270px; }
      .wix-store-pages-panel .pages-panel-add-gallery-section .add-page-btn span,
      .blog-pages-panel .pages-panel-add-gallery-section .add-page-btn span {
        margin-right: 6px; }
  .wix-store-pages-panel .panel-header > .panel-header-title,
  .blog-pages-panel .panel-header > .panel-header-title {
    font-size: 18px; }
  .super-app-panel-new-workspace .wix-store-pages-panel, .super-app-panel-new-workspace
  .blog-pages-panel {
    background-color: #fff; }
    .super-app-panel-new-workspace .wix-store-pages-panel .pages-panel-shadow, .super-app-panel-new-workspace
    .blog-pages-panel .pages-panel-shadow {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35);
      border-radius: 0; }

.wix-stores-manage-panel-with-dealer {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .wix-stores-manage-panel-with-dealer .content-wrapper {
    display: flex;
    flex-direction: column; }
  .wix-stores-manage-panel-with-dealer .preloader-center {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center; }
  .wix-stores-manage-panel-with-dealer .main-info {
    padding: 18px 24px; }
  .wix-stores-manage-panel-with-dealer .main-info-cta-container {
    display: inline-block; }
  .wix-stores-manage-panel-with-dealer .main-info-cta-button {
    display: block;
    width: 100%; }
  .wix-stores-manage-panel-with-dealer .main-info-secondary-cta-button {
    display: block;
    margin-top: 12px;
    width: 100%; }
  .wix-stores-manage-panel-with-dealer .text-center {
    text-align: center; }
  .wix-stores-manage-panel-with-dealer .title {
    color: #3b4057;
    margin-bottom: 14px;
    margin-top: 18px; }
  .wix-stores-manage-panel-with-dealer .subtitle {
    color: #868aa5;
    margin-bottom: 24px; }
  .wix-stores-manage-panel-with-dealer .quick-actions-header {
    color: #3b4057;
    padding: 0 24px 16px;
    text-align: left; }
    .wix-stores-manage-panel-with-dealer .quick-actions-header hr {
      background-color: #3b4057; }
  .wix-stores-manage-panel-with-dealer .quick-actions-header-text {
    line-height: 18px;
    margin-top: 22px; }
  .wix-stores-manage-panel-with-dealer .quick-actions {
    flex: 1;
    padding-bottom: 12px; }
  .wix-stores-manage-panel-with-dealer .quick-action {
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 24px; }
    .wix-stores-manage-panel-with-dealer .quick-action:hover {
      background-color: #e7f0ff; }
  .wix-stores-manage-panel-with-dealer .quick-action-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .wix-stores-manage-panel-with-dealer .quick-action-text {
    align-items: center;
    color: #3b4057;
    cursor: pointer;
    display: flex;
    flex: 1;
    height: 40px;
    line-height: 24px;
    margin: 18px 12px;
    overflow: hidden; }

.intent-welcome, .izettle-welcome {
  width: 670px; }
  .intent-welcome .panel-header, .izettle-welcome .panel-header {
    height: 215px; }
  .intent-welcome .welcome-panel-header-title, .izettle-welcome .welcome-panel-header-title {
    padding-top: 55px; }
  .intent-welcome .store-image, .izettle-welcome .store-image {
    margin-top: 30px; }
  .intent-welcome .content, .izettle-welcome .content {
    margin-bottom: 20px;
    margin-top: 73px; }
  .intent-welcome .find-button, .izettle-welcome .find-button {
    text-align: center;
    margin: 10px 0; }
  .intent-welcome .btn-text, .izettle-welcome .btn-text {
    font-size: 16px; }

.izettle-welcome .welcome-panel-header-title {
  font-size: 20px; }

.izettle-welcome .content {
  margin-bottom: 44px;
  margin-top: 30px; }

.manage-bookings {
  padding-top: 26px;
  padding-bottom: 29px;
  width: 100%;
  height: 139px;
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/wixBookings/appMenuPanel/main/manageBookings/manageBookings_01.png") center no-repeat; }

.wix-bookings-manage-panel .control-section-divider {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.wix-bookings-manage-panel .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left; }

.wix-bookings-manage-panel .wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%; }

.wix-bookings-manage-panel-list-item {
  display: flex;
  grid-template-columns: 1fr 4fr; }
  .wix-bookings-manage-panel-list-item:hover {
    background-color: #eaf7ff; }
  .wix-bookings-manage-panel-list-item-symbol {
    border-bottom: 1px solid #d9e1e8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 20%;
    cursor: pointer; }
  .wix-bookings-manage-panel-list-item-symbol-wrapper {
    border-radius: 20px;
    height: 38px;
    width: 38px;
    border: 1px solid #d9e1e8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; }
  .wix-bookings-manage-panel-list-item .control-drill-in-list-item {
    flex-basis: 80%;
    padding-left: 0; }
    .wix-bookings-manage-panel-list-item .control-drill-in-list-item > .content {
      width: initial; }

.wix-bookings-manage-panel-filler {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.wix-bookings-manage-panel-footer {
  width: 354px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .wix-bookings-manage-panel-footer .composite-button-medium {
    padding: 0;
    margin-left: 5px; }
  .wix-bookings-manage-panel-footer .control-button.btn-text {
    font-size: 14px; }

.wix-bookings-manage-panel-setup {
  margin: 18px 24px 12px 24px; }
  .wix-bookings-manage-panel-setup-title {
    font-size: 16px;
    margin-bottom: 6px; }
  .wix-bookings-manage-panel-setup-description {
    font-size: 13px;
    margin-bottom: 12px; }

.wix-bookings-manage-panel-skip-button-composite.composite-button-medium {
  padding: 0; }

.wix-bookings-manage-panel-skip-button-composite .control-button.btn-text {
  font-size: 14px;
  height: 30px; }

.super-app-menu-panel.dynamic-height {
  max-height: 626px; }

.wix-bookings-panel .symbol-plus {
  display: none; }

.wix-bookings-panel .first-time-install-app .title {
  margin-left: 30px;
  margin-right: 30px; }

.wix-bookings-panel .first-time-install-app .sub-title {
  margin-left: 78px;
  margin-right: 78px;
  white-space: pre-line; }

.wix-bookings-panel-one-widget .super-app-add-elements-panel .custom-scroll .outer-container .positioning {
  display: none; }

.wix-bookings-panel-one-widget .super-app-add-elements-panel .custom-scroll .outer-container .content-wrapper {
  overflow-y: hidden !important; }

.video-search-panel {
  min-width: 430px; }
  .video-search-panel > .focus-panel-frame-content {
    position: relative; }
    .video-search-panel > .focus-panel-frame-content .control-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 32px;
      min-width: 107px;
      max-width: 195px; }
  .video-search-panel .content-wrapper {
    display: flex;
    flex-direction: column; }
  .video-search-panel .search-info {
    height: 57px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0 29px;
    font-size: 16px; }
  .video-search-panel .video-search-content {
    height: calc(100% - 180px);
    margin: 0 15px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-search-panel .video-search-content .custom-scroll {
      display: flex;
      height: 100%;
      width: 100%; }
    .video-search-panel .video-search-content .outer-container {
      display: flex; }
    .video-search-panel .video-search-content .video-search-results {
      margin-left: 5px; }
    .video-search-panel .video-search-content .video-search-empty-state {
      margin-top: -30px;
      text-align: center;
      font-size: 18px; }
  .video-search-panel footer {
    height: 69px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    box-sizing: border-box; }

.video-preview-modal .focus-panel-frame-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .video-preview-modal .focus-panel-frame-content header {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 0 10px; }
    .video-preview-modal .focus-panel-frame-content header .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      font-weight: normal;
      color: #000624;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .video-preview-modal .focus-panel-frame-content header .close {
      position: relative;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: none;
      padding: 0;
      background-color: #d6e6fe;
      outline: none;
      flex-shrink: 0;
      margin-left: 5px; }
      .video-preview-modal .focus-panel-frame-content header .close svg {
        fill: #116dff;
        transform: scale(0.8);
        position: absolute;
        top: -2px;
        left: -2px; }
      .video-preview-modal .focus-panel-frame-content header .close:hover {
        background-color: #e7f0ff; }
  .video-preview-modal .focus-panel-frame-content main {
    bottom: 10px;
    left: 10px;
    width: 640px;
    height: 345px;
    flex-shrink: 0; }
  .video-preview-modal .focus-panel-frame-content footer {
    border-radius: 0 0 8px 8px;
    height: 54px;
    width: 100%;
    background: #fff;
    bottom: 0; }

.video-search-results {
  display: flex;
  flex-wrap: wrap; }
  .video-search-results .load-more {
    width: 100%;
    text-align: center;
    height: 50px;
    position: relative; }

.video-search-item {
  display: block;
  width: 174px;
  height: 123px;
  position: relative;
  margin: 5px;
  cursor: pointer; }
  .video-search-item img {
    width: 100%;
    height: 100%; }
  .video-search-item .title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 32px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(22, 45, 61, 0.65);
    color: #fff;
    line-height: 32px;
    text-align: left;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box; }
  .video-search-item .open-preview-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    text-align: center;
    position: absolute;
    bottom: 40px;
    left: 6px; }
    .video-search-item .open-preview-button span {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4.5px 0 4.5px 7px;
      margin-left: 2px;
      margin-top: -0.5px;
      border-color: transparent transparent transparent #ffffff; }
    .video-search-item .open-preview-button:hover {
      background-color: #5999ff; }
  .video-search-item .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 3px #116dff solid;
    border-radius: 5px;
    left: -3px;
    top: -3px;
    opacity: 0; }
  .video-search-item:hover:not(.selected) .overlay {
    opacity: 0.5;
    background-color: #116dff; }
  .video-search-item.selected .overlay {
    opacity: 1; }
  .video-search-item.selected .title {
    background-color: #116dff; }

.video-search-form {
  position: relative;
  background-color: #a8caff;
  padding: 11px 18px;
  display: flex; }
  .video-search-form .control-button[disabled] {
    color: #116dff;
    background-color: #e7f0ff; }
  .video-search-form input {
    flex-grow: 1;
    margin-right: 9px;
    line-height: 16px;
    padding: 0 24px 0 37px;
    border-radius: 18px;
    border: none;
    outline: none;
    font-size: 14px; }
    .video-search-form input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #fff inset; }
    .video-search-form input::-ms-clear {
      display: none; }
  .video-search-form svg {
    position: absolute;
    left: 30px;
    top: 20px; }

.add-panel-draggable-wrapper {
  cursor: grabbing;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1350; }
  .add-panel-draggable-wrapper > .add-panel-draggable-item {
    background: rgba(45, 245, 253, 0.2) no-repeat center center;
    border: 1px dashed #2df5fd;
    height: 100px;
    pointer-events: none;
    position: fixed;
    transition: width, height, background-size;
    transition-duration: 200ms;
    width: 200px;
    z-index: 1350; }
  .add-panel-draggable-wrapper_new-workspaces {
    z-index: 1550; }
    .add-panel-draggable-wrapper_new-workspaces > .add-panel-draggable-item {
      z-index: 1550; }

.add-panel {
  white-space: nowrap;
  color: #000;
  height: calc( 100vh - var(--g-top-bar-height) - 64px); }

.add-panel-categories-container {
  background: #116dff;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  max-width: 186px; }
  .add-panel-categories-container.with-editor-search .add-panel-editor-search-button {
    display: inline-flex;
    align-items: center;
    height: 30px;
    padding: 9px 12px 6px;
    color: #ffffff;
    cursor: pointer; }
    .add-panel-categories-container.with-editor-search .add-panel-editor-search-button .add-panel-editor-search-label {
      max-width: 90px;
      color: #ffffff;
      cursor: pointer; }
    .add-panel-categories-container.with-editor-search .add-panel-editor-search-button .add-panel-editor-search-bubble {
      display: none;
      position: relative;
      width: 0;
      height: 0;
      z-index: 99999;
      top: -21px;
      left: 22px; }
      .add-panel-categories-container.with-editor-search .add-panel-editor-search-button .add-panel-editor-search-bubble .bubble-content {
        flex-direction: column;
        align-items: unset;
        width: 240px;
        white-space: normal; }
    .add-panel-categories-container.with-editor-search .add-panel-editor-search-button:hover {
      text-decoration: underline; }
      .add-panel-categories-container.with-editor-search .add-panel-editor-search-button:hover .add-panel-editor-search-bubble {
        display: block; }
    .add-panel-categories-container.with-editor-search .add-panel-editor-search-button svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      margin-right: 4px; }
      .add-panel-categories-container.with-editor-search .add-panel-editor-search-button svg > path {
        fill: #ffffff; }
  .add-panel-categories-container.with-editor-search .add-panel-editor-search-separator {
    background: #dfe5eb;
    border: none;
    width: calc(100% - 43px);
    height: 1px;
    margin: 0 18px;
    opacity: 0.5; }
  .add-panel-categories-container.with-editor-search .add-panel-category-list {
    max-height: calc( 100% - 30px - 9px - 6px * 2 - 14px - 1px);
    height: 100%;
    padding-top: 6px; }

.add-panel-category-list {
  display: inline-block;
  height: calc(100% - 28px);
  padding: 14px 0;
  vertical-align: top;
  max-width: 186px; }
  .add-panel-category-list .custom-scroll .inner-handle {
    background-color: #868aa5; }
  .add-panel-category-list .content-wrapper > .category {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 2px;
    max-height: 30px;
    min-height: 14px;
    padding: 0 10px 0 3px;
    position: relative; }
    .add-panel-category-list .content-wrapper > .category > .category-name-wrapper {
      border-radius: 20px;
      display: inline-block;
      height: 100%;
      overflow: hidden;
      padding: 0 14px;
      position: relative;
      max-width: 140px; }
      .add-panel-category-list .content-wrapper > .category > .category-name-wrapper.is-selected, .add-panel-category-list .content-wrapper > .category > .category-name-wrapper:not(.is-selected):hover {
        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .add-panel-category-list .content-wrapper > .category > .category-name-wrapper.is-selected {
        background-color: rgba(0, 0, 0, 0.2); }
      .add-panel-category-list .content-wrapper > .category > .category-name-wrapper:not(.is-selected):hover {
        background-color: rgba(0, 0, 0, 0.07); }
      .add-panel-category-list .content-wrapper > .category > .category-name-wrapper > .category-name {
        color: #fff;
        display: inline-block;
        position: relative;
        height: 14px;
        top: calc(50% - 14px / 2 - 2px);
        cursor: pointer; }
  .add-panel-category-list .content-wrapper > .category-divider {
    height: auto;
    cursor: auto;
    margin-top: 8px;
    margin-bottom: 0;
    min-height: 11px; }
    .add-panel-category-list .content-wrapper > .category-divider > hr {
      width: calc(100% - 30px);
      height: 1px;
      opacity: 0.5;
      border-top: 1px solid #dfe5eb;
      margin-left: 13px;
      margin-top: 10px;
      border-bottom: none; }

.add-panel-sub-category-list {
  background-color: #f1f8ff;
  display: inline-block;
  height: calc(100% - 28px);
  padding: 14px 0;
  vertical-align: top; }
  .add-panel-sub-category-list .custom-scroll .inner-handle {
    background-color: #868aa5; }
  .add-panel-sub-category-list .content-wrapper > li.sectionHeader:first-of-type {
    margin-top: 7px; }
  .add-panel-sub-category-list .content-wrapper > .sectionHeader {
    max-height: 25px;
    padding-left: 10px; }
    .add-panel-sub-category-list .content-wrapper > .sectionHeader > .category-group-title {
      height: 18px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      padding-left: 12px;
      padding-right: 18px;
      text-transform: uppercase;
      color: #868aa5; }
  .add-panel-sub-category-list .content-wrapper > .sectionHeader.withoutDividerAbove {
    margin-top: min(1.9vh, 18px); }
  .add-panel-sub-category-list .content-wrapper > .sectionDivider {
    max-height: 16px;
    margin-top: 10px; }
    .add-panel-sub-category-list .content-wrapper > .sectionDivider > .sub-category-group-divider {
      height: 1px;
      width: calc(100% - 44px);
      opacity: 0.3; }
  .add-panel-sub-category-list .content-wrapper > .category {
    color: #406781;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 2px;
    max-height: 30px;
    min-height: 14px;
    padding: 0 10px 0 3px;
    position: relative;
    margin-left: 5px; }
    .add-panel-sub-category-list .content-wrapper > .category > .category-name-wrapper {
      border-radius: 20px;
      display: inline-block;
      height: 100%;
      overflow: hidden;
      padding: 0 14px;
      position: relative;
      max-width: 140px; }
      .add-panel-sub-category-list .content-wrapper > .category > .category-name-wrapper.is-selected {
        background-color: #d3edff;
        box-shadow: 1px 1px 2px 0 rgba(255, 255, 255, 0.11), inset 1px 1px 2px 0 #c6e4f9; }
      .add-panel-sub-category-list .content-wrapper > .category > .category-name-wrapper:not(.is-selected):hover {
        color: #53a8ef; }
      .add-panel-sub-category-list .content-wrapper > .category > .category-name-wrapper > .category-name {
        display: inline-block;
        position: relative;
        height: 14px;
        top: calc(50% - 14px / 2 - 2px);
        cursor: pointer; }
      .add-panel-sub-category-list .content-wrapper > .category > .category-name-wrapper > .category-group-title {
        display: inline-block; }
  .add-panel-sub-category-list .content-wrapper > .category:hover .category-name {
    color: #53a8ef; }

.classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button {
  width: auto;
  display: flex;
  align-items: center; }
  .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:hover, .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:active {
    background: none; }
    .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:hover path, .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:active path {
      fill: #116dff; }
    .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:hover .control-text.skin-link, .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button:active .control-text.skin-link {
      text-decoration: none;
      color: #116dff; }
  .classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button .control-text.skin-link {
    color: #000624; }

.classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-button-wrapper {
  line-height: 0;
  margin-right: 7px; }

.classic-facelift-skin .add-panel .panel-header .add-panel-editor-search-header-icon {
  margin-right: -4px; }

.anchors-navigation {
  height: auto;
  left: 100%;
  position: absolute;
  top: 0; }
  .larger-than-window.anchors-navigation {
    visibility: hidden; }
  .anchors-navigation > .anchors-nav-section-wrapper {
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: inset 4px 0 3px -2px rgba(0, 0, 0, 0.2), 2px 1px 6px -1px rgba(0, 0, 0, 0.2); }
    .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections {
      list-style: none;
      margin: 0;
      padding: 16px 0;
      position: relative; }
      .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections > .anchors-nav-section-item {
        cursor: pointer;
        margin-bottom: 18px;
        padding: 0 12px;
        position: relative; }
        .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections > .anchors-nav-section-item:last-child {
          margin-bottom: 0; }

.anchors-nav-pin {
  width: 12px;
  height: 12px;
  display: inline-block;
  line-height: 12px;
  position: relative;
  text-align: center;
  vertical-align: middle; }
  .anchors-nav-pin:after {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: solid 2px #cceaff;
    border-radius: 50%;
    content: '';
    display: inline-block;
    position: relative; }
    .anchors-nav-section-item:hover:not(.selected) .anchors-nav-pin:after {
      background-color: #cceaff; }
    .selected .anchors-nav-pin:after {
      width: 10px;
      height: 10px;
      background-color: #116dff;
      left: -1px; }
  .anchors-nav-pin:before {
    width: 4px;
    height: 31px;
    background-color: #cceaff;
    content: '';
    left: 50%;
    margin-left: -2px;
    margin-top: -4px;
    position: absolute;
    top: 100%; }
    .anchors-nav-section-item:last-child .anchors-nav-pin:before {
      display: none; }

.how-to-video-tooltip-content {
  max-width: 204px;
  word-wrap: break-word; }
  .how-to-video-tooltip-content > .container-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer; }
    .how-to-video-tooltip-content > .container-close > .symbol-close > path {
      fill: #000624; }
      .how-to-video-tooltip-content > .container-close:hover > .symbol-close > path {
        fill: #116dff; }
  .how-to-video-tooltip-content > .control-label {
    margin-bottom: 6px; }
  .how-to-video-tooltip-content > .container-thumbnail {
    position: relative;
    left: -4px;
    margin: 1px 0 6px 0;
    cursor: pointer; }
    .how-to-video-tooltip-content > .container-thumbnail > .thumbnail {
      border: 4px solid #fff;
      border-radius: 6px;
      box-shadow: 0 0 0 1px #dfe5eb;
      width: 204px; }
      .how-to-video-tooltip-content > .container-thumbnail:hover > .thumbnail {
        box-shadow: 0 0 0 2px #d6e6fe; }
    .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video {
      position: absolute;
      top: calc(50% - 42px / 2);
      left: calc(50% - 42px / 2);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: rgba(22, 45, 61, 0.7);
      border: 2px solid #fff; }
      .how-to-video-tooltip-content > .container-thumbnail:hover > .symbol-play-video {
        border-color: #80b1ff; }
      .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video .st0 {
        fill: #fff; }
        .how-to-video-tooltip-content > .container-thumbnail:hover > .symbol-play-video .st0 {
          fill: #80b1ff; }
      .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video .st1 {
        fill: transparent; }

.category-view-wrapper {
  background: #fff;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  white-space: normal; }
  .category-view-wrapper .category-view .custom-scroll .outer-container .inner-container.content-scrolled:after {
    height: 0; }
  .category-view-wrapper > .header {
    border-bottom: 1px solid #dfe5eb;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .category-view-wrapper > .header > .top-section-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #395671;
      font-size: 18px;
      margin-left: 24px;
      width: 231px;
      display: inline-block; }
    .category-view-wrapper > .header > .actions {
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
      .category-view-wrapper > .header > .actions > .button {
        width: 25px;
        height: 25px;
        color: #116dff;
        background-color: #d6e6fe;
        border-radius: 50%;
        cursor: pointer;
        line-height: 25px;
        text-align: center;
        box-sizing: border-box; }
        .category-view-wrapper > .header > .actions > .button.close {
          margin-left: 9px;
          margin-right: 14px; }
          .category-view-wrapper > .header > .actions > .button.close:active {
            background-color: #d6e6fe; }
        .category-view-wrapper > .header > .actions > .button.help-button {
          top: 8px; }
          .category-view-wrapper > .header > .actions > .button.help-button:active {
            background-color: #ffffff;
            border: solid 1px #0099ef; }
        .category-view-wrapper > .header > .actions > .button:hover {
          background-color: #e7f0ff; }
  .classic-facelift-skin .category-view-wrapper {
    border-radius: 0; }

.category-view {
  height: calc(100% - 46px); }
  .category-view .sections {
    position: relative; }
  .classic-facelift-skin .category-view,
  .classic-facelift-skin .category-view.with-editor-search {
    height: 100%; }

.live-comps {
  padding-bottom: 9px;
  margin-bottom: 21px; }
  .live-comps .info-icon {
    bottom: 6px;
    right: 6px;
    position: absolute; }
  .live-comps .section-live-site-wrapper {
    margin: 24px 24px 0 24px; }
    .live-comps .section-live-site-wrapper input,
    .live-comps .section-live-site-wrapper textarea {
      pointer-events: none; }
    .live-comps .section-live-site-wrapper,
    .live-comps .section-live-site-wrapper * {
      cursor: pointer !important; }
    .live-comps .section-live-site-wrapper .label-describing-comp {
      color: #3b4057; }

.section-live-site-wrapper h1,
.section-live-site-wrapper h2,
.section-live-site-wrapper h3,
.section-live-site-wrapper h4,
.section-live-site-wrapper h5,
.section-live-site-wrapper h6 {
  margin: 0; }

.section-live-site-wrapper p {
  -webkit-margin-before: 0;
  -moz-margin-before: 0;
  -o-margin-before: 0;
  margin-before: 0;
  -webkit-margin-after: 0;
  -moz-margin-after: 0;
  -o-margin-after: 0;
  margin-after: 0;
  -webkit-margin-start: 0;
  -o-margin-start: 0;
  margin-start: 0;
  -webkit-margin-end: 0;
  -o-margin-end: 0;
  margin-end: 0;
  margin: 0; }

.section-live-site-wrapper .section-live-site-block-layer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1447; }
  .section-live-site-wrapper .section-live-site-block-layer .symbol-selectedItemDesignPanel {
    position: absolute; }
  .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item {
    box-sizing: content-box;
    position: absolute; }
    .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-no-frame-skin-item > div {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #fff;
      text-align: center; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-no-frame-skin-item > div > span {
        background-color: rgba(22, 45, 61, 0.5); }
    .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item {
      background-color: #f2f2f2;
      background-image: linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb), linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb);
      background-position: 0 0, 8px 8px;
      background-size: 16px 16px; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item.section-scaled-in-half {
        background-position: 0 0, 16px 16px;
        background-size: 32px 32px; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item > div {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #000624;
        text-align: center; }

.classic-facelift-skin .symbol-selectedItemDesignPanel .cls-1 {
  fill: #116dff; }

.snapshot-section {
  padding-bottom: 19px;
  margin-top: 12px; }

.snapshot-section-item {
  width: 304px;
  margin: 0 auto 10px; }

.section-section-item-name {
  margin-bottom: 6px; }

.snapshot-section-preview-wrapper {
  height: 168px;
  border-radius: 5px;
  background: #f1f8ff;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer; }
  .snapshot-section-preview-wrapper:hover .snapshot-section-preview__overlay, .snapshot-section-preview-wrapper:active .snapshot-section-preview__overlay, .snapshot-section-preview-wrapper_active .snapshot-section-preview__overlay {
    background: #80b1ff;
    visibility: visible;
    opacity: 0.07; }
  .snapshot-section-preview-wrapper_landscape {
    height: 114px; }

.snapshot-section-preview {
  position: relative;
  width: 100%;
  height: 100%; }
  .snapshot-section-preview__overlay {
    position: relative;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background: transparent; }
  .snapshot-section-preview__inner {
    transform-origin: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%; }
  .snapshot-section-preview__snapshot {
    pointer-events: none; }

.context-menu-action[data-aid*='addAndApplyTheme'] svg path {
  fill: #3b4057 !important; }

.context-menu-action[data-aid*='add'] svg path {
  fill: #3b4057 !important; }

.snapshot-section-context-menu {
  display: none;
  position: absolute;
  top: 6px;
  right: 6px; }
  .snapshot-section-preview-wrapper:hover .snapshot-section-context-menu,
  .snapshot-section-preview-wrapper_active .snapshot-section-context-menu {
    display: block; }
  .snapshot-section-context-menu .context-menu-button {
    background: #fff; }

.design-data-preset-items-container {
  position: relative;
  padding: 7px 24px 0; }
  .design-data-preset-items-container .design-data-preset-item-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 12px; }
    .design-data-preset-items-container .design-data-preset-item-wrapper:nth-child(2n) {
      margin-left: 12px; }
    .design-data-preset-items-container .design-data-preset-item-wrapper .design-data-preset-item {
      width: 114px;
      height: 72px;
      box-sizing: border-box;
      cursor: pointer; }
      .design-data-preset-items-container .design-data-preset-item-wrapper .design-data-preset-item.empty-preset {
        border: 1px solid #ccc;
        text-align: center;
        line-height: 72px;
        color: #333; }

.list-section {
  padding-bottom: 19px; }

.preset-section.with-labels {
  padding-bottom: 5px; }

.preset-section.without-labels {
  padding-bottom: 9px; }

.preset-section.no-header-sibling {
  padding-bottom: 0; }

.preset-section > .items-wrapper {
  margin-left: auto;
  margin-right: auto; }
  .category-view-wrapper .preset-section > .items-wrapper {
    margin-left: 14px; }
  .preset-section > .items-wrapper > .items {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    overflow: hidden; }
    .preset-section > .items-wrapper > .items.has-label {
      margin-bottom: 28px; }
    .preset-section > .items-wrapper > .items.disabled-preset-section {
      opacity: 0.2;
      pointer-events: none; }

.preset-section .image-items-wrapper {
  width: 100%;
  height: 100%;
  text-align: center; }

.add-panel-welcome-section > .banner {
  width: 352px;
  height: 162px;
  margin-bottom: 24px; }

.add-panel-welcome-section > .title-container {
  text-align: center;
  padding: 0 24px;
  margin-bottom: 6px; }
  .add-panel-welcome-section > .title-container > .control-label {
    display: block; }
  .add-panel-welcome-section > .title-container > .title {
    margin-bottom: 6px; }

.add-panel-welcome-section > .composite-promotional-list {
  padding: 18px 24px 12px; }
  .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list > .promotional-list-item {
    align-items: center; }
    .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list > .promotional-list-item > svg {
      width: 11px;
      height: 10px;
      align-self: flex-start;
      margin-right: 13px;
      margin-top: 4px;
      fill: #25a55a; }
  .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list_numeric > .promotional-list-item {
    align-items: start; }
    .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list_numeric > .promotional-list-item > .control-label + .control-label {
      padding-left: 0.5em; }

.classic-facelift-skin .add-panel-welcome-section > .banner {
  height: 140px; }

.preset-item {
  position: absolute;
  cursor: pointer; }
  .preset-item.small-gap {
    overflow: visible; }
  .preset-item > .video {
    position: absolute;
    top: 0; }
    .preset-item > .video.videoPaused {
      visibility: hidden; }
  .preset-item.live-text {
    position: relative;
    margin-bottom: 14px;
    margin-left: 14px; }
    .preset-item.live-text:first-child {
      margin-top: 24px; }
    .preset-item.live-text:last-child {
      margin-bottom: 0; }
    .preset-item.live-text:nth-child(1), .preset-item.live-text:nth-child(2) {
      margin-bottom: 10px; }
    .preset-item.live-text > .preset-item-label {
      position: initial;
      visibility: hidden;
      display: block;
      font-size: 13px; }
  .preset-item.live-text:hover {
    color: #3b4057; }
    .preset-item.live-text:hover > .preset-item-label {
      visibility: visible; }
  .preset-item > .preset-item-label {
    position: absolute;
    top: calc(100% - 14px);
    right: 0;
    left: 0;
    text-align: center;
    display: none;
    font-size: 12px;
    color: #3b4057; }
    .small-gap.preset-item > .preset-item-label {
      bottom: -5px; }
    .preset-item:hover > .preset-item-label.hover {
      display: block; }
    .preset-item > .preset-item-label.align-left {
      text-align: left;
      left: 11px; }
    .preset-item > .preset-item-label.always {
      display: block; }
    .preset-item > .preset-item-label.never {
      display: none; }
  .preset-item > .info-wrapper {
    opacity: 0; }
    .preset-item:hover > .info-wrapper {
      opacity: 1; }

.preset-area-item {
  outline: none; }

.section-header {
  margin: 0 auto 5px; }
  .section-header > .title-line {
    font-size: 14px;
    position: relative;
    border-bottom: 1px solid #8092a4;
    padding-bottom: 4px;
    color: #3b4057;
    display: flex; }
    .section-header > .title-line > .title {
      display: inline-block;
      width: 100%;
      margin-top: -1px; }
    .section-header > .title-line > .new-title-sub-category {
      width: 100%; }
  .section-header > .title-line-sub-category {
    height: 20px; }
  .list-section .section-header {
    margin-bottom: 0; }
  .preset-section.without-labels .section-header {
    margin-bottom: 7px; }
  .section-wrapper.invisible-header .section-header {
    display: none; }
  .section-header .add-panel-info-icon {
    fill: #116dff; }
  .section-header .sub-category-add-panel-info-icon > svg path {
    fill: #116dff; }
    .composite-banner .section-header .sub-category-add-panel-info-icon > svg path {
      fill: #fff; }

.sub-category-info-icon {
  display: none; }

.info-icon-image-container {
  position: relative;
  cursor: pointer; }
  .info-icon-image-container .info-icon-image {
    border: 4px solid #fff;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #dfe5eb;
    width: 204px; }
    .info-icon-image-container:hover .info-icon-image {
      box-shadow: 0 0 0 2px #d6e6fe; }
  .info-icon-image-container .symbol-play-video {
    position: absolute;
    top: calc(50% - 42px / 2);
    left: calc(50% - 42px / 2);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: rgba(22, 45, 61, 0.7);
    border: 2px solid #fff; }
    .info-icon-image-container:hover .symbol-play-video {
      border-color: #80b1ff; }
    .info-icon-image-container .symbol-play-video .st0 {
      fill: #fff; }
      .info-icon-image-container:hover .symbol-play-video .st0 {
        fill: #80b1ff; }
    .info-icon-image-container .symbol-play-video .st1 {
      fill: transparent; }

.classic-facelift-skin .section-header > .title-line {
  border-bottom: 1px solid #dfe5eb;
  padding-bottom: 7px;
  height: initial; }
  .classic-facelift-skin .section-header > .title-line > .title {
    color: #000624; }

.classic-facelift-skin .section-header .control-info-icon {
  margin-left: 12px; }

.classic-facelift-skin .section-header .control-info-icon svg path {
  fill: #868aa5; }

.classic-facelift-skin .section-header .control-info-icon:hover svg path {
  fill: #116dff; }

.section-footer {
  text-align: center;
  padding-top: 6px; }
  .section-footer > .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    padding: 4px 9px 5px 13px;
    border-radius: 18px;
    cursor: pointer;
    color: #116dff;
    background-color: #e7f0ff; }
    .section-footer > .link:hover {
      background-color: #d6e6fe; }
  .preset-section.without-labels.with-footer .section-footer {
    margin-top: 3px; }

.category-view-wrapper .section-wrapper:not(.no-space-before-section) {
  margin-top: 19px;
  padding-top: 3px;
  overflow: hidden; }

.section-wrapper h1,
.section-wrapper h2,
.section-wrapper h3,
.section-wrapper h4,
.section-wrapper h5,
.section-wrapper h6 {
  margin: 0; }

.section-wrapper p {
  -webkit-margin-before: 0;
  -moz-margin-before: 0;
  -o-margin-before: 0;
  margin-before: 0;
  -webkit-margin-after: 0;
  -moz-margin-after: 0;
  -o-margin-after: 0;
  margin-after: 0;
  -webkit-margin-start: 0;
  -o-margin-start: 0;
  margin-start: 0;
  -webkit-margin-end: 0;
  -o-margin-end: 0;
  margin-end: 0;
  margin: 0; }

.section-wrapper .section-header .info-icon {
  display: block; }

.section-wrapper .section-header .sub-category-info-icon {
  display: block; }

.spinnerWrapper {
  width: 400px;
  display: flex;
  justify-content: center;
  padding-top: 180px; }

.headerSearchButtonWrapper {
  margin-right: 7px;
  line-height: 0; }

.searchIcon {
  margin-right: -4px; }

.headerSearchButton {
  width: auto !important;
  display: flex;
  align-items: center; }
  .headerSearchButton :global .control-text {
    color: #000624 !important; }
  .headerSearchButton:hover {
    background: none !important; }
    .headerSearchButton:hover path {
      fill: #116dff !important; }
    .headerSearchButton:hover :global .control-text {
      color: #116dff !important;
      text-decoration: none; }

:global [data-madefor='true'] .info-icon-title {
  font-weight: var(--wbu-font-weight-medium);
  margin-bottom: 6px;
  font-size: 16px; }

.add-panel-new-workspace {
  height: 100%; }
  .add-panel-new-workspace .add-panel-categories-container {
    position: relative;
    background-color: #f7f8f8;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    max-width: 186px;
    border-right: 1px solid #dfe5eb; }
    .add-panel-new-workspace .add-panel-categories-container:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 13px;
      pointer-events: none;
      background: linear-gradient(to top, #f7f8f8, rgba(247, 248, 248, 0)); }
  .add-panel-new-workspace .add-panel-category-list {
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    vertical-align: top;
    max-width: 186px;
    overflow-y: auto; }
    .add-panel-new-workspace .add-panel-category-list > .category {
      height: 30px;
      cursor: pointer;
      font-size: 24px;
      margin: 0;
      max-height: 36px;
      min-height: 25px;
      padding: 0 12px;
      position: relative; }
      .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper {
        border-radius: 20px;
        display: inline-block;
        height: 96%;
        max-height: 30px;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        max-width: 140px; }
        .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper.is-selected, .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper:not(.is-selected):hover {
          box-shadow: none; }
        .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper.is-selected {
          background-color: #d6e6fe; }
          .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper.is-selected .category-name {
            color: #116dff; }
        .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper:not(.is-selected):hover {
          background-color: #e7f0ff; }
          .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper:not(.is-selected):hover .category-name {
            color: #116dff; }
        .add-panel-new-workspace .add-panel-category-list > .category > .category-name-wrapper > .category-name {
          color: #000624;
          display: inline-block;
          position: relative;
          height: 24px;
          top: calc(50% - 24px / 2 - 6px);
          cursor: pointer; }
    .add-panel-new-workspace .add-panel-category-list > .category-divider {
      height: auto;
      cursor: auto;
      margin-top: 8px;
      margin-bottom: 0;
      min-height: 11px; }
      .add-panel-new-workspace .add-panel-category-list > .category-divider > hr {
        width: calc(100% - 30px);
        height: 1px;
        opacity: 0.5;
        border-top: 1px solid #dfe5eb;
        margin-left: 12px;
        margin-top: 10px;
        border-bottom: none; }
  .add-panel-new-workspace .add-panel-sub-category-list {
    background-color: #fff;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    vertical-align: top;
    border-right: 1px solid #dfe5eb;
    overflow-y: auto; }
    .add-panel-new-workspace .add-panel-sub-category-list > li.sectionHeader:first-of-type {
      margin-top: 0; }
    .add-panel-new-workspace .add-panel-sub-category-list > .sectionHeader {
      max-height: 30px;
      min-height: 18px;
      padding-left: 12px;
      position: relative; }
      .add-panel-new-workspace .add-panel-sub-category-list > .sectionHeader > .category-group-title {
        position: relative;
        height: 18px;
        top: calc(50% - 18px / 2);
        font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        padding-left: 12px;
        padding-right: 18px;
        text-transform: uppercase;
        color: #868aa5; }
    .add-panel-new-workspace .add-panel-sub-category-list > .sectionDivider {
      max-height: 16px;
      margin-top: 10px; }
      .add-panel-new-workspace .add-panel-sub-category-list > .sectionDivider > .sub-category-group-divider {
        height: 1px;
        width: calc(100% - 48px);
        opacity: 0.3; }
    .add-panel-new-workspace .add-panel-sub-category-list > .category {
      color: #3b4057;
      cursor: pointer;
      font-size: 24px;
      max-height: 36px;
      min-height: 25px;
      padding: 0 12px;
      margin: 0;
      position: relative; }
      .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper {
        border-radius: 20px;
        display: inline-block;
        height: 96%;
        max-height: 30px;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        max-width: 140px; }
        .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper.is-selected {
          background-color: #d6e6fe;
          box-shadow: none; }
          .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper.is-selected .category-name {
            color: #116dff; }
        .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper:not(.is-selected):hover {
          background-color: #e7f0ff; }
          .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper:not(.is-selected):hover .category-name {
            color: #116dff; }
        .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper > .category-name {
          display: inline-block;
          position: relative;
          height: 24px;
          top: calc(50% - 24px / 2 - 6px);
          cursor: pointer;
          color: #000624; }
        .add-panel-new-workspace .add-panel-sub-category-list > .category > .category-name-wrapper > .category-group-title {
          display: inline-block; }

.cannot-add-list {
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  width: 352px;
  margin-top: 38px; }
  .cannot-add-list > .error-title {
    font-size: 18px;
    margin-top: 26px; }
  .cannot-add-list > .support-link {
    color: #116dff;
    text-decoration: none; }
    .cannot-add-list > .support-link:hover {
      text-decoration: underline; }

.design-customization-checkbox .controls-wrapper {
  padding: 22px 24px; }

#designPanel .comp-panel-content {
  max-height: 504px; }
  #designPanel .comp-panel-content.hideOverFlow {
    overflow: hidden; }

#designPanel .sections {
  overflow: hidden; }
  #designPanel .sections.panel-has-children {
    padding-bottom: 84px; }
    #designPanel .sections.panel-has-children .section-wrapper:last-child {
      padding-bottom: 0; }
  #designPanel .sections .section-header {
    padding-top: 18px; }
    #designPanel .sections .section-header .title {
      width: 100%; }

#designPanel .button-wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  text-align: center; }
  #designPanel .button-wrapper .button {
    bottom: -23px;
    position: relative;
    line-height: 26px; }
    #designPanel .button-wrapper .button.animate-button {
      animation-duration: 0.6s;
      animation-timing-function: ease;
      animation-name: tada; }

@keyframes tada {
  60% {
    background-color: #d6e6fe;
    transform: scale(1.05); } }

#designPanel .design-panel-selected-item svg {
  position: absolute; }

#designPanel .design-panel-selected-item.preset-item svg {
  top: 0;
  right: -2px;
  height: 31px;
  width: 31px; }

#designPanel .design-panel-transparent-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #000624;
  text-align: center; }

#designPanel .design-panel-no-frame-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  text-align: center; }
  #designPanel .design-panel-no-frame-skin-item > div > span {
    background-color: rgba(22, 45, 61, 0.5); }

#designPanel .design-panel-no-frame-skin-item-discover-more > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  text-align: center; }
  #designPanel .design-panel-no-frame-skin-item-discover-more > div > span {
    background-color: rgba(22, 45, 61, 0.5);
    font-size: 14px; }

#designPanel .preset-item.design-panel-transparent-skin-item > div, #designPanel .preset-item.design-panel-no-frame-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased; }

#designPanel .section-live-site-wrapper .section-live-site-block-layer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item {
    box-sizing: content-box;
    position: absolute; }
    #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item {
      background-color: #f2f2f2;
      background-image: linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb), linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb);
      background-position: 0 0, 4px 4px;
      background-size: 8px 8px; }
      #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item.section-scaled-in-half {
        background-position: 0 0, 16px 16px;
        background-size: 32px 32px; }

.cssContainer {
  padding: 18px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.pin-design-panel-content .pin-thumbnails-wrapper {
  position: relative; }
  .pin-design-panel-content .pin-thumbnails-wrapper .change-custom-pin {
    position: absolute;
    bottom: 50px;
    right: 18px;
    cursor: pointer; }
  .pin-design-panel-content .pin-thumbnails-wrapper .control-label .has-tooltip .tooltip-on-ellipsis-content .control-label-base {
    padding: 24px 24px 0 24px; }
  .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list {
    padding: 12px 18px 12px 20px; }
    .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper {
      padding: 6px; }
      .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper .add-thumbnail-symbol-container {
        width: 50px;
        height: 50px; }
      .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper.selected .illustration-container .illustration-inner-container {
        box-shadow: 0 0 0 2px #116dff; }
      .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper:not(.selected):hover .illustration-container .illustration-inner-container {
        box-shadow: 0 0 0 2px #d6e6fe; }
      .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container {
        border-radius: 6px;
        box-shadow: 0 0 0 1px #dfe5eb; }
        .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > img {
          padding: 5px 13px 6px 13px;
          height: 38px; }
        .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > svg {
          width: 40px;
          height: 40px;
          padding: 5px; }
          .pin-design-panel-content .pin-thumbnails-wrapper .control-thumbnails-list .item-wrapper .illustration-container .illustration-inner-container > svg > path {
            stroke: #868aa5;
            stroke-opacity: 0.6;
            stroke-width: 1px; }

.pin-design-panel-content .pin-colors-wrapper .control-label .has-tooltip .tooltip-on-ellipsis-content .control-label-base {
  padding: 18px 24px 18px 24px; }

.pin-design-panel-content .pin-colors-wrapper .control-thumbnail-button-group .thumbnail-buttons-section {
  padding: 0 24px 24px 24px; }

.pin-design-panel-content .pin-colors-wrapper .control-thumbnail-color-input .control-thumbnail-color-input-item:hover {
  box-shadow: inset 0 0 0 2px #d6e6fe; }

.design-panel-title .control-label .has-tooltip .tooltip-on-ellipsis-content .control-label-base {
  padding: 24px 24px 0 24px; }

.stylable-panel {
  width: 308px;
  min-height: 200px;
  max-height: 558px; }
  .stylable-panel .comp-panel-content {
    min-height: 506px; }
  .stylable-panel.stylable-panel-vertical-collapse {
    width: 260px; }

.stylable-panel-inner {
  width: 308px;
  height: 455px;
  border-top: 1px solid #dfe5eb; }
  .stylable-panel-inner.stylable-panel-inner-tall {
    height: 505px; }
  .stylable-panel-inner.stylable-panel-inner-vertical-collapse {
    width: 260px; }
  .stylable-panel-inner.stylable-panel-inner-stretched {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%; }

.stylable-panel-height-only {
  min-height: 200px;
  max-height: 558px; }

.stylable-panel-mobile {
  width: 308px;
  height: 508px; }
  .stylable-panel-mobile.stylable-panel-vertical-collapse {
    width: 260px; }

.css-panel-button {
  position: absolute;
  bottom: 8px;
  left: 8px;
  height: 22px;
  padding: 0 8px;
  background-color: transparent;
  border: 1px solid #116dff;
  color: #116dff;
  font-size: 12px;
  transition: all 0.1s linear; }
  .css-panel-button:hover {
    background-color: #5999ff;
    border-color: transparent;
    color: #fff; }
  .css-panel-button:active {
    background-color: #116dff;
    border-color: #116dff;
    color: #fff; }

.layer-visibility {
  padding-right: 10px;
  cursor: pointer; }
  .layer-visibility .symbol-showComp.disabled path {
    fill: #7a7a7a; }

.hidden-item {
  height: 60px;
  border-bottom: 1px solid #ddd;
  position: relative;
  background-color: #fff; }
  .hidden-item.new-hidden-item {
    transition: max-height 500ms ease 300ms, opacity 300ms ease;
    cursor: pointer;
    max-height: 60px;
    opacity: 1; }
    .hidden-item.new-hidden-item.added-to-stage {
      max-height: 0;
      opacity: 0; }
  .hidden-item:hover {
    background-color: #e7f0ff; }
  .hidden-item .icon {
    position: absolute;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.2);
    border-radius: 4px;
    background-repeat: no-repeat;
    left: 25px;
    top: 10px;
    display: flex; }
    .hidden-item .icon svg {
      margin: auto;
      width: 14px;
      height: 14px; }
  .hidden-item .label {
    position: relative;
    margin-left: 80px;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 195px; }
  .hidden-item .essential {
    position: absolute;
    right: 85px;
    top: 23px;
    margin-right: 7px;
    margin-left: 7px; }
  .hidden-item .show-button {
    position: absolute;
    background-color: #e7f0ff;
    text-align: center;
    height: 24px;
    width: auto;
    top: 18px;
    left: auto;
    right: 12px;
    border-radius: 18px;
    color: #116dff; }
    .hidden-item .show-button .symbol-showComp {
      height: 10px;
      width: auto;
      margin-top: 7.5px;
      margin-left: 8.25px; }
    .hidden-item .show-button .text {
      margin: 3px 12px 0 2px; }
    .hidden-item .show-button:hover {
      background-color: #d6e6fe; }
  .hidden-item .show-button-extend {
    position: absolute;
    top: 18px;
    right: 12px; }

.classic-facelift-skin .left-panel-hidden-elements-redesign-experiment .show-button-extend .control-button.btn-sm.prefix-icon {
  padding: 0 12px 0 6px; }

.hidden-items-container .container-header {
  height: 42px;
  text-align: center;
  line-height: 40px;
  background-color: #f1f3f5;
  color: #3b4057;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.hidden-items-container:first-of-type .container-header {
  border-top: none; }

.interactions-hidden-items-container .container-header {
  height: 42px;
  text-align: center;
  line-height: 40px;
  background-color: #f1f3f5;
  color: #3b4057;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: 1px solid #ddd; }

.interactions-hidden-items-container:first-of-type .container-header {
  border-top: none; }

.interaction-mobile-hidden-item {
  height: 60px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #ddd; }
  .interaction-mobile-hidden-item:hover {
    background-color: #e7f0ff; }
  .interaction-mobile-hidden-item > .icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border: solid 1px #dfe5eb;
    border-radius: 100%;
    background-repeat: no-repeat;
    left: 25px;
    top: 10px;
    display: flex; }
    .interaction-mobile-hidden-item > .icon svg {
      margin: auto;
      width: 14px;
      height: 14px; }
  .interaction-mobile-hidden-item .label {
    position: relative;
    margin-left: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 195px;
    padding-top: 14px; }
    .interaction-mobile-hidden-item .label .no-subtitle {
      padding-top: 10px; }
    .interaction-mobile-hidden-item .label .hover-symbol {
      color: #000624;
      margin-left: 6px; }
    .interaction-mobile-hidden-item .label .hover-label {
      color: #868aa5; }
  .interaction-mobile-hidden-item .show-button {
    position: absolute;
    height: 24px;
    background-color: #e7f0ff;
    text-align: center;
    height: 24px;
    width: auto;
    top: 18px;
    left: auto;
    right: 12px;
    border-radius: 18px;
    color: #116dff; }
    .interaction-mobile-hidden-item .show-button .symbol-showComp {
      height: 10px;
      width: auto;
      margin-top: 7.5px;
      margin-left: 8.25px; }
    .interaction-mobile-hidden-item .show-button .text {
      margin: 3px 12px 0 2px; }
    .interaction-mobile-hidden-item .show-button:hover {
      background-color: #d6e6fe; }

.hidden-items-panel {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  max-height: calc(100vh - 50px - 165px);
  width: 352px; }
  .hidden-items-panel .custom-scroll .content {
    max-height: calc(calc(100vh - 50px - 54px) - 153px);
    position: relative; }
    .hidden-items-panel .custom-scroll .content .rich-text-subtitle {
      padding: 18px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dfe5eb; }
      .hidden-items-panel .custom-scroll .content .rich-text-subtitle span {
        color: #3b4057; }
    .hidden-items-panel .custom-scroll .content .scrollable {
      min-height: calc(calc(100vh - 50px - 54px) - 165px);
      display: flex;
      flex-direction: column;
      padding-bottom: 18px; }
      .hidden-items-panel .custom-scroll .content .scrollable .first-time-info-box {
        margin-bottom: 12px; }
    .hidden-items-panel .custom-scroll .content .no-items {
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #d5d5d5; }
  .hidden-items-panel.hidden-items-panel-new-workspace .symbol-plusBig:hover:not(.disabled) .circle {
    fill: #116dff;
    stroke: #116dff; }
  .hidden-items-panel.hidden-items-panel-new-workspace .hidden-item .icon {
    box-shadow: none;
    border: solid 1px #dfe5eb; }
  .hidden-items-panel .hidden-panel-message {
    padding: 18px 24px;
    position: absolute;
    bottom: 0;
    display: flex;
    background-color: #fff;
    border-top: solid 1px #dfe5eb; }
    .hidden-items-panel .hidden-panel-message .footer-content {
      border: solid 1px #dfe5eb;
      padding: 18px; }
      .hidden-items-panel .hidden-panel-message .footer-content .title {
        display: flex;
        align-items: center;
        color: #000624;
        margin-bottom: 6px; }
        .hidden-items-panel .hidden-panel-message .footer-content .title .infoSmall {
          color: #868aa5;
          margin-right: 6px; }

.left-panel-hidden-elements-redesign-experiment .hidden-items-panel .custom-scroll .content .rich-text-subtitle {
  padding: 18px; }

.left-panel-hidden-elements-redesign-experiment .hidden-items-panel .hidden-panel-message {
  border-top: solid 1px #dfe5eb;
  padding: 18px 24px; }
  .left-panel-hidden-elements-redesign-experiment .hidden-items-panel .hidden-panel-message .footer-content {
    border: none;
    padding: 0;
    padding-left: 4px;
    padding-top: 4px; }
    .left-panel-hidden-elements-redesign-experiment .hidden-items-panel .hidden-panel-message .footer-content .title {
      margin-bottom: 4px; }
      .left-panel-hidden-elements-redesign-experiment .hidden-items-panel .hidden-panel-message .footer-content .title .hintSmall {
        color: #868aa5;
        margin-right: 6px; }

.mobile-element-settings {
  position: absolute;
  top: 0;
  left: 340px;
  width: 440px;
  height: 100%;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35); }
  .mobile-element-settings .content {
    padding-left: 24px;
    margin-left: 17px; }
  .mobile-element-settings .category-desc {
    color: #000000;
    font-size: 14px;
    font-weight: 300; }
  .mobile-element-settings .learn-more,
  .mobile-element-settings .go-to-preview {
    color: #116dff;
    cursor: pointer; }
    .mobile-element-settings .learn-more:hover,
    .mobile-element-settings .go-to-preview:hover {
      text-decoration: underline; }
  .classic-facelift-skin .mobile-element-settings {
    position: relative;
    top: initial;
    left: initial;
    border-radius: 0;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35); }

.mobile-item-wrapper {
  display: inline-block;
  vertical-align: top; }
  .mobile-item-wrapper .imgDesc,
  .mobile-item-wrapper .imgDesc2 {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    height: 16px; }
  .mobile-item-wrapper .imgDesc {
    color: #000624;
    margin-top: 10px; }
  .mobile-item-wrapper .imgDesc2 {
    font-size: 12px;
    color: #3b4057;
    margin-top: 4px; }
  .mobile-item-wrapper .mobile-item-selection {
    width: 179px;
    border: 1px solid #d5d5d5;
    box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.29);
    border-radius: 4px;
    cursor: pointer; }
    .mobile-item-wrapper .mobile-item-selection:hover {
      border: 1px solid #80b1ff; }
    .mobile-item-wrapper .mobile-item-selection.selected {
      border: 1px solid #116dff; }
    .mobile-item-wrapper .mobile-item-selection.mobile-item-optimized {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/optimized.gif") no-repeat 50% 50%; }
    .mobile-item-wrapper .mobile-item-selection.mobile-item-non-optimized {
      margin-right: 15px;
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/nonOptimized.png") no-repeat 50% 50%; }
    .mobile-item-wrapper .mobile-item-selection .image {
      width: 180px;
      height: 235px; }

.mobile-element-settings.backToTopEnabled .imageWrapper {
  border: 1px solid #116dff; }

.mobile-element-settings .img {
  width: 123px;
  height: 205px;
  margin-left: 119px;
  margin-top: 17px;
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/backToTop.v3.gif"); }

.mobile-element-settings .imageWrapper {
  width: 373px;
  height: 240px;
  box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.29);
  border: 1px solid #d9e1e8;
  margin-top: 6px;
  border-radius: 4px; }
  .mobile-element-settings .imageWrapper + .text {
    padding-right: 24px;
    line-height: 20px; }

.mobile-element-settings.action-bar-settings {
  width: 613px;
  white-space: break-spaces; }
  .mobile-element-settings.action-bar-settings .content .category-desc {
    width: 435px; }
    .mobile-element-settings.action-bar-settings .content .category-desc + .control-switch .symbol-switch {
      margin-right: 29px; }
  .classic-facelift-skin .mobile-element-settings.action-bar-settings {
    min-width: 613px; }

.action-bar-main-content {
  position: absolute;
  bottom: 45px;
  top: 204px;
  left: 30px;
  right: 0; }

.action-bar-menu-wrapper {
  border-top: 1px solid #dfe5eb;
  border-bottom: 1px solid #dfe5eb;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .action-bar-menu-wrapper .action-bar-menu-title {
    background-color: #eff3f5;
    padding: 13px 20px;
    position: absolute;
    left: -15px;
    top: -1px;
    width: 284px;
    text-align: center;
    color: #075673;
    font-size: 16px;
    border: 1px solid #dfe5eb; }
  .action-bar-menu-wrapper .action-bar-menu {
    position: absolute;
    top: 45px;
    left: 0;
    bottom: 10px;
    border-right: 1px solid #dfe5eb; }
    .action-bar-menu-wrapper .action-bar-menu .boolean-control span.label {
      vertical-align: top;
      margin-left: 5px; }
    .action-bar-menu-wrapper .action-bar-menu .link-label {
      vertical-align: top;
      display: inline-block;
      margin: 5px auto;
      color: #3b4057;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .action-bar-menu-wrapper .action-bar-menu .link-label.link-active {
        color: #116dff; }
        .action-bar-menu-wrapper .action-bar-menu .link-label.link-active:hover {
          cursor: pointer;
          text-decoration: underline; }
    .action-bar-menu-wrapper .action-bar-menu .text {
      display: inline-block;
      vertical-align: top; }
    .action-bar-menu-wrapper .action-bar-menu .action-bar-item {
      width: 320px;
      margin-left: -10px; }
  .action-bar-menu-wrapper .action-bar-color-scheme {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    overflow: hidden; }
    .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker {
      background-color: #fff;
      width: 224px;
      height: 105px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin: 0 24px;
      border-bottom: 1px solid #dfe5eb;
      padding-bottom: 5px; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker .color-scheme-label {
        padding: 15px 13px;
        color: #075673;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker .control-buttons-group {
        text-align: center; }
    .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-preview {
      background-color: #fff;
      width: 250px;
      background: no-repeat center;
      background-size: 65%;
      position: absolute;
      right: 0;
      bottom: 8%;
      height: 320px;
      margin: 0 14px; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-preview .action-bar-menu-preview {
        position: absolute;
        bottom: 73px;
        left: 1px;
        height: 40px;
        background: no-repeat center;
        right: 0;
        width: 100%;
        background-size: 55%; }

.action-bar-footer {
  position: absolute;
  bottom: 0;
  padding: 20px 0 20px 23px;
  margin-left: -23px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  width: 100%; }

.action-bar-publish {
  cursor: pointer;
  color: #116dff; }
  .action-bar-publish:hover {
    text-decoration: underline; }

.mobile-settings-header {
  border-bottom: 1px solid #d5d5d5;
  height: 53px; }
  .mobile-settings-header .title {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 24px;
    margin-left: 17px;
    line-height: 54px;
    height: 54px;
    font-size: 18px;
    color: #3b4057; }
  .mobile-settings-header .helpSign {
    width: 25px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    text-align: center;
    background-color: #d6e6fe;
    color: #116dff;
    border-radius: 50%;
    position: absolute;
    right: 55px;
    top: 14px; }
    .mobile-settings-header .helpSign:hover {
      background-color: #e7f0ff; }
  .mobile-settings-header .symbol-arrowRightBig {
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-color: #d6e6fe;
    fill: #116dff;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotateY(180deg); }
    .mobile-settings-header .symbol-arrowRightBig:hover {
      background-color: #e7f0ff; }

.mobile-tools-custom-tooltip-invalid-icon {
  fill: #ffc233; }

.left-panel-frame.mobile-elements-panel {
  overflow: visible;
  min-height: 520px; }
  .left-panel-frame.mobile-elements-panel .panel-header {
    border-top-right-radius: 4px; }
  .left-panel-frame.mobile-elements-panel .left-panel-frame-content {
    background-color: #ffffff;
    border-bottom-right-radius: 4px;
    min-height: 466px;
    padding-top: 11px; }
    .left-panel-frame.mobile-elements-panel .left-panel-frame-content.action-bar-menu-open {
      height: calc(100vh - 150px);
      max-height: 678px;
      min-height: calc(470px - 54px); }
      .left-panel-frame.mobile-elements-panel .left-panel-frame-content.action-bar-menu-open .mobile-element-settings {
        height: calc(100vh - 150px + 54px);
        max-height: calc(678px + 54px);
        min-height: 470px; }
  .left-panel-frame.mobile-elements-panel .items-wrapper {
    width: 350px;
    position: relative; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .selected .listItem {
      background-color: #d6e6fe; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .listItem {
      display: flex;
      align-items: center;
      padding: 13px 24px; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .content {
        margin-top: 0; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .image {
        height: 60px;
        width: 60px;
        position: relative; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .has-tooltip {
        display: flex; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem.list-item-with-symbol .image {
        border-radius: 50%;
        height: 90px;
        width: 90px;
        padding: 21px;
        margin: 0;
        background-color: #ecf9ff;
        border: 3px #fff solid;
        box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
        box-sizing: border-box; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .symbol-arrowRightBig {
      margin-left: 49px;
      width: 23px;
      height: 23px; }
  .left-panel-frame.mobile-elements-panel .panel-shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    border-radius: 6px 4px 4px 6px;
    pointer-events: none; }
  .left-panel-frame.mobile-elements-panel :not(.composite-toggle-switch) > .control-switch > .symbol-switch {
    margin-right: 17px; }
  .left-panel-frame.mobile-elements-panel .category-desc {
    width: 375px; }
    .left-panel-frame.mobile-elements-panel .category-desc .text {
      margin: 21px 0 16px 0;
      line-height: 20px; }
  .left-panel-frame.mobile-elements-panel .mobile-element-settings {
    z-index: -1; }

.layout-optimizer-panel {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }
  .layout-optimizer-panel .content {
    width: 292px;
    min-height: 426px;
    padding: 24px 30px 0 30px;
    display: flex;
    flex-direction: column; }
    .layout-optimizer-panel .content .image-container {
      align-self: center; }
    .layout-optimizer-panel .content .text {
      padding: 20px 36px;
      text-align: center;
      color: #3b4057; }
      .layout-optimizer-panel .content .text .learn-more {
        color: #116dff;
        cursor: pointer; }
        .layout-optimizer-panel .content .text .learn-more :hover {
          text-decoration: underline; }
    .layout-optimizer-panel .content .optimize-layout-button {
      display: block;
      margin: 0 auto; }

.mobile-frame-opacity-layer {
  position: absolute;
  pointer-events: none; }
  .mobile-frame-opacity-layer:before, .mobile-frame-opacity-layer:after {
    content: '';
    position: absolute;
    top: var(--g-mobile-top);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    width: calc((100% - 320px) / 2); }
  .mobile-frame-opacity-layer:before {
    left: 0; }
  .mobile-frame-opacity-layer:after {
    right: 0; }

.mobile-frame {
  z-index: 1;
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 320px;
  left: calc((100% - 320px) / 2);
  height: 100%;
  transform-origin: center 0 0;
  transition: 0.3s transform, 0.3s height; }
  .mobile-frame .block-layer {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 0;
    width: 100%; }
    .mobile-frame .block-layer.top {
      top: calc(-1 * var(--g-mobile-top));
      height: var(--g-mobile-top); }
    .mobile-frame .block-layer.bottom {
      bottom: calc(-1 * var(--g-mobile-bottom));
      height: var(--g-mobile-bottom);
      top: auto; }
  .mobile-frame .mobile-frame-top,
  .mobile-frame .mobile-frame-bottom,
  .mobile-frame .site-shadow {
    position: absolute;
    background-size: contain !important; }
  .mobile-frame .site-shadow {
    height: 100%;
    width: 448px;
    left: calc( (320px - 448px) / 2);
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bg.v4.png") center 0 repeat-y; }
  .mobile-frame .mobile-frame-top {
    left: calc( (320px - 448px) / 2);
    width: 448px;
    height: 72px;
    top: -72px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-top.v4.png") center 0 no-repeat;
    z-index: 1; }
  .mobile-frame .mobile-frame-bottom {
    left: calc( (320px - 448px) / 2);
    width: 448px;
    height: 127px;
    bottom: -127px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bottom.v4.png") center 0 no-repeat;
    z-index: 1;
    pointer-events: all; }
  @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    .mobile-frame .mobile-frame-top {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-top@x2.v5.png") center 0 no-repeat; }
    .mobile-frame .mobile-frame-bottom {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bottom@x2.v5.png") center 0 no-repeat; }
    .mobile-frame .site-shadow {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bg@x2.v4.png") center 0 repeat-y; } }
  .mobile-frame.previewMode .site-shadow {
    max-height: var(--g-mobile-preview-max-height); }

.new-mobile-editor-layout .mobile-frame-scroll-wrapper {
  position: absolute;
  width: 100%;
  height: 100%; }
  .new-mobile-editor-layout .mobile-frame-scroll-wrapper.with-shadow-extra-space {
    height: calc(100% + 30px);
    top: -15px; }
    .new-mobile-editor-layout .mobile-frame-scroll-wrapper.with-shadow-extra-space .mobile-frame {
      height: calc(100% - 30px);
      top: 15px; }
  .new-mobile-editor-layout .mobile-frame-scroll-wrapper.with-frame-extra-space {
    height: calc(100% + 80px);
    top: -40px; }
    .new-mobile-editor-layout .mobile-frame-scroll-wrapper.with-frame-extra-space .mobile-frame {
      height: calc(100% - 80px);
      top: 40px; }
  .new-mobile-editor-layout .mobile-frame-scroll-wrapper .mobile-frame-scroll-container {
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    height: 100%; }
  .new-mobile-editor-layout .mobile-frame-scroll-wrapper .mobile-frame-scroll-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-right: 17px solid #f7f8f8; }

.new-mobile-editor-layout .fixed-margin-top .mobile-frame-scroll-cover {
  border-color: transparent; }

.new-mobile-editor-layout .fixed-margin-top .mobile-frame-scroll-container {
  overflow-y: hidden;
  width: calc(100% - var(--stage-scrollbar-width)); }

.new-mobile-editor-layout .mobile-frame .site-shadow {
  box-sizing: border-box;
  height: calc(100% + 50px);
  width: calc(100% + 8px);
  left: -4px;
  top: -25px;
  background: none;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  border-color: #fff;
  border-style: solid;
  border-width: 25px 4px;
  border-radius: 18px; }
  .sections-experience .new-mobile-editor-layout .mobile-frame .site-shadow {
    box-shadow: 0px -1px 8px 2px rgba(0, 0, 0, 0.08), 0px 1px 8px 2px rgba(0, 0, 0, 0.08), 2px 0px 8px 2px rgba(0, 0, 0, 0.08), -2px 0px 8px 2px rgba(0, 0, 0, 0.08); }
  .new-mobile-editor-layout .mobile-frame .site-shadow:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border: solid 1px #dfe5eb; }

.new-mobile-editor-layout .mobile-frame .mobile-frame-top {
  display: block;
  top: -25px;
  left: -4px;
  height: 25px;
  width: calc(100% + 8px);
  background: none; }
  .new-mobile-editor-layout .mobile-frame .mobile-frame-top:before, .new-mobile-editor-layout .mobile-frame .mobile-frame-top:after {
    content: '';
    position: absolute;
    top: 50%; }
  .new-mobile-editor-layout .mobile-frame .mobile-frame-top:after {
    left: 50%;
    transform: translate(14px, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50px;
    border: 1px solid #dfe5eb; }
  .new-mobile-editor-layout .mobile-frame .mobile-frame-top:before {
    left: 50%;
    transform: translate(-24px, -50%);
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: #dfe5eb; }

.new-mobile-editor-layout .mobile-frame .mobile-frame-bottom {
  display: block;
  bottom: -25px;
  left: -4px;
  height: 25px;
  width: calc(100% + 8px);
  background: none; }

.new-mobile-editor-layout .mobile-frame.previewMode .site-shadow {
  max-height: calc(100% + 50px); }

.new-mobile-editor-layout .mobile-frame:not(.frameless) {
  animation: mobile-frameless-out-animation 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; }

.new-mobile-editor-layout .mobile-frame.frameless {
  animation: mobile-frameless-in-animation 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; }
  .new-mobile-editor-layout .mobile-frame.frameless .mobile-frame-top,
  .new-mobile-editor-layout .mobile-frame.frameless .mobile-frame-bottom {
    display: none; }
  .new-mobile-editor-layout .mobile-frame.frameless .site-shadow {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17); }
    .sections-experience .new-mobile-editor-layout .mobile-frame.frameless .site-shadow {
      box-shadow: 0px -1px 8px 2px rgba(0, 0, 0, 0.08), 0px 1px 8px 2px rgba(0, 0, 0, 0.08), 2px 0px 8px 2px rgba(0, 0, 0, 0.08), -2px 0px 8px 2px rgba(0, 0, 0, 0.08); }
    .new-mobile-editor-layout .mobile-frame.frameless .site-shadow:before {
      display: none; }

.mobile-wizard-preview-frame .mobile-frame-scroll-container,
.mobile-wizard-preview-frame-out .mobile-frame-scroll-container {
  overflow: hidden; }

.mobile-wizard-preview-frame .mobile-frame-scroll-cover,
.mobile-wizard-preview-frame-out .mobile-frame-scroll-cover {
  display: none; }

@keyframes mobile-frameless-in-animation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mobile-frameless-out-animation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mobile-wizard-single-step .step-header-wrapper {
  padding: 18px 24px 24px 24px;
  box-sizing: border-box;
  text-align: center; }
  .mobile-wizard-single-step .step-header-wrapper .step-header {
    padding-bottom: 4px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .mobile-wizard-single-step .step-header-wrapper .step-sub-header {
    min-height: 48px; }

.mobile-wizard-single-step .mobile-wizard-thumbnails {
  justify-content: center;
  max-width: 444px;
  min-width: 430px;
  margin: auto; }

.mobile-wizard-first-step .step-image {
  display: block;
  margin: 9px auto 0;
  min-height: 231px; }

.mobile-wizard-first-step .step-header {
  display: block;
  margin-top: 5px; }

.mobile-wizard-first-step .step-sub-header {
  display: block;
  margin-top: 10px;
  margin-bottom: 9px; }

.mobile-wizard-last-step .step-header-wrapper {
  text-align: center;
  padding-top: 18px; }
  .mobile-wizard-last-step .step-header-wrapper .step-header {
    padding-bottom: 7px; }
  .mobile-wizard-last-step .step-header-wrapper .step-sub-header {
    height: 48px;
    max-height: 48px;
    overflow: hidden; }

.mobile-wizard-last-step .mobile-wizard-last-step-video {
  width: 482px;
  height: 271px;
  box-shadow: 0 0 4px 1px rgba(17, 91, 154, 0.1), 0 0 18px 2px rgba(17, 91, 154, 0.1);
  margin-bottom: 2px; }

.mobile-thumbnail {
  width: 84px;
  min-width: 84px;
  height: 174px;
  background-color: #fff;
  box-shadow: -1px 2px 4px 1px rgba(43, 86, 114, 0.17), -3px 3px 18px 2px rgba(43, 86, 114, 0.13);
  border: 2px solid transparent;
  position: relative;
  border-radius: 4px;
  transition: transform 0.2s; }
  .mobile-thumbnail:hover {
    transform: scale(1.03); }
  .mobile-thumbnail.selected {
    position: relative;
    border-color: #116dff;
    border-radius: 4px; }
    .mobile-thumbnail.selected .mobile-thumbnail-device-button {
      background-color: #d6e6fe; }
  .mobile-thumbnail .mobile-thumbnail-screen {
    width: 76px;
    height: 136px;
    background-color: #e7f0ff;
    position: absolute;
    top: 14px;
    left: 4px; }
    .mobile-thumbnail .mobile-thumbnail-screen .mobile-thumbnail-image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      border: 0; }
  .mobile-thumbnail .mobile-thumbnail-device-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e7f0ff;
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%); }
  .mobile-thumbnail > .selected-v-sign {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(50%, -50%, 0); }

.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .progress-bar .progress-bar-step {
    margin: 0 12px;
    width: 15px;
    height: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .progress-bar .progress-bar-step .circle {
      background: #d6e6fe;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin: 3px; }
    .progress-bar .progress-bar-step.visited .circle {
      background: #116dff; }
    .progress-bar .progress-bar-step.visited:hover .circle {
      background: #5999ff; }
    .progress-bar .progress-bar-step.visited:not(.current) {
      cursor: pointer; }
    .progress-bar .progress-bar-step.current .circle {
      background: #116dff; }
      .progress-bar .progress-bar-step.current .circle:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 1px solid #116dff;
        width: 13px;
        height: 13px;
        left: 0;
        top: 0; }
        .progress-bar .progress-bar-step.current .circle:after:hover {
          background: #5999ff; }
      .progress-bar .progress-bar-step.current .circle:hover {
        background: #5999ff; }

.mobile-wizard {
  position: absolute;
  width: 100%;
  height: 100%; }
  .mobile-wizard .mobile-wizard-left-half {
    position: relative;
    transition: transform 1.7s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-100%);
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    min-width: 640px; }
  .mobile-wizard .mobile-wizard-top-bar {
    transition: opacity 1.7s ease-in-out;
    opacity: 0;
    width: 100%;
    height: 48px;
    position: absolute;
    top: 0;
    z-index: 1; }
    .mobile-wizard .mobile-wizard-top-bar .top-bar-left-wix-logo {
      border-right: none;
      position: absolute;
      left: 23px;
      top: 15px; }
    .mobile-wizard .mobile-wizard-top-bar .mobile-wizard-top-bar-skip {
      position: absolute;
      font-size: 16px;
      top: 28px;
      right: 36px; }
  .mobile-wizard.mobile-wizard-expanded .mobile-wizard-left-half {
    transform: translateX(0); }
  .mobile-wizard.mobile-wizard-expanded .mobile-wizard-top-bar {
    opacity: 1; }
  .mobile-wizard .mobile-wizard-inner {
    top: calc( 50% - 247.5px - 36px);
    position: relative; }
    .mobile-wizard .mobile-wizard-inner::after {
      content: '';
      border-right: 1px solid #d3edff;
      position: absolute;
      width: 0;
      height: 608px;
      top: 0;
      right: 0; }
    .mobile-wizard .mobile-wizard-inner .current-step-container {
      min-height: 400px;
      max-width: 542px;
      margin: auto;
      width: 100%;
      box-sizing: border-box;
      padding: 12px 0; }
    .mobile-wizard .mobile-wizard-inner .mobile-wizard-actions {
      justify-content: center; }
      .mobile-wizard .mobile-wizard-inner .mobile-wizard-actions button:first-of-type {
        margin-left: 0; }

.mobile-elements-panel-new-workspace {
  overflow: visible; }
  .mobile-elements-panel-new-workspace .panel-header {
    border-top-right-radius: 0; }
  .mobile-elements-panel-new-workspace .left-panel-frame-content {
    background-color: #ffffff;
    border-bottom-right-radius: 10px;
    padding-top: 11px; }
  .mobile-elements-panel-new-workspace .items-wrapper {
    width: 350px;
    position: relative;
    overflow-y: auto;
    height: 100%; }
    .mobile-elements-panel-new-workspace .items-wrapper .selected .listItem {
      background-color: #d6e6fe; }
    .mobile-elements-panel-new-workspace .items-wrapper .listItem {
      display: flex;
      align-items: center;
      padding: 13px 24px; }
      .mobile-elements-panel-new-workspace .items-wrapper .listItem .content {
        margin-top: 0; }
      .mobile-elements-panel-new-workspace .items-wrapper .listItem .image {
        height: 60px;
        width: 60px;
        position: relative; }
      .mobile-elements-panel-new-workspace .items-wrapper .listItem .has-tooltip {
        display: flex; }
      .mobile-elements-panel-new-workspace .items-wrapper .listItem.list-item-with-symbol .image {
        border-radius: 50%;
        height: 90px;
        width: 90px;
        padding: 21px;
        margin: 0;
        background-color: #e7f0ff;
        border: 3px #fff solid;
        box-shadow: none;
        box-sizing: border-box; }
    .mobile-elements-panel-new-workspace .items-wrapper .symbol-arrowRightBig {
      margin-left: 49px;
      width: 23px;
      height: 23px; }
    .mobile-elements-panel-new-workspace .items-wrapper .symbol-plusBig:hover:not(.disabled) .circle {
      fill: #116dff;
      stroke: #116dff; }
  .mobile-elements-panel-new-workspace .panel-shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    pointer-events: none; }
  .mobile-elements-panel-new-workspace :not(.composite-toggle-switch) > .control-switch > .symbol-switch {
    margin-right: 17px; }
  .mobile-elements-panel-new-workspace .category-desc {
    width: 375px; }
    .mobile-elements-panel-new-workspace .category-desc .text {
      margin: 21px 0 16px 0;
      line-height: 20px; }

.qrcode-exposer-wrapper {
  position: absolute;
  z-index: 1300; }
  .qrcode-exposer-wrapper .qrcode-exposer-panel {
    visibility: visible;
    opacity: 1;
    transition: 100ms all ease-in;
    width: 240px;
    margin: 0 0 12px 0;
    padding: 9px 0 0;
    border-radius: 4px;
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    background-color: #ffffff; }
    .qrcode-exposer-wrapper .qrcode-exposer-panel.hidden {
      visibility: hidden;
      opacity: 0; }
    .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-header {
      display: flex;
      justify-content: flex-end;
      margin: 6px 15px -1px 15px; }
      .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-header .qrcode-exposer-panel-header-icon {
        width: 12px;
        height: 12px;
        cursor: pointer; }
    .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-content {
      padding: 0 24px 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
      .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-content .control-text {
        text-align: center; }
      .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-content .qr-code-image {
        padding: 16px 0 23px;
        width: 110px;
        height: 110px; }
    .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-footer {
      padding: 18px 23px 24px 23px; }
      .qrcode-exposer-wrapper .qrcode-exposer-panel .qrcode-exposer-panel-footer .qrcode-exposer-learn-more {
        color: #116dff; }
  .qrcode-exposer-wrapper .qrcode-exposer-button-wrapper {
    display: flex;
    justify-content: flex-start; }
    .qrcode-exposer-wrapper .qrcode-exposer-button-wrapper .qrcode-exposer-button {
      cursor: pointer;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: #ffffff;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 2px 7px -1px rgba(0, 0, 0, 0.14), 0 0 12px -3px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center; }
      .qrcode-exposer-wrapper .qrcode-exposer-button-wrapper .qrcode-exposer-button:hover svg path.st1 {
        fill: #116dff; }

.choose-domain-panel {
  position: relative;
  width: 720px;
  height: 540px;
  background-color: #fff; }
  .choose-domain-panel .choose-domain-action-button {
    position: absolute;
    height: 30px;
    line-height: 30px;
    right: 24px;
    bottom: 27px;
    padding: 0 18px; }
    .choose-domain-panel .choose-domain-action-button:hover {
      background-color: #5999ff; }
    .choose-domain-panel .choose-domain-action-button:active {
      background-color: #116dff; }
    .choose-domain-panel .choose-domain-action-button:disabled {
      background-color: #bcbcbc; }

.choose-domain-panel-options {
  position: relative; }
  .choose-domain-panel-options .domain-option {
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 138px;
    margin-right: 42px;
    margin-left: 42px;
    border-radius: 6px;
    border: 1px solid #d6e6fe;
    cursor: pointer; }
    .choose-domain-panel-options .domain-option:first-of-type {
      margin-top: 30px; }
    .choose-domain-panel-options .domain-option:nth-of-type(2) {
      margin-top: 10px; }
      .choose-domain-panel-options .domain-option:nth-of-type(2) .domain-option-label {
        margin-top: 45px; }
    .choose-domain-panel-options .domain-option .domain-option-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      display: block;
      padding-left: 70px;
      margin-top: 35px;
      color: #3b4057; }
    .choose-domain-panel-options .domain-option .domain-option-radio-button {
      display: none; }
      .choose-domain-panel-options .domain-option .domain-option-radio-button + span {
        position: absolute;
        display: inline-block;
        height: 17px;
        width: 17px;
        left: 30px;
        top: 60px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px #116dff;
        transition-property: background-color, border-width;
        transition-duration: 0.15s;
        box-sizing: border-box;
        cursor: pointer; }
      .choose-domain-panel-options .domain-option .domain-option-radio-button:checked + span {
        background-color: #116dff;
        box-shadow: inset 0 0 0 1px #116dff, inset 0 0 0 2px #fff; }
      .choose-domain-panel-options .domain-option .domain-option-radio-button:not(:checked) + span:hover {
        background-color: #d6e6fe; }
    .choose-domain-panel-options .domain-option .domain-input {
      display: block;
      margin-top: 11px;
      padding-left: 70px;
      max-width: 522px; }
    .choose-domain-panel-options .domain-option .connect-domain-example {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      display: block;
      padding-left: 70px;
      margin-top: 4px;
      color: #868aa5;
      white-space: nowrap; }
    .choose-domain-panel-options .domain-option:not(.checked)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    .choose-domain-panel-options .domain-option.checked {
      background-color: #e7f0ff; }
    .choose-domain-panel-options .domain-option:hover:not(.checked) {
      border-color: #80b1ff; }

.new-workspace-choose-domain-panel .control-custom-modal {
  width: 660px; }

.new-workspace-choose-domain-panel .choose-domain-header {
  min-height: 90px;
  text-align: center; }

.new-workspace-choose-domain-panel .control-custom-modal-footer {
  text-align: right; }

.new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option {
  height: auto;
  min-height: 113px;
  margin: 0 44px;
  padding: 24px 24px 24px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option:not(:first-of-type) {
    margin-top: 12px; }
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .connect-domain-example,
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .domain-option-label,
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .domain-input {
    margin: 0;
    padding: 0; }
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .connect-domain-example,
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .domain-input {
    margin-top: 6px; }
  .new-workspace-choose-domain-panel .choose-domain-panel-options .domain-option .domain-option-radio-button + span {
    top: 50%;
    transform: translateY(-50%); }

.dealer-postsave-banner {
  overflow: hidden; }

.site-was-saved-panel {
  position: fixed;
  top: calc((100% - 348px) / 2);
  left: calc((100% - 720px) / 2);
  width: 720px;
  height: 348px;
  border-radius: 4px;
  overflow: hidden; }
  .site-was-saved-panel.contains-publish-inner-panel {
    height: 480px;
    top: calc((100% - 480px) / 2); }
  .site-was-saved-panel.contains-connect-domain-inner-panel {
    height: 530px;
    top: calc((100% - 530px) / 2); }
  .site-was-saved-panel .wrapper {
    padding-top: 50px;
    margin-left: 72px;
    margin-right: 72px;
    text-align: center;
    height: 100%;
    background-color: #fff; }
    .site-was-saved-panel .wrapper .domain-input {
      display: inline-block;
      box-sizing: border-box;
      width: 100%; }
    .site-was-saved-panel .wrapper .inner-panel {
      top: 60px;
      width: 100%; }
    .site-was-saved-panel .wrapper .site-saved-footer {
      margin-top: 10px; }
      .site-was-saved-panel .wrapper .site-saved-footer .dont-show-again {
        position: absolute;
        bottom: 32px;
        left: 24px; }
      .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        bottom: 36px;
        left: 36px;
        color: #3b4057; }
        .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain a {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          color: #116dff;
          cursor: pointer;
          text-decoration: none; }
          .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain a:hover {
            text-decoration: underline; }
      .site-was-saved-panel .wrapper .site-saved-footer .footer-buttons {
        position: absolute;
        right: 24px;
        bottom: 30px; }
      .site-was-saved-panel .wrapper .site-saved-footer .continue-button,
      .site-was-saved-panel .wrapper .site-saved-footer .publish-button {
        height: 30px;
        line-height: 30px;
        padding: 0 18px; }
      .site-was-saved-panel .wrapper .site-saved-footer .continue-button {
        margin-left: 18px; }
  .site-was-saved-panel.narrow-domain-input .domain-input {
    width: 430px; }

.new-workspace-save-publish-panel-frame .control-custom-modal {
  width: 660px; }

.new-workspace-save-publish-panel-frame .control-custom-modal-content-wrapper {
  padding: 0; }

.new-workspace-save-publish-panel-frame .site-saved-header {
  min-height: 90px;
  text-align: center; }

.new-workspace-save-publish-panel-frame .site-saved-footer {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.new-workspace-save-publish-panel-frame .domain-input {
  margin: 0 85px 24px; }

.new-workspace-save-publish-panel-frame .footer-buttons .control-button {
  margin-left: 12px; }

.new-workspace-save-publish-panel-frame .help-connecting-domain {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057; }
  .new-workspace-save-publish-panel-frame .help-connecting-domain a {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    cursor: pointer;
    text-decoration: none; }
    .new-workspace-save-publish-panel-frame .help-connecting-domain a:hover {
      text-decoration: underline; }

.concurrent-save-panel .content-wrapper {
  display: inline-block;
  font-size: 0;
  position: relative;
  margin: 36px 0 30px 0;
  width: 100%; }

.concurrent-save-panel .error-logo {
  float: left;
  text-align: center;
  width: 186px; }

.concurrent-save-panel .description-wrapper {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  max-width: 294px;
  line-height: 22px;
  margin: -1px 50px 0 0;
  color: #000624; }
  .concurrent-save-panel .description-wrapper .description-line {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    padding-left: 0.2em; }
    .concurrent-save-panel .description-wrapper .description-line:first-child, .concurrent-save-panel .description-wrapper .description-line.initialPadding {
      padding-left: initial; }

.concurrent-save-panel .description-wrapper-wider {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  max-width: 315px;
  line-height: 22px;
  margin: -1px 50px 0 0;
  color: #000624; }
  .concurrent-save-panel .description-wrapper-wider .description-line {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    padding-left: 0.2em;
    margin-top: 15px; }
    .concurrent-save-panel .description-wrapper-wider .description-line:first-child, .concurrent-save-panel .description-wrapper-wider .description-line.initialPadding {
      padding-left: initial; }

.new-workspace-concurrent-save-panel .description-line:not(:last-child) {
  margin-bottom: 1em; }

.save-configuration-panel {
  position: fixed;
  right: 20px;
  top: 100px;
  padding: 20px;
  background-color: #ffd392;
  border-radius: 4px; }
  .save-configuration-panel .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
  .save-configuration-panel .checkbox-field {
    margin: 10px 10px; }
  .save-configuration-panel .domain-text-field {
    margin: 0 20px;
    padding-left: 30px; }
  .save-configuration-panel .confirm-button {
    float: right; }
  .save-configuration-panel .save-configuration-footer {
    text-align: center;
    margin-top: 10px; }
  .save-configuration-panel label.disabled {
    color: #808080; }

.save-on-boarding-site-confirm-panel {
  margin-top: 0;
  width: 564px;
  height: 275px; }
  .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content {
    width: 504px;
    margin: 34px 30px 30px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .image-container {
      display: inline-block;
      width: 132px;
      height: 94px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .description-container {
      display: inline-block;
      padding-left: 24px;
      width: 348px;
      vertical-align: top; }
      .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .description-container > .description-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        line-height: 22px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .footer-buttons {
      text-align: right; }
      .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .footer-buttons .footer-button {
        margin: 13px 0 0 10px; }

.save-publish-panel-frame.hide-panel-before-dealer-loaded {
  display: none; }

.publish-success-panel {
  width: 744px;
  border-radius: 4px; }
  .publish-success-panel.contains-grow-business-inner-panel .publish-inner-panel {
    margin-top: 10px; }
  .publish-success-panel.hide-panel-before-dealer-loaded {
    position: absolute;
    left: -2000px; }
  .publish-success-panel .content {
    position: relative;
    top: -20px; }
  .publish-success-panel .address-bar-wrapper,
  .publish-success-panel .publish-inner-panel,
  .publish-success-panel .publish-success-footer {
    background-color: #fff; }
  .publish-success-panel .address-bar-wrapper {
    color: #868aa5;
    text-align: center;
    padding: 50px 0;
    position: static;
    height: 36px; }
    .publish-success-panel .address-bar-wrapper .address {
      display: inline-block;
      width: 387px; }
    .publish-success-panel .address-bar-wrapper .view-site-button {
      background-color: #e7f0ff;
      color: #116dff;
      display: inline-block;
      width: auto;
      min-width: 0;
      height: 30px;
      line-height: 30px;
      margin-left: 12px;
      font-weight: 400;
      vertical-align: top;
      margin-top: 3px; }
      .publish-success-panel .address-bar-wrapper .view-site-button:hover {
        background-color: #116dff;
        color: #e7f0ff; }
  .publish-success-panel .publish-success-footer {
    position: relative;
    width: 100%;
    height: 105px;
    bottom: 0; }
    .publish-success-panel .publish-success-footer .footer-buttons {
      position: absolute;
      right: 24px;
      bottom: 30px; }
      .publish-success-panel .publish-success-footer .footer-buttons .btn-confirm-primary {
        margin-left: 18px;
        padding: 0 18px;
        height: 30px;
        line-height: 30px; }
      .publish-success-panel .publish-success-footer .footer-buttons .btn-confirm-secondary {
        padding: 0 18px;
        height: 30px;
        line-height: 30px; }
    .publish-success-panel .publish-success-footer .dont-show-again {
      position: absolute;
      left: 32px;
      top: calc(50% - 9px); }

.new-workspace-publish-success-panel .control-custom-modal {
  width: 660px; }
  .new-workspace-publish-success-panel .control-custom-modal .control-custom-modal-footer {
    padding: 0; }

.new-workspace-publish-success-panel .control-custom-modal-content-wrapper {
  padding: 0; }

.new-workspace-publish-success-panel .publish-success-header {
  min-height: 90px;
  text-align: center; }

.new-workspace-publish-success-panel .publish-inner-panel {
  overflow: hidden; }

.new-workspace-publish-success-panel .publish-success-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .new-workspace-publish-success-panel .publish-success-footer .control-button {
    margin-left: 12px; }
  .new-workspace-publish-success-panel .publish-success-footer .footer-inner {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px 24px 30px; }
    .new-workspace-publish-success-panel .publish-success-footer .footer-inner .footer-buttons {
      display: flex;
      gap: 12px;
      margin-left: auto; }
  .new-workspace-publish-success-panel .publish-success-footer .disclaimer {
    font-size: 12px;
    color: #3b4057;
    font-weight: 400;
    text-align: center;
    padding: 12px 11.5px 12px 11.5px; }

.new-workspace-publish-success-panel .address-bar-wrapper {
  display: flex;
  align-items: center;
  margin: 0 100px 24px; }
  .new-workspace-publish-success-panel .address-bar-wrapper .address {
    flex: 1; }
  .new-workspace-publish-success-panel .address-bar-wrapper .control-button {
    margin-left: 12px; }

.bulletItem {
  display: flex;
  margin-top: 10px; }

.bulletItemText {
  margin-left: 10px; }

.learn-more {
  margin-top: 20px; }

.prepare-panel .control-message-modal {
  width: 602px; }

.rc-success-panel-wrapper {
  text-align: center; }

.publish-rc-old .desc {
  width: 510px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 25px;
  display: inline-block; }

.publish-rc-old .publish-inner-panel {
  padding-top: 31px; }

.publish-rc-old .publish-success-footer {
  height: 78px; }

.publish-rc .publish-rc-header {
  text-align: center; }
  .publish-rc .publish-rc-header .publish-rc-header-main {
    margin-bottom: 6px; }

.publish-rc .address {
  display: flex;
  padding: 24px 12px;
  align-items: center; }

.publish-rc .address-url {
  flex-grow: 1;
  margin-right: 12px; }

.publish-rc .publish-rc-footer {
  display: flex;
  justify-content: flex-end; }

.publish-rc .publish-success-footer {
  height: 78px; }

.publish-rc .publish-inner-panel {
  padding-top: 31px; }

.publish-rc .desc {
  width: 510px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 25px;
  display: inline-block; }

.panel-grow-business,
.panel-shout-out,
.panel-connect-domain {
  border-radius: 4px;
  margin: 0 auto;
  width: 530px;
  height: 198px;
  position: relative; }
  .panel-grow-business .promote-title-wrapper,
  .panel-shout-out .promote-title-wrapper,
  .panel-connect-domain .promote-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 498px;
    margin: 0 auto; }
    .panel-grow-business .promote-title-wrapper .promote-title,
    .panel-shout-out .promote-title-wrapper .promote-title,
    .panel-connect-domain .promote-title-wrapper .promote-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 20px;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
      color: rgba(59, 64, 87, 0.8);
      margin: 0 18px;
      flex-shrink: 0; }
    .panel-grow-business .promote-title-wrapper .divider-long,
    .panel-shout-out .promote-title-wrapper .divider-long,
    .panel-connect-domain .promote-title-wrapper .divider-long {
      flex: 1 1 auto;
      background-color: rgba(59, 64, 87, 0.3); }
  .panel-grow-business .promotion-content,
  .panel-shout-out .promotion-content,
  .panel-connect-domain .promotion-content {
    display: flex;
    justify-content: space-between;
    margin-top: 22px; }
  .panel-grow-business .promote,
  .panel-shout-out .promote,
  .panel-connect-domain .promote {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
    color: #3b4057;
    text-align: center; }
    .panel-grow-business .promote .promote-symbol,
    .panel-grow-business .promote .promote-image,
    .panel-shout-out .promote .promote-symbol,
    .panel-shout-out .promote .promote-image,
    .panel-connect-domain .promote .promote-symbol,
    .panel-connect-domain .promote .promote-image {
      position: relative;
      width: 50px;
      height: 62px; }
      .panel-grow-business .promote .promote-symbol svg,
      .panel-grow-business .promote .promote-image svg,
      .panel-shout-out .promote .promote-symbol svg,
      .panel-shout-out .promote .promote-image svg,
      .panel-connect-domain .promote .promote-symbol svg,
      .panel-connect-domain .promote .promote-image svg {
        width: 100%;
        height: 100%; }
    .panel-grow-business .promote .promote-symbol-traffic,
    .panel-shout-out .promote .promote-symbol-traffic,
    .panel-connect-domain .promote .promote-symbol-traffic {
      top: -2px; }
    .panel-grow-business .promote .promote-symbol-newsletter,
    .panel-shout-out .promote .promote-symbol-newsletter,
    .panel-connect-domain .promote .promote-symbol-newsletter {
      left: 5px;
      top: -7px; }
    .panel-grow-business .promote .promote-symbol-mail,
    .panel-shout-out .promote .promote-symbol-mail,
    .panel-connect-domain .promote .promote-symbol-mail {
      top: -8px; }
    .panel-grow-business .promote .promote-image,
    .panel-shout-out .promote .promote-image,
    .panel-connect-domain .promote .promote-image {
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 50px; }
    .panel-grow-business .promote .description,
    .panel-shout-out .promote .description,
    .panel-connect-domain .promote .description {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      margin: 4px 0 2px 0;
      max-width: 154px;
      color: #3b4057; }
    .panel-grow-business .promote a,
    .panel-shout-out .promote a,
    .panel-connect-domain .promote a {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      margin-top: 2px;
      color: #116dff; }
      .panel-grow-business .promote a:hover,
      .panel-shout-out .promote a:hover,
      .panel-connect-domain .promote a:hover {
        cursor: pointer;
        text-decoration: underline; }

.connect-domain-panel {
  position: fixed;
  top: calc((100% - 435px) / 2);
  left: calc((100% - 720px) / 2);
  height: 435px;
  width: 720px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14); }
  .connect-domain-panel a {
    color: #116dff; }
    .connect-domain-panel a:hover {
      text-decoration: underline;
      cursor: pointer; }
  .connect-domain-panel .content {
    background: transparent; }
  .connect-domain-panel .actions,
  .connect-domain-panel .panel-content {
    background-color: #fff;
    height: 161px;
    padding: 43px 35px 5px 35px; }
  .connect-domain-panel .actions .action {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    background-color: #e7f0ff;
    border-radius: 4px;
    display: block;
    margin: 12px auto;
    height: 104px;
    line-height: 104px;
    position: relative;
    width: 528px; }
    .connect-domain-panel .actions .action:first-child {
      margin: 0 auto; }
    .connect-domain-panel .actions .action:hover {
      cursor: pointer;
      background-color: #d6e6fe; }
    .connect-domain-panel .actions .action .action-graphics {
      display: inline-block;
      margin-left: 42px;
      width: 63px; }
      .connect-domain-panel .actions .action .action-graphics svg {
        height: 56px;
        margin-top: 24px; }
    .connect-domain-panel .actions .action .action-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      position: relative;
      margin-left: 20px;
      left: 0;
      vertical-align: top;
      word-break: normal;
      width: calc(100% - 220px);
      line-height: 22px;
      top: 50%;
      transform: translateY(-50%);
      color: #3b4057;
      display: inline-block; }
    .connect-domain-panel .actions .action .action-arrow {
      display: inline-block;
      position: relative;
      left: 30px;
      top: 0;
      vertical-align: top;
      height: 100%; }
      .connect-domain-panel .actions .action .action-arrow svg {
        width: 25px;
        height: 25px;
        margin-top: 37.5px;
        padding: 2px; }
  .connect-domain-panel .panel-content .content-graphics {
    display: inline-block; }
    .connect-domain-panel .panel-content .content-graphics svg {
      width: 92px; }
  .connect-domain-panel .panel-content .content-top-part {
    margin-bottom: 43px; }
    .connect-domain-panel .panel-content .content-top-part .content-top-message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-left: 22px;
      width: 368px;
      height: 62px; }
      .connect-domain-panel .panel-content .content-top-part .content-top-message .public-url {
        color: #868aa5; }
  .connect-domain-panel .panel-content .content-bottom-message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    background-color: #e7f0ff;
    width: 504px;
    height: 66px;
    line-height: 66px;
    text-align: center; }
  .connect-domain-panel .connect-domain-panel-footer {
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 100px;
    position: absolute;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
    .connect-domain-panel .connect-domain-panel-footer .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: 27px;
      left: 41px; }
    .connect-domain-panel .connect-domain-panel-footer .maybe-later-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: 29px;
      right: 24px;
      height: 30px;
      line-height: 30px;
      padding: 0 18px; }
  .classic-facelift-skin .connect-domain-panel .control-custom-modal {
    width: 660px; }
    .classic-facelift-skin .connect-domain-panel .control-custom-modal .control-base-modal-body {
      padding-bottom: 5px; }
  .classic-facelift-skin .connect-domain-panel .connect-domain-header {
    min-height: 90px;
    text-align: center; }
  .classic-facelift-skin .connect-domain-panel .actions {
    min-height: 260px;
    padding: 0; }
    .classic-facelift-skin .connect-domain-panel .actions .action {
      display: flex;
      align-items: center;
      min-width: 572px;
      height: 114px;
      background-color: rgba(231, 240, 255, 0.5);
      border-radius: 8px; }
      .classic-facelift-skin .connect-domain-panel .actions .action:hover {
        background-color: rgba(214, 230, 254, 0.5); }
      .classic-facelift-skin .connect-domain-panel .actions .action .action-title {
        top: 0;
        transform: inherit;
        font-size: 18px;
        line-height: 24px;
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .classic-facelift-skin .connect-domain-panel .actions .action .action-graphics {
        line-height: initial; }
        .classic-facelift-skin .connect-domain-panel .actions .action .action-graphics svg {
          margin-top: 0; }
      .classic-facelift-skin .connect-domain-panel .actions .action .action-arrow {
        display: flex;
        align-items: center; }
        .classic-facelift-skin .connect-domain-panel .actions .action .action-arrow svg {
          margin-top: 0; }
  .classic-facelift-skin .connect-domain-panel a:hover {
    text-decoration: none; }

.connect-domain-in-progress-panel {
  position: fixed;
  top: calc((100% - 474px) / 2);
  left: calc((100% - 720px) / 2);
  height: 474px;
  width: 720px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff; }
  .connect-domain-in-progress-panel a {
    color: #116dff; }
    .connect-domain-in-progress-panel a:hover {
      text-decoration: underline;
      cursor: pointer; }
  .connect-domain-in-progress-panel .content {
    background: transparent; }
  .connect-domain-in-progress-panel .actions,
  .connect-domain-in-progress-panel .panel-content {
    background-color: #fff;
    height: 208px;
    padding: 55px 108px 5px 108px; }
  .connect-domain-in-progress-panel .actions .action {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    background-color: #e7f0ff;
    border-radius: 4px;
    display: block;
    margin: 12px auto;
    height: 104px;
    line-height: 104px;
    position: relative;
    width: 528px; }
    .connect-domain-in-progress-panel .actions .action:first-child {
      margin: 0 auto; }
    .connect-domain-in-progress-panel .actions .action:hover {
      cursor: pointer;
      background-color: #d6e6fe; }
    .connect-domain-in-progress-panel .actions .action .action-graphics {
      display: inline-block;
      margin-left: 42px;
      width: 63px; }
      .connect-domain-in-progress-panel .actions .action .action-graphics svg {
        height: 56px;
        margin-top: 24px; }
    .connect-domain-in-progress-panel .actions .action .action-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      position: relative;
      margin-left: 20px;
      left: 0;
      vertical-align: top;
      word-break: normal;
      width: calc(100% - 220px);
      line-height: 22px;
      top: 50%;
      transform: translateY(-50%);
      color: #3b4057;
      display: inline-block; }
    .connect-domain-in-progress-panel .actions .action .action-arrow {
      display: inline-block;
      position: relative;
      left: 30px;
      top: 0;
      vertical-align: top;
      height: 100%; }
      .connect-domain-in-progress-panel .actions .action .action-arrow svg {
        width: 25px;
        height: 25px;
        margin-top: 37.5px;
        padding: 2px; }
  .connect-domain-in-progress-panel .panel-content .content-graphics {
    display: inline-block; }
    .connect-domain-in-progress-panel .panel-content .content-graphics svg {
      width: 92px; }
  .connect-domain-in-progress-panel .panel-content .content-top-part {
    margin-bottom: 43px; }
    .connect-domain-in-progress-panel .panel-content .content-top-part .content-top-message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-left: 22px;
      width: 368px;
      height: 62px; }
      .connect-domain-in-progress-panel .panel-content .content-top-part .content-top-message .public-url {
        color: #868aa5; }
  .connect-domain-in-progress-panel .panel-content .content-bottom-message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    background-color: #e7f0ff;
    width: 504px;
    height: 66px;
    line-height: 66px;
    text-align: center; }
  .connect-domain-in-progress-panel .connect-domain-panel-footer {
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 80px;
    position: absolute;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
    .connect-domain-in-progress-panel .connect-domain-panel-footer .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: 27px;
      left: 41px; }
    .connect-domain-in-progress-panel .connect-domain-panel-footer .maybe-later-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: 29px;
      right: 24px;
      height: 30px;
      line-height: 30px;
      padding: 0 18px; }

.general-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 530px;
  margin: 0 auto; }
  .general-content .main-title-wrapper {
    position: relative;
    display: flex;
    align-items: center; }
    .general-content .main-title-wrapper .main-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 20px;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
      color: rgba(59, 64, 87, 0.8);
      margin: 0 18px; }
      .classic-facelift-skin .general-content .main-title-wrapper .main-title {
        white-space: nowrap; }
    .general-content .main-title-wrapper .divider-long {
      flex: 1 1 auto;
      background-color: rgba(59, 64, 87, 0.3); }
  .general-content .middle-spacer {
    width: 100%;
    height: 10px; }
    .classic-facelift-skin .general-content .middle-spacer {
      height: 12px; }
  .general-content .bottom-section {
    position: relative;
    display: flex;
    flex: 1 1 auto; }
    .general-content .bottom-section .left-side {
      display: inline-block;
      flex: 1 0 auto;
      width: 145px;
      height: 100%;
      text-align: center; }
      .general-content .bottom-section .left-side svg {
        position: relative;
        left: -5px; }
    .general-content .bottom-section .right-side {
      display: inline-block;
      flex: 1 1 auto;
      height: 100%;
      width: calc(100% - 145px); }
      .general-content .bottom-section .right-side .title {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-medium);
        font-size: 20px;
        -webkit-font-smoothing: antialiased;
        margin-top: 15px;
        text-align: left;
        color: #3b4057;
        word-wrap: break-word; }
        .classic-facelift-skin .general-content .bottom-section .right-side .title {
          color: #000624; }
      .general-content .bottom-section .right-side .subtitle {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        margin-top: 10px;
        text-align: left;
        color: #868aa5;
        word-wrap: break-word; }
      .general-content .bottom-section .right-side .general-content-action {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        color: #116dff;
        display: block;
        margin-top: 10px;
        text-align: left;
        word-wrap: break-word; }
        .general-content .bottom-section .right-side .general-content-action:hover {
          text-decoration: underline;
          cursor: pointer; }
        .classic-facelift-skin .general-content .bottom-section .right-side .general-content-action {
          display: inline-block; }
  .general-content.music-promo-content .middle-spacer {
    height: 32px; }
  .general-content.music-promo-content .right-side .title {
    margin-top: 0; }
  .general-content.music-promo-content .left-side {
    margin-top: 5px; }
    .general-content.music-promo-content .left-side img {
      position: relative;
      left: -4px; }
  .general-content.photo-promo-content .middle-spacer {
    height: 32px; }
  .general-content.photo-promo-content .right-side .title {
    margin-top: 0; }
  .general-content.photo-promo-content .left-side {
    position: relative;
    width: 121px;
    margin-right: 24px; }
  .general-content.connect-domain-content .middle-spacer {
    height: 17px; }
    .classic-facelift-skin .general-content.connect-domain-content .middle-spacer {
      height: 12px; }
  .general-content.personal-sale-campaign .bottom-section .left-side svg .symbol-connectDomainPersonalSale {
    top: -12px; }
  .general-content.personal-sale-campaign .bottom-section .right-side .subtitle span {
    color: #9a27d5; }
  .general-content.personal-sale-campaign .bottom-section .left-side svg .symbol-manageExposure {
    top: 20px; }

.message-panel-frame.fail-panel .control-illustration svg {
  margin-top: 20px; }

.message-panel-frame.fail-panel .control-illustration svg.symbol-publish_test_site_override_warning {
  margin-top: 0; }

.message-panel-frame.fail-panel .fail-panel__message .steps-title {
  font-weight: 600; }

.message-panel-frame.fail-panel .fail-panel__message .error-description {
  max-width: 375px; }
  .message-panel-frame.fail-panel .fail-panel__message .error-description span {
    margin-right: 4px; }
  .message-panel-frame.fail-panel .fail-panel__message .error-description .error-description-secondary-text {
    margin-top: 10px; }

.message-panel-frame.fail-panel .fail-panel__message .error-description,
.message-panel-frame.fail-panel .fail-panel__message .description,
.message-panel-frame.fail-panel .fail-panel__message h3 {
  margin-bottom: 16px;
  margin-top: 0; }

.message-panel-frame.fail-panel .fail-panel__message ol {
  padding: 0 0 0 24px;
  margin: 0; }
  .message-panel-frame.fail-panel .fail-panel__message ol li {
    list-style: inherit;
    margin-bottom: 5px; }
    .message-panel-frame.fail-panel .fail-panel__message ol li:last-of-type {
      margin-bottom: 0; }

.message-panel-frame.fail-panel .fail-panel-help-text {
  margin: 0; }
  .message-panel-frame.fail-panel .fail-panel-help-text span {
    color: #3b4057; }
  .message-panel-frame.fail-panel .fail-panel-help-text .link-part {
    padding-left: 0.4em;
    color: #116dff; }
    .message-panel-frame.fail-panel .fail-panel-help-text .link-part:hover {
      cursor: pointer;
      text-decoration: underline; }
  .message-panel-frame.fail-panel .fail-panel-help-text .error-code-part {
    padding-left: 0.4em; }

.message-panel-frame.fail-panel.test-site-override-fail-panel {
  width: 615px; }

.failPanel ol {
  padding-left: 18px; }

.failPanel li {
  list-style: inherit; }

.failPanel a {
  color: #116dff; }
  .failPanel a:hover {
    cursor: pointer;
    text-decoration: underline; }

.failPanel .error-description-secondary-text {
  margin-top: 10px; }

.failPanel .errorDescriptionText {
  margin-right: 4px; }

.failPanel :global(.fail-panel-help-text) {
  font-size: 14px; }
  .failPanel :global(.fail-panel-help-text) :global(.link-part) {
    margin-left: 4px; }

.save-publish-panel + .dark-frame-overlay {
  background-color: rgba(0, 6, 36, 0.85); }

.save-publish-panel-header,
.confirm-panel-header {
  position: relative;
  height: 126px;
  border-radius: 4px 4px 0 0;
  background-color: #116dff;
  box-sizing: border-box;
  padding-top: 35px;
  text-align: center; }
  .save-publish-panel-header .title,
  .confirm-panel-header .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 30px;
    -webkit-font-smoothing: antialiased;
    display: block;
    line-height: 30px;
    color: #fff;
    white-space: nowrap; }
  .save-publish-panel-header .subtitle,
  .confirm-panel-header .subtitle {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-top: 10px;
    white-space: nowrap;
    color: #d6e6fe; }
  .save-publish-panel-header button,
  .confirm-panel-header button {
    border-radius: 50%;
    border: 0;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 24px;
    right: 24px;
    background-color: #0057e1;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0; }
    .save-publish-panel-header button svg,
    .confirm-panel-header button svg {
      fill: #fff; }
    .save-publish-panel-header button:hover,
    .confirm-panel-header button:hover {
      background-color: #003790; }

.multilingual-manage-panel {
  width: 480px;
  height: 80%;
  position: relative; }
  .multilingual-manage-panel .ml-toggle {
    width: 230px;
    margin: 0 auto; }
  .multilingual-manage-panel .control-dropdown-base {
    max-width: none !important; }
  .multilingual-manage-panel .btn-delete {
    padding: 6px; }
    .multilingual-manage-panel .btn-delete svg {
      margin: 0 !important; }
  .multilingual-manage-panel .lang-toggle::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #dfe5eb; }
  .multilingual-manage-panel .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 78px); }
  .multilingual-manage-panel .original-language-container {
    padding: 24px;
    line-height: 24px; }

.multilingual-init-panel .content {
  padding-bottom: 0; }

.multilingual-init-panel .help {
  top: 24px; }

.multilingual-init-panel .composite-dropdown-labeled {
  padding: 0; }

.icon-button-container {
  display: inline-block; }
  .icon-button-container .icon-button {
    border-radius: 4px;
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    fill: #3b4057;
    padding: 0;
    cursor: pointer; }
    .icon-button-container .icon-button.small {
      width: 24px;
      height: 24px;
      padding-top: 3px; }
    .icon-button-container .icon-button svg:not(.symbol-arrowDown) {
      display: inline-block;
      vertical-align: top;
      padding: 0; }
    .icon-button-container .icon-button.popup {
      width: 52px;
      position: relative;
      text-align: left;
      padding: 0 11px; }
      .icon-button-container .icon-button.popup i.arrow-button {
        position: absolute;
        right: 8px; }
      .icon-button-container .icon-button.popup svg:not(.symbol-arrowDown) {
        vertical-align: middle !important; }
    .icon-button-container .icon-button .symbol-textOutdentRight,
    .icon-button-container .icon-button .symbol-textIndentRight,
    .icon-button-container .icon-button .symbol-textOutdentLeft,
    .icon-button-container .icon-button .symbol-textIndentLeft {
      width: 22px;
      height: 22px; }
    .icon-button-container .icon-button:hover {
      background-color: #e7f0ff; }
    .icon-button-container .icon-button.selected, .icon-button-container .icon-button:active {
      background-color: #116dff;
      color: #ffffff; }
      .icon-button-container .icon-button.selected svg, .icon-button-container .icon-button:active svg {
        fill: #ffffff !important; }
    .icon-button-container .icon-button.selected:hover {
      background-color: #5999ff; }

.dropdown-options .option.icons-dd-option {
  line-height: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  text-align: center; }
  .dropdown-options .option.icons-dd-option svg {
    fill: #3b4057;
    width: 22px;
    height: 22px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    pointer-events: none; }
    .dropdown-options .option.icons-dd-option svg.symbol-textListNone {
      width: 18px !important;
      height: 18px !important; }
  .dropdown-options .option.icons-dd-option.selected svg {
    fill: #ffffff; }

.icons-dd {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px; }
  .icons-dd:hover {
    background-color: #e7f0ff; }
  .icons-dd:active {
    background-color: #5999ff; }
    .icons-dd:active svg {
      fill: #ffffff !important; }
  .icons-dd.small .selected-container {
    height: 24px !important;
    line-height: 24px !important;
    min-height: 24px !important;
    padding-left: 0;
    padding-right: 22px !important; }
    .icons-dd.small .selected-container svg:not(.symbol-arrowDown) {
      vertical-align: middle;
      fill: #3b4057; }
  .icons-dd .selected-container {
    height: 32px !important;
    line-height: 32px !important;
    min-height: 32px !important;
    padding-left: 9px;
    padding-right: 24px !important; }
    .icons-dd .selected-container svg:not(.symbol-arrowDown) {
      vertical-align: middle; }
  .icons-dd i.expand.arrow {
    border: none !important;
    width: 9px !important;
    opacity: 1 !important;
    right: 8px !important; }
    .icons-dd i.expand.arrow svg {
      width: 9px;
      padding: 0; }
    .icons-dd i.expand.arrow:hover {
      background-color: transparent !important; }
      .icons-dd i.expand.arrow:hover svg {
        fill: #116dff !important; }

.back-color-dd {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px; }
  .back-color-dd svg {
    fill: #3b4057; }
  .back-color-dd:hover {
    background-color: #e7f0ff; }
  .back-color-dd:active {
    background-color: #5999ff; }
    .back-color-dd:active svg {
      fill: #ffffff !important; }
  .back-color-dd .selected-container {
    height: 32px !important;
    line-height: 32px !important;
    min-height: 32px !important;
    width: 32px;
    padding-right: 0 !important; }
    .back-color-dd .selected-container .symbol-textBackColor {
      vertical-align: middle !important; }

.themes-dropdown-options .dropdown-options {
  width: 264px; }
  .themes-dropdown-options .dropdown-options .custom-option {
    display: none; }

.themes-dropdown-options.advanced-style-option .dropdown-theme-option .has-tooltip {
  padding: 0; }

.themes-dropdown-options.advanced-style-option .dropdown-theme-option-color {
  display: none; }

.themes-dropdown-options.advanced-style-option .dropdown-theme-option-html-tag {
  display: none; }

.fonts-dropdown {
  width: 336px; }

.text-effect-container {
  padding: 0 0 20px 25px; }
  .text-effect-container .text-none-icon {
    margin: 12px 12px 0 0;
    width: 32px;
    height: 32px;
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    position: relative;
    border: 2px solid #dfe5eb; }
    .text-effect-container .text-none-icon:before {
      content: '';
      height: 2px;
      background: #f00;
      position: absolute;
      width: 42px;
      right: -5px;
      top: 15px;
      transform: rotate(135deg); }
    .text-effect-container .text-none-icon:hover {
      background: #e7f0ff; }
    .text-effect-container .text-none-icon.selected {
      border: 2px solid #116dff; }

.text-effect-button {
  color: #116dff;
  font-size: 26px;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  background-color: #e7f0ff;
  border: none;
  outline: none;
  display: inline-block;
  margin: 12px 12px 0 0;
  padding: 0;
  font-weight: 600;
  cursor: pointer; }
  .text-effect-button:hover {
    background-color: #d6e6fe; }
  .text-effect-button.selected, .text-effect-button:active {
    background-color: #116dff;
    color: #e7f0ff; }
    .text-effect-button.selected:hover, .text-effect-button:active:hover {
      background-color: #5999ff; }

.text-popup-control {
  display: inline-block;
  position: relative; }
  .text-popup-control .text-popup {
    bottom: 32px;
    position: absolute;
    background: #fff;
    z-index: 5999;
    width: 258px;
    padding: 10px;
    border-radius: 4px; }
  .text-popup-control .tri {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    top: -25px;
    right: 25px; }
  .text-popup-control .cover-shadow {
    background: #fff;
    z-index: 6000; }
  .text-popup-control .with-shadow {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.44); }

.text-style-save-confirm-panel {
  margin-top: 0; }
  .text-style-save-confirm-panel .text-style-save-confirm-content {
    width: 548px;
    margin: 30px 24px; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .image-container {
      display: inline-block;
      padding-left: 17px; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .description-container {
      display: inline-block;
      padding-left: 22px;
      width: 375px;
      vertical-align: top; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .description-container > .description-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        line-height: 22px; }
        .text-style-save-confirm-panel .text-style-save-confirm-content .description-container > .description-text.bold {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-medium);
          font-size: 16px;
          -webkit-font-smoothing: antialiased; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container {
        display: block; }
        .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container .learn-more-link {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          color: #116dff;
          line-height: 22px;
          display: inline-block;
          margin-left: 0; }
          .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container .learn-more-link:hover {
            cursor: pointer;
            text-decoration: underline; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .footer-buttons {
      margin-top: 30px;
      display: flex;
      align-items: center; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .footer-buttons .save-button {
        margin-left: auto; }

.text-link-dd .link-icon-container {
  text-align: center;
  vertical-align: top;
  border-radius: 4px;
  background-color: #116dff; }
  .text-link-dd .link-icon-container svg {
    margin-top: -4px; }
  .text-link-dd .link-icon-container.secondary {
    background-color: transparent; }
    .text-link-dd .link-icon-container.secondary svg {
      fill: #116dff; }
  .text-link-dd .link-icon-container:active {
    background-color: #5999ff; }

.small .text-link-dd.dropdown > .dd > .selected-container {
  height: 24px;
  line-height: 24px;
  min-height: 24px;
  width: 24px; }

.control-line-height .no-bottom-padding {
  padding-bottom: 0; }

.password-control .password-label {
  margin-bottom: 6px; }

.tpa-gallery-advanced-style-panel .color-picker-input {
  padding: 16px 24px 19px !important;
  box-sizing: border-box; }
  .tpa-gallery-advanced-style-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
    right: 24px !important;
    top: 16px; }

.tpa-gallery-advanced-style-panel .color-picker-input-with-opacity {
  margin: 15px 24px 0 24px; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
    font-size: 18px;
    height: 36px; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-slider {
    margin: 0 30px 8px 0; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .color-picker-input {
    padding: 0 !important; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-stepper.small {
    margin: -2px 6px 0 3px;
    padding: 0 6px 0 4px; }

.advanced-style-panel {
  text-align: left; }
  .advanced-style-panel .opacity-disabled-info {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center; }
  .advanced-style-panel.flex-column {
    flex-direction: column;
    display: flex; }
    .advanced-style-panel.flex-column .flex-column {
      flex-direction: column;
      display: flex; }
    .advanced-style-panel.flex-column .flex-child {
      flex: 1; }
  .advanced-style-panel.only-advanced-styling {
    height: auto; }
  .advanced-style-panel .input-slider {
    margin: 10px 24px 7px; }
  .advanced-style-panel .control-section-divider.labeled {
    width: 90%;
    left: 5%;
    padding: 0 18px; }
  .advanced-style-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .advanced-style-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .advanced-style-panel .dropdown {
    padding: 10px 12px;
    margin: 0 11px; }
  .advanced-style-panel .writable-drop-down {
    padding: 10px 12px;
    margin-right: 11px; }
  .advanced-style-panel .selective-text-input .control-checkbox {
    margin: -6px 0; }
  .advanced-style-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .advanced-style-panel :not(.selective-text-input) > .control-checkbox {
    display: block;
    width: calc(100% - 60px);
    margin: 21px 30px;
    box-sizing: border-box; }
  .advanced-style-panel .control-alignment {
    padding: 19px 0 24px 24px; }
    .advanced-style-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .advanced-style-panel :not(.composite-toggle-switch) > .control-switch,
  .advanced-style-panel > .control-switch {
    margin-left: 24px;
    position: static;
    padding-top: 16px; }
    .advanced-style-panel :not(.composite-toggle-switch) > .control-switch .symbol-switch,
    .advanced-style-panel > .control-switch .symbol-switch {
      position: relative;
      bottom: 2px;
      right: 15px; }
  .advanced-style-panel .tab-icon svg.symbol-coverImage,
  .advanced-style-panel .tab-icon svg.symbol-borderDesign,
  .advanced-style-panel .tab-icon svg.symbol-cornersDesign,
  .advanced-style-panel .tab-icon svg.symbol-fillDesign,
  .advanced-style-panel .tab-icon svg.symbol-shadowDesign,
  .advanced-style-panel .tab-icon svg.symbol-textDesign {
    margin: 5px; }
  .advanced-style-panel .tab-text, .advanced-style-panel .tab-text-composite {
    font-size: medium;
    position: absolute;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #3b4057; }
  .advanced-style-panel .tab-text-composite {
    position: static;
    display: flex;
    align-items: center;
    height: 48px; }
  .advanced-style-panel .control-angle {
    margin: 17px 24px 24px; }
  .advanced-style-panel .color-picker-input {
    padding: 16px 24px 19px;
    box-sizing: border-box; }
    .advanced-style-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
      right: 24px;
      top: 16px; }
  .advanced-style-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .advanced-style-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
      font-size: 18px;
      height: 36px; }
    .advanced-style-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 30px 8px 0; }
      .advanced-style-panel .color-picker-input-with-opacity .input-slider .slider {
        margin: 0 75px 0 0; }
    .advanced-style-panel .color-picker-input-with-opacity .color-picker-input {
      padding: 0; }
    .advanced-style-panel .color-picker-input-with-opacity .input-stepper.small {
      margin: -2px 6px 0 3px;
      padding: 0 6px 0 4px; }
  .advanced-style-panel .backBtn {
    margin-top: 7px;
    width: 66px;
    height: 21px;
    background-color: #dfedfd;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #5c99e9;
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    text-align: left; }
    .advanced-style-panel .backBtn:hover {
      background-color: #d6e6fe; }
  .advanced-style-panel .nothing-to-edit {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: #868aa5; }
    .advanced-style-panel .nothing-to-edit svg {
      margin-top: 45px;
      margin-bottom: 24px; }
  .advanced-style-panel .advanced-style-panel-body {
    position: relative; }
    .advanced-style-panel .advanced-style-panel-body .content-container {
      width: 100%;
      position: absolute;
      height: 100%; }
      .advanced-style-panel .advanced-style-panel-body .content-container .control-tabs-vertical {
        border-radius: 0 0 0 4px; }
      .advanced-style-panel .advanced-style-panel-body .content-container .content {
        position: absolute;
        display: inline-block;
        left: 48px;
        top: 0;
        height: 100%;
        width: calc(100% - 48px);
        border-radius: 0 0 4px 0;
        background: #fff; }
        .advanced-style-panel .advanced-style-panel-body .content-container .content .tab-text, .advanced-style-panel .advanced-style-panel-body .content-container .content .tab-text-composite {
          left: 0;
          position: relative; }
        .advanced-style-panel .advanced-style-panel-body .content-container .content .section .control-section-divider + div hr:first-child {
          display: none; }
    .advanced-style-panel .advanced-style-panel-body .scrolled-content {
      overflow-x: hidden; }
    .advanced-style-panel .advanced-style-panel-body .no-options {
      margin-left: 24px;
      margin-right: 24px; }
    .advanced-style-panel .advanced-style-panel-body .font-control {
      position: relative; }
      .advanced-style-panel .advanced-style-panel-body .font-control .text-formatting-toggle-button .input:active ~ .symbol,
      .advanced-style-panel .advanced-style-panel-body .font-control .text-formatting-toggle-button .input:checked ~ .symbol {
        fill: #fff; }
      .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        text-align: right;
        font-weight: 300;
        position: absolute;
        top: 17px;
        right: -20px;
        opacity: 0;
        visibility: hidden; }
        .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator.visible {
          visibility: visible;
          right: 24px;
          opacity: 1;
          transition: right 400ms ease-out, opacity 220ms ease-out 30ms; }
        .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator > .save-theme-button {
          color: #116dff;
          line-height: 21px; }
          .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator > .save-theme-button:hover {
            cursor: pointer;
            text-decoration: underline; }
        .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator .save-success-indicator {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          line-height: 21px;
          color: #25a55a; }
        .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator.saving-theme .save-success-indicator {
          visibility: visible;
          opacity: 1;
          transition: opacity 600ms ease-out 100ms; }
        .advanced-style-panel .advanced-style-panel-body .font-control .save-theme-text-indicator.saving-theme .save-theme-button {
          visibility: hidden; }
  .advanced-style-panel .fixed-button-wrapper {
    padding-bottom: 12px;
    width: 100%;
    text-align: center; }
  .advanced-style-panel .shadow-divider {
    background-color: transparent;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 8px;
    border: 0;
    box-shadow: 0 7px 10px -8px rgba(22, 45, 61, 0.2) inset; }
  .advanced-style-panel .border-position-switch .radio-control {
    margin: 0 10px 10px 0; }

.advanced-style-panel-narrow .advanced-style-panel-body .content-container {
  position: relative;
  display: flex; }
  .advanced-style-panel-narrow .advanced-style-panel-body .content-container > div:first-child {
    height: auto; }
  .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content {
    position: relative;
    left: 0;
    border-radius: 0;
    min-width: 197px; }
    .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content .control-corner-radius-input {
      width: 154px; }
      .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content .control-corner-radius-input .left .corner-border {
        margin-left: 32px;
        width: 30px; }
      .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content .control-corner-radius-input .right .corner-border {
        margin-right: 32px;
        width: 30px; }
    .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content .shadow-control .control-angle-input {
      padding: 0; }
    .advanced-style-panel-narrow .advanced-style-panel-body .content-container .content.as-labels {
      min-width: 100%; }

.multi-component-advanced-style-panel .section-wrapper .section-header {
  padding-top: 26px; }

.multi-component-advanced-style-panel .button-wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, #ffffff 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; }

.multi-component-advanced-style-panel .presets-wrapper {
  display: flex;
  padding-left: 24px;
  justify-content: normal;
  flex-flow: row wrap;
  margin-top: 13px;
  padding-bottom: 84px; }

.multi-component-advanced-style-panel .preset-image {
  position: relative;
  display: grid;
  margin: 0 12px 20px 0;
  background-color: #f7f8f8;
  border-radius: 4px; }
  .multi-component-advanced-style-panel .preset-image:hover {
    cursor: pointer; }
  .multi-component-advanced-style-panel .preset-image .design-panel-selected-preset {
    position: absolute;
    top: -12px;
    right: -12px; }

.multi-component-advanced-style-panel .tabs-wrapper {
  height: 453px; }
  .multi-component-advanced-style-panel .tabs-wrapper > div:first-child {
    border-top: 1px solid #d9e1e8; }

.tpa-gallery-advanced-style-panel .control-section-divider {
  width: 100% !important;
  left: 0 !important; }
  .tpa-gallery-advanced-style-panel .control-section-divider.labeled {
    padding: 0 24px; }

.tpa-gallery-advanced-style-panel .boolean-control span.label {
  padding-top: 15px;
  padding-bottom: 15px; }

.tpa-gallery-advanced-style-panel .boolean-control input ~ svg,
.tpa-gallery-advanced-style-panel .boolean-control input ~ .label {
  padding-top: 20px; }

.tpa-gallery-advanced-style-panel .advanced-style-panel .control-switch .symbol-switch {
  padding-top: 20px; }

.design-data-panel-frame .comp-panel-content {
  min-height: 400px; }

.design-data-panel {
  text-align: left; }
  .design-data-panel.flex-column {
    flex-direction: column;
    display: flex; }
    .design-data-panel.flex-column .flex-column {
      flex-direction: column;
      display: flex; }
    .design-data-panel.flex-column .flex-child {
      flex: 1; }
  .design-data-panel.only-advanced-styling {
    height: auto; }
  .design-data-panel .input-slider {
    margin: 10px 24px 7px; }
  .design-data-panel .control-section-divider.labeled {
    width: 90%;
    left: 5%;
    padding: 0 18px; }
  .design-data-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .design-data-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .design-data-panel .dropdown {
    padding: 10px 12px;
    margin: 0 11px; }
  .design-data-panel .writable-drop-down {
    padding: 10px 12px;
    margin-right: 11px; }
  .design-data-panel .selective-text-input .control-checkbox {
    margin: -6px 0; }
  .design-data-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .design-data-panel :not(.selective-text-input) > .control-checkbox {
    display: block;
    width: calc(100% - 60px);
    margin: 21px 30px;
    box-sizing: border-box; }
  .design-data-panel .control-alignment {
    padding: 19px 0 24px 24px; }
    .design-data-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .design-data-panel .tab-text, .design-data-panel .advanced-style-panel .tab-text-composite, .advanced-style-panel .design-data-panel .tab-text-composite {
    font-size: medium;
    position: absolute;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #3b4057; }
  .design-data-panel .control-angle {
    margin: 17px 24px 24px; }
  .design-data-panel .color-picker-input {
    padding: 16px 24px 19px;
    box-sizing: border-box; }
    .design-data-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
      right: 24px;
      top: 16px; }
  .design-data-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .design-data-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
      font-size: 18px;
      height: 36px; }
    .design-data-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 30px 8px 0; }
      .design-data-panel .color-picker-input-with-opacity .input-slider .slider {
        margin: 0 75px 0 0; }
    .design-data-panel .color-picker-input-with-opacity .color-picker-input {
      padding: 0; }
    .design-data-panel .color-picker-input-with-opacity .input-stepper.small {
      margin: -2px 6px 0 3px;
      padding: 0 6px 0 4px; }
  .design-data-panel .backBtn {
    margin-top: 7px;
    width: 66px;
    height: 21px;
    background-color: #dfedfd;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #5c99e9;
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    text-align: left; }
    .design-data-panel .backBtn:hover {
      background-color: #d6e6fe; }
  .design-data-panel .nothing-to-edit {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: #868aa5; }
    .design-data-panel .nothing-to-edit svg {
      margin-top: 45px;
      margin-bottom: 24px; }
  .design-data-panel .design-data-panel-body {
    position: relative; }
    .design-data-panel .design-data-panel-body .content-container {
      width: 100%;
      position: absolute;
      height: 100%; }
      .design-data-panel .design-data-panel-body .content-container .control-tabs-vertical {
        border-radius: 0 0 0 4px; }
      .design-data-panel .design-data-panel-body .content-container .content {
        position: absolute;
        display: inline-block;
        left: 48px;
        top: 0;
        height: 100%;
        width: calc(100% - 48px);
        border-radius: 0 0 4px 0;
        background: #fff; }
        .design-data-panel .design-data-panel-body .content-container .content .tab-text, .design-data-panel .design-data-panel-body .content-container .content .advanced-style-panel .tab-text-composite, .advanced-style-panel .design-data-panel .design-data-panel-body .content-container .content .tab-text-composite {
          left: 0;
          position: relative; }
        .design-data-panel .design-data-panel-body .content-container .content .section .control-section-divider + div hr:first-child {
          display: none; }
        .design-data-panel .design-data-panel-body .content-container .content .section .control-angle-input {
          padding: 0; }
    .design-data-panel .design-data-panel-body .scrolled-content {
      overflow-x: hidden; }
    .design-data-panel .design-data-panel-body .no-options {
      margin-left: 24px;
      margin-right: 24px; }
  .design-data-panel .fixed-button-wrapper {
    padding-bottom: 12px;
    width: 100%;
    text-align: center; }

.design-data-panel-narrow .design-data-panel-body .content-container {
  position: relative;
  display: flex; }
  .design-data-panel-narrow .design-data-panel-body .content-container > div:first-child {
    height: auto; }
  .design-data-panel-narrow .design-data-panel-body .content-container .content {
    position: relative;
    left: 0;
    border-radius: 0;
    min-width: 197px; }
    .design-data-panel-narrow .design-data-panel-body .content-container .content .link-style-action {
      flex-direction: row-reverse;
      position: relative;
      left: 15px; }
      .design-data-panel-narrow .design-data-panel-body .content-container .content .link-style-action .content-container {
        position: relative; }
        .design-data-panel-narrow .design-data-panel-body .content-container .content .link-style-action .content-container .content {
          position: relative;
          left: 0;
          width: 187px;
          margin-left: 10px; }
    .design-data-panel-narrow .design-data-panel-body .content-container .content .control-corner-radius-input {
      width: 154px; }
      .design-data-panel-narrow .design-data-panel-body .content-container .content .control-corner-radius-input .left .corner-border {
        margin-left: 32px;
        width: 30px; }
      .design-data-panel-narrow .design-data-panel-body .content-container .content .control-corner-radius-input .right .corner-border {
        margin-right: 32px;
        width: 30px; }

.media-preview-panel {
  width: 270px;
  height: 176px;
  overflow: hidden;
  margin: 12px 12px 4px 12px; }
  .media-preview-panel .hide * {
    visibility: hidden; }
  .media-preview-panel .show * {
    visibility: visible; }
  .media-preview-panel .media-preview-wrapper {
    position: relative;
    background-color: #000;
    height: 152px;
    width: 270px;
    overflow: hidden;
    border: 1px solid #e7f0ff; }
    .media-preview-panel .media-preview-wrapper .media-preview-color {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-video-wrapper {
      position: absolute;
      top: 0;
      left: 0; }
      .media-preview-panel .media-preview-wrapper .media-preview-video-wrapper .media-preview-video {
        width: 100%;
        height: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .media-preview-panel .media-preview-caption {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    padding: 3px 0 2px;
    line-height: 20px;
    color: #000624; }
    .media-preview-panel .media-preview-caption .media-title {
      width: 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left; }
    .media-preview-panel .media-preview-caption .video-duration {
      text-align: right;
      width: 12%;
      float: right;
      font-size: 0.9em; }

.bg-preset-list-section .preset-list-section-title {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  border-bottom: 1px solid #3b4057;
  font-weight: normal;
  margin-bottom: 16px;
  line-height: 1em;
  padding-bottom: 4px;
  position: relative; }

.bg-preset-list-section .section-header {
  margin-bottom: 22px; }

.bg-preset-list-section .preset-list-section-list {
  text-align: center; }

.bg-preset-list-section .preset-list-item {
  font-size: 0;
  margin-bottom: 10px;
  text-align: center;
  width: 100%; }

.bg-preset-list-section .columns-2 .preset-list-item {
  text-align: left;
  display: inline-block;
  width: 50%; }
  .bg-preset-list-section .columns-2 .preset-list-item:nth-child(2n) {
    text-align: right; }

.bg-preset-list-section .columns-3 .preset-list-item {
  text-align: left;
  display: inline-block;
  width: 33%; }
  .bg-preset-list-section .columns-3 .preset-list-item:nth-child(2), .bg-preset-list-section .columns-3 .preset-list-item:nth-child(3n + 2) {
    text-align: center; }
  .bg-preset-list-section .columns-3 .preset-list-item:nth-child(3n) {
    text-align: right; }

.bg-preset-list-section .preset-list-preset {
  font-size: 12px;
  border: 1px solid #e7f0ff;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  display: inline-block; }
  .bg-preset-list-section .preset-list-preset.selected {
    box-shadow: 0 0 0 2px #116dff; }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-content {
    position: absolute; }
  .bg-preset-list-section .preset-list-preset .preset-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-video-controls {
    height: 100%;
    background: linear-gradient(to top, #555555 0, rgba(85, 85, 85, 0) 14px, rgba(0, 0, 0, 0) 15px); }
    .bg-preset-list-section .preset-list-preset .preset-list-preset-video-controls .symbol-bgVideoIndicator {
      width: 14px;
      height: 9px;
      left: 3px;
      bottom: 3px;
      position: absolute; }

.media-manager-panel-add-media-section__sources {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px; }

.media-manager-panel-add-media-section__source-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 999px;
  outline: none;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgba(0, 6, 36, 0.07);
  transition-property: box-shadow, background-color;
  transition-duration: 300ms; }
  .media-manager-panel-add-media-section__source-button:hover {
    box-shadow: 0 3px 12px 0 rgba(0, 6, 36, 0.2); }

.media-manager-panel-preset {
  cursor: pointer;
  position: relative; }
  .media-manager-panel-preset:hover .media-manager-panel-preset__purchase-overlay {
    opacity: 1; }
  .media-manager-panel-preset__purchase-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    z-index: 1;
    background-color: rgba(0, 6, 36, 0.3);
    color: #fff;
    opacity: 0;
    transition: opacity 300ms; }
  .media-manager-panel-preset__transcoding-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.4); }
  .media-manager-panel-preset__transcoding-failed-icon svg {
    width: 30px;
    height: 30px; }
  .media-manager-panel-preset__thumbnail {
    width: 90px;
    height: 90px; }
    .media-manager-panel-preset__thumbnail.with-error {
      opacity: 0; }
  .media-manager-panel-preset__title {
    height: 14px;
    margin-top: 9px;
    text-align: center; }

.media-manager-panel-section {
  padding-top: 18px; }
  .media-manager-panel-section__header-wrapper {
    padding: 0 24px;
    margin-bottom: 18px; }
  .media-manager-panel-section__header {
    display: flex;
    justify-content: space-between;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding-bottom: 2px;
    color: #3b4057;
    border-bottom: 1px solid #3b4057;
    line-height: 1.29; }
  .media-manager-panel-section__content-with-paddings {
    padding: 0 24px; }
  .media-manager-panel-section__header-actions {
    opacity: 0;
    transition: opacity ease 200ms; }
  .media-manager-panel-section:hover .media-manager-panel-section__header-actions {
    opacity: 1; }

.new-workspace-media-manager-panel-section-header {
  margin: 0 24px 18px 24px; }
  .new-workspace-media-manager-panel-section-header.section-header-without-margins {
    margin-left: 0;
    margin-right: 0; }

.media-manager-panel-preset-section__presets {
  display: flex;
  flex-wrap: wrap;
  margin: 18px 0 12px; }

.media-manager-panel-preset-section__preset {
  width: 90px;
  margin-top: 18px;
  margin-right: 18px; }
  .media-manager-panel-preset-section__preset:nth-child(-n + 3) {
    margin-top: 0; }
  .media-manager-panel-preset-section__preset:nth-child(3n + 3) {
    margin-right: 0; }

.media-manager-panel-preset-section__preset-mock {
  background-color: #f4fafe;
  height: 90px; }

.media-manager-panel-preset-section__action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px; }
  .media-manager-panel-preset-section__action-wrapper .btn-sm {
    font-size: 12px;
    height: 21px;
    padding: 0 12px; }

.media-manager-panel-preset-section__action-content-wrapper {
  display: flex;
  align-items: center; }

.media-manager-panel-preset-section__action-text {
  display: inline-block;
  margin-right: 6px; }

.media-manager-panel-preset-thumbnail {
  width: 90px;
  height: 90px;
  position: relative;
  transition: background-color 300ms; }
  .media-manager-panel-preset-thumbnail.with-blue-background {
    background-color: #f4fafe; }
  .media-manager-panel-preset-thumbnail .picture,
  .media-manager-panel-preset-thumbnail .shape,
  .media-manager-panel-preset-thumbnail .video {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: #f4faff;
    background-position: center;
    background-repeat: no-repeat; }
  .media-manager-panel-preset-thumbnail .shape,
  .media-manager-panel-preset-thumbnail .picture {
    background-color: transparent; }
  .media-manager-panel-preset-thumbnail .document,
  .media-manager-panel-preset-thumbnail .music,
  .media-manager-panel-preset-thumbnail .swf,
  .media-manager-panel-preset-thumbnail .archive {
    text-align: center;
    background-color: #fff;
    transition: background-color ease 300ms; }
    .media-manager-panel-preset-thumbnail .document:hover,
    .media-manager-panel-preset-thumbnail .music:hover,
    .media-manager-panel-preset-thumbnail .swf:hover,
    .media-manager-panel-preset-thumbnail .archive:hover {
      background-color: #f4faff; }
    .media-manager-panel-preset-thumbnail .document g,
    .media-manager-panel-preset-thumbnail .music g,
    .media-manager-panel-preset-thumbnail .swf g,
    .media-manager-panel-preset-thumbnail .archive g {
      fill: #fff; }
  .media-manager-panel-preset-thumbnail__picture-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .media-manager-panel-preset-thumbnail__music-control-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .media-manager-panel-preset-thumbnail__music-control-wrapper svg {
      fill: #116dff; }
  .media-manager-panel-preset-thumbnail__music-symbol {
    margin-top: -10px;
    margin-right: 5px;
    transform: rotateZ(-18deg); }
  .media-manager-panel-preset-thumbnail__video-symbol {
    position: absolute;
    left: 4px;
    bottom: 4px;
    z-index: 2; }
  .media-manager-panel-preset-thumbnail__video-bottom-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(0, 0, 0, 0.23));
    z-index: 1; }
  .media-manager-panel-preset-thumbnail__extension-label {
    position: absolute;
    pointer-events: none;
    right: 6px;
    bottom: 8px; }

.media-manager-panel-preset-extension-label {
  display: inline-block;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.09;
  letter-spacing: 0.6px;
  border: 1px solid #116dff;
  background-color: #fff;
  padding: 3px 7px;
  color: #116dff; }

.media-manager-media-preview {
  padding: 12px 12px 6px;
  border-radius: 6px;
  background-color: #fff; }
  .media-manager-media-preview__content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    max-width: 228px;
    min-height: 120px;
    max-height: 342px; }
    .media-manager-media-preview__content.with-blue-background {
      background-color: #f4fafe; }
  .media-manager-media-preview__video, .media-manager-media-preview__picture {
    height: 100%; }
  .media-manager-media-preview__video {
    width: 100%;
    background-color: #f4faff; }
  .media-manager-media-preview__picture {
    background-size: cover;
    background-position: center; }
  .media-manager-media-preview__shape {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .media-manager-media-preview__footer {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .media-manager-media-preview__title-text {
    overflow-x: hidden;
    max-height: 2.6em; }
  .media-manager-media-preview__duration {
    margin-left: 12px; }
  .media-manager-media-preview__price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #868aa5;
    height: 15px;
    max-width: 228px;
    overflow-x: hidden;
    margin-top: -5px;
    margin-bottom: 5px; }

.media-manager-panel-search__input-wrapper {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 999px;
  border: 1px solid #a8caff;
  padding: 0 6px 0 12px;
  box-sizing: border-box; }
  .media-manager-panel-search__input-wrapper.focused {
    border-color: #80b1ff;
    box-shadow: inset 0 0 4px 0 rgba(42, 90, 130, 0.55); }
  .media-manager-panel-search__input-wrapper:hover {
    background-color: #e7f0ff; }
    .media-manager-panel-search__input-wrapper:hover .media-manager-panel-search__input {
      background-color: #e7f0ff; }

.media-manager-panel-search__input {
  width: 100%;
  max-width: 240px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 1.29;
  color: #000624;
  font-family: var(--wbu-font-stack); }
  .media-manager-panel-search__input::placeholder {
    color: #b6c1cd; }
  .media-manager-panel-search__input::-ms-clear {
    display: none; }

.media-manager-panel-search__search-icon {
  display: flex;
  margin-right: 6px; }

.media-manager-panel-search__button-wrapper {
  display: none;
  width: 18px;
  height: 18px;
  flex-shrink: 0; }
  .media-manager-panel-search__button-wrapper.is-visible {
    display: block; }

.media-manager-panel-search__button {
  width: 18px;
  height: 18px;
  padding: 0;
  transition: background-color ease 300ms; }

.media-manager-panel-card {
  border: 1px solid #dfe5eb;
  border-radius: 4px;
  padding: 12px 14px 18px; }
  .media-manager-panel-card__header {
    display: flex;
    justify-content: flex-end; }
  .media-manager-panel-card__header-actions-button {
    background: none;
    border: none;
    padding: 0; }
  .media-manager-panel-card__title {
    max-width: 270px; }
    .media-manager-panel-card__title .control-label-base {
      color: #000624; }
  .media-manager-panel-card__subtitle {
    margin-top: 6px;
    max-width: 270px; }
  .media-manager-panel-card__body {
    margin-top: 24px; }

.media-manager-panel-tag {
  display: inline-block;
  padding: 3px 11px;
  border-radius: 999px;
  border: 1px solid #c1e4fe;
  transition: background-color ease 300ms; }
  .media-manager-panel-tag .control-label-base {
    line-height: 1.25;
    transition: color ease 300ms; }
  .media-manager-panel-tag:hover, .media-manager-panel-tag.selected:hover {
    background-color: #e7f0ff; }
    .media-manager-panel-tag:hover .control-label-base, .media-manager-panel-tag.selected:hover .control-label-base {
      color: #116dff; }
  .media-manager-panel-tag.selected {
    background-color: #daeffe; }
    .media-manager-panel-tag.selected .control-label-base {
      color: #116dff; }
  .media-manager-panel-tag__text {
    display: inline-block;
    max-width: 204px; }
  .media-manager-panel-tag__icon {
    display: inline-block;
    margin-left: 6px;
    width: 6px; }

.recommended-media-card__tag {
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 12px; }

.recommended-media-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px; }
  .recommended-media-card__footer .btn-text {
    font-size: 12px;
    height: auto; }

.media-manager-panel-settings-button {
  padding: 0;
  background: none;
  border: none;
  outline: none; }
  .media-manager-panel-settings-button:hover path {
    fill: #116dff; }

.media-manager-panel-three-dots-button {
  padding: 0;
  background: none;
  border: none;
  outline: none; }

.wix-code-tree-view > .tree-entry {
  padding: 3px 3px 3px 12px;
  height: 24px; }

.wix-code-tree-view svg {
  max-width: 20px; }

.wix-code-tree-view .rename-input {
  height: 24px;
  font-size: 14px;
  margin-left: 3px; }

.wix-code-tree-view .sub-tree-entry {
  margin: 3px 0; }

.wix-code-tree-view .tree-entry {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0);
  box-sizing: border-box; }
  .wix-code-tree-view .tree-entry.clickable {
    cursor: pointer; }
  .wix-code-tree-view .tree-entry.selected {
    background-color: #f7f8f8;
    border-color: #f7f8f8; }
  .wix-code-tree-view .tree-entry.show-highlight:hover {
    background-color: #e7f0ff;
    border-color: #e7f0ff; }
  .wix-code-tree-view .tree-entry .expander-symbol {
    width: 16px;
    height: 16px;
    text-align: center;
    margin-left: -16px; }
  .wix-code-tree-view .tree-entry .title-tooltip {
    flex-grow: 1;
    margin-left: 2px;
    overflow: hidden; }
    .wix-code-tree-view .tree-entry .title-tooltip .title {
      white-space: nowrap;
      color: #000000; }
      .wix-code-tree-view .tree-entry .title-tooltip .title.clickable {
        cursor: pointer; }
  .wix-code-tree-view .tree-entry .title-input {
    flex-grow: 1;
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    height: 24px; }
  .wix-code-tree-view .tree-entry .tree-symbol {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative; }
    .wix-code-tree-view .tree-entry .tree-symbol .tree-symbol-inner {
      display: flex;
      flex-direction: column; }
  .wix-code-tree-view .tree-entry .right-actions-container {
    height: 20px;
    display: flex;
    align-items: center; }
    .wix-code-tree-view .tree-entry .right-actions-container .confirm-symbol {
      animation: fadeInAndOut 1.7s ease-in-out; }
    .wix-code-tree-view .tree-entry .right-actions-container .stop-propagation-bg {
      display: flex; }
    .wix-code-tree-view .tree-entry .right-actions-container .single-action-button-tooltip {
      visibility: hidden;
      height: 20px; }
      .wix-code-tree-view .tree-entry .right-actions-container .single-action-button-tooltip.always-show {
        visibility: visible; }
    .wix-code-tree-view .tree-entry .right-actions-container .single-action-button {
      background-color: transparent;
      width: 20px;
      height: 20px;
      padding: 0; }
      .wix-code-tree-view .tree-entry .right-actions-container .single-action-button svg * {
        fill: #116dff; }
    .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer {
      height: 20px;
      display: flex; }
      .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol .circle {
        fill: #a8caff; }
      .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol .dot {
        fill: #5999ff; }
      .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol:hover .circle {
        fill: #80b1ff; }
      .is-open .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol .circle {
        fill: #116dff; }
      .is-open .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol .circle-content {
        fill: #116dff; }
      .is-open .wix-code-tree-view .tree-entry .right-actions-container .contextMenuContainer svg.more-symbol .dot {
        fill: #fff; }
  .wix-code-tree-view .tree-entry .is-loading-preloader {
    margin-right: 13px; }
    .wix-code-tree-view .tree-entry .is-loading-preloader .preloader-inner {
      width: 10px;
      height: 20px; }
  .wix-code-tree-view .tree-entry .context-menu .dropdown-selected {
    visibility: hidden; }
  .wix-code-tree-view:hover .tree-entry .visible-on-tree-hover.context-menu .dropdown-selected {
    visibility: visible; }
  .wix-code-tree-view .tree-entry .context-menu .dropdown-selected.is-open,
  .wix-code-tree-view .tree-entry:hover .context-menu .dropdown-selected,
  .wix-code-tree-view .tree-entry .context-menu.always-visible .dropdown-selected {
    visibility: visible; }
  .wix-code-tree-view .tree-entry:hover .right-actions-container .single-action-button-tooltip {
    visibility: visible; }
  .wix-code-tree-view .tree-entry .hover-content-container {
    display: none; }
  .wix-code-tree-view .tree-entry:hover .hover-content-container {
    display: inline; }
  .wix-code-tree-view .tree-entry .notification-pimple {
    width: 7px;
    height: 7px;
    background-color: #ee5951;
    border-radius: 50%;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .wix-code-tree-view .tree-entry .notification-pimple.has-count {
      width: 14px;
      height: 14px;
      margin-right: 4px; }
    .wix-code-tree-view .tree-entry .notification-pimple span {
      color: #ffffff;
      font-size: 10px; }

.wix-code-tree-view .sub-tree {
  margin-left: 10px; }
  .wix-code-tree-view .sub-tree .tree-entry {
    position: relative; }
    .wix-code-tree-view .sub-tree .tree-entry .tree-outlines:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 100%;
      bottom: 50%;
      left: -2px;
      border: 1px solid #d8d8d8;
      border-top: 0;
      border-right: 0; }
  .wix-code-tree-view .sub-tree .sub-tree-entry:first-child .tree-entry {
    position: relative; }
    .wix-code-tree-view .sub-tree .sub-tree-entry:first-child .tree-entry .tree-outlines:before {
      height: 45%; }
  .wix-code-tree-view .sub-tree .sub-tree-entry:not(:last-child) .tree-entry .tree-outlines:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 100%;
    left: -2px;
    border-left: 1px solid #d8d8d8; }
  .wix-code-tree-view .sub-tree .sub-tree-entry:not(:last-child) .sub-tree.subTreeWithOutlines {
    border-left: 1px solid #d8d8d8;
    position: relative;
    left: -1px; }
  .wix-code-tree-view .sub-tree.expanderWithIcon {
    margin-left: 38px; }

@keyframes fadeInAndOut {
  0% {
    opacity: 0; }
  20%,
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.custom-action-container {
  width: 100%;
  height: 100%;
  display: flex; }

.ui-version-label {
  -webkit-user-select: text;
          user-select: text; }

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.collection-item {
  display: flex;
  align-items: center;
  padding: 14px 0 14px 0;
  border-bottom: 1px solid #d6e1e8;
  cursor: pointer; }
  .collection-item .collection-item-icon {
    display: flex;
    margin: 0 15px 0 23px; }
  .collection-item .collection-info {
    flex-grow: 1;
    min-width: 0; }
  .collection-item .collection-properties {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-right: 6px; }
  .collection-item .collection-properties > span:first-child {
    min-width: 0; }
  .collection-item .details-label {
    display: flex;
    width: 100%; }
  .collection-item .details-label,
  .collection-item .collection-name {
    cursor: pointer; }
  .collection-item .context-menu {
    margin: 0 24px 0 0;
    align-self: center; }
  .collection-item .delimiter {
    margin: 0 3px; }

.wix-data-context-menu-item {
  color: #3b4057;
  display: flex;
  align-items: center; }
  .wix-data-context-menu-item > .icon {
    width: 20px;
    margin-right: 11px;
    display: flex;
    justify-content: center; }

.loadCollectionsPlaceholder {
  height: 100%;
  text-align: left; }
  .loadCollectionsPlaceholder .collectionsListWrapper,
  .loadCollectionsPlaceholder .applicationStatesWrapper {
    height: 100%; }

.loadCollectionsPlaceholderChildrenContainer {
  height: 100%; }

.dynamic-pages-starting-panel .composite-popup-large-size .composite-action-set-vertical a {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #116dff;
  margin-top: 10px;
  display: inline; }
  .dynamic-pages-starting-panel .composite-popup-large-size .composite-action-set-vertical a:hover {
    text-decoration: underline; }

.dynamic-pages-starting-panel .composite-panel-content .composite-promotional-list:last-child {
  padding: 20px 48px 20px 48px; }

.limits-notification-banner-container {
  padding: 9px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  text-align: left; }
  .limits-notification-banner-container.skin-warning {
    background-color: #fff3d6; }
  .limits-notification-banner-container.skin-alert {
    background-color: #fde3e1; }
  .limits-notification-banner-container.skin-standard {
    background-color: #e7f0ff; }
  .limits-notification-banner-container .control-text-button {
    vertical-align: top; }

.shared-collection-badge {
  cursor: pointer; }
  .shared-collection-badge > span {
    cursor: pointer; }

.form-collections-migration-tooltip {
  display: flex;
  width: 207px; }
  .form-collections-migration-tooltip .close-button {
    position: relative;
    top: -8px; }

.forms-migration-helper {
  padding: 9px 12px;
  background-color: #e7f0ff; }
  .forms-migration-helper .control-text-button {
    vertical-align: top; }

.image-crop-layer-mask.parentSizeAbs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.image-crop-layer-mask .clip,
.image-crop-layer-mask .clip-marker,
.image-crop-layer-mask .image-crop-container {
  position: absolute;
  color: transparent; }
  .image-crop-layer-mask .clip img:-moz-broken,
  .image-crop-layer-mask .clip img:-moz-user-disabled,
  .image-crop-layer-mask .clip-marker img:-moz-broken,
  .image-crop-layer-mask .clip-marker img:-moz-user-disabled,
  .image-crop-layer-mask .image-crop-container img:-moz-broken,
  .image-crop-layer-mask .image-crop-container img:-moz-user-disabled {
    display: none !important; }

.image-crop-layer-mask .crop-mode-divider {
  position: relative; }

.image-crop-layer-mask .crop-mode-divider::after {
  content: '';
  position: absolute;
  height: 54px;
  top: 0;
  right: 0;
  border-left: 1px solid #f7f8f8; }

.image-crop-layer-mask .inner {
  width: 100%;
  height: 100%; }

.image-crop-layer-mask .outer {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: move; }
  .image-crop-layer-mask .outer img {
    opacity: 0.35; }

.image-crop-layer-mask .crop-mode-tool-bar {
  white-space: nowrap;
  background-color: #fff;
  box-shadow: 0 0 14px 0 rgba(22, 51, 70, 0.36);
  height: 54px;
  border-radius: 50px; }
  .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options {
    list-style: none;
    height: 100%; }
    .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control {
      display: inline-block;
      vertical-align: middle;
      padding: 0 18px 0; }
      .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control .crop-mode-slider-content {
        display: flex; }
      .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider {
        position: relative;
        width: 154px; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider::after {
          top: -20px; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider .control-slider-input {
          flex: 1; }
      .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control .crop-mode-slider-label {
        flex: 0 0 8px;
        height: 14px; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control .crop-mode-slider-label.in {
          margin-left: 6px; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control .crop-mode-slider-label.out {
          margin-right: 6px; }
      .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons {
        padding: 8px 12px;
        height: auto; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-button {
          width: 30px;
          height: 30px;
          margin: 4px 0;
          padding: 0;
          background-color: #fff;
          cursor: pointer;
          vertical-align: middle;
          border-radius: 6px; }
          .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-button:hover {
            background-color: #e7f0ff; }
          .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-button.selected {
            background-color: #116dff; }
          .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-button.crop-mode-flip-horizontal.selected, .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-button.crop-mode-flip-vertical.selected {
            background-color: #fff; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-reset,
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-flip-horizontal,
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-flip-vertical,
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-save {
          margin-left: 6px; }
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-cancel,
        .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-save {
          border-radius: 50%;
          background-color: #e7f0ff; }
          .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-cancel svg path,
          .image-crop-layer-mask .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-buttons .crop-mode-save svg path {
            fill: #116dff; }

.classic-facelift-skin .crop-mode-tool-bar {
  display: flex;
  align-items: center;
  height: 42px;
  width: 558px;
  border-radius: 4px; }
  .classic-facelift-skin .crop-mode-tool-bar .crop-mode-options {
    height: auto;
    width: 100%; }

.classic-facelift-skin .image-crop-layer-mask .crop-mode-divider::after {
  display: none; }

.classic-facelift-skin .crop-mode-tool-bar-divider::after {
  content: '';
  position: absolute;
  top: 0;
  height: 42px;
  width: 1px;
  background: #dfe5eb; }

.classic-facelift-skin .control-button.btn-sm {
  padding: 0 13px; }

.classic-facelift-skin .crop-mode-cancel-nw {
  margin-right: 6px; }

.classic-facelift-skin .drag-handler {
  cursor: move; }

.clip-marker {
  border: 1px solid #116dff;
  z-index: 1;
  box-sizing: border-box;
  cursor: move; }
  .clip-marker .clip-handle {
    height: 12px;
    width: 12px;
    background: transparent;
    position: absolute;
    z-index: 2; }
    .clip-marker .clip-handle.handle-top-left {
      top: -1px;
      left: 0;
      transform: rotate(90deg); }
    .clip-marker .clip-handle.handle-top-right {
      top: 0;
      right: -1px;
      transform: rotate(180deg); }
    .clip-marker .clip-handle.handle-bottom-left {
      bottom: 0;
      left: -1px; }
    .clip-marker .clip-handle.handle-bottom-right {
      bottom: -1px;
      right: 0;
      transform: rotate(-90deg); }
    .clip-marker .clip-handle.handle-top {
      width: 4px;
      top: -5px;
      left: calc(50% - 1px);
      transform: rotate(90deg); }
    .clip-marker .clip-handle.handle-right {
      width: 4px;
      top: calc(50% - 5px);
      right: -1px;
      transform: rotate(180deg); }
    .clip-marker .clip-handle.handle-left {
      width: 4px;
      top: calc(50% - 7px);
      left: -1px; }
    .clip-marker .clip-handle.handle-bottom {
      width: 4px;
      bottom: -5px;
      left: calc(50% - 3px);
      transform: rotate(-90deg); }
    .clip-marker .clip-handle.cursor-resize-vertical {
      cursor: ns-resize; }
    .clip-marker .clip-handle.cursor-resize-horizontal {
      cursor: ew-resize; }
    .clip-marker .clip-handle.cursor-resize-diagonal-top-left {
      cursor: nwse-resize; }
    .clip-marker .clip-handle.cursor-resize-diagonal-top-right {
      cursor: nesw-resize; }
  .clip-marker .clip-marker-indicator {
    position: absolute;
    height: 22px;
    line-height: 22px;
    top: -32px;
    left: 0;
    background-color: #343434;
    border-radius: 4px;
    opacity: 0.8;
    color: #fff;
    padding: 0 3px;
    white-space: nowrap; }

.columns-container-box {
  border: 3px solid #116dff;
  box-sizing: border-box;
  position: absolute; }
  .columns-container-box.fade {
    border-color: #2b81cb;
    opacity: 0.45; }
  .columns-container-box.components-ui-color-orange {
    border-color: #fe620f; }
    .columns-container-box.components-ui-color-orange.fade {
      border-color: #b8561d; }

@keyframes flicker {
  1% {
    background: rgba(78, 183, 245, 0); }
  50% {
    background: rgba(78, 183, 245, 0.2); }
  100% {
    background: rgba(78, 183, 245, 0); } }

.column-content-highlight {
  position: absolute;
  background: rgba(78, 183, 245, 0.2); }
  .column-content-highlight.flicker {
    background: rgba(78, 183, 245, 0);
    animation: flicker 1s forwards; }

.first-time-steps {
  width: 257px;
  min-height: 276px; }
  .first-time-steps .text-container {
    padding: 23px; }
    .first-time-steps .text-container .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      margin-bottom: 12px; }
    .first-time-steps .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057; }
  .first-time-steps .image-container {
    text-align: center;
    left: 0;
    right: 0;
    height: 115px;
    background-size: 182px 115px;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .first-time-steps .image-container .btn-close {
      padding: 0;
      border-radius: 50%;
      border: 0;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 12px;
      right: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      box-sizing: border-box; }
      .first-time-steps .image-container .btn-close:focus {
        outline: 0; }
      .first-time-steps .image-container .btn-close:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      .first-time-steps .image-container .btn-close svg {
        fill: #ffffff; }
  .first-time-steps > .panel-footer {
    padding: 0 18px 18px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .first-time-steps > .panel-footer.one-step {
      justify-content: flex-end; }
    .first-time-steps > .panel-footer > .btn-confirm {
      background-color: #116dff;
      height: 24px;
      line-height: 13px;
      min-width: 87px; }
      .first-time-steps > .panel-footer > .btn-confirm:hover {
        background-color: #5999ff; }

.first-time-steps-menu {
  display: inline-flex; }
  .first-time-steps-menu .first-time-steps-menu-item {
    display: inline-flex; }
  .first-time-steps-menu .step-button {
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    box-shadow: inset 0 0 0 1px #116dff;
    transition-property: background-color, border-width;
    transition-duration: 0.15s;
    box-sizing: border-box;
    margin-right: 6px;
    vertical-align: middle; }
    .first-time-steps-menu .step-button:hover {
      background-color: #d6e6fe; }
    .first-time-steps-menu .step-button.checked {
      background-color: #116dff;
      box-shadow: inset 0 0 0 1px #116dff, inset 0 0 0 3px #fff; }

.collapsible-header {
  padding: 0 0 0 16px;
  height: 42px;
  display: flex;
  align-items: center; }
  .collapsible-header .title {
    display: flex;
    align-items: center; }
    .collapsible-header .title svg * {
      fill: #2d3034; }
    .collapsible-header .title:hover .collapsible-title {
      color: #116dff; }
    .collapsible-header .title:hover svg * {
      fill: #116dff; }
    .collapsible-header .title .control-label-base {
      font-size: 12px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .collapsible-header .collapsible-title {
    width: 145px;
    color: #2d3034;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .collapsible-header .close-button,
  .collapsible-header .help-button {
    background-color: transparent;
    min-width: 20px;
    width: 20px;
    padding: 0; }
    .collapsible-header .close-button svg *,
    .collapsible-header .help-button svg * {
      fill: #2d3034; }
    .collapsible-header .close-button:hover svg *,
    .collapsible-header .help-button:hover svg * {
      fill: #116dff; }
  .collapsible-header .separator {
    display: inline-block;
    width: 1px;
    min-width: 1px;
    height: 14px;
    background-color: #dfe5eb; }
  .collapsible-header .title svg.close {
    transform: rotateX(180deg); }

.right-pane > .scroll-container {
  box-sizing: border-box;
  border-left: 1px solid #b6c1cd;
  display: flex;
  flex-direction: column;
  width: 234px;
  height: 100%;
  overflow-y: visible;
  background: #ffffff; }
  .right-pane > .scroll-container .sectioned-panel {
    height: 100%; }

.sections-content-wrapper {
  padding-left: 4px; }

.right-pane > .scroll-container-preview {
  box-sizing: border-box;
  border-left: 1px solid #b6c1cd;
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 100%;
  overflow-y: visible;
  background: #ffffff; }
  .right-pane > .scroll-container-preview .sectioned-panel {
    height: 100%; }

.sections-content-wrapper {
  padding-left: 4px; }

.key-value-table .table-title-container {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 13px; }
  .key-value-table .table-title-container .table-title .control-label-base {
    color: #868aa5;
    letter-spacing: 0.6px; }
  .key-value-table .table-title-container .title-action-button {
    visibility: hidden;
    background-color: transparent;
    width: 18px;
    height: 100%;
    padding: 0; }
    .key-value-table .table-title-container .title-action-button svg * {
      fill: #116dff; }

.key-value-table:hover .title-action-button {
  visibility: visible; }

.key-value-table .table-placeholder {
  padding-left: 12px; }

.key-value-table .key-value-table-button-left {
  padding: 0 0 0 5px; }
  .key-value-table .key-value-table-button-left .key-value-table-button {
    font-size: 12px; }

.key-value-entry {
  display: flex;
  padding-left: 16px;
  padding-right: 13px;
  height: 24px; }
  .key-value-entry:hover {
    background-color: #d6e6fe; }
  .key-value-entry .entry-key .control-label-base {
    font-size: 12px;
    color: #174165;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .key-value-entry .entry-field-name {
    display: flex;
    align-items: center;
    width: 50%; }
  .key-value-entry .entry-right-area {
    display: flex;
    align-items: center;
    width: 50%; }
    .key-value-entry .entry-right-area .entry-value-container {
      flex-grow: 1;
      flex-basis: auto;
      overflow: hidden; }
      .key-value-entry .entry-right-area .entry-value-container .entry-value .control-label-base {
        font-size: 12px;
        color: #174165;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .key-value-entry .entry-right-area .entry-buttons-area {
      flex-basis: auto;
      display: flex;
      align-items: center;
      visibility: hidden;
      width: 0; }
      .key-value-entry .entry-right-area .entry-buttons-area .action-button {
        background-color: transparent;
        width: 18px;
        height: 100%;
        padding: 0; }
        .key-value-entry .entry-right-area .entry-buttons-area .action-button svg * {
          fill: #3b4057; }
        .key-value-entry .entry-right-area .entry-buttons-area .action-button:hover svg * {
          fill: #116dff; }
  .key-value-entry:hover .entry-right-area .entry-buttons-area {
    visibility: visible;
    width: auto; }

.widget-public-interface .widget-public-api-table {
  margin-bottom: 12px; }

.widget-public-interface .widget-public-api-table:last-child {
  margin-bottom: 24px; }

.widget-public-interface .widget-public-interface-divider {
  width: calc(100% - 24px);
  margin-left: 12px; }
  .widget-public-interface .widget-public-interface-divider:first-child {
    margin-bottom: 12px; }

.widget-public-interface-preview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: visible; }
  .widget-public-interface-preview .empty-state-rich-text {
    padding: 0 36px 18px 36px; }
  .widget-public-interface-preview .preview-header {
    position: relative;
    padding: 0 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .widget-public-interface-preview .help-button {
    background-color: transparent;
    min-width: 20px;
    width: 20px;
    padding: 0; }
    .widget-public-interface-preview .help-button svg * {
      fill: #2d3034; }
    .widget-public-interface-preview .help-button:hover svg * {
      fill: #116dff; }
  .widget-public-interface-preview .preview-header-title {
    width: 145px;
    color: #2d3034; }
    .widget-public-interface-preview .preview-header-title .control-label-base {
      font-size: 12px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.full-stage-frame {
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 1441; }

.platform-panel-application-native .renderer-component {
  position: relative; }

.platform-modal-panel-centered {
  margin-top: 0; }

.changeVariationsPanel .section-wrapper.withDesignBtn {
  padding-bottom: 58px; }

.changeVariationsPanel .section-wrapper .section-header {
  padding-top: 26px; }

.changeVariationsPanel .section-wrapper .presets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding: 13px 12px 12px 24px;
  box-sizing: border-box;
  width: 100%;
  position: relative; }
  .changeVariationsPanel .section-wrapper .presets-wrapper .preset-item {
    max-width: 100%;
    box-sizing: border-box;
    list-style-type: none;
    position: relative;
    overflow-wrap: break-word;
    margin-right: 12px;
    margin-bottom: 16px; }
    .changeVariationsPanel .section-wrapper .presets-wrapper .preset-item .design-panel-selected-item {
      position: absolute;
      top: -12px;
      right: -12px; }

.changeVariationsPanel .customize-design-btn .customize-button-symbol {
  padding-right: 8px; }

.right-pane > .scroll-container {
  box-sizing: border-box;
  border-left: 1px solid #b6c1cd;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  background: #ffffff; }
  .right-pane > .scroll-container .platform-side-panel {
    height: 100%; }

.platform-side-content-wrapper {
  padding-left: 4px; }

.platform-error-panel .label {
  color: #000624; }
  .platform-error-panel .label:not(:first-child) {
    margin-top: 6px; }

.error-panel-illustration {
  width: 120px;
  height: 120px; }

.platform-confirmation-panel .label {
  color: #000624; }

.confirmation-panel-illustration {
  width: 120px;
  height: 120px; }

.control-promotional-modal .promotional-panel-illustration {
  width: 120px;
  height: 120px; }

.manage-content-panel.manage-content-panel-full .content-section {
  padding-bottom: 108px; }

.manage-content-panel.manage-content-panel-compact .content-section {
  padding-bottom: 84px; }

.platform-uninstall-confirmation-panel-new-workspace .textLabel:not(:first-child) {
  margin-top: 6px; }

.platform-uninstall-confirmation-panel-new-workspace .composite-checkboxes {
  width: 366px;
  padding: 22px 0 !important; }

.platform-uninstall-confirmation-panel-new-workspace .control-checkbox .label.label-checkbox {
  font-size: 16px;
  color: #3b4057; }

.platform-uninstall-confirmation-panel-new-workspace .appName {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.platform-uninstall-confirmation-panel-new-workspace .premiumLabel {
  color: #000624;
  position: center; }

.list-parser ol > li {
  list-style: decimal;
  list-style-position: inside; }

.list-parser ul > li {
  list-style: disc;
  list-style-position: inside; }

.list-parser ul,
.list-parser ol {
  padding-left: 16px; }

.show-tools {
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) transform, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) opacity; }

.hide-tools {
  transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) transform; }
  .hide-tools.slide-left {
    transform: translateX(-100vw); }
  .hide-tools.slide-right {
    transform: translateX(100vw); }

.half-opacity {
  opacity: 0.5; }

.notification-frame {
  display: flex;
  width: 336px;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.42);
  border-radius: 4px;
  flex-flow: row;
  justify-content: flex-start; }
  .notification-frame .close-notification {
    order: 999;
    margin: 12px 12px 0 auto;
    cursor: pointer; }
  .notification-frame .notification-gutter {
    width: 6px;
    border-radius: 4px 0 0 4px;
    flex-shrink: 0; }
    .notification-frame .notification-gutter.gutter-warning {
      background-color: #ffce5c; }
    .notification-frame .notification-gutter.gutter-info {
      background-color: #5999ff; }
    .notification-frame .notification-gutter.gutter-success {
      background-color: #51b77b; }
    .notification-frame .notification-gutter.gutter-error {
      background-color: #ff6d63; }

.notification-frame-editor {
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.42);
  border-radius: 4px;
  min-height: 48px; }
  .notification-frame-editor .notification-frame-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: flex-start; }
    .notification-frame-editor .notification-frame-wrapper .close-notification {
      order: 999;
      margin-top: 15px;
      margin-right: 18px;
      cursor: pointer; }
    .notification-frame-editor .notification-frame-wrapper .notification-gutter {
      min-height: 48px;
      width: 6px;
      border-radius: 4px 0 0 4px;
      flex-shrink: 0; }
      .notification-frame-editor .notification-frame-wrapper .notification-gutter.gutter-warning {
        background-color: #ffce5c; }
      .notification-frame-editor .notification-frame-wrapper .notification-gutter.gutter-info {
        background-color: #5999ff; }
      .notification-frame-editor .notification-frame-wrapper .notification-gutter.gutter-success {
        background-color: #51b77b; }
      .notification-frame-editor .notification-frame-wrapper .notification-gutter.gutter-error {
        background-color: #ff6d63; }

.oneLineNotification {
  max-width: 600px; }

.twoLineNotification {
  max-width: 400px; }

.notification-center-editor {
  position: fixed;
  bottom: 0;
  left: 18px;
  display: flex;
  flex-direction: column;
  justify-items: end;
  z-index: 1459;
  -webkit-user-select: none;
  user-select: none; }
  .notification-center-editor > div > * {
    margin-bottom: 18px; }

.editor-layout-wrapper .notification-center-editor {
  position: absolute; }
  .classic-facelift-skin .editor-layout-wrapper .notification-center-editor {
    left: calc(18px + 60px); }

.error-notification-content {
  padding: 15px;
  max-width: 540px; }
  .error-notification-content .error-title {
    margin-bottom: 4px; }
  .error-notification-content .error-notification-copy-paste {
    display: flex;
    margin-top: 3px; }
    .error-notification-content .error-notification-copy-paste .error-notification-copy-paste-text {
      height: 30px;
      margin: auto 0;
      cursor: pointer;
      color: #116dff;
      align-items: center;
      display: inline-flex; }
      .error-notification-content .error-notification-copy-paste .error-notification-copy-paste-text:hover {
        text-decoration: underline; }
  .error-notification-content .action-success-symbol {
    width: 30px;
    height: 30px;
    position: relative; }

.editor-notification-content {
  margin: 15px 18px 3px 18px; }
  .editor-notification-content .notification-message,
  .editor-notification-content .notification-link,
  .editor-notification-content .notification-icon {
    margin-bottom: 6px;
    display: inline-block; }
  .editor-notification-content .notification-message {
    margin-right: 4px; }
  .editor-notification-content .notification-icon {
    margin-right: 5px; }
    .editor-notification-content .notification-icon,
    .editor-notification-content .notification-icon ~ .notification-link {
      vertical-align: middle; }
    .editor-notification-content .notification-icon svg {
      display: block; }

.platform-notification-content {
  margin: 12px 0 14px 18px;
  display: flex;
  flex-flow: column nowrap; }
  .platform-notification-content .notification-title {
    margin-bottom: 6px; }
  .platform-notification-content .notification-message {
    margin-bottom: 6px; }

update-panel-content {
  padding: 24px; }

.welcome-screen-settings-panel {
  display: flex;
  height: 77vh; }

.welcome-screen-sidebar {
  width: 288px;
  height: 77vh; }

.welcome-screen-settings {
  width: 288px;
  max-height: calc(77vh - 75px); }

.welcome-screen-logo-picker {
  padding: 24px 24px 0; }

.welcome-screen-logo-picker-header {
  display: flex;
  justify-content: space-between; }

.welcome-screen-logo-picker-header-info {
  visibility: hidden; }

.welcome-screen-logo-picker:hover .welcome-screen-logo-picker-header-info {
  visibility: visible; }

.welcome-screen-logo-picker-header-label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.welcome-screen-logo-picker-buttons {
  display: flex;
  justify-content: center; }

.welcome-screen-logo-picker-buttons :first-child {
  margin-right: 6px; }

.welcome-screen-logo-picker-buttons :nth-child(2) {
  margin-left: 6px; }

.welcome-screen-logo-picker-button {
  margin: 18px 0 24px;
  width: 114px; }

.welcome-screen-logo-picker-button-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  height: 100%;
  text-align: center;
  line-height: 2; }

.welcome-screen-logo-picker-button-icon {
  flex-shrink: 0; }

.welcome-screen-preview-area {
  height: 77vh;
  width: 50vw;
  min-width: 320px;
  border-right: 1px solid #dfe5eb;
  display: flex;
  justify-content: center;
  align-items: center; }

.welcome-screen-mobile-frame-container {
  width: 320px;
  height: 57vh;
  max-height: 475px;
  position: relative;
  top: -12px; }
  .classic-facelift-skin .welcome-screen-mobile-frame-container {
    width: 32.091vh;
    max-width: 267.425px; }
    .classic-facelift-skin .welcome-screen-mobile-frame-container .mobile-frame {
      width: 100%;
      left: 50%;
      transform: translateX(-50%); }

.welcome-screen-preview-container {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.welcome-screen-play {
  height: 75px;
  padding: 0 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfe5eb;
  cursor: pointer; }

.welcome-screen-play-label {
  color: #3b4057;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer; }

.welcome-screen-footer {
  display: flex;
  flex-direction: row-reverse; }

.welcome-screen-footer-content {
  width: 100%; }

.welcome-screen-save-button {
  margin-left: 0 !important; }

.local-push-success-panel .composite-panel-content {
  padding: 0;
  margin-top: -10px; }

.local-push-success-panel .illustration {
  height: 138px;
  width: 394px; }

.local-push-success-panel .composite-rich-text {
  padding: 0 45px 20px;
  box-sizing: border-box; }

.local-version-outdated {
  width: 576px;
  background-color: #fff; }
  .local-version-outdated .panel-header {
    height: 54px; }
    .local-version-outdated .panel-header .panel-header-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 20px;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
      line-height: 54px; }
    .local-version-outdated .panel-header .close {
      top: 14px;
      margin-top: 0; }
  .local-version-outdated .content {
    padding: 0; }
  .local-version-outdated > footer {
    position: static;
    text-align: right;
    margin: 0 30px 30px 0; }
    .local-version-outdated > footer .btn-confirm-primary,
    .local-version-outdated > footer .btn-confirm-secondary {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased; }
  .local-version-outdated .content-wrapper {
    display: inline-block;
    font-size: 0;
    position: relative;
    margin: 36px 0 30px 0;
    width: 100%; }
  .local-version-outdated .error-logo {
    float: left;
    text-align: center;
    margin-top: -20px;
    width: 186px; }
  .local-version-outdated .support-link {
    color: #116dff;
    text-decoration: none;
    display: block; }
    .local-version-outdated .support-link:hover {
      text-decoration: underline; }
  .local-version-outdated .description-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    max-width: 294px;
    line-height: 22px;
    margin: -1px 50px 0 0;
    color: #000624; }
    .local-version-outdated .description-wrapper .description-line {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      padding-left: 0.2em; }
      .local-version-outdated .description-wrapper .description-line:first-child, .local-version-outdated .description-wrapper .description-line.initialPadding {
        padding-left: initial; }

.message-accept-panel .symbol-localModeGeneralError {
  margin-top: -16px; }

.general-panel-frame-z-index {
  z-index: 1719; }

#connection-error-panel-frame .composite-panel-content {
  padding-bottom: 24px; }

#connection-error-panel-frame .danger-message-content {
  color: #000624; }

.local-mode-reconnect {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .local-mode-reconnect.show-loader .local-mode-reconnect-panel {
    display: none; }
  .local-mode-reconnect .reconnecting-loader-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .local-mode-reconnect .reconnecting-loader-content .control-label {
      color: #fff; }
  .local-mode-reconnect .local-mode-reconnect-panel {
    width: 576px;
    background-color: #fff; }
    .local-mode-reconnect .local-mode-reconnect-panel .panel-header {
      height: 54px; }
      .local-mode-reconnect .local-mode-reconnect-panel .panel-header .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 20px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        line-height: 54px; }
      .local-mode-reconnect .local-mode-reconnect-panel .panel-header .close {
        top: 14px;
        margin-top: 0; }
    .local-mode-reconnect .local-mode-reconnect-panel .content {
      padding: 0; }
    .local-mode-reconnect .local-mode-reconnect-panel > footer {
      position: static;
      text-align: right;
      margin: 0 30px 30px 0; }
      .local-mode-reconnect .local-mode-reconnect-panel > footer .btn-confirm-primary,
      .local-mode-reconnect .local-mode-reconnect-panel > footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased; }
    .local-mode-reconnect .local-mode-reconnect-panel .content-wrapper {
      display: inline-block;
      font-size: 0;
      position: relative;
      margin: 36px 0 30px 0;
      width: 100%; }
    .local-mode-reconnect .local-mode-reconnect-panel .error-logo {
      float: left;
      text-align: center;
      margin-top: -20px;
      width: 186px; }
    .local-mode-reconnect .local-mode-reconnect-panel .support-link {
      color: #116dff;
      text-decoration: none;
      display: block; }
      .local-mode-reconnect .local-mode-reconnect-panel .support-link:hover {
        text-decoration: underline; }
    .local-mode-reconnect .local-mode-reconnect-panel .description-wrapper {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      max-width: 294px;
      line-height: 22px;
      margin: -1px 50px 0 0;
      color: #000624; }
      .local-mode-reconnect .local-mode-reconnect-panel .description-wrapper .description-line {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        padding-left: 0.2em; }
        .local-mode-reconnect .local-mode-reconnect-panel .description-wrapper .description-line:first-child, .local-mode-reconnect .local-mode-reconnect-panel .description-wrapper .description-line.initialPadding {
          padding-left: initial; }

.interactions-hidden-components-panel {
  width: 360px;
  min-height: 504px;
  max-height: calc(100vh - 88px); }
  .interactions-hidden-components-panel .panel-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 450px;
    justify-content: space-between; }
  .interactions-hidden-components-panel .composite-rich-text-with-illustration-vertical {
    padding: 24px 36px 0 36px; }
  .interactions-hidden-components-panel .hidden-items-container {
    max-height: calc(100vh - 240px); }
  .interactions-hidden-components-panel .hidden-panel-message {
    padding: 24px 36px;
    border-top: 1px solid #dfe5eb; }

.classic-facelift-skin .interactions-hidden-components-panel {
  min-height: 0;
  max-height: none; }
  .classic-facelift-skin .interactions-hidden-components-panel .panel-content-wrapper {
    min-height: 0;
    height: 100%; }
  .classic-facelift-skin .interactions-hidden-components-panel .hidden-items-container {
    max-height: none; }

.interactions-panel .interactions-panel-content {
  padding-bottom: 18px;
  min-height: 30px; }
  .interactions-panel .interactions-panel-content .control-illustration {
    padding-top: 18px; }
  .interactions-panel .interactions-panel-content .composite-rich-text-with-illustration-vertical {
    padding-top: 6px; }
    .interactions-panel .interactions-panel-content .composite-rich-text-with-illustration-vertical .interactions-applied-title {
      margin-bottom: 12px; }
  .interactions-panel .interactions-panel-content .edit-interaction-content .composite-button-large {
    padding-top: 6px; }
  .interactions-panel .interactions-panel-content .add-interaction-content .composite-button-large,
  .interactions-panel .interactions-panel-content .edit-interaction-content .composite-button-large {
    padding-top: 0;
    padding-bottom: 18px; }
  .interactions-panel .interactions-panel-content .remove-interaction-content {
    display: flex;
    justify-content: center;
    padding-bottom: 18px; }
    .interactions-panel .interactions-panel-content .remove-interaction-content span {
      color: #116dff;
      cursor: pointer; }
      .interactions-panel .interactions-panel-content .remove-interaction-content span:hover {
        text-decoration: underline; }

.hidden-interaction-item {
  height: 60px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #dfe5eb; }
  .hidden-interaction-item:hover {
    background-color: #e7f0ff; }
  .hidden-interaction-item > .icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border: solid 1px #dfe5eb;
    border-radius: 100%;
    background-repeat: no-repeat;
    left: 25px;
    top: 10px;
    display: flex; }
    .hidden-interaction-item > .icon svg {
      margin: auto;
      width: 14px;
      height: 14px; }
  .hidden-interaction-item > .label {
    position: relative;
    margin-left: 80px;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 195px; }
  .hidden-interaction-item .add-button {
    position: absolute;
    height: 26px;
    width: 26px;
    right: 17px;
    top: 17px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer; }

.customize-effect-panel .effect-name {
  display: flex;
  align-items: center; }
  .customize-effect-panel .effect-name .control-label {
    margin-left: 10px; }

.customize-effect-panel .control-alignment {
  padding: 16px 24px; }

.interaction-nav {
  border-radius: 2px;
  position: absolute; }
  .interaction-nav span {
    color: #fff; }
  .interaction-nav.regular {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73px;
    background-image: linear-gradient(to bottom, #50adff, #3999ec);
    height: 26px;
    top: -26px;
    left: -1px; }
    .interaction-nav.regular.control-position {
      top: calc(-26px - 2px);
      left: -3px; }
    .interaction-nav.regular,
    .interaction-nav.regular span,
    .interaction-nav.regular svg {
      cursor: move; }
    .classic-facelift-skin .interaction-nav.regular {
      background-image: none;
      background-color: #116dff; }
  .interaction-nav.interaction {
    min-width: 82px;
    background-color: #0057e1;
    height: 23px;
    top: -23px;
    left: calc(73px - 7px); }
    .interaction-nav.interaction .control-label {
      padding-right: 10px; }
    .interaction-nav.interaction.control-position {
      top: calc(-23px - 2px);
      left: calc(73px - 9px); }
    .interaction-nav.interaction:hover {
      background-color: #116dff; }
    .interaction-nav.interaction,
    .interaction-nav.interaction span,
    .interaction-nav.interaction svg {
      cursor: pointer; }
    .interaction-nav.interaction svg {
      float: left;
      margin-left: 12px;
      margin-top: 1px; }
    .interaction-nav.interaction span {
      float: left;
      line-height: 23px; }
    .classic-facelift-skin .interaction-nav.interaction {
      background-color: #0057e1; }
      .classic-facelift-skin .interaction-nav.interaction:hover {
        background-color: #5999ff; }
  .interaction-nav.components-ui-color-orange.regular {
    background-image: linear-gradient(to bottom, #ff7d38, #fe620f); }
  .interaction-nav.components-ui-color-orange.interaction {
    background-color: #bf5727; }
    .interaction-nav.components-ui-color-orange.interaction:hover {
      background-color: #bf5727; }
  .classic-facelift-skin .interaction-nav.components-ui-color-orange.regular {
    background-image: none;
    background-color: #fe620f; }
  .classic-facelift-skin .interaction-nav.components-ui-color-orange.interaction {
    background-color: #bf5727; }
    .classic-facelift-skin .interaction-nav.components-ui-color-orange.interaction:hover {
      background-color: #ff7d38; }

.transition-panel-content .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container {
  background-color: transparent; }

.transition-panel-content .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: 24px;
  right: 16px; }

.add-page-panel {
  margin-top: 0;
  width: calc(100vw - (39px * 2));
  max-width: 1482px;
  height: 93vh;
  max-height: 900px; }
  .add-page-panel .focus-panel-frame-content {
    padding-left: 176px;
    position: relative; }
    .add-page-panel .focus-panel-frame-content .left-menu-wrapper {
      background-color: #f6fbff;
      width: 176px;
      max-width: 176px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .add-page-panel .focus-panel-frame-content .left-menu-wrapper .blank-page-wrapper {
        padding: 24px 21px 8px; }
        .add-page-panel .focus-panel-frame-content .left-menu-wrapper .blank-page-wrapper .blank-page-button {
          height: 30px;
          width: 134px;
          padding: 0 15px; }
          .add-page-panel .focus-panel-frame-content .left-menu-wrapper .blank-page-wrapper .blank-page-button svg {
            min-width: 9px; }
          .add-page-panel .focus-panel-frame-content .left-menu-wrapper .blank-page-wrapper .blank-page-button .control-label-base {
            max-width: 100px; }
      .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .tabs-divider-title {
        margin: 20px 0 10px 32px; }
        .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .tabs-divider-title .control-label-base {
          font-size: 11px;
          color: #868aa5; }
      .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .control-vertical-tabs {
        background-color: #f6fbff;
        width: 176px;
        max-width: 176px;
        padding: 16px 10px 14px; }
        .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .control-vertical-tabs .radio-button-item {
          margin-left: 10px; }
          .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .control-vertical-tabs .radio-button-item .control-label-base {
            font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
            font-weight: var(--wbu-font-weight-regular);
            font-size: 14px;
            -webkit-font-smoothing: antialiased; }
          .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu .control-vertical-tabs .radio-button-item.selected .control-label-base {
            color: #116dff; }
      .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu.premium-selected .control-vertical-tabs .radio-button-item.selected .radio-button-wrapper {
        background-color: #eedbf4; }
        .add-page-panel .focus-panel-frame-content .left-menu-wrapper .categories-menu.premium-selected .control-vertical-tabs .radio-button-item.selected .radio-button-wrapper .control-label-base {
          color: #9a27d5; }
    .add-page-panel .focus-panel-frame-content .error-state-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; }
      .add-page-panel .focus-panel-frame-content .error-state-wrapper .composite-button-large {
        padding: 6px 24px 14px 24px; }
        .add-page-panel .focus-panel-frame-content .error-state-wrapper .composite-button-large .control-button {
          height: 30px;
          min-width: 117px; }
      .add-page-panel .focus-panel-frame-content .error-state-wrapper a {
        color: #116dff;
        cursor: pointer;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
        .add-page-panel .focus-panel-frame-content .error-state-wrapper a:hover {
          text-decoration: underline; }
    .add-page-panel .focus-panel-frame-content .add-page-panel-content {
      position: relative;
      height: 100%;
      width: 100%;
      margin: auto;
      overflow-y: scroll; }
      .add-page-panel .focus-panel-frame-content .add-page-panel-content .preloader-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%); }
      .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper {
        position: relative;
        max-width: 1143px;
        width: calc(100% - 54px * 2);
        padding: 0;
        margin: auto !important;
        will-change: transform; }
        .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header {
          width: 100%;
          display: block;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: #fff;
          padding: 40px 5px 0;
          margin-left: -5px;
          z-index: 1; }
          .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header:before {
            content: '';
            height: 100%;
            width: calc(100% + 40px);
            position: absolute;
            top: 0;
            left: -20px;
            background-color: #fff;
            z-index: -1; }
          .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-title {
            display: flex;
            transition: transform 250ms;
            transform-origin: 0 0; }
            .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-title .category-label {
              transform-origin: 0 0;
              display: inline-block;
              height: 24px;
              line-height: 24px;
              padding: 0 14px;
              border-radius: 2px;
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1.2px;
              text-transform: uppercase;
              position: relative;
              top: 1px;
              left: 5px; }
              .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-title .category-label.premium {
                font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
                font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
                font-weight: var(--wbu-font-weight-regular);
                font-size: 14px;
                -webkit-font-smoothing: antialiased;
                font-size: 12px;
                font-weight: normal;
                text-transform: none;
                letter-spacing: normal; }
              .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-title .category-label > svg {
                position: relative;
                top: 1px;
                left: -8px; }
                .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-title .category-label > svg path {
                  fill: #9a27d5; }
          .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-description {
            margin-top: 5px; }
            .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-description .control-label-base {
              margin-right: 4px;
              white-space: normal;
              line-height: 19px; }
            .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-header .thumbnails-header-description a {
              font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
              font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
              font-weight: var(--wbu-font-weight-regular);
              font-size: 16px;
              -webkit-font-smoothing: antialiased; }
        .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          padding-top: 36px; }
          .add-page-panel .focus-panel-frame-content .add-page-panel-content .main-wrapper .thumbnails-wrapper .thumbnails-column {
            padding: 0 calc(54px / 2); }
    .add-page-panel .focus-panel-frame-content .add-page-panel-content-scrolled .main-wrapper .thumbnails-header {
      padding: 33px 5px 8px;
      box-shadow: 0 6px 6px -7px rgba(22, 45, 61, 0.36); }
      .add-page-panel .focus-panel-frame-content .add-page-panel-content-scrolled .main-wrapper .thumbnails-header .thumbnails-header-title {
        transform: translateY(-10px) scale(0.8); }
        .add-page-panel .focus-panel-frame-content .add-page-panel-content-scrolled .main-wrapper .thumbnails-header .thumbnails-header-title .category-label {
          transform: scale(1.25) translateY(-3px); }
      .add-page-panel .focus-panel-frame-content .add-page-panel-content-scrolled .main-wrapper .thumbnails-header .thumbnails-header-description {
        display: none; }

.html-preview-thumbnail {
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  pointer-events: none;
  transition: background-color 350ms cubic-bezier(0.4, 0, 1, 1); }
  .html-preview-thumbnail iframe {
    transition: opacity 350ms cubic-bezier(0.4, 0, 1, 1);
    opacity: 1; }
  .html-preview-thumbnail.loading {
    background-color: #f0f3f5; }
    .html-preview-thumbnail.loading iframe {
      opacity: 0; }

.add-page-thumbnail {
  position: relative;
  top: 0;
  box-shadow: 0 2px 7px rgba(22, 45, 61, 0.17);
  margin-bottom: calc(24px + 37px);
  border-radius: 4px;
  border: 1px solid #dfe5eb;
  transform-origin: 50% 50%;
  opacity: 0;
  transform: scale(0.7);
  transition: top 250ms ease-in-out, box-shadow 250ms ease-in-out, opacity 500ms linear, transform 500ms ease-out; }
  .add-page-thumbnail.complete {
    opacity: 1;
    transform: scale(1); }
  .add-page-thumbnail.hovered {
    top: -5px;
    box-shadow: 0 12px 20px rgba(22, 45, 61, 0.12); }
    .add-page-thumbnail.hovered .add-page-button-overlay {
      opacity: 1; }
  .add-page-thumbnail .add-page-thumbnail-image-wrapper {
    width: 100%;
    height: 100%; }
  .add-page-thumbnail .add-page-thumbnail-info-wrapper {
    padding: 12px 6px;
    display: flex;
    justify-content: space-between;
    transition: padding 250ms ease-in-out;
    position: absolute; }
  .add-page-thumbnail.multiple-preset .add-page-thumbnail-info-wrapper {
    padding-top: 22px; }
  .add-page-thumbnail .premium-symbol {
    position: absolute;
    right: 0;
    bottom: -28px;
    transition: bottom 250ms ease-in-out; }
  .add-page-thumbnail .add-page-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.88);
    opacity: 0;
    border-radius: 4px;
    transition: opacity 250ms cubic-bezier(0.4, 0, 1, 1);
    cursor: pointer; }
    .add-page-thumbnail .add-page-button-overlay .add-page-thumbnail-button {
      min-width: 117px; }
      .add-page-thumbnail .add-page-button-overlay .add-page-thumbnail-button .control-label-base {
        font-size: 16px;
        line-height: 20px; }
    .add-page-thumbnail .add-page-button-overlay .add-page-preview-button {
      min-width: 117px;
      margin-top: 12px; }
      .add-page-thumbnail .add-page-button-overlay .add-page-preview-button .control-label-base {
        font-size: 16px; }

.premium-icon-tooltip-content {
  max-width: 170px; }

.premium-icon-tooltip-title,
.premium-icon-tooltip-description {
  margin-bottom: 5px; }

.tooltip-upgrade-link {
  cursor: pointer; }

.add-page-panel-preview-thumbnail {
  overflow: visible;
  margin-top: -50px; }
  .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header {
    border-radius: 4px; }
    .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .x-button {
      position: absolute;
      right: -38px;
      top: -13px;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border-radius: 100%; }
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .x-button,
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .x-button svg {
        cursor: pointer; }
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .x-button svg path {
        fill: #116dff; }
    .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-wrapper {
      overflow-y: scroll;
      height: 100%; }
    .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 84px;
      position: absolute;
      bottom: calc(2px - 84px);
      width: 100%;
      box-shadow: 0 0 25px 0 rgba(22, 45, 61, 0.15);
      background: #fff;
      border-radius: 0 0 4px 4px; }
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-footer.extra-footer-height {
        bottom: -50px; }
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-footer .thumbnail-preview-title {
        padding-left: 30px; }
      .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-footer .add-page-footer-button {
        min-width: 117px;
        margin-right: 24px; }
        .add-page-panel-preview-thumbnail .focus-panel-frame-content-no-header .thumbnail-preview-footer .add-page-footer-button .control-label-base {
          font-size: 16px; }

.add-page-pre-install-app {
  margin: 30px;
  width: calc(100% - (30px * 2));
  height: calc(100% - (30px * 2));
  border-radius: 6px;
  border: 1px solid #dfe5eb;
  display: flex;
  justify-content: space-between; }
  .add-page-pre-install-app .add-page-pre-install-app-content {
    padding: 0 60px;
    margin: auto 0;
    width: calc(100% - 33vw - (60px * 2)); }
    .add-page-pre-install-app .add-page-pre-install-app-content .app-page-pre-install-texts {
      padding: 14px 0; }
      .add-page-pre-install-app .add-page-pre-install-app-content .app-page-pre-install-texts .app-page-pre-install-title span {
        color: #000624; }
      .add-page-pre-install-app .add-page-pre-install-app-content .app-page-pre-install-texts .app-page-pre-install-description span {
        color: #3b4057; }
    .add-page-pre-install-app .add-page-pre-install-app-content .composite-rich-text-with-illustration {
      padding-bottom: 0; }
      .add-page-pre-install-app .add-page-pre-install-app-content .composite-rich-text-with-illustration .composite-rich-text-labeled {
        padding: 9px 12px; }
      .add-page-pre-install-app .add-page-pre-install-app-content .composite-rich-text-with-illustration .app-page-illustration {
        min-width: 40px;
        min-height: 40px;
        border: 1px solid #dfe5eb;
        border-radius: 50%;
        margin: auto 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .add-page-pre-install-app .add-page-pre-install-app-content .composite-rich-text-with-illustration .app-page-illustration svg path {
          fill: #3b4057; }
    .add-page-pre-install-app .add-page-pre-install-app-content .add-app-page-button {
      margin: 24px 0; }
    .add-page-pre-install-app .add-page-pre-install-app-content .num-pages-title span {
      color: #3b4057; }
    .add-page-pre-install-app .add-page-pre-install-app-content .num-pages-description.control-rich-text {
      color: #868aa5; }
  .add-page-pre-install-app .add-page-carousel-gallery {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7; }
    .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper {
      width: 33vw;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 6px; }
      .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button {
        width: 76px;
        height: 76px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(122, 146, 165, 0.4);
        border-radius: 50%;
        transition: all 150ms ease-in;
        cursor: pointer; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all 150ms ease-in; }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button svg path {
            fill: #000624; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.left {
          left: calc(76px / -2); }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.left svg {
            left: 37px; }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.left:hover {
            transform: translateY(-50%) translateX(10px); }
            .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.left:hover svg {
              transform: translateY(-50%) translateX(-5px); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.right {
          right: calc(76px / -2); }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.right svg {
            right: 37px; }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.right:hover {
            transform: translateY(-50%) translateX(-10px); }
            .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button.right:hover svg {
              transform: translateY(-50%) translateX(5px); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button:hover {
          background-color: rgba(22, 45, 61, 0.6); }
          .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .arrow-button:hover svg path {
            fill: #fff; }
      .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-item-description {
        position: absolute;
        left: 50%;
        top: calc((100% - calc(calc(33vw * 0.75) * 0.85)) / 2 + calc(calc(33vw * 0.75) * 0.85) + 20px);
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-item-description .carousel-item-description-text {
          margin-right: 8px; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-item-description .carousel-item-info-icon svg path {
          fill: #3b4057; }
      .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image {
        display: inline-block;
        height: calc(calc(33vw * 0.75) * 0.85);
        width: calc(33vw * 0.75);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        left: calc(calc(calc(33vw * 0.75) * (-0.85)) - calc(33vw * 0.75));
        transition: all 350ms ease-in-out;
        transform: translateY(-50%);
        border-top: 8px solid #3f42d4;
        border-radius: 3px; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.hidden {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-50%); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.center {
          left: calc(33vw / 2);
          transform: translate(-50%, -50%);
          box-shadow: 0 4px 9px -3px rgba(0, 0, 0, 0.19), 0 46px 66px -30px rgba(22, 45, 61, 0.2); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.right {
          left: calc( 33vw - (calc(33vw * 0.75) + calc(calc(33vw * 0.75) * (-0.85))));
          transform: translateY(-50%) scale(0.85); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.left {
          left: calc(calc(33vw * 0.75) * (-0.85));
          transform: translateY(-50%) scale(0.85); }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.right-to-hidden {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-50%) scale(0.85);
          animation: right-to-hidden 350ms ease-in-out; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.left-to-hidden {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-50%) scale(0.85);
          animation: left-to-hidden 350ms ease-in-out; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.hidden-to-left {
          opacity: 1;
          visibility: visible;
          left: calc(calc(33vw * 0.75) * (-0.85));
          transform: translateY(-50%) scale(0.85);
          animation: hidden-to-left 350ms ease-in-out; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.hidden-to-right {
          opacity: 1;
          visibility: visible;
          left: calc( 33vw - (calc(33vw * 0.75) + calc(calc(33vw * 0.75) * (-0.85))));
          transform: translateY(-50%) scale(0.85);
          animation: hidden-to-right 350ms ease-in-out; }
        .add-page-pre-install-app .add-page-carousel-gallery .add-page-carousel-gallery-items-wrapper .carousel-gallery-images-wrapper .carousel-gallery-image.left-to-center {
          opacity: 1;
          visibility: visible;
          left: calc(33vw / 2);
          transform: translate(-50%, -50%);
          animation: left-to-center 350ms ease-in-out; }
    .add-page-pre-install-app .add-page-carousel-gallery .circle-indicators-wrapper {
      display: flex;
      align-items: center;
      margin: 0 auto 14px; }
      .add-page-pre-install-app .add-page-carousel-gallery .circle-indicators-wrapper .circle-indicator {
        width: 8px;
        height: 8px;
        background-color: #dfe5eb;
        border-radius: 50%;
        margin: 8px;
        transition: background-color 200ms ease-in; }
        .add-page-pre-install-app .add-page-carousel-gallery .circle-indicators-wrapper .circle-indicator.centered {
          background-color: #116dff; }

@media only screen and (max-width: 1018px) and (min-width: 898px) {
  .add-page-pre-install-app .add-page-pre-install-app-content {
    padding: 0 calc(60px - (1018px - 100vw) / 2);
    margin: auto 0;
    width: calc( 100% - 33vw - (calc(60px - (1018px - 100vw) / 2) * 2)); } }

@media only screen and (max-width: 898px) {
  .add-page-pre-install-app .add-page-pre-install-app-content {
    padding: 0;
    width: calc(100% - 33vw); } }

@keyframes left-to-hidden {
  from {
    left: calc(calc(33vw * 0.75) * (-0.85)); }
  to {
    left: calc(calc(calc(33vw * 0.75) * (-0.85)) - calc(33vw * 0.75)); } }

@keyframes hidden-to-right {
  from {
    left: calc(33vw - calc(calc(33vw * 0.75) * (-0.85))); }
  to {
    left: calc(33vw - (calc(33vw * 0.75) + calc(calc(33vw * 0.75) * (-0.85)))); } }

@keyframes hidden-to-left {
  from {
    left: calc(calc(calc(33vw * 0.75) * (-0.85)) - calc(33vw * 0.75)); }
  to {
    left: calc(calc(33vw * 0.75) * (-0.85)); } }

@keyframes right-to-hidden {
  from {
    left: calc(33vw - (calc(33vw * 0.75) + calc(calc(33vw * 0.75) * (-0.85)))); }
  to {
    left: 33vw; } }

@keyframes left-to-center {
  from {
    left: calc(calc(33vw * 0.75) * (-0.85)); }
  to {
    left: calc(33vw / 2); } }

.add-page-post-install-app {
  margin: 30px;
  width: calc(100% - (30px * 2));
  height: calc(100% - (30px * 2));
  border-radius: 6px;
  border: 1px solid #dfe5eb;
  display: flex;
  justify-content: space-between; }
  .add-page-post-install-app .add-page-post-install-app-content {
    padding: 0 60px;
    margin: auto 0;
    width: calc( 100% - 32vw - (60px * 2)); }
    .add-page-post-install-app .add-page-post-install-app-content .app-page-post-install-texts {
      padding: 14px 0; }
      .add-page-post-install-app .add-page-post-install-app-content .app-page-post-install-texts .app-page-post-install-title span {
        color: #000624;
        margin-bottom: 6px; }
      .add-page-post-install-app .add-page-post-install-app-content .app-page-post-install-texts .app-page-post-install-description {
        margin-bottom: 4px;
        line-height: 23px; }
        .add-page-post-install-app .add-page-post-install-app-content .app-page-post-install-texts .app-page-post-install-description span {
          color: #3b4057; }
    .add-page-post-install-app .add-page-post-install-app-content .post-install-action-container {
      display: flex;
      align-items: center; }
      .add-page-post-install-app .add-page-post-install-app-content .post-install-action-container .post-install-action-divider {
        margin-right: 12px;
        margin-left: 12px; }
        .add-page-post-install-app .add-page-post-install-app-content .post-install-action-container .post-install-action-divider svg path {
          fill: #116dff; }
    .add-page-post-install-app .add-page-post-install-app-content .post-install-action {
      display: flex;
      align-items: center; }
      .add-page-post-install-app .add-page-post-install-app-content .post-install-action .post-install-action-text {
        cursor: pointer;
        color: #116dff; }
        .add-page-post-install-app .add-page-post-install-app-content .post-install-action .post-install-action-text:hover {
          text-decoration: underline; }
      .add-page-post-install-app .add-page-post-install-app-content .post-install-action svg path {
        fill: #116dff; }
  .add-page-post-install-app .add-page-post-install-app-wrapper {
    margin: 16px;
    position: relative;
    width: 32vw; }
    .add-page-post-install-app .add-page-post-install-app-wrapper .add-page-post-install-app-image {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: calc(32vw * 0.8);
      height: calc(calc(32vw * 0.8) * 0.85);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 4px 9px -3px rgba(0, 0, 0, 0.19), 0 46px 66px -30px rgba(22, 45, 61, 0.2); }
    .add-page-post-install-app .add-page-post-install-app-wrapper .add-page-post-install-app-bg {
      float: right;
      height: 100%;
      background-color: #f7f7f7;
      width: calc(32vw * 0.75); }

@media only screen and (max-width: 1018px) and (min-width: 898px) {
  .add-page-post-install-app .add-page-post-install-app-content {
    padding: 0 calc(60px - (1018px - 100vw) / 2);
    width: calc( 100% - 32vw - (calc(60px - (1018px - 100vw) / 2) * 2)); } }

@media only screen and (max-width: 898px) {
  .add-page-post-install-app .add-page-post-install-app-content {
    padding: 0;
    width: calc(100% - 32vw); } }

.panel-page-already-added .popup-modal {
  width: 577px; }
  .panel-page-already-added .popup-modal .modal-illustration {
    text-align: center;
    padding-top: 18px; }

.add-page-notification-banner {
  padding: 0; }
  .add-page-notification-banner button.control-button {
    height: 20px; }
  .add-page-notification-banner .notification-banner {
    z-index: 1;
    transition: all 0.4s ease; }
    .add-page-notification-banner .notification-banner .control-button.link > .button-content:hover {
      color: #c161f0; }

.menu-manage-tooltip-content .composite-rich-text-labeled {
  padding: 0; }

.menu-manage-tooltip-content__link {
  margin-top: 6px; }

.menu-manage-panel__footer {
  display: flex;
  padding: 17px 24px 19px;
  box-sizing: border-box;
  justify-content: center;
  flex-shrink: 0; }
  .menu-manage-panel__footer__buttons-wrapper {
    margin-left: 12px; }
  .menu-manage-panel__footer .add-page-button {
    margin: 0 !important; }
    .menu-manage-panel__footer .add-page-button-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .menu-manage-panel__footer .add-page-button-content {
      display: flex;
      overflow: hidden;
      line-height: 1.2em; }
  .menu-manage-panel__footer .add-link-button,
  .menu-manage-panel__footer .add-menu-button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    background-color: #e7f0ff;
    margin: 0 !important; }
    .menu-manage-panel__footer .add-link-button .symbol path,
    .menu-manage-panel__footer .add-menu-button .symbol path {
      fill: #116dff; }
    .menu-manage-panel__footer .add-link-button:hover,
    .menu-manage-panel__footer .add-menu-button:hover {
      background-color: #d6e6fe; }

.menu-manage-panel__footer_disclaimer {
  padding: 18px 24px; }

.menu-manage-panel__header {
  padding: 16px 12px 18px 24px; }

.menu-manage-panel__header-description {
  display: flex;
  justify-content: space-between; }

.menu-manage-panel__header-menu-title {
  margin-top: 7px;
  display: grid;
  grid-template-columns: -webkit-min-content minmax(0, 1fr) -webkit-min-content;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  align-items: center; }

.menu-manage-panel__header-menu-title .control-label {
  min-width: 0; }

.menu-manage-panel__header-menu-title .control-button {
  margin: 0 12px 0 6px; }

.menu-manage-panel__header-icon {
  visibility: hidden; }

.menu-manage-panel__main-radio-icon {
  margin-right: 10px; }

.menu-manage-panel__header:hover .menu-manage-panel__header-icon {
  visibility: visible; }

.menu-manage-panel__header-menu-title__warning {
  margin-left: 6px;
  margin-top: 3px; }

.menu-manage-panel {
  display: flex;
  flex-direction: column; }

.menu-manage-panel header + div {
  display: flex; }

.menu-manage-panel__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; }

.menu-manage-panel__items-list {
  min-height: calc( ( var(--comp-Panel-Frame-Height) - var(--header-height) - var( --footer-height ) ) * 1px);
  display: flex;
  overflow: hidden;
  flex-grow: 1; }

.menu-manage-panel__divider {
  flex-shrink: 0; }

.menu-manager-panel__add-container-badge {
  margin-left: 8px; }

.menu-manage-panel__slot-custom-button {
  visibility: visible; }

.menu-sets-panel__item {
  width: 100%;
  border-bottom: 1px solid #dfe5eb; }
  .menu-sets-panel__item_main-menu .text-inner-content {
    max-width: calc(100% - 24px);
    width: unset !important; }
  .menu-sets-panel__item .sbdl-item-row-base {
    width: 100%; }
    .menu-sets-panel__item .sbdl-item-row-base .content .view-mode-content {
      height: 47px; }
    .menu-sets-panel__item .sbdl-item-row-base .input-container input {
      width: 100%; }
  .menu-sets-panel__item .control-button {
    flex-shrink: 0;
    margin-right: 12px; }

.menu-sets-panel {
  width: 240px;
  height: 478px; }
  .menu-sets-panel__content {
    height: calc(478px - 54px); }
  .menu-sets-panel__add-button {
    margin: 16px 24px;
    font-size: 14px !important;
    line-height: 18px !important;
    height: 18px; }

.tour-maker-highlight-element,
.intro-fixparent {
  pointer-events: none; }

.tour-backdrop-disable-pointer-events::after {
  content: '';
  position: absolute;
  opacity: 0;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000000000; }

.tour-tooltip-panel-intro-step .control-base-modal-footer {
  flex-direction: column-reverse; }

.tour-tooltip-panel.control-custom-modal {
  width: 288px;
  min-width: 288px; }
  .tour-tooltip-panel.control-custom-modal.tour-tooltip-panel-intro-step .control-base-modal-body {
    margin-bottom: 24px; }
  .tour-tooltip-panel.control-custom-modal .control-custom-modal-content-wrapper {
    padding: 0; }
  .tour-tooltip-panel.control-custom-modal .control-base-modal-header {
    border-top: none;
    padding-top: 36px; }
  .tour-tooltip-panel.control-custom-modal .control-base-modal-body {
    padding: 0; }
  .tour-tooltip-panel.control-custom-modal .control-custom-modal-footer {
    padding: 0; }
  .tour-tooltip-panel.control-custom-modal .control-base-modal-btn {
    margin-left: 0; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__title {
    color: #000624;
    padding: 0 24px 3px; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__subtitle {
    color: #3b4057;
    padding: 3px 24px 0; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__link {
    display: block;
    padding: 9px 24px 0;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__link:hover {
      text-decoration: underline; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__link:visited {
      color: #116dff; }
  .tour-tooltip-panel.control-custom-modal hr {
    margin-top: 24px; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__media {
    padding: 0 24px 20px; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__media_img {
    width: 100%; }
  .tour-tooltip-panel.control-custom-modal .control-base-modal-header-btn-bar {
    top: 3px;
    right: 3px; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button {
      display: flex; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .btn--secondary {
        display: flex;
        align-items: center; }
        .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .btn--secondary:hover {
          text-decoration: underline;
          -webkit-text-decoration-color: #80b1ff;
                  text-decoration-color: #80b1ff; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .control-button:focus,
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .btn--secondary:focus {
        outline: 2px solid #80b1ff; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .control-button.suffix-icon {
        padding: 0 0 0 12px; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .control-button.prefix-icon {
        padding: 0 12px 0 0; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .control-button.btn-confirm-secondary {
        background: transparent; }
        .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav .tour-tooltip-panel__nav-button .control-button.btn-confirm-secondary:hover {
          background-color: #ebf2ff; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-progress {
      display: flex;
      width: 28%;
      justify-content: center;
      align-items: center;
      color: #868aa5;
      font-size: 12px;
      line-height: 18px; }
      .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-progress-step {
        width: 4px;
        height: 4px;
        background: #dfe5eb;
        border-radius: 50%;
        margin: 3px; }
        .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-progress-step_active {
          background: #116dff; }
  .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-self: center;
    padding: 0 24px 24px;
    align-items: center; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-reverse .tour-tooltip-panel__nav-button .control-button {
      padding: 0 12px; }
    .tour-tooltip-panel.control-custom-modal .tour-tooltip-panel__nav-reverse .tour-tooltip-panel__nav-button + .tour-tooltip-panel__nav-button {
      margin-bottom: 10px; }
  .tour-tooltip-panel.control-custom-modal.tour-tooltip-success .control-custom-modal-content-wrapper {
    padding: 0 24px; }
    .tour-tooltip-panel.control-custom-modal.tour-tooltip-success .control-custom-modal-content-wrapper .divider-long {
      display: none; }
    .tour-tooltip-panel.control-custom-modal.tour-tooltip-success .control-custom-modal-content-wrapper .tour-tooltip-panel__title {
      padding: 0 0 6px;
      width: 100%; }
    .tour-tooltip-panel.control-custom-modal.tour-tooltip-success .control-custom-modal-content-wrapper .tour-tooltip-panel__subtitle {
      padding: 0;
      display: inline; }
    .tour-tooltip-panel.control-custom-modal.tour-tooltip-success .control-custom-modal-content-wrapper .tour-tooltip-panel__link {
      display: inline;
      -webkit-margin-start: 4px;
              margin-inline-start: 4px;
      padding: 0; }

.tour-tooltip-animation-appear {
  transition: opacity 0.05s, transform 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  opacity: 0;
  transform: translate(-25px, 0); }
  .tour-tooltip-animation-appear.tour-tooltip-opens-down:not(.tour-tooltip-animation-appear-active) {
    transform: translate(0, -25px); }

.tour-tooltip-animation-appear-active {
  opacity: 1;
  transform: translate(0, 0); }

.tour-tooltip-animation-from-the-top-appear {
  opacity: 0;
  transform: translateY(-20px); }

.tour-tooltip-animation-from-the-top-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s 0.34s; }
  .tour-tooltip-animation-from-the-top-appear-active.tour-tooltip-panel-intro-step {
    transition: all 0.5s 0.8s; }

.tour-card-shake-animation {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px; }

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    transform: translate3d(4px, 0, 0); } }

.success-animation {
  position: absolute;
  transform: translate(-94px, -106px) rotate(150deg) scale(0.75);
  animation: success-animation-disappear 2.7s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes success-animation-disappear {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    overflow: hidden;
    visibility: hidden; } }

.pages-tree-node {
  margin: 4px 0 0;
  border: 0;
  position: relative; }
  .pages-tree-node:first-child > .pages-tree-item:before {
    height: 50%;
    padding-top: 3px; }
  .pages-tree-node .pages-tree-node {
    margin-left: 25px; }
    .pages-tree-node .pages-tree-node > .pages-tree-item:before {
      display: block; }
  .pages-tree-node .expander {
    display: none;
    background-color: #cde;
    border-radius: 100%;
    color: #1a92df;
    cursor: pointer;
    height: 14px;
    left: -7px;
    font-size: 14px;
    line-height: 10px;
    position: absolute;
    text-align: center;
    top: 12.5px;
    width: 14px;
    z-index: 1445; }

.pages-tree-shrinked .pages-tree {
  margin: 5px 0 0; }
  .pages-tree-shrinked .pages-tree:first-child > .pages-tree-item:before {
    padding-top: 4px; }
  .pages-tree-shrinked .pages-tree .symbol-quickActionsSettings {
    margin-left: -0.5px;
    margin-top: 0.5px; }
  .pages-tree-shrinked .pages-tree .expander {
    top: 4.5px; }

.has-children.pages-tree-node > .expander {
  display: block; }

.tree-collapsed.pages-tree-node + .pages-tree-node {
  margin-top: 12px; }

.tree-collapsed .pages-tree-node {
  display: none; }
  .tree-collapsed .pages-tree-node.shadow {
    display: block;
    margin-top: 12px; }
    .tree-collapsed .pages-tree-node.shadow > .pages-tree-item:before {
      height: 50%;
      padding-top: 4px; }

.tree-collapsed > .pages-tree-item,
.rotated.has-children > .pages-tree-item {
  margin-bottom: 6px; }

.tree-collapsed > .pages-tree-item {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #fff, 0 4px 0 #cde, 0 6px 0 #fff, 0 7px 0 #cde; }

.tree-collapsed > .pages-tree-item:hover,
.tree-collapsed > .pages-tree-item.selected,
.rotated.has-children > .pages-tree-item {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #e7f0ff, 0 4px 0 #cde, 0 6px 0 #e7f0ff, 0 7px 0 #cde; }

.rotated > .pages-tree-item {
  background-color: #e7f0ff;
  padding-bottom: 20px;
  transform-origin: 50% 50%;
  transform: rotate(-6deg); }
  .rotated > .pages-tree-item:before {
    display: none !important; }
  .rotated > .pages-tree-item .pages-quick-actions {
    display: none; }

.shadow .pages-tree {
  display: none; }

.shadow > .expander {
  display: none !important; }

.shadow > .pages-tree-item {
  background-color: #dfe5eb;
  border: 1px solid #cde; }
  .shadow > .pages-tree-item > * {
    display: none; }

section.dropdown-options > div.options > ol.list > li.option.pages-menu-quick-action {
  text-overflow: clip; }

section.dropdown-options.context-menu > div.options > ol.list {
  min-width: 144px; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) {
    height: 30px;
    line-height: 30px;
    padding: 0 18px; }
    section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) > .qa-label {
      padding-left: 9px;
      color: #3b4057;
      vertical-align: top; }
    section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) > .svg-container {
      width: 18px;
      display: inline-block; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option.psp-qa {
    height: 30px;
    line-height: 30px; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option.separator {
    padding: 6px 12px; }

section.dropdown-options.context-menu .symbol-quickActionsSettings {
  margin-top: 6px;
  margin-left: -1px; }

section.dropdown-options.context-menu .symbol-quickActionsHide,
section.dropdown-options.context-menu .symbol-quickActionsShow,
section.dropdown-options.context-menu .symbol-qaDuplicate,
section.dropdown-options.context-menu .symbol-qaDelete,
section.dropdown-options.context-menu .symbol-quickActionsRename,
section.dropdown-options.context-menu .symbol-qaPopupSettings {
  margin-top: 8px; }

section.dropdown-options.context-menu .symbol-quickActionsSubpage,
section.dropdown-options.context-menu .symbol-quickActionsMainPage {
  margin-left: 2px; }

.mobile-discoverability-modal.focus-panel-frame {
  width: 504px; }
  .mobile-discoverability-modal.focus-panel-frame.gif-preview header {
    padding-bottom: 41px; }
  .mobile-discoverability-modal.focus-panel-frame.gif-preview .panel-header-illustration .illustration {
    width: 234px;
    height: 144px; }
  .mobile-discoverability-modal.focus-panel-frame.live-preview .panel-header-illustration .preview-container {
    position: relative;
    width: 200px;
    height: 366px;
    margin: 0 auto;
    overflow: visible; }
    .mobile-discoverability-modal.focus-panel-frame.live-preview .panel-header-illustration .preview-container iframe {
      position: absolute;
      width: 320px;
      height: 476.8px;
      border: none;
      left: 0;
      top: 28px;
      transform: scale(0.625);
      transform-origin: 0 0; }
    .mobile-discoverability-modal.focus-panel-frame.live-preview .panel-header-illustration .preview-container .mobile-frame.previewMode {
      transform: scale(0.625);
      top: 28px; }
      .mobile-discoverability-modal.focus-panel-frame.live-preview .panel-header-illustration .preview-container .mobile-frame.previewMode .site-shadow {
        height: 477px; }
      .mobile-discoverability-modal.focus-panel-frame.live-preview .panel-header-illustration .preview-container .mobile-frame.previewMode .mobile-frame-bottom {
        bottom: -238px; }
  .mobile-discoverability-modal.focus-panel-frame footer {
    height: 78px; }
    .mobile-discoverability-modal.focus-panel-frame footer .dont-show-again {
      position: absolute;
      bottom: 32px;
      left: 24px; }
    .mobile-discoverability-modal.focus-panel-frame footer .footer-buttons {
      position: absolute;
      right: 24px;
      bottom: 24px; }

.box-slide-show-manage-slides-panel .add-new-state {
  position: relative;
  line-height: 26px;
  margin-top: 24px; }

.box-slide-show-manage-slides-panel .duplicate-state {
  position: relative;
  font-size: 16px;
  width: 100%; }

.add-state-action svg.symbol {
  width: 14px;
  height: 14px; }
  .add-state-action svg.symbol * {
    fill: #3b4057; }

.media-manager-panel {
  overflow: initial; }
  .media-manager-panel .left-panel-frame-content {
    display: flex;
    flex-direction: column;
    flex: 1; }
  .media-manager-panel__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    width: 354px;
    box-sizing: border-box; }
  .media-manager-panel__error-info-wrapper, .media-manager-panel__content-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden; }
  .media-manager-panel__content-inner {
    overflow: hidden; }
  .media-manager-panel__search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 18px 0;
    border-bottom: 1px solid #dfe5eb; }
    .media-manager-panel__search-box .media-manager-panel__search-wrapper {
      margin-top: 6px;
      transition: margin-top ease 300ms; }
    .media-manager-panel__search-box.sticky .media-manager-panel__extended-search-message {
      max-height: 0;
      opacity: 0; }
    .media-manager-panel__search-box.sticky .media-manager-panel__search-wrapper {
      margin-top: 0; }
  .media-manager-panel__extended-search-message {
    max-height: 20px;
    overflow: hidden;
    opacity: 1;
    transition-property: max-height, opacity;
    transition-duration: 300ms; }
  .media-manager-panel__search {
    width: 304px; }
  .media-manager-panel__scroll-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden; }
    .media-manager-panel__scroll-wrapper .content-wrapper {
      display: flex;
      flex-direction: column; }
    .media-manager-panel__scroll-wrapper .custom-scroll,
    .media-manager-panel__scroll-wrapper .outer-container {
      display: flex;
      flex-direction: column;
      flex: 1; }
  .media-manager-panel__sections {
    width: 354px;
    box-sizing: border-box; }
  .media-manager-panel__error-info-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 292px;
    margin: 10px auto auto;
    padding: 24px;
    box-sizing: border-box;
    text-align: center; }
  .media-manager-panel__error-info-wrapper {
    margin-top: 25px; }
    .media-manager-panel__error-info-wrapper .btn-text {
      font-size: 14px; }
  .media-manager-panel__error-title {
    margin-top: 18px; }
  .media-manager-panel__error-text {
    margin-top: 10px;
    color: #868aa5; }
  .media-manager-panel__list-upload-button-divider {
    flex: 1 0 auto; }
  .media-manager-panel__recommended-media-card {
    padding: 12px 18px; }

.bg-panel {
  background-color: #fff; }
  .bg-panel .left-panel-frame-content,
  .bg-panel .custom-scroll,
  .bg-panel .outer-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 352px; }

.tpa-settings-panel-frame {
  position: absolute;
  top: 32px;
  left: 78px; }

.classic-facelift-skin .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn .label {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.background-sub-panel .control-thumbnails input:checked + span {
  background-color: #e7f0ff;
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #116dff; }

.background-sub-panel .bg-settings-panel-scrollbox {
  min-height: 196px;
  max-height: calc( 100vh - 193px); }

.bg-apply-panel-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  border: 1px solid #a8caff;
  border-radius: 4px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  position: relative;
  margin-bottom: 5px;
  padding: 0 10px; }
  .bg-apply-panel-item.item-is-page {
    cursor: pointer;
    color: #000624; }
  .bg-apply-panel-item.current-page {
    background-color: #d6e6fe; }
  .bg-apply-panel-item.item-hidden {
    color: #868aa5; }
  .bg-apply-panel-item:not(.current-page).item-is-page:hover {
    background-color: #e7f0ff; }
  .bg-apply-panel-item.item-second-level {
    margin-left: 25px; }
    .bg-apply-panel-item.item-second-level:before {
      content: '';
      width: 11px;
      height: 0;
      top: 50%;
      left: -11px;
      border-top: 1px solid #a8caff;
      position: absolute; }
    .bg-apply-panel-item.item-second-level:after {
      content: '';
      width: 11px;
      height: 55px;
      top: -6px;
      left: -11px;
      border-left: 1px solid #a8caff;
      position: absolute; }
    .bg-apply-panel-item.item-second-level.item-second-level-last:after {
      height: 30px; }
  .bg-apply-panel-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .bg-apply-panel-item .page-preview {
    width: 50px;
    height: 34px;
    border: 1px solid #e7f0ff;
    margin: 0 10px; }
    .bg-apply-panel-item .page-preview .page-preview-video-controls {
      height: 34px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 65%, rgba(85, 85, 85, 0) 66%, #555555 100%);
      position: relative; }
      .bg-apply-panel-item .page-preview .page-preview-video-controls .symbol-bgVideoIndicator {
        width: 14px;
        height: 9px;
        left: 1px;
        bottom: 1px;
        position: absolute; }
  .bg-apply-panel-item .page-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }
  .bg-apply-panel-item .symbol-homePage {
    width: 13px;
    height: 10px; }
  .bg-apply-panel-item .symbol-hiddenPage {
    width: 17px;
    height: 15px; }

.bg-apply-panel .bg-apply-panel-wrapper {
  background-color: #f7f8f8; }

.bg-apply-panel .bg-apply-panel-description {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  padding: 20px 27px; }

.bg-apply-panel .bg-apply-panel-all {
  padding: 0 30px 10px; }

.bg-apply-panel .bg-apply-panel-scrollbox {
  min-height: 166px;
  max-height: calc( 100vh - 293px);
  padding: 0 18px 40px; }

.slideShow-apply-panel-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  border: 1px solid #a8caff;
  border-radius: 4px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  position: relative;
  margin-bottom: 5px;
  padding: 0 10px; }
  .slideShow-apply-panel-item.current-slide {
    background-color: #d6e6fe; }
  .slideShow-apply-panel-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .slideShow-apply-panel-item .slide-preview {
    width: 50px;
    height: 34px;
    border: 1px solid #e7f0ff;
    margin: 0 10px; }
    .slideShow-apply-panel-item .slide-preview .slide-preview-video-controls {
      height: 34px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 65%, rgba(85, 85, 85, 0) 66%, #555555 100%);
      position: relative; }
      .slideShow-apply-panel-item .slide-preview .slide-preview-video-controls .symbol-bgVideoIndicator {
        width: 14px;
        height: 9px;
        left: 1px;
        bottom: 1px;
        position: absolute; }
  .slideShow-apply-panel-item .slide-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }

.slide-show-apply-panel {
  z-index: 1441; }
  .slide-show-apply-panel .slide-show-apply-panel-wrapper {
    background-color: #f7f8f8; }
  .slide-show-apply-panel .slide-show-apply-panel-description {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    padding: 20px 27px; }
  .slide-show-apply-panel .slide-show-apply-panel-all {
    padding: 0 30px 10px; }
  .slide-show-apply-panel .slide-show-apply-panel-scrollbox {
    min-height: 166px;
    max-height: calc( 100vh - 293px);
    padding: 0 18px 40px; }

.popup-overlay-settings-panel .group-buttons-container {
  text-align: center; }

.editorVersionInfoPanel .content {
  max-height: 300px;
  width: auto;
  overflow-y: auto;
  padding: 12px; }
  .editorVersionInfoPanel .content .row {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px 0; }
    .editorVersionInfoPanel .content .row label {
      display: inline-block;
      min-width: 100px; }
    .editorVersionInfoPanel .content .row span {
      margin-left: 10px;
      font-weight: bold;
      -webkit-user-select: text;
              user-select: text; }

.editorVersionInfoPanel .panel-footer {
  padding: 5px 0; }
  .editorVersionInfoPanel .panel-footer .done-button {
    left: calc(100% - 80px);
    position: relative;
    height: 25px;
    line-height: 25px; }

.site-settings-panel {
  position: relative; }
  .site-settings-panel .hidden {
    display: none; }

.preloader-overlap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; }

.preview-holder {
  overflow: auto;
  overflow-x: hidden; }

@keyframes progress-indicator-anim {
  0% {
    width: 0; }
  50% {
    width: 100%; }
  100% {
    width: 0; } }

.top-bar-button {
  text-align: center;
  height: 48px;
  cursor: pointer; }
  .top-bar-button.top-bar-item-margin .top-bar-button-content {
    padding: 0 20px; }
  .top-bar-button .wrapper-to-perserve-ref {
    height: 100%; }
    .top-bar-button .wrapper-to-perserve-ref .control-button2-wrapper {
      max-height: 48px; }
  .top-bar-button:hover svg.button-symbol path {
    fill: #116dff; }
  .top-bar-button.disabled-button {
    cursor: default;
    color: #bcbcbc; }
    .top-bar-button.disabled-button:hover .top-bar-button-content path,
    .top-bar-button.disabled-button .top-bar-button-content path {
      fill: #bcbcbc; }
    .top-bar-button.disabled-button svg.button-symbol path {
      fill: #bcbcbc; }
  .top-bar-button.saved-button, .top-bar-button.in-progress {
    color: #868aa5;
    fill: #868aa5; }
    .top-bar-button.saved-button:hover svg.button-symbol path, .top-bar-button.in-progress:hover svg.button-symbol path {
      color: #116dff;
      fill: #116dff; }
  .top-bar-button .top-bar-button-content {
    height: 48px;
    width: 100%; }
    .top-bar-button .top-bar-button-content.icon-and-label:hover {
      color: #116dff; }
  .top-bar-button .button-symbol {
    height: 48px; }
  .top-bar-button .vSign {
    position: absolute;
    top: calc(50% - 14px / 2);
    left: calc(50% - 14px / 2);
    max-width: 14px;
    max-height: 14px;
    width: 14px;
    height: 14px; }
    .top-bar-button .vSign path {
      fill: #116dff; }
  .top-bar-button .invisible {
    visibility: hidden; }

.zoom-bar-wrapper {
  position: fixed;
  top: 0;
  z-index: 1510;
  font-size: 0;
  -webkit-user-select: none;
  user-select: none; }
  .zoom-bar-wrapper * {
    box-sizing: border-box; }
  .zoom-bar-wrapper .zoom-mode-bar {
    position: relative;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    left: -50%;
    white-space: nowrap; }
    .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar {
      display: inline-flex;
      position: relative;
      background-color: #25a55a;
      width: calc(100% - 3 * 55px);
      height: 49px;
      font-size: initial;
      align-items: center;
      padding: 0 49px 0 22px;
      cursor: pointer; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar:hover,
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar:hover > .symbol {
        background: #51b77b; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-text {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #ffffff; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-symbol {
        position: absolute;
        right: 0;
        top: 0;
        width: 55px;
        height: 49px; }
        .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-symbol .top-bar-button-content {
          width: inherit; }
    .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar {
      display: inline-block;
      background-color: #fff;
      vertical-align: top; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator,
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn {
        display: inline-block;
        width: 55px;
        height: 49px;
        vertical-align: top; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator:hover g,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn:hover g {
          fill: #116dff; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator:hover path,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn:hover path {
          fill: #116dff; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator .top-bar-button-content,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn .top-bar-button-content {
          width: inherit; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator {
        border-right: solid 1px #e3eaf6; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn .top-bar-button-content {
        padding: 0 16px; }

.dynamic-pages-combo-box {
  z-index: 1310;
  position: fixed;
  top: calc(12px + var(--g-top-bar-height));
  left: calc(100% - 390px);
  width: 380px;
  height: 42px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(22, 51, 70, 0.36);
  display: flex;
  align-items: center;
  cursor: move;
  -webkit-user-select: none;
  user-select: none; }
  .dynamic-pages-combo-box .inner-route-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dynamic-pages-combo-box .dragable-area {
    width: 30px;
    cursor: move;
    height: 100%;
    border-right: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    width: initial; }
    .dynamic-pages-combo-box .dragable-area .symbol {
      margin: 0 12px; }
  .dynamic-pages-combo-box .go-to-preview {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    height: 24px;
    width: 78px;
    margin: 0 12px; }
  .dynamic-pages-combo-box .combo-box-wrapper {
    padding: 0 1px;
    width: 246px;
    height: 100%;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    cursor: default; }
    .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-top: 0; }
      .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected,
      .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected.is-open {
        width: 239px;
        border: none;
        padding-left: 16px;
        margin: 0 2px;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased; }
        .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected > .selected-container > .selected-input,
        .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected.is-open > .selected-container > .selected-input {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 14px;
          -webkit-font-smoothing: antialiased; }
        .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected .symbol-arrow-down,
        .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected.is-open .symbol-arrow-down {
          border: none;
          padding-right: 11px; }
          .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected .symbol-arrow-down path,
          .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected.is-open .symbol-arrow-down path {
            fill: #000624; }
          .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected .symbol-arrow-down:hover path,
          .dynamic-pages-combo-box .combo-box-wrapper .dynamic-pages-combobox .dropdown-selected.is-open .symbol-arrow-down:hover path {
            fill: #116dff; }
  .dynamic-pages-combo-box.preview {
    width: 380px; }
    .dynamic-pages-combo-box.preview .combo-box-wrapper {
      width: 349px;
      padding-left: 1px; }
    .dynamic-pages-combo-box.preview .dragable-area {
      border-right: 1px solid #f0f0f0; }
    .dynamic-pages-combo-box.preview .dynamic-pages-combobox {
      width: 346px; }
      .dynamic-pages-combo-box.preview .dynamic-pages-combobox .dropdown-selected,
      .dynamic-pages-combo-box.preview .dynamic-pages-combobox .dropdown-selected.is-open {
        width: 346px; }

.dynamic-pages-options.preview .dropdown-options {
  max-width: 349px;
  max-height: 300px; }

.dynamic-pages-options .dropdown-options {
  max-width: 246px;
  max-height: 300px; }

.dynamic-pages-options .selected-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rootcomp-toolbar {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  position: fixed;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.94);
  width: 96px;
  z-index: 1055;
  right: 10px; }
  .rootcomp-toolbar.hidden {
    display: none; }
  .rootcomp-toolbar .header-seperator {
    margin: 0 0 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f7f8f8; }
  .rootcomp-toolbar .seperator {
    margin: 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f7f8f8; }
  .rootcomp-toolbar .header {
    height: 30px; }
    .rootcomp-toolbar .header .drag-zone {
      height: 30px;
      cursor: move;
      background: transparent;
      width: 100%; }
      .rootcomp-toolbar .header .drag-zone .drag-handle {
        left: calc(50% - 6px);
        position: absolute;
        top: 13px;
        color: #2d3034; }
        .rootcomp-toolbar .header .drag-zone .drag-handle > svg {
          display: block; }
    .rootcomp-toolbar .header .close {
      float: right;
      margin: 6px 7px 0 0;
      border: 0;
      background: transparent;
      padding: 7px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0; }
      .rootcomp-toolbar .header .close > svg {
        fill: #2d3034;
        display: block; }
      .rootcomp-toolbar .header .close:hover path {
        fill: #116dff; }
    .rootcomp-toolbar .header .help {
      font-size: 12px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      color: #2d3034;
      float: left;
      margin: 9px 0 0 13px;
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
      position: absolute;
      top: 0; }
      .rootcomp-toolbar .header .help:hover {
        color: #116dff; }
      .rootcomp-toolbar .header .help:focus {
        outline: none; }
  .rootcomp-toolbar:hover {
    background-color: white; }
    .rootcomp-toolbar:hover .inner.form .row .control-text-input > input {
      background: #e7f0ff; }
    .rootcomp-toolbar:hover .inner.form .row .input-stepper:not(.disabled) {
      background: #e7f0ff; }
  .rootcomp-toolbar .inner {
    text-align: center;
    display: block; }
    .rootcomp-toolbar .inner.button-grid {
      font-size: 0; }
      .rootcomp-toolbar .inner.button-grid .button,
      .rootcomp-toolbar .inner.button-grid button,
      .rootcomp-toolbar .inner.button-grid .control-button {
        display: inline-block;
        width: 33px;
        height: 33px;
        background-color: transparent;
        border: 0;
        border-radius: 6px;
        color: #2d3034;
        font-size: 15px;
        line-height: 33px;
        margin: 3px;
        padding: 0;
        vertical-align: top;
        min-width: initial;
        cursor: pointer;
        text-align: center; }
        .rootcomp-toolbar .inner.button-grid .button.selected svg > path,
        .rootcomp-toolbar .inner.button-grid button.selected svg > path,
        .rootcomp-toolbar .inner.button-grid .control-button.selected svg > path {
          fill: #116dff; }
        .rootcomp-toolbar .inner.button-grid .button:hover,
        .rootcomp-toolbar .inner.button-grid button:hover,
        .rootcomp-toolbar .inner.button-grid .control-button:hover {
          background-color: #e7f0ff; }
        .rootcomp-toolbar .inner.button-grid .button:active,
        .rootcomp-toolbar .inner.button-grid button:active,
        .rootcomp-toolbar .inner.button-grid .control-button:active {
          background-color: #5999ff; }
          .rootcomp-toolbar .inner.button-grid .button:active svg > path,
          .rootcomp-toolbar .inner.button-grid button:active svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button:active svg > path {
            fill: #fff; }
        .rootcomp-toolbar .inner.button-grid .button svg,
        .rootcomp-toolbar .inner.button-grid button svg,
        .rootcomp-toolbar .inner.button-grid .control-button svg {
          display: inline-block;
          position: relative; }
          .rootcomp-toolbar .inner.button-grid .button svg > path,
          .rootcomp-toolbar .inner.button-grid button svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button svg > path {
            fill: #2d3034; }
        .rootcomp-toolbar .inner.button-grid .button.disabled,
        .rootcomp-toolbar .inner.button-grid button.disabled,
        .rootcomp-toolbar .inner.button-grid .control-button.disabled {
          background-color: transparent;
          cursor: default; }
          .rootcomp-toolbar .inner.button-grid .button.disabled svg > path,
          .rootcomp-toolbar .inner.button-grid button.disabled svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button.disabled svg > path {
            fill: #bcbcbc; }
    .rootcomp-toolbar .inner.form {
      text-align: left;
      padding: 0 12px; }
      .rootcomp-toolbar .inner.form .row {
        width: 100%;
        height: 30px;
        display: flex;
        margin-bottom: 5px; }
        .rootcomp-toolbar .inner.form .row > span {
          line-height: 30px;
          color: #3b4057;
          padding: 0 7px 0 7px;
          font-size: 12px; }
          .rootcomp-toolbar .inner.form .row > span > svg {
            height: 85%; }
            .rootcomp-toolbar .inner.form .row > span > svg > path {
              fill: #3b4057; }
        .rootcomp-toolbar .inner.form .row:hover .input-stepper:not(.disabled) {
          background: #d6e6fe; }
        .rootcomp-toolbar .inner.form .row .input-stepper {
          flex: 1;
          float: right;
          padding: 0 2px !important;
          background-color: transparent; }
          .rootcomp-toolbar .inner.form .row .input-stepper > input {
            border: 0 solid transparent;
            color: #3b4057;
            padding: 5px 8px 4px 4px;
            text-align: right;
            transition: background 0.1s ease;
            will-change: background;
            background-color: rgba(250, 250, 250, 0); }
            .rootcomp-toolbar .inner.form .row .input-stepper > input:focus {
              background: rgba(250, 250, 250, 0); }
              .rootcomp-toolbar .inner.form .row .input-stepper > input:focus:hover {
                background: rgba(250, 250, 250, 0); }
          .rootcomp-toolbar .inner.form .row .input-stepper.disabled {
            opacity: 1; }
            .rootcomp-toolbar .inner.form .row .input-stepper.disabled > input {
              color: #bcbcbc !important; }
          .rootcomp-toolbar .inner.form .row .input-stepper.edited:after {
            border: 1px solid #5999ff; }
            .rootcomp-toolbar .inner.form .row .input-stepper.edited:after:hover {
              border: 1px solid #5999ff; }
          .rootcomp-toolbar .inner.form .row .input-stepper.degrees input {
            padding: 4px 0; }
        .rootcomp-toolbar .inner.form .row.show-on-all-pages-row {
          height: auto;
          display: block;
          padding-top: 5px; }
          .rootcomp-toolbar .inner.form .row.show-on-all-pages-row .control-switch {
            justify-content: center; }
          .rootcomp-toolbar .inner.form .row.show-on-all-pages-row .show-on-all-pages .symbol-switch {
            float: none; }
  .rootcomp-toolbar .boolean-control .label-checkbox {
    display: inline-block;
    cursor: pointer;
    color: #3b4057;
    font-size: 12px;
    margin: 0 0 0 6px;
    line-height: 14px;
    width: 51px;
    position: absolute;
    white-space: normal;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .rootcomp-toolbar .tool-selector {
    display: inline-block; }
    .rootcomp-toolbar .tool-selector .drop-down {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      border-radius: 4px;
      background-color: #fff;
      padding: 3px;
      width: 40px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-2-col {
        width: 82px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-3-col {
        width: 120px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-4-col {
        width: 166px; }
      .rootcomp-toolbar .tool-selector .drop-down .control-button {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin: 2px; }
    .rootcomp-toolbar .tool-selector.disabled .button {
      cursor: default; }
    .rootcomp-toolbar .tool-selector.disabled .button:hover,
    .rootcomp-toolbar .tool-selector.disabled .button:active {
      background-color: transparent !important; }
    .rootcomp-toolbar .tool-selector.disabled .small-indicator {
      border-bottom: 2px solid #bcbcbc !important;
      border-right: 2px solid #bcbcbc !important; }
    .rootcomp-toolbar .tool-selector .small-indicator {
      box-sizing: border-box;
      width: 4px;
      height: 4px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-bottom: 2px solid #116dff;
      border-right: 2px solid #116dff;
      position: absolute;
      bottom: 6px;
      right: 6px; }
  .rootcomp-toolbar .layout-title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    display: block;
    text-align: center;
    color: #3b4057;
    line-height: 24px;
    overflow: hidden; }
    .rootcomp-toolbar .layout-title.show-on-all-pages-title {
      line-height: normal; }
  .rootcomp-toolbar .layers-panel-button-container {
    text-align: center; }
  .rootcomp-toolbar .layers-panel-button {
    color: #116dff;
    cursor: pointer;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    width: 64px;
    height: 21px;
    border: 0;
    border-radius: 100px;
    background-color: #e7f0ff;
    margin-bottom: 10px;
    outline: none; }
    .rootcomp-toolbar .layers-panel-button:hover {
      color: #116dff;
      background-color: #d6e6fe; }

.left-bar-items {
  display: inline-block; }
  .left-bar-items .left-bar-item {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(to bottom, #fff 0%, rgba(238, 238, 238, 0.96) 100%);
    box-shadow: 0 2px 5px 0 rgba(22, 45, 61, 0.58);
    border-radius: 24px;
    line-height: 48px;
    height: 48px;
    position: relative;
    cursor: pointer;
    padding-right: 46px;
    margin-bottom: 12px;
    clear: left;
    z-index: 1050; }
    .left-bar-items .left-bar-item.app-manager-panel.selected svg.ear path {
      fill: #5a48f5; }
    .left-bar-items .left-bar-item.app-manager-panel svg.icon {
      top: 8px;
      left: 8px; }
      .left-bar-items .left-bar-item.app-manager-panel svg.icon path {
        fill: #5a48f5; }
    .left-bar-items .left-bar-item.app-manager-panel-new.selected svg.ear path {
      fill: #1b3689; }
    .left-bar-items .left-bar-item.app-manager-panel-new svg.icon {
      top: 8px;
      left: 8px; }
      .left-bar-items .left-bar-item.app-manager-panel-new svg.icon path {
        fill: #1b3689; }
    .left-bar-items .left-bar-item.am-leftBarAppManagerFitnessIcon.selected svg.ear path {
      fill: #5a48f5; }
    .left-bar-items .left-bar-item.am-leftBarAppManagerFitnessIcon svg.icon {
      top: 8px;
      left: 8px; }
      .left-bar-items .left-bar-item.am-leftBarAppManagerFitnessIcon svg.icon path {
        fill: #5a48f5; }
    .left-bar-items .left-bar-item.pages-panel-pp.selected svg.ear path {
      fill: #00aaaf; }
    .left-bar-items .left-bar-item.pages-panel-pp svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.pages-panel-pp svg.icon path {
        fill: #00aaaf; }
    .left-bar-items .left-bar-item.theme-panel.selected svg.ear path {
      fill: #003790; }
    .left-bar-items .left-bar-item.theme-panel svg.icon {
      top: 7px;
      left: 8px; }
      .left-bar-items .left-bar-item.theme-panel svg.icon path {
        fill: #003790; }
    .left-bar-items .left-bar-item.bg-panel.selected svg.ear path {
      fill: #f8a239; }
    .left-bar-items .left-bar-item.bg-panel svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.bg-panel svg.icon path {
        fill: #f8a239; }
    .left-bar-items .left-bar-item.add-panel.selected svg.ear path {
      fill: #3899ec; }
    .left-bar-items .left-bar-item.add-panel svg.icon {
      top: 11px;
      left: 11px; }
      .left-bar-items .left-bar-item.add-panel svg.icon path {
        fill: #3899ec; }
    .left-bar-items .left-bar-item.app-market-panel.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-20.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-20 svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel-20 svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-new.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-new svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel-new svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-no-header.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-no-header svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel-no-header svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.my-uploads-panel.selected svg.ear path {
      fill: #fa7c33; }
    .left-bar-items .left-bar-item.my-uploads-panel svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.my-uploads-panel svg.icon path {
        fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel.selected svg.ear path {
      fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.media-manager-panel svg.icon path {
        fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel-icon-b.selected svg.ear path {
      fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel-icon-b svg.icon {
      top: 11px;
      left: 11px; }
      .left-bar-items .left-bar-item.media-manager-panel-icon-b svg.icon path {
        fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel-icon-c.selected svg.ear path {
      fill: #fa7c33; }
    .left-bar-items .left-bar-item.media-manager-panel-icon-c svg.icon {
      top: 13px;
      left: 13px; }
      .left-bar-items .left-bar-item.media-manager-panel-icon-c svg.icon path {
        fill: #fa7c33; }
    .left-bar-items .left-bar-item.wix-data-panel.selected svg.ear path {
      fill: #00A65F; }
    .left-bar-items .left-bar-item.wix-data-panel svg.icon {
      top: 12px;
      left: 14px; }
      .left-bar-items .left-bar-item.wix-data-panel svg.icon path {
        fill: #00A65F; }
    .left-bar-items .left-bar-item.wix-data-panel-icon-b.selected svg.ear path {
      fill: #00A65F; }
    .left-bar-items .left-bar-item.wix-data-panel-icon-b svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.wix-data-panel-icon-b svg.icon path {
        fill: #00A65F; }
    .left-bar-items .left-bar-item.interactions-hidden-components.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.interactions-hidden-components svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.interactions-hidden-components svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.blog-panel.selected svg.ear path {
      fill: #25a55a; }
    .left-bar-items .left-bar-item.blog-panel svg.icon {
      top: 13px;
      left: 10px; }
      .left-bar-items .left-bar-item.blog-panel svg.icon path {
        fill: #25a55a; }
    .left-bar-items .left-bar-item.new-blog-panel.selected svg.ear path {
      fill: #faad4d; }
    .left-bar-items .left-bar-item.new-blog-panel svg.icon {
      top: 9px;
      left: 15px; }
      .left-bar-items .left-bar-item.new-blog-panel svg.icon path {
        fill: #faad4d; }
    .left-bar-items .left-bar-item.wix-store-panel.selected svg.ear path {
      fill: #00adf5; }
    .left-bar-items .left-bar-item.wix-store-panel svg.icon {
      top: 9px;
      left: 13px; }
      .left-bar-items .left-bar-item.wix-store-panel svg.icon path {
        fill: #00adf5; }
    .left-bar-items .left-bar-item.wix-bookings-panel.selected svg.ear path {
      fill: #6C48EF; }
    .left-bar-items .left-bar-item.wix-bookings-panel svg.icon {
      top: 9px;
      left: 11px; }
      .left-bar-items .left-bar-item.wix-bookings-panel svg.icon path {
        fill: #6C48EF; }
    .left-bar-items .left-bar-item.hidden-items-panel.selected svg.ear path {
      fill: #d0427d; }
    .left-bar-items .left-bar-item.hidden-items-panel svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.hidden-items-panel svg.icon path {
        fill: #d0427d; }
    .left-bar-items .left-bar-item.mobile-elements-panel.selected svg.ear path {
      fill: #3899eb; }
    .left-bar-items .left-bar-item.mobile-elements-panel svg.icon {
      top: 11px;
      left: 12px; }
      .left-bar-items .left-bar-item.mobile-elements-panel svg.icon path {
        fill: #3899eb; }
    .left-bar-items .left-bar-item.layout-optimizer-panel.selected svg.ear path {
      fill: #45c8c1; }
    .left-bar-items .left-bar-item.layout-optimizer-panel svg.icon {
      top: 13px;
      left: 13px; }
      .left-bar-items .left-bar-item.layout-optimizer-panel svg.icon path {
        fill: #45c8c1; }
    .left-bar-items .left-bar-item.ascend-panel.selected svg.ear path {
      fill: #1463DA; }
    .left-bar-items .left-bar-item.ascend-panel svg.icon {
      top: 11px;
      left: 11px; }
      .left-bar-items .left-bar-item.ascend-panel svg.icon path {
        fill: #1463DA; }
    .left-bar-items .left-bar-item:hover {
      background: linear-gradient(to bottom, white 0%, #eeeeee 100%); }
      .left-bar-items .left-bar-item:hover svg.icon path {
        fill: #2d3034; }
    .left-bar-items .left-bar-item svg.icon {
      position: absolute; }
    .left-bar-items .left-bar-item .mask {
      width: 4px;
      position: absolute;
      left: 58px;
      height: 77px;
      top: -13px; }
    .left-bar-items .left-bar-item.first-panel .mask {
      top: 0;
      height: 67px; }
    .left-bar-items .left-bar-item .text {
      padding-left: 48px;
      color: #000624;
      white-space: nowrap;
      display: inline-block; }
    .left-bar-items .left-bar-item .notifications {
      position: absolute;
      top: -3px;
      right: 0;
      transform: translate3d(50%, 0, 0);
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      background: #e62214;
      color: #fff;
      padding: 4px;
      font-size: 13px;
      display: block;
      text-align: center;
      line-height: 13px;
      min-width: 13px;
      height: 13px;
      border-radius: 11px; }
  .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item:not(.selected):hover, .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.force-hover {
    width: inherit;
    z-index: 1060; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item:not(.selected):hover .text, .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.force-hover .text {
      display: inline-block;
      width: auto;
      padding-right: 18px; }
  .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click {
    width: inherit;
    display: inline-block;
    float: left;
    clear: left; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click .text {
      display: inline-block;
      width: auto;
      padding-right: 18px; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-manager-panel:not(.selected) svg.icon path {
      fill: #5a48f5; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-manager-panel-new:not(.selected) svg.icon path {
      fill: #1b3689; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.am-leftBarAppManagerFitnessIcon:not(.selected) svg.icon path {
      fill: #5a48f5; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.pages-panel-pp:not(.selected) svg.icon path {
      fill: #00aaaf; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.theme-panel:not(.selected) svg.icon path {
      fill: #003790; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.bg-panel:not(.selected) svg.icon path {
      fill: #f8a239; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.add-panel:not(.selected) svg.icon path {
      fill: #3899ec; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel-20:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel-new:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel-no-header:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.my-uploads-panel:not(.selected) svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.media-manager-panel:not(.selected) svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.media-manager-panel-icon-b:not(.selected) svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.media-manager-panel-icon-c:not(.selected) svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-data-panel:not(.selected) svg.icon path {
      fill: #00A65F; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-data-panel-icon-b:not(.selected) svg.icon path {
      fill: #00A65F; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.interactions-hidden-components:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.blog-panel:not(.selected) svg.icon path {
      fill: #25a55a; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.new-blog-panel:not(.selected) svg.icon path {
      fill: #faad4d; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-store-panel:not(.selected) svg.icon path {
      fill: #00adf5; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-bookings-panel:not(.selected) svg.icon path {
      fill: #6C48EF; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.hidden-items-panel:not(.selected) svg.icon path {
      fill: #d0427d; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.mobile-elements-panel:not(.selected) svg.icon path {
      fill: #3899eb; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.layout-optimizer-panel:not(.selected) svg.icon path {
      fill: #45c8c1; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.ascend-panel:not(.selected) svg.icon path {
      fill: #1463DA; }
  .left-bar-items.menu-collapsed .left-bar-item {
    width: 48px;
    padding-right: 0; }
    .left-bar-items.menu-collapsed .left-bar-item .text {
      display: none; }
    .left-bar-items.menu-collapsed .left-bar-item svg.icon path {
      fill: #2d3034; }
    .left-bar-items.menu-collapsed .left-bar-item.selected {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      z-index: 1150; }
      .left-bar-items.menu-collapsed .left-bar-item.selected svg.icon path {
        fill: #fff; }
      .left-bar-items.menu-collapsed .left-bar-item.selected .notifications {
        display: none; }
    .left-bar-items.menu-collapsed .left-bar-item.app-manager-panel:not(.selected):hover svg.icon path {
      fill: #5a48f5; }
    .left-bar-items.menu-collapsed .left-bar-item.app-manager-panel.selected .mask {
      background-color: #5a48f5; }
    .left-bar-items.menu-collapsed .left-bar-item.app-manager-panel-new:not(.selected):hover svg.icon path {
      fill: #1b3689; }
    .left-bar-items.menu-collapsed .left-bar-item.app-manager-panel-new.selected .mask {
      background-color: #1b3689; }
    .left-bar-items.menu-collapsed .left-bar-item.am-leftBarAppManagerFitnessIcon:not(.selected):hover svg.icon path {
      fill: #5a48f5; }
    .left-bar-items.menu-collapsed .left-bar-item.am-leftBarAppManagerFitnessIcon.selected .mask {
      background-color: #5a48f5; }
    .left-bar-items.menu-collapsed .left-bar-item.pages-panel-pp:not(.selected):hover svg.icon path {
      fill: #00aaaf; }
    .left-bar-items.menu-collapsed .left-bar-item.pages-panel-pp.selected .mask {
      background-color: #00aaaf; }
    .left-bar-items.menu-collapsed .left-bar-item.theme-panel:not(.selected):hover svg.icon path {
      fill: #003790; }
    .left-bar-items.menu-collapsed .left-bar-item.theme-panel.selected .mask {
      background-color: #003790; }
    .left-bar-items.menu-collapsed .left-bar-item.bg-panel:not(.selected):hover svg.icon path {
      fill: #f8a239; }
    .left-bar-items.menu-collapsed .left-bar-item.bg-panel.selected .mask {
      background-color: #f8a239; }
    .left-bar-items.menu-collapsed .left-bar-item.add-panel:not(.selected):hover svg.icon path {
      fill: #3899ec; }
    .left-bar-items.menu-collapsed .left-bar-item.add-panel.selected .mask {
      background-color: #3899ec; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-20:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-20.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-new:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-new.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-no-header:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-no-header.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.my-uploads-panel:not(.selected):hover svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.my-uploads-panel.selected .mask {
      background-color: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel:not(.selected):hover svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel.selected .mask {
      background-color: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel-icon-b:not(.selected):hover svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel-icon-b.selected .mask {
      background-color: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel-icon-c:not(.selected):hover svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.media-manager-panel-icon-c.selected .mask {
      background-color: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-data-panel:not(.selected):hover svg.icon path {
      fill: #00A65F; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-data-panel.selected .mask {
      background-color: #00A65F; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-data-panel-icon-b:not(.selected):hover svg.icon path {
      fill: #00A65F; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-data-panel-icon-b.selected .mask {
      background-color: #00A65F; }
    .left-bar-items.menu-collapsed .left-bar-item.interactions-hidden-components:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.interactions-hidden-components.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.blog-panel:not(.selected):hover svg.icon path {
      fill: #25a55a; }
    .left-bar-items.menu-collapsed .left-bar-item.blog-panel.selected .mask {
      background-color: #25a55a; }
    .left-bar-items.menu-collapsed .left-bar-item.new-blog-panel:not(.selected):hover svg.icon path {
      fill: #faad4d; }
    .left-bar-items.menu-collapsed .left-bar-item.new-blog-panel.selected .mask {
      background-color: #faad4d; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-store-panel:not(.selected):hover svg.icon path {
      fill: #00adf5; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-store-panel.selected .mask {
      background-color: #00adf5; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-bookings-panel:not(.selected):hover svg.icon path {
      fill: #6C48EF; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-bookings-panel.selected .mask {
      background-color: #6C48EF; }
    .left-bar-items.menu-collapsed .left-bar-item.hidden-items-panel:not(.selected):hover svg.icon path {
      fill: #d0427d; }
    .left-bar-items.menu-collapsed .left-bar-item.hidden-items-panel.selected .mask {
      background-color: #d0427d; }
    .left-bar-items.menu-collapsed .left-bar-item.mobile-elements-panel:not(.selected):hover svg.icon path {
      fill: #3899eb; }
    .left-bar-items.menu-collapsed .left-bar-item.mobile-elements-panel.selected .mask {
      background-color: #3899eb; }
    .left-bar-items.menu-collapsed .left-bar-item.layout-optimizer-panel:not(.selected):hover svg.icon path {
      fill: #45c8c1; }
    .left-bar-items.menu-collapsed .left-bar-item.layout-optimizer-panel.selected .mask {
      background-color: #45c8c1; }
    .left-bar-items.menu-collapsed .left-bar-item.ascend-panel:not(.selected):hover svg.icon path {
      fill: #1463DA; }
    .left-bar-items.menu-collapsed .left-bar-item.ascend-panel.selected .mask {
      background-color: #1463DA; }
  .left-bar-items.menu-collapsed svg.ear {
    top: -20px;
    position: absolute;
    left: -9px; }
    .left-bar-items.menu-collapsed svg.ear.ear-top {
      top: -4px;
      left: -5px; }

.left-bar {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 32px;
  left: 12px;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) left, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) opacity; }
  .left-bar.is-hidden {
    transition-duration: 0.4s; }
    .left-bar.is-hidden .mask {
      display: none; }
  .left-bar.half-opacity {
    opacity: 0.5; }

.opened-panels {
  -webkit-user-select: none;
  user-select: none; }

.mouseCatcher.parentSize, .mouseCatcher.parentSizeAbs {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.mouseCatcher.parentSizeAbs {
  position: absolute; }

.mouseCatcher.draggable {
  cursor: move; }

.mouseCatcher > .margins-indicator {
  position: absolute; }
  .mouseCatcher > .margins-indicator > .left,
  .mouseCatcher > .margins-indicator > .right {
    background: rgba(0, 6, 36, 0.2) url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/dead-areas-indication-pattern.v4.png") repeat;
    height: 100%;
    position: absolute; }
  .mouseCatcher > .margins-indicator > .left {
    left: 0; }
  .mouseCatcher > .margins-indicator > .right {
    right: 0; }

.editor-stage {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
  .editor-stage.tools-hidden {
    transition-duration: 0.4s; }
  .sections-experience .editor-stage .site-cover > *:not(.block-layer),
  .sections-experience .editor-stage .fixed-editor-stage > *:not(.block-layer) {
    opacity: 1;
    transition: opacity 0.2s; }
  .editor-stage.zoomOutTransitioning .site-cover > *:not(.block-layer),
  .editor-stage.zoomOutTransitioning .fixed-editor-stage > *:not(.block-layer) {
    opacity: 0;
    transition: none; }
  .editor-stage .site-cover {
    position: relative;
    min-height: 100%;
    overflow: hidden; }
    .sections-experience .mobile-editor .editor-stage .site-cover {
      overflow: clip; }
    .sections-experience .editor-stage .site-cover.siteShadow {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08); }
  .editor-stage .scrollable-editor-stage {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden; }
    .editor-stage .scrollable-editor-stage.main-thread-scroll {
      background-attachment: fixed;
      background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==); }
    .editor-stage .scrollable-editor-stage.preview-mode, .editor-stage .scrollable-editor-stage.scrolling-disabled {
      overflow: hidden; }
    .editor-stage .scrollable-editor-stage .scrollable-editor-stage-content {
      height: 100%; }
  .editor-stage .fixed-editor-stage {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    visibility: hidden; }
    .sections-experience .editor-stage .fixed-editor-stage .rulers {
      z-index: 3; }
    .editor-stage .fixed-editor-stage .fixed-editor-stage-contents-wrapper {
      pointer-events: auto;
      visibility: visible; }
      .editor-stage .fixed-editor-stage .fixed-editor-stage-contents-wrapper.no-pointer-events {
        pointer-events: none; }
  .editor-stage .mobile-optimized-teaser {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    z-index: 1;
    bottom: -66px;
    left: 12px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 16px 0 rgba(57, 86, 113, 0.24);
    width: 238px;
    height: 115px;
    pointer-events: auto;
    padding: 3px 17px 14px 23px;
    text-align: left;
    cursor: default;
    color: #3b4057; }
    .sections-experience .editor-stage .mobile-optimized-teaser {
      z-index: 3; }
    .editor-stage .mobile-optimized-teaser h1 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased; }
    .editor-stage .mobile-optimized-teaser a {
      color: #116dff;
      cursor: pointer; }
    .classic-facelift-skin .editor-stage .mobile-optimized-teaser {
      bottom: 0; }

.language-change-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: #fff;
  opacity: 0;
  transition: opacity 0.8s, height 0s 0.8s, width 0s 0.8s;
  transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1); }
  .language-change-overlay.active {
    width: 100%;
    height: 100%;
    opacity: 0.9;
    transition: opacity 0.4s;
    transition-timing-function: cubic-bezier(0.1, 0.5, 0.6, 1); }
    .language-change-overlay.active .preloader {
      display: block; }
  .language-change-overlay .preloader {
    display: none;
    width: 36px;
    height: 72px;
    position: absolute;
    left: 50%;
    top: calc(50vh - 72px); }

.mobile-editor .language-change-overlay .preloader {
  top: calc(50vh - 131px); }

#gfpp {
  -webkit-user-select: none;
  user-select: none;
  height: 34px;
  white-space: nowrap;
  color: #000;
  text-align: left;
  z-index: 1058; }
  #gfpp .gfpp-btn-base, #gfpp .gfpp-btn {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    box-sizing: border-box;
    height: 32px;
    background: linear-gradient(to bottom, white 0%, #eeeeee 100%);
    box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.55);
    border-radius: 16px; }
  #gfpp .gfpp-btn {
    min-width: 32px;
    cursor: pointer;
    position: relative;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0 7px 0 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    vertical-align: top; }
    #gfpp .gfpp-btn:not(.gfpp-btn_ai-icon_animation) {
      color: #2d3034; }
    #gfpp .gfpp-btn:last-child {
      margin-right: 0; }
    #gfpp .gfpp-btn.gfpp-pin svg path {
      fill: #fe620f; }
    #gfpp .gfpp-btn.connected:not(.selected) svg path {
      fill: #25a55a; }
    #gfpp .gfpp-btn.applied:not(.selected) svg path {
      fill: #25a55a; }
    #gfpp .gfpp-btn.applied:not(.selected):hover svg path {
      fill: #51b77b; }
    #gfpp .gfpp-btn.disabled, #gfpp .gfpp-btn.disabled:hover {
      color: #898989;
      cursor: default; }
      #gfpp .gfpp-btn.disabled svg *, #gfpp .gfpp-btn.disabled:hover svg * {
        fill: #898989; }
    #gfpp .gfpp-btn:not(.gfpp-btn_ai-icon_animation) svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      #gfpp .gfpp-btn:not(.gfpp-btn_ai-icon_animation) svg * {
        fill: #2d3034; }
    #gfpp .gfpp-btn:hover:not(.gfpp-btn_ai-icon_animation) {
      color: #116dff; }
      #gfpp .gfpp-btn:hover:not(.gfpp-btn_ai-icon_animation) svg * {
        fill: #116dff; }
    #gfpp .gfpp-btn.selected {
      box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.65); }
      #gfpp .gfpp-btn.selected:not(.gfpp-btn_label):not(.with-background-icon):not(.gfpp-btn_ai-icon_animation) {
        background: #116dff;
        color: #fff;
        border: 2px solid #fff;
        line-height: 30px; }
        #gfpp .gfpp-btn.selected:not(.gfpp-btn_label):not(.with-background-icon):not(.gfpp-btn_ai-icon_animation) svg * {
          fill: #fff; }
        #gfpp .gfpp-btn.selected:not(.gfpp-btn_label):not(.with-background-icon):not(.gfpp-btn_ai-icon_animation) .gfpp-label {
          padding: 0 11px; }
      #gfpp .gfpp-btn.selected.gfpp-btn_label {
        color: #116dff; }
    #gfpp .gfpp-btn.more-btn {
      margin: 0 5px 0 0;
      left: 0;
      top: 0;
      cursor: default;
      position: absolute; }
      #gfpp .gfpp-btn.more-btn span {
        display: inline-block; }
    #gfpp .gfpp-btn .gfpp-label {
      padding: 0 13px; }
    #gfpp .gfpp-btn .gfpp-icon {
      font-size: 18px; }
  .classic-facelift-skin #gfpp .gfpp-btn {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #2d3034;
    background: #fff;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 0 4px 0 rgba(0, 0, 0, 0.1); }
    .classic-facelift-skin #gfpp .gfpp-btn:not(.gfpp-btn_ai-icon_animation) svg * {
      fill: #2d3034; }
    .classic-facelift-skin #gfpp .gfpp-btn:hover:not(.gfpp-btn_ai-icon_animation) {
      color: #5999ff; }
      .classic-facelift-skin #gfpp .gfpp-btn:hover:not(.gfpp-btn_ai-icon_animation) svg * {
        fill: #116dff; }
    .classic-facelift-skin #gfpp .gfpp-btn.selected:not(.gfpp-btn_label):not(.with-background-icon):not(.gfpp-btn_ai-icon_animation) {
      background: #116dff;
      color: #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.selected:not(.gfpp-btn_label):not(.with-background-icon):not(.gfpp-btn_ai-icon_animation) svg * {
        fill: #fff; }
    .classic-facelift-skin #gfpp .gfpp-btn.selected.gfpp-btn_label {
      background: #fff;
      color: #116dff; }
    .classic-facelift-skin #gfpp .gfpp-btn.disabled, .classic-facelift-skin #gfpp .gfpp-btn.disabled:hover {
      color: #939393; }
      .classic-facelift-skin #gfpp .gfpp-btn.disabled svg *, .classic-facelift-skin #gfpp .gfpp-btn.disabled:hover svg * {
        fill: #898989; }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon {
      background: linear-gradient(52.63deg, #6f56f9 18.28%, #116dff 51.81%, #18d2de 87.19%);
      border: 2px solid #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon svg * {
        fill: #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon:hover {
        background: linear-gradient(52.63deg, #9fadf5 18.28%, #71ace6 51.81%, #82e7e6 87.19%); }
        .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon:hover svg * {
          fill: #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon.selected {
        background: linear-gradient(52.63deg, #6f56f9 18.28%, #116dff 51.81%, #18d2de 87.19%); }
        .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon.selected svg * {
          fill: #fff; }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation.selected {
      background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackground.v2.svg");
      background-size: contain;
      border: 2px solid #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation.selected svg * {
        fill: #fff; }
      .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation.selected:hover {
        background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackgroundHover.svg");
        background-size: contain; }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation:hover .around-shapes {
      animation: circle-shapes-rotate 6s infinite;
      animation-timing-function: linear;
      opacity: 1; }

@keyframes circle-shapes-rotate {
  0% {
    rotate: 0deg; }
  30% {
    transform: translateX(-6px); }
  70% {
    opacity: 0.6;
    transform: translateX(-6px); }
  100% {
    opacity: 1;
    rotate: 360deg; } }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation:hover .ai-background {
      animation: bg-rotate 10s infinite;
      animation-timing-function: linear;
      transform-origin: center;
      transform-box: fill-box;
      rotate: 0deg; }

@keyframes bg-rotate {
  0% {
    rotate: 0deg; }
  40% {
    transform: translateX(8px); }
  80% {
    transform: translateY(-2px); }
  100% {
    rotate: 350deg; } }
    .classic-facelift-skin #gfpp .gfpp-btn.gfpp-btn_ai-icon_animation .around-shapes {
      opacity: 1;
      transform-origin: center;
      transform-box: fill-box;
      rotate: 0deg; }
  #gfpp .with-background-icon {
    display: inline-flex; }
    #gfpp .with-background-icon .gfpp-label {
      padding: 0 13px 0 6px; }

.rightClickMenu {
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  z-index: 6000;
  position: absolute;
  border-radius: 4px;
  padding: 14px 0 16px 0;
  background-color: rgba(255, 255, 255, 0.95);
  color: #1d2d3c; }
  .rightClickMenu .subMenu .with-wbu-icons {
    --item-padding: 20px;
    --basic-item-icon-container-size: 24px; }
  .rightClickMenu .rcmStageContentWrapper {
    max-height: calc( 100vh - var(--g-top-bar-height) - (20px * 2) - 14px - 16px);
    scrollbar-width: none; }
    .rightClickMenu .rcmStageContentWrapper::-webkit-scrollbar {
      width: 0; }
    .rightClickMenu .rcmStageContentWrapper.scrollable {
      overflow-y: scroll;
      overflow-x: hidden; }
  .rightClickMenu .scroll-arrow-up,
  .rightClickMenu .scroll-arrow-down {
    background-color: rgba(255, 255, 255, 0.93);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    left: 0;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 1; }
    .rightClickMenu .scroll-arrow-up svg,
    .rightClickMenu .scroll-arrow-down svg {
      fill: #116dff; }
  .rightClickMenu .scroll-arrow-up {
    top: 0;
    transform: rotate(180deg); }
  .rightClickMenu .scroll-arrow-down {
    bottom: 0; }
  .rightClickMenu.fixed-position {
    position: fixed; }
  .rightClickMenu ul:after {
    content: '';
    height: 1px;
    display: block;
    border: 0;
    background-color: #dfe5eb;
    margin: 10px 24px 12px 24px; }
  .rightClickMenu ul:last-of-type:after {
    display: none; }
  .rightClickMenu li {
    line-height: 25px;
    height: 25px;
    padding-left: var(--item-padding, 24px);
    padding-right: var(--item-padding, 24px);
    cursor: pointer; }
    .rightClickMenu li:hover > .subMenu {
      display: block; }
    .rightClickMenu li.disabled {
      color: #bababa;
      cursor: default; }
    .rightClickMenu li .show-on-all-pages-row {
      width: 100%; }
  .rightClickMenu .flex {
    display: flex; }
  .rightClickMenu .hoverable:hover {
    background-color: #daedfe; }
  .rightClickMenu .subMenu {
    display: none;
    position: absolute;
    left: 100%;
    margin-left: -10px;
    background-color: white;
    z-index: 1; }
  .rightClickMenu .icon {
    line-height: initial; }
    .rightClickMenu .icon + .label {
      margin-left: 10px; }
  .rightClickMenu .item-basic-flex {
    display: flex;
    align-items: center; }
  .rightClickMenu .item-basic .icon svg {
    width: var(--basic-item-icon-container-size, 14px);
    height: var(--basic-item-icon-container-size, 14px); }
  .rightClickMenu .item-basic .icon span {
    position: absolute;
    width: var(--basic-item-icon-container-size, 14px);
    height: var(--basic-item-icon-container-size, 14px);
    border-radius: 2px; }
  .rightClickMenu .item-basic .with-custom-icon-size {
    display: flex;
    justify-content: center;
    align-items: center; }
    .rightClickMenu .item-basic .with-custom-icon-size .image {
      display: inline-block;
      position: relative; }
  .rightClickMenu .item-shortcut-flex {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .rightClickMenu .item-shortcut .icon {
    width: 14px;
    height: 14px; }
    .rightClickMenu .item-shortcut .icon svg {
      width: 14px;
      height: 14px; }
  .rightClickMenu .label {
    font-size: 13px;
    white-space: nowrap; }
  .rightClickMenu .label-sub-item {
    margin-right: 46px; }
  .rightClickMenu .label-toggle {
    cursor: default;
    margin-right: 86px; }
  .rightClickMenu .label-shortcut {
    margin-right: 96px; }
  .rightClickMenu .label-shortcut-small {
    margin-right: 52px; }
  .rightClickMenu .action-relative {
    position: relative;
    white-space: nowrap; }
  .rightClickMenu .action {
    bottom: -1px; }
    .rightClickMenu .action .symbol.symbol-switch svg {
      position: relative;
      left: 8px; }
  .rightClickMenu .action-shortcut {
    text-align: right;
    font-size: 10px;
    line-height: 25px;
    height: 25px; }
  .rightClickMenu .margin-left-auto {
    margin-left: auto; }
  .rightClickMenu .action-expand {
    width: 4px;
    height: 4px;
    top: 50%;
    margin-top: -2px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-right: 1px solid; }
  .rightClickMenu.open-sub-menu-left .subMenu {
    left: inherit;
    right: 100%; }
  .rightClickMenu .divider {
    margin-top: 12px;
    margin-bottom: 12px; }
  .rightClickMenu .layers-divider-wrapper.with-margins,
  .rightClickMenu .addons-divider-wrapper {
    margin: 6px 0; }
  .rightClickMenu .subMenu .with-wbu-icons .icon {
    display: flex;
    justify-content: center;
    align-items: center; }
  .rightClickMenu .subMenu .with-wbu-icons .label {
    margin-left: 6px; }
  .rightClickMenu .subMenu .with-wbu-icons .with-custom-icon-size .image {
    width: 15px;
    height: 15px; }

.recommended-mobile-header-height {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .recommended-mobile-header-height > .header-footer-info-tooltip {
    position: absolute;
    background: #f7f8f8;
    padding-right: 25px;
    max-width: 200px;
    height: 28px;
    pointer-events: auto; }
    .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content {
      display: flex;
      align-items: center; }
      .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .recommended-mobile-header-height-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        line-height: 14px;
        margin-left: 10px;
        text-align: start;
        color: #868aa5; }
      .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .info-icon {
        fill: #868aa5; }
        .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .info-icon circle {
          fill: transparent; }
  .recommended-mobile-header-height > svg.header-recommended-height-separator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .recommended-mobile-header-height > svg.header-recommended-height-separator > line.header-recommended-height-separator {
      stroke-width: 1;
      stroke: #868aa5;
      stroke-dasharray: 10 5; }
      .classic-facelift-skin .recommended-mobile-header-height > svg.header-recommended-height-separator > line.header-recommended-height-separator {
        stroke-width: 0.5px;
        stroke: #bcbcbc;
        stroke-dasharray: 0; }

.sections-reorganize-exp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .sections-reorganize-exp .spacer-handle {
    pointer-events: auto;
    position: relative;
    height: 12px;
    background-color: #116dff;
    margin: 0 auto;
    cursor: row-resize;
    display: flex;
    align-items: center;
    justify-content: center; }
  .sections-reorganize-exp > .section-separators {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .sections-reorganize-exp > .section-separators > .section-separator {
      stroke-width: 1;
      stroke: #dfe5eb;
      stroke-dasharray: 10 5; }
      .classic-facelift-skin .sections-reorganize-exp > .section-separators > .section-separator {
        stroke-width: 0.5px;
        stroke: #bcbcbc;
        stroke-dasharray: 4px 4px; }

.section-controls-button {
  pointer-events: auto;
  cursor: pointer;
  display: inline-block;
  margin-right: 6px;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.36);
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  position: relative;
  color: #2d3034; }
  .section-controls-button > .symbol {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(-50%, -50%); }
    .section-controls-button > .symbol * {
      fill: #2d3034; }
  .section-controls-button:not(.with-label):hover {
    background-color: #116dff; }
    .section-controls-button:not(.with-label):hover > .symbol * {
      fill: #fff; }
  .section-controls-button.with-label {
    padding: 0 10px 0 36px; }
    .section-controls-button.with-label:hover {
      background-color: #116dff;
      color: #fff; }
      .section-controls-button.with-label:hover > .symbol * {
        fill: #fff; }
  .section-controls-button.disabled {
    background-color: #fff !important;
    cursor: default; }
    .section-controls-button.disabled > .symbol * {
      fill: #bcbcbc !important; }
  .classic-facelift-skin .section-controls-button {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08); }

.section-controls {
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none; }
  .section-controls > .highlight {
    display: none;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    border: 3px solid #116dff;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(94, 255, 255, 0.2);
    pointer-events: auto;
    cursor: move; }
    .show-highlight.section-controls > .highlight {
      display: flex; }
    .no-drag-handle.section-controls > .highlight > .drag-handle {
      display: none; }
    .header-footer-blocker.section-controls > .highlight {
      display: flex;
      border: none;
      background-color: transparent;
      cursor: default; }
    .section-controls > .highlight.with-shadow {
      box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.2); }
    .section-controls > .highlight > .drag-handle {
      max-height: 36px;
      width: 18px;
      margin-right: -21px;
      background-color: #116dff;
      border-radius: 0 18px 18px 0;
      cursor: move;
      display: flex;
      align-items: center;
      justify-content: center; }
      .section-controls > .highlight > .drag-handle > .drag-handle-dots {
        margin-left: -5px; }
  .section-controls > .section-buttons-wrapper {
    position: absolute;
    top: 0;
    max-height: 74px;
    width: 168px; }
    .section-controls > .section-buttons-wrapper > .section-buttons {
      position: absolute;
      top: 50%;
      margin-top: -18px; }
      .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info {
        height: 36px;
        pointer-events: auto;
        display: flex;
        align-items: center; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-icon circle {
          fill: transparent; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-icon path {
          fill: #868aa5; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-text {
          padding-left: 10px;
          color: #868aa5; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info:hover > .info-icon path {
          fill: #116dff; }

.constraint-area {
  position: absolute;
  text-align: center;
  background-color: rgba(108, 203, 101, 0.32); }
  .constraint-area .box {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #25a55a;
    border-bottom: 1px dashed #25a55a;
    background-color: rgba(108, 203, 101, 0.8);
    margin-left: 50px;
    margin-right: 50px; }
  .constraint-area label {
    line-height: 34px;
    color: #fff;
    font-size: 14px; }

.negative-constraint-indication.constraint-area {
  background-color: rgba(17, 109, 255, 0.12);
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/dead-areas-indication-pattern.v4.png");
  background-repeat: repeat; }

.negative-constraint-indication .box {
  display: none; }

.snap-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100; }
  .snap-layer svg {
    height: 100%;
    width: 100%;
    shape-rendering: crispEdges; }
    .snap-layer svg line {
      stroke: #ff00ff;
      stroke-width: 1px; }
    .snap-layer svg rect {
      stroke: #92fafe;
      stroke-width: 1px;
      fill: none; }
      .snap-layer svg rect.textbox {
        fill: #a107ff !important;
        stroke: #a107ff !important; }
    .snap-layer svg text {
      fill: #fff;
      font-size: 10px;
      font-family: Madefor, Helvetica Neue, sans-serif; }

.highlightFrame {
  box-sizing: border-box;
  z-index: 1;
  pointer-events: none; }
  .highlightFrame.classicSection.normal, .highlightFrame.classicSection.with-opacity, .highlightFrame.classicSection.thick {
    border-color: #6f56f9; }
  .highlightFrame.classicSection.thick.light-theme {
    border-color: #d9d2ff; }
  .highlightFrame.classicSection.with-background {
    background-color: rgba(111, 86, 249, 0.1);
    outline-color: #6f56f9; }
    .highlightFrame.classicSection.with-background.with-background-opacity {
      background-color: rgba(111, 86, 249, 0.1);
      outline-color: #d9d2ff; }
  .highlightFrame.thick {
    border: 3px solid #116dff; }
    .highlightFrame.thick.light-theme {
      border-color: #d6e6fe; }
  .highlightFrame.normal {
    border: 2px solid #116dff; }
  .highlightFrame.thin {
    border: 1px solid #116dff; }
  .highlightFrame.with-opacity {
    opacity: 0.6;
    border: 3px solid #116dff; }
  .highlightFrame.show-on-all-pages {
    border-color: #fe620f; }
  .highlightFrame.with-background {
    background: rgba(56, 153, 236, 0.3);
    outline: 1px solid #116dff; }
    .highlightFrame.with-background.with-background-opacity {
      background: rgba(211, 237, 255, 0.52);
      opacity: 0.5;
      outline: 1px solid #80b1ff; }

.gridLines {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  shape-rendering: crispEdges; }
  .gridLines.fade {
    opacity: 0; }
  .gridLines line {
    stroke-width: 1; }
    .gridLines line.backLine {
      stroke: #ffffff; }
    .gridLines line.frontLine {
      stroke: #000000;
      stroke-dasharray: 5 5; }

.edit-box.draggable {
  cursor: move; }

.edit-box.multi-select .layout .handle.handle-resize-corner,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-corner {
  border: 1px solid #000000; }

.edit-box.multi-select .layout .handle.handle-resize-side.top:after, .edit-box.multi-select .layout .handle.handle-resize-side.right:after, .edit-box.multi-select .layout .handle.handle-resize-side.bottom:after, .edit-box.multi-select .layout .handle.handle-resize-side.left:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.top:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.right:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.bottom:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.left:after {
  border: 1px solid #000000; }

.edit-box .layout {
  border: 1px solid #116dff; }
  .edit-box .layout.isSectionLike {
    border: none; }
  .edit-box .layout .handle {
    position: absolute; }
    .edit-box .layout .handle:active {
      visibility: visible !important; }
    .edit-box .layout .handle.top-left-resize-cursor {
      cursor: nwse-resize; }
    .edit-box .layout .handle.top-resize-cursor {
      cursor: ns-resize; }
    .edit-box .layout .handle.top-right-resize-cursor {
      cursor: nesw-resize; }
    .edit-box .layout .handle.right-resize-cursor {
      cursor: ew-resize; }
    .edit-box .layout .handle.bottom-right-resize-cursor {
      cursor: nwse-resize; }
    .edit-box .layout .handle.bottom-resize-cursor {
      cursor: ns-resize; }
    .edit-box .layout .handle.bottom-left-resize-cursor {
      cursor: nesw-resize; }
    .edit-box .layout .handle.left-resize-cursor {
      cursor: ew-resize; }
    .edit-box .layout .handle.handle-resize-corner {
      width: 7px;
      height: 7px;
      background-color: #fff;
      border: 1px solid #116dff;
      border-radius: 50%;
      z-index: 1; }
      .edit-box .layout .handle.handle-resize-corner.top.left {
        top: -5px;
        left: -5px; }
      .edit-box .layout .handle.handle-resize-corner.top.right {
        top: -5px;
        right: -5px; }
      .edit-box .layout .handle.handle-resize-corner.bottom.right {
        bottom: -5px;
        right: -5px; }
      .edit-box .layout .handle.handle-resize-corner.bottom.left {
        bottom: -5px;
        left: -5px; }
    .edit-box .layout .handle.handle-resize-side.top {
      height: 5px;
      left: 4px;
      right: 4px;
      top: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.top.new-design-blue:hover:after {
        background-color: #116dff; }
      .edit-box .layout .handle.handle-resize-side.top.new-design-orange:hover:after {
        background-color: #fe620f; }
      .edit-box .layout .handle.handle-resize-side.top.new-design-blue, .edit-box .layout .handle.handle-resize-side.top.new-design-orange {
        height: 20px; }
        .edit-box .layout .handle.handle-resize-side.top.new-design-blue:hover:after, .edit-box .layout .handle.handle-resize-side.top.new-design-orange:hover:after {
          content: '';
          width: 100%;
          height: 10px;
          margin: 0;
          border: none;
          border-radius: 0;
          opacity: 0.4; }
        .edit-box .layout .handle.handle-resize-side.top.new-design-blue:after, .edit-box .layout .handle.handle-resize-side.top.new-design-orange:after {
          content: none; }
      .edit-box .layout .handle.handle-resize-side.top.new-design-blue, .edit-box .layout .handle.handle-resize-side.top.new-design-orange {
        top: -10px;
        left: 0;
        right: 0; }
        .edit-box .layout .handle.handle-resize-side.top.new-design-blue:hover:after, .edit-box .layout .handle.handle-resize-side.top.new-design-orange:hover:after {
          bottom: 0;
          left: 0; }
      .edit-box .layout .handle.handle-resize-side.top:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #116dff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px; }
    .edit-box .layout .handle.handle-resize-side.right {
      width: 5px;
      top: 4px;
      bottom: 4px;
      right: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.right:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #116dff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        margin-top: -5px;
        margin-right: -5px; }
    .edit-box .layout .handle.handle-resize-side.bottom {
      height: 5px;
      left: 4px;
      right: 4px;
      bottom: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue:hover:after {
        background-color: #116dff; }
      .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange:hover:after {
        background-color: #fe620f; }
      .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue, .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange {
        height: 20px; }
        .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue:hover:after, .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange:hover:after {
          content: '';
          width: 100%;
          height: 10px;
          margin: 0;
          border: none;
          border-radius: 0;
          opacity: 0.4; }
        .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue:after, .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange:after {
          content: none; }
      .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue, .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange {
        bottom: -10px;
        left: 0;
        right: 0; }
        .edit-box .layout .handle.handle-resize-side.bottom.new-design-blue:hover:after, .edit-box .layout .handle.handle-resize-side.bottom.new-design-orange:hover:after {
          top: 0;
          left: 0; }
      .edit-box .layout .handle.handle-resize-side.bottom:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #116dff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 50%;
        margin-left: -5px;
        margin-bottom: -5px; }
    .edit-box .layout .handle.handle-resize-side.left {
      width: 5px;
      top: 4px;
      bottom: 4px;
      left: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.left:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #116dff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px; }
    .edit-box .layout .handle.handle-rotate {
      width: 18px;
      height: 18px;
      top: -24px;
      left: -24px;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%);
      box-shadow: 0 0 5px 0 rgba(22, 45, 61, 0.41);
      border-radius: 50%;
      cursor: pointer; }
      .edit-box .layout .handle.handle-rotate:active {
        opacity: 0.5; }
        .edit-box .layout .handle.handle-rotate:active svg path {
          fill: #116dff; }
      .edit-box .layout .handle.handle-rotate:hover svg path {
        fill: #116dff; }
      .edit-box .layout .handle.handle-rotate svg.symbol-rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .edit-box .layout .handle.handle-rotate-cancel {
      width: 13px;
      height: 13px;
      top: -38px;
      left: -38px;
      cursor: pointer;
      border: 1px #e62214 solid;
      opacity: 0.6;
      border-radius: 50%;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%); }
      .edit-box .layout .handle.handle-rotate-cancel:hover {
        border-color: #e62214;
        opacity: 1; }
      .edit-box .layout .handle.handle-rotate-cancel svg {
        position: absolute;
        top: calc(50% - (2.5px));
        left: calc(50% - (2.5px)); }
    .edit-box .layout .handle.handle-push {
      cursor: s-resize; }
    .edit-box .layout .handle.handle-pull {
      cursor: n-resize; }
    .edit-box .layout .handle.handle-pull, .edit-box .layout .handle.handle-push {
      display: inline-block;
      padding: 0 10px;
      height: 19px;
      left: 75%;
      margin-left: -9.5px;
      border-radius: 6px;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%);
      box-shadow: 0 0 5px 0 rgba(22, 45, 61, 0.41);
      font-size: 0;
      transition: padding-left 0.1s; }
      .edit-box .layout .handle.handle-pull.handle-drag-with-anchors, .edit-box .layout .handle.handle-push.handle-drag-with-anchors {
        top: -9.5px; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:active, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:active {
          opacity: 0.5; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:active svg path, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:active svg path {
            fill: #116dff; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:hover svg path, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:hover svg path {
          fill: #116dff; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover, .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover {
          left: calc(50% + 76px);
          padding: 0 16px; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover.section, .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover.section {
            z-index: 101; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover:hover:not(:active), .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover:hover:not(:active) {
            font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
            font-weight: var(--wbu-font-weight-regular);
            font-size: 12px;
            -webkit-font-smoothing: antialiased;
            height: 18px;
            line-height: 17px;
            color: #116dff;
            padding-left: 26px;
            padding-right: 12px;
            padding-top: 1px; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover svg, .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover svg {
            left: 16px; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors svg, .edit-box .layout .handle.handle-push.handle-drag-with-anchors svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:not(.footer-handle), .edit-box .layout .handle.handle-push.handle-resize-with-anchors:not(.footer-handle) {
        bottom: -9.5px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.footer-handle, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.footer-handle {
        top: -9.5px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:active, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:active {
        opacity: 0.5; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:active svg path, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:active svg path {
          fill: #116dff; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:hover svg path, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:hover svg path {
        fill: #116dff; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover {
        left: calc(50% + 76px);
        padding: 0 16px; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover.section, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover.section {
          z-index: 101; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover:hover:not(:active), .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover:hover:not(:active) {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
          font-weight: var(--wbu-font-weight-regular);
          font-size: 12px;
          -webkit-font-smoothing: antialiased;
          height: 18px;
          line-height: 17px;
          color: #116dff;
          padding-left: 26px;
          padding-right: 12px;
          padding-top: 1px; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover svg, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover svg {
          left: 16px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors svg, .edit-box .layout .handle.handle-push.handle-resize-with-anchors svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .classic-facelift-skin .edit-box .layout .handle.handle-rotate, .classic-facelift-skin .edit-box .layout .handle.handle-rotate-cancel, .classic-facelift-skin .edit-box .layout .handle.handle-push {
      background: none;
      background-color: #fff; }
      .classic-facelift-skin .edit-box .layout .handle.handle-rotate path, .classic-facelift-skin .edit-box .layout .handle.handle-rotate-cancel path, .classic-facelift-skin .edit-box .layout .handle.handle-push path {
        fill: #3b4057; }
  .edit-box .layout.components-ui-color-orange {
    border-color: #fe620f; }
    .edit-box .layout.components-ui-color-orange .handle.handle-resize-corner {
      border-color: #fe620f; }
    .edit-box .layout.components-ui-color-orange .handle.handle-resize-side:after {
      border-color: #fe620f; }
  .edit-box .layout.site-segments-component {
    border-width: 3px; }
  .edit-box .layout.components-ui-color-orange.secondary-component:before, .edit-box .layout.secondary-component:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border: 1px dashed #fff; }
  .edit-box .layout.secondary-component {
    border: 1px solid #626262; }

.edit-box .layout.container-interaction-layout {
  border: 3px solid #116dff; }
  .edit-box .layout.container-interaction-layout.components-ui-color-orange {
    border-color: #fe620f; }

.edit-box .move-to-footer {
  display: inline-block;
  position: absolute;
  padding-left: 18px;
  padding-right: 15px;
  transform: translate(-50%);
  height: 28px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 8px 0 rgba(22, 45, 61, 0.35);
  border-radius: 20px;
  cursor: pointer; }
  .edit-box .move-to-footer svg {
    margin-left: 9px; }
  .edit-box .move-to-footer.components-ui-color-orange {
    border-color: #fe620f; }
  .edit-box .move-to-footer:active {
    opacity: 0.5;
    color: #116dff; }
    .edit-box .move-to-footer:active svg path {
      fill: #116dff; }
  .edit-box .move-to-footer:hover {
    color: #116dff; }
    .edit-box .move-to-footer:hover svg path {
      fill: #116dff; }

.edit-box .bounding {
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  border: 1px solid #116dff; }
  .edit-box .bounding.components-ui-color-orange {
    border-color: #fe620f; }

.edit-box .padding-wrapper {
  width: 100%; }
  .edit-box .padding-wrapper > .left-padding,
  .edit-box .padding-wrapper > .right-padding {
    position: absolute;
    background: rgba(17, 109, 255, 0.12) url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/graphic-slider-fill-pattern.png") repeat; }
    .edit-box .padding-wrapper > .left-padding .min-indicator-margin,
    .edit-box .padding-wrapper > .right-padding .min-indicator-margin {
      width: 56px;
      display: inline-block;
      position: relative; }
    .edit-box .padding-wrapper > .left-padding .edit-box-indicator.resizing,
    .edit-box .padding-wrapper > .right-padding .edit-box-indicator.resizing {
      visibility: visible;
      position: relative;
      padding: 3px 10px;
      top: 0; }
    .edit-box .padding-wrapper > .left-padding:before,
    .edit-box .padding-wrapper > .right-padding:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 15px;
      position: absolute;
      top: calc(50% - 7px);
      background: #116dff; }
    .edit-box .padding-wrapper > .left-padding:after,
    .edit-box .padding-wrapper > .right-padding:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      border-top: #116dff 1px dashed; }
  .edit-box .padding-wrapper > .left-padding:before {
    left: 0; }
  .edit-box .padding-wrapper > .right-padding:before {
    right: 0; }

.edit-box .preloader {
  background: rgba(255, 255, 255, 0.5); }

.edit-box .edit-box-indicator {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  visibility: hidden;
  position: absolute;
  height: 22px;
  line-height: 22px;
  top: -32px;
  left: 0;
  background-color: #343434;
  border-radius: 4px;
  opacity: 0.8;
  color: #fff;
  padding: 0 3px;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px; }
  .edit-box .edit-box-indicator.rotating {
    -webkit-user-select: none;
    user-select: none; }

.edit-box .bottom-indicator {
  top: unset;
  bottom: -32px; }

.edit-box.rotating .layout .handle {
  visibility: hidden; }

.edit-box.rotating .layout .edit-box-indicator {
  visibility: visible; }

.edit-box.dragging .layout {
  border: none; }
  .edit-box.dragging .layout.secondary-component {
    border: 1px solid #626262; }
  .edit-box.dragging .layout .handle {
    visibility: hidden; }

.edit-box.dragging .bounding {
  visibility: visible; }
  .edit-box.dragging .bounding .edit-box-indicator.dragging {
    visibility: visible; }

.edit-box.resizing .bounding {
  visibility: visible;
  border: none; }
  .edit-box.resizing .bounding .edit-box-indicator.resizing {
    visibility: visible; }

.edit-box.resizing .layout .handle {
  visibility: hidden; }

.edit-box.dimmed-handles .layout {
  border-color: rgba(17, 109, 255, 0.3); }

.edit-box.dimmed-handles.components-ui-color-orange .layout {
  border-color: rgba(254, 98, 15, 0.3); }

.edit-box.dimmed-handles .handle:not(.handle-rotate):not(.handle-rotate-cancel) {
  opacity: 0.3; }

.edit-box.edit-box-for-pin-mode .handle {
  opacity: 0; }

.edit-box.small .layout .handle.handle-push {
  left: calc(100% + 13px);
  margin-left: 0; }

.interactions-edit-box {
  z-index: 0; }
  .interactions-edit-box .trigger-layout,
  .interactions-edit-box .non-trigger-layout {
    position: absolute;
    cursor: move;
    transition: border 300ms ease-in-out, background-color 300ms ease-in-out; }
    .interactions-edit-box .trigger-layout .center-dot,
    .interactions-edit-box .non-trigger-layout .center-dot {
      height: 8px;
      width: 8px;
      background-color: #fff;
      border: 1px solid #000624;
      border-radius: 50%; }
    .interactions-edit-box .trigger-layout .drag-handle,
    .interactions-edit-box .non-trigger-layout .drag-handle {
      top: -1px;
      left: calc( -21px - 6px - 1px);
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to bottom, #fff, #f4f4f4 70%, #d9d9d9);
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.41);
      border-radius: 50%;
      cursor: grab;
      transition: opacity 200ms ease; }
      .interactions-edit-box .trigger-layout .drag-handle:active,
      .interactions-edit-box .non-trigger-layout .drag-handle:active {
        opacity: 0.5;
        cursor: grabbing; }
    .interactions-edit-box .trigger-layout .drag-handle svg,
    .interactions-edit-box .non-trigger-layout .drag-handle svg {
      fill: #3b4057;
      transition: fill 250ms ease-in-out; }
    .interactions-edit-box .trigger-layout .drag-handle:hover svg, .interactions-edit-box .trigger-layout .drag-handle:active svg,
    .interactions-edit-box .non-trigger-layout .drag-handle:hover svg,
    .interactions-edit-box .non-trigger-layout .drag-handle:active svg {
      fill: #116dff; }
    .interactions-edit-box .trigger-layout .edit-box .layout,
    .interactions-edit-box .non-trigger-layout .edit-box .layout {
      z-index: 1; }
  .interactions-edit-box .trigger-layout {
    border: 3px solid #116dff;
    z-index: 1; }
    .interactions-edit-box .trigger-layout .trigger-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      display: flex;
      top: -26px;
      left: -3px;
      transform-origin: 0 0;
      height: 26px;
      line-height: 26px;
      white-space: nowrap;
      border-radius: 4px;
      z-index: 1;
      transition: background-color 400ms ease-in-out, color 400ms ease-in-out; }
      .interactions-edit-box .trigger-layout .trigger-label .regular {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 71px;
        height: 23px;
        line-height: 23px;
        background-color: #0057e1;
        cursor: pointer;
        border-radius: 2px 0 0 0; }
        .interactions-edit-box .trigger-layout .trigger-label .regular:hover {
          background-color: #116dff; }
        .interactions-edit-box .trigger-layout .trigger-label .regular span {
          cursor: pointer;
          color: #fff; }
        .classic-facelift-skin .interactions-edit-box .trigger-layout .trigger-label .regular {
          background-color: #0057e1; }
          .classic-facelift-skin .interactions-edit-box .trigger-layout .trigger-label .regular:hover {
            background-color: #5999ff; }
      .interactions-edit-box .trigger-layout .trigger-label .interaction {
        position: absolute;
        bottom: 0;
        left: 71px;
        border-radius: 2px 2px 0 0;
        background-image: linear-gradient(to bottom, #50adff, #3999ec);
        color: #fff;
        padding: 0 10px 0 28px; }
        .interactions-edit-box .trigger-layout .trigger-label .interaction svg {
          position: absolute;
          left: 6px;
          top: 3px;
          height: 20px;
          width: 20px;
          fill: #fff;
          transition: fill 400ms ease-in-out; }
        .classic-facelift-skin .interactions-edit-box .trigger-layout .trigger-label .interaction {
          background-image: none;
          background-color: #116dff; }
    .interactions-edit-box .trigger-layout .drag-handle {
      top: -3px;
      left: calc( -21px - 6px - 3px); }
  .interactions-edit-box .non-trigger-layout {
    border: 1px solid transparent;
    z-index: 1; }
    .interactions-edit-box .non-trigger-layout.selected {
      border-color: #116dff; }
    .interactions-edit-box .non-trigger-layout:not(.selected).hovered {
      z-index: 0;
      border-color: #d6e6fe;
      background-color: rgba(223, 242, 221, 0.07); }
  .interactions-edit-box .selected-original-layout {
    position: absolute;
    border: 1px dashed #116dff;
    border-image-source: repeating-linear-gradient(45deg, #116dff calc(1px - 1px) 8px, transparent 8px 12px, #116dff 12px);
    border-image-slice: 1;
    z-index: 1;
    pointer-events: none; }
  .interactions-edit-box .x-y-indicator {
    opacity: 0;
    position: fixed;
    transform: translate(-3px, calc(-3px - 22px - 9px));
    border-radius: 12px;
    padding: 0 9px;
    height: 22px;
    line-height: 22px;
    background-color: #343434;
    color: #fff;
    font-size: 14px;
    z-index: 3;
    transition: 150ms opacity ease-in-out;
    pointer-events: none; }
    .interactions-edit-box .x-y-indicator.visible {
      opacity: 1; }
  .interactions-edit-box .center-to-original-center-connecting-line {
    position: fixed;
    top: 0;
    left: 0;
    overflow: visible;
    pointer-events: none;
    z-index: 4; }
    .interactions-edit-box .center-to-original-center-connecting-line line {
      pointer-events: none;
      stroke: #116dff; }
  .interactions-edit-box.trigger-not-selected .trigger-layout {
    border-color: #d6e6fe; }
    .interactions-edit-box.trigger-not-selected .trigger-layout > .center-dot,
    .interactions-edit-box.trigger-not-selected .trigger-layout > .drag-handle {
      display: none; }
    .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .regular {
      background-color: #e7f0ff; }
      .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .regular span {
        color: #116dff; }
      .classic-facelift-skin .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .regular:hover {
        background-color: #e7f0ff; }
    .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .interaction {
      color: #116dff;
      background-color: #d6e6fe;
      background-image: none; }
      .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .interaction svg {
        fill: #116dff; }
        .interactions-edit-box.trigger-not-selected .trigger-layout .trigger-label .interaction svg path {
          fill: #116dff; }
  .interactions-edit-box.trigger-not-selected .x-y-indicator {
    transform: translate(-1px, calc(-1px - 22px - 9px)); }
  .interactions-edit-box.selected-shown-only-in-variant .selected.non-trigger-layout,
  .interactions-edit-box.selected-shown-only-in-variant .selected-original-layout,
  .interactions-edit-box.selected-shown-only-in-variant .x-y-indicator,
  .interactions-edit-box.selected-shown-only-in-variant .center-to-original-center-connecting-line {
    display: none; }
  .interactions-edit-box.components-ui-color-orange .trigger-layout {
    border: 3px solid #fe620f; }
    .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .regular {
      background-color: #bf5727; }
      .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .regular:hover {
        background-color: #bf5727; }
    .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .interaction {
      background-image: linear-gradient(to bottom, #ff7d38, #fe620f); }
    .classic-facelift-skin .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .interaction {
      background-image: none;
      background-color: #fe620f; }
    .classic-facelift-skin .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .regular {
      background-color: #bf5727; }
      .classic-facelift-skin .interactions-edit-box.components-ui-color-orange .trigger-layout .trigger-label .regular:hover {
        background-color: #ff7d38; }
  .interactions-edit-box.components-ui-color-orange .non-trigger-layout.selected {
    border-color: #fe620f; }
  .interactions-edit-box.components-ui-color-orange .non-trigger-layout:not(.selected).hovered {
    border-color: #fee8dc; }
  .interactions-edit-box.components-ui-color-orange .selected-original-layout {
    border: 1px dashed #fe620f; }
  .interactions-edit-box.components-ui-color-orange .center-to-original-center-connecting-line line {
    stroke: #fe620f; }
  .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout {
    border-color: #fee8dc; }
    .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .regular {
      background-color: #fdd1ba; }
      .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .regular span {
        color: #fe620f; }
      .classic-facelift-skin .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .regular:hover {
        background-color: #fdd1ba; }
    .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .interaction {
      color: #fe620f;
      background-color: #fee8dc;
      background-image: none; }
      .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .interaction svg {
        fill: #fe620f; }
        .interactions-edit-box.components-ui-color-orange.trigger-not-selected .trigger-layout .trigger-label .interaction svg path {
          fill: #fe620f; }

.focusbox .focus-frame {
  border: 3px solid #116dff;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .focusbox .focus-frame > .grid-lines {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .focusbox .focus-frame > .grid-lines > .line {
      stroke-width: 1; }
      .focusbox .focus-frame > .grid-lines > .line.back {
        stroke: #fff; }
      .focusbox .focus-frame > .grid-lines > .line.front {
        stroke: #000000;
        stroke-dasharray: 5.5; }

.focusbox.components-ui-color-orange .focus-frame {
  border-color: #fe620f; }

.focusbox.components-ui-color-orange.components-ui-theme-light .focus-frame {
  border-color: #fdd1ba; }

.focusbox.components-ui-theme-light:not(.components-ui-color-orange) .focus-frame {
  border-color: #d6e6fe; }

.hover-box {
  position: absolute;
  border: 1px solid rgba(211, 237, 255, 0.8); }
  .hover-box.components-background-color {
    background: rgba(214, 230, 254, 0.07); }
  .hover-box.overlay {
    background-color: rgba(211, 237, 255, 0.24); }
  .hover-box.components-ui-color-orange {
    border-color: rgba(235, 165, 84, 0.6);
    background-color: rgba(235, 165, 84, 0.05); }
    .hover-box.components-ui-color-orange .comp-label {
      background-color: #eba554;
      color: #fff; }
    .hover-box.components-ui-color-orange.overlay {
      background-color: rgba(235, 165, 84, 0.24); }
    .classic-facelift-skin .hover-box.components-ui-color-orange {
      border-color: #fe620f;
      background-color: rgba(255, 125, 56, 0.05); }
  .hover-box.fixed-position {
    position: fixed; }

.interactions-container-outline {
  position: absolute;
  border: 3px solid #d6e6fe; }
  .interactions-container-outline .container-outline-nav-wrapper {
    top: -2px;
    left: -2px;
    height: 26px;
    position: absolute; }
    .interactions-container-outline .container-outline-nav-wrapper .interaction-nav {
      cursor: pointer; }
      .interactions-container-outline .container-outline-nav-wrapper .interaction-nav.interaction {
        background-color: #a8caff; }
        .classic-facelift-skin .interactions-container-outline .container-outline-nav-wrapper .interaction-nav.interaction {
          background-color: #e7f0ff; }
      .interactions-container-outline .container-outline-nav-wrapper .interaction-nav.regular {
        background-color: #d6e6fe;
        background-image: none; }
      .interactions-container-outline .container-outline-nav-wrapper .interaction-nav span {
        color: #116dff;
        cursor: pointer; }
      .interactions-container-outline .container-outline-nav-wrapper .interaction-nav svg path {
        fill: #116dff; }
  .interactions-container-outline.components-ui-color-orange {
    border-color: #fee8dc; }
    .interactions-container-outline.components-ui-color-orange .container-outline-nav-wrapper .interaction-nav.interaction {
      background-color: #fee8dc; }
    .interactions-container-outline.components-ui-color-orange .container-outline-nav-wrapper .interaction-nav.regular {
      background-color: #fdd1ba;
      background-image: none; }
    .interactions-container-outline.components-ui-color-orange .container-outline-nav-wrapper .interaction-nav span {
      color: #fe620f; }
    .interactions-container-outline.components-ui-color-orange .container-outline-nav-wrapper .interaction-nav svg path {
      fill: #fe620f; }

.component-labels {
  position: absolute;
  white-space: nowrap;
  cursor: default;
  display: inline-flex;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .component-labels.label-large {
    font-size: 16px; }
    .component-labels.label-large.label-above-line {
      top: -20px; }
  .component-labels.label-inner {
    left: -6px; }
  .component-labels.label-small {
    left: 0;
    margin-left: -1px;
    font-size: 12px; }
  .component-labels.label-top {
    top: -17px; }
  .component-labels.label-bottom {
    bottom: -17px; }
  .component-labels.dimmed {
    pointer-events: none; }
    .component-labels.dimmed > .label-info-icon-container {
      opacity: 0.2; }
    .component-labels.dimmed > span > .label-comp-nickname {
      color: rgba(0, 6, 36, 0.2);
      background-color: rgba(247, 248, 248, 0.2); }
      .component-labels.dimmed > span > .label-comp-nickname.components-ui-color-orange {
        background-color: rgba(247, 248, 248, 0.2);
        color: rgba(0, 6, 36, 0.2); }
    .component-labels.dimmed > .label-comp-type {
      background-color: rgba(214, 230, 254, 0.2); }
      .component-labels.dimmed > .label-comp-type.components-ui-theme-dark {
        background-color: rgba(17, 109, 255, 0.2); }
      .component-labels.dimmed > .label-comp-type.components-ui-color-orange {
        background-color: rgba(253, 209, 186, 0.2); }
        .component-labels.dimmed > .label-comp-type.components-ui-color-orange.components-ui-theme-dark {
          background-color: rgba(254, 98, 15, 0.2); }
  .component-labels > .symbol-container {
    padding: 2px 0 1px 4px;
    height: 16px;
    display: inline-flex;
    box-sizing: border-box;
    background-color: #d6e6fe; }
    .component-labels > .symbol-container.connected-comp {
      background-color: #f7f8f8; }
    .component-labels > .symbol-container.components-ui-color-orange {
      background-color: #eba554; }
      .classic-facelift-skin .component-labels > .symbol-container.components-ui-color-orange {
        background-color: #fe620f; }
  .component-labels > .label-comp-type,
  .component-labels > span > .label-comp-nickname {
    -webkit-user-select: none;
    user-select: none;
    display: inline-flex;
    height: 16px;
    padding: 0 6px; }
    .label-large.component-labels > .label-comp-type, .label-large.component-labels > span > .label-comp-nickname {
      height: 20px; }
    .component-labels > .label-comp-type.components-ui-color-orange,
    .component-labels > span > .label-comp-nickname.components-ui-color-orange {
      background-color: #f7f8f8;
      color: #000624; }
  .component-labels > .label-comp-type {
    line-height: 16px;
    color: #598bdf;
    background-color: #d6e6fe; }
    .component-labels > .label-comp-type.draggable {
      cursor: move; }
    .component-labels > .label-comp-type.components-ui-theme-dark {
      color: #fff;
      background-color: #116dff; }
    .label-large.component-labels > .label-comp-type {
      line-height: 18px; }
    .component-labels > .label-comp-type.components-ui-color-orange {
      color: #fe620f;
      background-color: #fdd1ba; }
      .component-labels > .label-comp-type.components-ui-color-orange.components-ui-theme-dark {
        color: #fff;
        background-color: #fe620f; }
    .component-labels > .label-comp-type_se-madefor-font > :first-child {
      color: inherit; }
  .component-labels > span > .label-comp-nickname {
    -webkit-user-select: none;
    user-select: none;
    line-height: 18px;
    font-family: Menlo, Monaco, "Courier New", monospace;
    color: #000624;
    background-color: #f7f8f8; }
    .label-large.component-labels > span > .label-comp-nickname {
      line-height: 22px; }
    .component-labels > span > .label-comp-nickname.secondary-nickname, .component-labels > span > .label-comp-nickname.inner-nickname {
      border-left: 1px solid #dfe5eb; }

.overlay {
  position: absolute;
  opacity: 0;
  transition-property: opacity;
  height: 100%;
  width: 100%; }
  .overlay.visible {
    opacity: 1; }

.dropdown-controller {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: linear-gradient(0deg, #116dff 0%, #5999ff 100%);
  box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.55);
  height: 24px;
  line-height: 24px;
  display: flex; }
  .dropdown-controller .controller-label {
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    cursor: default;
    font-size: 14px;
    display: inline-block;
    margin: 0 12px; }
    .dropdown-controller .controller-label:hover {
      cursor: move; }
  .dropdown-controller .select {
    display: inline-block;
    color: #fff;
    margin-right: 6px; }
  .dropdown-controller.comp-bottom {
    border-radius: 0 0 2px 2px; }
  .dropdown-controller.comp-top {
    border-radius: 2px 2px 0 0; }
  .dropdown-controller .dropdown-label {
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    display: inline-block;
    cursor: default;
    padding: 0 12px;
    line-height: 24px; }
    .dropdown-controller .dropdown-label:hover {
      cursor: move; }
  .dropdown-controller > .dropdown > .dd {
    width: 79px;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    height: 20px;
    padding: 0 17px 0 6px;
    margin: 2px 0 2px 0;
    color: #fff;
    border-radius: 4px;
    background-color: #2b5672; }
    .dropdown-controller > .dropdown > .dd:hover {
      background-color: #2b6b9e; }
    .dropdown-controller > .dropdown > .dd > .selected-container {
      display: inline-block;
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      min-height: 20px;
      width: 79px;
      color: #fff;
      padding: 0; }
      .dropdown-controller > .dropdown > .dd > .selected-container > .dropdown-selected {
        width: 79px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left; }
    .dropdown-controller > .dropdown > .dd > .expand.arrow {
      width: 5px;
      opacity: 1;
      position: absolute;
      right: 0;
      top: -1px;
      transform: none;
      height: 4px;
      margin: 0 6px;
      display: inline-block;
      border: none; }
      .dropdown-controller > .dropdown > .dd > .expand.arrow > .symbol {
        position: static;
        transform: none;
        width: 100%;
        height: 100%;
        fill: #fff; }
  .dropdown-controller.components-ui-color-orange {
    background: linear-gradient(0deg, #fe620f 0%, #ff7d38 100%); }
    .dropdown-controller.components-ui-color-orange.components-ui-theme-light {
      background: linear-gradient(to bottom, #fee8dc, #fdd1ba); }
      .dropdown-controller.components-ui-color-orange.components-ui-theme-light .controller-label {
        color: #fe620f; }
  .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) {
    background: linear-gradient(to bottom, #e3f3ff, #d6e6fe); }
    .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) .controller-label {
      color: #116dff; }
  .classic-facelift-skin .dropdown-controller {
    background: none;
    box-shadow: none;
    background-color: #116dff; }
    .classic-facelift-skin .dropdown-controller .dark-skin.control-dropdown > .dropdown-selected {
      background-color: #003790; }
      .classic-facelift-skin .dropdown-controller .dark-skin.control-dropdown > .dropdown-selected:hover {
        background-color: #0057e1; }
    .classic-facelift-skin .dropdown-controller .control-dropdown:hover:not(.disabled) > .dropdown-selected svg path {
      fill: #fff; }
    .classic-facelift-skin .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) {
      background: none;
      box-shadow: none;
      background-color: #d6e6fe; }
      .classic-facelift-skin .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) .dark-skin.control-dropdown > .dropdown-selected {
        background-color: #e7f0ff;
        color: #116dff; }
        .classic-facelift-skin .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) .dark-skin.control-dropdown > .dropdown-selected svg path {
          fill: #116dff; }
        .classic-facelift-skin .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) .dark-skin.control-dropdown > .dropdown-selected:hover:not(.disabled) {
          background-color: #80b1ff;
          color: #fff; }
          .classic-facelift-skin .dropdown-controller.components-ui-theme-light:not(.components-ui-color-orange) .dark-skin.control-dropdown > .dropdown-selected:hover:not(.disabled) svg path {
            fill: #fff; }
    .classic-facelift-skin .dropdown-controller.components-ui-color-orange {
      background: none;
      box-shadow: none;
      background-color: #fe620f; }
      .classic-facelift-skin .dropdown-controller.components-ui-color-orange .dark-skin.control-dropdown > .dropdown-selected {
        background-color: #bf5727; }
        .classic-facelift-skin .dropdown-controller.components-ui-color-orange .dark-skin.control-dropdown > .dropdown-selected:hover {
          background-color: #ff7d38;
          color: #fff; }
          .classic-facelift-skin .dropdown-controller.components-ui-color-orange .dark-skin.control-dropdown > .dropdown-selected:hover svg path {
            fill: #fff; }
    .classic-facelift-skin .dropdown-controller.components-ui-theme-light {
      background-color: #fee8dc; }
      .classic-facelift-skin .dropdown-controller.components-ui-theme-light .dark-skin.control-dropdown > .dropdown-selected {
        background-color: #fdd1ba;
        color: #fe620f; }
        .classic-facelift-skin .dropdown-controller.components-ui-theme-light .dark-skin.control-dropdown > .dropdown-selected svg path {
          fill: #fe620f; }
  .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector > .control-dropdown-base > .dropdown-selected {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #5999ff;
    background-color: #003790;
    color: #fff; }
    .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector > .control-dropdown-base > .dropdown-selected:hover {
      background-color: #0057e1; }
    .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector > .control-dropdown-base > .dropdown-selected > .selected-container > .selected-content {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector > .control-dropdown-base > .dropdown-selected > .selected-container > .symbol-arrow-down {
      width: 24px;
      margin-right: 0; }
      .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector > .control-dropdown-base > .dropdown-selected > .selected-container > .symbol-arrow-down svg path {
        fill: #fff; }
  .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector.components-ui-theme-light > .control-dropdown-base > .dropdown-selected {
    background-color: #e7f0ff;
    border: 1px solid #a8caff;
    color: #116dff; }
    .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector.components-ui-theme-light > .control-dropdown-base > .dropdown-selected > .selected-container > .symbol-arrow-down {
      width: 24px;
      margin-right: 0; }
      .dropdown-controller.view-state-controller.dropdown-view-state.view-state-selector.components-ui-theme-light > .control-dropdown-base > .dropdown-selected > .selected-container > .symbol-arrow-down svg path {
        fill: #116dff; }

.controller-options .dropdown-options {
  width: 210px; }
  .controller-options .dropdown-options .control-dropdown-option {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    position: relative;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    -webkit-font-smoothing: antialiased; }

.pagination-controller {
  background: linear-gradient(0deg, #116dff 0%, #5999ff 100%);
  box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.55);
  height: 24px;
  line-height: 24px;
  padding-left: 32px;
  padding-right: 32px; }
  .pagination-controller.comp-bottom {
    border-radius: 0 0 2px 2px; }
  .pagination-controller.comp-top {
    border-radius: 2px 2px 0 0; }
  .pagination-controller .middle-label {
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    display: inline-block;
    cursor: default; }
    .pagination-controller .middle-label:hover {
      cursor: move; }
  .pagination-controller .arrow {
    width: 20px;
    height: 20px;
    top: -2px;
    border-radius: 2px;
    background-color: #0057e1;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    position: absolute; }
    .pagination-controller .arrow:after {
      border-left: 2px solid #fff;
      border-top: 2px solid #fff;
      content: '';
      height: 6px;
      position: absolute;
      width: 6px; }
    .pagination-controller .arrow:hover {
      cursor: pointer;
      background-color: #d6e6fe; }
      .pagination-controller .arrow:hover:after {
        border-color: #116dff; }
    .pagination-controller .arrow.prev-arrow {
      left: 0;
      top: 3px; }
      .pagination-controller .arrow.prev-arrow:after {
        left: calc(50% - 2px);
        top: calc(50% - 4px);
        transform: rotate(-45deg); }
    .pagination-controller .arrow.next-arrow {
      right: 0;
      top: 3px; }
      .pagination-controller .arrow.next-arrow:after {
        left: calc(50% - 5px);
        top: calc(50% - 4px);
        transform: rotate(135deg); }
  .pagination-controller.components-ui-color-orange {
    background: linear-gradient(0deg, #fe620f 0%, #ff7d38 100%); }
    .pagination-controller.components-ui-color-orange .arrow {
      background-color: #b8561d; }
      .pagination-controller.components-ui-color-orange .arrow:hover {
        background-color: #fdd1ba; }
        .pagination-controller.components-ui-color-orange .arrow:hover:after {
          border-color: #fe620f; }
    .pagination-controller.components-ui-color-orange.components-ui-theme-light {
      background: linear-gradient(to bottom, #fee8dc, #fdd1ba); }
      .pagination-controller.components-ui-color-orange.components-ui-theme-light .middle-label {
        color: #fe620f; }
      .pagination-controller.components-ui-color-orange.components-ui-theme-light .arrow {
        background-color: #ff7d38; }
        .pagination-controller.components-ui-color-orange.components-ui-theme-light .arrow:hover {
          background-color: #fe620f; }
          .pagination-controller.components-ui-color-orange.components-ui-theme-light .arrow:hover:after {
            border-color: #fff; }
  .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) {
    background: linear-gradient(to bottom, #e3f3ff, #d6e6fe); }
    .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .middle-label {
      color: #116dff; }
    .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow {
      background-color: #a8caff; }
      .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow:after {
        border-color: #116dff; }
      .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow:hover {
        background-color: #80b1ff; }
        .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow:hover:after {
          border-color: #fff; }

.classic-facelift-skin .pagination-controller {
  background: #116dff;
  box-shadow: none; }
  .classic-facelift-skin .pagination-controller.components-ui-color-orange {
    background: #fe620f; }
  .classic-facelift-skin .pagination-controller.components-ui-theme-light.components-ui-color-orange {
    background: #fee8dc; }
  .classic-facelift-skin .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) {
    background: #d6e6fe; }
    .classic-facelift-skin .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow {
      background-color: #e7f0ff; }
      .classic-facelift-skin .pagination-controller.components-ui-theme-light:not(.components-ui-color-orange) .arrow:hover {
        background-color: #80b1ff; }

.tabs-controller {
  overflow: hidden;
  position: relative;
  z-index: 0;
  left: 0; }
  .tabs-controller.comp-top .tab.active {
    cursor: default; }
    .tabs-controller.comp-top .tab.active:hover {
      cursor: move; }
  .tabs-controller.comp-top .tab.clickable:hover {
    cursor: pointer; }

.tabs-controller.comp-top .tab {
  border-radius: 2px 2px 0 0;
  line-height: 24px;
  align-self: flex-end; }
  .tabs-controller.comp-top .tab.active {
    line-height: 30px; }
  .tabs-controller.comp-top .tab.small {
    line-height: 16px; }
  .tabs-controller.comp-top .tab.left-most-tab {
    border-radius: 2px 2px 0 3px; }
  .tabs-controller.comp-top .tab.right-most-tab {
    border-radius: 2px 2px 2px 0; }
  .tabs-controller.comp-top .tab.no-radius {
    border-radius: 0; }

.tabs-controller.comp-bottom .tab {
  border-radius: 0 0 2px 2px;
  line-height: 30px;
  align-self: flex-start; }
  .tabs-controller.comp-bottom .tab.left-most-tab {
    border-radius: 2px 0 2px 2px; }
  .tabs-controller.comp-bottom .tab.right-most-tab {
    border-radius: 0 2px 2px 2px; }
  .tabs-controller.comp-bottom .tab.no-radius {
    border-radius: 0; }

.tabs-controller .tab {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding: 0 12px 0 17px;
  height: 27px;
  box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.55);
  display: inline-block;
  background-color: #0057e1;
  color: #fff;
  margin-left: -5px;
  text-align: center; }
  .tabs-controller .tab:hover {
    cursor: pointer; }
    .tabs-controller .tab:hover.active {
      cursor: default; }
    .tabs-controller .tab:hover.clickable {
      cursor: pointer; }
  .tabs-controller .tab.left-most-tab {
    margin-left: 0;
    padding: 0 17px 0 12px; }
  .tabs-controller .tab.active {
    background: linear-gradient(0deg, #116dff 0%, #5999ff 100%);
    color: #fff;
    height: 30px;
    padding: 0 12px;
    z-index: 1; }
    .classic-facelift-skin .tabs-controller .tab.active {
      background: #116dff; }
  .tabs-controller .tab .action {
    position: absolute;
    top: 0;
    opacity: 0;
    text-align: center;
    width: 60px; }
  .tabs-controller .tab.tab-with-indication {
    display: flex; }
    .tabs-controller .tab.tab-with-indication .tab-indicator {
      background-color: transparent; }
  .tabs-controller .tab.small {
    height: 16px;
    line-height: 16px;
    padding: 0 6px;
    font-size: 12px; }

.tabs-controller.components-ui-color-orange .tab {
  background-color: #b8561d; }
  .tabs-controller.components-ui-color-orange .tab.active {
    background: linear-gradient(0deg, #fe620f 0%, #ff7d38 100%); }
    .classic-facelift-skin .tabs-controller.components-ui-color-orange .tab.active {
      background: #fe620f; }

.tabs-controller.components-ui-color-orange.components-ui-theme-light .tab {
  color: #fff;
  background-color: #ff7d38; }
  .tabs-controller.components-ui-color-orange.components-ui-theme-light .tab.active {
    color: #fe620f;
    background: linear-gradient(to bottom, #fee8dc, #fdd1ba); }

.tabs-controller.components-ui-theme-light:not(.components-ui-color-orange) .tab {
  color: #116dff;
  background-color: #a8caff; }
  .tabs-controller.components-ui-theme-light:not(.components-ui-color-orange) .tab.active {
    color: #116dff;
    background: linear-gradient(to bottom, #e3f3ff, #d6e6fe); }
    .classic-facelift-skin .tabs-controller.components-ui-theme-light:not(.components-ui-color-orange) .tab.active {
      background: #d6e6fe; }

.nav-controls {
  white-space: nowrap;
  position: absolute;
  margin-left: -2px; }
  .focusbox-secondary .nav-controls {
    margin-left: -1px; }
  .nav-controls.comp-bottom {
    top: 100%; }
  .nav-controls.comp-top {
    bottom: 100%; }

.mobile-only-comp-indicator {
  background-color: #d6e6fe;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mobile-only-comp-indicator .symbol-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
    .mobile-only-comp-indicator .symbol-wrapper svg path {
      fill: #598bdf; }
  .mobile-only-comp-indicator.components-ui-color-orange {
    background-color: #fdd1ba; }
    .mobile-only-comp-indicator.components-ui-color-orange .symbol-wrapper svg path {
      fill: #fe620f; }
  .mobile-only-comp-indicator.components-ui-theme-dark {
    background-color: #116dff; }
    .mobile-only-comp-indicator.components-ui-theme-dark.dimmed {
      background-color: rgba(17, 109, 255, 0.2); }
    .mobile-only-comp-indicator.components-ui-theme-dark .symbol-wrapper svg path {
      fill: #fff; }
  .mobile-only-comp-indicator.components-ui-theme-dark.components-ui-color-orange {
    background-color: #fe620f; }
    .mobile-only-comp-indicator.components-ui-theme-dark.components-ui-color-orange.dimmed {
      background-color: rgba(254, 98, 15, 0.2); }
  .mobile-only-comp-indicator .mobile-only-indicator-tooltip-content a {
    color: #116dff;
    cursor: pointer;
    text-decoration: none; }
  .mobile-only-comp-indicator.component-labels-indicator {
    width: 10px;
    padding-left: 4px; }
  .mobile-only-comp-indicator.tab-indicator {
    margin-right: 6px; }

.text-editor {
  position: absolute;
  top: 0;
  left: 0; }

.tag-triangle:before {
  width: 10px;
  height: 10px;
  content: '';
  background-color: inherit;
  display: block;
  position: absolute;
  transform: rotate(45deg); }

.tag-triangle.tag-top:before {
  box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
  top: -5px; }

.tag-triangle.tag-right:before {
  box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35);
  right: -5px; }

.tag-triangle.tag-bottom:before {
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
  bottom: -5px; }

.tag-triangle.tag-left:before {
  box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
  left: -5px; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

body {
  overflow-y: hidden; }
  .sections-experience body {
    overflow-x: hidden; }

#preview,
#previewPlaceholder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden; }

.editor-animation-manager-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw; }

.editor-layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 0; }
  .editor-layout-wrapper.zoom-mode {
    background: #f7f8f8; }
  .editor-layout-wrapper.prefetched-iframe {
    background: initial !important; }
    .editor-layout-wrapper.prefetched-iframe .editing-area {
      background: initial !important; }

.editor-layout-header {
  flex: 0 0 auto; }

.editor-layout-body {
  position: relative;
  flex: 1 0 0;
  min-height: 0;
  overflow: hidden; }

.editor-layout-splitter-row {
  display: flex;
  flex-direction: row;
  height: 100%; }
  .editor-layout-splitter-row::before {
    content: '';
    height: 100%;
    width: var(--stage-scrollbar-width);
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fafafa; }

.editor-layout-left-bar {
  background-color: var(--bodyBackgroundColor);
  position: relative;
  flex: 0 0 auto;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) width; }
  .sections-experience .editor-layout-left-bar {
    width: var(--left-bar-width); }
  .tools-hidden .editor-layout-left-bar {
    width: 0; }
  .editor-layout-left-bar.expanded,
  .previewMode .editor-layout-left-bar {
    width: auto; }
  .editor-layout-left-bar.left-panel-hidden-elements-redesign-experiment button.hidden-items-panel span {
    background-color: #116dff; }

.editor-layout-editing-area {
  position: relative;
  flex: 1 0 0;
  height: 100%; }
  .editor-layout-editing-area.truncated {
    margin-right: calc( var(--stage-right-margin) + var(--stage-scrollbar-width));
    box-shadow: 0px -1px 8px 2px rgba(0, 0, 0, 0.08), 0px 1px 8px 2px rgba(0, 0, 0, 0.08), 2px 0px 8px 2px rgba(0, 0, 0, 0.08), -2px 0px 8px 2px rgba(0, 0, 0, 0.08); }
    .editor-layout-editing-area.truncated .preview-container {
      overflow-y: hidden; }
    .editor-layout-editing-area.truncated .scrollable-editor-stage {
      width: calc( 100% + var(--stage-right-margin) + var(--stage-scrollbar-width)); }
    .editor-layout-editing-area.truncated .site-cover {
      width: calc(100% - var(--stage-right-margin)); }
  .editor-layout-editing-area .fixed-margin-top::before {
    content: '';
    height: 100%;
    max-height: 100%;
    width: calc(var(--stage-fixed-width) * var(--site-scale));
    max-width: var(--stage-fixed-width);
    position: absolute;
    top: 0;
    left: calc( var(--stage-left-margin) + (var(--stage-fixed-width) - calc(var(--stage-fixed-width) * var(--site-scale))) / 2);
    box-shadow: 0px -1px 8px 2px rgba(0, 0, 0, 0.08), 0px 1px 8px 2px rgba(0, 0, 0, 0.08), 2px 0px 8px 2px rgba(0, 0, 0, 0.08), -2px 0px 8px 2px rgba(0, 0, 0, 0.08);
    transform: var(--stage-shift-transform);
    transition: var(--stage-shift-transition);
    pointer-events: none; }
  .editor-layout-editing-area.fixed {
    margin-right: 0;
    box-shadow: none; }
    .editor-layout-editing-area.fixed:before {
      width: 100%; }
    .editor-layout-editing-area.fixed .preview-frame-cover .site-cover {
      width: var(--stage-fixed-width);
      overflow: visible;
      box-shadow: none; }
    .editor-layout-editing-area.fixed .preview-container {
      margin-left: var(--stage-left-margin);
      overflow-x: hidden; }
      .editor-layout-editing-area.fixed .preview-container #previewPlaceholder {
        width: var(--stage-fixed-width); }
    .editor-layout-editing-area.fixed .editor-stage {
      width: 100%;
      box-shadow: none; }
    .editor-layout-editing-area.fixed .scrollable-editor-stage {
      padding-left: var(--stage-left-margin);
      box-sizing: border-box;
      width: 100%; }
      .editor-layout-editing-area.fixed .scrollable-editor-stage .scrollable-editor-stage-content {
        position: relative; }
      .editor-layout-editing-area.fixed .scrollable-editor-stage .scalable {
        width: var(--stage-fixed-width); }
    .editor-layout-editing-area.fixed .stage-zoom-mode .editor-stage {
      width: 100%;
      margin-left: 0; }
      .editor-layout-editing-area.fixed .stage-zoom-mode .editor-stage .scrollable-editor-stage {
        padding-left: var(--stage-left-margin); }
    .editor-layout-editing-area.fixed .stage-zoom-mode .scrollable-editor-stage-content {
      width: var(--stage-fixed-width); }
    .editor-layout-editing-area.fixed .stage-zoom-mode .site-cover {
      overflow: hidden; }
    .editor-layout-editing-area.fixed .anchors-container {
      width: var(--stage-fixed-width); }
    .editor-layout-editing-area.fixed .lassoWrapper {
      position: absolute;
      left: calc(-1 * var(--stage-left-margin));
      top: 0;
      width: calc( var(--stage-fixed-width) + var(--stage-left-margin) + var(--stage-right-margin));
      height: 100%; }
      .editor-layout-editing-area.fixed .lassoWrapper .lasso-layer {
        top: 0;
        left: 0;
        width: calc(100% + var(--stage-left-margin)); }
  .editor-layout-editing-area.horizontallyScrollable {
    box-shadow: none; }
    .editor-layout-editing-area.horizontallyScrollable .preview-frame-cover::before {
      width: 100%; }
    .editor-layout-editing-area.horizontallyScrollable .preview-container {
      height: calc(100% - var(--stage-scrollbar-width));
      left: var(--stage-left-margin); }
      .editor-layout-editing-area.horizontallyScrollable .preview-container #previewPlaceholder {
        max-width: var(--stage-fixed-width); }
    .editor-layout-editing-area.horizontallyScrollable .site-cover {
      width: var(--site-cover-width);
      max-width: var(--stage-fixed-width);
      left: var(--stage-left-margin); }
    .editor-layout-editing-area.horizontallyScrollable .scrollable-editor-stage {
      overflow-x: scroll; }
      .editor-layout-editing-area.horizontallyScrollable .scrollable-editor-stage .scrollable-editor-stage-content {
        width: calc(var(--stage-fixed-width) + var(--stage-right-margin)); }

.editor-content-wrapper {
  position: relative;
  top: 0;
  height: 100%;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .editor-content-wrapper .secondary-left-area {
    z-index: 10;
    overflow: hidden;
    border-right: 1px solid #b6c1cd; }
  .editor-content-wrapper.tools-hidden {
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .editor-content-wrapper .splitter {
    height: 100%;
    width: 100%; }
  .editor-content-wrapper .wix-code-left-pane {
    -webkit-user-select: none;
    user-select: none;
    z-index: 1072;
    position: relative; }
    .classic-facelift-skin .editor-content-wrapper .wix-code-left-pane {
      z-index: 1444; }
  .editor-content-wrapper .wix-code-ide-pane {
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 10px 0 16px 0 rgba(0, 0, 0, 0.14);
    z-index: 1071; }
    .editor-content-wrapper .wix-code-ide-pane.above-editor {
      z-index: 1440; }
  .editor-content-wrapper .right-pane {
    z-index: 1085; }
    .editor-content-wrapper .right-pane.right-pane-above-modals {
      z-index: 6002; }
    .classic-facelift-skin .editor-content-wrapper .right-pane {
      border-top: 1px solid #dfe5eb;
      box-sizing: border-box; }
  .editor-content-wrapper .ide-splitter .splitter-divider {
    z-index: 1070; }
  .editor-content-wrapper .file-pane-splitter .splitter-divider-handle {
    display: none; }
  .editor-content-wrapper .file-pane-splitter.file-pane-splitter-pane-0-closed > .splitter-divider {
    pointer-events: none; }
  .editor-content-wrapper .file-pane-splitter.file-pane-splitter-pane-0-hover .splitter-divider-handle:not(.closed), .editor-content-wrapper .file-pane-splitter.file-pane-splitter-divider-hover .splitter-divider-handle:not(.closed) {
    display: block; }

.editing-area {
  position: absolute;
  height: 100%;
  width: 100%; }
  .editing-area.mobile-editor {
    background-color: #f7f8f8;
    transition: background-color 1.4s ease-in-out; }
    .editing-area.mobile-editor .preview-frame-cover.mobile-wizard-preview-frame {
      animation: mobile-frame-wizard-animation 3s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards; }
      .editing-area.mobile-editor .preview-frame-cover.mobile-wizard-preview-frame .preview-container {
        overflow: hidden; }
    .editing-area.mobile-editor .preview-frame-cover.mobile-wizard-preview-frame-out {
      animation: mobile-frame-wizard-out-animation 2s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards; }
    .editing-area.mobile-editor.mobile-wizard-editing-area {
      overflow: hidden;
      background-color: #fff; }
  .editing-area .preview-container {
    -webkit-user-select: none;
    user-select: none;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    height: 100%; }
    .editing-area .preview-container .mobile-hide-preview-left,
    .editing-area .preview-container .mobile-hide-preview-right {
      display: block;
      position: absolute;
      top: 0;
      height: 100%; }
    .editing-area .preview-container .mobile-hide-preview-left {
      left: 0; }
  .editing-area.resizable-preview-mode {
    overflow-x: auto; }
    .editing-area.resizable-preview-mode .preview-frame-cover {
      display: flex;
      justify-content: center;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content; }
      .editing-area.resizable-preview-mode .preview-frame-cover::before {
        display: none; }
    .editing-area.resizable-preview-mode .preview-container {
      margin: 0 var(--preview-horizontal-margin);
      flex-shrink: 0;
      width: var(--preview-width);
      box-shadow: 0px 12px 20px 0px rgba(23, 25, 28, 0.12); }
      .editing-area.resizable-preview-mode .preview-container #previewPlaceholder {
        width: var(--preview-width); }
    .editing-area.resizable-preview-mode .preview-width-info {
      left: 0;
      text-align: center;
      padding-top: 12px;
      position: -webkit-sticky;
      position: sticky; }
    .editing-area.resizable-preview-mode.mobile-editor .preview-container {
      box-shadow: none;
      width: 100%;
      animation: fade-in linear 0.4s;
      transition: none; }
      .editing-area.resizable-preview-mode.mobile-editor .preview-container #previewPlaceholder {
        width: 100%; }
    .editing-area.resizable-preview-mode.preview-resize-animation .preview-frame-cover {
      transition: top 0.33s cubic-bezier(0.45, 0, 0.55, 1), height 0.33s cubic-bezier(0.45, 0, 0.55, 1); }
    .editing-area.resizable-preview-mode.preview-resize-animation .preview-container {
      transition: width 0.33s cubic-bezier(0.45, 0, 0.55, 1), margin 0.33s cubic-bezier(0.45, 0, 0.55, 1), height 0.33s cubic-bezier(0.45, 0, 0.55, 1); }
    .editing-area.resizable-preview-mode.preview-resize-animation #previewPlaceholder {
      transition: width 0.33s cubic-bezier(0.45, 0, 0.55, 1), height 0.33s cubic-bezier(0.45, 0, 0.55, 1); }
  .editing-area.preview-mode .preview-container {
    overflow: hidden; }
  .editing-area.lightbox-mode.new-lightbox-mode, .editing-area.translation-mode.new-translation-mode {
    box-shadow: inset 0 0 0 4px #25a55a; }
  .editing-area.new-mobile-editor-layout {
    --g-mobile-top: 18px;
    --g-mobile-bottom: 18px;
    --g-mobile-preview-max-height: 568px; }
  .editing-area.new-mobile-editor-layout .fixed-margin-top {
    --g-mobile-top: var(--stage-top); }
  .classic-facelift-skin .editing-area.lightbox-mode.new-lightbox-mode, .classic-facelift-skin .editing-area.translation-mode.new-translation-mode {
    box-shadow: none; }

@keyframes mobile-frame-wizard-animation {
  0% {
    top: var(--g-mobile-top);
    margin-top: 0;
    max-height: 100%;
    left: 0;
    width: 100%;
    opacity: 1; }
  33% {
    top: var(--g-mobile-top);
    margin-top: 0;
    max-height: 100%;
    left: 0;
    width: 100%;
    opacity: 0; }
  66% {
    top: 50%;
    margin-top: calc(var(--g-mobile-preview-max-height) / -2);
    max-height: var(--g-mobile-preview-max-height);
    left: 50%;
    width: 50%;
    opacity: 0; }
  100% {
    top: 50%;
    margin-top: calc(var(--g-mobile-preview-max-height) / -2);
    max-height: var(--g-mobile-preview-max-height);
    left: 50%;
    width: 50%;
    opacity: 1; } }

@media (max-width: 1280px) {
  @keyframes mobile-frame-wizard-animation {
    0% {
      top: var(--g-mobile-top);
      margin-top: 0;
      max-height: 100%;
      left: 0;
      width: 100%;
      opacity: 1; }
    33% {
      top: var(--g-mobile-top);
      margin-top: 0;
      max-height: 100%;
      left: 0;
      width: 100%;
      opacity: 0; }
    66% {
      top: 50%;
      margin-top: calc(var(--g-mobile-preview-max-height) / -2);
      max-height: var(--g-mobile-preview-max-height);
      width: calc(100% - 640px);
      left: 640px;
      opacity: 0; }
    100% {
      top: 50%;
      margin-top: calc(var(--g-mobile-preview-max-height) / -2);
      max-height: var(--g-mobile-preview-max-height);
      width: calc(100% - 640px);
      left: 640px;
      opacity: 1; } } }

@keyframes mobile-frame-wizard-out-animation {
  0% {
    top: 50%;
    margin-top: calc(var(--g-mobile-preview-max-height) / -2);
    max-height: var(--g-mobile-preview-max-height);
    left: 50%;
    width: 50%;
    opacity: 1; }
  33% {
    top: 50%;
    margin-top: calc(var(--g-mobile-preview-max-height) / -2);
    max-height: var(--g-mobile-preview-max-height);
    left: 50%;
    width: 50%;
    opacity: 0; }
  66% {
    top: var(--g-mobile-top);
    margin-top: 0;
    max-height: 100%;
    left: 0;
    width: 100%;
    opacity: 0; }
  100% {
    top: var(--g-mobile-top);
    margin-top: 0;
    max-height: 100%;
    left: 0;
    width: 100%;
    opacity: 1; } }

@media (max-width: 1280px) {
  @keyframes mobile-frame-wizard-out-animation {
    0% {
      top: 50%;
      margin-top: calc(var(--g-mobile-preview-max-height) / -2);
      max-height: var(--g-mobile-preview-max-height);
      width: calc(100% - 640px);
      left: 640px;
      opacity: 1; }
    33% {
      top: 50%;
      margin-top: calc(var(--g-mobile-preview-max-height) / -2);
      max-height: var(--g-mobile-preview-max-height);
      width: calc(100% - 640px);
      left: 640px;
      opacity: 0; }
    66% {
      top: var(--g-mobile-top);
      margin-top: 0;
      max-height: 100%;
      left: 0;
      width: 100%;
      opacity: 0; }
    100% {
      top: var(--g-mobile-top);
      margin-top: 0;
      max-height: 100%;
      left: 0;
      width: 100%;
      opacity: 1; } } }

.editor-stage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }
  .editor-stage.mobile-editor {
    top: var(--g-mobile-top); }

.preview-frame-cover {
  position: absolute;
  top: var(--stage-top);
  height: calc(100% - var(--stage-top) - var(--stage-bottom));
  width: 100%; }
  .preview-frame-cover.stage-zoom-mode {
    top: var(--stage-top);
    height: calc(100% - var(--stage-top) - var(--stage-bottom)); }
  .zoom-mode:not(.mobile-editor):not(.top-bar-redesign) .preview-frame-cover {
    top: 37px;
    height: calc(100% - 37px); }
  .preview-frame-cover.fixed-margin-top {
    height: var(--stage-height);
    max-height: calc(100% - var(--stage-top) - var(--stage-bottom)); }
  .mobile-editor .preview-frame-cover {
    top: var(--g-mobile-top);
    height: calc(100% - var(--g-mobile-top) - var(--g-mobile-bottom)); }
  .mobile-editor.preview-mode .preview-frame-cover {
    max-height: var(--g-mobile-preview-max-height); }
  .mobile-editor.preview-mode.new-mobile-editor-layout .preview-frame-cover {
    top: 50%;
    transform: translateY(-50%); }

.classic-facelift-skin .corvid-site-structure {
  border-top: 1px solid #dfe5eb;
  box-sizing: border-box; }

.classic-facelift-skin .wix-code-ide-pane {
  border-top: 1px solid #dfe5eb;
  box-sizing: border-box; }

.classic-facelift-skin .editor-layout-editing-area:before {
  z-index: 1450;
  content: '';
  position: absolute;
  left: 0;
  right: calc(var(--stage-right-margin) * -1);
  top: -30px;
  height: 30px;
  -webkit-clip-path: inset(30px 0 -20px 0);
          clip-path: inset(30px 0 -20px 0);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  pointer-events: none; }

.classic-facelift-skin .top-bar-wrapper > .next-main-top-bar {
  box-shadow: none; }

.anchors-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .anchors-container.mobile-editor {
    width: 320px;
    left: calc((100% - 320px) / 2); }
  .anchors-container .anchorWrapper {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    transform: translate(0, -50%);
    pointer-events: none; }
    .anchors-container .anchorWrapper .anchor-line {
      border-top: 1px solid rgba(94, 255, 255, 0.7);
      transform: scale(1.00001); }
      .anchors-container .anchorWrapper .anchor-line.before {
        flex: 1; }
        .anchors-container .anchorWrapper .anchor-line.before.anchor-selected, .anchors-container .anchorWrapper .anchor-line.before.anchor-hovered {
          border-top: 1px solid rgba(24, 210, 222, 0.7); }
      .anchors-container .anchorWrapper .anchor-line.after {
        width: 68px; }
        .anchors-container .anchorWrapper .anchor-line.after.anchor-selected, .anchors-container .anchorWrapper .anchor-line.after.anchor-hovered {
          border-top: 1px solid rgba(24, 210, 222, 0.7); }
      .anchors-container .anchorWrapper .anchor-line.in-zoom {
        border-top-width: 2px; }
    .anchors-container .anchorWrapper .anchor {
      /* anchor handle: ◀︎triangle + ◼️◼️◼️rectangle */
      height: 24px;
      width: 126px;
      pointer-events: auto;
      margin-left: 14px; }
      .anchors-container .anchorWrapper .anchor .anchorContent {
        /* ◀︎◼️◼️◼️rectangle */
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background: rgba(94, 255, 255, 0.7);
        line-height: 24px;
        text-align: left;
        box-sizing: border-box;
        height: 100%;
        cursor: move;
        position: relative; }
        .anchors-container .anchorWrapper .anchor .anchorContent:before {
          /* ◀︎triangle */
          position: absolute;
          right: 100%;
          content: '';
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 15px solid rgba(94, 255, 255, 0.7); }
      .anchors-container .anchorWrapper .anchor svg {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
        .anchors-container .anchorWrapper .anchor svg * {
          fill: #0a6363; }
      .anchors-container .anchorWrapper .anchor .anchorIcon {
        left: 2px;
        width: 13px;
        height: 15px; }
      .anchors-container .anchorWrapper .anchor .rightIcon {
        right: 12px; }
      .anchors-container .anchorWrapper .anchor .anchorName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 19px;
        width: 67px;
        color: #0a6363; }
      .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent,
      .anchors-container .anchorWrapper .anchor .anchorContent:hover {
        background: rgba(24, 210, 222, 0.7);
        color: #2d3034; }
        .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent svg *,
        .anchors-container .anchorWrapper .anchor .anchorContent:hover svg * {
          fill: #2d3034; }
        .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent:before,
        .anchors-container .anchorWrapper .anchor .anchorContent:hover:before {
          border-right-color: rgba(24, 210, 222, 0.7); }
      .anchors-container .anchorWrapper .anchor.anchor-selected .anchorName {
        color: #2d3034; }
    .anchors-container .anchorWrapper .anchor:hover .anchorName {
      color: #2d3034; }

.lasso-layer {
  width: 100%;
  height: 100%;
  position: absolute; }
  .lasso-layer .lasso-highlights {
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .lasso-layer .lasso-highlights .lasso-rect {
      fill: #116dff;
      fill-opacity: 0.24;
      stroke: #116dff;
      stroke-width: 1px; }
    .lasso-layer .lasso-highlights .lasso-candidate {
      stroke: #4a86e8;
      fill: none; }
      .lasso-layer .lasso-highlights .lasso-candidate.show-on-all-pages {
        stroke: #dc9024; }

.apply-style-panel .content {
  overflow: initial;
  padding: 0;
  overflow-x: hidden; }

.apply-style-panel .custom-scroll {
  border-left: 1px solid #d6e1e8; }

.apply-style-panel .comp-container {
  display: inline-block;
  width: 352px;
  max-height: 428px;
  background-color: #fff; }

.apply-style-panel .live-comps {
  margin-top: 91px; }

.apply-style-panel .current-style {
  border-right: 0; }
  .apply-style-panel .current-style .section-live-site-block-layer,
  .apply-style-panel .current-style .section-live-site-item {
    cursor: default !important; }

.apply-style-panel .container-wrapper {
  width: 352px;
  position: relative; }

.apply-style-panel .header {
  height: 67px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 0;
  width: 340px;
  position: absolute;
  z-index: 100;
  pointer-events: none; }
  .apply-style-panel .header.applied-styles-header {
    border-left: 1px solid #d6e1e8; }

.apply-style-panel .current-style-header {
  border-right: 0; }

.apply-style-panel footer {
  right: 30px;
  bottom: 0;
  margin-bottom: 24px; }
  .apply-style-panel footer .btn-confirm-secondary {
    margin-right: 480px; }

.apply-style-panel .text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  margin-left: 20px;
  color: #3b4057;
  line-height: 67px; }

.apply-style-panel .panel-content {
  margin-bottom: 24px;
  display: flex;
  border-bottom: 1px solid #d6e1e8;
  flex-direction: row; }

.apply-style-panel .help {
  color: #fff;
  right: 55px; }

.apply-style-panel .empty-styles {
  width: 352px;
  height: 185px;
  margin-top: 75px;
  text-align: center; }
  .apply-style-panel .empty-styles .empty-style-text {
    position: relative;
    top: 0; }

.pin-mode > .preview-rect {
  position: absolute;
  background-color: #116dff;
  opacity: 0.5;
  border: 1px dashed rgba(255, 255, 255, 0.5); }

.pin-mode > .blocking-layer {
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

.floating-mode-bar {
  position: fixed;
  min-width: 534px;
  left: calc((100vw - 534px) / 2);
  line-height: 3.4;
  top: 89px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  z-index: 1059;
  background: #25a55a;
  margin-top: 18px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0 18px 0 12px;
  height: 48px;
  min-height: 48px;
  cursor: move; }
  .floating-mode-bar.with-top-bar-banner {
    top: calc( 89px + 48px); }
  .floating-mode-bar.dragging .floating-mode-bar__main {
    pointer-events: none; }
  .floating-mode-bar__main {
    display: flex;
    justify-content: start; }
    .floating-mode-bar__main .drag-icon-wrapper {
      margin: 2px 2px 2px 3px; }
      .floating-mode-bar__main .drag-icon-wrapper > svg {
        cursor: move; }
        .floating-mode-bar__main .drag-icon-wrapper > svg path {
          fill: #397034; }
  .floating-mode-bar__title {
    line-height: 2.3;
    color: #fff;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    margin: 6px 6px 6px 10px; }
  .floating-mode-bar__secondary {
    display: flex;
    justify-content: end; }
    .floating-mode-bar__secondary .floating-help-wrapper {
      margin-top: 2px; }
      .floating-mode-bar__secondary .floating-help-wrapper .floating-mode-bar__btn-help {
        background: #4f9d49;
        color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 0;
        box-sizing: border-box;
        padding: 0; }
        .floating-mode-bar__secondary .floating-help-wrapper .floating-mode-bar__btn-help:hover {
          background: #397034; }
    .floating-mode-bar__secondary .floating-mode-bar__btn-close {
      background: #fff;
      color: #4f9d49;
      margin-left: 9px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      line-height: 1.5; }
      .floating-mode-bar__secondary .floating-mode-bar__btn-close:hover {
        background: #e1f4eb; }
    .floating-mode-bar__secondary .floating-mode-bar__btn-secondary {
      background: #4f9d49;
      color: #fff;
      height: 24px;
      margin-left: 10px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      line-height: 1.5;
      position: relative; }
      .floating-mode-bar__secondary .floating-mode-bar__btn-secondary:hover {
        background: #397034; }
    .floating-mode-bar__secondary button:disabled.floating-mode-bar__btn-secondary {
      color: #51b77b;
      background: #4f9d49; }
      .floating-mode-bar__secondary button:disabled.floating-mode-bar__btn-secondary:hover {
        color: #51b77b;
        background: #4f9d49; }
    .floating-mode-bar__secondary .floating-mode-bar__btn-secondary .button-content .playInteractionProgress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      animation-name: progress-anim;
      animation-timing-function: linear; }
    .floating-mode-bar__secondary .floating-mode-bar__btn-secondary.active .button-content .playInteractionProgress {
      background: rgba(225, 244, 235, 0.45);
      opacity: 1; }
    .floating-mode-bar__secondary .button-content svg * {
      fill: currentColor; }
    .floating-mode-bar__secondary .button-content .hide {
      visibility: collapse;
      position: absolute; }

@keyframes progress-anim {
  0% {
    width: 0%; }
  99% {
    width: 100%; }
  100% {
    width: 0%; } }
  .floating-mode-bar .title-section-wrapper {
    display: flex; }

.classic-facelift-skin .floating-mode-bar {
  height: 42px;
  min-height: 42px;
  background: #fff; }
  .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-wrapper {
    display: flex;
    align-items: center; }
    .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-wrapper .control-button.btn-sm {
      padding: 0 12px; }
  .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-help {
    background: none;
    color: #116dff; }
    .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-help:hover {
      background: none;
      color: #5999ff; }
  .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-close {
    background: #116dff;
    color: #fff;
    margin-left: 6px; }
    .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-close:hover {
      background: #5999ff; }
  .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-secondary {
    background: #e7f0ff;
    color: #116dff;
    margin-left: 6px; }
    .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-secondary:hover {
      background: #d6e6fe; }
  .classic-facelift-skin .floating-mode-bar__secondary button:disabled.floating-mode-bar__btn-secondary {
    background: #f0f0f0;
    color: #bcbcbc; }
    .classic-facelift-skin .floating-mode-bar__secondary button:disabled.floating-mode-bar__btn-secondary:hover {
      background: #f0f0f0;
      color: #bcbcbc; }
  .classic-facelift-skin .floating-mode-bar__secondary .floating-mode-bar__btn-secondary.active .button-content .playInteractionProgress {
    background: rgba(17, 109, 255, 0.2); }
  .classic-facelift-skin .floating-mode-bar .drag-icon-wrapper {
    margin: 0;
    padding-right: 10px; }
    .classic-facelift-skin .floating-mode-bar .drag-icon-wrapper > svg path {
      fill: #000624; }
  .classic-facelift-skin .floating-mode-bar.with-label-border .title-section-wrapper {
    border-right: 1px solid #dfe5eb;
    border-left: 1px solid #dfe5eb; }
  .classic-facelift-skin .floating-mode-bar__title {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: #000624; }

.classic-facelift-skin .floating-help-wrapper {
  display: flex;
  align-items: center;
  margin: 0; }

.interaction-mode-bar .interaction-icon-wrapper {
  margin: 6px;
  float: left;
  position: relative;
  top: -2px;
  left: -6px; }

.language-mode-bar__tooltip {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 18px;
  padding-bottom: 18px; }

.language-mode-bar__tooltip__preloader__wrapper {
  min-height: 125px;
  width: 253px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.language-mode-bar__tooltip__preloader {
  display: flex;
  justify-content: center; }

.language-mode-bar__tooltip__preloader__label {
  margin-top: 18px;
  display: flex;
  justify-content: center; }

.language-mode-bar__title {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.language-mode-bar__tooltip__wrapper {
  width: 253px; }

.language-mode-bar__tooltip__header {
  margin-bottom: 5px; }

.language-mode-bar__tooltip__line1 {
  margin-bottom: 12px; }

.language-mode-bar__tooltip__error__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.language-mode-bar__tooltip__error__symbol {
  flex: 1;
  margin-bottom: 12px;
  margin-top: 54px; }

.language-mode-bar__tooltip__error__message {
  flex: 1;
  text-align: center;
  margin-bottom: 12px; }

.language-mode-bar__tooltip__error__refresh {
  flex: 1;
  display: flex;
  margin-bottom: 54px; }

.language-mode-bar__tooltip__quota__line {
  flex: 1; }

.language-mode-bar__tooltip__credits__button {
  margin-bottom: 12px;
  margin-top: 12px;
  align-self: center; }

.language-mode-bar__container-auto-translate {
  display: flex;
  align-items: center;
  line-height: 1; }

.language-mode-bar__btn-auto-translate {
  color: #fff;
  height: 25px;
  width: 48px;
  border-radius: 18px;
  border: 0;
  box-sizing: border-box;
  padding: 0 10px; }
  .language-mode-bar__btn-auto-translate.disabled {
    background: #bcbcbc;
    pointer-events: none; }
  .language-mode-bar__btn-auto-translate svg {
    width: 18px;
    height: 18px; }

.language-mode-bar__tooltip__btn-auto-translation__wrapper {
  display: flex;
  margin-top: 12px; }

.language-mode-bar__auto-translated svg {
  margin-right: 6px; }

.language-mode-bar__auto-translated-label {
  margin-right: 3px;
  margin-left: 6px; }
  .language-mode-bar__auto-translated-label * {
    color: #fff; }

.language-mode-bar__now-editing {
  margin: auto 18px; }

.classic-facelift-skin .language-mode-bar__auto-translated-label * {
  color: #000624; }

.classic-facelift-skin .language-mode-bar__auto-translated path {
  fill: #000624; }

.classic-facelift-skin .language-mode-bar__btn-auto-translate {
  width: auto; }

.classic-facelift-skin .language-mode-bar__container-auto-translate {
  padding-right: 6px;
  padding-left: 18px; }

.classic-facelift-skin .language-mode-bar__btn-language {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: none;
  color: #000624; }
  .classic-facelift-skin .language-mode-bar__btn-language .size-tiny {
    font-size: 14px;
    color: #000624;
    padding-right: 3px; }
  .classic-facelift-skin .language-mode-bar__btn-language svg {
    padding-left: 24px; }
  .classic-facelift-skin .language-mode-bar__btn-language path {
    fill: #000624; }
  .classic-facelift-skin .language-mode-bar__btn-language:hover {
    background: none;
    color: #000624; }
    .classic-facelift-skin .language-mode-bar__btn-language:hover .size-tiny {
      color: #116dff; }
    .classic-facelift-skin .language-mode-bar__btn-language:hover path {
      fill: #116dff; }
  .classic-facelift-skin .language-mode-bar__btn-language:active {
    background: none;
    color: #000624; }

.classic-facelift-skin .language-mode-bar__btn-help-wrapper {
  display: flex;
  align-items: center;
  margin-right: 6px; }

.language-mode-bar__container-translation-manager-btn {
  padding-right: 12px; }

.box-slide-show-manage-slides-panel.comp-panel-content {
  height: 525px; }

.box-slide-show-manage-slides-panel .sort-by-drag-list {
  padding-bottom: 114px; }

.box-slide-show-manage-slides-panel .item-list-row {
  height: 60px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 24px 0 12px;
  border-bottom: 1px solid #dfe5eb;
  cursor: move;
  position: relative; }
  .box-slide-show-manage-slides-panel .item-list-row.compacted {
    height: 32px; }
    .box-slide-show-manage-slides-panel .item-list-row.compacted .quick-actions {
      top: 3px; }
  .box-slide-show-manage-slides-panel .item-list-row .slide-name {
    display: inline-block;
    width: 191px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle {
    width: 13px;
    height: 13px;
    padding-right: 13px; }
    .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle path {
      fill: #80b1ff; }
    .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle:hover path {
      fill: #116dff; }
  .box-slide-show-manage-slides-panel .item-list-row .item-details {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    font-size: 14px; }
  .box-slide-show-manage-slides-panel .item-list-row:hover {
    background-color: #e7f0ff; }
    .box-slide-show-manage-slides-panel .item-list-row:hover .quick-actions {
      visibility: visible; }
      .box-slide-show-manage-slides-panel .item-list-row:hover .quick-actions .context-menu-button {
        background-color: #fff; }
  .box-slide-show-manage-slides-panel .item-list-row .quick-actions {
    position: absolute;
    right: 24px;
    top: 15px;
    border: 0;
    visibility: hidden; }
    .box-slide-show-manage-slides-panel .item-list-row .quick-actions svg .cls-b1 {
      fill: #116dff; }
    .box-slide-show-manage-slides-panel .item-list-row .quick-actions:hover .context-menu-button {
      background-color: #80b1ff; }
  .box-slide-show-manage-slides-panel .item-list-row.selected {
    background-color: #d6e6fe; }
    .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions {
      visibility: visible; }
      .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions .context-menu-button {
        background-color: #fff; }
      .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions .is-open .context-menu-button {
        background-color: #80b1ff; }

.box-slide-show-manage-slides-panel .control-text-input-with-fixed-button {
  flex: 1; }

.box-slide-show-manage-slides-panel .drag-placeholder {
  background-color: #f8f8f8;
  border-top: 1px dashed #dfe5eb; }

.box-slide-show-manage-slides-panel .drag-container {
  background-color: #e7f0ff;
  box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22); }

.box-slide-show-manage-slides-panel .button-wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  height: 114px;
  width: 100%;
  position: absolute;
  text-align: center; }
  .box-slide-show-manage-slides-panel .button-wrapper .mainButton {
    top: 24px;
    position: relative;
    line-height: 26px; }
  .box-slide-show-manage-slides-panel .button-wrapper .secondaryButton {
    bottom: -30px;
    position: relative;
    font-size: 16px;
    width: 100%; }

.rulers.horizontal-ruler {
  position: absolute;
  right: 0;
  top: 0;
  width: 22px;
  height: calc(100% - 22px); }
  .rulers.horizontal-ruler.for-sections {
    z-index: 4; }
    .rulers.horizontal-ruler.for-sections .horizontal-ticks .rulers-background {
      overflow: hidden; }
  .tools-hidden .rulers.horizontal-ruler .horizontal-ticks {
    width: 0; }
  .rulers.horizontal-ruler .horizontal-ticks .rulers-separator {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    background-color: #fff;
    transition: opacity 0.15s ease-in;
    transition-delay: 0.15s;
    opacity: 0.5; }
    .rulers.horizontal-ruler .horizontal-ticks .rulers-separator.rulers-separator-hover {
      transition: opacity 0.15s ease-out;
      opacity: 0.9;
      cursor: default; }
  .rulers.horizontal-ruler .horizontal-ticks .rulers-background {
    position: absolute;
    top: 22px;
    right: 0; }
    .rulers.horizontal-ruler .horizontal-ticks .rulers-background.rulers-background-mobile {
      top: 0; }
    .rulers.horizontal-ruler .horizontal-ticks .rulers-background .rulers-horizontal-ticks {
      background: url(//static.parastorage.com/services/santa-resources/resources/editor/rEditor/rootComps/rulers/ruler_ticks_vertical.png) repeat-y;
      width: 100%;
      height: 100%; }
    .rulers.horizontal-ruler .horizontal-ticks .rulers-background .ruler-numbers-container {
      position: absolute;
      text-align: left;
      width: 100%;
      height: 100%; }
      .rulers.horizontal-ruler .horizontal-ticks .rulers-background .ruler-numbers-container .ruler-number {
        word-break: break-all;
        height: 100px;
        width: 0;
        margin-left: 4px;
        text-align: left; }
  .rulers.horizontal-ruler .horizontal-ticks:hover {
    cursor: copy; }

.rulers.vertical-ruler {
  pointer-events: auto;
  position: absolute;
  right: 22px;
  top: 0;
  height: 22px; }
  .rulers.vertical-ruler.for-sections {
    right: 0; }
  .rulers.vertical-ruler .vertical-ticks {
    width: 100%;
    height: 100%; }
    .rulers.vertical-ruler .vertical-ticks:hover {
      cursor: copy; }
    .tools-hidden .rulers.vertical-ruler .vertical-ticks {
      height: 0; }
    .rulers.vertical-ruler .vertical-ticks .rulers-separator-blocker {
      position: absolute;
      top: 0;
      right: -22px;
      height: 22px;
      width: 22px;
      cursor: default;
      z-index: 160; }
    .rulers.vertical-ruler .vertical-ticks .rulers-background .rulers-vertical-ticks {
      background: url(//static.parastorage.com/services/santa-resources/resources/editor/rEditor/rootComps/rulers/ruler_ticks.png) 0 0 repeat-x;
      width: 100%;
      height: 100%; }
    .rulers.vertical-ruler .vertical-ticks .rulers-background .ruler-numbers-container {
      position: absolute;
      bottom: 2px;
      display: flex; }
      .rulers.vertical-ruler .vertical-ticks .rulers-background .ruler-numbers-container .ruler-number {
        display: inline-block;
        width: 100px;
        text-align: left; }

.rulers .rulers-background {
  height: 100%;
  width: 100%;
  background-color: #fff;
  transition: opacity 0.15s ease-in;
  transition-delay: 0.15s;
  opacity: 0.5; }
  .rulers .rulers-background.rulers-background-hover {
    transition: opacity 0.15s ease-out;
    opacity: 0.9; }
  .rulers .rulers-background .ruler-numbers-container {
    color: #384f68;
    font-size: 9px;
    letter-spacing: 1px; }

.rulers.mobile-editor-rulers.vertical-ruler {
  top: calc(-1 * var(--g-mobile-top)); }

.rulers.mobile-editor-rulers .horizontal-ticks {
  top: 0; }
  .rulers.mobile-editor-rulers .horizontal-ticks .ruler-numbers-container {
    top: 0; }

.rulers-in-fixed-stage.vertical-ruler {
  top: var(--horizontal-ruler-top);
  left: var(--stage-left-margin); }
  .rulers-in-fixed-stage.vertical-ruler .vertical-ticks {
    width: var(--stage-fixed-width); }

.rulers-in-fixed-stage.horizontal-ruler {
  right: var(--horizontal-ruler-right); }
  .rulers-in-fixed-stage.horizontal-ruler .horizontal-ticks .rulers-background {
    top: 0;
    height: calc(100% + 22px); }

.rulers-in-horizontally-scrolllable-stage.vertical-ruler .vertical-ticks {
  width: 100%;
  overflow: hidden; }
  .rulers-in-horizontally-scrolllable-stage.vertical-ruler .vertical-ticks .rulers-background {
    width: calc(var(--stage-fixed-width) + var(--stage-right-margin));
    position: relative; }

.vertical-guide-container {
  display: inline-block;
  position: absolute;
  width: 0;
  top: 0; }
  .vertical-guide-container .vertical-guide-catcher {
    display: inline-block;
    position: absolute;
    left: -5px;
    width: 11px;
    height: 22px; }
    .vertical-guide-container .vertical-guide-catcher:hover {
      cursor: ew-resize; }
    .tools-hidden .vertical-guide-container .vertical-guide-catcher {
      height: 0; }
      .tools-hidden .vertical-guide-container .vertical-guide-catcher:hover {
        cursor: default; }
    .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler {
      z-index: 150;
      position: absolute; }
      .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler .symbol-guidesHandler_regular {
        position: absolute;
        top: 0; }
      .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler .symbol-guidesHandler_selected {
        position: absolute;
        top: 0;
        left: -2px; }
  .vertical-guide-container .vertical-guide {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #91f7fd;
    pointer-events: none; }
    .vertical-guide-container .vertical-guide:hover {
      cursor: default; }
    .mobile-editor-rulers .vertical-guide-container .vertical-guide {
      z-index: 1; }
  .classic-facelift-skin .vertical-guide-container path {
    fill: #5999ff; }

.horizontal-guide-container {
  position: absolute;
  height: 0;
  width: 0;
  right: 0; }
  .horizontal-guide-container .horizontal-guide-catcher {
    position: absolute;
    right: 0;
    top: -5px;
    height: 11px;
    width: 22px;
    overflow: hidden; }
    .horizontal-guide-container .horizontal-guide-catcher:hover {
      cursor: ns-resize; }
    .tools-hidden .horizontal-guide-container .horizontal-guide-catcher {
      width: 0; }
      .tools-hidden .horizontal-guide-container .horizontal-guide-catcher:hover {
        cursor: default; }
    .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler {
      z-index: 150;
      position: absolute;
      right: -3px;
      top: -4px; }
      .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler .symbol-guidesHandler_regular,
      .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler .symbol-guidesHandler_selected {
        transform: rotate(90deg); }
  .horizontal-guide-container .horizontal-guide {
    position: absolute;
    height: 1px;
    background-color: #91f7fd;
    pointer-events: none; }
    .horizontal-guide-container .horizontal-guide:hover {
      cursor: default; }
  .classic-facelift-skin .horizontal-guide-container path {
    fill: #5999ff; }

.permanent-component-border {
  position: absolute;
  background-image: linear-gradient(to right, #868aa5 50%, #ffffff 50%), linear-gradient(to right, #868aa5 50%, #ffffff 50%), linear-gradient(to bottom, #868aa5 50%, #ffffff 50%), linear-gradient(to bottom, #868aa5 50%, #ffffff 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px; }

.add-section-container {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none; }
  .add-section-container:before, .add-section-container:after {
    content: '';
    width: 100%;
    opacity: 0.5;
    position: absolute; }
  .add-section-container:before {
    border-top: 2px solid #116dff;
    top: -1px; }
  .add-section-container:after {
    border-bottom: 2px solid #116dff;
    bottom: -1px; }

.add-section-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  left: calc(50% - 30px / 2);
  width: 30px;
  height: 30px;
  animation: 0.2s add-section-button-fade-in;
  pointer-events: all;
  cursor: pointer; }
  .add-section-button.position-top {
    top: calc(30px / -2); }
  .add-section-button.position-bottom {
    bottom: calc(30px / -2); }
  .add-section-button:hover:before {
    transform: scale(1.15); }
  .add-section-button:before {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: #116dff;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.41), 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    width: 26px;
    height: 26px;
    will-change: transform;
    transition: transform 0.2s; }
  .add-section-button svg {
    position: absolute; }

@keyframes add-section-button-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.media-button .symbol.symbol-with-label {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 14px; }

.info-icon > .symbol circle {
  fill: transparent; }

.info-icon > .symbol:hover path {
  fill: #116dff; }

.label-info-icon-container .symbol {
  vertical-align: middle;
  margin-bottom: 1px; }
  .label-info-icon-container .symbol:hover path {
    fill: #fff; }

.symbol-addressBarMiniToolbar .c1 {
  fill: #ADB7BF; }

.symbol-arrowLeftNarrow .cls-4 {
  fill: #116dff; }

.symbol-arrowLeft {
  fill: #116dff; }
  .symbol-arrowLeft path {
    fill: #116dff; }

.symbol-arrowWithCircle .cls-4 {
  fill: #0099ef;
  stroke: #0099ef;
  stroke-width: 2px;
  opacity: 0.5;
  fill-opacity: 0; }

.symbol-arrowWithCircle .cls-5 {
  fill: #0099ef; }

.symbol-arrowWithStates .st0 {
  fill: transparent;
  stroke: #116dff; }
  .disabled.symbol-arrowWithStates .st0 {
    stroke: #bcbcbc; }

.symbol-arrowWithStates .st1 {
  fill: #116dff; }
  .disabled.symbol-arrowWithStates .st1 {
    fill: #bcbcbc; }

.symbol-arrowWithStates:hover:not(.disabled) .st0 {
  fill: #5999ff;
  stroke: #5999ff; }

.symbol-arrowWithStates:hover:not(.disabled) .st1 {
  fill: #ffffff; }

.symbol-arrowRight {
  fill: none;
  stroke-width: 1px;
  stroke: 000; }
  .section-footer .symbol-arrowRight {
    vertical-align: middle;
    margin-left: 6px;
    width: 4px;
    height: 7px; }
    .section-footer .symbol-arrowRight .c1 {
      stroke: #116dff; }

.symbol-bgImage g.image-icon .st2 {
  fill: #116dff; }

.symbol-bgImage g.image-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgImage g.image-icon:hover .st2 {
  fill: #5999ff; }

.symbol-bgImage g.image-icon:hover .st6 {
  fill: #E9F1F4; }

.symbol-bgImage .disabled g.image-icon .st2,
.symbol-bgImage .disabled:hover g.image-icon .st2 {
  fill: #A7A7A7; }

.symbol-bgImage .disabled g.image-icon .st6 .disabled:hover g.image-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgSlideShow .slideshow-icon .st2 {
  fill: #116dff; }

.symbol-bgSlideShow .slideshow-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgSlideShow .slideshow-icon:hover .st2 {
  fill: #ECF7FF; }

.symbol-bgSlideShow .slideshow-icon:hover .st6 {
  fill: #116dff; }

.symbol-bgSlideShow .slideshow-icon .disable .st2 {
  fill: #A7A7A7; }

.symbol-bgSlideShow .slideshow-icon .disable .st6 {
  fill: #E9F1F4; }

.symbol-bgVideo .video-icon .st2 {
  fill: #116dff; }

.symbol-bgVideo .video-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgVideo .video-icon:hover .st2 {
  fill: #5999ff; }

.symbol-bgVideo .video-icon:hover .st6 {
  fill: #E9F1F4; }

.disabled .video-icon .st2,
.disabled .video-icon:hover .st2 {
  fill: #F1F1F1; }

.disabled .video-icon .st6,
.disabled .video-icon:hover .st6 {
  fill: #CCCCCC; }

.symbol-bgVideoIndicator .video-indicator-icon {
  fill: #FFFFFF; }

.symbol-ArchiveDropDown .cls-4 {
  stroke: #80b1ff;
  stroke-width: 1px;
  fill: none; }

.symbol-ArchiveDropDown .cls-5 {
  fill: #80b1ff; }

.symbol-ArchiveDropDown .cls-7 {
  fill: #e7f0ff; }

.symbol-ArchiveDropDown .cls-8 {
  fill: #ffffff; }

.symbol-ArchiveList .cls-4 {
  fill: #80b1ff; }

.symbol-Masonry .cls-5 {
  stroke: #80b1ff;
  stroke-width: 1px;
  fill: none; }

.symbol-Masonry .cls-7 {
  fill: #80b1ff; }

.symbol-Masonry .cls-8 {
  fill: #b5e2fb; }

.symbol-Masonry .cls-9 {
  fill: #e7f0ff; }

.symbol-MasonryPage .cls-5 {
  stroke: #80b1ff;
  stroke-width: 1px;
  fill: none; }

.symbol-MasonryPage .cls-7 {
  fill: #80b1ff; }

.symbol-MasonryPage .cls-8 {
  fill: #b5e2fb; }

.symbol-MasonryPage .cls-9 {
  fill: #e7f0ff; }

.symbol-MediaBottom .cls-4 {
  fill: #80b1ff; }

.symbol-MediaBottom .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaBottom .cls-6 {
  fill: #e7f0ff; }

.symbol-MediaBottomPage .cls-4 {
  fill: #80b1ff; }

.symbol-MediaBottomPage .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaBottomPage .cls-6 {
  fill: #e7f0ff; }

.symbol-MediaLeft .cls-4 {
  fill: #80b1ff; }

.symbol-MediaLeft .cls-9 {
  fill: #b5e2fb; }

.symbol-MediaLeft .cls-10 {
  fill: #f7f8f8; }

.symbol-MediaLeft .cls-11 {
  fill: #e7f0ff; }

.symbol-MediaLeftPage .cls-4 {
  fill: #80b1ff; }

.symbol-MediaLeftPage .cls-9 {
  fill: #b5e2fb; }

.symbol-MediaLeftPage .cls-10 {
  fill: #f7f8f8; }

.symbol-MediaLeftPage .cls-11 {
  fill: #e7f0ff; }

.symbol-MediaRight .cls-4 {
  fill: #80b1ff; }

.symbol-MediaRight .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaRight .cls-8 {
  fill: #f7f8f8; }

.symbol-MediaRight .cls-9 {
  fill: #e7f0ff; }

.symbol-MediaRightPage .cls-4 {
  fill: #80b1ff; }

.symbol-MediaRightPage .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaRightPage .cls-8 {
  fill: #f7f8f8; }

.symbol-MediaRightPage .cls-9 {
  fill: #e7f0ff; }

.symbol-MediaTop .cls-4 {
  fill: #80b1ff; }

.symbol-MediaTop .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaTop .cls-6 {
  fill: #e7f0ff; }

.symbol-MediaTopPage .cls-4 {
  fill: #80b1ff; }

.symbol-MediaTopPage .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaTopPage .cls-6 {
  fill: #e7f0ff; }

.symbol-MediaZigzag .cls-4 {
  fill: #80b1ff; }

.symbol-MediaZigzag .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaZigzag .cls-8 {
  fill: #f7f8f8; }

.symbol-MediaZigzag .cls-9 {
  fill: #e7f0ff; }

.symbol-MediaZigzagPage .cls-4 {
  fill: #80b1ff; }

.symbol-MediaZigzagPage .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaZigzagPage .cls-8 {
  fill: #f7f8f8; }

.symbol-MediaZigzagPage .cls-9 {
  fill: #e7f0ff; }

.symbol-PostsGalleryMediaBottom .cls-4 {
  fill: #80b1ff; }

.symbol-PostsGalleryMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsGalleryMediaBottom .cls-7 {
  fill: #e7f0ff; }

.symbol-PostsGalleryMediaTop .cls-4 {
  fill: #80b1ff; }

.symbol-PostsGalleryMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsGalleryMediaTop .cls-7 {
  fill: #e7f0ff; }

.symbol-PostsList .cls-4 {
  fill: #80b1ff; }

.symbol-PostsListMediaBottom .cls-4 {
  fill: #80b1ff; }

.symbol-PostsListMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsListMediaBottom .cls-7 {
  fill: #e7f0ff; }

.symbol-PostsListMediaLeft .cls-4 {
  fill: #80b1ff; }

.symbol-PostsListMediaLeft .cls-5 {
  fill: #b5e2fb; }

.symbol-PostsListMediaLeft .cls-6 {
  fill: #e7f0ff; }

.symbol-PostsListMediaTop .cls-4 {
  fill: #80b1ff; }

.symbol-PostsListMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsListMediaTop .cls-7 {
  fill: #e7f0ff; }

.symbol-TickerMediaBottom .cls-4 {
  fill: #80b1ff; }

.symbol-TickerMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-TickerMediaBottom .cls-7 {
  fill: #e7f0ff; }

.symbol-TickerMediaTop .cls-4 {
  fill: #80b1ff; }

.symbol-TickerMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-TickerMediaTop .cls-7 {
  fill: #e7f0ff; }

.symbol-bottomTumbnails .cls-4 {
  fill: #e7f0ff; }

.symbol-bottomTumbnails .cls-5 {
  fill: #80b1ff; }

.symbol-connectNewDomain .cls-44 {
  fill: #d6e6fe; }

.symbol-connectNewDomain .cls-5 {
  fill: #ffffff; }

.symbol-connectNewDomain .cls-6 {
  fill: #5999ff; }

.symbol-defaultSkinIcon .style0 {
  font-size: 13px;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  fill: white; }

.symbol-diamond .cls-4 {
  fill: #116dff; }

.symbol-diamond .cls-5 {
  fill: #80b1ff; }

.symbol-dragHandle .cls-4 {
  fill: #868aa5; }

.symbol-firstTimeInfoBoxClose {
  stroke: #116dff; }

.symbol-firstTimeInfoBoxArrowRight {
  cursor: pointer;
  position: relative;
  padding-left: 9px;
  stroke: #116dff; }

.symbol-hangon .cls-4 {
  fill: #d6e6fe; }

.symbol-hangon .cls-5 {
  fill: #ffffff; }

.symbol-hangon .cls-6 {
  fill: #116dff; }

.symbol-hexagon .cls-4 {
  fill: #e7f0ff; }

.symbol-hexagon .cls-5 {
  fill: #80b1ff; }

.symbol-horizontalSpread .cls-4 {
  fill: #80b1ff; }

.symbol-horizontalSpread .cls-5 {
  fill: #5999ff; }

.symbol-leftTumbnails .cls-4 {
  fill: #e7f0ff; }

.symbol-leftTumbnails .cls-5 {
  fill: #80b1ff; }

.symbol-linkBtnThin path {
  fill: #fff; }

.symbol-mobile .icon {
  fill: #1D2D3C; }

.symbol-desktop .icon {
  fill: #1D2D3C; }

.symbol-noLink {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-noLink .plug-pin {
    fill: #B1DDF9; }
  .symbol-noLink .wires {
    stroke: #A7E3FA;
    stroke-width: 2; }
  .symbol-noLink .plug-body {
    fill: #A7E3FA; }
  .symbol-noLink .plug-strip {
    fill: #5FCEF6; }
  .symbol-noLink .plug-shadow {
    fill: #e7f0ff; }
  .symbol-noLink .white {
    fill: #fff; }

.symbol-noPopup {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-noPopup .shadow {
    fill: #e7f0ff; }
  .symbol-noPopup .header {
    fill: #A7E3FA; }
  .symbol-noPopup .blue {
    fill: #5FCEF5; }
  .symbol-noPopup .white {
    fill: #fff; }

.symbol-anchorBottom {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-anchorBottom #browser {
    fill: #d6e6fe; }
  .symbol-anchorBottom #bar {
    fill: #80b1ff; }
  .symbol-anchorBottom #arrow {
    stroke: #116dff;
    stroke-linecap: square; }

.symbol-anchorTop {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-anchorTop #browser {
    fill: #d6e6fe; }
  .symbol-anchorTop #bar {
    fill: #80b1ff; }
  .symbol-anchorTop #arrow {
    stroke: #116dff;
    stroke-linecap: square; }

.symbol-openLinkInWindow .cls-4 {
  fill: #0099ef; }

.symbol-pageSelector {
  fill: #fff; }
  .control-action-with-symbol > .symbol-container > .symbol-pageSelector {
    width: 12px;
    height: 12px; }

.symbol-publishSite .cls-4 {
  fill: #d6e6fe; }

.symbol-publishSite .cls-5 {
  fill: #ffffff; }

.symbol-publishSite .cls-6 {
  fill: #5999ff; }

.symbol-plusBig .circle {
  stroke: #116dff;
  fill: transparent; }
  .disabled.symbol-plusBig .circle {
    stroke: #bcbcbc; }

.symbol-plusBig .plus {
  fill: #116dff; }
  .disabled.symbol-plusBig .plus {
    fill: #bcbcbc; }

.symbol-plusBig:hover:not(.disabled) .circle {
  fill: #5999ff;
  stroke: #5999ff; }

.symbol-plusBig:hover:not(.disabled) .plus {
  fill: #ffffff; }

.symbol-threeDots .circle {
  stroke: #116dff;
  fill: transparent; }
  .disabled.symbol-threeDots .circle {
    stroke: #bcbcbc; }

.symbol-threeDots .dots {
  fill: #116dff; }
  .disabled.symbol-threeDots .dots {
    fill: #bcbcbc; }

.symbol-threeDots:hover:not(.disabled) .circle {
  fill: #5999ff;
  stroke: #5999ff; }

.symbol-threeDots:hover:not(.disabled) .dots {
  fill: #ffffff; }

.symbol-rightTumbnails .cls-4 {
  fill: #116dff; }

.symbol-rightTumbnails .cls-5 {
  fill: #80b1ff; }

.symbol-success .success-animation-shape, .symbol-success .rectangle-green-left, .symbol-success .triangle-green-left, .symbol-success .circle-yellow-left, .symbol-success .circle-yellow-external-left, .symbol-success .triangle-green-external-left, .symbol-success .triangle-yellow-left, .symbol-success .rectangle-green-right, .symbol-success .triangle-green-right, .symbol-success .circle-yellow-right, .symbol-success .circle-yellow-external-right, .symbol-success .triangle-green-external-right, .symbol-success .triangle-yellow-right {
  transform-box: fill-box;
  transform-origin: center; }

.symbol-success .success-animation-green, .symbol-success .rectangle-green-left, .symbol-success .triangle-green-left, .symbol-success .triangle-green-external-left, .symbol-success .rectangle-green-right, .symbol-success .triangle-green-right, .symbol-success .triangle-green-external-right {
  fill: #5dbb9b; }

.symbol-success .success-animation-yellow, .symbol-success .circle-yellow-left, .symbol-success .circle-yellow-external-left, .symbol-success .triangle-yellow-left, .symbol-success .circle-yellow-right, .symbol-success .circle-yellow-external-right, .symbol-success .triangle-yellow-right {
  fill: #f8d452; }

.symbol-success .rectangle-green-left {
  animation: rectangle-green-animation-left 2.3s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes rectangle-green-animation-left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(-52px) translateY(27px) rotate(-720deg); } }

.symbol-success .triangle-green-left {
  animation: triangle-green-animation-left 2.5s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-green-animation-left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(-67px) translateY(0px) rotate(-540deg); } }

.symbol-success .circle-yellow-left {
  animation: circle-yellow-animation-left 2.7s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes circle-yellow-animation-left {
  0% {
    transform: translateX(0px) translateY(0px); }
  100% {
    transform: translate(-52px, -32px); } }

.symbol-success .circle-yellow-external-left {
  animation: circle-yellow-external-animation-left 2.5s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes circle-yellow-external-animation-left {
  0% {
    transform: translateX(0px) translateY(0px); }
  100% {
    transform: translate(-71px, -13px); } }

.symbol-success .triangle-green-external-left {
  animation: triangle-green-external-animation-left 2.4s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-green-external-animation-left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translate(-57px) translateY(-10px) rotate(-650deg); } }

.symbol-success .triangle-yellow-left {
  animation: triangle-yellow-animation-left 2.6s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-yellow-animation-left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(-67px) translateY(7px) rotate(-540deg); }
  100% {
    transform: translateX(-67px) translateY(7px) rotate(-540deg); } }

.symbol-success .rectangle-green-right {
  animation: rectangle-green-animation-right 2.3s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes rectangle-green-animation-right {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(53px) translateY(27px) rotate(720deg); } }

.symbol-success .triangle-green-right {
  animation: triangle-green-animation-right 2.6s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-green-animation-right {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(53px) translateY(-2px) rotate(470deg); } }

.symbol-success .circle-yellow-right {
  animation: circle-yellow-animation-right 2.7s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes circle-yellow-animation-right {
  0% {
    transform: translateX(0px) translateY(0px); }
  100% {
    transform: translate(32px, -29px); } }

.symbol-success .circle-yellow-external-right {
  animation: circle-yellow-external-animation-right 2.4s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes circle-yellow-external-animation-right {
  0% {
    transform: translateX(0px) translateY(0px); }
  100% {
    transform: translate(74px, -18px); } }

.symbol-success .triangle-green-external-right {
  animation: triangle-green-external-animation-right 2.5s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-green-external-animation-right {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translate(70px) translateY(-4px) rotate(540deg); } }

.symbol-success .triangle-yellow-right {
  animation: triangle-yellow-animation-right 2.6s cubic-bezier(0.16, 1, 0.3, 1) forwards; }

@keyframes triangle-yellow-animation-right {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg); }
  100% {
    transform: translateX(67px) translateY(13px) rotate(720deg); } }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-4 {
  fill: #80b1ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-6 {
  fill: #e7f0ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-7 {
  fill: #5999ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-9 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-4 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-5 {
  fill: #80b1ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-7 {
  fill: #e7f0ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-9 {
  fill: #5999ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-4 {
  fill: #80b1ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-6 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-8 {
  fill: #e7f0ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-10 {
  fill: #5999ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-4 {
  fill: #80b1ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-6 {
  fill: #e7f0ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-9 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-4 {
  fill: #80b1ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-6 {
  fill: #e7f0ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-8 {
  fill: #5999ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-9 {
  fill: #ffffff; }

.symbol-sliderThumbShapeBar .st1,
.control-sliderThumbShapeBar .symbol-sliderThumbShapeBar .st1 {
  fill: #5999ff; }

.selected .symbol-sliderThumbShapeBar .st1,
.control-sliderThumbShapeBar .input-sliderThumbShapeBar:checked ~ .symbol-sliderThumbShapeBar .st1 {
  fill: #d6e6fe; }

.symbol-sliderThumbShapeCircle .st1,
.control-sliderThumbShapeCircle .symbol-sliderThumbShapeCircle .st1 {
  fill: #5999ff; }

.selected .symbol-sliderThumbShapeCircle .st1,
.control-sliderThumbShapeCircle .input-sliderThumbShapeCircle:checked ~ .symbol-sliderThumbShapeCircle .st1 {
  fill: #d6e6fe; }

.symbol-sliderThumbShapeRectangle .st1,
.control-sliderThumbShapeRectangle .symbol-sliderThumbShapeRectangle .st1 {
  fill: #5999ff; }

.selected .symbol-sliderThumbShapeRectangle .st1,
.control-sliderThumbShapeRectangle .input-sliderThumbShapeRectangle:checked ~ .symbol-sliderThumbShapeRectangle .st1 {
  fill: #d6e6fe; }

.symbol-sliderThumbShapeSquare .st1,
.control-sliderThumbShapeSquare .symbol-sliderThumbShapeSquare .st1 {
  fill: #5999ff; }

.selected .symbol-sliderThumbShapeSquare .st1,
.control-sliderThumbShapeSquare .input-sliderThumbShapeSquare:checked ~ .symbol-sliderThumbShapeSquare .st1 {
  fill: #d6e6fe; }

.symbol-sliderTooltipVisibilityAlways .st1,
.control-sliderTooltipVisibilityAlways .symbol-sliderTooltipVisibilityAlways .st1 {
  fill: #5999ff; }

.symbol-sliderTooltipVisibilityAlways .st2,
.control-sliderTooltipVisibilityAlways .symbol-sliderTooltipVisibilityAlways .st2 {
  fill: #d6e6fe; }

.selected .symbol-sliderTooltipVisibilityAlways .st1,
.control-sliderTooltipVisibilityAlways .input-sliderTooltipVisibilityAlways:checked ~ .symbol-sliderTooltipVisibilityAlways .st1 {
  fill: #d6e6fe; }

.selected .symbol-sliderTooltipVisibilityAlways .st2,
.control-sliderTooltipVisibilityAlways .input-sliderTooltipVisibilityAlways:checked ~ .symbol-sliderTooltipVisibilityAlways .st2 {
  fill: #5999ff; }

.symbol-sliderTooltipVisibilityHover .st1,
.control-sliderTooltipVisibilityHover .symbol-sliderTooltipVisibilityHover .st1 {
  fill: #5999ff; }

.symbol-sliderTooltipVisibilityHover .st2,
.control-sliderTooltipVisibilityHover .symbol-sliderTooltipVisibilityHover .st2 {
  fill: #d6e6fe; }

.selected .symbol-sliderTooltipVisibilityHover .st1,
.control-sliderTooltipVisibilityHover .input-sliderTooltipVisibilityHover:checked ~ .symbol-sliderTooltipVisibilityHover .st1 {
  fill: #d6e6fe; }

.selected .symbol-sliderTooltipVisibilityHover .st2,
.control-sliderTooltipVisibilityHover .input-sliderTooltipVisibilityHover:checked ~ .symbol-sliderTooltipVisibilityHover .st2 {
  fill: #5999ff; }

.symbol-sliderTickMarksShapeLine .st1,
.control-sliderTickMarksShapeLine .symbol-sliderTickMarksShapeLine .st1 {
  fill: #5999ff; }

.symbol-sliderTickMarksShapeLine .st2,
.control-sliderTickMarksShapeLine .symbol-sliderTickMarksShapeLine .st2 {
  fill: #d6e6fe; }

.selected .symbol-sliderTickMarksShapeLine .st1,
.control-sliderTickMarksShapeLine .input-sliderTickMarksShapeLine:checked ~ .symbol-sliderTickMarksShapeLine .st1 {
  fill: #d6e6fe; }

.selected .symbol-sliderTickMarksShapeLine .st2,
.control-sliderTickMarksShapeLine .input-sliderTickMarksShapeLine:checked ~ .symbol-sliderTickMarksShapeLine .st2 {
  fill: #5999ff; }

.symbol-sliderTickMarksShapeDot .st1,
.control-sliderTickMarksShapeDot .symbol-sliderTickMarksShapeDot .st1 {
  fill: #5999ff; }

.symbol-sliderTickMarksShapeDot .st2,
.control-sliderTickMarksShapeDot .symbol-sliderTickMarksShapeDot .st2 {
  fill: #d6e6fe; }

.selected .symbol-sliderTickMarksShapeDot .st1,
.control-sliderTickMarksShapeDot .input-sliderTickMarksShapeDot:checked ~ .symbol-sliderTickMarksShapeDot .st1 {
  fill: #d6e6fe; }

.selected .symbol-sliderTickMarksShapeDot .st2,
.control-sliderTickMarksShapeDot .input-sliderTickMarksShapeDot:checked ~ .symbol-sliderTickMarksShapeDot .st2 {
  fill: #5999ff; }

.symbol-spreadLayout .cls-3 {
  fill: #5999ff; }

.symbol-spreadLayout .cls-4 {
  fill: #80b1ff; }

.symbol-spreadLayout .cls-6 {
  fill: #d6e6fe; }

.symbol-square .cls-4 {
  fill: #e7f0ff; }

.symbol-square .cls-5 {
  fill: #80b1ff; }

.symbol-successIcon {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  fill: #25a55a; }

.symbol-stackLayout .cls-3 {
  fill: #d6e6fe; }

.symbol-stackLayout .cls-4 {
  fill: #80b1ff; }

.symbol-stackLayout .cls-5 {
  fill: #5999ff; }

.symbol-topTumbnails .cls-4 {
  fill: #e7f0ff; }

.symbol-topTumbnails .cls-5 {
  fill: #80b1ff; }

.symbol-topbarDevicePromo .cls-3 {
  fill: #e7f0ff; }

.symbol-topbarDevicePromo .cls-4 {
  fill: #80b1ff; }

.checkmark-symbol {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  background-color: #ffffff; }
  .top-bar-edit-mode-last-btn .checkmark-symbol {
    background-color: #116dff; }
  .top-bar-edit-mode-last-btn:hover .checkmark-symbol {
    background-color: #5999ff; }
  .top-bar-edit-mode-last-btn.app-studio-package .checkmark-symbol {
    background-color: #2d3034; }
  .top-bar-edit-mode-last-btn.app-studio-package:hover .checkmark-symbol {
    background-color: #3b4057; }
  .checkmark-symbol .check {
    fill: #116dff; }
    .checkmark-symbol .check.app-studio-package {
      fill: #2d3034; }
    .top-bar-edit-mode-last-btn .checkmark-symbol .check {
      fill: #ffffff; }

.symbol-triangle .cls-4 {
  fill: #e7f0ff; }

.symbol-triangle .cls-5 {
  fill: #80b1ff; }

.symbol-vCyclePicker .cls-2 {
  fill: #116dff; }

.symbol-verticalSpread .cls-4 {
  fill: #80b1ff; }

.symbol-verticalSpread .cls-5 {
  fill: #5999ff; }

.symbol-videoLayoutShow .st1 {
  fill: #80b1ff; }

.symbol-videoLayoutShow .st2 {
  fill: #e7f0ff; }

.symbol-videoLayoutHide .st1 {
  fill: #80b1ff; }

.symbol-videoLayoutHide .st2 {
  fill: #e7f0ff; }

.classic-facelift-skin .control-button.btn-text svg * {
  fill: #000000; }

.symbol-videoPlayerStoryboardNone .st1,
.control-videoPlayerStoryboardNone .symbol-videoPlayerStoryboardNone .st1 {
  fill: #80b1ff;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardNone .st2,
.control-videoPlayerStoryboardNone .symbol-videoPlayerStoryboardNone .st2 {
  fill: #5999ff; }

.selected .symbol-videoPlayerStoryboardNone .st1,
.control-videoPlayerStoryboardNone .input-videoPlayerStoryboardNone:checked ~ .symbol-videoPlayerStoryboardNone .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardNone .st2,
.control-videoPlayerStoryboardNone .input-videoPlayerStoryboardNone:checked ~ .symbol-videoPlayerStoryboardNone .st2 {
  fill: #d6e6fe; }

.symbol-videoPlayerStoryboardPreview .st1,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st1 {
  fill: #80b1ff;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardPreview .st2,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st2 {
  fill: #5999ff; }

.symbol-videoPlayerStoryboardPreview .st3,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st3 {
  fill: #5999ff; }

.symbol-videoPlayerStoryboardPreview .st4,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st4 {
  fill: #d6e6fe; }

.symbol-videoPlayerStoryboardPreview .st5,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st5 {
  fill: #80b1ff;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardPreview .st6,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st6 {
  fill: #80b1ff; }

.symbol-videoPlayerStoryboardPreview .st7,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st7 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st1,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st2,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st2 {
  fill: #d6e6fe; }

.selected .symbol-videoPlayerStoryboardPreview .st3,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st3 {
  fill: #d6e6fe; }

.selected .symbol-videoPlayerStoryboardPreview .st4,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st4 {
  fill: #80b1ff; }

.selected .symbol-videoPlayerStoryboardPreview .st5,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st5 {
  fill: #e7f0ff; }

.selected .symbol-videoPlayerStoryboardPreview .st6,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st6 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st7,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st7 {
  fill: #5999ff; }

.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview {
  pointer-events: none; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st1 {
    fill: #d5d5d5;
    opacity: 0.6; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st2 {
    fill: #c8c8c8; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st3 {
    fill: #c8c8c8; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st4 {
    fill: #e2e2e2; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st5 {
    fill: #d5d5d5;
    opacity: 0.6; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st6 {
    fill: #d5d5d5; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st7 {
    fill: #fff; }

.symbol-videoPlayerStoryboardTime .st1,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st1 {
  fill: #80b1ff;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardTime .st2,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st2 {
  fill: #5999ff; }

.symbol-videoPlayerStoryboardTime .st3,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st3 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardTime .st1,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardTime .st2,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st2 {
  fill: #d6e6fe; }

.selected .symbol-videoPlayerStoryboardTime .st3,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st3 {
  fill: #5999ff; }

.symbol-video-player-change-poster .st1 {
  fill: #116dff; }

.symbol-video-player-change-poster .st2 {
  fill: #fff; }

.symbol-video-player-change-poster .st3 {
  fill: #fff; }

.symbol-video-player-change-poster:hover .st1 {
  fill: #80b1ff; }

.symbol-video-player-change-poster:hover .st2 {
  fill: #fff; }

.symbol-video-player-change-poster:hover .st3 {
  fill: #fff; }

.symbol-video-player-recolor .st1 {
  fill: #116dff; }

.symbol-video-player-recolor .st2 {
  fill: #fff; }

.symbol-video-player-recolor .st3 {
  fill: #fff; }

.symbol-video-player-recolor:hover .st1 {
  fill: #80b1ff; }

.symbol-video-player-recolor:hover .st2 {
  fill: #fff; }

.symbol-video-player-recolor:hover .st3 {
  fill: #fff; }

.symbol-video-player-upload .st1 {
  fill: #e7f0ff; }

.symbol-video-player-upload .st2 {
  fill: #116dff; }

.symbol-addThumbnail .line {
  fill: #116dff; }

.symbol-zoomClose {
  stroke: none;
  stroke-width: 1px;
  fill: none; }
  .symbol-zoomClose .c1 {
    fill: #DFF2DD; }
  .symbol-zoomClose .c2 {
    stroke: #468B42; }

.symbol-zoomClose:hover .c1 {
  fill: #cfebcc; }

.symbol-zoomBarHelp .c1 {
  fill: #2D4150; }

.symbol-zoomBarHelp:hover .c1 {
  fill: #3899EC; }

.symbol-transition-gallery-crossfade .st2,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-crossfade .st3,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st3 {
  fill: #CDEBFD; }

.symbol-transition-gallery-crossfade .st4,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st4 {
  fill: #E3F3FF; }

.symbol-transition-gallery-crossfade .st5,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st5 {
  fill: #7DCAF9; }

.symbol-transition-gallery-crossfade .st6,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st6 {
  fill: #6AC2F8; }

.symbol-transition-gallery-crossfade .st7,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st7 {
  fill: #74C6F8; }

.symbol-transition-gallery-crossfade .st8,
.symbol-transition-gallery-crossfade .st9,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st8,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st9 {
  fill: #7AC9F9; }

.symbol-transition-gallery-crossfade .st10,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st10 {
  fill: #B6E1FC; }

.symbol-transition-gallery-crossfade .st11,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st11 {
  fill: #A2D9FB; }

.symbol-transition-gallery-crossfade .st12,
.symbol-transition-gallery-crossfade .st16,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st12,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st16 {
  fill: #C8E8FE; }

.symbol-transition-gallery-crossfade .st13,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st13 {
  fill: #D1ECFE; }

.symbol-transition-gallery-crossfade .st14,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st14 {
  fill: #D8EFFF; }

.symbol-transition-gallery-crossfade .st15,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st15 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-crossfade .st2,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-crossfade .st3,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-gallery-crossfade .st4,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-gallery-crossfade .st5,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-crossfade .st6,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-crossfade .st7,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-gallery-crossfade .st8,
.selected .symbol-transition-gallery-crossfade .st9,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st8,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-gallery-crossfade .st10,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-crossfade .st11,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-crossfade .st12,
.selected .symbol-transition-gallery-crossfade .st16,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st12,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st16 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-crossfade .st13,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st13 {
  fill: #76C5F7; }

.selected .symbol-transition-gallery-crossfade .st14,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st14 {
  fill: #72C3F6; }

.selected .symbol-transition-gallery-crossfade .st15,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st15 {
  fill: #6ABDF4; }

.symbol-transition-gallery-fade .st2,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-fade .st3,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st3 {
  fill: #CDEBFD; }

.symbol-transition-gallery-fade .st4,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st4 {
  fill: #E3F3FF; }

.symbol-transition-gallery-fade .st5,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st5 {
  fill: #7DCAF9; }

.symbol-transition-gallery-fade .st6,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st6 {
  fill: #6AC2F8; }

.symbol-transition-gallery-fade .st7,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st7 {
  fill: #74C6F8; }

.symbol-transition-gallery-fade .st8,
.symbol-transition-gallery-fade .st9,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st8,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st9 {
  fill: #7AC9F9; }

.symbol-transition-gallery-fade .st10,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st10 {
  fill: #B6E1FC; }

.symbol-transition-gallery-fade .st11,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st11 {
  fill: #A2D9FB; }

.symbol-transition-gallery-fade .st12,
.symbol-transition-gallery-fade .st16,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st12,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st16 {
  fill: #C8E8FE; }

.symbol-transition-gallery-fade .st13,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st13 {
  fill: #D1ECFE; }

.symbol-transition-gallery-fade .st14,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st14 {
  fill: #D8EFFF; }

.symbol-transition-gallery-fade .st15,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st15 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-fade .st2,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-fade .st3,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-gallery-fade .st4,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-gallery-fade .st5,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-fade .st6,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-fade .st7,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-gallery-fade .st8,
.selected .symbol-transition-gallery-fade .st9,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st8,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-gallery-fade .st10,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-fade .st11,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-fade .st12,
.selected .symbol-transition-gallery-fade .st16,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st12,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st16 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-fade .st13,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st13 {
  fill: #76C5F7; }

.selected .symbol-transition-gallery-fade .st14,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st14 {
  fill: #72C3F6; }

.selected .symbol-transition-gallery-fade .st15,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st15 {
  fill: #6ABDF4; }

.symbol-transition-gallery-horizontal .st2,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-horizontal .st3,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-horizontal .st4,
.symbol-transition-gallery-horizontal .st5,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st4,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-horizontal .st6,
.symbol-transition-gallery-horizontal .st7,
.symbol-transition-gallery-horizontal .st8,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st6,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st7,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st8 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-horizontal .st2,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-horizontal .st3,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-horizontal .st4,
.selected .symbol-transition-gallery-horizontal .st5,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st4,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-horizontal .st6,
.selected .symbol-transition-gallery-horizontal .st7,
.selected .symbol-transition-gallery-horizontal .st8,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st6,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st7,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st8 {
  fill: #FFFFFF; }

.symbol-transition-gallery-inout .st2,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-inout .st3,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st3 {
  fill: #7DCAF9; }

.symbol-transition-gallery-inout .st4,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st4 {
  fill: #6AC2F8; }

.symbol-transition-gallery-inout .st5,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st5 {
  fill: #B6E1FC; }

.symbol-transition-gallery-inout .st6,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st6 {
  fill: #A2D9FB; }

.symbol-transition-gallery-inout .st7,
.symbol-transition-gallery-inout .st9,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st7,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st9 {
  fill: #C8E8FE; }

.symbol-transition-gallery-inout .st8,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st8 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-inout .st2,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-inout .st3,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st3 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-inout .st4,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st4 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-inout .st5,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st5 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-inout .st6,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st6 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-inout .st7,
.selected .symbol-transition-gallery-inout .st9,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st7,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st9 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-inout .st8,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st8 {
  fill: #6ABDF4; }

.symbol-transition-gallery-popabove .st2,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popabove .st3,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popabove .st4,
.symbol-transition-gallery-popabove .st5,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st4,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-popabove .st6,
.symbol-transition-gallery-popabove .st7,
.symbol-transition-gallery-popabove .st8,
.symbol-transition-gallery-popabove .st9,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st6,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st7,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st8,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-popabove .st2,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-popabove .st3,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popabove .st4,
.selected .symbol-transition-gallery-popabove .st5,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st4,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-popabove .st6,
.selected .symbol-transition-gallery-popabove .st7,
.selected .symbol-transition-gallery-popabove .st8,
.selected .symbol-transition-gallery-popabove .st9,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st6,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st7,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st8,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st9 {
  fill: #FFFFFF; }

.symbol-transition-gallery-popleft .st2,
.symbol-transition-gallery-popleft .st3,
.symbol-transition-gallery-popleft .st4,
.symbol-transition-gallery-popleft .st9,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st2,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st3,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st4,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st9 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popleft .st5,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st5 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popleft .st6,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st6 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popleft .st7,
.symbol-transition-gallery-popleft .st8,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st7,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st8 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-popleft .st2,
.selected .symbol-transition-gallery-popleft .st3,
.selected .symbol-transition-gallery-popleft .st4,
.selected .symbol-transition-gallery-popleft .st9,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st2,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st3,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st4,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st9 {
  fill: #FFFFFF; }

.selected .symbol-transition-gallery-popleft .st5,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st5 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-popleft .st6,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st6 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popleft .st7,
.selected .symbol-transition-gallery-popleft .st8,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st7,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st8 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popright .st2,
.symbol-transition-gallery-popright .st3,
.symbol-transition-gallery-popright .st4,
.symbol-transition-gallery-popright .st9,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st2,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st3,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st4,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st9 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popright .st5,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st5 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popright .st6,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st6 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popright .st7,
.symbol-transition-gallery-popright .st8,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st7,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st8 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-popright .st2,
.selected .symbol-transition-gallery-popright .st3,
.selected .symbol-transition-gallery-popright .st4,
.selected .symbol-transition-gallery-popright .st9,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st2,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st3,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st4,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st9 {
  fill: #FFFFFF; }

.selected .symbol-transition-gallery-popright .st5,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st5 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-popright .st6,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st6 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popright .st7,
.selected .symbol-transition-gallery-popright .st8,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st7,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st8 {
  fill: #7FCCF8; }

.symbol-transition-gallery-random .st2,
.control-transition-gallery-random .symbol-transition-gallery-random .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-random .st3,
.control-transition-gallery-random .symbol-transition-gallery-random .st3 {
  fill: #8ED1F9; }

.symbol-transition-gallery-random .st4,
.control-transition-gallery-random .symbol-transition-gallery-random .st4 {
  fill: #4EB7F6; }

.symbol-transition-gallery-random .st5,
.control-transition-gallery-random .symbol-transition-gallery-random .st5 {
  fill: #9ED7FB; }

.symbol-transition-gallery-random .st6,
.symbol-transition-gallery-random .st7,
.control-transition-gallery-random .symbol-transition-gallery-random .st6,
.control-transition-gallery-random .symbol-transition-gallery-random .st7 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-random .st2,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-random .st3,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st3 {
  fill: #95CBF8; }

.selected .symbol-transition-gallery-random .st4,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st4 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-random .st5,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st5 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-random .st6,
.selected .symbol-transition-gallery-random .st7,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st6,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st7 {
  fill: #7FCCF8; }

.symbol-transition-gallery-rollabove .st2,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-rollabove .st3,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st3 {
  fill: #B6E1FB; }

.symbol-transition-gallery-rollabove .st4,
.symbol-transition-gallery-rollabove .st5,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st4,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-rollabove .st6,
.symbol-transition-gallery-rollabove .st7,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st6,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st7 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-rollabove .st2,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-rollabove .st3,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-rollabove .st4,
.selected .symbol-transition-gallery-rollabove .st5,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st4,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-rollabove .st6,
.selected .symbol-transition-gallery-rollabove .st7,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st6,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st7 {
  fill: #FFFFFF; }

.symbol-transition-gallery-shrink .st2,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-shrink .st3,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-shrink .st4,
.symbol-transition-gallery-shrink .st5,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st4,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-shrink .st6,
.symbol-transition-gallery-shrink .st7,
.symbol-transition-gallery-shrink .st8,
.symbol-transition-gallery-shrink .st9,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st6,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st7,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st8,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-shrink .st2,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-shrink .st3,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-shrink .st4,
.selected .symbol-transition-gallery-shrink .st5,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st4,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-shrink .st6,
.selected .symbol-transition-gallery-shrink .st7,
.selected .symbol-transition-gallery-shrink .st8,
.selected .symbol-transition-gallery-shrink .st9,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st6,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st7,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st8,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st9 {
  fill: #FFFFFF; }

.symbol-transition-gallery-vertical .st2,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-vertical .st3,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-vertical .st4,
.symbol-transition-gallery-vertical .st5,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st4,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-vertical .st6,
.symbol-transition-gallery-vertical .st7,
.symbol-transition-gallery-vertical .st8,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st6,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st7,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st8 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-vertical .st2,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-vertical .st3,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-vertical .st4,
.selected .symbol-transition-gallery-vertical .st5,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st4,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-vertical .st6,
.selected .symbol-transition-gallery-vertical .st7,
.selected .symbol-transition-gallery-vertical .st8,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st6,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st7,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st8 {
  fill: #FFFFFF; }

.symbol-transition-gallery-zoom .st2,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-zoom .st3,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-zoom .st4,
.symbol-transition-gallery-zoom .st5,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st4,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st5 {
  fill: #d6e6fe; }

.symbol-transition-gallery-zoom .st6,
.symbol-transition-gallery-zoom .st7,
.symbol-transition-gallery-zoom .st8,
.symbol-transition-gallery-zoom .st9,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st6,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st7,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st8,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-zoom .st2,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-gallery-zoom .st3,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-zoom .st4,
.selected .symbol-transition-gallery-zoom .st5,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st4,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-zoom .st6,
.selected .symbol-transition-gallery-zoom .st7,
.selected .symbol-transition-gallery-zoom .st8,
.selected .symbol-transition-gallery-zoom .st9,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st6,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st7,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st8,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st9 {
  fill: #FFFFFF; }

.symbol-transition-imagebutton-dissolve .st2,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st2 {
  fill: #ADDDFB; }

.symbol-transition-imagebutton-dissolve .st3,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st3 {
  fill: #CDEBFD; }

.symbol-transition-imagebutton-dissolve .st4,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st4 {
  fill: #E3F3FF; }

.symbol-transition-imagebutton-dissolve .st5,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st5 {
  fill: #7DCAF9; }

.symbol-transition-imagebutton-dissolve .st6,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st6 {
  fill: #6AC2F8; }

.symbol-transition-imagebutton-dissolve .st7,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st7 {
  fill: #74C6F8; }

.symbol-transition-imagebutton-dissolve .st8,
.symbol-transition-imagebutton-dissolve .st9,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st8,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st9 {
  fill: #7AC9F9; }

.symbol-transition-imagebutton-dissolve .st10,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st10 {
  fill: #B6E1FC; }

.symbol-transition-imagebutton-dissolve .st11,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st11 {
  fill: #A2D9FB; }

.symbol-transition-imagebutton-dissolve .st12,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st12 {
  fill: #B3DFFC; }

.symbol-transition-imagebutton-dissolve .st13,
.symbol-transition-imagebutton-dissolve .st17,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st13,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st17 {
  fill: #C8E8FE; }

.symbol-transition-imagebutton-dissolve .st14,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st14 {
  fill: #D1ECFE; }

.symbol-transition-imagebutton-dissolve .st15,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st15 {
  fill: #D8EFFF; }

.symbol-transition-imagebutton-dissolve .st16,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st16 {
  fill: #DBF0FF; }

.selected .symbol-transition-imagebutton-dissolve .st2,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-imagebutton-dissolve .st3,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-imagebutton-dissolve .st4,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-imagebutton-dissolve .st5,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-imagebutton-dissolve .st6,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-imagebutton-dissolve .st7,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-imagebutton-dissolve .st8,
.selected .symbol-transition-imagebutton-dissolve .st9,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st8,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-imagebutton-dissolve .st10,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-imagebutton-dissolve .st11,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-imagebutton-dissolve .st12,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st12 {
  fill: #8ACCF8; }

.selected .symbol-transition-imagebutton-dissolve .st13,
.selected .symbol-transition-imagebutton-dissolve .st17,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st13,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st17 {
  fill: #7CC7F7; }

.selected .symbol-transition-imagebutton-dissolve .st14,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st14 {
  fill: #76C5F7; }

.selected .symbol-transition-imagebutton-dissolve .st15,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st15 {
  fill: #72C3F6; }

.selected .symbol-transition-imagebutton-dissolve .st16,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st16 {
  fill: #6ABDF4; }

.symbol-transition-pages-crossfade .st2,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st2 {
  fill: #9DD7FA; }

.symbol-transition-pages-crossfade .st3,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st3 {
  fill: #B6E1FB; }

.symbol-transition-pages-crossfade .st4,
.symbol-transition-pages-crossfade .st5,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st4,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st5 {
  fill: #E0F2FF; }

.symbol-transition-pages-crossfade .st6,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st6 {
  fill: #ADDDFB; }

.symbol-transition-pages-crossfade .st7,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st7 {
  fill: #7DCAF9; }

.symbol-transition-pages-crossfade .st8,
.symbol-transition-pages-crossfade .st9,
.symbol-transition-pages-crossfade .st10,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st8,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st9,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st10 {
  fill: #A6DAFB; }

.symbol-transition-pages-crossfade .st11,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st11 {
  fill: #91D3F9; }

.symbol-transition-pages-crossfade .st12,
.symbol-transition-pages-crossfade .st14,
.symbol-transition-pages-crossfade .st15,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st12,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st14,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st15 {
  fill: #E3F3FF; }

.symbol-transition-pages-crossfade .st13,
.symbol-transition-pages-crossfade .st16,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st13,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st16 {
  fill: #B3E0FC; }

.symbol-transition-pages-crossfade .st17,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st17 {
  fill: #DBF0FF; }

.selected .symbol-transition-pages-crossfade .st2,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st2 {
  fill: #86C3F7; }

.selected .symbol-transition-pages-crossfade .st3,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st3 {
  fill: #9CCCF7; }

.selected .symbol-transition-pages-crossfade .st4,
.selected .symbol-transition-pages-crossfade .st5,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st4,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st5 {
  fill: #5CB3F2; }

.selected .symbol-transition-pages-crossfade .st6,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st6 {
  fill: #76BBF4; }

.selected .symbol-transition-pages-crossfade .st7,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st7 {
  fill: #A5D4FA; }

.selected .symbol-transition-pages-crossfade .st8,
.selected .symbol-transition-pages-crossfade .st9,
.selected .symbol-transition-pages-crossfade .st10,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st8,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st9,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st10 {
  fill: #8CCAF8; }

.selected .symbol-transition-pages-crossfade .st11,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st11 {
  fill: #B6DBFA; }

.selected .symbol-transition-pages-crossfade .st12,
.selected .symbol-transition-pages-crossfade .st14,
.selected .symbol-transition-pages-crossfade .st15,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st12,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st14,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st15 {
  fill: #54ADF0; }

.selected .symbol-transition-pages-crossfade .st13,
.selected .symbol-transition-pages-crossfade .st16,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st13,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st16 {
  fill: #83C7F7; }

.selected .symbol-transition-pages-crossfade .st17,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st17 {
  fill: #6ABDF4; }

.symbol-transition-pages-horizontal .st2,
.symbol-transition-pages-horizontal .st3,
.symbol-transition-pages-horizontal .st4,
.symbol-transition-pages-horizontal .st6,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st2,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st3,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st4,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st6 {
  fill: #7FCCF8; }

.symbol-transition-pages-horizontal .st5,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st5 {
  fill: #4EB7F6; }

.symbol-transition-pages-horizontal .st7,
.symbol-transition-pages-horizontal .st8,
.symbol-transition-pages-horizontal .st9,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st7,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st8,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st9 {
  fill: #d6e6fe; }

.selected .symbol-transition-pages-horizontal .st2,
.selected .symbol-transition-pages-horizontal .st3,
.selected .symbol-transition-pages-horizontal .st4,
.selected .symbol-transition-pages-horizontal .st6,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st2,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st3,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st4,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st6 {
  fill: #FFFFFF; }

.selected .symbol-transition-pages-horizontal .st5,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st5 {
  fill: #d6e6fe; }

.selected .symbol-transition-pages-horizontal .st7,
.selected .symbol-transition-pages-horizontal .st8,
.selected .symbol-transition-pages-horizontal .st9,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st7,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st8,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st9 {
  fill: #7FCCF8; }

.symbol-transition-pages-inout .st2,
.control-transition-pages-inout .symbol-transition-pages-inout .st2 {
  fill: #79C8F9; }

.symbol-transition-pages-inout .st3,
.control-transition-pages-inout .symbol-transition-pages-inout .st3 {
  fill: #9DD8F9; }

.symbol-transition-pages-inout .st4,
.control-transition-pages-inout .symbol-transition-pages-inout .st4 {
  fill: #8ED1F9; }

.symbol-transition-pages-inout .st5,
.control-transition-pages-inout .symbol-transition-pages-inout .st5 {
  fill: #5FBEF7; }

.symbol-transition-pages-inout .st6,
.control-transition-pages-inout .symbol-transition-pages-inout .st6 {
  fill: #7DCAF8; }

.symbol-transition-pages-inout .st7,
.symbol-transition-pages-inout .st9,
.symbol-transition-pages-inout .st10,
.control-transition-pages-inout .symbol-transition-pages-inout .st7,
.control-transition-pages-inout .symbol-transition-pages-inout .st9,
.control-transition-pages-inout .symbol-transition-pages-inout .st10 {
  fill: #DDF1FF; }

.symbol-transition-pages-inout .st8,
.symbol-transition-pages-inout .st11,
.control-transition-pages-inout .symbol-transition-pages-inout .st8,
.control-transition-pages-inout .symbol-transition-pages-inout .st11 {
  fill: #AFDEFC; }

.selected .symbol-transition-pages-inout .st2,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st2 {
  fill: #A9D6FA; }

.selected .symbol-transition-pages-inout .st3,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st3 {
  fill: #C9E3FA; }

.selected .symbol-transition-pages-inout .st4,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st4 {
  fill: #95CBF8; }

.selected .symbol-transition-pages-inout .st5,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st5 {
  fill: #C2E4FD; }

.selected .symbol-transition-pages-inout .st6,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st6 {
  fill: #DDEFFD; }

.selected .symbol-transition-pages-inout .st7,
.selected .symbol-transition-pages-inout .st9,
.selected .symbol-transition-pages-inout .st10,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st7,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st9,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st10 {
  fill: #63B8F3; }

.selected .symbol-transition-pages-inout .st8,
.selected .symbol-transition-pages-inout .st11,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st8,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st11 {
  fill: #90D0F9; }

.symbol-transition-pages-vertical .st2,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st2 {
  fill: #4eb7f6; }

.symbol-transition-pages-vertical .st3,
.symbol-transition-pages-vertical .st7,
.symbol-transition-pages-vertical .st8,
.symbol-transition-pages-vertical .st9,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st3,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st7,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st8,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st9 {
  fill: #7fccf8; }

.symbol-transition-pages-vertical .st4,
.symbol-transition-pages-vertical .st5,
.symbol-transition-pages-vertical .st6,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st4,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st5,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st6 {
  fill: #d6e6fe; }

.selected .symbol-transition-pages-vertical .st2,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st2 {
  fill: #d6e6fe; }

.selected .symbol-transition-pages-vertical .st3,
.selected .symbol-transition-pages-vertical .st7,
.selected .symbol-transition-pages-vertical .st8,
.selected .symbol-transition-pages-vertical .st9,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st3,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st7,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st8,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st9 {
  fill: #ffffff; }

.selected .symbol-transition-pages-vertical .st4,
.selected .symbol-transition-pages-vertical .st5,
.selected .symbol-transition-pages-vertical .st6,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st4,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st5,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st6 {
  fill: #7fccf8; }

.symbol-transition-none .st2,
.control-transition-none .symbol-transition-none .st2 {
  stroke: #e62214;
  stroke-width: 2; }

.symbol-transition-none .st3,
.control-transition-none .symbol-transition-none .st3 {
  fill: transparent; }

.selected .symbol-transition-none,
.control-transition-none .input-transition-none:checked ~ .symbol-transition-none {
  background-color: #e7f0ff; }
  .selected .symbol-transition-none .st2,
  .control-transition-none .input-transition-none:checked ~ .symbol-transition-none .st2 {
    stroke: #e62214;
    stroke-width: 2; }
  .selected .symbol-transition-none .st3,
  .control-transition-none .input-transition-none:checked ~ .symbol-transition-none .st3 {
    fill: #116dff; }

.symbol-settings-circle .st0,
.control-settings-circle .symbol-settings-circle .st0 {
  fill: #000624;
  fill-opacity: 0.5;
  stroke: #fff;
  stroke-width: 1; }

.symbol-settings-circle .st1,
.control-settings-circle .symbol-settings-circle .st1 {
  fill: #fff; }

.symbol-settings-circle .st2,
.control-settings-circle .symbol-settings-circle .st2 {
  fill: transparent; }

.control-settings-circle:hover .symbol-settings-circle .st0 {
  fill: #e7f0ff; }

.control-settings-circle:active .symbol-settings-circle .st0 {
  fill: #fff; }

.control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle {
  pointer-events: none; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st0 {
    fill: #bcbcbc;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st1 {
    fill: #fff;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st2 {
    fill: transparent; }

.selected .symbol-settings-circle .st0,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st0 {
  fill: #116dff; }

.selected .symbol-settings-circle .st1,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st1 {
  fill: transparent; }

.selected .symbol-settings-circle .st2,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st2 {
  fill: #fff; }

.control-settings-circle:hover .input-settings-circle:not(:disabled):checked ~ .symbol-settings-circle .st0 {
  fill: #5999ff; }

.control-settings-circle:active .input-settings-circle:checked ~ .symbol-settings-circle .st0 {
  fill: #116dff; }

.control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle {
  pointer-events: none; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st0 {
    fill: #bcbcbc;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st1 {
    fill: transparent; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st2 {
    fill: #fff;
    opacity: 0.4; }

.symbol-settings .st0,
.control-settings .symbol-settings .st0 {
  fill: #fff; }

.control-settings:hover .symbol-settings .st0 {
  fill: #116dff; }

.control-settings:active .symbol-settings .st0 {
  fill: #fff; }

.control-settings .input-settings:disabled ~ .symbol-settings {
  pointer-events: none; }
  .control-settings .input-settings:disabled ~ .symbol-settings .st0 {
    fill: #bcbcbc; }

.selected .symbol-settings .st0,
.control-settings .input-settings:checked ~ .symbol-settings .st0 {
  fill: #fff; }

.control-settings:hover .input-settings:not(:disabled):checked ~ .symbol-settings .st0 {
  fill: #116dff; }

.control-settings:active .input-settings:checked ~ .symbol-settings .st0 {
  fill: #fff; }

.control-settings .input-settings:checked:disabled ~ .symbol-settings {
  pointer-events: none; }
  .control-settings .input-settings:checked:disabled ~ .symbol-settings .st0 {
    fill: #bcbcbc; }

.symbol-play-video .st0,
.control-play-video .symbol-play-video .st0 {
  fill: #fff; }

.symbol-play-video .st1,
.control-play-video .symbol-play-video .st1 {
  fill: transparent; }

.control-play-video:hover .symbol-play-video .st0 {
  fill: #116dff; }

.control-play-video:active .symbol-play-video .st0 {
  fill: #fff; }

.control-play-video .input-play-video:disabled ~ .symbol-play-video {
  pointer-events: none; }
  .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
    fill: #bcbcbc; }

.selected .symbol-play-video .st0,
.control-play-video .input-play-video:checked ~ .symbol-play-video .st0 {
  fill: transparent; }

.selected .symbol-play-video .st1,
.control-play-video .input-play-video:checked ~ .symbol-play-video .st1 {
  fill: #fff; }

.control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
  fill: #116dff; }

.control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
  fill: #fff; }

.control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
  pointer-events: none; }
  .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
    fill: #bcbcbc; }

.symbol-play .st0,
.control-play .symbol-play .st0 {
  fill: transparent;
  stroke: #116dff;
  stroke-width: 1.15;
  opacity: 0.5; }

.symbol-play .st1,
.control-play .symbol-play .st1 {
  fill: #116dff; }

.symbol-play .st2,
.control-play .symbol-play .st2 {
  fill: transparent; }

.symbol-play .st3,
.control-play .symbol-play .st3 {
  fill: transparent;
  stroke: #116dff;
  stroke-width: 1.15; }

.control-play:hover .symbol-play .st0 {
  fill: #e7f0ff; }

.selected .symbol-play .st0,
.control-play .input-play:checked ~ .symbol-play .st0 {
  fill: transparent;
  stroke: #116dff;
  stroke-width: 1.15;
  opacity: 0.5; }

.selected .symbol-play .st1,
.control-play .input-play:checked ~ .symbol-play .st1 {
  fill: transparent; }

.selected .symbol-play .st2,
.control-play .input-play:checked ~ .symbol-play .st2 {
  fill: #116dff; }

.selected .symbol-play .st3,
.control-play .input-play:checked ~ .symbol-play .st3 {
  fill: transparent;
  stroke: #116dff;
  stroke-width: 1.15; }

.control-play:hover .input-play:not(:disabled):checked ~ .symbol-play .st0 {
  fill: #e7f0ff; }

.symbol-thumb-none .st1,
.control-thumb-none .symbol-thumb-none .st1 {
  stroke: #e62214;
  stroke-width: 2; }

.symbol-thumb-none .st2,
.control-thumb-none .symbol-thumb-none .st2 {
  fill: transparent; }

.selected .symbol-thumb-none .st1,
.control-thumb-none .input-thumb-none:checked ~ .symbol-thumb-none .st1 {
  stroke: #e62214;
  stroke-width: 2; }

.selected .symbol-thumb-none .st2,
.control-thumb-none .input-thumb-none:checked ~ .symbol-thumb-none .st2 {
  fill: #116dff; }

.symbol-single-bottom-border .st0,
.control-single-bottom-border .symbol-single-bottom-border .st0 {
  stroke: #80b1ff;
  fill: none; }

.symbol-single-bottom-border .st1,
.control-single-bottom-border .symbol-single-bottom-border .st1 {
  fill: #5999ff; }

.selected .symbol-single-bottom-border .st0,
.control-single-bottom-border .input-single-bottom-border:checked ~ .symbol-single-bottom-border .st0 {
  stroke: #d6e6fe;
  fill: none; }

.selected .symbol-single-bottom-border .st1,
.control-single-bottom-border .input-single-bottom-border:checked ~ .symbol-single-bottom-border .st1 {
  fill: #d6e6fe; }

.symbol-all-sides-border .st0,
.control-all-sides-border .symbol-all-sides-border .st0 {
  stroke: #80b1ff;
  fill: none; }

.symbol-all-sides-border .st1,
.control-all-sides-border .symbol-all-sides-border .st1 {
  fill: #5999ff; }

.selected .symbol-all-sides-border .st0,
.control-all-sides-border .input-all-sides-border:checked ~ .symbol-all-sides-border .st0 {
  stroke: #d6e6fe;
  fill: none; }

.selected .symbol-all-sides-border .st1,
.control-all-sides-border .input-all-sides-border:checked ~ .symbol-all-sides-border .st1 {
  fill: #d6e6fe; }

.symbol-bp-no-border .st0,
.control-bp-no-border .symbol-bp-no-border .st0 {
  fill: #5999ff; }

.selected .symbol-bp-no-border .st0,
.control-bp-no-border .input-bp-no-border:checked ~ .symbol-bp-no-border .st0 {
  fill: #d6e6fe; }

.symbol-bp-single-bottom-border .st0,
.control-bp-single-bottom-border .symbol-bp-single-bottom-border .st0 {
  fill: #80b1ff; }

.symbol-bp-single-bottom-border .st1,
.control-bp-single-bottom-border .symbol-bp-single-bottom-border .st1 {
  fill: #5999ff; }

.selected .symbol-bp-single-bottom-border .st0,
.control-bp-single-bottom-border .input-bp-single-bottom-border:checked ~ .symbol-bp-single-bottom-border .st0 {
  fill: #d6e6fe; }

.selected .symbol-bp-single-bottom-border .st1,
.control-bp-single-bottom-border .input-bp-single-bottom-border:checked ~ .symbol-bp-single-bottom-border .st1 {
  fill: #d6e6fe; }

.symbol-bp-all-sides-border .st0,
.control-bp-all-sides-border .symbol-bp-all-sides-border .st0 {
  stroke: #80b1ff;
  fill: none; }

.symbol-bp-all-sides-border .st1,
.control-bp-all-sides-border .symbol-bp-all-sides-border .st1 {
  fill: #5999ff; }

.selected .symbol-bp-all-sides-border .st0,
.control-bp-all-sides-border .input-bp-all-sides-border:checked ~ .symbol-bp-all-sides-border .st0 {
  stroke: #d6e6fe;
  fill: none; }

.selected .symbol-bp-all-sides-border .st1,
.control-bp-all-sides-border .input-bp-all-sides-border:checked ~ .symbol-bp-all-sides-border .st1 {
  fill: #d6e6fe; }

.symbol-video-layout-dark .st1,
.control-video-layout-dark .symbol-video-layout-dark .st1 {
  fill: #80b1ff; }

.symbol-video-layout-dark .st2,
.control-video-layout-dark .symbol-video-layout-dark .st2 {
  fill: #116dff; }

.symbol-video-layout-dark .st3,
.control-video-layout-dark .symbol-video-layout-dark .st3 {
  fill: #e7f0ff; }

.symbol-video-layout-light .st1,
.control-video-layout-light .symbol-video-layout-light .st1 {
  fill: #80b1ff; }

.symbol-video-layout-light .st2,
.control-video-layout-light .symbol-video-layout-light .st2 {
  fill: #d6e6fe; }

.symbol-video-layout-light .st3,
.control-video-layout-light .symbol-video-layout-light .st3 {
  fill: #e7f0ff; }

.symbol-video-layout-show .st1,
.control-video-layout-show .symbol-video-layout-show .st1 {
  fill: #80b1ff; }

.symbol-video-layout-show .st2,
.control-video-layout-show .symbol-video-layout-show .st2 {
  fill: #e7f0ff; }

.symbol-video-layout-hide .st1,
.control-video-layout-hide .symbol-video-layout-hide .st1 {
  fill: #80b1ff; }

.symbol-video-layout-hide .st2,
.control-video-layout-hide .symbol-video-layout-hide .st2 {
  fill: #e7f0ff; }

.symbol-aspect-ratio-1-1 .st1,
.control-aspect-ratio-1-1 .symbol-aspect-ratio-1-1 .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-1-1 .st1,
.control-aspect-ratio-1-1 .input-aspect-ratio-1-1:checked ~ .symbol-aspect-ratio-1-1 .st1 {
  fill: #e7f0ff; }

.symbol-aspect-ratio-3-4,
.control-aspect-ratio-3-4 .symbol-aspect-ratio-3-4 {
  transform: rotate(90deg); }
  .symbol-aspect-ratio-3-4 .st1,
  .control-aspect-ratio-3-4 .symbol-aspect-ratio-3-4 .st1 {
    fill: #80b1ff;
    transition: fill 0.2s; }

.selected .symbol-aspect-ratio-3-4 .st1,
.control-aspect-ratio-3-4 .input-aspect-ratio-3-4:checked ~ .symbol-aspect-ratio-3-4 .st1 {
  fill: #e7f0ff; }

.symbol-aspect-ratio-4-3 .st1,
.control-aspect-ratio-4-3 .symbol-aspect-ratio-4-3 .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-4-3 .st1,
.control-aspect-ratio-4-3 .input-aspect-ratio-4-3:checked ~ .symbol-aspect-ratio-4-3 .st1 {
  fill: #e7f0ff; }

.symbol-aspect-ratio-9-16,
.control-aspect-ratio-9-16 .symbol-aspect-ratio-9-16 {
  transform: rotate(90deg); }
  .symbol-aspect-ratio-9-16 .st1,
  .control-aspect-ratio-9-16 .symbol-aspect-ratio-9-16 .st1 {
    fill: #80b1ff;
    transition: fill 0.2s; }

.selected .symbol-aspect-ratio-9-16 .st1,
.control-aspect-ratio-9-16 .input-aspect-ratio-9-16:checked ~ .symbol-aspect-ratio-9-16 .st1 {
  fill: #e7f0ff; }

.symbol-aspect-ratio-16-9 .st1,
.control-aspect-ratio-16-9 .symbol-aspect-ratio-16-9 .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-16-9 .st1,
.control-aspect-ratio-16-9 .input-aspect-ratio-16-9:checked ~ .symbol-aspect-ratio-16-9 .st1 {
  fill: #e7f0ff; }

.symbol-size-small .st1,
.control-size-small .symbol-size-small .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-size-small .st1,
.control-size-small .input-size-small:checked ~ .symbol-size-small .st1 {
  fill: #e7f0ff; }

.symbol-size-medium .st1,
.control-size-medium .symbol-size-medium .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-size-medium .st1,
.control-size-medium .input-size-medium:checked ~ .symbol-size-medium .st1 {
  fill: #e7f0ff; }

.symbol-size-standard .st1,
.control-size-standard .symbol-size-standard .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-size-standard .st1,
.control-size-standard .input-size-standard:checked ~ .symbol-size-standard .st1 {
  fill: #e7f0ff; }

.symbol-size-wide .st1,
.control-size-wide .symbol-size-wide .st1 {
  fill: #80b1ff;
  transition: fill 0.2s; }

.selected .symbol-size-wide .st1,
.control-size-wide .input-size-wide:checked ~ .symbol-size-wide .st1 {
  fill: #e7f0ff; }

.symbol-position-bottom .st1,
.control-position-bottom .symbol-position-bottom .st1 {
  fill: #80b1ff; }

.selected .symbol-position-bottom .st1,
.control-position-bottom .input-position-bottom:checked ~ .symbol-position-bottom .st1 {
  fill: #e7f0ff; }

.symbol-position-left .st1,
.control-position-left .symbol-position-left .st1 {
  fill: #80b1ff; }

.selected .symbol-position-left .st1,
.control-position-left .input-position-left:checked ~ .symbol-position-left .st1 {
  fill: #e7f0ff; }

.symbol-position-right .st1,
.control-position-right .symbol-position-right .st1 {
  fill: #80b1ff; }

.selected .symbol-position-right .st1,
.control-position-right .input-position-right:checked ~ .symbol-position-right .st1 {
  fill: #e7f0ff; }

.symbol-position-top .st1,
.control-position-top .symbol-position-top .st1 {
  fill: #80b1ff; }

.selected .symbol-position-top .st1,
.control-position-top .input-position-top:checked ~ .symbol-position-top .st1 {
  fill: #e7f0ff; }

.symbol-shape-hexagon .st1,
.control-shape-hexagon .symbol-shape-hexagon .st1 {
  fill: #116dff; }

.selected .symbol-shape-hexagon .st1,
.control-shape-hexagon .input-shape-hexagon:checked ~ .symbol-shape-hexagon .st1 {
  fill: #e7f0ff; }

.symbol-shape-rectangle .st1,
.control-shape-rectangle .symbol-shape-rectangle .st1 {
  fill: #116dff; }

.selected .symbol-shape-rectangle .st1,
.control-shape-rectangle .input-shape-rectangle:checked ~ .symbol-shape-rectangle .st1 {
  fill: #e7f0ff; }

.symbol-shape-rhombus .st1,
.control-shape-rhombus .symbol-shape-rhombus .st1 {
  fill: #116dff; }

.selected .symbol-shape-rhombus .st1,
.control-shape-rhombus .input-shape-rhombus:checked ~ .symbol-shape-rhombus .st1 {
  fill: #e7f0ff; }

.symbol-shape-triangle .st1,
.control-shape-triangle .symbol-shape-triangle .st1 {
  fill: #116dff; }

.selected .symbol-shape-triangle .st1,
.control-shape-triangle .input-shape-triangle:checked ~ .symbol-shape-triangle .st1 {
  fill: #e7f0ff; }

.symbol-imageScaleCrop .st1,
.control-imageScaleCrop .symbol-imageScaleCrop .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleCrop .st2,
.control-imageScaleCrop .symbol-imageScaleCrop .st2 {
  fill: #D8F0FD; }

.symbol-imageScaleCrop .st3,
.control-imageScaleCrop .symbol-imageScaleCrop .st3 {
  fill: #d6e6fe; }

.symbol-imageScaleCrop .st4,
.control-imageScaleCrop .symbol-imageScaleCrop .st4 {
  fill: #A4DAFB; }

.selected .symbol-imageScaleCrop .st1,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st1 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleCrop .st2,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st2 {
  fill-opacity: 0.3; }

.selected .symbol-imageScaleCrop .st3,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st3 {
  fill: #7FCCF8; }

.selected .symbol-imageScaleCrop .st4,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st4 {
  fill: #A1D9FA; }

.symbol-imageScaleFit .st1,
.control-imageScaleFit .symbol-imageScaleFit .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleFit .st2,
.control-imageScaleFit .symbol-imageScaleFit .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleFit .st3,
.control-imageScaleFit .symbol-imageScaleFit .st3 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleFit .st1,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st1 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleFit .st2,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleFit .st3,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st3 {
  fill: #7FCCF8; }

.symbol-imageScaleStretch .st1,
.control-imageScaleStretch .symbol-imageScaleStretch .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleStretch .st2,
.control-imageScaleStretch .symbol-imageScaleStretch .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleStretch .st3,
.control-imageScaleStretch .symbol-imageScaleStretch .st3 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleStretch .st1,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st1 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleStretch .st2,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleStretch .st3,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st3 {
  fill: #7FCCF8; }

.symbol-imageScaleCenter .st1,
.control-imageScaleCenter .symbol-imageScaleCenter .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleCenter .st2,
.control-imageScaleCenter .symbol-imageScaleCenter .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleCenter .st3,
.control-imageScaleCenter .symbol-imageScaleCenter .st3 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleCenter .st1,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st1 {
  fill: #d6e6fe; }

.selected .symbol-imageScaleCenter .st2,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleCenter .st3,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st3 {
  fill: #7FCCF8; }

.symbol-alignLeft,
.control-alignLeft .symbol-alignLeft {
  shape-rendering: crispEdges; }
  .symbol-alignLeft path,
  .control-alignLeft .symbol-alignLeft path {
    transition: fill 0.2s; }
  .symbol-alignLeft .cls-b1,
  .control-alignLeft .symbol-alignLeft .cls-b1 {
    fill: #116dff; }
  .symbol-alignLeft .cls-b3,
  .control-alignLeft .symbol-alignLeft .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignLeft .cls-b1,
.control-alignLeft .input-alignLeft:checked ~ .symbol-alignLeft .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignLeft .cls-b3,
.control-alignLeft .input-alignLeft:checked ~ .symbol-alignLeft .cls-b3 {
  fill: #e7f0ff; }

.symbol-alignCenter,
.control-alignCenter .symbol-alignCenter {
  shape-rendering: crispEdges; }
  .symbol-alignCenter path,
  .control-alignCenter .symbol-alignCenter path {
    transition: fill 0.2s; }
  .symbol-alignCenter .cls-b1,
  .control-alignCenter .symbol-alignCenter .cls-b1 {
    fill: #116dff; }
  .symbol-alignCenter .cls-b3,
  .control-alignCenter .symbol-alignCenter .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignCenter .cls-b1,
.control-alignCenter .input-alignCenter:checked ~ .symbol-alignCenter .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignCenter .cls-b3,
.control-alignCenter .input-alignCenter:checked ~ .symbol-alignCenter .cls-b3 {
  fill: #e7f0ff; }

.symbol-alignRight,
.control-alignRight .symbol-alignRight {
  shape-rendering: crispEdges; }
  .symbol-alignRight path,
  .control-alignRight .symbol-alignRight path {
    transition: fill 0.2s; }
  .symbol-alignRight .cls-b1,
  .control-alignRight .symbol-alignRight .cls-b1 {
    fill: #116dff; }
  .symbol-alignRight .cls-b3,
  .control-alignRight .symbol-alignRight .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignRight .cls-b1,
.control-alignRight .input-alignRight:checked ~ .symbol-alignRight .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignRight .cls-b3,
.control-alignRight .input-alignRight:checked ~ .symbol-alignRight .cls-b3 {
  fill: #e7f0ff; }

.symbol-alignTop,
.control-alignTop .symbol-alignTop {
  shape-rendering: crispEdges; }
  .symbol-alignTop path,
  .control-alignTop .symbol-alignTop path {
    transition: fill 0.2s; }
  .symbol-alignTop .cls-b1,
  .control-alignTop .symbol-alignTop .cls-b1 {
    fill: #116dff; }
  .symbol-alignTop .cls-b3,
  .control-alignTop .symbol-alignTop .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignTop .cls-b1,
.control-alignTop .input-alignTop:checked ~ .symbol-alignTop .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignTop .cls-b3,
.control-alignTop .input-alignTop:checked ~ .symbol-alignTop .cls-b3 {
  fill: #e7f0ff; }

.symbol-alignMiddle,
.control-alignMiddle .symbol-alignMiddle {
  shape-rendering: crispEdges; }
  .symbol-alignMiddle path,
  .control-alignMiddle .symbol-alignMiddle path {
    transition: fill 0.2s; }
  .symbol-alignMiddle .cls-b1,
  .control-alignMiddle .symbol-alignMiddle .cls-b1 {
    fill: #116dff; }
  .symbol-alignMiddle .cls-b3,
  .control-alignMiddle .symbol-alignMiddle .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignMiddle .cls-b1,
.control-alignMiddle .input-alignMiddle:checked ~ .symbol-alignMiddle .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignMiddle .cls-b3,
.control-alignMiddle .input-alignMiddle:checked ~ .symbol-alignMiddle .cls-b3 {
  fill: #e7f0ff; }

.symbol-alignBottom,
.control-alignBottom .symbol-alignBottom {
  shape-rendering: crispEdges; }
  .symbol-alignBottom path,
  .control-alignBottom .symbol-alignBottom path {
    transition: fill 0.2s; }
  .symbol-alignBottom .cls-b1,
  .control-alignBottom .symbol-alignBottom .cls-b1 {
    fill: #116dff; }
  .symbol-alignBottom .cls-b3,
  .control-alignBottom .symbol-alignBottom .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-alignBottom .cls-b1,
.control-alignBottom .input-alignBottom:checked ~ .symbol-alignBottom .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-alignBottom .cls-b3,
.control-alignBottom .input-alignBottom:checked ~ .symbol-alignBottom .cls-b3 {
  fill: #e7f0ff; }

.symbol-align-center .st1,
.control-align-center .symbol-align-center .st1 {
  fill: #116dff; }

.symbol-align-center .st2,
.control-align-center .symbol-align-center .st2 {
  fill: #80b1ff; }

.selected .symbol-align-center .st1,
.control-align-center .input-align-center:checked ~ .symbol-align-center .st1 {
  fill: #80b1ff; }

.selected .symbol-align-center .st2,
.control-align-center .input-align-center:checked ~ .symbol-align-center .st2 {
  fill: #d6e6fe; }

.symbol-align-right .st1,
.control-align-right .symbol-align-right .st1 {
  fill: #116dff; }

.symbol-align-right .st2,
.control-align-right .symbol-align-right .st2 {
  fill: #80b1ff; }

.selected .symbol-align-right .st1,
.control-align-right .input-align-right:checked ~ .symbol-align-right .st1 {
  fill: #80b1ff; }

.selected .symbol-align-right .st2,
.control-align-right .input-align-right:checked ~ .symbol-align-right .st2 {
  fill: #d6e6fe; }

.symbol-align-left .st1,
.control-align-left .symbol-align-left .st1 {
  fill: #116dff; }

.symbol-align-left .st2,
.control-align-left .symbol-align-left .st2 {
  fill: #80b1ff; }

.selected .symbol-align-left .st1,
.control-align-left .input-align-left:checked ~ .symbol-align-left .st1 {
  fill: #80b1ff; }

.selected .symbol-align-left .st2,
.control-align-left .input-align-left:checked ~ .symbol-align-left .st2 {
  fill: #d6e6fe; }

.symbol-align-row-left .st1,
.control-align-row-left .symbol-align-row-left .st1 {
  fill: #5999ff; }

.symbol-align-row-left .st2,
.control-align-row-left .symbol-align-row-left .st2 {
  fill: #5999ff; }

.symbol-align-row-left .st3,
.control-align-row-left .symbol-align-row-left .st3 {
  fill: #a8caff; }

.selected .symbol-align-row-left .st1,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st1 {
  fill: #d6e6fe; }

.selected .symbol-align-row-left .st2,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st2 {
  fill: #d6e6fe; }

.selected .symbol-align-row-left .st3,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st3 {
  fill: #80b1ff; }

.symbol-align-row-right .st1,
.control-align-row-right .symbol-align-row-right .st1 {
  fill: #5999ff; }

.symbol-align-row-right .st2,
.control-align-row-right .symbol-align-row-right .st2 {
  fill: #5999ff; }

.symbol-align-row-right .st3,
.control-align-row-right .symbol-align-row-right .st3 {
  fill: #a8caff; }

.selected .symbol-align-row-right .st1,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st1 {
  fill: #d6e6fe; }

.selected .symbol-align-row-right .st2,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st2 {
  fill: #d6e6fe; }

.selected .symbol-align-row-right .st3,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st3 {
  fill: #80b1ff; }

.symbol-positionLeft,
.control-positionLeft .symbol-positionLeft {
  shape-rendering: crispEdges; }
  .symbol-positionLeft .cls-b1,
  .control-positionLeft .symbol-positionLeft .cls-b1 {
    fill: #116dff; }
  .symbol-positionLeft .cls-b3,
  .control-positionLeft .symbol-positionLeft .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-positionLeft .cls-b1,
.control-positionLeft .input-positionLeft:checked ~ .symbol-positionLeft .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-positionLeft .cls-b3,
.control-positionLeft .input-positionLeft:checked ~ .symbol-positionLeft .cls-b3 {
  fill: #e7f0ff; }

.symbol-positionRight,
.control-positionRight .symbol-positionRight {
  shape-rendering: crispEdges; }
  .symbol-positionRight .cls-b1,
  .control-positionRight .symbol-positionRight .cls-b1 {
    fill: #116dff; }
  .symbol-positionRight .cls-b3,
  .control-positionRight .symbol-positionRight .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-positionRight .cls-b1,
.control-positionRight .input-positionRight:checked ~ .symbol-positionRight .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-positionRight .cls-b3,
.control-positionRight .input-positionRight:checked ~ .symbol-positionRight .cls-b3 {
  fill: #e7f0ff; }

.symbol-positionBottom,
.control-positionBottom .symbol-positionBottom {
  shape-rendering: crispEdges; }
  .symbol-positionBottom .cls-b1,
  .control-positionBottom .symbol-positionBottom .cls-b1 {
    fill: #116dff; }
  .symbol-positionBottom .cls-b3,
  .control-positionBottom .symbol-positionBottom .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-positionBottom .cls-b1,
.control-positionBottom .input-positionBottom:checked ~ .symbol-positionBottom .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-positionBottom .cls-b3,
.control-positionBottom .input-positionBottom:checked ~ .symbol-positionBottom .cls-b3 {
  fill: #e7f0ff; }

.symbol-positionTop,
.control-positionTop .symbol-positionTop {
  shape-rendering: crispEdges; }
  .symbol-positionTop .cls-b1,
  .control-positionTop .symbol-positionTop .cls-b1 {
    fill: #116dff; }
  .symbol-positionTop .cls-b3,
  .control-positionTop .symbol-positionTop .cls-b3 {
    fill: #80b1ff; }

.selected .symbol-positionTop .cls-b1,
.control-positionTop .input-positionTop:checked ~ .symbol-positionTop .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-positionTop .cls-b3,
.control-positionTop .input-positionTop:checked ~ .symbol-positionTop .cls-b3 {
  fill: #e7f0ff; }

.symbol-save2 .st1,
.control-save2 .symbol-save2 .st1 {
  fill: #2d3034; }

.symbol-save2 .st2,
.control-save2 .symbol-save2 .st2 {
  fill: transparent; }

.control-save2:hover .symbol-save2 .st1 {
  fill: #5999ff; }

.control-save2:hover .symbol-save2 .st2 {
  fill: transparent; }

.control-save2:active .symbol-save2 .st1 {
  fill: #2d3034; }

.control-save2:active .symbol-save2 .st2 {
  fill: transparent; }

.control-save2 .input-save2:disabled ~ .symbol-save2 {
  pointer-events: none; }
  .control-save2 .input-save2:disabled ~ .symbol-save2 .st1 {
    fill: #898989; }
  .control-save2 .input-save2:disabled ~ .symbol-save2 .st2 {
    fill: transparent; }

.control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 {
  pointer-events: none; }
  .control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 .st1 {
    fill: transparent; }
  .control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 .st2 {
    fill: #116dff; }

.symbol-save-bld .st1,
.control-save-bld .symbol-save-bld .st1 {
  fill: #2d3034; }

.symbol-save-bld .st2,
.control-save-bld .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld:hover .symbol-save-bld .st1 {
  fill: #5999ff; }

.control-save-bld:hover .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld:active .symbol-save-bld .st1 {
  fill: #2d3034; }

.control-save-bld:active .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld .input-save-bld:disabled ~ .symbol-save-bld {
  pointer-events: none; }
  .control-save-bld .input-save-bld:disabled ~ .symbol-save-bld .st1 {
    fill: #898989; }
  .control-save-bld .input-save-bld:disabled ~ .symbol-save-bld .st2 {
    fill: transparent; }

.control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld {
  pointer-events: none; }
  .control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld .st1 {
    fill: transparent; }
  .control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld .st2 {
    fill: #116dff; }

.control-button2.delete-circle .st0 {
  fill: #fff; }

.control-button2.delete-circle .st1 {
  fill: #116dff; }

.control-button2.delete-circle .st2 {
  fill: transparent; }

.control-button2.delete-circle:hover .st0 {
  fill: #e7f0ff; }

.control-button2.delete-circle:active .st0 {
  fill: #fff; }

.control-button2.delete-circle[disabled] .st0 {
  fill: #bcbcbc;
  opacity: 0.4; }

.control-button2.delete-circle[disabled] .st1 {
  fill: #fff;
  opacity: 0.4; }

.control-button2.delete-circle[disabled] .st2 {
  fill: transparent; }

.control-button2.image-btn .st0 {
  fill: #116dff; }

.control-button2.image-btn:hover .st0 {
  fill: #116dff; }

.control-button2.image-btn:active .st0 {
  fill: #116dff; }

.control-button2.image-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.video-btn .st0 {
  fill: #116dff; }

.control-button2.video-btn:hover .st0 {
  fill: #116dff; }

.control-button2.video-btn:active .st0 {
  fill: #116dff; }

.control-button2.video-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.slideShow-btn .st0 {
  fill: #116dff; }

.control-button2.slideShow-btn .st1 {
  fill: #fff; }

.control-button2.slideShow-btn:hover .st0 {
  fill: #5999ff; }

.control-button2.slideShow-btn:active .st0 {
  fill: #116dff; }

.control-button2.slideShow-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.color-btn .st0 {
  fill: #116dff; }

.control-button2.color-btn:hover .st0 {
  fill: #116dff; }

.control-button2.color-btn:active .st0 {
  fill: #116dff; }

.control-button2.color-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.device-btn .st1 {
  fill: #2d3034; }

.control-button2.device-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.device-btn:active .st1 {
  fill: #2d3034; }

.control-button2.device-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.device-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.device-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.device-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.device-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.help-btn .st1 {
  fill: #2d3034; }

.control-button2.help-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.help-btn:active .st1 {
  fill: #2d3034; }

.control-button2.help-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.help-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.help-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.help-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.help-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.pages-btn .st1 {
  fill: #2d3034; }

.control-button2.pages-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.pages-btn:active .st1 {
  fill: #2d3034; }

.control-button2.pages-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.pages-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.pages-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.pages-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.pages-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.preview-btn .st1 {
  fill: #2d3034; }

.control-button2.preview-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.preview-btn:active .st1 {
  fill: #2d3034; }

.control-button2.preview-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.preview-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.preview-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.preview-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.preview-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.redo-btn .st1 {
  fill: #2d3034; }

.control-button2.redo-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.redo-btn:active .st1 {
  fill: #2d3034; }

.control-button2.redo-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.redo-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.redo-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.redo-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.redo-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.undo-btn .st1 {
  fill: #2d3034; }

.control-button2.undo-btn:hover .st1 {
  fill: #5999ff; }

.control-button2.undo-btn:active .st1 {
  fill: #2d3034; }

.control-button2.undo-btn[disabled] .st1 {
  fill: #898989; }

.control-button2.undo-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.undo-btn-bld:hover .st1 {
  fill: #5999ff; }

.control-button2.undo-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.undo-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-bottom-edges-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-bottom-edges-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-bottom-edges-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-bottom-edges-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-bottom-edges-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-bottom-edges-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-bottom-edges-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-bottom-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-center-edges-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-center-edges-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-center-edges-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-center-edges-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-center-edges-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-center-edges-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-center-edges-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-center-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-center-vertical-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-center-vertical-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-center-vertical-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-center-vertical-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-center-vertical-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-center-vertical-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-center-vertical-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-center-vertical-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-left-edges-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-left-edges-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-left-edges-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-left-edges-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-left-edges-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-left-edges-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-left-edges-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-left-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-right-edges-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-right-edges-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-right-edges-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-right-edges-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-right-edges-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-right-edges-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-right-edges-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-right-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-top-edges-btn-bld .st1 {
  fill: #2d3034; }

.control-button2.align-top-edges-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.align-top-edges-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.align-top-edges-btn-bld:hover .st2 {
  fill: #80b1ff; }

.control-button2.align-top-edges-btn-bld:active .st1 {
  fill: #2d3034; }

.control-button2.align-top-edges-btn-bld:active .st2 {
  fill: #868aa5; }

.control-button2.align-top-edges-btn-bld[disabled] .st1 {
  fill: #898989; }

.control-button2.align-top-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.copy-btn-bld .st1 {
  fill: #000624; }

.control-button2.copy-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.copy-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.copy-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.copy-style-btn-bld .st1 {
  fill: #000624; }

.control-button2.copy-style-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.copy-style-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.copy-style-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.delete-btn-bld .st1 {
  fill: #000624; }

.control-button2.delete-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.delete-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.delete-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-horizontal-btn-bld path,
.control-distribute-horizontal-btn-bld .symbol-distribute-horizontal-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-horizontal-btn-bld .st1,
.control-distribute-horizontal-btn-bld .symbol-distribute-horizontal-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-distribute-horizontal-btn-bld .st1,
.control-distribute-horizontal-btn-bld .input-distribute-horizontal-btn-bld:checked ~ .symbol-distribute-horizontal-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.distribute-horizontal-btn-bld .st1 {
  fill: #000624; }

.control-button2.distribute-horizontal-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.distribute-horizontal-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.distribute-horizontal-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-hw-btn-bld path,
.control-distribute-hw-btn-bld .symbol-distribute-hw-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-hw-btn-bld .st1,
.control-distribute-hw-btn-bld .symbol-distribute-hw-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-distribute-hw-btn-bld .st1,
.control-distribute-hw-btn-bld .input-distribute-hw-btn-bld:checked ~ .symbol-distribute-hw-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.distribute-hw-btn-bld .st1 {
  fill: #000624; }

.control-button2.distribute-hw-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.distribute-hw-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.distribute-hw-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-vertical-btn-bld path,
.control-distribute-vertical-btn-bld .symbol-distribute-vertical-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-vertical-btn-bld .st1,
.control-distribute-vertical-btn-bld .symbol-distribute-vertical-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-distribute-vertical-btn-bld .st1,
.control-distribute-vertical-btn-bld .input-distribute-vertical-btn-bld:checked ~ .symbol-distribute-vertical-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.distribute-vertical-btn-bld .st1 {
  fill: #000624; }

.control-button2.distribute-vertical-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.distribute-vertical-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.distribute-vertical-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.duplicate-btn-bld .st1 {
  fill: #000624; }

.control-button2.duplicate-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.duplicate-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.duplicate-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.lock-btn-bld .st1 {
  fill: #000624; }

.control-button2.lock-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.lock-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.lock-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-mech-hw-btn-bld path,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-hw-btn-bld .st2,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld .st2 {
  fill: #116dff; }

.symbol-mech-hw-btn-bld .st1,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-mech-hw-btn-bld .st2,
.control-mech-hw-btn-bld .input-mech-hw-btn-bld:checked ~ .symbol-mech-hw-btn-bld .st2 {
  fill: #80b1ff; }

.selected .symbol-mech-hw-btn-bld .st1,
.control-mech-hw-btn-bld .input-mech-hw-btn-bld:checked ~ .symbol-mech-hw-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.mech-hw-btn-bld .st1 {
  fill: #000624; }

.control-button2.mech-hw-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.mech-hw-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.mech-hw-btn-bld:hover .st2 {
  fill: #000624; }

.control-button2.mech-hw-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.mech-hw-btn-bld:active .st2 {
  fill: #80b1ff; }

.control-button2.mech-hw-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-hw-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.symbol-mech-to-height-btn-bld path,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-to-height-btn-bld .st2,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld .st2 {
  fill: #116dff; }

.symbol-mech-to-height-btn-bld .st1,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-mech-to-height-btn-bld .st2,
.control-mech-to-height-btn-bld .input-mech-to-height-btn-bld:checked ~ .symbol-mech-to-height-btn-bld .st2 {
  fill: #80b1ff; }

.selected .symbol-mech-to-height-btn-bld .st1,
.control-mech-to-height-btn-bld .input-mech-to-height-btn-bld:checked ~ .symbol-mech-to-height-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.mech-to-height-btn-bld .st1 {
  fill: #000624; }

.control-button2.mech-to-height-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.mech-to-height-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.mech-to-height-btn-bld:hover .st2 {
  fill: #000624; }

.control-button2.mech-to-height-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.mech-to-height-btn-bld:active .st2 {
  fill: #80b1ff; }

.control-button2.mech-to-height-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-to-height-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.symbol-mech-to-width-btn-bld path,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-to-width-btn-bld .st2,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld .st2 {
  fill: #116dff; }

.symbol-mech-to-width-btn-bld .st1,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld .st1 {
  fill: #80b1ff; }

.selected .symbol-mech-to-width-btn-bld .st2,
.control-mech-to-width-btn-bld .input-mech-to-width-btn-bld:checked ~ .symbol-mech-to-width-btn-bld .st2 {
  fill: #80b1ff; }

.selected .symbol-mech-to-width-btn-bld .st1,
.control-mech-to-width-btn-bld .input-mech-to-width-btn-bld:checked ~ .symbol-mech-to-width-btn-bld .st1 {
  fill: #e7f0ff; }

.control-button2.mech-to-width-btn-bld .st1 {
  fill: #000624; }

.control-button2.mech-to-width-btn-bld .st2 {
  fill: #868aa5; }

.control-button2.mech-to-width-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.mech-to-width-btn-bld:hover .st2 {
  fill: #000624; }

.control-button2.mech-to-width-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.mech-to-width-btn-bld:active .st2 {
  fill: #80b1ff; }

.control-button2.mech-to-width-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-to-width-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.move-backward-btn-bld .st1 {
  fill: #000624; }

.control-button2.move-backward-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.move-backward-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.move-backward-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-foreward-btn-bld .st1 {
  fill: #000624; }

.control-button2.move-foreward-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.move-foreward-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.move-foreward-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-to-back-btn-bld .st1 {
  fill: #000624; }

.control-button2.move-to-back-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.move-to-back-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.move-to-back-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-to-front-btn-bld .st1 {
  fill: #000624; }

.control-button2.move-to-front-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.move-to-front-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.move-to-front-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.paste-btn-bld .st1 {
  fill: #000624; }

.control-button2.paste-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.paste-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.paste-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.paste-style-btn-bld .st1 {
  fill: #000624; }

.control-button2.paste-style-btn-bld:hover .st1 {
  fill: #116dff; }

.control-button2.paste-style-btn-bld:active .st1 {
  fill: #000624; }

.control-button2.paste-style-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.rotation-btn .st1 {
  fill: #000624; }

.control-button2.rotation-btn:hover .st1 {
  fill: #116dff; }

.control-button2.rotation-btn:active .st1 {
  fill: #000624; }

.control-button2.rotation-btn[disabled] .st1 {
  fill: #7a7a7a; }

.category-view-wrapper .symbol-headerCloseButton {
  fill: #116dff; }

.wixstores-migration-base-modal-header .symbol-headerCloseButton {
  fill: #d6e6fe; }

.cancel-icon .symbol-headerCloseButton {
  fill: #116dff; }

.symbol-anim-arc-in .st1,
.control-anim-arc-in .symbol-anim-arc-in .st1 {
  fill: #5999ff; }

.symbol-anim-arc-in .st2,
.control-anim-arc-in .symbol-anim-arc-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-arc-in .st1,
.control-anim-arc-in .input-anim-arc-in:checked ~ .symbol-anim-arc-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-arc-in .st2,
.control-anim-arc-in .input-anim-arc-in:checked ~ .symbol-anim-arc-in .st2 {
  fill: #fff; }

.symbol-anim-expand-in .st1,
.control-anim-expand-in .symbol-anim-expand-in .st1 {
  fill: #5999ff; }

.symbol-anim-expand-in .st2,
.control-anim-expand-in .symbol-anim-expand-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-expand-in .st1,
.control-anim-expand-in .input-anim-expand-in:checked ~ .symbol-anim-expand-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-expand-in .st2,
.control-anim-expand-in .input-anim-expand-in:checked ~ .symbol-anim-expand-in .st2 {
  fill: #fff; }

.symbol-anim-fade-in .st1,
.symbol-anim-fade-in .st2,
.symbol-anim-fade-in .st3,
.symbol-anim-fade-in .st4,
.symbol-anim-fade-in .st5,
.control-anim-fade-in .symbol-anim-fade-in .st1,
.control-anim-fade-in .symbol-anim-fade-in .st2,
.control-anim-fade-in .symbol-anim-fade-in .st3,
.control-anim-fade-in .symbol-anim-fade-in .st4,
.control-anim-fade-in .symbol-anim-fade-in .st5 {
  fill: #5999ff; }

.symbol-anim-fade-in .st1,
.control-anim-fade-in .symbol-anim-fade-in .st1 {
  opacity: 0.3; }

.symbol-anim-fade-in .st2,
.control-anim-fade-in .symbol-anim-fade-in .st2 {
  opacity: 0.4; }

.symbol-anim-fade-in .st3,
.control-anim-fade-in .symbol-anim-fade-in .st3 {
  opacity: 0.5; }

.symbol-anim-fade-in .st4,
.control-anim-fade-in .symbol-anim-fade-in .st4 {
  opacity: 0.6; }

.symbol-anim-fade-in .st5,
.control-anim-fade-in .symbol-anim-fade-in .st5 {
  opacity: 0.8; }

.selected .symbol-anim-fade-in .st1,
.selected .symbol-anim-fade-in .st2,
.selected .symbol-anim-fade-in .st3,
.selected .symbol-anim-fade-in .st4,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st1,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st2,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st3,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st4 {
  fill: #d6e6fe; }

.selected .symbol-anim-fade-in .st1,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st1 {
  opacity: 0.3; }

.selected .symbol-anim-fade-in .st2,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st2 {
  opacity: 0.4; }

.selected .symbol-anim-fade-in .st3,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st3 {
  opacity: 0.5; }

.selected .symbol-anim-fade-in .st4,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st4 {
  opacity: 0.6; }

.selected .symbol-anim-fade-in .st5,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st5 {
  fill: #fff;
  opacity: 0.8; }

.symbol-anim-flip-in .st1,
.control-anim-flip-in .symbol-anim-flip-in .st1 {
  fill: #5999ff; }

.symbol-anim-flip-in .st2,
.control-anim-flip-in .symbol-anim-flip-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-flip-in .st1,
.control-anim-flip-in .input-anim-flip-in:checked ~ .symbol-anim-flip-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-flip-in .st2,
.control-anim-flip-in .input-anim-flip-in:checked ~ .symbol-anim-flip-in .st2 {
  fill: #fff; }

.symbol-anim-float-in .st1,
.control-anim-float-in .symbol-anim-float-in .st1 {
  fill: #5999ff; }

.symbol-anim-float-in .st2,
.control-anim-float-in .symbol-anim-float-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-float-in .st1,
.control-anim-float-in .input-anim-float-in:checked ~ .symbol-anim-float-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-float-in .st2,
.control-anim-float-in .input-anim-float-in:checked ~ .symbol-anim-float-in .st2 {
  fill: #fff; }

.symbol-anim-fly-in .st1,
.control-anim-fly-in .symbol-anim-fly-in .st1 {
  fill: #5999ff; }

.symbol-anim-fly-in .st2,
.control-anim-fly-in .symbol-anim-fly-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-fly-in .st1,
.control-anim-fly-in .input-anim-fly-in:checked ~ .symbol-anim-fly-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-fly-in .st2,
.control-anim-fly-in .input-anim-fly-in:checked ~ .symbol-anim-fly-in .st2 {
  fill: #fff; }

.symbol-anim-fold-in .st1,
.control-anim-fold-in .symbol-anim-fold-in .st1 {
  fill: #5999ff; }

.symbol-anim-fold-in .st2,
.control-anim-fold-in .symbol-anim-fold-in .st2 {
  fill: #80b1ff; }

.symbol-anim-fold-in .st3,
.control-anim-fold-in .symbol-anim-fold-in .st3 {
  fill: #5999ff;
  opacity: 0.25; }

.selected .symbol-anim-fold-in .st1,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-fold-in .st2,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st2 {
  fill: #fff; }

.selected .symbol-anim-fold-in .st3,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st3 {
  fill: #d6e6fe;
  opacity: 0.25; }

.symbol-anim-puff-in .st1,
.control-anim-puff-in .symbol-anim-puff-in .st1 {
  fill: #5999ff; }

.symbol-anim-puff-in .st2,
.control-anim-puff-in .symbol-anim-puff-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-puff-in .st1,
.control-anim-puff-in .input-anim-puff-in:checked ~ .symbol-anim-puff-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-puff-in .st2,
.control-anim-puff-in .input-anim-puff-in:checked ~ .symbol-anim-puff-in .st2 {
  fill: #fff; }

.symbol-anim-reveal .st1,
.control-anim-reveal .symbol-anim-reveal .st1 {
  fill: #5999ff; }

.symbol-anim-reveal .st2,
.control-anim-reveal .symbol-anim-reveal .st2 {
  fill: #80b1ff; }

.symbol-anim-reveal .st3,
.control-anim-reveal .symbol-anim-reveal .st3 {
  fill: #5999ff;
  opacity: 0.25; }

.selected .symbol-anim-reveal .st1,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-reveal .st2,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st2 {
  fill: #fff; }

.selected .symbol-anim-reveal .st3,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st3 {
  fill: #d6e6fe;
  opacity: 0.25; }

.symbol-anim-slide-in .st1,
.control-anim-slide-in .symbol-anim-slide-in .st1 {
  fill: #5999ff; }

.symbol-anim-slide-in .st2,
.control-anim-slide-in .symbol-anim-slide-in .st2 {
  fill: #80b1ff; }

.symbol-anim-slide-in .st3,
.control-anim-slide-in .symbol-anim-slide-in .st3 {
  fill: #5999ff;
  opacity: 0.25; }

.selected .symbol-anim-slide-in .st1,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-slide-in .st2,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st2 {
  fill: #fff; }

.selected .symbol-anim-slide-in .st3,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st3 {
  fill: #d6e6fe;
  opacity: 0.25; }

.symbol-anim-spin-in .st1,
.control-anim-spin-in .symbol-anim-spin-in .st1 {
  fill: #5999ff; }

.symbol-anim-spin-in .st2,
.control-anim-spin-in .symbol-anim-spin-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-spin-in .st1,
.control-anim-spin-in .input-anim-spin-in:checked ~ .symbol-anim-spin-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-spin-in .st2,
.control-anim-spin-in .input-anim-spin-in:checked ~ .symbol-anim-spin-in .st2 {
  fill: #fff; }

.symbol-anim-turn-in .st1,
.control-anim-turn-in .symbol-anim-turn-in .st1 {
  fill: #5999ff; }

.symbol-anim-turn-in .st2,
.control-anim-turn-in .symbol-anim-turn-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-turn-in .st1,
.control-anim-turn-in .input-anim-turn-in:checked ~ .symbol-anim-turn-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-turn-in .st2,
.control-anim-turn-in .input-anim-turn-in:checked ~ .symbol-anim-turn-in .st2 {
  fill: #fff; }

.symbol-anim-bounce-in .st1,
.control-anim-bounce-in .symbol-anim-bounce-in .st1 {
  fill: #5999ff; }

.symbol-anim-bounce-in .st2,
.control-anim-bounce-in .symbol-anim-bounce-in .st2 {
  fill: #80b1ff; }

.selected .symbol-anim-bounce-in .st1,
.control-anim-bounce-in .input-anim-bounce-in:checked ~ .symbol-anim-bounce-in .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-bounce-in .st2,
.control-anim-bounce-in .input-anim-bounce-in:checked ~ .symbol-anim-bounce-in .st2 {
  fill: #fff; }

.symbol-anim-glide-in .st1,
.control-anim-glide-in .symbol-anim-glide-in .st1 {
  fill: #80b1ff; }

.symbol-anim-glide-in .st2,
.control-anim-glide-in .symbol-anim-glide-in .st2 {
  fill: #5999ff; }

.selected .symbol-anim-glide-in .st1,
.control-anim-glide-in .input-anim-glide-in:checked ~ .symbol-anim-glide-in .st1 {
  fill: #fff; }

.selected .symbol-anim-glide-in .st2,
.control-anim-glide-in .input-anim-glide-in:checked ~ .symbol-anim-glide-in .st2 {
  fill: #dfe5eb; }

.symbol-anim-on .st0,
.symbol-anim-on .st2,
.symbol-anim-on .st3,
.control-anim-on .symbol-anim-on .st0,
.control-anim-on .symbol-anim-on .st2,
.control-anim-on .symbol-anim-on .st3 {
  fill: #5999ff; }

.symbol-anim-on .st1,
.control-anim-on .symbol-anim-on .st1 {
  fill: #80b1ff; }

.selected .symbol-anim-on .st0,
.selected .symbol-anim-on .st3,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st0,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st3 {
  fill: #d6e6fe; }

.selected .symbol-anim-on .st1,
.selected .symbol-anim-on .st2,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st1,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st2 {
  fill: #fff; }

.symbol-play-anim .st0,
.control-play-anim .symbol-play-anim .st0 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2;
  opacity: 0.5; }

.symbol-play-anim .st1,
.control-play-anim .symbol-play-anim .st1 {
  fill: #fff; }

.symbol-play-anim .st2,
.control-play-anim .symbol-play-anim .st2 {
  fill: transparent; }

.symbol-play-anim .st3,
.control-play-anim .symbol-play-anim .st3 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2; }

.selected .symbol-play-anim .st0,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st0 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2;
  opacity: 0.5; }

.selected .symbol-play-anim .st1,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st1 {
  fill: transparent; }

.selected .symbol-play-anim .st2,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st2 {
  fill: #fff; }

.selected .symbol-play-anim .st3,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st3 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2; }

.symbol-anim-blur .st1,
.control-anim-blur .symbol-anim-blur .st1 {
  fill: #5999ff; }

.symbol-anim-blur .st2,
.control-anim-blur .symbol-anim-blur .st2 {
  fill: #80b1ff; }

.symbol-anim-blur .st3,
.control-anim-blur .symbol-anim-blur .st3 {
  fill: #5999ff; }

.symbol-anim-blur .st4,
.control-anim-blur .symbol-anim-blur .st4 {
  fill: #80b1ff;
  opacity: 0.4; }

.symbol-anim-blur .st5,
.control-anim-blur .symbol-anim-blur .st5 {
  fill: #80b1ff;
  opacity: 0.6; }

.symbol-anim-blur .st6,
.control-anim-blur .symbol-anim-blur .st6 {
  fill: #80b1ff;
  opacity: 0.2; }

.selected .symbol-anim-blur .st1,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st1 {
  fill: #d6e6fe; }

.selected .symbol-anim-blur .st2,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st2 {
  fill: #fff;
  opacity: 0.8; }

.selected .symbol-anim-blur .st3,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st3 {
  fill: #d6e6fe; }

.selected .symbol-anim-blur .st4,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st4 {
  fill: #d6e6fe;
  opacity: 0.4; }

.selected .symbol-anim-blur .st5,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st5 {
  fill: #d6e6fe;
  opacity: 0.6; }

.selected .symbol-anim-blur .st6,
.control-anim-blur .input-anim-blur:checked ~ .symbol-anim-blur .st6 {
  fill: #d6e6fe;
  opacity: 0.2; }

.symbol-anim-bg-expand .st1,
.control-anim-bg-expand .symbol-anim-bg-expand .st1 {
  fill: #e7f0ff; }

.symbol-anim-bg-expand .st2,
.control-anim-bg-expand .symbol-anim-bg-expand .st2 {
  fill: #116dff; }

.symbol-anim-bg-expand .st3,
.control-anim-bg-expand .symbol-anim-bg-expand .st3 {
  fill: #5999ff; }

.selected .symbol-anim-bg-expand .st1,
.control-anim-bg-expand .input-anim-bg-expand:checked ~ .symbol-anim-bg-expand .st1 {
  fill: #116dff; }

.selected .symbol-anim-bg-expand .st2,
.control-anim-bg-expand .input-anim-bg-expand:checked ~ .symbol-anim-bg-expand .st2 {
  fill: #fff; }

.selected .symbol-anim-bg-expand .st3,
.control-anim-bg-expand .input-anim-bg-expand:checked ~ .symbol-anim-bg-expand .st3 {
  fill: #d6e6fe; }

.symbol-anim-bg-fade-in .st1,
.control-anim-bg-fade-in .symbol-anim-bg-fade-in .st1 {
  fill: #5999ff; }

.symbol-anim-bg-fade-in .st2,
.control-anim-bg-fade-in .symbol-anim-bg-fade-in .st2 {
  fill: #80b1ff;
  opacity: 0.8; }

.symbol-anim-bg-fade-in .st3,
.control-anim-bg-fade-in .symbol-anim-bg-fade-in .st3 {
  fill: #80b1ff;
  opacity: 0.4; }

.symbol-anim-bg-fade-in .st4,
.control-anim-bg-fade-in .symbol-anim-bg-fade-in .st4 {
  fill: #116dff; }

.selected .symbol-anim-bg-fade-in .st1,
.control-anim-bg-fade-in .input-anim-bg-fade-in:checked ~ .symbol-anim-bg-fade-in .st1 {
  fill: #e7f0ff; }

.selected .symbol-anim-bg-fade-in .st2,
.control-anim-bg-fade-in .input-anim-bg-fade-in:checked ~ .symbol-anim-bg-fade-in .st2 {
  fill: #d6e6fe;
  opacity: 0.6; }

.selected .symbol-anim-bg-fade-in .st3,
.control-anim-bg-fade-in .input-anim-bg-fade-in:checked ~ .symbol-anim-bg-fade-in .st3 {
  fill: #d6e6fe;
  opacity: 0.3; }

.selected .symbol-anim-bg-fade-in .st4,
.control-anim-bg-fade-in .input-anim-bg-fade-in:checked ~ .symbol-anim-bg-fade-in .st4 {
  fill: #fff; }

.symbol-anim-bg-slide-in .st1,
.control-anim-bg-slide-in .symbol-anim-bg-slide-in .st1 {
  fill: #e7f0ff; }

.symbol-anim-bg-slide-in .st2,
.control-anim-bg-slide-in .symbol-anim-bg-slide-in .st2 {
  fill: #116dff; }

.symbol-anim-bg-slide-in .st3,
.control-anim-bg-slide-in .symbol-anim-bg-slide-in .st3 {
  fill: #5999ff; }

.symbol-anim-bg-slide-in .st4,
.control-anim-bg-slide-in .symbol-anim-bg-slide-in .st4 {
  fill: #80b1ff;
  opacity: 0.4; }

.selected .symbol-anim-bg-slide-in .st1,
.control-anim-bg-slide-in .input-anim-bg-slide-in:checked ~ .symbol-anim-bg-slide-in .st1 {
  fill: #116dff; }

.selected .symbol-anim-bg-slide-in .st2,
.control-anim-bg-slide-in .input-anim-bg-slide-in:checked ~ .symbol-anim-bg-slide-in .st2 {
  fill: #fff; }

.selected .symbol-anim-bg-slide-in .st3,
.control-anim-bg-slide-in .input-anim-bg-slide-in:checked ~ .symbol-anim-bg-slide-in .st3 {
  fill: #d6e6fe; }

.selected .symbol-anim-bg-slide-in .st4,
.control-anim-bg-slide-in .input-anim-bg-slide-in:checked ~ .symbol-anim-bg-slide-in .st4 {
  fill: #80b1ff; }

.symbol-flickr-number-images-1 .st1,
.control-flickr-number-images-1 .symbol-flickr-number-images-1 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-1 .st1,
.control-flickr-number-images-1 .input-flickr-number-images-1:checked ~ .symbol-flickr-number-images-1 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-3 .st1,
.control-flickr-number-images-3 .symbol-flickr-number-images-3 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-3 .st1,
.control-flickr-number-images-3 .input-flickr-number-images-3:checked ~ .symbol-flickr-number-images-3 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-5 .st1,
.control-flickr-number-images-5 .symbol-flickr-number-images-5 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-5 .st1,
.control-flickr-number-images-5 .input-flickr-number-images-5:checked ~ .symbol-flickr-number-images-5 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-10 .st1,
.control-flickr-number-images-10 .symbol-flickr-number-images-10 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-10 .st1,
.control-flickr-number-images-10 .input-flickr-number-images-10:checked ~ .symbol-flickr-number-images-10 .st1 {
  fill: #ffffff; }

.symbol-quickActionsLinkArrow {
  transform: scale(-1, 1); }
  .symbol-quickActionsLinkArrow .st1 {
    fill: #3b4057; }

.symbol-add-custom-color-plus {
  fill: #116dff; }

.control-accordion .symbol-accordionOpen {
  fill: #000624;
  width: 10px;
  height: 5px; }

.rich-text-input-control .symbol-accordionOpen {
  fill: #3b4057;
  width: 7px;
  padding: 3px 7px; }

.control-accordion .symbol-accordionClose {
  fill: #000624;
  width: 5px;
  height: 10px; }

.rich-text-input-control .symbol-accordionClose {
  fill: #3b4057;
  width: 4px;
  padding: 0 9px; }

.symbol-triangle-down {
  width: 8px;
  height: 6px; }

.symbol-triangle-down {
  width: 6px;
  height: 8px; }

.dropdown .symbol-arrowDown,
.dropdown-options .symbol-arrowDown {
  fill: #116dff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

.dropdown-options .arrow:hover .symbol-arrowDown {
  opacity: 1; }

.dropdown.disabled.select .symbol-arrowDown {
  fill: #bcbcbc; }

.font-family-dd.toolbar-mode .symbol-arrowDown,
.units-dd .symbol-arrowDown {
  width: 9px;
  padding: 0; }

.font-family-dd.toolbar-mode .expand.arrow:hover .symbol-arrowDown,
.units-dd .expand.arrow:hover .symbol-arrowDown {
  fill: #116dff; }

.symbol-inputValidationError {
  fill: #e62214; }
  .symbol-inputValidationError .c1,
  .symbol-inputValidationError .c2 {
    fill: #fff; }

.symbol-inputValidationSuccess {
  fill: #25a55a; }
  .symbol-inputValidationSuccess .c1 {
    fill: #fff; }
  .media-button-filename-text .symbol-inputValidationSuccess {
    width: 20px;
    height: 20px;
    padding-left: 4px;
    vertical-align: middle;
    display: inline-block;
    background-color: #fff; }

.symbol-inputValidationWarning {
  fill: #ffc233; }
  .symbol-inputValidationWarning > .line {
    stroke: #fff; }

.symbol-switchToOldEditorPanel {
  stroke: none;
  stroke-width: 1px;
  fill: none; }
  .symbol-switchToOldEditorPanel > .c1 {
    fill: #d9e1e8; }
  .symbol-switchToOldEditorPanel > .c2 {
    fill: #7992a5; }
  .symbol-switchToOldEditorPanel > .c3 {
    fill: #a7e3fa; }
  .symbol-switchToOldEditorPanel > .c4 {
    fill: #d3edff; }
  .symbol-switchToOldEditorPanel > .c5 {
    fill: #fff; }
  .symbol-switchToOldEditorPanel > .c9 {
    fill: #2b5672; }

.symbol-upload-btn {
  fill: #fff; }

.symbol-fonts-manager-clipart .d-1 {
  fill: #E9F6FF; }

.symbol-fonts-manager-clipart .d-2 {
  fill: #92E0F8; }

.symbol-fonts-manager-clipart .d-3 {
  fill: #5FCEF6; }

.dragging .symbol-fonts-manager-clipart .d-2,
.dragging .symbol-fonts-manager-clipart .d-3 {
  fill: #4EB7F5; }

.symbol-site-members {
  fill: #116dff;
  width: 17px;
  height: 15px; }

.label-info-icon-container .connected-info-icon .content {
  fill: #fff; }

.platform-warning .a {
  fill: #2B5672; }

.platform-warning .b {
  fill: #FDB5B4; }

.platform-warning .c {
  fill: #FACDCA; }

.platform-warning .d {
  fill: #FFF; }

.platform-warning .e {
  fill: #7992A5; }

.platform-warning .f {
  fill: #E6EBED; }

.platform-warning .g {
  stroke-width: 4;
  stroke: #E6EBED; }

.symbol-timepicker-text .st0,
.control-timepicker-text .symbol-timepicker-text .st0 {
  fill: #4EB7F6; }

.symbol-timepicker-text .st1,
.control-timepicker-text .symbol-timepicker-text .st1 {
  fill: #EAF7FF; }

.selected .symbol-timepicker-text .st0,
.control-timepicker-text .input-timepicker-text:checked ~ .symbol-timepicker-text .st0 {
  fill: #D3EDFF; }

.selected .symbol-timepicker-text .st1,
.control-timepicker-text .input-timepicker-text:checked ~ .symbol-timepicker-text .st1 {
  fill: #4EB7F6; }

.symbol-timepicker-stepper .st0,
.control-timepicker-stepper .symbol-timepicker-stepper .st0 {
  fill: #4EB7F6; }

.symbol-timepicker-stepper .st1,
.control-timepicker-stepper .symbol-timepicker-stepper .st1 {
  fill: #EAF7FF; }

.selected .symbol-timepicker-stepper .st0,
.control-timepicker-stepper .input-timepicker-stepper:checked ~ .symbol-timepicker-stepper .st0 {
  fill: #D3EDFF; }

.selected .symbol-timepicker-stepper .st1,
.control-timepicker-stepper .input-timepicker-stepper:checked ~ .symbol-timepicker-stepper .st1 {
  fill: #4EB7F6; }

.symbol-timepicker-dropdown .st0,
.control-timepicker-dropdown .symbol-timepicker-dropdown .st0 {
  fill: #4EB7F6; }

.symbol-timepicker-dropdown .st1,
.control-timepicker-dropdown .symbol-timepicker-dropdown .st1 {
  fill: #EAF7FF; }

.selected .symbol-timepicker-dropdown .st0,
.control-timepicker-dropdown .input-timepicker-dropdown:checked ~ .symbol-timepicker-dropdown .st0 {
  fill: #D3EDFF; }

.selected .symbol-timepicker-dropdown .st1,
.control-timepicker-dropdown .input-timepicker-dropdown:checked ~ .symbol-timepicker-dropdown .st1 {
  fill: #4EB7F6; }

.symbol-textUnderline,
.control-textUnderline .symbol-textUnderline {
  fill: #3b4057; }

.control-textUnderline:active .symbol-textUnderline {
  fill: #fff; }

.selected .symbol-textUnderline,
.control-textUnderline .input-textUnderline:checked ~ .symbol-textUnderline {
  fill: #fff; }

.symbol-textBold,
.control-textBold .symbol-textBold {
  fill: #3b4057; }

.control-textBold:active .symbol-textBold {
  fill: #fff; }

.selected .symbol-textBold,
.control-textBold .input-textBold:checked ~ .symbol-textBold {
  fill: #fff; }

.symbol-textAlignLeft,
.control-textAlignLeft .symbol-textAlignLeft {
  fill: #7fccf7; }

.selected .symbol-textAlignLeft,
.control-textAlignLeft .input-textAlignLeft:checked ~ .symbol-textAlignLeft {
  fill: #d3edfe; }

.symbol-textAlignRight,
.control-textAlignRight .symbol-textAlignRight {
  fill: #7fccf7; }

.selected .symbol-textAlignRight,
.control-textAlignRight .input-textAlignRight:checked ~ .symbol-textAlignRight {
  fill: #d3edfe; }

.symbol-textAlignCenter,
.control-textAlignCenter .symbol-textAlignCenter {
  fill: #7fccf7; }

.selected .symbol-textAlignCenter,
.control-textAlignCenter .input-textAlignCenter:checked ~ .symbol-textAlignCenter {
  fill: #d3edfe; }

.symbol-textAlignBlock,
.control-textAlignBlock .symbol-textAlignBlock {
  fill: #7fccf7; }

.selected .symbol-textAlignBlock,
.control-textAlignBlock .input-textAlignBlock:checked ~ .symbol-textAlignBlock {
  fill: #d3edfe; }

.symbol-textItalic,
.control-textItalic .symbol-textItalic {
  fill: #3b4057; }

.control-textItalic:active .symbol-textItalic {
  fill: #fff; }

.selected .symbol-textItalic,
.control-textItalic .input-textItalic:checked ~ .symbol-textItalic {
  fill: #fff; }

.symbol-textLink,
.control-textLink .symbol-textLink {
  fill: #3b4057; }

.control-textLink:active .symbol-textLink {
  fill: #fff; }

.selected .symbol-textLink,
.control-textLink .input-textLink:checked ~ .symbol-textLink {
  fill: #fff; }

.control-textLink .input-textLink:disabled ~ .symbol-textLink {
  pointer-events: none;
  fill: #939393; }

.text-link-dd .symbol-textLink {
  pointer-events: none;
  vertical-align: middle;
  padding: 0;
  display: inline-block; }

.selected .symbol-textLink {
  fill: #ffffff; }

.control-accordion .symbol-textEffectsIndicator {
  fill: #80abe4; }

.symbol-font-delete {
  fill: #3b4057;
  width: 13px;
  height: 14px; }

.symbol-font-download {
  fill: #3b4057;
  width: 17px;
  height: 13px; }
  .font-actions .symbol-font-download {
    margin: 0 12px; }

.symbol-font-rename {
  fill: #3b4057;
  width: 15px;
  height: 14px; }

.symbol-font-uploading-loader {
  fill: #116dff;
  width: 24px;
  height: 24px; }
  .symbol-font-uploading-loader.processing {
    animation: spin 1.5s linear infinite; }

@keyframes spin {
  100% {
    transform: rotate(-360deg); } }
  .symbol-font-uploading-loader.waiting, .symbol-font-uploading-loader.uploading {
    fill: #dfe5eb; }

.symbol-textAddLang {
  fill: #116dff; }
  .font-family-footer-link .symbol-textAddLang {
    margin-right: 5px; }

.symbol-textUploadFonts {
  fill: #116dff; }
  .font-family-footer-link .symbol-textUploadFonts {
    margin-right: 8px; }

.symbol-handle-dots {
  margin: 4px 0; }
  .symbol-handle-dots.dark .regular {
    fill: #868aa5; }
  .symbol-handle-dots.dark .offset {
    fill: #fff; }
  .symbol-handle-dots.light .regular {
    fill: #fff; }
  .symbol-handle-dots.light .offset {
    display: none; }

.symbol-handle-arrow .arrow {
  fill: #fff; }

.symbol-handle-arrow.bottom {
  transform: scale(1, -1); }

.symbol-scroll-fx-site-bg-freeze .st1,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st1 {
  fill: #5999ff; }

.symbol-scroll-fx-site-bg-freeze .st2,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st2 {
  fill: #d6e6fe; }

.symbol-scroll-fx-site-bg-freeze .st3,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st3 {
  fill: #e7f0ff; }

.symbol-scroll-fx-site-bg-freeze .st4,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st4 {
  fill: #80b1ff;
  opacity: 0.9; }

.symbol-scroll-fx-site-bg-freeze .st5,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st5 {
  fill: #d6e6fe; }

.symbol-scroll-fx-site-bg-freeze .st6,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st6 {
  fill: none;
  stroke: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-freeze .st1,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st1 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-site-bg-freeze .st2,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st2 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-freeze .st3,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st3 {
  fill: #5999ff; }

.selected .symbol-scroll-fx-site-bg-freeze .st4,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st4 {
  fill: #e7f0ff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-site-bg-freeze .st5,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st5 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-freeze .st6,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st6 {
  fill: none;
  stroke: #80b1ff; }

.symbol-scroll-fx-site-bg-parallax .st1,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st1 {
  fill: #5999ff; }

.symbol-scroll-fx-site-bg-parallax .st2,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st2 {
  fill: #d6e6fe; }

.symbol-scroll-fx-site-bg-parallax .st3,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st3 {
  fill: #e7f0ff; }

.symbol-scroll-fx-site-bg-parallax .st4,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st4 {
  fill: #80b1ff;
  opacity: 0.9; }

.symbol-scroll-fx-site-bg-parallax .st5,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st5 {
  fill: #d6e6fe; }

.symbol-scroll-fx-site-bg-parallax .st6,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st6 {
  fill: none;
  stroke: #5999ff; }

.symbol-scroll-fx-site-bg-parallax .st7,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st7 {
  fill: none;
  stroke: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-parallax .st1,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st1 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-site-bg-parallax .st2,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st2 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-parallax .st3,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st3 {
  fill: #5999ff; }

.selected .symbol-scroll-fx-site-bg-parallax .st4,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st4 {
  fill: #e7f0ff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-site-bg-parallax .st5,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st5 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-site-bg-parallax .st6,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st6 {
  fill: none;
  stroke: #d6e6fe; }

.selected .symbol-scroll-fx-site-bg-parallax .st7,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st7 {
  fill: none;
  stroke: #80b1ff; }

.symbol-scroll-fx-strip-bg-reveal .st1,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st1 {
  fill: #A5DBF9; }

.symbol-scroll-fx-strip-bg-reveal .st2,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st2 {
  fill: #fff; }

.symbol-scroll-fx-strip-bg-reveal .st3,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st3 {
  fill: #e7f0ff; }

.symbol-scroll-fx-strip-bg-reveal .st4,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st4 {
  fill: #5999ff; }

.symbol-scroll-fx-strip-bg-reveal .st5,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st5 {
  fill: #5999ff;
  opacity: 0.9; }

.symbol-scroll-fx-strip-bg-reveal .st6,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st6 {
  fill: #80b1ff; }

.symbol-scroll-fx-strip-bg-reveal .st7,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st7 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-strip-bg-reveal .st1,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st1 {
  fill: #5999ff;
  opacity: 0.7; }

.selected .symbol-scroll-fx-strip-bg-reveal .st2,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st2 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-reveal .st3,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st3 {
  fill: #80b1ff;
  opacity: 0.6; }

.selected .symbol-scroll-fx-strip-bg-reveal .st4,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st4 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-strip-bg-reveal .st5,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st5 {
  fill: #d6e6fe;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-reveal .st6,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st6 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-reveal .st7,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st7 {
  fill: #80b1ff; }

.symbol-scroll-fx-strip-bg-parallax .st1,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st1 {
  fill: #A5DBF9; }

.symbol-scroll-fx-strip-bg-parallax .st2,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st2 {
  fill: #e7f0ff; }

.symbol-scroll-fx-strip-bg-parallax .st3,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st3 {
  fill: #fff; }

.symbol-scroll-fx-strip-bg-parallax .st4,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st4 {
  fill: #5999ff; }

.symbol-scroll-fx-strip-bg-parallax .st5,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st5 {
  fill: #80b1ff; }

.symbol-scroll-fx-strip-bg-parallax .st6,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st6 {
  fill: #5999ff;
  opacity: 0.9; }

.symbol-scroll-fx-strip-bg-parallax .st7,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st7 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-strip-bg-parallax .st1,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st1 {
  fill: #5999ff;
  opacity: 0.7; }

.selected .symbol-scroll-fx-strip-bg-parallax .st2,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st2 {
  fill: #80b1ff;
  opacity: 0.6; }

.selected .symbol-scroll-fx-strip-bg-parallax .st3,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st3 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-parallax .st4,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st4 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-strip-bg-parallax .st5,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st5 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-parallax .st6,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st6 {
  fill: #d6e6fe;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-parallax .st7,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st7 {
  fill: #80b1ff; }

.symbol-scroll-fx-strip-bg-zoom .st1,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st1 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-zoom .st2,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st2 {
  fill: #A5DAFB; }

.symbol-scroll-fx-strip-bg-zoom .st3,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st3 {
  fill: #d6e6fe; }

.symbol-scroll-fx-strip-bg-zoom .st4,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st4 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-zoom .st1,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st1 {
  fill: #d6e6fe;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-zoom .st2,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st2 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-zoom .st3,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st3 {
  fill: #5999ff; }

.selected .symbol-scroll-fx-strip-bg-zoom .st4,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st4 {
  fill: #D8EFFF; }

.symbol-scroll-fx-strip-bg-fade .st1,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st1 {
  fill: #5FBEF7;
  opacity: 0.4; }

.symbol-scroll-fx-strip-bg-fade .st2,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st2 {
  fill: #d6e6fe; }

.symbol-scroll-fx-strip-bg-fade .st3,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st3 {
  fill: #d6e6fe;
  opacity: 0.6; }

.symbol-scroll-fx-strip-bg-fade .st4,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st4 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-fade .st5,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st5 {
  fill: #A5DAFB; }

.selected .symbol-scroll-fx-strip-bg-fade .st1,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st1 {
  fill: #d6e6fe;
  opacity: 0.4; }

.selected .symbol-scroll-fx-strip-bg-fade .st2,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st2 {
  fill: #5999ff;
  opacity: 0.5; }

.selected .symbol-scroll-fx-strip-bg-fade .st3,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st3 {
  fill: #5999ff;
  opacity: 0.4; }

.selected .symbol-scroll-fx-strip-bg-fade .st4,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st4 {
  fill: #d6e6fe;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-fade .st5,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st5 {
  fill: #80b1ff; }

.symbol-scroll-fx-strip-bg-blur .st1,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st1 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-blur .st2,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st2 {
  fill: #A5DAFB; }

.symbol-scroll-fx-strip-bg-blur .st3,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st3 {
  fill: #d6e6fe; }

.selected .symbol-scroll-fx-strip-bg-blur .st1,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st1 {
  fill: #d6e6fe;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-blur .st2,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st2 {
  fill: #80b1ff; }

.selected .symbol-scroll-fx-strip-bg-blur .st3,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st3 {
  fill: #5999ff; }

.wix-store-manage-panel .symbol-onlineStoreManager {
  margin-top: 30px;
  margin-bottom: 14px; }

.symbol-somethingWentWrong .blue1 {
  fill: #E9F6FF; }

.symbol-somethingWentWrong .blue2 {
  fill: #D3EDFF;
  opacity: 0.95; }

.symbol-somethingWentWrong .blue3 {
  fill: #5FCEF5; }

.symbol-somethingWentWrong .white {
  fill: #FFFFFF; }

.symbol-somethingWentWrong .gradient1 {
  fill: url(#gradient1); }

.symbol-newStorePreviewReady .blue1 {
  fill: #E9F6FF; }

.symbol-newStorePreviewReady .blue2 {
  fill: #D3EDFF; }

.symbol-newStorePreviewReady .blue3 {
  fill: #5FCEF5; }

.symbol-newStorePreviewReady .blue4 {
  fill: #A7E3FA; }

.symbol-newStorePreviewReady .blue5 {
  fill: #4EB7F5; }

.symbol-newStorePreviewReady .white {
  fill: #FFFFFF; }

.symbol-newStorePreviewReady .opacity95 {
  opacity: 0.95; }

.symbol-router_icon {
  margin-top: 3px;
  margin-left: -1px; }
  .context-menu-action > .symbol-router_icon {
    flex-shrink: 0;
    height: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 0;
    width: 16px; }
    .context-menu-action > .symbol-router_icon path {
      fill: #2B5672; }

.wix-code-context-menu-item .symbol-add_new_item {
  margin-left: -1px; }
  .wix-code-context-menu-item .symbol-add_new_item path[fill] {
    fill: #1D2D3C; }

.wix-code-context-menu-item .symbol-manage_content {
  margin-left: -1px; }
  .wix-code-context-menu-item .symbol-manage_content path[fill] {
    fill: #1D2D3C; }

.symbol-delete_icon {
  margin-top: 3px;
  margin-left: -1px; }
  .context-menu-action > .symbol-delete_icon {
    flex-shrink: 0;
    height: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 0;
    width: 16px; }
    .context-menu-action > .symbol-delete_icon path {
      fill: #2B5672; }

.symbol-unlink_icon {
  margin-top: 3px;
  margin-left: -4px; }
  .context-menu-action > .symbol-unlink_icon {
    flex-shrink: 0;
    height: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 0;
    width: 16px; }
    .context-menu-action > .symbol-unlink_icon path {
      fill: #2B5672; }

.symbol-edit_icon {
  margin-top: 3px;
  margin-left: -1px; }
  .context-menu-action > .symbol-edit_icon {
    flex-shrink: 0;
    height: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 0;
    width: 16px; }
    .context-menu-action > .symbol-edit_icon path {
      fill: #2B5672; }

.symbol-add_hook_icon {
  margin-left: -2px; }
  .wix-data-context-menu-item .symbol-add_hook_icon {
    margin-left: 0; }
    .wix-data-context-menu-item .symbol-add_hook_icon path[fill] {
      fill: #2b5672; }

.context-menu-action > .symbol-page_dynamic_icon {
  flex-shrink: 0;
  height: 16px;
  margin-left: 0;
  margin-right: 12px;
  margin-top: 0;
  width: 16px; }
  .context-menu-action > .symbol-page_dynamic_icon path {
    fill: #2B5672; }

.symbol-layoutNative .st1,
.control-layoutNative .symbol-layoutNative .st1 {
  fill: #116dff; }

.symbol-layoutNative .st2,
.control-layoutNative .symbol-layoutNative .st2 {
  fill: #d6e6fe; }

.selected .symbol-layoutNative .st1,
.control-layoutNative .input-layoutNative:checked ~ .symbol-layoutNative .st1 {
  fill: #d6e6fe; }

.selected .symbol-layoutNative .st2,
.control-layoutNative .input-layoutNative:checked ~ .symbol-layoutNative .st2 {
  fill: #116dff; }

.symbol-layoutAbb .st1,
.control-layoutAbb .symbol-layoutAbb .st1 {
  fill: #116dff; }

.symbol-layoutAbb .st2,
.control-layoutAbb .symbol-layoutAbb .st2 {
  fill: #d6e6fe; }

.selected .symbol-layoutAbb .st1,
.control-layoutAbb .input-layoutAbb:checked ~ .symbol-layoutAbb .st1 {
  fill: #d6e6fe; }

.selected .symbol-layoutAbb .st2,
.control-layoutAbb .input-layoutAbb:checked ~ .symbol-layoutAbb .st2 {
  fill: #116dff; }

.symbol-layoutIconOnly .st1,
.control-layoutIconOnly .symbol-layoutIconOnly .st1 {
  fill: #116dff; }

.symbol-layoutIconOnly .st2,
.control-layoutIconOnly .symbol-layoutIconOnly .st2 {
  fill: #d6e6fe; }

.selected .symbol-layoutIconOnly .st1,
.control-layoutIconOnly .input-layoutIconOnly:checked ~ .symbol-layoutIconOnly .st1 {
  fill: #d6e6fe; }

.selected .symbol-layoutIconOnly .st2,
.control-layoutIconOnly .input-layoutIconOnly:checked ~ .symbol-layoutIconOnly .st2 {
  fill: #116dff; }

.st1 {
  fill: #3b4057; }

.st1 {
  fill: #3b4057; }

.symbol-designFlagSquare .st1,
.control-designFlagSquare .symbol-designFlagSquare .st1 {
  fill: #116dff; }

.symbol-designFlagSquare .st2,
.control-designFlagSquare .symbol-designFlagSquare .st2 {
  fill: #d6e6fe; }

.selected .symbol-designFlagSquare .st1,
.control-designFlagSquare .input-designFlagSquare:checked ~ .symbol-designFlagSquare .st1 {
  fill: #d6e6fe; }

.selected .symbol-designFlagSquare .st2,
.control-designFlagSquare .input-designFlagSquare:checked ~ .symbol-designFlagSquare .st2 {
  fill: #116dff; }

.symbol-designFlagRounded .st1,
.control-designFlagRounded .symbol-designFlagRounded .st1 {
  fill: #116dff; }

.symbol-designFlagRounded .st2,
.control-designFlagRounded .symbol-designFlagRounded .st2 {
  fill: #d6e6fe; }

.selected .symbol-designFlagRounded .st1,
.control-designFlagRounded .input-designFlagRounded:checked ~ .symbol-designFlagRounded .st1 {
  fill: #d6e6fe; }

.selected .symbol-designFlagRounded .st2,
.control-designFlagRounded .input-designFlagRounded:checked ~ .symbol-designFlagRounded .st2 {
  fill: #116dff; }

.symbol-designFlagCircle .st1,
.control-designFlagCircle .symbol-designFlagCircle .st1 {
  fill: #116dff; }

.symbol-designFlagCircle .st2,
.control-designFlagCircle .symbol-designFlagCircle .st2 {
  fill: #d6e6fe; }

.selected .symbol-designFlagCircle .st1,
.control-designFlagCircle .input-designFlagCircle:checked ~ .symbol-designFlagCircle .st1 {
  fill: #d6e6fe; }

.selected .symbol-designFlagCircle .st2,
.control-designFlagCircle .input-designFlagCircle:checked ~ .symbol-designFlagCircle .st2 {
  fill: #116dff; }

.symbol-flip-horizontal .st1,
.control-flip-horizontal .symbol-flip-horizontal .st1 {
  fill: #2d3034; }

.selected .symbol-flip-horizontal .st1,
.control-flip-horizontal .input-flip-horizontal:checked ~ .symbol-flip-horizontal .st1 {
  fill: #116dff; }

.symbol-flip-vertical .st1,
.control-flip-vertical .symbol-flip-vertical .st1 {
  fill: #2d3034; }

.selected .symbol-flip-vertical .st1,
.control-flip-vertical .input-flip-vertical:checked ~ .symbol-flip-vertical .st1 {
  fill: #116dff; }

.symbol-reset-crop .st1,
.control-reset-crop .symbol-reset-crop .st1 {
  fill: #2d3034; }

.symbol-shape-crop .st1,
.control-shape-crop .symbol-shape-crop .st1 {
  fill: #2d3034; }

.symbol-shape-crop .st2,
.control-shape-crop .symbol-shape-crop .st2 {
  fill: #116dff; }

.selected .symbol-shape-crop .st1,
.control-shape-crop .input-shape-crop:checked ~ .symbol-shape-crop .st1 {
  fill: #fff; }

.selected .symbol-shape-crop .st2,
.control-shape-crop .input-shape-crop:checked ~ .symbol-shape-crop .st2 {
  fill: #fff; }

.selected .symbol-richTextBoxToolbarTop .window,
.control-richTextBoxToolbarTop .input-richTextBoxToolbarTop:checked ~ .symbol-richTextBoxToolbarTop .window {
  fill: #d6e6fe; }

.selected .symbol-richTextBoxToolbarTop .text,
.control-richTextBoxToolbarTop .input-richTextBoxToolbarTop:checked ~ .symbol-richTextBoxToolbarTop .text {
  fill: #5999ff; }

.selected .symbol-richTextBoxToolbarBottom .window,
.control-richTextBoxToolbarBottom .input-richTextBoxToolbarBottom:checked ~ .symbol-richTextBoxToolbarBottom .window {
  fill: #d6e6fe; }

.selected .symbol-richTextBoxToolbarBottom .text,
.control-richTextBoxToolbarBottom .input-richTextBoxToolbarBottom:checked ~ .symbol-richTextBoxToolbarBottom .text {
  fill: #5999ff; }

.selected .symbol-richTextBoxToolbarFloating .window,
.control-richTextBoxToolbarFloating .input-richTextBoxToolbarFloating:checked ~ .symbol-richTextBoxToolbarFloating .window {
  fill: #d6e6fe; }

.selected .symbol-richTextBoxToolbarFloating .text,
.control-richTextBoxToolbarFloating .input-richTextBoxToolbarFloating:checked ~ .symbol-richTextBoxToolbarFloating .text {
  fill: #5999ff; }

.selected .symbol-ratingsInputLayoutTop .st1,
.control-ratingsInputLayoutTop .input-ratingsInputLayoutTop:checked ~ .symbol-ratingsInputLayoutTop .st1 {
  fill: #e7f0ff; }

.selected .symbol-ratingsInputLayoutBottom .st1,
.control-ratingsInputLayoutBottom .input-ratingsInputLayoutBottom:checked ~ .symbol-ratingsInputLayoutBottom .st1 {
  fill: #e7f0ff; }

.selected .symbol-ratingsInputLayoutSide .st1,
.control-ratingsInputLayoutSide .input-ratingsInputLayoutSide:checked ~ .symbol-ratingsInputLayoutSide .st1 {
  fill: #e7f0ff; }

.symbol-menuContainerFullScreen .cls-b1,
.control-menuContainerFullScreen .symbol-menuContainerFullScreen .cls-b1 {
  fill: #116dff; }

.symbol-menuContainerFullScreen .cls-b2,
.control-menuContainerFullScreen .symbol-menuContainerFullScreen .cls-b2 {
  stroke: #e7f0ff; }

.selected .symbol-menuContainerFullScreen .cls-b1,
.control-menuContainerFullScreen .input-menuContainerFullScreen:checked ~ .symbol-menuContainerFullScreen .cls-b1 {
  fill: #e7f0ff; }

.selected .symbol-menuContainerFullScreen .cls-b2,
.control-menuContainerFullScreen .input-menuContainerFullScreen:checked ~ .symbol-menuContainerFullScreen .cls-b2 {
  stroke: #116dff; }

.symbol-menuContainerLeft .cls-b1,
.control-menuContainerLeft .symbol-menuContainerLeft .cls-b1 {
  fill: #d6e6fe; }

.symbol-menuContainerLeft .cls-b2,
.control-menuContainerLeft .symbol-menuContainerLeft .cls-b2 {
  fill: #116dff; }

.symbol-menuContainerLeft .cls-b3,
.control-menuContainerLeft .symbol-menuContainerLeft .cls-b3 {
  stroke: #e7f0ff; }

.selected .symbol-menuContainerLeft .cls-b1,
.control-menuContainerLeft .input-menuContainerLeft:checked ~ .symbol-menuContainerLeft .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-menuContainerLeft .cls-b2,
.control-menuContainerLeft .input-menuContainerLeft:checked ~ .symbol-menuContainerLeft .cls-b2 {
  fill: #e7f0ff; }

.selected .symbol-menuContainerLeft .cls-b3,
.control-menuContainerLeft .input-menuContainerLeft:checked ~ .symbol-menuContainerLeft .cls-b3 {
  stroke: #116dff; }

.symbol-menuContainerRight .cls-b1,
.control-menuContainerRight .symbol-menuContainerRight .cls-b1 {
  fill: #d6e6fe; }

.symbol-menuContainerRight .cls-b2,
.control-menuContainerRight .symbol-menuContainerRight .cls-b2 {
  fill: #116dff; }

.symbol-menuContainerRight .cls-b3,
.control-menuContainerRight .symbol-menuContainerRight .cls-b3 {
  stroke: #e7f0ff; }

.selected .symbol-menuContainerRight .cls-b1,
.control-menuContainerRight .input-menuContainerRight:checked ~ .symbol-menuContainerRight .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-menuContainerRight .cls-b2,
.control-menuContainerRight .input-menuContainerRight:checked ~ .symbol-menuContainerRight .cls-b2 {
  fill: #e7f0ff; }

.selected .symbol-menuContainerRight .cls-b3,
.control-menuContainerRight .input-menuContainerRight:checked ~ .symbol-menuContainerRight .cls-b3 {
  stroke: #116dff; }

.symbol-menuContainerTop .cls-b1,
.control-menuContainerTop .symbol-menuContainerTop .cls-b1 {
  fill: #d6e6fe; }

.symbol-menuContainerTop .cls-b2,
.control-menuContainerTop .symbol-menuContainerTop .cls-b2 {
  fill: #116dff; }

.symbol-menuContainerTop .cls-b3,
.control-menuContainerTop .symbol-menuContainerTop .cls-b3 {
  stroke: #e7f0ff; }

.selected .symbol-menuContainerTop .cls-b1,
.control-menuContainerTop .input-menuContainerTop:checked ~ .symbol-menuContainerTop .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-menuContainerTop .cls-b2,
.control-menuContainerTop .input-menuContainerTop:checked ~ .symbol-menuContainerTop .cls-b2 {
  fill: #e7f0ff; }

.selected .symbol-menuContainerTop .cls-b3,
.control-menuContainerTop .input-menuContainerTop:checked ~ .symbol-menuContainerTop .cls-b3 {
  stroke: #116dff; }

.symbol-menuContainerBottom .cls-b1,
.control-menuContainerBottom .symbol-menuContainerBottom .cls-b1 {
  fill: #d6e6fe; }

.symbol-menuContainerBottom .cls-b2,
.control-menuContainerBottom .symbol-menuContainerBottom .cls-b2 {
  fill: #116dff; }

.symbol-menuContainerBottom .cls-b3,
.control-menuContainerBottom .symbol-menuContainerBottom .cls-b3 {
  stroke: #e7f0ff; }

.selected .symbol-menuContainerBottom .cls-b1,
.control-menuContainerBottom .input-menuContainerBottom:checked ~ .symbol-menuContainerBottom .cls-b1 {
  fill: #80b1ff; }

.selected .symbol-menuContainerBottom .cls-b2,
.control-menuContainerBottom .input-menuContainerBottom:checked ~ .symbol-menuContainerBottom .cls-b2 {
  fill: #e7f0ff; }

.selected .symbol-menuContainerBottom .cls-b3,
.control-menuContainerBottom .input-menuContainerBottom:checked ~ .symbol-menuContainerBottom .cls-b3 {
  stroke: #116dff; }

.symbol-ltrThumbnailAbc .abc,
.control-ltrThumbnailAbc .symbol-ltrThumbnailAbc .abc {
  fill: #5999ff; }

.selected .symbol-ltrThumbnailAbc .abc,
.control-ltrThumbnailAbc .input-ltrThumbnailAbc:checked ~ .symbol-ltrThumbnailAbc .abc {
  fill: #5999ff; }

.symbol-rtlThumbnailAbc .abc,
.control-rtlThumbnailAbc .symbol-rtlThumbnailAbc .abc {
  fill: #5999ff; }

.selected .symbol-rtlThumbnailAbc .abc,
.control-rtlThumbnailAbc .input-rtlThumbnailAbc:checked ~ .symbol-rtlThumbnailAbc .abc {
  fill: #5999ff; }

.symbol-interactionsEffectsGrow .st1,
.control-interactionsEffectsGrow .symbol-interactionsEffectsGrow .st1 {
  fill: #80b1ff; }

.symbol-interactionsEffectsGrow .st2,
.control-interactionsEffectsGrow .symbol-interactionsEffectsGrow .st2 {
  fill: #d6e6fe; }

.symbol-interactionsEffectsGrow .st3,
.control-interactionsEffectsGrow .symbol-interactionsEffectsGrow .st3 {
  fill: #5999ff; }

.selected .symbol-interactionsEffectsGrow .st1,
.control-interactionsEffectsGrow .input-interactionsEffectsGrow:checked ~ .symbol-interactionsEffectsGrow .st1 {
  fill: #fff; }

.selected .symbol-interactionsEffectsGrow .st2,
.control-interactionsEffectsGrow .input-interactionsEffectsGrow:checked ~ .symbol-interactionsEffectsGrow .st2 {
  fill: #80b1ff; }

.selected .symbol-interactionsEffectsGrow .st3,
.control-interactionsEffectsGrow .input-interactionsEffectsGrow:checked ~ .symbol-interactionsEffectsGrow .st3 {
  fill: #d6e6fe; }

.symbol-interactionsEffectsShrink .st1,
.control-interactionsEffectsShrink .symbol-interactionsEffectsShrink .st1 {
  fill: #5999ff; }

.symbol-interactionsEffectsShrink .st2,
.control-interactionsEffectsShrink .symbol-interactionsEffectsShrink .st2 {
  fill: #80b1ff; }

.symbol-interactionsEffectsShrink .st3,
.control-interactionsEffectsShrink .symbol-interactionsEffectsShrink .st3 {
  fill: #d6e6fe; }

.selected .symbol-interactionsEffectsShrink .st1,
.control-interactionsEffectsShrink .input-interactionsEffectsShrink:checked ~ .symbol-interactionsEffectsShrink .st1 {
  fill: #d6e6fe; }

.selected .symbol-interactionsEffectsShrink .st2,
.control-interactionsEffectsShrink .input-interactionsEffectsShrink:checked ~ .symbol-interactionsEffectsShrink .st2 {
  fill: #fff; }

.selected .symbol-interactionsEffectsShrink .st3,
.control-interactionsEffectsShrink .input-interactionsEffectsShrink:checked ~ .symbol-interactionsEffectsShrink .st3 {
  fill: #80b1ff; }

.symbol-interactionsEffectsSkew .st1,
.control-interactionsEffectsSkew .symbol-interactionsEffectsSkew .st1 {
  fill: #5999ff; }

.symbol-interactionsEffectsSkew .st2,
.control-interactionsEffectsSkew .symbol-interactionsEffectsSkew .st2 {
  fill: #80b1ff; }

.symbol-interactionsEffectsSkew .st3,
.control-interactionsEffectsSkew .symbol-interactionsEffectsSkew .st3 {
  fill: #d6e6fe; }

.selected .symbol-interactionsEffectsSkew .st1,
.control-interactionsEffectsSkew .input-interactionsEffectsSkew:checked ~ .symbol-interactionsEffectsSkew .st1 {
  fill: #d6e6fe; }

.selected .symbol-interactionsEffectsSkew .st2,
.control-interactionsEffectsSkew .input-interactionsEffectsSkew:checked ~ .symbol-interactionsEffectsSkew .st2 {
  fill: #fff; }

.selected .symbol-interactionsEffectsSkew .st3,
.control-interactionsEffectsSkew .input-interactionsEffectsSkew:checked ~ .symbol-interactionsEffectsSkew .st3 {
  fill: #80b1ff; }

.symbol-interactionsEffectsRotate .st1,
.control-interactionsEffectsRotate .symbol-interactionsEffectsRotate .st1 {
  fill: #80b1ff; }

.symbol-interactionsEffectsRotate .st2,
.control-interactionsEffectsRotate .symbol-interactionsEffectsRotate .st2 {
  fill: #d6e6fe; }

.symbol-interactionsEffectsRotate .st3,
.control-interactionsEffectsRotate .symbol-interactionsEffectsRotate .st3 {
  fill: #5999ff; }

.selected .symbol-interactionsEffectsRotate .st1,
.control-interactionsEffectsRotate .input-interactionsEffectsRotate:checked ~ .symbol-interactionsEffectsRotate .st1 {
  fill: #fff; }

.selected .symbol-interactionsEffectsRotate .st2,
.control-interactionsEffectsRotate .input-interactionsEffectsRotate:checked ~ .symbol-interactionsEffectsRotate .st2 {
  fill: #80b1ff; }

.selected .symbol-interactionsEffectsRotate .st3,
.control-interactionsEffectsRotate .input-interactionsEffectsRotate:checked ~ .symbol-interactionsEffectsRotate .st3 {
  fill: #d6e6fe; }

.symbol-interactionsEffectsCustomize .st1,
.control-interactionsEffectsCustomize .symbol-interactionsEffectsCustomize .st1 {
  fill: #80b1ff; }

.symbol-interactionsEffectsCustomize .st2,
.control-interactionsEffectsCustomize .symbol-interactionsEffectsCustomize .st2 {
  fill: #d6e6fe; }

.symbol-interactionsEffectsCustomize .st3,
.control-interactionsEffectsCustomize .symbol-interactionsEffectsCustomize .st3 {
  fill: #EDF8FF; }

.symbol-interactionsEffectsCustomize .st4,
.control-interactionsEffectsCustomize .symbol-interactionsEffectsCustomize .st4 {
  fill: #5999ff; }

.symbol-interactionsEffectsCustomize .st5,
.control-interactionsEffectsCustomize .symbol-interactionsEffectsCustomize .st5 {
  fill: #EDF8FF; }

.selected .symbol-interactionsEffectsCustomize .st1,
.control-interactionsEffectsCustomize .input-interactionsEffectsCustomize:checked ~ .symbol-interactionsEffectsCustomize .st1 {
  fill: #fff; }

.selected .symbol-interactionsEffectsCustomize .st2,
.control-interactionsEffectsCustomize .input-interactionsEffectsCustomize:checked ~ .symbol-interactionsEffectsCustomize .st2 {
  fill: #80b1ff; }

.selected .symbol-interactionsEffectsCustomize .st3,
.control-interactionsEffectsCustomize .input-interactionsEffectsCustomize:checked ~ .symbol-interactionsEffectsCustomize .st3 {
  fill: #116dff; }

.selected .symbol-interactionsEffectsCustomize .st4,
.control-interactionsEffectsCustomize .input-interactionsEffectsCustomize:checked ~ .symbol-interactionsEffectsCustomize .st4 {
  fill: #d6e6fe; }

.selected .symbol-interactionsEffectsCustomize .st5,
.control-interactionsEffectsCustomize .input-interactionsEffectsCustomize:checked ~ .symbol-interactionsEffectsCustomize .st5 {
  fill: #116dff; }

.symbol-interactionsEffectsRotateLeft .st1,
.control-interactionsEffectsRotateLeft .symbol-interactionsEffectsRotateLeft .st1 {
  fill: #5999ff; }

.selected .symbol-interactionsEffectsRotateLeft .st1,
.control-interactionsEffectsRotateLeft .input-interactionsEffectsRotateLeft:checked ~ .symbol-interactionsEffectsRotateLeft .st1 {
  fill: #fff; }

.symbol-interactionsEffectsRotateRight .st1,
.control-interactionsEffectsRotateRight .symbol-interactionsEffectsRotateRight .st1 {
  fill: #5999ff; }

.selected .symbol-interactionsEffectsRotateRight .st1,
.control-interactionsEffectsRotateRight .input-interactionsEffectsRotateRight:checked ~ .symbol-interactionsEffectsRotateRight .st1 {
  fill: #fff; }

.symbol-interactionsEffectsSkewHorizontal .st1,
.control-interactionsEffectsSkewHorizontal .symbol-interactionsEffectsSkewHorizontal .st1 {
  fill: #116dff; }

.selected .symbol-interactionsEffectsSkewHorizontal .st1,
.control-interactionsEffectsSkewHorizontal .input-interactionsEffectsSkewHorizontal:checked ~ .symbol-interactionsEffectsSkewHorizontal .st1 {
  fill: #fff; }

.symbol-interactionsEffectsSkewVertical .st1,
.control-interactionsEffectsSkewVertical .symbol-interactionsEffectsSkewVertical .st1 {
  fill: #116dff; }

.selected .symbol-interactionsEffectsSkewVertical .st1,
.control-interactionsEffectsSkewVertical .input-interactionsEffectsSkewVertical:checked ~ .symbol-interactionsEffectsSkewVertical .st1 {
  fill: #fff; }

.symbol-focal-point .st0,
.control-focal-point .symbol-focal-point .st0 {
  fill: #000624; }

.control-focal-point:hover .symbol-focal-point .st0 {
  fill: #116dff; }

.control-focal-point .input-focal-point:disabled ~ .symbol-focal-point {
  pointer-events: none; }
  .control-focal-point .input-focal-point:disabled ~ .symbol-focal-point st0 {
    fill: #939393; }

.selected .symbol-focal-point .st0,
.control-focal-point .input-focal-point:checked ~ .symbol-focal-point .st0 {
  fill: #116dff; }

.control-focal-point:hover .input-focal-point:not(:disabled):checked ~ .symbol-focal-point .st0 {
  fill: #116dff; }

.control-focal-point .input-focal-point:checked:disabled ~ .symbol-focal-point {
  pointer-events: none; }
  .control-focal-point .input-focal-point:checked:disabled ~ .symbol-focal-point st0 {
    fill: #939393; }

.symbol-play-pause .st0,
.control-play-pause .symbol-play-pause .st0 {
  fill: #000000; }

.symbol-play-pause .st1,
.control-play-pause .symbol-play-pause .st1 {
  fill: transparent; }

.symbol-play-pause .st2,
.control-play-pause .symbol-play-pause .st2 {
  fill: #000624;
  fill-opacity: .65; }

.symbol-play-pause .st3,
.control-play-pause .symbol-play-pause .st3 {
  fill: #000000;
  fill-opacity: .5; }

.control-play-pause:hover .symbol-play-pause .st2 {
  fill: #000624;
  fill-opacity: .5; }

.control-play-pause:active .symbol-play-pause .st2 {
  fill: #000624;
  fill-opacity: .65; }

.control-play-pause .input-play-pause:disabled ~ .symbol-play-pause {
  pointer-events: none; }
  .control-play-pause .input-play-pause:disabled ~ .symbol-play-pause .st2 {
    fill: #939393;
    fill-opacity: .65; }

.selected .symbol-play-pause .st0,
.control-play-pause .input-play-pause:checked ~ .symbol-play-pause .st0 {
  fill: transparent; }

.selected .symbol-play-pause .st1,
.control-play-pause .input-play-pause:checked ~ .symbol-play-pause .st1 {
  fill: #000000; }

.control-play-pause:hover .input-play-pause:not(:disabled):checked ~ .symbol-play-pause .st2 {
  fill: #000624;
  fill-opacity: .5; }

.control-play-pause:active .input-play-pause:checked ~ .symbol-play-pause .st2 {
  fill: #000624;
  fill-opacity: .65; }

.control-play-pause .input-play-pause:checked:disabled ~ .symbol-play-pause {
  pointer-events: none; }
  .control-play-pause .input-play-pause:checked:disabled ~ .symbol-play-pause .st2 {
    fill: #939393;
    fill-opacity: .65; }

.control-button2.plus .st0 {
  fill: #116dff; }

.control-button2.plus:hover .st0 {
  fill: #116dff; }

.control-button2.plus:active .st0 {
  fill: #116dff; }

.control-button2.plus[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.remove .st0 {
  fill: #000624; }

.control-button2.remove:hover .st0 {
  fill: #116dff; }

.control-button2.remove:active .st0 {
  fill: #116dff; }

.control-button2.remove[disabled] .st0 {
  fill: #939393; }
